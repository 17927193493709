import React from 'react'
import Modal from '../../../components/utils/Modal'
import List from '../../../classes/List'
import { Button } from 'primereact/button'
import TextInput from '../../../components/inputs/TextInput'
import { formatCurrencyToNumber, formatToCurrency } from '../../../helpers/formaters'
import { Column } from 'primereact/column'
import DataList from '../../../components/layout/DataList'
import { FaPix } from 'react-icons/fa6'
import { showErrorMessage, showSuccessMessage } from '../../../components/utils/Message'
import TextAreaInput from '../../../components/inputs/TextAreaInput'
import CommonHelper from '../../../helpers/CommonHelper'
import PixGeradoService from '../../../services/cadastro/financeiro/integracao/PixGeradoService'
import { getStore } from '../../../redux/store'

class AdicionarPixModal extends List {
  constructor (props) {
    super(props, '', PixGeradoService, 'avaliacao', 'codigo_ava')
    this.state = {
      valorCredito: 0,
      pixList: [],
      expandedRows: null,
      pixGerado: false,
      total: 0,
      exibirDadosPixCobranca: false
    }
  }

  componentDidUpdate (prevProps) {
    const { visible } = this.props

    if (prevProps.visible !== visible && visible) {
      this.handleLoadPixData()
    }
  }

  handleLoadPixData = async () => {
    const { idNegociacao } = this.props

    try {
      const response = await PixGeradoService.listarPix(getStore().usuarioLogado.databaseConnectionName, idNegociacao)

      if (response) {
        this.setState({
          pixList: response || [],
          pixGerado: response || false,
          total: response?.length
        }
        )
      }
    } catch (error) {
      console.error('Erro ao carregar dados do PIX:', error)
      showErrorMessage('Erro ao carregar os dados do PIX.')
    }
  };

  setValorCredito = (valor_pix) => {
    this.setState({ valorCredito: valor_pix })
  };

  handleAddPix = () => {
    const { valorCredito, pixList } = this.state

    if (valorCredito > 0) {
      // Adicionar um novo item à lista de PIX
      const newPix = {
        id: pixList.length + 1,
        valor_pix: valorCredito
      }

      this.setState({
        pixList: [...pixList, newPix],
        valorCredito: 0
      })
    }
  };

  handleRemoverPix = (id) => {
    const { pixList } = this.state
    const novaPixList = pixList.filter((pix) => pix.id !== id)

    this.setState({
      pixList: novaPixList,
      pixGerado: false
    })
  };

  cancelarPix = () => {
    this.setState({
      pixGerado: false,
      exibirDadosPixCobranca: false
    })
    this.resetarDados()
    showSuccessMessage('PIX cancelado com sucesso!')
  };

  async componentDidMount () {
    this.addEventListeners()
  }

  somarValoresPix = () => {
    const { pixList } = this.state

    // Calcula a soma dos valores
    const total = pixList.reduce((acumulador, item) => acumulador + item.valor_pix, 0)

    return total
  };

  resetarDados = () => {
    this.setState({
      valorCredito: 0,
      pixList: [],
      pixGerado: false,
      expandedRows: null,
      total: 0,
      exibirDadosPixCobranca: false
    })
  };

  abrirModalPix = (qrCodePix, pixCopiaCola) => {
    this.setState({
      exibirDadosPixCobranca: true,
      qrCodePix,
      pixCopiaCola
    })
  };

  handleGerarPixCobranca = async () => {
    const { idNegociacao, CnpjCpfFranqueado } = this.props
    const { pixList } = this.state

    if (pixList.length === 0) {
      showErrorMessage('Adicione pelo menos um item antes de gerar o PIX.')
      return
    }

    if (this.somarValoresPix() <= 0) {
      showErrorMessage('O valor total precisa ser maior que zero para gerar o PIX.')
      return
    }

    const payload = {
      idNegociacao,
      CnpjCpfFranqueado,
      vlNegociacao: this.somarValoresPix(),
      chave: pixList.map((item) => ({ qrCodeValue: item.valor_pix }))
    }

    try {
      const response = await PixGeradoService.gerarPix(payload)
      if (response && response.data) {
        this.setState({
          pixList: response.data.chave || [], // Atualiza com a resposta do backend
          pixGerado: true
        })
        showSuccessMessage('PIX gerado com sucesso!')
      }
    } catch (error) {
      showErrorMessage(error.message)
    }
  };

  fecharModal = async () => {
    this.resetarDados()
    this.props.hide()
  }

  render () {
    const { valorCredito, pixList, pixGerado } = this.state

    const columns = pixGerado
      ? [
        {
          header: 'PIX',
          field: 'valor',
          className: 'text-left pl-4',
          body: (rowData) => formatToCurrency(rowData.valor_pix)
        },
        {
          header: 'Visualizar',
          field: 'acoes',
          className: 'text-center pr-4'
        }
      ]
      : [

        {
          header: 'PIX',
          field: 'valor_pix',
          className: 'text-center pl-4',
          body: (rowData) => formatToCurrency(rowData.valor_pix)
        },
        {
          header: 'Remover',
          field: 'acoes',
          className: 'text-center pr-4'
        }

      ]

    return (

      <Modal
        header="PIX"
        width="40"
        visible={this.props.visible}
        modal={true}
        onHide={this.fecharModal}
      >
        {!this.state.pixGerado && (
          <div className="flex flex-row justify-content-center align-items-center" style={{ gap: '10px' }}>
            <div className="flex" style={{ gap: '10px' }}>
              <TextInput
                label="Valor"
                placeholder="Valor a ser creditado"
                value={formatToCurrency(valorCredito)}
                onChange={(e) => this.setValorCredito(formatCurrencyToNumber(e.target.value))}
              />
            </div>
            <div>
              <Button
                label="Adicionar"
                icon="pi pi-plus"
                onClick={this.handleAddPix}
                disabled={valorCredito <= 0}
              />
            </div>
          </div>
        )}
        <div className="field col-12 grid justify-content-center no-margin pt-4">
          <DataList
            data={

              pixList.map((item) => ({
                ...item,
                acoes: pixGerado ? (
                  <div className="flex justify-content-center gap-2">
                    <Button
                      icon="pi pi-eye"
                      onClick={() => this.abrirModalPix(item.qrcode_pix, item.copcol_pix)}
                    />

                  </div>
                ) : (
                  <Button
                    icon="pi pi-times"
                    className="p-button-danger"
                    onClick={() => this.handleRemoverPix(item.id)}
                  />
                )
              }))}

            hidePaginator={true}
            page={this.state.page}

            showFilter={false}
            responsive={true}
          >
            {columns.map((col, i) => (
              <Column
                key={i}
                header={col.header}
                field={col.field}
                body={col.body || null}
                style={col.style || {}}
              />
            ))}
          </DataList>
        </div>

        {/* Botão para gerar ou cancelar PIX */}
        <div className="flex justify-content-center mt-4">
          {!this.state.pixGerado ? (
            <Button
              label="Gerar PIX"
              icon={<FaPix />}
              onClick={this.handleGerarPixCobranca}
              disabled={this.somarValoresPix() <= 0}
              className="w-1/2 p-3"
              style={{ gap: '10px' }}
            />
          ) : (
            <Button
              label="Cancelar PIX"
              icon="pi pi-times"
              onClick={this.cancelarPix}
              className="w-1/2 p-3 p-button-danger"
              style={{ gap: '10px' }}
            />
          )}
        </div>

        {/* Modal para exibir detalhes do PIX */}
        {this.state.exibirDadosPixCobranca && (
          <Modal
            header="Detalhes do PIX"
            width="40"
            visible={this.state.exibirDadosPixCobranca}
            onHide={() => this.setState({ exibirDadosPixCobranca: false })}
            footer={
              <Button
                label="Fechar"
                onClick={() => this.setState({ exibirDadosPixCobranca: false })}
                className="p-button-danger"
                icon="pi pi-times"
              />
            }
          >
            <div className="flex justify-content-around align-items-center">
              <div>
                <img
                  src={this.state.qrCodePix}
                  alt="QR Code"
                />
              </div>
              <div>
                <TextAreaInput
                  value={this.state.pixCopiaCola}
                  className="w-full textArea carentDisabled"
                  disabled={true}
                  rows={1}
                  autoResize={false}
                />
                <Button
                  icon="pi pi-copy"
                  type="button"
                  label="Copiar PIX"
                  className="edit-button ml-2 mt-2"
                  onClick={async () => {
                    const sucesso = await CommonHelper.copyToClipboard(
                      this.state.pixCopiaCola
                    )
                    if (sucesso) {
                      showSuccessMessage('Texto copiado para a área de transferência!')
                    } else {
                      showErrorMessage('Erro ao copiar o texto.')
                    }
                  }}
                />
              </div>
            </div>
          </Modal>
        )}
      </Modal>

    )
  }
}

export default AdicionarPixModal
