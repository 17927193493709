import { classNames } from 'primereact/utils'
import React, { useEffect } from 'react'
import DateInput from '../../../../../../components/inputs/DateInput'
import SelectInput from '../../../../../../components/inputs/SelectInput'
import TextAreaInput from '../../../../../../components/inputs/TextAreaInput'
import TextInput from '../../../../../../components/inputs/TextInput'
import ResponsavelPagamento from '../../../../../../components/layout/ResponsavelPagamento/ResponsavelPagamento'
import FieldErrorMessage from '../../../../../../components/utils/FieldErrorMessage'
import Modal from '../../../../../../components/utils/Modal'
import RequiredLabel from '../../../../../../components/utils/RequiredLabel'
import { formatToCurrency } from '../../../../../../helpers/formaters'
import { useValidateInput } from '../../../../../../helpers/useValidateInput'
import FoPagNeg from '../_FoPagNeg'
import { FinanciamentoAbertoValidator } from './FinanciamentoAbertoValidator'

const FoPagFinanciamentoAberto = (props) => {
  const {
    form,
    edicaoHabilitada,
    calculateValorParcela,
    handleChangeCurrency,
    handleTipoFinanciamento,
    getFinanceiras,
    getTiposRestricaoVeiculo,
    setFinanceira,
    setRestricao,
    setValorVeiculo,
    hide,
    dados,
    isLojaPaga,
    excluir,
    isNew
  } = props

  const deveSelecionarVeiculo = props.negociacaoForm.values.veiculosConjunto.length > 0

  useEffect(() => {
    const onLoad = async () => {
      form.setFieldValue('veiculo', props.veiculo)

      const financeiraOptions = await getFinanceiras()
      setFinanceira(dados.codfin_fpn, financeiraOptions)

      const restricaoOptions = await getTiposRestricaoVeiculo()
      setRestricao(dados.codtrv_fpn || 0, restricaoOptions)

      if (isNew) {
        form.setFieldValue('isLojaPaga', isLojaPaga)
      }

      form.setFieldValue(
        'prazoEntreParcelas',
        form.values.prazoEntreParcelas || 30
      )
    }

    onLoad()
  }, [])

  useEffect(() => {
    calculateValorParcela()
  }, [form.values.valor, form.values.quantidadeParcelas])

  const handleSelect = async (e) => {
    await form.handleChange(e)
    await form.setFieldTouched(e.target.name)
  }

  const handleChangePrimeiroVencimento = async (e) => {
    form.handleChange(e)
    await form.setFieldTouched('primeiroVencimento')
    await form.validateField('primeiroVencimento')
  }

  const { getFormErrorMessage, isFormFieldValid } = useValidateInput(form)

  return (
    <Modal
      header="Financiamento em aberto"
      btnSalvar={edicaoHabilitada ? form.submitForm : undefined}
      btnExcluir={(!isNew && edicaoHabilitada) ? excluir : undefined}
      width="65"
      visible={true}
      modal={true}
      onHide={hide}
    >
      <div className="formgrid grid">
        <div className="field col-12 flex justify-content-around mb-4">
          <ResponsavelPagamento isLojaPaga={isNew ? isLojaPaga : form.values.isLojaPaga} />
        </div>
        <div className={'field col-12 md:col-' + (deveSelecionarVeiculo ? '8' : '12')}>
          <RequiredLabel label="Financeira" valid={isFormFieldValid('financeira.codigo_fin')} />
          <SelectInput
            value={form.values.financeira}
            options={form.values.financeiraOptions}
            optionLabel="pessoa.nomraz_pes"
            onChange={handleSelect}
            placeholder="- Selecione -"
            name="financeira"
            noFloatLabel
            disabled={!edicaoHabilitada}
            className={classNames(
              { 'p-invalid': isFormFieldValid('financeira.codigo_fin') },
              'inputfield w-full'
            )}
            onHide={() => form.setFieldTouched('financeira.codigo_fin')}
          />
          <FieldErrorMessage message={getFormErrorMessage('financeira.codigo_fin')} />
        </div>
        {deveSelecionarVeiculo && (
          <div className="field col-12 md:col-4">
            <RequiredLabel label="Veículo" valid={isFormFieldValid('veiculo.codigo_vei')} />
            <SelectInput
              value={form.values.veiculo}
              options={props.negociacaoForm.values.veiculosConjunto.map(veiculoNve => veiculoNve.veiculo)}
              onChange={(e) => form.setFieldValue('veiculo', e.target.value)}
              optionLabel="placha"
              dataKey="codigo_vei"
              name='veiculo'
              disabled={!edicaoHabilitada}
              className={classNames({ 'p-invalid': isFormFieldValid('veiculo.codigo_vei') }, 'inputfield w-full')}
              noFloatLabel
            />
            <FieldErrorMessage message={getFormErrorMessage('veiculo.codigo_vei')} />
          </div>
        )}
        <div className="field col-12 md:col-4">
          <RequiredLabel label="Valor do financiamento" valid={isFormFieldValid('valor')} />
          <TextInput
            name="valor"
            disabled={!edicaoHabilitada}
            value={formatToCurrency(form.values.valor)}
            onChange={async (e) => {
              await handleChangeCurrency(e)
            }}
            onBlur={() => {
              setValorVeiculo()
              form.setFieldTouched('valor')
            }}
            className={classNames({ 'p-invalid': isFormFieldValid('valor') }, 'inputfield w-full')}
          />
          <FieldErrorMessage message={getFormErrorMessage('valor')} />
        </div>
        <div className="field col-12 md:col-4">
          <TextInput
            name="quantidadeParcelas"
            label="Quantidade de parcelas"
            value={form.values.quantidadeParcelas}
            onChange={(e) => {
              form.handleChange(e)
              calculateValorParcela()
            }}
            keyfilter="int"
            disabled={!edicaoHabilitada}
            className="inputfield w-full"
            onBlur={() => form.setFieldTouched('valor')}
          />
          <FieldErrorMessage message={getFormErrorMessage('valor')} />
        </div>
        <div className="field col-12 md:col-4">
          <RequiredLabel label="Valor da parcela" valid={isFormFieldValid('valorParcela')} />
          <TextInput
            name="valorParcela"
            disabled={!edicaoHabilitada}
            value={formatToCurrency(form.values.valorParcela)}
            onChange={handleChangeCurrency}
            onBlur={() => form.setFieldTouched('valorParcela')}
            className={classNames({ 'p-error': isFormFieldValid('valorParcela') }, 'inputfield w-full')}
          />
          <FieldErrorMessage message={getFormErrorMessage('valorParcela')} />
        </div>
        <div className="field col-12 md:col-4">
          <RequiredLabel label="Previsão de Pagamento" valid={isFormFieldValid('primeiroVencimento')} />
          <DateInput
            name="primeiroVencimento"
            disabled={!edicaoHabilitada}
            value={form.values.primeiroVencimento}
            onChange={handleChangePrimeiroVencimento}
            className={classNames(
              { 'p-invalid': isFormFieldValid('primeiroVencimento') },
              'inputfield w-full'
            )}
            onBlur={() => form.setFieldTouched('primeiroVencimento')}
          />
          <FieldErrorMessage message={getFormErrorMessage('primeiroVencimento')} />
        </div>
        <div className="field col-12 md:col-8">
          <SelectInput
            value={form.values.restricao}
            options={form.values.restricaoOptions}
            optionLabel="descri_trv"
            onChange={handleTipoFinanciamento}
            placeholder="- Selecione -"
            name="restricao"
            label="Restrição"
            noFloatLabel
            disabled={!edicaoHabilitada}
            className="inputfield w-full"
          />
        </div>
        <div className="field col-12">
          <TextAreaInput
            name="observacoes"
            label="Observações"
            value={form.values.observacoes}
            disabled={!edicaoHabilitada}
            onChange={form.handleChange}
            className="inputfield w-full"
          />
        </div>
      </div>
    </Modal>
  )
}

export default (props) =>
  FoPagNeg({
    Component: FoPagFinanciamentoAberto,
    validationSchema: FinanciamentoAbertoValidator,
    ...props
  })
