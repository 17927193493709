import ServiceBase from '../../base/ServiceBase'

export default class ContaService {
  static async getAll () {
    return await ServiceBase.getBase('contas')
  }

  static async filterContas () {
    return await ServiceBase.getBase('contas/filter')
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`conta?${query}&page=${page}&perPage=${perPage}`)
  }

  static async filterAutocomplete (query) {
    return await ServiceBase.getBase('conta?name=' + query, false)
  }
}
