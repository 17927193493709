import React from 'react'
import DataListSimples from '../../../../components/layout/DataListSimples/DataListSimples'
import { formatDate } from '../../../../helpers/formaters'
import { createColumns } from '../../../../helpers/tableConfigs'

export default function PedidoCompraList ({ pedidoCompra }) {
  const columns = createColumns([
    {
      field: 'numped_pcp',
      header: 'PEDIDO',
      width: '20%',
      sortable: false
    },
    {
      field: 'pedfor_pcp',
      header: 'NÚMERO FORNECEDOR',
      sortable: false
    },
    {
      field: 'fornecedor_pcp',
      header: 'FORNECEDOR',
      width: '20%',
      sortable: false
    },
    {
      field: 'dahped_pcp',
      header: 'DATA',
      width: '20%',
      customBody: formatData,
      sortable: false
    },
    {
      field: 'qtd_ppc',
      header: 'QUANTIDADE PEDIDA',
      sortable: false
    },
    {
      field: 'qtdrec_ppc',
      header: 'QUANTIDADE RECEBIDA',
      sortable: false
    },
    {
      field: 'qtdEmPedido',
      header: 'BACKORDER?',
      customBody: formatBO,
      sortable: false
    }
  ])

  function formatData (data) {
    return formatDate(data.dahped_pcp)
  }
  function formatBO (data) {
    return Number(data.baord_ppc) === 1 ? 'SIM' : 'NÃO'
  }

  return (
    <>
      <DataListSimples
        value={pedidoCompra}
        columns={columns}
        totalizadores={[

          {
            key: 'qtd_ppc',
            label: 'Total pedido'
          },
          { key: 'qtdrec_ppc', label: 'Total recebido' }
        ]}
        onSelect={() => {

        }}
      />
    </>
  )
}
