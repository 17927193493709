import ServiceBase from '../../base/ServiceBase'

export default class RestricaoService {
  async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`/restricao?page=${page}&perPage=${perPage}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`/restricao?${query}&page=${page}&perPage=${perPage}`)
  }

  static async getByCodigo (codigoRestricao) {
    return await ServiceBase.getBase(`/restricao/${codigoRestricao}`)
  }

  static async getPermissaoMenu (codigoMen) {
    return await ServiceBase.getBase(`/permissaoacessomenu/${codigoMen}`)
  }
}
