import React, { Component } from 'react'
import LegendaRow from './LegendaRow'

class ReportLegenda extends Component {
  generateHtmlLegenda () {
    const props = this.props

    return props.legendas.map((row, index) => {
      return (
        <LegendaRow key={index} value={row} />
      )
    })
  }

  render () {
    const props = this.props

    return props.legendas ? (
      <div className="legenda ml-2 mb-2">
        <div className="mb-2">
          <strong className="legenda-title">{props.legendaTitle}</strong>
        </div>
        {this.generateHtmlLegenda()}
      </div>
    ) : (
      <></>
    )
  }
}

export default ReportLegenda
