import React from 'react'
import { Crud } from '../../../classes/NewCrud'
import { getEstoqueDTO } from '../../../dtos/vendas/estoque/EstoqueDTO'
import ConsultaEstoqueForm from '../../../forms/vendas/consultaEstoque/consultaEstoqueFormPages/ConsultaEstoqueForm'
import { OrcamentoBalcaoValidator } from '../../../forms/vendas/orcamentoBalcao/OrcamentoBalcaoValidator'
import OrcamentoBalcaoService from '../../../services/vendas/orcamentoBalcao/OrcamentoBalcaoService'

export function ConsultaEstoque (props) {
  return (
    <Crud
      match={props.match}
      onSaveModal={props.onSaveModal}
      onDeleteModal={props.onDeleteModal}
      codigoSelecionado={props.codigoSelecionado}
      onHide={props.onHide}
      apenasVisualizacao={props.apenasVisualizacao}
      formContent={ConsultaEstoqueForm}
      mostrarBotaoSalvar={false}

      modelGetDTO={getEstoqueDTO}
      service={OrcamentoBalcaoService}
      valida={OrcamentoBalcaoValidator}
      resource=""
      primarykeyname="codigo_opv"
      numeroKeyName="numero_opv"
      isNew={props.isNew}
    />
  )
}
