import { postPessoaJuridicaDTO } from '../../../dtos/cadastro/pessoa/PessoaJuridicaDTO'
import ServiceBase from '../../base/ServiceBase'

export default class PessoaJuridicaService {
  async get (id) {
    return await ServiceBase.getBase(`pessoas/juridica/${id}`)
  }

  async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`pessoas/juridica?page=${page}&perPage=${perPage}`)
  }

  async getFormValidator () {
    return await ServiceBase.getBase('pessoas/juridica/validator')
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`pessoas/juridica?${query}&page=${page}&perPage=${perPage}`)
  }

  static async filterAutoComplete (query) {
    return await ServiceBase.getBase(`pessoas/juridica?${query}`, false)
  }

  async save (data) {
    return await ServiceBase.postBase('pessoas/juridica', postPessoaJuridicaDTO(data))
  }

  async delete (codigo_pej) {
    return await ServiceBase.deleteBase(`pessoas/juridica/${codigo_pej}`)
  }

  static async getAllRegimesTributacao () {
    return await ServiceBase.getBase('/regimetributacao')
  }

  static async getAllRegimesTributacaoEspecial () {
    return await ServiceBase.getBase('/regimetributacaoespecial')
  }

  static async validaConveniado (codCliente, codConveniado) {
    return await ServiceBase.getBase(`pessoas/juridica/validaConveniado/${codCliente}/${codConveniado}`)
  }
}
