import { postDeParaModeloDTO } from '../../dtos/cadastro/autogestor/DeParaModeloDTO'
import ServiceBase from '../base/ServiceBase'

export default class ModeloDeParaService {
  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`modelos/depara/lista?${query}&page=${page}&perPage=${perPage}`)
  }

  async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`modelos/depara/lista?page=${page}&perPage=${perPage}`)
  }

  static async save (data) {
    return await ServiceBase.postBase('/modelos/depara', postDeParaModeloDTO(data))
  }
}
