import { classNames } from 'primereact/utils'
import React from 'react'
import TextInput from '../../../../../components/inputs/TextInput'
import FieldErrorMessage from '../../../../../components/utils/FieldErrorMessage'
import RequiredLabel from '../../../../../components/utils/RequiredLabel'
import { useValidateInput } from '../../../../../helpers/useValidateInput'

const DadosPrincipaisAce = ({ form, edicaoHabilitada }) => {
  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(form)

  return (
    <div className="formgrid grid fluid">
      <div className="field col-12 sm:col-6 md:col-6">
        <RequiredLabel label="Descrição" valid={isFormFieldValid('descri_ace')} />
        <TextInput
          placeholder="Descrição"
          className={classNames({ 'p-invalid': isFormFieldValid('descri_ace') }, 'inputfield w-full')}
          value={form.values.descri_ace}
          name="descri_ace"
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          onBlur={() => form.setFieldTouched('descri_ace')}
        />
        <FieldErrorMessage message={getFormErrorMessage('descri_ace')} />
      </div>
      <div className="field col-12 sm:col-3 md:col-3">
        <RequiredLabel label="Sigla" valid={isFormFieldValid('sigla_ace')} />
        <TextInput
          placeholder="Sigla"
          className={classNames({ 'p-invalid': isFormFieldValid('sigla_ace') }, 'inputfield w-full')}
          value={form.values.sigla_ace}
          name="sigla_ace"
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          onBlur={() => form.setFieldTouched('sigla_ace')}
        />
        <FieldErrorMessage message={getFormErrorMessage('sigla_ace')} />
      </div>
      <div className="field col-12 sm:col-3 md:col-3">
        <label className="label">Código externo</label>
        <TextInput
          placeholder="Código externo"
          className="inputfield w-full"
          value={form.values.codext_ace}
          name="codext_ace"
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          keyfilter="int"
        />
      </div>
    </div>
  )
}

export default DadosPrincipaisAce
