import { classNames } from 'primereact/utils'
import React, { useEffect, useState } from 'react'
import SelectInput from '../../../../../../components/inputs/SelectInput'
import TextAreaInput from '../../../../../../components/inputs/TextAreaInput'
import TextInput from '../../../../../../components/inputs/TextInput'
import SearchInput from '../../../../../../components/inputs/searchInput/SearchInput'
import ResponsavelPagamento from '../../../../../../components/layout/ResponsavelPagamento/ResponsavelPagamento'
import FieldErrorMessage from '../../../../../../components/utils/FieldErrorMessage'
import { showErrorMessage, showWarnMessage } from '../../../../../../components/utils/Message'
import Modal from '../../../../../../components/utils/Modal'
import RequiredLabel from '../../../../../../components/utils/RequiredLabel'
import { formatPlaca, formatToCurrency } from '../../../../../../helpers/formaters'
import { useValidateInput } from '../../../../../../helpers/useValidateInput'
import VeiculoService from '../../../../../../services/cadastro/veiculo/VeiculoService'
import Veiculo from '../../../../../../views/cadastro/veiculo/veiculo/Veiculo'
import FoPagNeg from '../_FoPagNeg'
import { VeiculoTrocaValidator } from './VeiculoTrocaValidator'
import { getVeiculoDTO } from '../../../../../../dtos/cadastro/veiculo/VeiculoDTO'

const FoPagVeiculoTroca = (props) => {
  const {
    setValorVeiculo,
    handleChangeCurrency,
    edicaoHabilitada,
    form,
    hide,
    isLojaPaga,
    excluir,
    isNew
  } = props

  const deveSelecionarVeiculo = props.negociacaoForm.values.veiculosConjunto.length > 0

  useEffect(() => {
    const onLoad = async () => {
      form.setFieldValue('veiculo', props.veiculo)

      if (isNew) {
        form.setFieldValue('isLojaPaga', isLojaPaga)
      }
    }
    onLoad()
  }, [])

  const [sugestaoVeiculos, setSugestaoVeiculos] = useState([])

  const VeiculoTemplate = (veiculo) => (
    <span><b>{formatPlaca(veiculo.placa_vei) || veiculo.chassi_vei}</b> - {veiculo.produto?.descri_pro}</span>
  )

  const sugerirVeiculo = async () => {
    try {
      const veiculoService = new VeiculoService()
      const veiculos = await veiculoService.filterAutoComplete(`placa_vei=${form.values.veiculoTroca.placha_vei}`)

      if (veiculos.length <= 0) {
        showWarnMessage('Veículo não encontrado!')
      }

      setSugestaoVeiculos(veiculos)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um veículo!')
    }
  }

  const onSelectVeiculo = async (e) => {
    form.setFieldValue('veiculoTroca', e.value)
    form.setFieldValue('veitro_fpn', e.value.codigo_vei)
    form.validateField('veiculoTroca.codigo_vei')
  }

  const handleChangeVeiculo = (e) => {
    if (!!(e.target.value)) {
      form.setFieldValue('veiculoTroca.placha_vei', e.target.value)
      form.validateField('veiculoTroca.codigo_vei')
    } else {
      form.setFieldValue('veiculoTroca', {})
      form.setFieldValue('veitro_fpn', null)
    }
  }

  function onSaveModalVeiculo (veiculo) {
    onSelectVeiculo({ value: veiculo })
  }

  const { getFormErrorMessage, isFormFieldValid } = useValidateInput(form)

  return (
    <Modal
      header="Veículo na troca"
      btnSalvar={edicaoHabilitada ? form.submitForm : undefined}
      btnExcluir={(!isNew && edicaoHabilitada) ? excluir : undefined}
      width="40"
      visible={true}
      modal={true}
      onHide={hide}
    >
      <div className="formgrid grid">
        <div className="field col-12 flex justify-content-around mb-4">
          <ResponsavelPagamento isLojaPaga={isNew ? isLojaPaga : form.values.isLojaPaga} />
        </div>
        <div className="field col-12" style={{ position: 'relative' }}>
          <SearchInput
            field="placha_vei"
            placeholder="Veículo de troca"
            value={form.values.veiculoTroca?.placha_vei ? `${form.values.veiculoTroca?.placha_vei} - ${form.values.veiculoTroca?.modelo?.descri_mod}/${form.values.veiculoTroca?.modelo?.marca?.descri_mar}` : ''}
            suggestions={sugestaoVeiculos}
            completeMethod={sugerirVeiculo}
            onChange={handleChangeVeiculo}
            onSelect={async (e) => { await onSelectVeiculo(e); form.setFieldTouched('veiculoTroca.codigo_vei') }}
            disabled={!edicaoHabilitada}
            className={classNames({ 'p-invalid': isFormFieldValid('veiculoTroca.codigo_vei') }, 'inputfield w-full')}
            onBlur={() => form.setFieldTouched('veiculoTroca.codigo_vei')}
            itemTemplate={VeiculoTemplate}
            disabledAutoComplete={true}
            label="Veículo para troca"
            //RegisterModal
            selected={form.values.veiculoTroca}
            onSaveModal={onSaveModalVeiculo}
            onDeleteModal={async () => await onSelectVeiculo({})}
            modalHeader={form.values.veiculoTroca.codigo_vei ? `Veículo - ${form.values.veiculoTroca.codigo_vei}` : 'Cadastro de veículo'}
            ComponenteCadastro={Veiculo}
            // FiltroModal
            filtrotitle="Pesquisa de veículo"
            service={VeiculoService}
            model={getVeiculoDTO}
            primarykeyname="codigo_vei"
            columns={[
              { campo: 'codigo_vei', nome: 'Código' },
              { campo: 'placha_vei', nome: 'Veículo' },
              { campo: 'modelo.descri_mod', nome: 'Modelo' },
              { campo: 'modelo.marca.descri_mar', nome: 'Marca' }
            ]}
          />
          <FieldErrorMessage message={getFormErrorMessage('veiculoTroca.codigo_vei')} />
        </div>
        <div className="field col-12">
          <TextAreaInput
            name="observacoes"
            label="Situação do veículo (Multas, financiamento, entre outros)"
            value={form.values.observacoes}
            disabled={!edicaoHabilitada}
            onChange={form.handleChange}
            className="inputfield w-full" />
        </div>
        <div className="field col-12 md:col-6">
          <RequiredLabel label="Valor" valid={isFormFieldValid('valor')} />
          <TextInput
            name="valor"
            value={formatToCurrency(form.values.valor)}
            disabled={!edicaoHabilitada}
            onChange={handleChangeCurrency}
            onBlur={() => { form.setFieldTouched('valor'); setValorVeiculo() }}
            className={classNames({ 'p-invalid': isFormFieldValid('valor') }, 'inputfield w-full')} />
          <FieldErrorMessage message={getFormErrorMessage('valor')} />
        </div>
        {deveSelecionarVeiculo && (
          <div className="field col-12 md:col-6">
            <SelectInput
              value={form.values.veiculo}
              options={props.negociacaoForm.values.veiculosConjunto.map(veiculoNve => veiculoNve.veiculo)}
              onChange={(e) => form.setFieldValue('veiculo', e.target.value)}
              label="Veículo"
              optionLabel="placha"
              dataKey="codigo_vei"
              disabled={!edicaoHabilitada}
              className="inputfield w-full"
              noFloatLabel
            />
          </div>
        )}
      </div>
    </Modal>
  )
}

export default (props) => FoPagNeg({
  Component: FoPagVeiculoTroca,
  validationSchema: VeiculoTrocaValidator,
  ...props
})
