import React from 'react'

import CommonHelper from '../../helpers/CommonHelper'
import Page from '../../components/layout/Page'
import { Column } from 'primereact/column'
import SelectInput from '../../components/inputs/SelectInput'
import DataList from '../../components/layout/DataList'
import List from '../../classes/List'
import { ModeloDeParaModel } from '../../models/cadastro/veiculo/ModeloDeParaModel'
import ModeloDeParaService from '../../services/Integracao/ModeloDeParaService'
import ModeloAutoGestorService from '../../services/Integracao/autogestor/ModeloAutoGestorService'
import CheckboxInput from '../../components/inputs/CheckboxInput'
import AppButton from '../../components/layout/AppButton'

export class DeParaModeloForm extends List {
  constructor (props) {
    super(props, ModeloDeParaModel, ModeloDeParaService, 'modelodepara', 'codigo_mdd')

    this.state = {
      ...this.state,
      deParaModelos: [],
      modelosAG: [],
      somentePendentes: false
    }
  }

  async componentDidMount () {
    await super.componentDidMount()
    await this.buscaModelosAG()
  }

  async buscaModelosAG () {
    const modelosAG = await ModeloAutoGestorService.getAllModelosAG()
    this.setState({ modelosAG: modelosAG })
  }

  handleChangeModeloAG = async (key, props, value) => {
    const modelo = value
    props.rowData.codmodaug_mdd = modelo
    props.rowData.codmodtur_mdd = props.rowData.codigo_mod
    await ModeloDeParaService.save(props.rowData)
    let updatedModelosAG = [...props.value]
    updatedModelosAG[props.rowIndex][props.field] = value
    this.setState({ [`${key}`]: updatedModelosAG })
    await this.onFilter()
  }

  onBlurModeloAG = async (codMarcaAG) => {
    const modelosAG = await ModeloAutoGestorService.getAllModelosAGByMarca(codMarcaAG)
    this.setState({ modelosAG: modelosAG })
  }

  modeloAutoGestorSearch = (key, props) => {
    const { modelosAG } = this.state
    return (
      <SelectInput
        value={props.rowData['codmodaug_mdd']}
        options={modelosAG}
        onBlur={(e) => this.onBlurModeloAG(props.rowData['codmaraug_mdp'])}
        onChange={(e) => this.handleChangeModeloAG(key, props, e.value)}
        dataKey="codigo_mda"
        name="modeloAG"
        filter={CommonHelper.isDesktop()}
        optionLabel="descri_mda"
        optionValue="codigo_mda"
        noFloatLabel
        className={'inputfield w-full'}
      />
    )
  }

  statusBodyTemplate = (rowData) => {
    return rowData.descri_mda
  }

  onFilterClick = () => {
    const filter = this.state.filter
    filter.somentePendentes = this.state.somentePendentes
    this.setState({ filter })
    this.onFilter()
  }

  expandedFiltersTemplate = () => (
    <>
      <div className="field col-12 md:col-3">
        <CheckboxInput
          inputId="chkSomentePendente"
          value={this.state.somentePendentes}
          label="Apenas não configurados"
          checked={this.state.somentePendentes}
          onChange={(e) => this.setState({ somentePendentes: !e.target.value })} />
      </div>
      <div className="col-12 flex justify-content-end mb-4">
        <AppButton label="Buscar" icon="pi pi-search" onClick={this.onFilterClick} />
      </div>
    </>
  )

  render () {
    const { rows, totalRecords, first } = this.state

    return (

      <Page>
        <DataList
          data={this.state.list}
          selected={this.state.selected}
          editMode="cell"
          className="datalist p-datatable-responsive"
          rows={rows}
          totalRecords={totalRecords}
          first={first}
          onPage={this.onPageChange}
          page={this.state.page}
          showFilter={true}
          filterPlaceholder="Procurar Modelos"
          filterName="descricaoFiltro"
          responsive={true}
          filterOnChange={this.handleChangeFilter}
          onFilter={this.onFilter}
          expandedFiltersTemplate={this.expandedFiltersTemplate}
        >
          <Column field="descri_mar" header="Marca" style={{ width: '20%', textAlign: 'left' }} ></Column>
          <Column field="descri_mod" header="Modelo" style={{ width: '20%', textAlign: 'left' }} ></Column>
          <Column field="codmodaug_mdd" header="Modelo Auto Gestor" style={{ width: '72%', textAlign: 'left' }} body={this.statusBodyTemplate} editor={(props) => this.modeloAutoGestorSearch('list', props)}></Column>
        </DataList>

      </Page>
    )
  }
}
export default DeParaModeloForm
