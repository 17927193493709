import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import NumberInput from '../../../../components/inputs/NumberInput'
import TextInput from '../../../../components/inputs/TextInput'
import { showErrorMessage } from '../../../../components/utils/Message'
import CommonHelper from '../../../../helpers/CommonHelper'
import { formatCurrencyToNumber, formatToCurrency } from '../../../../helpers/formaters'
import { startLoading, stopLoading } from '../../../../redux/actions'
import DocumentosService from '../../../../services/cadastro/documento/DocumentosService'
import ManutencaoService from '../../../../services/cadastro/manutencao/ManutencaoService'
import ProdutoEmpresaService from '../../../../services/cadastro/produto/ProdutoEmpresaService'
import VeiculoService from '../../../../services/cadastro/veiculo/VeiculoService'
import ConfigService from '../../../../services/configuracao/ConfigService'
import NegociacaoVeiculoService from '../../../../services/vendas/negociacao/NegociacaoVeiculoService'

const CustoVeiculoConjunto = (props) => {
  const dispatch = useDispatch()
  const [state, setState] = useState({
    veiculo: {},
    sugestaoVeiculos: [],
    descricaoVeiculo: '',
    collapsedPanelCustos: CommonHelper.isDesktop() ? false : true,
    collapsedPanelVenda: CommonHelper.isDesktop() ? false : true,
    totalCusto: 0,
    custos: {
      valorCompra: 0,
      jurosEstoque: 0,
      rateioDespesas: 0,
      valorManutencoes: 0,
      valorDocumentacao: 0,
      comissaoVendedor: 0,
      outrosCustos: 0,
      totalCustos: 0
    },
    venda: {
      preco: 0,
      valorFinanciado: 0,
      retorno: 0,
      outrasReceitas: 0,
      totalVenda: 0,
      lucroReaisVenda: 0,
      lucroPorcentagemVenda: 0,
      valorMinimo: 0,
      lucroReaisMinimo: 0,
      lucroPorcentagemMinimo: 0,
      valorEcommerce: 0,
      lucroReaisEcommerce: 0,
      lucroPorcentagemEcommerce: 0
    }
  })

  useEffect(() => {
    const { codvei } = props
    if (codvei) {
      setDadosVeiculo(codvei)
    }
  }, [props.codvei])

  useEffect(() => {
    if (state.veiculo.codigo_vei) {
      if (veiculo.codigo_vei !== state.veiculo.codigo_vei) {
        setDadosVeiculo(state.veiculo.codigo_vei)
      }
    }
  }, [state.veiculo, state.veiculo.codigo_vei, veiculo])

  const setDadosVeiculo = async (codigo_vei) => {
    try {
      dispatch(startLoading())
      const veiculoService = new VeiculoService()
      veiculoService.get(codigo_vei).then((veiculo) => setState((prevState) => ({ ...prevState, veiculo })))

      const [
        produtoEmpresa,
        valorManutencao,
        custosDocumentacao,
        ultimaCompra,
        diasEmEstoque,
        taxaRateio,
        taxaJurosEstoque,
        responseVeiculo
      ] = await Promise.all([
        ProdutoEmpresaService.getByVeiculoId(codigo_vei, false),
        ManutencaoService.getValorTotalManutencaoDaUltimaNegociacao(codigo_vei, false),
        DocumentosService.getCustosDaUltimaNegociacaoPorVeiculo(codigo_vei, false),
        NegociacaoVeiculoService.getUltimaCompraVeiculo(codigo_vei, false),
        VeiculoService.getDiasEmEstoque(codigo_vei, false),
        ConfigService.getValor(40, false),
        ConfigService.getValor(1028, false),
        VeiculoService.getDescricaoCompleta(codigo_vei, false)
      ])

      setState((prevState) => ({
        ...prevState,
        descricaoVeiculo: responseVeiculo.descricaoVeiculo,
        custos: {
          valorCompra: ultimaCompra?.valor_nve || 0,
          jurosEstoque: Number((ultimaCompra?.valor_nve || 0) * diasEmEstoque * (taxaJurosEstoque / 100).toFixed(5)),
          rateioDespesas: Number(ultimaCompra?.valor_nve * (Number(taxaRateio) / 100)),
          valorManutencoes: valorManutencao || 0,
          valorDocumentacao: custosDocumentacao || 0,
          comissaoVendedor: 0,
          outrosCustos: 0,
          totalCustos: 0
        },
        venda: {
          preco: produtoEmpresa.preco_pre || 0,
          valorFinanciado: 0,
          retorno: 0,
          outrasReceitas: veiculo?.custos || 0,
          totalVenda: 0,
          valorMinimo: produtoEmpresa.premin_pre,
          lucroReaisMinimo: 0,
          lucroPorcentagemMinimo: 0,
          valorEcommerce: produtoEmpresa.preeco_pre
        },
        dadosVeiculo: JSON.parse(localStorage.getItem('dadosVeiculo'))
      }))

      dispatch(stopLoading())
    } catch (error) {
      dispatch(stopLoading())
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar buscar os dados do veículo!')
    }
  }
  const retornaCustoParaPai = () => {
    let totalCustos =
    custos.valorCompra +
    custos.valorDocumentacao +
    custos.valorManutencoes +
    custos.comissaoVendedor +
    custos.rateioDespesas +
    custos.jurosEstoque +
    custos.valorCompra * (custos.outrosCustos / 100)
    return { total: totalCustos, codigoVei: props.codvei }
  }

  const handleChangeCurrencyCustos = (event) => {
    const { custos } = state
    custos[event.target.name] = formatCurrencyToNumber(event.target.value)

    setState((prevState) => ({ ...prevState, custos }))
  }

  const lostFocusAtualizarPreco = () => {
    let totalCusto = retornaCustoParaPai()
    props.atualizarCusto(totalCusto)
  }

  const handleChangeOutrosCustos = (event) => {
    const { custos } = state
    custos.outrosCustos = event.value
    setState((prevState) => ({ ...prevState, custos }))
  }

  const { veiculo, custos } = state
  let totalCustos =
    custos.valorCompra +
    custos.valorDocumentacao +
    custos.valorManutencoes +
    custos.comissaoVendedor +
    custos.rateioDespesas +
    custos.jurosEstoque +
    custos.valorCompra * (custos.outrosCustos / 100)

  return (
    <>

      <div className="formgrid grid">
        <div className="field col-12 md:col-3 sm:col-4">
          <TextInput
            label="Valor compra"
            placeholder="Valor compra"
            className="inputfield w-full"
            value={formatToCurrency(custos.valorCompra)}
            disabled={true}
          />
        </div>
        <div className="field col-12 md:col-3 sm:col-4">
          <TextInput
            label="Juros de estoque"
            placeholder="Juros de estoque"
            className="inputfield w-full"
            name="jurosEstoque"
            value={formatToCurrency(custos.jurosEstoque.toFixed(2))}
            onChange={handleChangeCurrencyCustos}
            onBlur={() => lostFocusAtualizarPreco()}
          />
        </div>
        <div className="field col-12 md:col-3 sm:col-4">
          <TextInput
            label="Rateio de despesas"
            placeholder="Rateio de despesas"
            className="inputfield w-full"
            value={formatToCurrency(custos.rateioDespesas.toFixed(2))}
            disabled
          />
        </div>
        <div className="field col-12 md:col-3 sm:col-4">
          <TextInput
            label="Manutenções"
            placeholder="Manutenções"
            className="inputfield w-full"
            value={formatToCurrency(custos.valorManutencoes)}
            disabled
          />
        </div>
        <div className="field col-12 md:col-3 sm:col-4">
          <TextInput
            label="Documentação"
            placeholder="Documentação"
            className="inputfield w-full"
            name='valorDocumentacao'
            onChange={ handleChangeCurrencyCustos}
            value={formatToCurrency(custos.valorDocumentacao)}
            onBlur={() => lostFocusAtualizarPreco()}
          />
        </div>
        <div className="field col-12 md:col-3 sm:col-4">
          <TextInput
            label="Comissão Vendedor"
            placeholder="Comissão Vendedor"
            className="inputfield w-full"
            name='comissaoVendedor'
            onChange={handleChangeCurrencyCustos}
            onBlur={() => lostFocusAtualizarPreco()}
            value={formatToCurrency(custos.comissaoVendedor)}
          />
        </div>
        <div className="field col-12 md:col-3 sm:col-4">
          <NumberInput
            label="Outros custos (%)"
            placeholder="Outros custos (%)"
            className="inputfield w-full"
            name='outrosCustos'
            onChange={handleChangeOutrosCustos}
            onBlur={() => lostFocusAtualizarPreco()}
            value={custos.outrosCustos}
          />
        </div>
        <div className="field col-12 md:col-3 sm:col-4">
          <TextInput
            label={<b>Total de custos</b>}
            placeholder="Total de custos"
            className="inputfield w-full"
            value={formatToCurrency(Number(totalCustos))}
            disabled
          />
        </div>
      </div>

    </>
  )
}

export default CustoVeiculoConjunto
