import React, { useEffect, useState } from 'react'
import AppButton from '../../../../components/layout/AppButton'
import { baseForm } from '../../../../components/utils/BaseForm'
import { showErrorMessage, showSuccessMessage } from '../../../../components/utils/Message'
import Modal from '../../../../components/utils/Modal'
import { getNegociacaoDTO } from '../../../../dtos/vendas/negociacao/NegociacaoDTO'
import PagamentoNeg from '../../../../forms/vendas/negociacao/negociacao/NegociacaoPages/PagamentoNeg'
import NegociacaoService from '../../../../services/vendas/negociacao/NegociacaoService'

const AlteracaoFoPagNegModal = ({ visible, hide, negociacao }) => {
  const [totalValorDocumentos, setTotalValorDocumentos] = useState(0)

  const form = baseForm({
    initialValues: getNegociacaoDTO(negociacao)
  })

  useEffect(() => {
    if (!negociacao) return

    form.setValues(getNegociacaoDTO(negociacao))

    let valorTotal = 0.00

    if (negociacao.documents) {
      const negDocumentos = [...negociacao.documents]

      for (const documento of negDocumentos) {
        if (documento.respag_ndo !== 1) continue

        valorTotal += documento.valor_ndo
      }
    }

    setTotalValorDocumentos(valorTotal)
  }, [negociacao])

  const handleAlteracaoFoPagNeg = async () => {
    try {
      await NegociacaoService.alterarFoPagNeg(form.values.codigo_neg, form.values.foPagNegs)
      hide()
      showSuccessMessage('Pagamentos alterados com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Erro ao alterar pagamentos da negociação')
    }
  }

  return (
    <Modal
      header="Alterar pagamento"
      visible={visible}
      onHide={hide}
      width="70"
    >
      <PagamentoNeg
        form={form}
        edicaoHabilitada={true}
        operacaoNeg={form.values.operacaoNeg}
        totalValorDocumentos={totalValorDocumentos}
      />
      <div className="p-dialog-buttons">
        <div className="pr-4">
          <AppButton
            label="Finalizar"
            icon="pi pi-check"
            onClick={() => handleAlteracaoFoPagNeg()}
          />
        </div>
      </div>
    </Modal>
  )
}

export default AlteracaoFoPagNegModal
