export const getProdutoDTO = (data = {}) => {
  return {
    codigo_pro: data.codigo_pro || null,
    descri_pro: data.descri_pro || null
  }
}

export const postProdutoDTO = (data) => {
  return {
    codigo_pro: data.codigo_pro || null,
    descri_pro: data.descri_pro || null
  }
}
