import { Button } from 'primereact/button'
import { Fieldset } from 'primereact/fieldset'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import React, { Component } from 'react'
import NumberInput from '../../../../components/inputs/NumberInput'
import TextInput from '../../../../components/inputs/TextInput'
import SearchInput from '../../../../components/inputs/searchInput/SearchInput'
import AccordionContainer from '../../../../components/layout/AccordionContainer'
import AccordionPage from '../../../../components/layout/AccordionPage'
import AppButton from '../../../../components/layout/AppButton'
import Container from '../../../../components/layout/Container'
import TabMenuContainer from '../../../../components/layout/TabMenuContainer/TabMenuContainer'
import TabMenuPage from '../../../../components/layout/TabMenuContainer/TabMenuPage'
import { showErrorMessage, showSuccessMessage, showWarnMessage } from '../../../../components/utils/Message'
import VeiculoTemplate from '../../../../components/utils/VeiculoTemplate'
import { getVeiculoDTO } from '../../../../dtos/cadastro/veiculo/VeiculoDTO'
import CommonHelper from '../../../../helpers/CommonHelper'
import { formatCurrencyToNumber, formatPlaca, formatToCurrency } from '../../../../helpers/formaters'
import { startLoading, stopLoading } from '../../../../redux/actions'
import { dispatch } from '../../../../redux/store'
import ProdutoEmpresaService from '../../../../services/cadastro/produto/ProdutoEmpresaService'
import VeiculoService from '../../../../services/cadastro/veiculo/VeiculoService'
import { venderVeiculo } from '../../persistencia/VenderVeiculo'
import CustoVeiculoConjunto from './CustoVeiculoConjunto'
import ValorVendaVeiculoConjunto from './ValorVendaVeiculoConjunto'

export default class SimulacaoVenda extends Component {
  constructor (props) {
    super(props)
    this.state = {
      ...this.state,
      veiculo: {},
      sugestaoVeiculos: [],
      descricaoVeiculo: '',
      collapsedPanelCustos: CommonHelper.isDesktop() ? false : true,
      collapsedPanelVenda: CommonHelper.isDesktop() ? false : true,
      totalCustoVeiculosConjunto: [],
      custos: {
        valorCompra: 0,
        jurosEstoque: 0,
        rateioDespesas: 0,
        valorManutencoes: 0,
        valorDocumentacao: 0,
        comissaoVendedor: 0,
        outrosCustos: 0,
        totalCustos: 0
      },
      venda: {
        preco: 0,
        valorFinanciado: 0,
        retorno: 0,
        outrasReceitas: 0,
        totalVenda: 0,
        lucroReaisVenda: 0,
        lucroPorcentagemVenda: 0,
        valorMinimo: 0,
        lucroReaisMinimo: 0,
        lucroPorcentagemMinimo: 0,
        valorEcommerce: 0,
        lucroReaisEcommerce: 0,
        lucroPorcentagemEcommerce: 0
      },
      abrirPrecificacao: this.props.match?.url.includes('precificar'),
      isConjunto: false,
      scrollExecutado: false,
      veiculosConjuntoInfo: [],
      descricaoVeiculoConjunto: [],
      custoConjunto: 0,
      totalVendaConjunto: 0,
      documentoVeiculo: 0
    }
    this.atualizarCusto = this.atualizarCusto.bind(this)
  }

  componentDidMount () {
    const codigo_vei = this.props.match.params.id
    if (codigo_vei) {
      this.setDadosVeiculo(codigo_vei)
      this.setState({ isConjunto: !this.state.veiculo?.itemConjuntoVeiculo })
    }
  }

  async setDadosVeiculo (codigo_vei) {
    try {
      dispatch(startLoading())
      const veiculoService = new VeiculoService()
      const veiculo = await veiculoService.getInformacoesSimulacao(codigo_vei)
      this.setState({
        veiculo: veiculo,
        descricaoVeiculo: veiculo?.descricaoCompleta,
        custos: {
          valorCompra: veiculo?.ultimaCompra?.valor_nve || 0,
          jurosEstoque: Number((veiculo?.ultimaCompra?.valor_nve || 0) * (veiculo?.diasEmEstoque * (veiculo?.taxaJurosEstoque / 100).toFixed(5))),
          rateioDespesas: Number(veiculo?.ultimaCompra?.valor_nve * (Number(veiculo?.taxaRateio) / 100)),
          valorManutencoes: veiculo?.valorManutencao || 0,
          valorDocumentacao: veiculo?.custosDocumentacao || 0,
          comissaoVendedor: 0,
          outrosCustos: 0,
          totalCustos: 0
        },
        venda: {
          preco: veiculo?.preco_pre || 0,
          valorFinanciado: 0,
          retorno: 0,
          outrasReceitas: veiculo?.custos,
          totalVenda: 0,
          valorMinimo: veiculo?.premin_pre,
          lucroReaisMinimo: 0,
          lucroPorcentagemMinimo: 0,
          valorEcommerce: veiculo?.preeco_pre
        }
      }, dispatch(stopLoading()))
      if (this.state.abrirPrecificacao && !this.state.scrollExecutado) {
        window.scroll({
          top: document.body.scrollHeight,
          behavior: 'smooth'
        })
        this.setState({ scrollExecutado: true })
      }
      if (this.state.veiculo?.itemConjuntoVeiculo !== null && this.state.veiculo?.itemConjuntoVeiculo?.conjuntoVeiculo?.itensConjunto.length > 1) {
        this.setState({ isConjunto: true })
        this.informacaoVeiculoConjunto(this.state.veiculo.itemConjuntoVeiculo?.conjuntoVeiculo?.itensConjunto)
      } else {
        this.setState({ isConjunto: false })
      }
    } catch (error) {
      dispatch(stopLoading())
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar buscar os dados do veículo!')
    }
  }

  async informacaoVeiculoConjunto (veiculosConjunto) {
    try {
      const descricaoPromises = veiculosConjunto?.map(async (veiculo) => {
        const veiculoService = new VeiculoService()
        const veiculos = await veiculoService.getInformacoesSimulacao(veiculo.codvei_icj)
        let informacaoVeiculosConjunto = {
          veiculosDoConjunto: veiculos,
          descricaoVeiculo: veiculos?.descricaoCompleta,
          custosVeiculo: {
            valorCompra: veiculos?.ultimaCompra?.valor_nve || 0,
            jurosEstoque: Number((veiculos?.ultimaCompra?.valor_nve || 0) * (veiculos?.diasEmEstoque * veiculos?.taxaJurosEstoque / 100)),
            rateioDespesas: Number(veiculos.ultimaCompra?.valor_nve * (Number(veiculos?.taxaRateio) / 100)),
            valorManutencoes: veiculos?.valorManutencao || 0,
            valorDocumentacao: veiculos?.custosDocumentacao || 0,
            comissaoVendedor: 0,
            outrosCustos: 0,
            totalCustos: 0
          },
          vendaVeiculo: {
            preco: veiculos?.preco_pre || 0,
            valorFinanciado: 0,
            retorno: 0,
            outrasReceitas: veiculos?.custos || 0,
            totalVenda: 0,
            valorMinimo: veiculos?.premin_pre,
            lucroReaisMinimo: 0,
            lucroPorcentagemMinimo: 0,
            valorEcommerce: veiculos?.preeco_pre
          }
        }
        return informacaoVeiculosConjunto
      })

      const conjuntoVeiculo = await Promise.all(descricaoPromises)
      this.descricaoVeiculo(conjuntoVeiculo)
      this.custoConjunto(conjuntoVeiculo)
      this.valorVendaConjunto(conjuntoVeiculo)
      this.setState({ veiculosConjuntoInfo: conjuntoVeiculo })
    } catch (error) {
      console.error('Erro ao obter descrições de veículos:', error)
    }
  }

  async descricaoVeiculo (veiculoConjunto) {
    const descricoes = veiculoConjunto?.map((descricao, index) => {
      if (descricao && descricao.descricaoVeiculo) {
        return descricao.descricaoVeiculo
      }
      return null
    })
    this.setState({ descricaoVeiculoConjunto: descricoes })
  }

  atualizarCusto (totalDoCustoVeiculo) {
    let { totalCustoVeiculosConjunto } = this.state
    let posicao = totalCustoVeiculosConjunto.findIndex(obj => obj.codigoVei === totalDoCustoVeiculo.codigoVei)
    if (posicao < 0) {
      totalCustoVeiculosConjunto.push(totalDoCustoVeiculo)
    } else {
      totalCustoVeiculosConjunto.splice(posicao, 1, totalDoCustoVeiculo)
    }
    this.setState({ totalCustoVeiculosConjunto })
  }

  async custoConjunto (conjuntoVeiculo) {
    let valorCompra = 0
    let jurosEstoque = 0
    let rateioDespesas = 0
    let valorManutencoes = 0
    let valorDocumentacao = 0
    let comissaoVendedor = 0
    let outrosCustos = 0
    let totalCustos = 0
    conjuntoVeiculo.forEach((custos) => {
      valorCompra += custos.custosVeiculo.valorCompra
      totalCustos +=
        custos.custosVeiculo.valorCompra +
        custos.custosVeiculo.valorDocumentacao +
        custos.custosVeiculo.valorManutencoes +
        custos.custosVeiculo.comissaoVendedor +
        custos.custosVeiculo.rateioDespesas +
        custos.custosVeiculo.jurosEstoque +
        (custos.custosVeiculo.valorCompra * custos.custosVeiculo.outrosCustos / 100)
      valorDocumentacao += custos.custosVeiculo.valorDocumentacao
      valorManutencoes += custos.custosVeiculo.valorManutencoes
      comissaoVendedor += custos.custosVeiculo.comissaoVendedor
      rateioDespesas += custos.custosVeiculo.rateioDespesas
      jurosEstoque += custos.custosVeiculo.jurosEstoque
      outrosCustos += (custos.custosVeiculo.outrosCustos / 100)
    })
    this.setState({
      custos: {
        valorCompra: valorCompra || 0,
        jurosEstoque: jurosEstoque,
        rateioDespesas: rateioDespesas,
        valorManutencoes: valorManutencoes || 0,
        valorDocumentacao: valorDocumentacao || 0,
        comissaoVendedor: comissaoVendedor,
        outrosCustos: outrosCustos,
        totalCustos: totalCustos
      }
    })
  }

  async valorVendaConjunto (conjuntoVeiculo) {
    let preco = 0
    let financiamento = 0
    let retorno = 0
    let outrasReceita = 0
    let totalVenda = 0
    let valorMinimo = 0
    let valorEcommerce = 0
    conjuntoVeiculo?.forEach((venda) => {
      preco += venda.vendaVeiculo.preco
      financiamento += venda.vendaVeiculo.valorFinanciado
      retorno += venda.vendaVeiculo.retorno
      outrasReceita += venda.vendaVeiculo.outrasReceitas
      totalVenda += venda.vendaVeiculo.preco + (venda.vendaVeiculo.valorFinanciado * (venda.vendaVeiculo.retorno / 100)) + venda.vendaVeiculo.outrasReceitas
      valorMinimo += venda?.veiculosDoConjunto?.premin_pre
      valorEcommerce += venda.vendaVeiculo.valorEcommerce + (venda.vendaVeiculo.valorFinanciado * (venda.vendaVeiculo.retorno / 100)) + venda.vendaVeiculo.outrasReceitas
    })
    this.setState({

      venda: {
        preco: preco || 0,
        valorFinanciado: financiamento,
        retorno: retorno,
        outrasReceitas: outrasReceita,
        totalVenda: totalVenda,
        valorMinimo: valorMinimo,
        lucroReaisMinimo: valorMinimo,
        lucroPorcentagemMinimo: 0,
        valorEcommerce: valorEcommerce
      }
    })
  }

  handleChangeCustos = (event) => {
    const { custos } = this.state

    custos[event.target.name] = event.target.value
    this.setState({ custos })
  }

  handleChangeCurrencyCustos = (event) => {
    const { custos } = this.state
    custos[event.target.name] = formatCurrencyToNumber(event.target.value)
    this.setState({ custos })
  }

  handleChangeVenda = (event) => {
    const { venda } = this.state

    venda[event.target.name] = event.target.value
    this.setState({ venda })
  }

  handleChangeCurrencyVenda = (event) => {
    const { venda } = this.state
    let totalVendaConjunto = event.target.name === 'preco' ? formatCurrencyToNumber(event.target.value) : this.state.totalVendaConjunto
    let totalValorEcommerceConjunto = event.target.name === 'valorEcommerce' ? formatCurrencyToNumber(event.target.value) : this.state.totalValorEcommerceConjunto
    let totalValorMinimoConjunto = event.target.name === 'valorMinimo' ? formatCurrencyToNumber(event.target.value) : this.state.totalValorMinimoConjunto
    let totalVendaEcommerce = event.target.name === 'valorEcommerce' ? formatCurrencyToNumber(event.target.value) : this.state.valorEcommerce
    venda[event.target.name] = formatCurrencyToNumber(event.target.value)
    this.setState({
      venda,
      totalVendaConjunto: totalVendaConjunto,
      totalValorMinimoConjunto: totalValorMinimoConjunto,
      totalValorEcommerceConjunto: totalValorEcommerceConjunto,
      valorEcommerce: totalVendaEcommerce
    })
  }

  handleChangeOutrosCustos = (event) => {
    const { custos } = this.state
    custos.outrosCustos = event.value
    this.setState({ custos })
  }

  handleChangeRetorno = (event) => {
    const { venda } = this.state
    venda.retorno = event.value
    this.setState({ venda })
  }

  gravarPrecoVenda = async () => {
    try {
      await ProdutoEmpresaService.alterarPrecoVeiculo(this.state.veiculo.codigo_vei, { preco_pre: this.state.venda.preco })
      this.setDadosVeiculo(this.state.veiculo.codigo_vei)
      showSuccessMessage('Preço alterado com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar gravar o preço!')
    }
  }

  gravarPrecoMinimo = async () => {
    try {
      await ProdutoEmpresaService.alterarPrecoVeiculo(this.state.veiculo.codigo_vei, { premin_pre: this.state.venda.valorMinimo })
      showSuccessMessage('Preço mínimo alterado com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar gravar o preço mínimo!')
    }
  }

  gravarPrecoEcommerce = async () => {
    try {
      await ProdutoEmpresaService.alterarPrecoVeiculo(this.state.veiculo.codigo_vei, { preeco_pre: this.state.venda.valorEcommerce })
      showSuccessMessage('Preço de e-commerce alterado com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar gravar o preço de e-commerce!')
    }
  }

  async handleChangeVeiculo (e) {
    this.setState({ veiculo: { placa_vei: e.target.value } })
  }

  sugerirVeiculos = async () => {
    try {
      const veiculoService = new VeiculoService()
      const veiculos = await veiculoService.filterAutoComplete(`placa_vei=${this.state.veiculo?.placa_vei}`)

      if (veiculos.length <= 0) {
        showWarnMessage('Veículo não encontrado!')
      }

      this.setState({ sugestaoVeiculos: veiculos })
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um veículo!')
    }
  }

  render () {
    const {
      veiculo, custos, venda, isConjunto, veiculosConjuntoInfo,
      documentoVeiculo
    } = this.state
    const totalCustos = custos.valorCompra +
      custos.valorDocumentacao +
      custos.valorManutencoes +
      custos.comissaoVendedor +
      custos.rateioDespesas +
      custos.jurosEstoque + documentoVeiculo +
      (custos.valorCompra * custos.outrosCustos / 100)

    const totalVenda = venda.preco + (venda.valorFinanciado * (venda.retorno / 100)) + venda.outrasReceitas

    const lucroVendaReais = totalVenda - totalCustos
    let lucroVendaPercentual = totalVenda === 0 ? -100 : (lucroVendaReais / totalVenda) * 100
    lucroVendaPercentual < -100 ? lucroVendaPercentual = -100 : lucroVendaPercentual = lucroVendaPercentual

    const totalVendaMinimo = venda.valorMinimo + (venda.valorFinanciado * (venda.retorno / 100)) + venda.outrasReceitas
    const lucroVendaReaisMinimo = totalVendaMinimo - totalCustos
    let lucroVendaPercentualMinimo = totalVendaMinimo === 0 ? -100 : (lucroVendaReaisMinimo / totalVendaMinimo) * 100
    lucroVendaPercentualMinimo < -100 ? lucroVendaPercentualMinimo = -100 : lucroVendaPercentualMinimo = lucroVendaPercentualMinimo

    const totalVendaEcommerce = venda.valorEcommerce + (venda.valorFinanciado * (venda.retorno / 100)) + venda.outrasReceitas
    const lucroVendaReaisEcommerce = totalVendaEcommerce - totalCustos
    let lucroVendaPercentualEcommerce = totalVendaEcommerce === 0 ? -100 : (lucroVendaReaisEcommerce / totalVendaEcommerce) * 100
    lucroVendaPercentualEcommerce < -100 ? lucroVendaPercentualEcommerce = -100 : lucroVendaPercentualEcommerce = lucroVendaPercentualEcommerce
    return (
      <>
        <Container col="12">
          <h1>Simulação de venda e precificação</h1>
        </Container>
        <div className="grid">
          <AccordionContainer>
            <AccordionPage header="Informações do veículo" active>
              <div className="formgrid grid">
                <div className="field col-12" style={{ position: 'relative' }}>
                  <SearchInput
                    //AutoComplete
                    field="placa_vei"
                    label="Veículo"
                    value={(veiculo && veiculo?.placa_vei) || veiculo?.chassi_vei}
                    placeholder="Informe a placa do veículo"
                    onChange={e => this.handleChangeVeiculo(e, veiculo)}
                    onSelect={async (e) => await this.setDadosVeiculo(e.value.codigo_vei)}
                    className="inputfield w-full"
                    suggestions={this.state.sugestaoVeiculos}
                    completeMethod={this.sugerirVeiculos}
                    itemTemplate={VeiculoTemplate}
                    // FiltroModal
                    filtrotitle="Pesquisa de veículo"
                    service={VeiculoService}
                    model={getVeiculoDTO}
                    primarykeyname="codigo_vei"
                    columns={[
                      { campo: 'codigo_vei', nome: 'Código' },
                      { campo: 'placa_vei', nome: 'Placa', format: formatPlaca },
                      { campo: 'chassi_vei', nome: 'Chassi' },
                      { campo: 'modelo.descri_mod', nome: 'Modelo' },
                      { campo: 'modelo.marca.descri_mar', nome: 'Marca' }
                    ]}
                    filtersDefault={{ apenasVeiculosComNegsDeCompra: true }}
                  />
                </div>
                <div className="field col-12">
                  <InputTextarea placeholder="Descrição do veículo"
                    value={isConjunto ? this.state.descricaoVeiculoConjunto.join('\n\n') : this.state.descricaoVeiculo || ''}
                    disabled={true} autoResize className="inputfield w-full" />
                </div>
              </div>
            </AccordionPage>
          </AccordionContainer>
          <div className= 'm-2'>
            <TabMenuContainer /*activeIndexParaNaoMostrarContentHeader={[0]} */ className='normal col-12 m-100'>
              <TabMenuPage header={isConjunto ? `Custos do conjunto - ${formatToCurrency(totalCustos)}` : `Custos - ${formatToCurrency(totalCustos)}`}>
                <div className="formgrid grid">
                  <div className="field col-12 md:col-3 sm:col-4">
                    <TextInput
                      label="Valor compra"
                      placeholder="Valor compra"
                      className="inputfield w-full"
                      value={ formatToCurrency(custos.valorCompra)}
                      disabled={true}
                    />
                  </div>
                  <div className="field col-12 md:col-3 sm:col-4">
                    <TextInput
                      label="Juros de estoque"
                      placeholder="Juros de estoque"
                      className="inputfield w-full"
                      name={ 'jurosEstoque'}
                      value={formatToCurrency(custos.jurosEstoque.toFixed(2))}
                      onChange={this.handleChangeCurrencyCustos}
                    />
                  </div>
                  <div className="field col-12 md:col-3 sm:col-4">
                    <TextInput
                      label="Rateio de despesas"
                      placeholder="Rateio de despesas"
                      className="inputfield w-full"
                      value={formatToCurrency(custos.rateioDespesas.toFixed(2))}
                      disabled
                    />
                  </div>
                  <div className="field col-12 md:col-3 sm:col-4">
                    <TextInput
                      label="Manutenções"
                      placeholder="Manutenções"
                      className="inputfield w-full"
                      value={formatToCurrency(custos.valorManutencoes)}
                      disabled
                    />
                  </div>
                  <div className="field col-12 md:col-3 sm:col-4">
                    <TextInput
                      label="Documentação"
                      placeholder="Documentação"
                      className="inputfield w-full"
                      name={'valorDocumentacao'}
                      onChange={this.handleChangeCurrencyCustos}
                      value={formatToCurrency(custos.valorDocumentacao)}
                    />
                  </div>
                  <div className="field col-12 md:col-3 sm:col-4">
                    <TextInput
                      label="Comissão Vendedor"
                      placeholder="Comissão Vendedor"
                      className="inputfield w-full"
                      name={'comissaoVendedor'}
                      onChange={this.handleChangeCurrencyCustos}
                      value={formatToCurrency(custos.comissaoVendedor)}
                    />
                  </div>
                  <div className="field col-12 md:col-3 sm:col-4">
                    <NumberInput
                      label="Outros custos (%)"
                      placeholder="Outros custos (%)"
                      className="inputfield w-full"
                      name={'outrosCustos'}
                      onChange={this.handleChangeOutrosCustos}
                      value={custos.outrosCustos}
                    />
                  </div>
                  <div className="field col-12 md:col-3 sm:col-4">
                    <TextInput
                      label={<b>Total de custos</b>}
                      placeholder="Total de custos"
                      className="inputfield w-full"
                      value={formatToCurrency(Number(totalCustos))}
                      disabled
                    />
                  </div>
                </div>
              </TabMenuPage>

              {veiculosConjuntoInfo?.length > 1 && isConjunto &&
              veiculosConjuntoInfo.map((vei) => {
                return (
                  <TabMenuPage key={vei} header={vei.veiculosDoConjunto.placa_vei ? formatPlaca(vei.veiculosDoConjunto.placa_vei) : vei.veiculosDoConjunto.chassi_vei.slice(9)}>
                    <CustoVeiculoConjunto codvei={vei.veiculosDoConjunto.codigo_vei} atualizarCusto={this.atualizarCusto} totalCustoVeiculosConjunto={this.state.totalCustoVeiculosConjunto}/>
                  </TabMenuPage>
                )
              }

              )
              }
            </TabMenuContainer>
          </div>
          <div className= 'm-2'>
            <TabMenuContainer activeIndexParaNaoMostrarContentHeader={this.state.abrirPrecificacao} className='normal col-12 md:col-4' >
              <TabMenuPage header={veiculo.itemConjuntoVeiculo?.conjuntoVeiculo?.itensConjunto.length > 1 ? `Venda do conjunto - ${formatToCurrency(totalVenda)}` : `Venda - ${formatToCurrency(totalVenda)}`}>
                <div className="formgrid grid">
                  <div className="field col-12 md:col-4">
                    <TextInput
                      label="Valor financiado"
                      placeholder="Valor financiado"
                      className="inputfield w-full"
                      value={formatToCurrency(Number(venda.valorFinanciado))}
                      name="valorFinanciado"
                      onChange={this.handleChangeCurrencyVenda}
                    />
                  </div>
                  <div className="field col-12 md:col-4">
                    <NumberInput
                      label="Retorno(%)"
                      placeholder="Retorno(%)"
                      className="inputfield w-full"
                      value={venda.retorno}
                      name="retorno"
                      onChange={this.handleChangeRetorno}
                    />
                  </div>
                  <div className="field col-12 md:col-4">
                    <TextInput
                      label="Outras receitas"
                      placeholder="Outras receitas"
                      className="inputfield w-full"
                      value={formatToCurrency(Number(venda.outrasReceitas))}
                      name="outrasReceitas"
                      onChange={this.handleChangeCurrencyVenda}
                    />
                  </div>
                  <div className="formgrid grid">
                    <div className="field col-12 md:col-4">
                      <Fieldset legend="Venda" className='h-full' >
                        <div className="field col-12 ">
                          <label className="label">Preço</label>
                          <div className={`${isConjunto ? 'w-full' : 'p-inputgroup'}`}>
                            <InputText
                              placeholder="Preço"
                              className={`${isConjunto ? 'inputfield w-full' : 'inputfield'}`}
                              value={formatToCurrency(venda.preco)}
                              name="preco"
                              onChange={this.handleChangeCurrencyVenda}
                            />
                            {!isConjunto ? <Button
                              label="Gravar"
                              style={{ fontSize: '0.85em', paddingRight: '8px', paddingLeft: '8px' }}
                              onClick={this.gravarPrecoVenda}
                              disabled={!veiculo.codigo_vei}
                            /> : '' }
                          </div>
                        </div>
                        <div className="field col-12">
                          <TextInput
                            label={<b>Total</b>}
                            placeholder="Total"
                            className="inputfield w-full"
                            value={formatToCurrency(Number(totalVenda))}
                            disabled

                          />
                        </div>
                        <div className="field col-12">
                          <TextInput
                            label={<b>Lucro (R$)</b>}
                            placeholder="Lucro (R$)"
                            className="inputfield w-full"
                            value={formatToCurrency(Number(lucroVendaReais))}
                            disabled
                          />
                        </div>
                        <div className="field col-12">
                          <TextInput
                            label={<b>Lucro (%)</b>}
                            placeholder="Lucro (%)"
                            className="inputfield w-full"
                            value={isFinite(lucroVendaPercentual) ? lucroVendaPercentual.toFixed(2) : '0'}
                            disabled
                          />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          { (this.state.veiculo.codigo_vdi !== 'B' && this.state.veiculo.codigo_vdi !== 'V' && this.state.veiculo.codigo_vdi !== 'I' && (this.state.veiculo.perven_ves === 1)) && (
                            <AppButton
                              label="Vender"
                              onClick={async () => {
                                if (await venderVeiculo.handleVenderVeiculo(this.state.veiculo)) {
                                  this.props.history.push('/negociacao')
                                }
                              }}
                            />
                          )
                          }
                        </div>
                      </Fieldset>
                    </div>
                    <div className="field col-12 md:col-4">
                      <Fieldset legend="Mínimo" className='h-full' >
                        <div className="field col-12" >
                          <label className="label">Preço</label>
                          <div className={`${isConjunto ? 'w-full' : 'p-inputgroup'}`}>
                            <InputText
                              placeholder="Preço"
                              className={`${isConjunto ? 'inputfield w-full' : 'inputfield'}`}
                              name="valorMinimo"
                              value={formatToCurrency(Number(venda.valorMinimo))}
                              onChange={this.handleChangeCurrencyVenda}
                            />
                            {!isConjunto &&
                            <Button
                              label="Gravar"
                              style={{ fontSize: '0.85em', paddingRight: '8px', paddingLeft: '8px' }}
                              onClick={this.gravarPrecoMinimo}
                              disabled={!veiculo.codigo_vei}
                            />
                            }
                          </div>
                        </div>
                        <div className="field col-12">
                          <TextInput
                            label={<b>Total</b>}
                            placeholder="Total"
                            className="inputfield w-full"
                            value={formatToCurrency(Number(totalVendaMinimo))}
                            disabled
                          />
                        </div>
                        <div className="field col-12">
                          <TextInput
                            label={<b>Lucro (R$)</b>}
                            placeholder="Lucro (R$)"
                            className="inputfield w-full"
                            value={formatToCurrency(Number(lucroVendaReaisMinimo))}
                            disabled
                          />
                        </div>
                        <div className="field col-12 h-200" >

                          <TextInput
                            label={<b>Lucro (%)</b>}
                            placeholder="Lucro (%)"
                            className="inputfield w-full"
                            value={isFinite(lucroVendaPercentualMinimo) ? lucroVendaPercentualMinimo.toFixed(2) : '0'}
                            disabled
                          />
                        </div>
                      </Fieldset>
                    </div>
                    <div className="field col-12 md:col-4">
                      <Fieldset legend="E-commerce" className='h-full' >
                        <div className="formgrid grid">
                          <div className="field col-12">
                            <label className="label">Preço</label>
                            <div className={`${isConjunto ? 'w-full' : 'p-inputgroup'}`}>
                              <InputText
                                placeholder="Preço"
                                className={`${isConjunto ? 'inputfield w-full' : 'inputfield'}`}
                                name="valorEcommerce"
                                value={formatToCurrency(Number(venda.valorEcommerce))}
                                onChange={this.handleChangeCurrencyVenda}
                              />
                              {!isConjunto &&
                              <Button
                                label="Gravar"
                                style={{ fontSize: '0.85em', paddingRight: '8px', paddingLeft: '8px' }}
                                onClick={this.gravarPrecoEcommerce}
                                disabled={!veiculo.codigo_vei}
                              />
                              }
                            </div>
                          </div>
                          <div className="field col-12">
                            <TextInput
                              label={<b>Total</b>}
                              placeholder="Total"
                              className="inputfield w-full"
                              value={formatToCurrency(Number(totalVendaEcommerce)) }
                              onChange={this.handleChangeCurrencyVenda}
                              disabled
                            />
                          </div>
                          <div className="field col-12">
                            <TextInput
                              label={<b>Lucro (R$)</b>}
                              placeholder="Lucro (R$)"
                              className="inputfield w-full"
                              value={formatToCurrency(Number(lucroVendaReaisEcommerce))}
                              disabled
                            />
                          </div>
                          <div className="field col-12">

                            <TextInput
                              label={<b>Lucro (%)</b>}
                              placeholder="Lucro (%)"
                              className="inputfield w-full"
                              value={isFinite(lucroVendaPercentualEcommerce) ? lucroVendaPercentualEcommerce.toFixed(2) : '0'}
                              disabled
                            />
                          </div>
                        </div>
                      </Fieldset>
                    </div>
                  </div>
                </div>
              </TabMenuPage>
              {veiculosConjuntoInfo && veiculosConjuntoInfo?.length > 1 && isConjunto &&
              veiculosConjuntoInfo.map((vei) => {
                return (
                  <TabMenuPage key={vei} header={vei.veiculosDoConjunto.placa_vei ? formatPlaca(vei.veiculosDoConjunto.placa_vei) : vei.veiculosDoConjunto.chassi_vei.slice(9)}>
                    <ValorVendaVeiculoConjunto
                      totalCustoVeiculosConjunto={this.state.totalCustoVeiculosConjunto}
                      codvei={vei.veiculosDoConjunto.codigo_vei}
                      history={this.props.history}
                      veiculo={veiculo}
                    />
                  </TabMenuPage>
                )
              }

              )
              }
            </TabMenuContainer>
          </div>
        </div>
      </>
    )
  }
}
