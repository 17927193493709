import React from 'react'
import List from '../../../../classes/List'
import DataList from '../../../../components/layout/DataList'
import { showErrorMessage } from '../../../../components/utils/Message'
import Modal from '../../../../components/utils/Modal'
import { getProdutoDTO } from '../../../../dtos/cadastro/produto/ProdutoDTO'
import { formatToCurrency } from '../../../../helpers/formaters'
import { createColumns, getColunasHabilitadas } from '../../../../helpers/tableConfigs'
import ProdutoService from '../../../../services/cadastro/produto/ProdutoService'
import ConfigService from '../../../../services/configuracao/ConfigService'

class PrecosEmpresasModal extends List {
  constructor (props) {
    super(
      props,
      getProdutoDTO,
      ProdutoService,
      '',
      '',
      20,
      ''
    )

    this.state = {
      ...this.state,
      nomePrecoPersonalizado1: 'Personalizado 1',
      nomePrecoPersonalizado2: 'Personalizado 2',
      nomePrecoPersonalizado3: 'Personalizado 3'
    }

    this.createDataListColumns()
  }

  verificaValor (data) {
    let valor = 0
    switch (data.codtpr_dqp) {
    case 1:
      valor = data.preco_pre - (data.preco_pre * (data.perdes_dqp / 100))
      break
    case 2:
      valor = data.cusmedliq_pre - (data.cusmedliq_pre * (data.perdes_dqp / 100))
      break
    case 3:
      valor = data.pregar_pre - (data.pregar_pre * (data.perdes_dqp / 100))
      break
    case 4:
      valor = data.prerep_pre - (data.prerep_pre * (data.perdes_dqp / 100))
      break
    case 5:
      valor = data.prepps_pre - (data.prepps_pre * (data.perdes_dqp / 100))
      break
    case 6:
      valor = data.prepro_pre - (data.prepro_pre * (data.perdes_dqp / 100))
      break
    case 7:
      valor = data.preata_pre - (data.preata_pre * (data.perdes_dqp / 100))
      break
    case 8:
      valor = data.preper1_pre - (data.preper1_pre * (data.perdes_dqp / 100))
      break
    case 9:
      valor = data.preper2_pre - (data.preper2_pre * (data.perdes_dqp / 100))
      break
    case 10:
      valor = data.preper3_pre - (data.preper3_pre * (data.perdes_dqp / 100))
    default:
      valor = 0
      break
    }

    return formatToCurrency(valor)
  }

  createDataListColumns () {
    this.setColumns(createColumns([
      { field: 'qtd_dqp', header: 'Quantidade', width: '100px' },
      { field: 'descri_tpr', header: 'Tipo de preço', width: '300px' },
      { field: 'perdes_dqp', header: 'Desconto (%)', customBody: (data) => Number(data?.perdes_dqp).toFixed(2), width: '150px' },
      { field: 'valor_dqp', header: 'Valor qtd.', customBody: (data) => this.verificaValor(data), width: '150px' },
      { field: 'preco_pre', header: 'Varejo', customBody: (data) => formatToCurrency(data.preco_pre), width: '150px' },
      { field: 'preata_pre', header: 'Atacado', customBody: (data) => formatToCurrency(data.preata_pre), width: '150px' },
      { field: 'preper1_pre', header: this.state.nomePrecoPersonalizado1, customBody: (data) => formatToCurrency(data.preper1_pre), width: '200px' },
      { field: 'preper2_pre', header: this.state.nomePrecoPersonalizado2, customBody: (data) => formatToCurrency(data.preper2_pre), width: '200px' },
      { field: 'preper3_pre', header: this.state.nomePrecoPersonalizado3, customBody: (data) => formatToCurrency(data.preper3_pre), width: '200px' }
    ]))
  }

  async componentDidMount () {
    const nomePrecoPersonalizado1 = await ConfigService.getValor(3091) || 'Personalizado 1'
    const nomePrecoPersonalizado2 = await ConfigService.getValor(3092) || 'Personalizado 2'
    const nomePrecoPersonalizado3 = await ConfigService.getValor(3093) || 'Personalizado 3'

    this.setState({ nomePrecoPersonalizado1, nomePrecoPersonalizado2, nomePrecoPersonalizado3 }, this.createDataListColumns)

    this.addEventListeners()
    this.resetDescricaoFiltro()
  }

  async componentDidUpdate (prevProps) {
    if (this.props.produto?.codigo_pro && prevProps.produto?.codigo_pro !== this.props.produto.codigo_pro) {
      const filter = { codigo_pro: this.props.produto.codigo_pro }
      this.setState({ filter }, this.onFilter)
    }
  }

  onFilter = async () => {
    try {
      let response = await ProdutoService.getPrecoEmpresaQtd(this.toFilter(this.state.filter), this.state.page, this.state.rows)
      this.setRetorno(response)
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao filtrar os registros!')
    }
  }

  render () {
    const props = this.props
    const state = this.state

    return (
      <Modal
        visible={props.visible}
        onHide={props.onHide}
        width={70}
        header={'Consulta de preços: ' + props.produto?.descri_pro}
      >
        <DataList
          data={state.list}
          rows={state.rows}
          totalRecords={state.totalRecords}
          first={state.first}
          onPage={this.onPageChange}
          responsive={true}
          page={state.page}
          scrollable={true}
          scrollDirection="horizontal"
        >
          {getColunasHabilitadas(this.columns)}
        </DataList>
      </Modal>
    )
  }
}

export default PrecosEmpresasModal
