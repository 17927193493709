import BaseModel from '../../BaseModel'

export class GerenciamentoEstoqueVeiculoModel extends BaseModel {
  constructor (params = {}) {
    super()
    this.codpro_pre = params.codpro_pre || ''
    this.preco_pre = params.preco_pre || ''
    this.codigo_vei = params.codigo_vei || ''
    this.codmod_vei = params.codmod_vei || ''
    this.anofabmod_vei = params.anofabmod_vei || ''
    this.anofab_vei = params.anofab_vei || ''
    this.anomod_vei = params.anomod_vei || ''
    this.placha_vei = params.placha_vei || ''
    this.placa_vei = params.placa_vei || ''
    this.chassi_vei = params.chassi_vei || ''
    this.codtiv_vei = params.codtiv_vei || ''
    this.codmar_mod = params.codmar_mod || ''
    this.descri_mod = params.descri_mod || ''
    this.codigo_mod = params.codigo_mod || ''
    this.descri_mar = params.descri_mar || ''
    this.codigo_mar = params.codigo_mar || ''
  }

  toMap () {
    return
  }
}
