import List from '../../../../classes/List'
import Modal from '../../../../components/utils/Modal'
import React from 'react'
import { getAcessorioDTO } from '../../../../dtos/cadastro/veiculo/AcessorioDTO'
import AcessorioService from '../../../../services/cadastro/veiculo/AcessorioService'
import DataList from '../../../../components/layout/DataList'
import AppButton from '../../../../components/layout/AppButton'
import VeiculoService from '../../../../services/cadastro/veiculo/VeiculoService'
import { createColumns, getColunasHabilitadas } from '../../../../helpers/tableConfigs'

class AlterarAcessoriosModal extends List {
  constructor (props) {
    super(
      props,
      getAcessorioDTO,
      AcessorioService,
      '',
      'codigo_ace'
    )

    this.state = {
      ...this.state,
      acessoriosSelecionados: []
    }

    this.setColumns(createColumns([
      { field: 'selecao', header: 'SEL', width: '10%', checkBoxColumn: true, sortable: false },
      { field: 'descri_ace', header: 'Descrição', width: '60%' },
      { field: 'sigla_ace', header: 'Sigla', width: '20%' }
    ]))
  }

  async componentDidUpdate(prevProps) {
    const { visible } = this.props

    if (prevProps.visible !== visible) {
      this.setState({ visible })

      if (visible) {
        this.resetDescricaoFiltro()
        await this.getAll()
        await this.checkAcessoriosVeiculo()
      }
    }
  }

  async componentDidMount () {
    this.resetDescricaoFiltro()
  }

  async checkAcessoriosVeiculo () {
    const todosOsAcessoriosDisponiveis = await VeiculoService.getAllAccessories()
    const codigosAcessoriosVeiculo = this.props.form.values.acessorios.map(acessorio => acessorio.codace_acv)

    const acessoriosVeiculo = []

    if (Array.isArray(this.props.form.values.acessorios)) {
      for (const codigoAcessorioVeiculo of this.props.form.values.acessorios) {
        const acessorioVeiculo = todosOsAcessoriosDisponiveis.find(
          acessorioDisponivel => acessorioDisponivel.codigo_ace == codigoAcessorioVeiculo.codace_acv
        )  
        acessoriosVeiculo.push(acessorioVeiculo)
      }
    }

    this.setState({ acessoriosSelecionados: acessoriosVeiculo })
  }

  handleAlterarAcessoriosVeiculo () {
    this.setAcessoriosVeiculoByAcessoriosSelecionados()
    this.setNewDescricaoAcessoriosVeiculo()
    this.props.onHide()
  }

  setAcessoriosVeiculoByAcessoriosSelecionados () {
    const acessoriosSelecionados = this.state.acessoriosSelecionados.map(acessorioSelecionado => { return { codace_acv: acessorioSelecionado.codigo_ace } })
    this.props.form.setFieldValue('acessorios', [...acessoriosSelecionados])
  }

  setNewDescricaoAcessoriosVeiculo () {
    let newDescricaoAcessoriosVeiculo = ''

    const { acessoriosSelecionados } = this.state
    let acessoriosOrdenados = acessoriosSelecionados?.sort((a, b) => {
      return a.descri_ace.localeCompare(b.descri_ace)
    })
    acessoriosOrdenados?.forEach(acessorio => {
      newDescricaoAcessoriosVeiculo += `${acessorio.descri_ace}, `
    })
    this.props.changeDescricaoAcessoriosVeiculo(newDescricaoAcessoriosVeiculo.slice(0, -2))
  }

  render () {
    const hide = () => {
      this.checkAcessoriosVeiculo()
      this.props.onHide()
    }

    const { rows, totalRecords, first, list, acessoriosSelecionados, page } = this.state

    return (
      <Modal
        header="Alterar acessórios do veículo"
        visible={this.props.visible}
        onHide={hide}
        width={50}
        footer={
          <AppButton
            label="Alterar"
            className="mb-1 mt-1 mr-5"
            onClick={() => this.handleAlterarAcessoriosVeiculo()}
          />
        }
      >
        <DataList
          className="without-header-checkbox"
          data={list}
          selected={acessoriosSelecionados}
          rows={rows}
          onSelect={(acessoriosSelecionados) => {
            this.setState({ acessoriosSelecionados })
          }}
          totalRecords={totalRecords}
          first={first}
          onPage={this.onPageChange}
          page={page}
          filterPlaceholder="Procurar acessórios"
          showFilter
          filterName="descricaoFiltro"
          filterOnChange={this.handleChangeFilter}
          responsive
          onFilter={this.onFilter}
          dataKey="codigo_ace"
          dragSelection
        >
          {getColunasHabilitadas(this.columns)}
        </DataList>
      </Modal>
    )
  }
}

export default AlterarAcessoriosModal
