import ServiceBase from '../../../base/ServiceBase'

export default class LandingPixService {
  static async getLandingVeiculoPix (codNve, database) {
    return await ServiceBase.getBase(`pagamento-pix-vabank/veiculo/${database}/${codNve}`)
  }

  static async getDadosEmpresa (empresaId, database) {
    return await ServiceBase.getBase(`pagamento-pix-vabank/empresa/${database}/${empresaId}`)
  }

  static async getLandingPix (codNve, database) {
    return await ServiceBase.getBase(`pagamento-pix-vabank/${database}/${codNve}`)
  }
}
