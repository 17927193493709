import * as Yup from 'yup'

export const DinheiroValidator = Yup.object().shape({

  dataEmissao: Yup.date()
    .nullable()
    .required('A data da operação não foi informada!'),
  valor: Yup.number()
    .required('O valor não foi informado!')
    .min(0.001, 'O valor não foi informado!'),
  veiculo: Yup.object().shape({
    codigo_vei: Yup.number()
      .required('O veículo não foi selecionado!')
  })
})
