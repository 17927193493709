export const getDeParaMarcaDTO = (data = {}) => {
  return {
    codigo_mdp: data.codigo_mdp || null,
    codmartur_mdp: data.codmartur_mdp || null,
    codmaraug_mdp: data.codmaraug_mdp || null
  }
}

export const postDeParaMarcaDTO = (data) => {
  return {
    codigo_mdp: data.codigo_mdp || null,
    codmartur_mdp: data.codmartur_mdp || null,
    codmaraug_mdp: data.codmaraug_mdp || null
  }
}
