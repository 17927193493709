import moment from 'moment'

export const getGrupoUsuarioDTO = (data = {}) => {
  return {
    codigo_gru: data.codigo_gru || null,
    nome_gru: data.nome_gru || null,
    dahins_gru: data.dahins_gru ? moment(new Date(data.dahins_gru)).format('DD/MM/YYYY') : null,
    dahalt_gru: data.dahalt_gru ? moment(new Date(data.dahalt_gru)).format('DD/MM/YYYY') : null,
    permissoes: data.permissoes || [],
    restricoes: data.restricoes || [],
    usuarios: data.usuarios || [],
    menusRestritos: data.menusRestritos || [],
    usuarioInsercao: data.usuarioInsercao || {},
    usuarioAlteracao: data.usuarioAlteracao || {}
  }
}

export const postGrupoUsuarioDTO = (data) => {
  return {
    codigo_gru: data.codigo_gru || null,
    nome_gru: data.nome_gru || null,
    permissoes: data.permissoes || [],
    restricoes: data.restricoes || [],
    menusRestritos: data.menusRestritos || []
  }
}
