import * as Yup from 'yup'
import moment from 'moment'

export const FinanciamentoValidator = Yup.object().shape({
  veiculo: Yup.object().shape({
    codigo_vei: Yup.number()
      .nullable()
      .required('O veículo não foi selecionado!')
  }),
  valor: Yup.number()
    .nullable()
    .required('O valor não foi informado!')
    .min(0.001, 'O valor não foi informado!'),
  financeira: Yup.object().shape({
    codigo_fin: Yup.number()
      .nullable()
      .required('A financeira não foi informada!')
  }),
  primeiroVencimento: Yup.date()
    .nullable()
    .required('A previsão de pagamento não foi informada!')
    .test('testeDataCompensacao', 'Previsão de pagamento menor que a data de emissão!', function (value) {
      const diferenca = moment(value).diff(this.parent.dataEmissao, 'days')

      if (diferenca >= 0) {
        return true
      }

      return false
    })
})
