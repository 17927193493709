import { Button } from 'primereact/button'
import { FileUpload } from 'primereact/fileupload'
import React, { useEffect, useState } from 'react'
import { showErrorMessage } from '../../../../../components/utils/Message'
import ImagemService from '../../../../../services/imagem/ImagemService'

const ImagensVei = ({ form, fileUploadRef, edicaoHabilitada }) => {
  const [imagensExcluidas, setImagensExcluidas] = useState([])
  const [imagensAtuais, setImagensAtuais] = useState([])

  const chooseOptions = { icon: 'pi pi-plus', label: 'Adicionar imagem', className: 'custom-choose-btn p-button-rounded' }
  const uploadOptions = { className: 'hidden' }
  const cancelOptions = { className: 'hidden' }

  useEffect(async () => {
    await getImagens()
  }, [])

  const getImagens = async () => {
    await ImagemService.getImages('codigo_vei', form.values.codigo_vei)
      .then((imagensAtuais) =>
        setImagensAtuais(imagensAtuais)
      ).catch((error) => {
        showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar as imagens do veículo!')
      })
  }

  const handleUpload = async (uploadEvent) => {
    await handleSalvarImagens(uploadEvent)

    if (imagensExcluidas.length > 0) {
      await handleExcluirImagens()
    }
  }

  const handleSalvarImagens = async (uploadEvent) => {
    try {
      fileUploadRef.current?.clear()
      await ImagemService.uploadImages(uploadEvent.files, form.values.codigo_vei, 'codigo_vei', 'veiculo')
        .then(imagensSalvas => addToImagensAtuais(imagensSalvas))
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao salvar as fotos do veículo!')
    }
  }

  const handleExcluirImagens = async () => {
    try {
      await ImagemService.deleteImages(imagensExcluidas)
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao excluir as fotos do veículo!')
    }
  }

  const itemTemplate = (file, props) => {
    return (
      <div className="flex align-itemscenter flex-wrap">
        <div className="imagem-container">
          <img alt={file.name} role="presentation" src={file.objectURL} className="imagem" />
          <i className="pi pi-times excluir-imagem" onClick={props.onRemove} />
        </div>
      </div>
    )
  }

  function showImagensAtuaisVeiculo () {
    if (imagensAtuais.length === 0) return

    return imagensAtuais.map(imagem => {
      const imagenEstaExcluida = imagensExcluidas.find(imagemExcluida => imagemExcluida.codigo_img === imagem.codigo_img)

      if (!imagenEstaExcluida)
        return (
          <div key={imagem.codigo_img} className="imagem-container">
            <img className="imagem" src={imagem.url} />
            <Button icon="pi pi-times" className="excluir-imagem" onClick={() => addToImagensExcluidas(imagem)} disabled={!edicaoHabilitada} />
          </div>
        )
    })
  }

  const addToImagensAtuais = (imagensNovas) => {
    setImagensAtuais([...imagensAtuais, ...imagensNovas])
  }

  const addToImagensExcluidas = (imagemNova) => {
    setImagensExcluidas([...imagensExcluidas, imagemNova])
  }

  return (
    <>
      <div className="flex overflow-x-scroll">
        {showImagensAtuaisVeiculo()}
      </div>
      <FileUpload
        className="fileupload-veiculo"
        disabled={!edicaoHabilitada}
        ref={fileUploadRef}
        style={{ marginTop: '30px' }}
        contentStyle={{ display: 'block', width: '100%' }}
        name="image[]"
        multiple={true}
        accept="image/png, image/jpeg, image/gif, image/jpg"
        maxFileSize={5000000}
        emptyTemplate={<></>}
        previewWidth={200}
        chooseOptions={chooseOptions}
        uploadOptions={uploadOptions}
        cancelOptions={cancelOptions}
        itemTemplate={itemTemplate}
        onValidationFail={(file) => showErrorMessage(`${file.name}: Tamanho de arquivo inválido, o tamanho máximo de envio é de 5MB.`)}
        customUpload
        uploadHandler={handleUpload}
      />
    </>
  )
}

export default ImagensVei
