import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { isLoggedIn } from '../../helpers/auth'

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => {
    if (isLoggedIn()) {
      return <Redirect to={{ pathname: '/home', state: { from: props.location } }} />
    }
    return <Component {...props} />
  }} />
)

export default PublicRoute
