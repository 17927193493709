import { postDeParaMarcaDTO } from '../../dtos/cadastro/autogestor/DeParaMarcaDTO'
import ServiceBase from '../base/ServiceBase'

export default class MarcaDeParaService {
  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`marcas/depara/lista?${query}&page=${page}&perPage=${perPage}`)
  }

  async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`marcas/depara/lista?page=${page}&perPage=${perPage}`)
  }

  static async sincronizarMarcaModeloAG () {
    return await ServiceBase.postBase('vehicle/sincronizarmarcamodeloautogestor', '')
  }

  static async save (data) {
    return await ServiceBase.postBase('/marcas/depara', postDeParaMarcaDTO(data))
  }
}
