import { Dialog } from 'primereact/dialog'
import React, { Component } from 'react'
import AppButton from '../layout/AppButton'

class Modal extends Component {
  render () {
    const props = this.props

    return (
      <Dialog
        className={`modal-vw-${props.width || '65'} h-${props.height}`}
        blockScroll
        resizable={false}
        footer={props.footer ||
          <>
          </>
        }
        {...props}
      >
        {props.children}
        <div className="p-dialog-buttons">
          {props.btnExcluir !== undefined && (
            <AppButton
              label={props.btnExcluirLabel || 'Excluir'}
              icon="pi pi-times"
              type="button"
              onClick={async () => await props.btnExcluir()}
              className="p-button-danger mr-2"
            />
          )}
          {props.btnSalvar !== undefined && (
            <AppButton
              type="button"
              label={props.btnSalvarLabel || 'Salvar'}
              icon={props.icon || ''}
              className="p-button mr-2"
              onClick={async () => await props.btnSalvar()}
            />
          )}
        </div>
      </Dialog>
    )
  }
}

export default Modal
