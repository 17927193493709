import { Button } from 'primereact/button'
import React, { Component } from 'react'
import TextInput from '../../../components/inputs/TextInput'
import { showErrorMessage, showMessage, showSuccessMessage, showWarnMessage } from '../../../components/utils/Message'
import Modal from '../../../components/utils/Modal'
import { dev, loc, prod } from '../../../config'
import CommonHelper from '../../../helpers/CommonHelper'
import { getStore } from '../../../redux/store'
import IntegracaoUsuarioService from '../../../services/Integracao/IntegracaoUsuarioService'
import PermissaoService from '../../../services/cadastro/pessoa/PermissaoService'

class IntegracaoUsuarioForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      codban_usu: '',
      email: '',
      login_usu: '',
      senha_usu: '',
      enviandoEmail: false,
      permissaoUsuarioSemSenha: false

    }
  }

  componentDidUpdate () {
    const { props, state } = this

    if (props.codigoOrganizacao && !state.codban_usu) {
      this.setState({ codban_usu: props.codigoOrganizacao })
      this.getPermissao()
    }

    if (props.formUsu) {
      if (props.formUsu.values.email && !state.login_usu) {
        this.setState({ login_usu: props.formUsu.values.email })
      }

      if (props.formUsu.values.email_pes && !state.email) {
        this.setState({ email: props.formUsu.values.email_pes })
      }
    }
  }

  async getPermissao () {
    const permissao222 = await PermissaoService.getByCodigo('222')
    this.setState({ permissaoUsuarioSemSenha: permissao222 })
  }

  render () {
    const { codban_usu, email, login_usu, senha_usu } = this.state
    const { codigoOrganizacao } = this.props

    return (
      <Modal
        header="Integração de usuário"
        visible={this.props.visible}
        width="30"
        modal={true}
        onHide={this.props.hide}
      >
        <div className="formgrid grid">
          {!codigoOrganizacao && (
            <div className="field col-12">
              <TextInput
                value={codban_usu}
                label="Organização"
                onChange={e => this.setState({ codban_usu: e.target.value })}
                placeholder="Organização"
                className="inputfield w-full"
              />
            </div>
          )}
          <div className="field col-12">
            <TextInput
              value={email}
              label={codigoOrganizacao ? 'E-mail (Novo login)' : 'E-mail'}
              onChange={e => this.setState({ email: e.target.value })}
              placeholder="E-mail"
              className="inputfield w-full"
            />
          </div>
          <div className="field col-12">
            <TextInput
              value={login_usu}
              label={codigoOrganizacao ? 'Login atual' : 'Login'}
              onChange={e => this.setState({ login_usu: e.target.value })}
              placeholder="Login"
              className="inputfield w-full"
            />
          </div>
          {this.state.permissaoUsuarioSemSenha ? ''
            : <div className="field col-12">
              <TextInput
                value={senha_usu}
                label={codigoOrganizacao ? 'Senha atual' : 'Senha'}
                onChange={e => this.setState({ senha_usu: e.target.value })}
                placeholder="Senha"
                type="password"
                className="inputfield w-full"
              />
            </div>
          }
        </div>
        <div className="p-dialog-buttons pt-1">
          <Button
            label="Integrar"
            onClick={this.onSave}
            disabled={this.state.enviandoEmail}
            className="mr-0"
          />
        </div>
      </Modal>
    )
  }

    validaCampos = (model) => {
      const validador = [
        {
          valor: model.codban_usu,
          validacao: 'notEmpty',
          nomeCampo: 'organização',
          mensagem: 'Informe uma organização válida!'
        },
        {
          valor: model.email,
          validacao: 'notEmpty',
          nomeCampo: 'e-mail',
          mensagem: 'Informe um endereço de e-mail válido!'
        },
        {
          valor: model.login_usu,
          validacao: 'notEmpty',
          nomeCampo: 'login',
          mensagem: 'Informe um login válido!'
        }
      ]

      if (!this.state.permissaoUsuarioSemSenha) {
        validador.push({
          valor: model.senha_usu,
          validacao: 'notEmpty',
          nomeCampo: 'senha',
          mensagem: 'Informe uma senha válida!'
        })
      }

      const valores = validador.map(item => item.valor)
      const validacoes = validador.map(item => item.validacao)
      const nomeCampos = validador.map(item => item.nomeCampo)
      const mensagens = validador.map(item => item.mensagem)

      const validacao = CommonHelper.validarCampos(valores, validacoes, nomeCampos, mensagens)

      if (validacao.erro) {
        const mensagens = []

        validacao.mensagens.forEach(item => {
          mensagens.push({ severity: 'warn', summary: 'Campo inválido', detail: item.mensagem })
        })

        showMessage(mensagens)

        return false
      }

      return true
    }

    onSave = async () => {
      try {
        const { email, login_usu, senha_usu, codban_usu } = this.state

        const valido = this.validaCampos(this.state)
        const emailValido = CommonHelper.validaEmail(email)

        if (valido) {
          if (emailValido) {
            this.setState({ enviandoEmail: true })

            await IntegracaoUsuarioService.enviarEmailIntegracao({
              organizacao: codban_usu,
              codigo_emp: getStore().empresaLogada.codigo_emp,
              login: login_usu,
              senha: senha_usu,
              email,
              url: this.getUrlAmbiente()
            })

            showSuccessMessage('A sua nova senha foi enviada para o seu e-mail!')
            this.props.hide()
            this.setState({
              enviandoEmail: false,
              email: '',
              login_usu: '',
              senha_usu: '',
              codban_usu: ''
            })
          } else {
            showWarnMessage('O e-mail informado deve estar em um formato válido!')
          }
        }
      } catch (error) {
        showErrorMessage(error.message || 'Houve um erro ao tentar redefinir sua senha!')
        this.setState({ enviandoEmail: false })
      }
    }

    getUrlAmbiente = () => {
      let config

      switch (process.env.REACT_APP_STAGE) {
      case 'loc':
        config = loc
        break
      case 'prod':
        config = prod
        break
      default:
        config = dev
      }

      return config.clientInfo.APP_URL
    }
}

export default IntegracaoUsuarioForm
