import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { SplitButton } from 'primereact/splitbutton'
import { logout } from '../../helpers/auth'
import UsuarioService from '../../services/cadastro/pessoa/UsuarioService'
import TextInput from '../inputs/TextInput'
import { Button } from 'primereact/button'
import { showErrorMessage, showSuccessMessage, showWarnMessage } from '../utils/Message'
import CommonHelper from '../../helpers/CommonHelper'
import Modal from '../utils/Modal'
import { getStore } from '../../redux/store'

export class AppTopbar extends Component {
    static defaultProps = {
      onToggleMenu: null
    };

    static propTypes = {
      onToggleMenu: PropTypes.func.isRequired
    };

    constructor (props) {
      super(props)
      this.state = {
        usuario: {},
        senhaAtual: '',
        novaSenha: '',
        confirmaSenha: '',
        visibleTrocarSenha: false
      }
    }

    componentDidMount () {
      this.setState({ usuario: getStore().usuarioLogado.usuario })
    }

    render () {
      const { usuario, senhaAtual, novaSenha, confirmaSenha } = this.state

      const items = [
        {
          label: 'Trocar Senha',
          icon: 'pi pi-fw pi-unlock',
          command: () => {
            this.setState({ visibleTrocarSenha: true })
          }
        },
        {
          label: 'Sair',
          icon: 'pi pi-fw pi-power-off',
          command: () => {
            this.logout()
          }
        }
      ]

      return (
        <>
          <div className="layout-topbar clearfix">
            <button className="p-link layout-menu-button" onClick={this.props.onToggleMenu}>
              <span className="pi pi-bars" />
            </button>
            <div className="layout-topbar-menu">
              <span className="big-user-menu flex">
                <SplitButton
                  model={items}
                  className="p-mr-2 user-menu"
                  label={`${CommonHelper.isDesktop()
                    ? (usuario.pessoa?.nomraz_pes || '')
                    : (usuario.pessoa?.nomraz_pes?.split(' ')[0] || '')}`
                  }
                />
              </span>
            </div>
          </div>
          <Modal
            header="Troque sua senha"
            focusOnShow={false}
            visible={this.state.visibleTrocarSenha}
            width="25"
            modal={true}
            onHide={this.fecharModalAlteracaoSenha}
          >
            <div className="formgrid grid">
              <div className="field col-12">
                <TextInput type="password" label="Senha atual" name="senhaAtual" className="inputfield w-full"
                  id="senhaAtual" value={senhaAtual} onChange={this.handleChange} />
              </div>
              <div className="field col-12">
                <TextInput type="password" label="Nova senha" name="novaSenha" className="inputfield w-full"
                  id="novaSenha" value={novaSenha} onChange={this.handleChange} />
              </div>
              <div className="field col-12">
                <TextInput type="password" label="Confirmar nova senha" name="confirmaSenha"
                  id="confirmaSenha" value={confirmaSenha} onChange={this.handleChange} className="inputfield w-full" />
              </div>
            </div>
            <div className="p-dialog-buttons">
              <Button
                label="Alterar"
                icon="pi pi-check"
                onClick={this.handleAlterarSenha}
                autoFocus={true}
              />
            </div>
          </Modal>
        </>
      )
    }

    logout = () => {
      logout()
      window.location.href = '/login'
    }

    handleChange = event => {
      this.setState({ [event.target.name]: event.target.value })
    }

    handleAlterarSenha = async () => {
      try {
        const { senhaAtual, novaSenha, confirmaSenha } = this.state

        const valores = [senhaAtual, novaSenha, confirmaSenha]
        const validacoes = ['notEmpty', 'notEmpty', 'notEmpty']
        const nomeCampos = ['senha atual', 'senha atual', 'confirmação de senha']
        const mensagens = [
          'O campo senha atual deve ser preenchido!',
          'O campo nova senha deve ser preenchido!',
          'O campo confirmar nova senha deve ser preenchido!'
        ]

        const validacao = CommonHelper.validarCampos(valores, validacoes, nomeCampos, mensagens)

        if (novaSenha !== confirmaSenha) {
          validacao.mensagens.push({ mensagem: 'As novas senhas não coincidem!' })
          validacao.erro = true
        }

        if (validacao.erro) {
          const mensagens = []

          validacao.mensagens.forEach(item => {
            mensagens.push(item.mensagem)
          })

          showWarnMessage(mensagens)
        } else {
          await UsuarioService.alterarSenha({ senhaAtual, novaSenha, confirmaSenha })
          showSuccessMessage('Sua senha foi alterada com sucesso!')
          this.fecharModalAlteracaoSenha()
        }
      } catch (e) {
        showErrorMessage(e.message || 'Ocorreu um erro. Tente novamente mais tarde.')
      }
    }

    fecharModalAlteracaoSenha = () => {
      this.setState({
        visibleTrocarSenha: false,
        senhaAtual: '',
        novaSenha: '',
        confirmaSenha: ''
      })
    }

    handleChangeBusca = event => {
      this.setState({ [event.target.name]: event.target.value })
    };
}
