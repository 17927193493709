import { Divider } from 'primereact/divider'
import React, { Component } from 'react'
import CommonHelper from '../../helpers/CommonHelper'
import { formatNumberToTwoFractionDigits, roundNumber } from '../../helpers/formaters'
import './_lucroDeVendasReportFooter.scss'

class LucroDeVendasReportFooter extends Component {
  constructor (props) {
    super(props)
  }

  render () {
    const { reportData } = this.props

    const somaCampo = (nomeCampo) => {
      let valorTotal = 0
      for (const vendedor of reportData) {
        for (const negociacao of vendedor.negociacoes) {
          valorTotal += negociacao[nomeCampo]
        }
      }

      return valorTotal
    }

    const getMediaDiasNoPatio = () => {
      let quantidadeDeNumeros = 0
      let totalDiasNoPatio = 0

      for (const vendedor of reportData) {
        for (const negociacao of vendedor.negociacoes) {
          if (negociacao.codope_neg !== 4) {
            quantidadeDeNumeros++

            totalDiasNoPatio += negociacao.diasestoque_ven
          }
        }
      }

      const mediaDiasNoPatio = totalDiasNoPatio / quantidadeDeNumeros

      return roundNumber(mediaDiasNoPatio || 0)
    }

    const getValorTotalCompras = () => {
      return formatNumberToTwoFractionDigits(somaCampo('valcompra_ven'))
    }

    const getTotalDeRetorno = () => {
      return formatNumberToTwoFractionDigits(somaCampo('valretorno_ven'))
    }

    const getTotalVeiculosVendidosPeriodo = () => {
      let totalVeiculosVendidosPeriodo = 0

      for (const vendedor of reportData) {
        for (const negociacao of vendedor.negociacoes) {
          if (negociacao.codope_neg !== 4) {
            totalVeiculosVendidosPeriodo++
          }
        }
      }

      return totalVeiculosVendidosPeriodo
    }

    const getTotalDeCustos = () => {
      return formatNumberToTwoFractionDigits(somaCampo('custo_total'))
    }

    const getPercentualDeRetorno = () => {
      return formatNumberToTwoFractionDigits((somaCampo('valretorno_ven') / somaCampo('valor_fpn') * 100) || 0.00)
    }

    const getTotalDeVendasComComplemento = () => {
      return formatNumberToTwoFractionDigits((somaCampo('valvenda_ven') + somaCampo('valcom_fpn')))
    }

    const getTotalLucro = () => {
      return formatNumberToTwoFractionDigits(somaCampo('lucro_venda'))
    }

    const getTacAcordoTotal = () => {
      return formatNumberToTwoFractionDigits(somaCampo('tac_acordo_total'))
    }

    const getPercentualLucro = () => {
      const valorCusto = somaCampo('custo_total') + somaCampo('valcompra_ven') + somaCampo('valor_ratdes')

      const valorVenda = somaCampo('venda_total') + somaCampo('valcom_fpn') + somaCampo('valor_documento')

      return formatNumberToTwoFractionDigits(CommonHelper.getMargem(valorCusto, valorVenda))
    }

    const getTotalJurosPatio = () => {
      return formatNumberToTwoFractionDigits(somaCampo('juros_estoque'))
    }

    const getTotalDeLucroLiquido = () => {
      return formatNumberToTwoFractionDigits((somaCampo('lucro_venda') - somaCampo('valretorno_ven')))
    }

    const getTotalReceitaComDocumento = () => {
      return formatNumberToTwoFractionDigits(somaCampo('valor_documento'))
    }

    const getTotalLucroComJurosEstoque = () => {
      return formatNumberToTwoFractionDigits(somaCampo('lucro_venda_incluso_juros_estoque'))
    }

    return (
      <div className="ml-3 mt-3 mr-3 pb-8">
        <h2 className="totalizadores-gerais-title">Totalizadores gerais</h2>
        <Divider></Divider>
        <div className="totalizadores-container">
          <div className="footer-totalizadorColumn">
            <div className="footer-totalizador">
              <strong className="footer-totalizador-label">Média de dias no pátio: </strong>
              <div>{getMediaDiasNoPatio()}</div>
            </div>

            <div className="footer-totalizador">
              <strong className="footer-totalizador-label">Total de veículos vendidos no período: </strong>
              <div>{getTotalVeiculosVendidosPeriodo()}</div>
            </div>
          </div>

          <div className="footer-totalizadorColumn">
            <div className="footer-totalizador">
              <strong className="footer-totalizador-label">Valor total de compras: </strong>
              <div>{getValorTotalCompras()}</div>
            </div>

            <div className="footer-totalizador">
              <strong className="footer-totalizador-label">Total de custos: </strong>
              <div>{getTotalDeCustos()}</div>
            </div>

            <div className="footer-totalizador">
              <strong className="footer-totalizador-label">Total de vendas com complemento: </strong>
              <div>{getTotalDeVendasComComplemento()}</div>
            </div>

            <div className="footer-totalizador">
              <strong className="footer-totalizador-label">Total de TAC + Acordo: </strong>
              <div>{getTacAcordoTotal()}</div>
            </div>

            <div className="footer-totalizador">
              <strong className="footer-totalizador-label">Total de juros de pátio: </strong>
              <div>{getTotalJurosPatio()}</div>
            </div>

            <div className="footer-totalizador">
              <strong className="footer-totalizador-label">Total de receita com documento: </strong>
              <div>{getTotalReceitaComDocumento()}</div>
            </div>
          </div>

          <div className="footer-totalizadorColumn">
            <div className="footer-totalizador">
              <strong className="footer-totalizador-label">Total de retorno(Sem impostos): </strong>
              <div>{getTotalDeRetorno()}</div>
            </div>

            <div className="footer-totalizador">
              <strong className="footer-totalizador-label">Percentual de retorno: </strong>
              <div>{getPercentualDeRetorno()}</div>
            </div>

            <div className="footer-totalizador">
              <strong className="footer-totalizador-label">Total lucro: </strong>
              <div>{getTotalLucro()}</div>
            </div>

            <div className="footer-totalizador">
              <strong className="footer-totalizador-label">Percentual de lucro: </strong>
              <div>{getPercentualLucro()}</div>
            </div>

            <div className="footer-totalizador">
              <strong className="footer-totalizador-label">Lucro líquido(Lucro - Retorno): </strong>
              <div>{getTotalDeLucroLiquido()}</div>
            </div>

            <div className="footer-totalizador">
              <strong className="footer-totalizador-label">Lucro com juros de estoque: </strong>
              <div>{getTotalLucroComJurosEstoque()}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LucroDeVendasReportFooter
