import React, { useEffect, useState } from 'react'
import AutoCompleteInput from '../../../../../components/inputs/AutoCompleteInput'
import SelectInput from '../../../../../components/inputs/SelectInput'
import TextInput from '../../../../../components/inputs/TextInput'
import { showErrorMessage } from '../../../../../components/utils/Message'
import { getMarcaDTO } from '../../../../../dtos/cadastro/veiculo/MarcaDTO'
import CommonHelper from '../../../../../helpers/CommonHelper'
import EspecieDenatranService from '../../../../../services/cadastro/veiculo/EspecieDenatranService'
import MarcaService from '../../../../../services/cadastro/veiculo/MarcaService'
import TipoVeiculoDenatranService from '../../../../../services/cadastro/veiculo/TipoVeiculoDenatranService'
import Marca from '../../../../../views/cadastro/veiculo/marca/Marca'

const DadosPrincipaisMod = ({ form, edicaoHabilitada, camposObrigatorios }) => {
  const [sugestaoMarcas, setSugestaoMarcas] = useState([])
  const [tiposVeiculoDenatran, setTiposVeiculoDenatran] = useState([])
  const [especiesDenatran, setEspeciesDenatran] = useState([])

  useEffect(async () => {
    Promise.all([
      await getTiposVeiculoDenatran(),
      await getEspeciesDenatran()
    ])
  }, [])

  const getTiposVeiculoDenatran = async () => {
    try {
      const tiposVeicDenatram = await TipoVeiculoDenatranService.getAll()
      setTiposVeiculoDenatran(tiposVeicDenatram)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar os tipos de veículos!')
    }
  }

  const getEspeciesDenatran = async () => {
    try {
      const especiesDenatran = await EspecieDenatranService.getAll()
      setEspeciesDenatran(especiesDenatran)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar as especies do DENATRAN!')
    }
  }

  const onSaveMarcaModal = async (marca) => {
    onSelectMarca({ value: marca })
  }

  const onSelectMarca = (event) => {
    form.setFieldValue('marca', event.value)
    form.validateField('marca.codigo_mar')
  }

  const handleChangeMarca = (e) => {
    form.setFieldValue('marca', { descri_mar: e.target.value })
  }

  const sugerirMarcas = async () => {
    try {
      const marcas = await MarcaService.filterMarcas(form.values.marca.descri_mar)
      setSugestaoMarcas(marcas)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir uma marca!')
    }
  }

  return (
    <div className="formgrid grid fluid">
      <div className="field col-12 sm:col-6 md:col-6">
        <TextInput
          label="Descrição"
          placeholder="Descrição"
          value={form.values.descri_mod}
          name="descri_mod"
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 sm:col-6 md:col-6">
        <AutoCompleteInput
          //AutoComplete
          label="Marca"
          name="marca.codigo_mar"
          field="descri_mar"
          value={form.values.marca?.descri_mar && form.values.marca}
          suggestions={sugestaoMarcas}
          completeMethod={sugerirMarcas}
          onChange={handleChangeMarca}
          onSelect={(e) => onSelectMarca(e)}
          disabled={!edicaoHabilitada}
          camposObrigatorios={camposObrigatorios}
          form={form}
          //RegisterModal
          selected={form.values.marca}
          onSaveModal={onSaveMarcaModal}
          onDeleteModal={() => onSelectMarca({})}
          modalHeader={form.values.marca?.codigo_mar ? `Marca - ${form.values.marca?.codigo_mar}` : 'Cadastro de marca'}
          ComponenteCadastro={Marca}
          //FiltroModal
          filtrotitle="Pesquisa de marca"
          service={MarcaService}
          model={getMarcaDTO}
          primarykeyname="codigo_mar"
          columns={[
            { campo: 'codigo_mar', nome: 'Código' },
            { campo: 'descri_mar', nome: 'Descrição' }
          ]}
        />
      </div>
      <div className="field col-12 sm:col-6 md:col-6">
        <SelectInput
          label="Tipo"
          options={tiposVeiculoDenatran}
          field="tipoVeiculoDenatran.descri_tvd"
          name="tipoVeiculoDenatran.codigo_tvd"
          dataKey="codigo_tvd"
          optionLabel="descri_tvd"
          value={form.values.tipoVeiculoDenatran}
          onChange={(e) => form.setFieldValue('tipoVeiculoDenatran', e.target.value || {})}
          disabled={!edicaoHabilitada}
          filter={CommonHelper.isDesktop()}
          noFloatLabel
          showClear={form.values.tipoVeiculoDenatran?.codigo_tvd}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 sm:col-6 md:col-6  ">
        <SelectInput
          label="Espécie"
          options={especiesDenatran}
          field="especieDenatran.descri_esd"
          name="especieDenatran.codigo_esd"
          dataKey="codigo_esd"
          optionLabel="descri_esd"
          value={form.values.especieDenatran}
          onChange={(e) => {
            form.setFieldValue('especieDenatran', e.target.value || {})
          }}
          disabled={!edicaoHabilitada}
          filter={CommonHelper.isDesktop()}
          noFloatLabel
          showClear={form.values.especieDenatran?.codigo_esd}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
    </div>
  )
}

export default DadosPrincipaisMod
