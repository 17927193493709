import React from 'react'
import { InputNumber } from 'primereact/inputnumber'
import InputBase from './base/InputBase'

class NumberInput extends InputBase {
  renderInput () {
    const { props } = this

    return (
      <InputNumber
        {...props}
        value={props.value || ''}
        onChange={props.onChange}
        useGrouping={props.useGrouping || false}
        onBlur={this.onBlur}
        className={this.getInputClassName()}
      />
    )
  }
}

export default NumberInput
