import React, { Component } from 'react'
import AppButton from './AppButton'

class BtnOption extends Component {
  constructor (props) {
    super(props)
  }

  render () {
    return (
      <div className="btn-option-container">
        <AppButton className="btn-option" {...this.props} style={{ boxShadow: 'none' }}>
          {this.props.children}
        </AppButton>
      </div>
    )
  }
}

export default BtnOption
