export const getGrupoProdutoDTO = (data = {}) => {
  return {
    codigo_grp: data.codigo_grp || null,
    descri_grp: data.descri_grp || null
  }
}

export const postProdutoDTO = (data) => {
  return {
    codigo_pro: data.codigo_pro || null,
    descri_pro: data.descri_pro || null
  }
}
