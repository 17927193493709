import { postCondicaoPagamentoDTO } from '../../dtos/cadastro/financeiro/CondicaoPagamentoDTO'
import ServiceBase from '../base/ServiceBase'

class CondicaoPagamentoService {

  static async parcelarVendas(data, descricaoFiltro, page, perPage) {
    if (!!descricaoFiltro) {
      return await ServiceBase.postBase(`condicaopagamento/parcelarVendas?descricaoFiltro=${descricaoFiltro}&page=${page}&perPage=${perPage}`, postCondicaoPagamentoDTO(data))
    } else {
      return await ServiceBase.postBase(`condicaopagamento/parcelarVendas?&page=${page}&perPage=${perPage}`, postCondicaoPagamentoDTO(data))
    }

  }

  async filter(query, page, perPage, data) {
    return await ServiceBase.postBase(`condicaopagamento/parcelarVendas?${query}&page=${page}&perPage=${perPage}`, postCondicaoPagamentoDTO(data))
  }

  async getAll(query, page, perPage, data) {
    return await ServiceBase.postBase(`condicaopagamento/parcelarVendas?${query}&page=${page}&perPage=${perPage}`, postCondicaoPagamentoDTO(data))
  }

}

export default CondicaoPagamentoService
