import ServiceBase from '../base/ServiceBase'

export default class ConfigService {
  async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`/config?page=${page}&perPage=${perPage}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`/config?${query}&page=${page}&perPage=${perPage}`)
  }

  static async getValor (numero_con) {
    const config = await ServiceBase.getBase(`/config/${numero_con}`)

    return config?.value === undefined ? config : config.value
  }

  static async setValor (numero_con, valor_con, observacoes) {
    return await ServiceBase.putBase(`/config/${numero_con}`, { valor_con, observacoes })
  }

  static async getConfigFiliais (numero_con) {
    return await ServiceBase.getBase(`/config/filiais/${numero_con}`)
  }

  static async getBancoDeDados () {
    return await ServiceBase.getBase('/config/user-bd')
  }
}
