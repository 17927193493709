import React from 'react'
import VeiculoForm from '../../../../forms/cadastro/veiculo/veiculo/VeiculoForm'
import VeiculoService from '../../../../services/cadastro/veiculo/VeiculoService'
import { getVeiculoDTO } from '../../../../dtos/cadastro/veiculo/VeiculoDTO'
import { Crud } from '../../../../classes/NewCrud'
import { VeiculoValidator } from '../../../../forms/cadastro/veiculo/veiculo/VeiculoValidator'

export default function Veiculo (props) {
  return (
    <Crud
      {...props}
      formContent={VeiculoForm}
      modelGetDTO={getVeiculoDTO}
      service={VeiculoService}
      valida={VeiculoValidator}
      resource="veiculos"
      primarykeyname="codigo_vei"
      formTitle="Veículo"
    />
  )
}
