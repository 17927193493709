import { formatDateTimeToAmerican } from '../../../helpers/formaters'

export const getPecaBalcaoDTO = (data = {}) => {
  return {
    codigo_peo: data.codigo_peo || null,
    acrcpg_peo: data.acrcpg_peo || 0.00,
    aliicm_peo: data.aliicm_peo || 0.00,
    aliipi_peo: data.aliipi_peo || 0.00,
    alisut_peo: data.alisut_peo || 0.00,
    bci_peo: data.bci_peo || 0.00,
    bcipi_peo: data.bcipi_peo || 0.00,
    bcisub_peo: data.bcisub_peo || 0.00,
    codcfo_peo: data.codcfo_peo || null,
    codkit_peo: data.codkit_peo || null,
    codicm_peo: data.codicm_peo || null,
    codmcv_peo: data.codmcv_peo || null,
    codopv_peo: data.codopv_peo,
    codpec_peo: data.codpec_peo || data.produto?.codigo_pro,
    codppr_peo: data.codppr_peo || null,
    codsit_peo: data.codsit_peo || null,
    codtpr_peo: data.codtpr_peo || null,
    cusuni_peo: data.cusuni_peo || 0.00,
    dahcan_peo: data.dahcan_peo ? formatDateTimeToAmerican(data.dahcan_peo) : null,
    dahsep_peo: data.dahsep_peo ? formatDateTimeToAmerican(data.dahsep_peo) : null,
    descri_peo: data.descri_peo || data.produto?.descri_pro,
    iscan_peo: data.iscan_peo === undefined ? false : data.iscan_peo,
    qtd_peo: data.qtd_peo || 1,
    mva_peo: data.mva_peo || 0.00,
    pebsut_peo: data.pebsut_peo || 0.00,
    perbas_peo: data.perbas_peo || 0.00,
    perdes_peo: data.perdes_peo || 0.00,
    produto: data.produto || {},
    usuarioSeparacaoPeca: data.usuarioSeparacaoPeca || {},
    refpro_peo: data.refpro_peo || data.produto?.refere_pro,
    siguni_peo: data.siguni_peo || data.produto?.unidade?.abrevi_uni,
    totacrcop_peo: data.totacrcop_peo || 0.00,
    toticm_peo: data.toticm_peo || 0.00,
    totipi_peo: data.totipi_peo || 0.00,
    totisu_peo: data.totisu_peo || 0.00,
    usapreqtd_peo: data.usapreqtd_peo || false,
    usucan_peo: data.usucan_peo || null,
    ususep_peo: data.ususep_peo || null,
    valacr_peo: data.valacr_peo || 0.00,
    valbru_peo: data.valbru_peo || 0.00,
    valdes_peo: data.valdes_peo || 0.00,
    valfre_peo: data.valfre_peo || 0.00,
    valoud_peo: data.valoud_peo || 0.00,
    valpro_peo: data.valpro_peo || 0.00,
    valseg_peo: data.valseg_peo || 0.00,
    valtot_peo: data.valtot_peo || 0.00,
    valuni_peo: data.valuni_peo || 0.00,
    venliq_peo: data.venliq_peo || false,
    venpro_peo: data.venpro_peo || false
  }
}

export const postPecaBalcaoDTO = (data) => {
  return {
    codigo_peo: data.codigo_peo,
    acrcpg_peo: data.acrcpg_peo || 0.00,
    aliicm_peo: data.aliicm_peo || 0.00,
    aliipi_peo: data.aliipi_peo || 0.00,
    alisut_peo: data.alisut_peo || 0.00,
    bci_peo: data.bci_peo || 0.00,
    bcipi_peo: data.bcipi_peo || 0.00,
    bcisub_peo: data.bcisub_peo || 0.00,
    codcfo_peo: data.codcfo_peo || null,
    codkit_peo: data.codkit_peo || null,
    codicm_peo: data.codicm_peo || null,
    codmcv_peo: data.codmcv_peo || null,
    codopv_peo: data.codopv_peo || null,
    codpec_peo: data.codpec_peo || data.produto?.codigo_pro,
    codppr_peo: data.codppr_peo || null,
    codsit_peo: data.codsit_peo || null,
    codtpr_peo: data.codtpr_peo || null,
    cusuni_peo: data.cusuni_peo || 0.00,
    dahcan_peo: data.dahcan_peo ? formatDateTimeToAmerican(data.dahcan_peo) : null,
    dahsep_peo: data.dahsep_peo ? formatDateTimeToAmerican(data.dahsep_peo) : null,
    descri_peo: data.descri_peo || data.produto?.descri_pro,
    iscan_peo: data.iscan_peo === undefined ? false : data.iscan_peo,
    qtd_peo: data.qtd_peo || 1,
    mva_peo: data.mva_peo || 0.00,
    pebsut_peo: data.pebsut_peo || 0.00,
    perbas_peo: data.perbas_peo || 0.00,
    perdes_peo: data.perdes_peo || 0.00,
    produto: data.produto || {},
    refpro_peo: data.refpro_peo || data.produto?.refere_pro,
    siguni_peo: data.siguni_peo || data.produto?.unidade?.abrevi_uni,
    totacrcop_peo: data.totacrcop_peo || 0.00,
    toticm_peo: data.toticm_peo || 0.00,
    totipi_peo: data.totipi_peo || 0.00,
    totisu_peo: data.totisu_peo || 0.00,
    usapreqtd_peo: data.usapreqtd_peo || false,
    usucan_peo: data.usucan_peo || null,
    ususep_peo: data.ususep_peo || null,
    valacr_peo: data.valacr_peo || 0.00,
    valbru_peo: data.valbru_peo || 0.00,
    valdes_peo: data.valdes_peo || 0.00,
    valfre_peo: data.valfre_peo || 0.00,
    valoud_peo: data.valoud_peo || 0.00,
    valpro_peo: data.valpro_peo || 0.00,
    valseg_peo: data.valseg_peo || 0.00,
    valtot_peo: data.valtot_peo || 0.00,
    valuni_peo: data.valuni_peo || 0.00,
    venliq_peo: data.venliq_peo || false,
    venpro_peo: data.venpro_peo || false

  }
}
