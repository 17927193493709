import AprovacaoNegociacaoService from '../services/vendas/negociacao/AprovacaoNegociacaoService'
import PagarConsignadosService from '../services/vendas/negociacao/PagarConsignadosService'
import {
  ATUALIZAR_QUANTIDADE_CONSIGNACOES_PENDENTE_PAGAMENTO,
  ATUALIZAR_QUANTIDADE_NEGOCIACOES_PENDENTE_APROVACAO,
  CLEAR_STORE,
  DECREMENT_ACTIVE_LOADING_REQUESTS,
  GENERATE_MENU_SIDEBAR,
  INCREMENT_ACTIVE_LOADING_REQUESTS,
  REMOVE_MARCA,
  REMOVE_PESSOA_FISICA,
  REMOVE_PESSOA_JURIDICA,
  REMOVE_VEICULO,
  SET_EMPRESA_LOGADA,
  SET_INICIAL_LOADED,
  SET_MARCA,
  SET_MENUSACESSO,
  SET_PESSOA_FISICA,
  SET_PESSOA_JURIDICA,
  SET_USER_AS_AUTENTICATED,
  SET_USER_AS_NOT_AUTENTICATED,
  SET_USUARIO_LOGADO,
  SET_VEICULO,
  START_LOADING,
  STOP_LOADING
} from './types'

export const setInicialLoaded = () => {
  return { type: SET_INICIAL_LOADED }
}

export const setUserAsAutenticated = () => {
  return { type: SET_USER_AS_AUTENTICATED }
}

export const setUserAsNotAutenticated = () => {
  return { type: SET_USER_AS_NOT_AUTENTICATED }
}

export const startLoading = () => {
  return { type: START_LOADING }
}

export const stopLoading = () => {
  return { type: STOP_LOADING }
}

export const setMenusAcesso = (payload) => {
  return { type: SET_MENUSACESSO, payload }
}

export const setUsuarioLogado = (payload) => {
  return { type: SET_USUARIO_LOGADO, payload }
}

export const setEmpresaLogada = (payload) => {
  return { type: SET_EMPRESA_LOGADA, payload }
}

export const setPessoaJuridica = (payload) => {
  return { type: SET_PESSOA_JURIDICA, payload }
}

export const removePessoaJuridica = () => {
  return { type: REMOVE_PESSOA_JURIDICA }
}

export const setPessoaFisica = (payload) => {
  return { type: SET_PESSOA_FISICA, payload }
}

export const removePessoaFisica = () => {
  return { type: REMOVE_PESSOA_FISICA }
}

export const setMarca = (payload) => {
  return { type: SET_MARCA, payload }
}

export const removeMarca = () => {
  return { type: REMOVE_MARCA }
}

export const setVeiculo = (payload) => {
  return { type: SET_VEICULO, payload }
}

export const removeVeiculo = () => {
  return { type: REMOVE_VEICULO }
}

export const incrementActiveLoadingRequests = () => {
  return { type: INCREMENT_ACTIVE_LOADING_REQUESTS }
}

export const decrementActiveLoadingRequests = () => {
  return { type: DECREMENT_ACTIVE_LOADING_REQUESTS }
}

export const clearStore = () => {
  return { type: CLEAR_STORE }
}

export const atualizarQuantidadeConsignacoesPendentePagamento = async () => {
  try {
    const quantidadePagarConsignados = await PagarConsignadosService.getQuantidade()
    return { type: ATUALIZAR_QUANTIDADE_CONSIGNACOES_PENDENTE_PAGAMENTO, payload: quantidadePagarConsignados }
  } catch (error) {
    console.error(error)
  }
}

export const atualizarQuantidadeNegociacoesPendenteAprovacao = async () => {
  try {
    const quantidadeAprovacaoNegociacoes = await AprovacaoNegociacaoService.getQuantidade()
    return { type: ATUALIZAR_QUANTIDADE_NEGOCIACOES_PENDENTE_APROVACAO, payload: quantidadeAprovacaoNegociacoes }
  } catch (error) {
    console.error(error)
  }
}

export const generateMenuSideBar = () => {
  return { type: GENERATE_MENU_SIDEBAR }
}
