import * as Yup from 'yup'

export const TedPixValidator = Yup.object().shape({
  veiculo: Yup.object().shape({
    codigo_vei: Yup.number()
      .nullable()
      .required('O veículo não foi selecionado!')
  }),
  banco: Yup.object().shape({
    codigo_ban: Yup.number()
      .nullable()
      .test('testeObrigarCampos', 'O banco não foi selecionado!', function (value) {
        if (this.parent.isCompra && this.parent.isLojaPaga && this.parent.isPix && this.parent.obrigarCampos && !value) {
          return false
        }

        return true
      })
  }),
  numeroAgencia: Yup.number()
    .nullable().
    test('testeObrigarCampos', 'O número da agência não foi informada!', function (value) {
      if (this.parent.isCompra && this.parent.isLojaPaga && this.parent.isPix && this.parent.obrigarCampos && !value) {
        return false
      }

      return true
    }),
  numeroConta: Yup.number()
    .nullable().
    test('testeObrigarCampos', 'O número da conta não foi informada!', function (value) {
      if (this.parent.isCompra && this.parent.isLojaPaga && this.parent.isPix && this.parent.obrigarCampos && !value) {
        return false
      }

      return true
    }),
  titular: Yup.string()
    .nullable().
    test('testeObrigarCampos', 'O titular não foi informada!', function (value) {
      if (this.parent.isCompra && this.parent.isLojaPaga && this.parent.isPix && this.parent.obrigarCampos && !value) {
        return false
      }

      return true
    }),
  valor: Yup.number()
    .nullable()
    .required('O valor não foi informado!')
    .min(0.001, 'O valor não foi informado!'),
  dataEmissao: Yup.date()
    .nullable()
    .required('A data da operação não foi informada!'),
  observacoes: Yup.string()
    .nullable().
    test('testeObrigarCampos', 'A observação não foi informada!', function (value) {
      if (this.parent.isCompra && this.parent.isLojaPaga && this.parent.isPix && this.parent.obrigarCampos && !value) {
        return false
      }

      return true
    })

})
