import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import LayoutEmailService from '../../services/cadastro/email/LayoutEmailService'
import ServidorEmailService from '../../services/cadastro/email/ServidorEmailService'
import SelectInput from '../inputs/SelectInput'
import TextAreaInput from '../inputs/TextAreaInput'
import TextInput from '../inputs/TextInput'
import { showWarnMessage } from './Message'
import Modal from './Modal'

function EnviarEmailModal ({ visible, onHide, onConfirm, emailCliente }) {
  const [leiautesEmail, setLeiautesEmail] = useState([])
  const [leiauteEmailSelecionado, setLeiauteEmailSelecionado] = useState(null)

  const [servidoresEmail, setServidoresEmail] = useState([])
  const [servidorEmailSelecionado, setServidorEmailSelecionado] = useState(null)

  const [emailEnviar, setEmailEnviar] = useState('')
  const [observacao, setObservacao] = useState('')
  const [responderPara, setResponderPara] = useState('')
  const [comCopiaPara, setComCopiaPara] = useState('')

  useEffect(async () => {
    if (visible === true) {
      await getLeiautesEmail()
      await getServidoresEmail()
      setEmailEnviar(emailCliente)
    }
  }, [visible])

  async function getLeiautesEmail () {
    const leiautes = await LayoutEmailService.getAll()
    setLeiautesEmail(leiautes)
  }

  async function getServidoresEmail () {
    const servidoresEmail = await ServidorEmailService.getAll()
    setServidoresEmail(servidoresEmail)
  }

  async function indicarLeiauteEmailSelecionado (leiauteEmailSelecionado) {
    setLeiauteEmailSelecionado(leiauteEmailSelecionado)
    setResponderPara(leiauteEmailSelecionado.emares_lem)
    setComCopiaPara(leiauteEmailSelecionado.emacco_lem)
  }

  async function indicarServidorEmailSelecionado (servidorEmailSelecionado) {
    setServidorEmailSelecionado(servidorEmailSelecionado)
  }

  async function handleConfirm () {
    if ((!leiauteEmailSelecionado)) {
      showWarnMessage('Selecione um leiaute de e-mail!')
      return
    }

    if ((!servidorEmailSelecionado)) {
      showWarnMessage('Selecione um servidor de e-mail!')
      return
    }

    if ((!emailEnviar)) {
      showWarnMessage('Informe para quem deve ser enviado o e-mail!')
      return
    }

    const dadosRetornoEnvioEmail = {
      leiauteEmail: leiauteEmailSelecionado,
      servidorEmail: servidorEmailSelecionado,
      observacao: observacao,
      emailEnviar: emailEnviar,
      responderPara: responderPara,
      comCopiaPara: comCopiaPara
    }
    await onConfirm(dadosRetornoEnvioEmail)

    onHide()
  }

  return (
    <Modal
      visible={visible}
      onHide={onHide}
      header="Enviar e-mail"
      width={40}
      footer={
        <Button
          label="Confirmar"
          onClick={async () => await handleConfirm()}
        />
      }
    >
      <div className="formgrid grid fluid">
        <div className="field col-12">
          <SelectInput
            label="Leiaute de e-mail"
            dataKey="codigo_lem"
            optionLabel="descri_lem"
            value={leiauteEmailSelecionado}
            options={leiautesEmail}
            onChange={(e) => indicarLeiauteEmailSelecionado(e.target.value)}
          />
        </div>
        <div className="field col-12">
          <SelectInput
            label="Enviar com"
            dataKey="codigo_sma"
            optionLabel="descri_sma"
            value={servidorEmailSelecionado}
            options={servidoresEmail}
            onChange={(e) => indicarServidorEmailSelecionado(e.target.value)}
          />
        </div>
        <div className="field col-12">
          <TextAreaInput
            label="Enviar para"
            name="emailEnvio"
            className="inputfield w-full"
            cols={1000}
            rows={1}
            value={emailEnviar}
            onChange={(e) => setEmailEnviar(e.target.value)}
          />
        </div>
        <div className="field col-12">
          <TextAreaInput
            label="Observação"
            name="observacao"
            className="inputfield w-full"
            cols={1000}
            rows={3}
            value={observacao}
            onChange={(e) => setObservacao(e.target.value)}
          />
        </div>
        <div className="field col-12">
          <TextInput
            label="Responder para"
            name="responderPara"
            value={responderPara}
            onChange={(e) => setResponderPara(e.target.value)}
            disabled={false}
          />
        </div>
        <div className="field col-12">
          <TextInput
            label="Com cópia para"
            name="comCopiaPara"
            value={comCopiaPara}
            onChange={(e) => setComCopiaPara(e.target.value)}
            disabled={false}
          />
        </div>
      </div>
    </Modal>
  )
}

export default EnviarEmailModal
