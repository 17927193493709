export const loc = {
  apiGateway: {
    URL: 'http://localhost:3333/'
  },
  clientInfo: {
    APP_URL: 'http://localhost:3000/'
  }
}

export const dev = {
  apiGateway: {
    URL: 'https://api.dev.sancesturbo.com.br/'
  },
  clientInfo: {
    APP_URL: 'https://dev.sancesturbo.com.br/'
  }
}

export const prod = {
  apiGateway: {
    URL: 'https://api.sancesturbo.com.br/'
  },
  clientInfo: {
    APP_URL: 'https://sancesturbo.com.br/'
  }
}
