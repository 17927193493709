import React from 'react'
import { Button } from 'primereact/button'
import List from '../../../../classes/List'
import Container from '../../../../components/layout/Container'
import Page from '../../../../components/layout/Page'
import DataList from '../../../../components/layout/DataList'
import TablePreferences from '../../../../components/utils/TablePreferences'
import { createColumns, getColunasHabilitadas } from '../../../../helpers/tableConfigs'
import PreferencesButton from '../../../../components/utils/PreferencesButton'
import MarcaService from '../../../../services/cadastro/veiculo/MarcaService'
import { getMarcaDTO } from '../../../../dtos/cadastro/veiculo/MarcaDTO'
import CommonHelper from '../../../../helpers/CommonHelper'
import { classNames } from 'primereact/utils'

export class MarcaList extends List {
  constructor (props) {
    super(props, getMarcaDTO, MarcaService, 'marca', 'codigo_mar')

    this.state = {
      ...this.state,
      visiblePreferencesModal: false
    }

    this.setColumns(createColumns([
      { field: 'codigo_mar', header: 'Código', width: '50%' },
      { field: 'descri_mar', header: 'Descrição', width: '50%' }
    ]))
  }

  render () {
    const { rows, totalRecords, first } = this.state
    const columns = this.columns

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Marcas</h4>
          <div className="page-header-buttons">
            <Button
              className="mr-2"
              label={CommonHelper.isDesktop() && 'Novo'}
              icon="pi pi-plus"
              onClick={this.onNew}
            />
          </div>
        </div>
        <div className="table-options-container">
          <div className="tabel-options">
            <div className={classNames({ active: this.state.opcaoSelecionada === 't' }, 'filter-option')}>
              <span className="option-label">Todas</span>
              <div className="option-quantity">
                {this.state.totalRecords}
              </div>
            </div>
          </div>
          <div className="table-preferences">
            <PreferencesButton
              onClick={() => this.setState({ visiblePreferencesModal: true })}
            />
          </div>
        </div>
        <Container>
          <DataList
            data={this.state.list}
            selected={this.state.selected}
            rows={rows}
            onSelect={this.onSelect}
            totalRecords={totalRecords}
            first={first}
            onPage={this.onPageChange}
            page={this.state.page}
            onRowSelect={(e) => this.onView(e.data)}
            showFilter={true}
            filterPlaceholder="Procurar Marcas"
            filterName="descricaoFiltro"
            responsive={true}
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
          >
            {getColunasHabilitadas(columns, 'marca')}
          </DataList>
        </Container>
        <TablePreferences
          title="Preferências da tabela de marca"
          tableName="marca"
          columns={columns}
          visible={this.state.visiblePreferencesModal}
          hide={() => this.setState({ visiblePreferencesModal: false })}
        />
      </Page>
    )
  }
}
