import ServiceBase from '../../base/ServiceBase'

export default class FormaPagamentosService {
  static async filterTiposPagamentos () {
    return await ServiceBase.getBase('tipoformapagamentos')
  }

  static async filterTiposRestricaoVeiculo () {
    return await ServiceBase.getBase('tiporestricaoveiculo')
  }

  static async filterFormaCobranca () {
    return await ServiceBase.getBase('formascobranca')
  }

  static async getSaldo (data) {
    return await ServiceBase.postBase('formaspagamento/saldo', data)
  }

  static async getPercentualILA () {
    return await ServiceBase.getBase('formaspagamento/percentualILA')
  }

  static async podeAdcVeiculoTroca (veitro_fpn) {
    return await ServiceBase.getBase(`formaspagamento/podeAdcVeiculoTroca/${veitro_fpn}`)
  }
}
