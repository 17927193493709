import React, { useState } from 'react'
import onClickOutside from 'react-onclickoutside'
import AppButton from '../AppButton'
import DropdownItem from './DropdownItem'
import './_dropdownButton.scss'

const DropdownMenu = (props) => {
  const [open, setOpen] = useState(false)

  DropdownMenu.handleClickOutside = (event) => {
    if (!(event.target.id === 'dropdown')) {
      setOpen(false)
    }
  }

  const dropdownItems = props.items.map((item, idx) => {
    if (item === '') {
      return
    }
    return (
      <DropdownItem
        first={idx === 0 ? true : false}
        label={item.label}
        key={idx}
        onClick={async () => {
          await item.command()
          setOpen(false)
        }}
      />
    )
  })

  return (
    <div className={'dropdown-container flex ' + (props.dropdownPosition === 'rigth' ? '' : 'justify-content-end')}>
      <AppButton
        onClick={() => setOpen(!open)}
        label={props.label}
        icon={props.icon}
        disabled={props.disabled}
        className="dropdown-button"
      />
      {open && (
        <div className="dropdown" id="dropdown">
          {dropdownItems}
        </div>
      )}
    </div>
  )
}

const clickOutsideConfig = {
  handleClickOutside: () => DropdownMenu.handleClickOutside
}

export default onClickOutside(DropdownMenu, clickOutsideConfig)
