import React, { useEffect, useState } from 'react'
import AES from '../../../helpers/AES'
import CommonHelper from '../../../helpers/CommonHelper'
import LandingPixService from '../../../services/cadastro/financeiro/integracao/LandingPixService'
import './_landingPix.scss'
import { showErrorMessage } from '../../../components/utils/Message'
import { formatDateTime, formatToCurrency } from '../../../helpers/formaters'
import SharePixModal from './SharePixModal'
import AppButton from '../../../components/layout/AppButton'

const LandingPix = (props) => {
  const [loading, setLoading] = useState(true)
  const [veiculo, setVeiculo] = useState({})
  const [empresa, setEmpresa] = useState({})
  const [pix, setPix] = useState({})
  const [logoEmpresa, setLogoEmpresa] = useState({})
  const [siteEmpresa, setSiteEmpresa] = useState(null)

  useEffect(async () => {
    try {
      const decryptedDatabase = AES.decryptWithAES(CommonHelper.formatURLtoString(props.match.params.database))
      const veiculo = await LandingPixService.getLandingVeiculoPix(props.match.params.codNve, decryptedDatabase)
      const empresa = await LandingPixService.getDadosEmpresa(props.match.params.empresaId, decryptedDatabase)
      const pix = await LandingPixService.getLandingPix(props.match.params.codNve, decryptedDatabase)

      if (empresa.urlsit_pej.includes('http')) {
        setSiteEmpresa(empresa.urlsit_pej)
      } else {
        setSiteEmpresa(`http://${empresa.urlsit_pej}`)
      }

      setLogoEmpresa(logoEmpresa[0])
      setVeiculo(veiculo)
      setEmpresa(empresa)
      setPix(pix)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar dos dados do PIX!')
    }
  }, [])

  useEffect(() => {
    if (veiculo.codigo_vei) {
      setLoading(false)
    }
  }, [veiculo])

  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      {loading && (
        <div id="root">
          <div className="splash-screen">
            <div className="splash-container">
              <div className="splash-double-bounce1"></div>
              <div className="splash-double-bounce2"></div>
            </div>
          </div>
        </div>
      )}
      {!loading && (
        <div className="landing-pix-container">
          <div className="landing-veiculo-header">
            {logoEmpresa?.url && (
              <img className="logo-empresa" src={logoEmpresa.url} />
            )}
            <h1 className="header-text">{empresa.apelid_pes}</h1>
          </div>
          <div className="veiculo-header">
            <h4>{veiculo.descri_mar}/{veiculo.descri_mod}</h4>
          </div>
          <div className="veiculo-info">
            <div className="veiculo-info-column">
              <div className="veiculo-info-row">
                <span>Marca/Modelo:</span> {veiculo.descri_mar}/{veiculo.descri_mod}
              </div>
              <div className="veiculo-info-row">
                <span>Ano:</span> {veiculo.anofab_vei}/{veiculo.anomod_vei}
              </div>
              <div className="veiculo-info-row">
                <span>Km:</span> {veiculo.km_vei.toLocaleString('pt-BR')}
              </div>
              {veiculo.descri_cam && (
                <div className="veiculo-info-row">
                  <span>Câmbio:</span> {veiculo.descri_cam}
                </div>
              )}
            </div>
            <div className="veiculo-info-column">
              {veiculo.descri_cor && (
                <div className="veiculo-info-row">
                  <span>Cor:</span> {veiculo.descri_cor}
                </div>
              )}
              {veiculo.descri_com && (
                <div className="veiculo-info-row">
                  <span>Combustível:</span> {veiculo.descri_com}
                </div>
              )}
              {veiculo.placa_vei && (
                <div className="veiculo-info-row">
                  <span>Final da placa:</span> {String(veiculo.placa_vei).charAt(6)}
                </div>
              )}
            </div>
          </div>

          <div className="landing-pix-header">
            <span className="header-text">PAGAMENTO POR PIX: {formatToCurrency(veiculo.preco_pre)}</span>
          </div>
          <div className="share-button-container">
            <button onClick={() => setIsModalOpen(true)}><i className="pi pi-share-alt"></i> Compartilhar</button>
          </div>
          <div className="pix-items">
            {(Array.isArray(pix) ? pix : []).map((item, index) => (
              <div key={item.codigo_pix} className="pix-info-row">
                <div className="pix-qr-code">
                  <div className = "pix-valor">
                    <p>PIX: {formatToCurrency(item.valor_pix)}
                    </p>
                  </div>
                  <img
                    src={`data:image/png;base64,${item.qrcode_pix}`}
                    alt={`QR Code PIX ${index + 1}`}
                    className='pix-qr-img'
                  />
                  <div className = "pix-dataexp">
                    <p>Expira em: {formatDateTime(item.dathorexp_pix)}
                    </p>
                  </div>
                </div>
                <div className='pix-code'>
                  <div className="pix-code">
                    <textarea
                      readOnly
                      value={item.copcol_pix}
                      className="pix-code-input"
                    />
                    <AppButton
                      className="copy-pix-btn"
                      onClick={() => navigator.clipboard.writeText(window.location.href)}
                      tooltip="Link copiado"
                      tooltipOptions={{
                        event: 'focus',
                        hideDelay: 100,
                        position: 'top',
                        className: 'link-tooltip'
                      }}

                    >
                    Copiar PIX
                    </AppButton>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="landing-pix-footer flex justify-content-start">
            <img className="logo-sances" src="https://sancesturboweb.s3.sa-east-1.amazonaws.com/logoemp.jpg" />
            {siteEmpresa && (
              <div className="footer-text-container">
                <h3 className="footer-text" onClick={() => window.open(siteEmpresa)}>{siteEmpresa.split('//')[1]}</h3>
              </div>
            )}
          </div>

        </div>

      )}
      <SharePixModal
        visible={isModalOpen}
        hide={() => setIsModalOpen(false)}
        database={props.match.params.database}
        empresa={empresa}
        veiculo={veiculo}
      />
    </>
  )
}

export default LandingPix
