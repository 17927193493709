import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import React, { useEffect, useState } from 'react'
import DataList from '../../../../../components/layout/DataList'
import { baseForm } from '../../../../../components/utils/BaseForm'
import { showWarnMessage } from '../../../../../components/utils/Message'
import ResponsiveColumn from '../../../../../components/utils/ResponsiveColumn'
import { getConveniadoDTO, postConveniadoDTO } from '../../../../../dtos/cadastro/pessoa/ConveniadoDTO'
import ModalPessoaConvenio from './ModalPessoaConvenio'
const DadosConvenioPej = ({ form, edicaoHabilitada, camposObrigatorios }) => {
  const [visibleModalPessoaConvenio, setVisibleModalPessoaConvenio] = useState(false)
  const [conveniadoSelecionado, setConveniadoSelecionado] = useState(null)
  const [totalRecords, setTotalRecords] = useState(0)
  useEffect(() => {
    setTotalRecords(form.values.conveniados.length)
  }, [form.values.conveniados])

  function insertOrUpdatePeca () {
    if (conveniadoSelecionado) {
      editarPeca()
    } else {
      adicionarPeca()
    }

    setVisibleModalPessoaConvenio(false)
  }

  function verificarSeJaFoiAdicionado (conveniados) {
    return conveniados.some(conveniado => conveniado.codigo_pes === formConveniado.values.conveniado.codigo_pes)
  }

  function adicionarPeca () {
    const conveniado = form.values.conveniados
    const jaAdicionado = verificarSeJaFoiAdicionado(conveniado)
    if (!jaAdicionado) {
      conveniado.push(postConveniadoDTO(formConveniado.values.conveniado))
      conveniado.sort((a, b) => Number(a.codigo_pes) - Number(b.codigo_pes))
      form.setFieldValue('conveniado', conveniado)
    } else {
      showWarnMessage('Já existe essa pessoa adicionada!')
    }
  }

  function editarPeca () {
    const conveniado = form.values.conveniados

    const indexConveniado = conveniado.indexOf(conveniadoSelecionado)
    conveniado.splice(indexConveniado, 1, postConveniadoDTO(formConveniado.values.conveniado))

    form.setFieldValue('conveniado', conveniado)
  }

  const formConveniado = baseForm({
    initialValues: getConveniadoDTO(),
    onSubmit: insertOrUpdatePeca
  })

  return (
    <div className="formgrid grid fluid">
      <div className='w-full md:flex justify-content-between'>
        <div>
        </div>

        <div className="field col-12 md:col-4 flex-column justify-content-end mg:m-8">
          <div className='flex  sm:w-full justify-content-end'>

            <Button
              label="Adicionar conveniado"
              className=' mr-2'
              icon="pi pi-plus"
              onClick={() => setVisibleModalPessoaConvenio(true)}
              disabled={!edicaoHabilitada}
            />
          </div>
        </div>

      </div>
      <div className="field col-12">
        <DataList
          data={form.values.conveniados}
          rows={totalRecords}
          hidePaginator={true}
          totalRecords={totalRecords}
          first={1}
          page={1}
          showFilter={false}
          responsive={true}
          onSelect={e => setConveniadoSelecionado(e)}
          selected={conveniadoSelecionado}
          onRowSelect={(e) => setVisibleModalPessoaConvenio(true)}

        >
          <Column
            header="Código"
            body={(data) => (
              <ResponsiveColumn
                className="pl-2"
                column="Código"
                value={data.codigo_pes}
              />
            )}
            headerClassName="HeaderDataList"
            sortable={true}
            field="codigo_pes"
          />
          <Column
            header="Conveniados"
            body={(data) => (
              <ResponsiveColumn
                className="pl-2"
                column="Conveniados"
                value={data.nomraz_pes}
              />
            )}
            headerClassName="HeaderDataList"
            sortable={true}
            field="nomraz_pes"
          />
          <Column
            header="Ativo?"
            body={(data) => (
              <ResponsiveColumn
                className="pl-2"
                column="Ativo?"
                value={data.ativo_cvp ? 'Sim' : 'Não'}
              />
            )}
            headerClassName="HeaderDataList"
            sortable={true}
            field="ativo_cpv"
          />

        </DataList>
        <ModalPessoaConvenio
          camposObrigatorios={camposObrigatorios}
          visible={visibleModalPessoaConvenio}
          onHide={() => setVisibleModalPessoaConvenio(false)}
          edicaoHabilitada={edicaoHabilitada}
          form={formConveniado}
          conveniados={form.values.conveniados}
          selected={conveniadoSelecionado}
          setSelected={setConveniadoSelecionado}
        />
      </div>

    </div>
  )
}

export default DadosConvenioPej
