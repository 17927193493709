import ServiceBase from '../../base/ServiceBase'

export default class PecaBalcaoService {
  async getFormValidator () {
    return await ServiceBase.getBase('pecaBalcao/getFormValidator')
  }

  static async verificaPrecoDasPecasPorTipoPreco (orcamentoBalcao, tipoPreco) {
    return await ServiceBase.postBase('pecaBalcao/verificaPrecoDasPecasPorTipoPreco', { orcamentoBalcao, tipoPreco })
  }

  static async persistencia (codProduto, codTipoPreco) {
    return await ServiceBase.getBase(`pecaBalcao/persistenciaProduto/${codProduto}/${codTipoPreco}`)
  }

  static async getLimiteDesconto (parMargem, codEmp) {
    return await ServiceBase.getBase(`pecaBalcao/limiteDesconto/${parMargem}/${codEmp}`)
  }
}
