
import React from 'react'
import TextInput from '../../../../../components/inputs/TextInput'

const DadosAvaliacaoInfoAdicional = ({ form, edicaoHabilitada }) => {
  return (
    <>
      <div className="field col-12 md:col-4">
        <TextInput
          label="Avaliado em"
          value={form.values.empresa?.pessoa?.apelid_pes || form.values.empresa?.pessoa?.nomraz_pes}
          placeholder="Cadastrado por"
          className="inputfield w-full"
          disabled={true}
        />
      </div>
      <div className="field col-12 md:col-4">
        <TextInput
          label="Avaliação cadastrada por"
          value={form.values.usuario.pessoa?.nomraz_pes}
          placeholder="Cadastrado por"
          className="inputfield w-full"
          disabled={true}
        />
      </div>
      <div className="field col-12 md:col-4">
        <TextInput
          label="Avaliação cadastrada em"
          value={form.values.dathor_ava}
          placeholder="Cadastrado por"
          className="inputfield w-full"
          disabled={true}
        />
      </div>
    </>)
}

export default DadosAvaliacaoInfoAdicional
