import { Toast } from 'primereact/toast'
import React, { Component } from 'react'

let showMessageFn

class Message extends Component {
  render () {
    return (
      <Toast ref={(el) => this.toast = el} />
    )
  }

  componentDidMount () {
    showMessageFn = this.showMessage
  }

    addToast = (toastMessage) => {
      let time = 100

      if (!toastMessage.life) {
        if (toastMessage.length) {
          toastMessage.forEach((message) => {
            let newLife = message.detail.length * time

            if (newLife) {
              message.life = newLife
            }
          })
        } else {
          let newLife = toastMessage.detail.length * time

          if (newLife) {
            toastMessage.life = newLife
          }
        }
      }

      this.toast.show(toastMessage)
    };

  showMessage = (toastMessage) => {
    this.addToast(toastMessage)
  };
}

export function showMessage (toastMessage = ToastMessage) {
  showMessageFn(toastMessage)
}

export const showErrorMessage = (message, lifeTimeMessage = 5000) => {
  showMessage({ severity: 'error', detail: message, life: lifeTimeMessage })
}
export const showInfoMessage = (message, lifeTimeMessage = 5000) => {
  showMessage({ severity: 'info', detail: message, life: lifeTimeMessage })
}

export const showSuccessMessage = (message, lifeTimeMessage = 5000) => {
  showMessage({ severity: 'success', detail: message, life: lifeTimeMessage })
}

export const showWarnMessage = (message, lifeTimeMessage = 5000) => {
  showMessage({ severity: 'warn', detail: message, life: lifeTimeMessage })
}

export default Message
