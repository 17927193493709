import React, { Component } from 'react'

class AccordionPage extends Component {
  constructor (props) {
    super(props)
  }

  render () {
    const props = this.props

    return (
      <div className={props.className}>
        {props.children}
      </div>
    )
  }
}

export default AccordionPage
