import React, { useState, useEffect } from 'react'
import { InputTextarea } from 'primereact/inputtextarea'
import { Button } from 'primereact/button'
import ComentariosService from '../../../services/cadastro/pessoa/ComentariosService'
import { formatDateTime } from '../../../helpers/formaters'
import ExcluirComentarioModal from './ExcluirComentarioModal'
import { showErrorMessage, showWarnMessage } from '../../../components/utils/Message'

const ComentariosPes = ({ edicaoHabilitada, tabela, codigo }) => {
  const [comentarios, setComentarios] = useState([])
  const [comentariosString, setComentariosString] = useState('')
  const [comentario, setComentario] = useState('')
  const [isVisibleExcluirModal, setIsVisibleExcluirModal] = useState(false)

  useEffect(() => {
    getComentarios()
  }, [])

  const formataComentario = (comentario) => {
    let comentariosString = ''

    comentariosString += `#${comentario.ordcom_com} `
    comentariosString += `${comentario.usuarioInsercao.pessoa?.apelid_pes} `
    comentariosString += `(${formatDateTime(comentario.dahins_com)})\r`
    comentariosString += comentario.coment_com

    return comentariosString
  }

  const getComentarios = async () => {
    try {
      const comentarios = await ComentariosService.getComentarios(tabela, codigo)

      let comentariosString = ''

      comentarios.forEach(comentario => {
        if (comentariosString !== '') {
          comentariosString += '\r\r'
        }

        comentariosString += formataComentario(comentario)
      })

      setComentariosString(comentariosString)
      setComentarios(comentarios)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar os comentários!')
    }
  }

  const addComentario = async () => {
    try {
      let stringComentarios = comentariosString

      if (comentario !== '') {
        const response = await ComentariosService.addComentario(tabela, codigo, comentario)

        if (stringComentarios !== '') {
          stringComentarios += '\r\r'
        }

        stringComentarios += formataComentario(response)

        setComentario('')
        setComentariosString(stringComentarios)
        setComentarios([...comentarios, response])
      }
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao adicionar o comentário!')
    }
  }

  const excluirComentario = async (comentario) => {
    try {
      await ComentariosService.excluirComentario(comentario.codigo_com)
      await getComentarios()
    } catch (error) {
      showWarnMessage(error.message || 'Erro ao excluir comentário')
    }
  }

  return (
    <div className="formgrid grid" style={{ paddingTop: '0.5em' }}>
      <div className="field col-12 md:col-6">
        <InputTextarea
          rows={3}
          autoResize
          value={comentariosString}
          disabled={true}
          className="inputfield w-full"
        />
      </div>
      <div className="col-0 md:col-6" />
      <div className="field col-12 md:col-6">
        <InputTextarea
          rows={3}
          autoResize
          value={comentario}
          onChange={(e) => setComentario(e.target.value)}
          disabled={edicaoHabilitada}
          className="inputfield w-full"
        />
      </div>
      <div className="col-12 md:col-6" />
      <div className="field col-6 md:col-3">
        <Button
          type="button"
          label="Excluir"
          icon="pi pi-times"
          onClick={() => setIsVisibleExcluirModal(true)}
          disabled={edicaoHabilitada}
          className="p-button-danger inputfield w-full"
        />
      </div>
      <div className="field col-6 md:col-3">
        <Button
          type="button"
          label="Adicionar"
          icon="pi pi-plus"
          onClick={() => addComentario()}
          disabled={edicaoHabilitada}
          className="p-button-primary inputfield w-full"
        />
      </div>
      {isVisibleExcluirModal && (
        <ExcluirComentarioModal
          comentarios={comentarios}
          hide={() => setIsVisibleExcluirModal(false)}
          excluirComentario={excluirComentario}
        />
      )}
    </div>
  )
}

export default ComentariosPes
