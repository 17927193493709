import { Button } from 'primereact/button'
import { Fieldset } from 'primereact/fieldset'
import { InputText } from 'primereact/inputtext'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import NumberInput from '../../../../components/inputs/NumberInput'
import TextInput from '../../../../components/inputs/TextInput'
import { showErrorMessage, showSuccessMessage } from '../../../../components/utils/Message'
import CommonHelper from '../../../../helpers/CommonHelper'
import { formatCurrencyToNumber, formatToCurrency } from '../../../../helpers/formaters'
import ProdutoEmpresaService from '../../../../services/cadastro/produto/ProdutoEmpresaService'
import VeiculoService from '../../../../services/cadastro/veiculo/VeiculoService'

import AppButton from '../../../../components/layout/AppButton'
import { startLoading, stopLoading } from '../../../../redux/actions'
import { venderVeiculo } from '../../persistencia/VenderVeiculo'

const ValorVendaVeiculoConjunto = ({ totalCustoVeiculosConjunto, ...props }) => {
  const dispatch = useDispatch()
  const [state, setState] = useState({
    veiculo: {},
    sugestaoVeiculos: [],
    descricaoVeiculo: '',
    collapsedPanelCustos: CommonHelper.isDesktop() ? false : true,
    collapsedPanelVenda: CommonHelper.isDesktop() ? false : true,
    custos: {
      valorCompra: 0,
      jurosEstoque: 0,
      rateioDespesas: 0,
      valorManutencoes: 0,
      valorDocumentacao: 0,
      comissaoVendedor: 0,
      outrosCustos: 0,
      totalCustos: 0
    },
    venda: {
      preco: 0,
      valorFinanciado: 0,
      retorno: 0,
      outrasReceitas: veiculo?.custos || 0,
      totalVenda: 0,
      lucroReaisVenda: 0,
      lucroPorcentagemVenda: 0,
      valorMinimo: 0,
      lucroReaisMinimo: 0,
      lucroPorcentagemMinimo: 0,
      valorEcommerce: 0,
      lucroReaisEcommerce: 0,
      lucroPorcentagemEcommerce: 0
    }
  })

  useEffect(() => {
    const { codvei } = props
    if (codvei) {
      setDadosVeiculo(codvei)
    }
  }, [props.codvei])
  useEffect(() => {
    if (state.veiculo?.codigo_vei) {
      if (veiculo?.codigo_vei !== state?.veiculo.codigo_vei) {
        setDadosVeiculo(state?.veiculo.codigo_vei)
      }
    }
  }, [state.veiculo, state.veiculo?.codigo_vei])
  const setDadosVeiculo = async (codigo_vei) => {
    try {
      dispatch(startLoading())
      const veiculoService = new VeiculoService()
      const veiculo = await veiculoService.getInformacoesSimulacao(codigo_vei)
      setState((prevState) => ({
        ...prevState,
        veiculo: veiculo,
        descricaoVeiculo: veiculo?.descricaoCompleta,
        custos: {
          valorCompra: veiculo?.ultimaCompra?.valor_nve || 0,
          jurosEstoque: Number((veiculo?.ultimaCompra?.valor_nve || 0) * (veiculo?.diasEmEstoque * (veiculo?.taxaJurosEstoque / 100).toFixed(5))),
          rateioDespesas: Number(veiculo?.ultimaCompra?.valor_nve * (Number(veiculo?.taxaRateio) / 100)),
          valorManutencoes: veiculo?.valorManutencao || 0,
          valorDocumentacao: veiculo?.custosDocumentacao || 0,
          comissaoVendedor: 0,
          outrosCustos: 0,
          totalCustos: 0
        },
        venda: {
          preco: veiculo?.preco_pre || 0,
          valorFinanciado: 0,
          retorno: 0,
          outrasReceitas: veiculo?.custos || 0,
          totalVenda: 0,
          valorMinimo: veiculo?.premin_pre,
          lucroReaisMinimo: 0,
          lucroPorcentagemMinimo: 0,
          valorEcommerce: veiculo?.preeco_pre
        }
      }))

      dispatch(stopLoading())
    } catch (error) {
      dispatch(stopLoading())
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar buscar os dados do veículo!')
    }
  }
  const handleChangeCurrencyVenda = (event) => {
    const { venda } = state
    venda[event.target.name] = formatCurrencyToNumber(event.target.value)
    setState({ ...state, venda })
  }

  const handleChangeRetorno = (event) => {
    const { venda } = state
    venda.retorno = event.value
    setState({ ...state, venda })
  }

  const gravarPrecoVenda = async () => {
    try {
      await ProdutoEmpresaService.alterarPrecoVeiculo(state.veiculo.codigo_vei, { preco_pre: state.venda.preco })
      const { veiculo } = state
      veiculo.preco_pre = state.venda.preco
      setState({ ...state, veiculo })
      showSuccessMessage('Preço alterado com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar gravar o preço!')
    }
  }

  const gravarPrecoMinimo = async () => {
    try {
      await ProdutoEmpresaService.alterarPrecoVeiculo(state.veiculo.codigo_vei, { premin_pre: state.venda.valorMinimo })
      showSuccessMessage('Preço mínimo alterado com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar gravar o preço mínimo!')
    }
  }

  const gravarPrecoEcommerce = async () => {
    try {
      await ProdutoEmpresaService.alterarPrecoVeiculo(state.veiculo.codigo_vei, { preeco_pre: state.venda.valorEcommerce })
      showSuccessMessage('Preço de e-commerce alterado com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar gravar o preço de e-commerce!')
    }
  }

  const { veiculo, venda, custos } = state

  const custoTotal = totalCustoVeiculosConjunto.find((custo) => custo.codigoVei === props.codvei)
  const totalCustos = custoTotal ? custoTotal.total : custos?.valorCompra +
  custos?.valorDocumentacao +
  custos?.valorManutencoes +
  custos?.comissaoVendedor +
  custos?.rateioDespesas +
  custos?.jurosEstoque +
  custos?.valorCompra * (custos?.outrosCustos / 100)
  const totalVenda = venda.preco + (venda.valorFinanciado * (venda.retorno / 100)) + venda.outrasReceitas
  const lucroVendaReais = totalVenda - totalCustos
  let lucroVendaPercentual = (lucroVendaReais / totalVenda) * 100
  lucroVendaPercentual < -100 ? lucroVendaPercentual = -100 : lucroVendaPercentual = lucroVendaPercentual

  const totalVendaMinimo = venda.valorMinimo + (venda.valorFinanciado * (venda.retorno / 100)) + venda.outrasReceitas
  const lucroVendaReaisMinimo = totalVendaMinimo - totalCustos
  let lucroVendaPercentualMinimo = (lucroVendaReaisMinimo / totalVendaMinimo) * 100
  lucroVendaPercentualMinimo < -100 ? lucroVendaPercentualMinimo = -100 : lucroVendaPercentualMinimo = lucroVendaPercentualMinimo

  const totalVendaEcommerce = venda.valorEcommerce + (venda.valorFinanciado * (venda.retorno / 100)) + venda.outrasReceitas
  const lucroVendaReaisEcommerce = totalVendaEcommerce - totalCustos
  let lucroVendaPercentualEcommerce = (lucroVendaReaisEcommerce / totalVendaEcommerce) * 100
  lucroVendaPercentualEcommerce < -100 ? lucroVendaPercentualEcommerce = -100 : lucroVendaPercentualEcommerce = lucroVendaPercentualEcommerce

  return (
    <div className="formgrid grid">
      <div className="field col-12 md:col-4">
        <TextInput
          label="Valor financiado"
          placeholder="Valor financiado"
          className="inputfield w-full"
          value={formatToCurrency(Number(venda.valorFinanciado))}
          name="valorFinanciado"
          onChange={handleChangeCurrencyVenda}
        />
      </div>
      <div className="field col-12 md:col-4">
        <NumberInput
          label="Retorno(%)"
          placeholder="Retorno(%)"
          className="inputfield w-full"
          value={venda.retorno}
          name="retorno"
          onChange={handleChangeRetorno}
        />
      </div>
      <div className="field col-12 md:col-4">
        <TextInput
          label="Outras receitas"
          placeholder="Outras receitas"
          className="inputfield w-full"
          value={formatToCurrency(Number(venda.outrasReceitas))}
          name="outrasReceitas"
          onChange={handleChangeCurrencyVenda}
        />
      </div>
      <div className="formgrid grid">
        <div className="field col-12 md:col-4">
          <Fieldset legend="Venda" className='h-full' >
            <div className="field col-12 ">
              <label className="label">Preço</label>
              <div className="p-inputgroup">
                <InputText
                  placeholder="Preço"
                  className="inputfield"
                  value={formatToCurrency(venda.preco)}
                  name="preco"
                  onChange={handleChangeCurrencyVenda}
                />
                <Button
                  label="Gravar"
                  style={{ fontSize: '0.85em', paddingRight: '8px', paddingLeft: '8px' }}
                  onClick={gravarPrecoVenda}
                  disabled={!veiculo?.codigo_vei}
                />
              </div>
            </div>
            <div className="field col-12">
              <TextInput
                label={<b>Total</b>}
                placeholder="Total"
                className="inputfield w-full"
                value={formatToCurrency(Number(totalVenda))}
                disabled
              />
            </div>
            <div className="field col-12">
              <TextInput
                label={<b>Lucro (R$)</b>}
                placeholder="Lucro (R$)"
                className="inputfield w-full"
                value={formatToCurrency(Number(lucroVendaReais))}
                disabled
              />
            </div>
            <div className="field col-12">
              <TextInput
                label={<b>Lucro (%)</b>}
                placeholder="Lucro (%)"
                className="inputfield w-full"
                value={isFinite(lucroVendaPercentual) ? lucroVendaPercentual.toFixed(2) : '0'}
                disabled
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              { (state.veiculo.codigo_vdi !== 'B' && state.veiculo.codigo_vdi !== 'V' && state.veiculo.codigo_vdi !== 'I' && (state.veiculo.perven_ves === 1)) &&
              (
                <AppButton
                  label="Vender"
                  onClick={async () => {
                    if (await venderVeiculo.handleVenderVeiculo(state.veiculo)) {
                      props.history.push('/negociacao')
                    }
                  }}
                />
              )}
            </div>
          </Fieldset>
        </div>
        <div className="field col-12 md:col-4">
          <Fieldset legend="Mínimo" className='h-full' >
            <div className="field col-12" >
              <label className="label">Preço</label>
              <div className="p-inputgroup">
                <InputText
                  placeholder="Preço"
                  className="inputfield"
                  name="valorMinimo"
                  value={formatToCurrency(Number(venda.valorMinimo))}
                  onChange={handleChangeCurrencyVenda}
                />
                <Button
                  label="Gravar"
                  style={{ fontSize: '0.85em', paddingRight: '8px', paddingLeft: '8px' }}
                  onClick={gravarPrecoMinimo}
                  disabled={!veiculo?.codigo_vei}
                />
              </div>
            </div>
            <div className="field col-12">
              <TextInput
                label={<b>Total</b>}
                placeholder="Total"
                className="inputfield w-full"
                value={formatToCurrency(Number(totalVendaMinimo))}
                disabled
              />
            </div>
            <div className="field col-12">
              <TextInput
                label={<b>Lucro (R$)</b>}
                placeholder="Lucro (R$)"
                className="inputfield w-full"
                value={formatToCurrency(Number(lucroVendaReaisMinimo))}
                disabled
              />
            </div>
            <div className="field col-12 h-200" >
              <TextInput
                label={<b>Lucro (%)</b>}
                placeholder="Lucro (%)"
                className="inputfield w-full"
                value={isFinite(lucroVendaPercentualMinimo) ? lucroVendaPercentualMinimo.toFixed(2) : '0'}
                disabled
              />
            </div>
          </Fieldset>
        </div>
        <div className="field col-12 md:col-4">
          <Fieldset legend="E-commerce" className='h-full' >
            <div className="formgrid grid">
              <div className="field col-12">
                <label className="label">Preço</label>
                <div className="p-inputgroup">
                  <InputText
                    placeholder="Preço"
                    className="inputfield"
                    name="valorEcommerce"
                    value={formatToCurrency(Number(venda.valorEcommerce))}
                    onChange={handleChangeCurrencyVenda}
                  />
                  <Button
                    label="Gravar"
                    style={{ fontSize: '0.85em', paddingRight: '8px', paddingLeft: '8px' }}
                    onClick={gravarPrecoEcommerce}
                    disabled={!veiculo?.codigo_vei}
                  />
                </div>
              </div>
              <div className="field col-12">
                <TextInput
                  label={<b>Total</b>}
                  placeholder="Total"
                  className="inputfield w-full"
                  value={formatToCurrency(Number(totalVendaEcommerce))}
                  disabled
                />
              </div>
              <div className="field col-12">
                <TextInput
                  label={<b>Lucro (R$)</b>}
                  placeholder="Lucro (R$)"
                  className="inputfield w-full"
                  value={formatToCurrency(Number(lucroVendaReaisEcommerce))}
                  disabled
                />
              </div>
              <div className="field col-12">
                <TextInput
                  label={<b>Lucro (%)</b>}
                  placeholder="Lucro (%)"
                  className="inputfield w-full"
                  value={isFinite(lucroVendaPercentualEcommerce) ? lucroVendaPercentualEcommerce.toFixed(2) : '0'}
                  disabled
                />
              </div>
            </div>
          </Fieldset>
        </div>
      </div>
    </div>

  )
}

export default ValorVendaVeiculoConjunto
