import { getStore } from '../../../redux/store'

export const getReservaDTO = (data = {}) => {
  const usuarioLogado = getStore().usuarioLogado.usuario

  let vendedor = {}

  if (usuarioLogado?.pessoa?.vendedor?.ativo_ven) {
    vendedor = { ...usuarioLogado.pessoa.vendedor, ...usuarioLogado.pessoa }
  }

  return {
    pessoa: data.pessoa || {},
    vendedor: data.vendedor || vendedor,
    motivoReserva: data.obs_mvs || '',
    descricaoVeiculo: data.descricaoVeiculo || '',
    clienteAutogestor: data.clienteAutogestor || null,
    removerAnuncios: data.removerAnuncios || true,
    codigo_pre: data.codigo_pre || null,
    visualizarCamposAutoGestor: data.visualizarCamposAutoGestor || false,
    styleFieldsAutoGestor: 'hidden'
  }
}
