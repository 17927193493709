import { Column } from 'primereact/column'
import { InputSwitch } from 'primereact/inputswitch'
import React from 'react'
import DataList from '../../../components/layout/DataList'
import Page from '../../../components/layout/Page'
import ResponsiveColumn from '../../../components/utils/ResponsiveColumn'
import CommonHelper from '../../../helpers/CommonHelper'
import useList from '../../../hook/useList'
import ConfigModulosService from '../../../services/configuracao/ConfigModulosService'

const ConfiguracoesModuloWeb = (props) => {
  const { state, page, onFilter, onPageChange, onSelect, getAll } = useList({}, ConfigModulosService, '')

  const updateModulos = async (codban, codmod) => {
    try {
      await ConfigModulosService.updateModulos(codban, codmod)
      getAll()
    } catch (error) {
      console.error(error)
    }
  }

  const getModuloVenda = (data) => (
    <div className="flex justify-content-around">
      <InputSwitch
        className="table-preferences-switch"
        name="moduloVenda"
        checked={String(data.modweb).includes(1)}
        onChange={(e) => updateModulos(data.codbd_mwb || data.codigo_ban, 1)}
      />
    </div>
  )

  const getModuloPeca = (data) => (
    <div className="flex justify-content-around">
      <InputSwitch
        className="table-preferences-switch"
        name="moduloPeca"
        checked={String(data.modweb).includes(2)}
        onChange={() => updateModulos(data.codbd_mwb || data.codigo_ban, 2)}
      />
    </div>
  )

  const getModuloAutoGesto = (data) => (
    <div className="flex justify-content-around">
      <InputSwitch
        className="table-preferences-switch"
        name="moduloAutoGesto"
        checked={String(data.modweb).includes(3)}
        onChange={() => updateModulos(data.codbd_mwb || data.codigo_ban, 3)}
      />
    </div>
  )

  const getModuloAutoConf = (data) => (
    <div className="flex justify-content-around">
      <InputSwitch
        className="table-preferences-switch"
        name="moduloAutoConf"
        checked={String(data.modweb).includes(4)}
        onChange={() => updateModulos(data.codbd_mwb || data.codigo_ban, 4)}
      />
    </div>
  )

  const getModuloDealerUp = (data) => (
    <div className="flex justify-content-around">
      <InputSwitch
        className="table-preferences-switch"
        name="moduloDeler"
        checked={String(data.modweb).includes(5)}
        onChange={() => updateModulos(data.codbd_mwb || data.codigo_ban, 5)}
      />
    </div>
  )

  const getModuloYamaha = (data) => (
    <div className="flex justify-content-around">
      <InputSwitch
        className="table-preferences-switch"
        name="moduloYamaha"
        checked={String(data.modweb).includes(6)}
        onChange={() => updateModulos(data.codbd_mwb || data.codigo_ban, 6)}
      />
    </div>
  )

  return (
    <Page>
      <div className='w-full pt-4'>
        <DataList
          data={state.list}
          selected={state.selected}
          onSelect={(e) => onSelect(e)}
          notSearch={true}
          headerLabel="Configuração Módulos"
          rows={state.rows}
          totalRecords={state.totalRecords}
          onPage={onPageChange}
          first={state.first}
          expandedRows={state.expandedRows}
          page={page}
          onFilter={onFilter}
          showFilter={true}
          filterPlaceholder="Procurar veículos"
          filterName="descricaoFiltro"
          scrollable={true}
          scrollDirection="horizontal"
          responsive={true}
          scrollHeight={`${!CommonHelper.isNotebook() ? '650px' : '450px'}`}
          frozenWidth="300px"

        >
          <Column
            header="Empresa"
            className='w-20rem frozen'
            alignFrozen="right"
            frozen
            body={(data) => (
              <ResponsiveColumn
                className="pl-2 w-full"
                column="Empresa"
                value={data.name_ban}
              />
            )}
            headerClassName="md:w-20rem w-screen"
            sortable={true}
            field="name_ban"
          />
          <Column
            header="Venda de veículo"
            className='w-20rem'
            body={(data) => (
              <ResponsiveColumn
                className="pl-2"
                column="Venda de veículo"
                value={getModuloVenda(data)}
              />
            )}
            headerClassName="md:w-20rem w-screen"
            sortable={true}
            field="modulo1"
          />
          <Column
            header="Venda de peça"
            className='w-20rem'
            body={(data) => (
              <ResponsiveColumn
                className="pl-2"
                column="Venda de peça"
                value={getModuloPeca(data)}
              />
            )}
            headerClassName="md:w-20rem w-screen"
            sortable={true}
            field="modulo1"
          />
          <Column
            header="AutoGestor"
            className='w-20rem'
            body={(data) => (
              <ResponsiveColumn
                className="pl-2"
                column="AutoGestor"
                value={getModuloAutoGesto(data)}
              />
            )}
            headerClassName="md:w-20rem w-screen"
            sortable={true}
            field="modulo2"
          />
          <Column
            header="AutoConf"
            className='w-20rem'
            body={(data) => (
              <ResponsiveColumn
                className="pl-2"
                column="AutoConf"
                value={getModuloAutoConf(data)}
              />
            )}
            headerClassName="md:w-20rem w-screen"
            sortable={true}
            field="modulo3"
          />
          <Column
            header="DealerUp"
            className='w-20rem'
            body={(data) => (
              <ResponsiveColumn
                className="pl-2"
                column="DealerUp"
                value={getModuloDealerUp(data)}
              />
            )}
            headerClassName="md:w-20rem w-screen"
            sortable={true}
            field="modulo4"
          />
          <Column
            header="Yamaha A1"
            className='w-20rem'
            body={(data) => (
              <ResponsiveColumn
                className="pl-2"
                column="Yamaha A1"
                value={getModuloYamaha(data)}
              />
            )}
            headerClassName="md:w-20rem w-screen"
            sortable={true}
            field="modulo5"
          />
        </DataList>
      </div>
    </Page>
  )
}

export default ConfiguracoesModuloWeb
