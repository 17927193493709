import React, { useRef, useState } from 'react'
import Message from '../../components/utils/Message'
import TextInput from '../../components/inputs/TextInput'
import AppButton from '../../components/layout/AppButton'
import Modal from '../../components/utils/Modal'
import LandingVeiculoService from '../../services/cadastro/veiculo/LandingVeiculoService'
import './_shareModal.scss'
import { Toast } from 'primereact/toast'

const ShareModal = (props) => {
  const toast = useRef(null)

  const [email, setEmail] = useState('')

  const handleEnviarEmail = async () => {
    try {
      await LandingVeiculoService.sendLandingVeiculoEmail(
        window.location.href,
        email,
        props.empresa.apelid_pes,
        props.veiculo
      )

      toast.current.show({
        severity: 'success',
        summary: 'Sucesso!',
        detail: 'E-mail enviado com sucesso!'
      })
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Erro',
        detail: error.message || 'Houve um erro ao tentar enviar o e-mail!'
      })
    }
  }

  const onHide = () => {
    setEmail('')
    props.hide()
  }

  return (
    <>
      <Modal
        header="Compartilhar link"
        visible={props.visible}
        width={40}
        onHide={onHide}
      >
        <div className="field col-12 sm:col-12 md:col-12">
          <label>Copiar link</label>
          <div>
            <div className="p-inputgroup">
              <TextInput
                className="inputfield w-full"
                value={window.location.href}
              />
              <AppButton
                icon="pi pi-clone"
                onClick={() => navigator.clipboard.writeText(window.location.href)}
                tooltip="Link copiado"
                tooltipOptions={{
                  event: 'focus',
                  hideDelay: 100,
                  position: 'top',
                  className: 'link-tooltip'
                }}
              />
            </div>
          </div>
        </div>
        <div className="field col-12 sm:col-12 md:col-12">
          <label>Enviar link para</label>
          <div className="p-inputgroup">
            <TextInput
              className="inputfield w-full"
              placeholder="Digite o email do destinatário."
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <AppButton
              icon="pi pi-send"
              onClick={() => handleEnviarEmail()}
            />
          </div>
        </div>
      </Modal>
      <Message />
      <Toast ref={toast} />
    </>
  )
}

export default ShareModal
