import React from 'react'
import List from '../../../classes/List'
import Modal from '../../../components/utils/Modal'
import { getOrcamentoBalcaoDTO } from '../../../dtos/vendas/orcamentoBalcao/OrcamentoBalcaoDTO'
import OrcamentoBalcaoService from '../../../services/vendas/orcamentoBalcao/OrcamentoBalcaoService'
import Container from '../../../components/layout/Container'
import DataList from '../../../components/layout/DataList'
import { Column } from 'primereact/column'
import ResponsiveColumn from '../../../components/utils/ResponsiveColumn'
import { formatDateTime } from '../../../helpers/formaters'
import { showErrorMessage } from '../../../components/utils/Message'
import PermissaoService from '../../../services/cadastro/pessoa/PermissaoService'

class DisponibilidadePecasModal extends List {
  constructor(props) {
    super(
      props,
      getOrcamentoBalcaoDTO,
      OrcamentoBalcaoService,
      'orcamentoBalcao',
      'codigo_opv'
    )

    this.state = {
      ...this.state,
      filterCollapsed: true,
      selected: this.model,
      filter: this.model,
      visible: props.visible,
      perPage: 20,
      page: 1,
      descricaoFiltro: "",
      expandedRows: null,
      onConfirmarVenda: props.onConfirmarVenda,
      permissaoPecaReservada: false,
      listaPecasIndisponiveis: props.listaPecasIndisponiveis,
      pecaSelecionada: {}
    }
  }

  componentDidUpdate(prevProps) {
    const { visible } = this.props

    if (prevProps.visible !== visible) {
      this.setState({ visible })

      if (visible) {
        this.getPermissao49()
      }
    }
  }

  componentDidMount() {
    this.addEventListeners()
  }

  getPermissao49 = async () => {
    const permiteVenderPecaReservada = await PermissaoService.getByCodigo('49')
    this.setState({ permissaoPecaReservada: permiteVenderPecaReservada })
  }

  onSelectPeca = selected => {
    this.setState({ pecaSelecionada: selected })
  }

  onConfirmar = async () => {
    if (this.state.permissaoPecaReservada) {
      await this.props.onConfirmarVenda()
      this.props.onHide()
    } else {
      showErrorMessage("Você não possui permissão para aprovar venda de peça reservada!")
    }
  }


  render() {
    const { visible } = this.state
    const { listaPecasIndisponiveis } = this.props
    const rowExpansionTemplate = (data) => {
      return (
        <div className={"row-expansion flex justify-content-center"}>
          <div className={"md:col-10 mt-2"}>
            <Container>
              <DataList
                data={data.reservas}
                rows={data.reservas.length}
                hidePaginator={true}
                totalRecords={data.reservas.length}
                first={1}
                page={1}
                showFilter={false}
                responsive
              >
                <Column
                  header="Reservas"
                  headerClassName="HeaderDataList"
                  body={(data) => (
                    <ResponsiveColumn
                      className="pl-2"
                      column="Reservas"
                      value={data.tipoorigem}
                    />
                  )}
                  sortable={true}
                  field="tipoorigem"
                />
                <Column
                  header="Número"
                  headerClassName="HeaderDataList"
                  body={(data) => (
                    <ResponsiveColumn
                      className="pl-2"
                      column="Número"
                      value={data.numeroorigem}
                    />
                  )}
                  sortable={true}
                  field="numeroorigem"
                />
                <Column
                  header="Data reserva"
                  headerClassName="HeaderDataList"
                  body={(data) => (
                    <ResponsiveColumn
                      className="pl-2"
                      column="Data reserva"
                      value={formatDateTime(data.datahora)}
                    />
                  )}
                  sortable={true}
                  field="datahora"
                />
                <Column
                  header="Cliente"
                  headerClassName="HeaderDataList"
                  body={(data) => (
                    <ResponsiveColumn
                      className="pl-2"
                      column="Cliente"
                      value={data.cliente}
                    />
                  )}
                  sortable={true}
                  field="cliente"
                />
                <Column
                  header="Consultor"
                  headerClassName="HeaderDataList"
                  body={(data) => (
                    <ResponsiveColumn
                      className="pl-2"
                      column="Consultor"
                      value={data.consultor}
                    />
                  )}
                  sortable={true}
                  field="consultor"
                />

                <Column
                  header="Quantidade"
                  headerClassName="HeaderDataList"
                  body={(data) => (
                    <ResponsiveColumn
                      className="pl-2"
                      column="Quantidade"
                      value={data.quantidade}
                    />
                  )}
                  sortable={true}
                  field="quantidade"
                />
              </DataList>
            </Container>
          </div>
        </div>
      )
    }

    return (
      <React.Fragment>
        <Modal
          header="Aprovação de venda de peça reservada"
          width={75}
          visible={visible}
          onHide={this.props.onHide}
          btnSalvarLabel={'Confirmar venda'}
          icon={'pi pi-check'}
          btnSalvar={() => this.onConfirmar()}
          btnExcluir={this.props.onHide}
          btnExcluirLabel={'Recusar'}
        >
          <div className="formgrid grid fluid">
            <div className="field col-12 d-flex justify-content-center">
              <p className="text-center">As peças abaixo estão reservadas também em outros processos</p>
            </div>

            <Container>
              <DataList
                data={listaPecasIndisponiveis}
                selected={this.state.selected}
                rows={listaPecasIndisponiveis?.length}
                onSelect={this.onSelectPeca}
                onRowClick={(e) => { this.handleRowExpansion(e.data) }}
                hidePaginator={true}
                totalRecords={listaPecasIndisponiveis?.length}
                first={1}
                onPage={1}
                page={1}
                showFilter={false}
                responsive={true}
                expandedRows={this.state.expandedRows}
                rowExpansionTemplate={rowExpansionTemplate}
              >
                <Column
                  header="Peças reservadas"
                  body={(data) => (
                    <ResponsiveColumn
                      className="pl-2"
                      column="Peças reservadas"
                      value={"(" + data.codigo_pro + ") -" + data.descri_pro}
                    />
                  )}
                  headerClassName="width-35"
                  sortable={true}
                  field="codigo_pro"
                />
                <Column
                  header="Referência"
                  body={(data) => (
                    <ResponsiveColumn
                      className="pl-2 wordWrapBreak"
                      column="Referência"
                      value={data.referencia}
                    />
                  )}
                  sortable={true}
                  field="referencia"
                />
                <Column
                  header="Estoque atual"
                  body={(data) => (
                    <ResponsiveColumn
                      className="pl-2"
                      column="Estoque atual"
                      value={data.estatu_pre}
                    />
                  )}
                  sortable={true}
                  field="estatu_pre"
                />
                <Column
                  header="Quantidade aplicada"
                  body={(data) => (
                    <ResponsiveColumn
                      className="pl-2"
                      column="Quantidade aplicada"
                      value={data.qtdapl_pre}
                    />
                  )}
                  sortable={true}
                  field="qtdapl_pre"
                />
                <Column
                  header="Quantidade reservada"
                  body={(data) => (
                    <ResponsiveColumn
                      className="pl-2"
                      column="Quantidade reservada"
                      value={data.qtdres_pre}
                    />
                  )}
                  sortable={true}
                  field="qtdres_pre"
                />
                <Column
                  header="Quantidade em trânsito"
                  body={(data) => (
                    <ResponsiveColumn
                      className="pl-2"
                      column="Quantidade em trânsito"
                      value={data.qtdtra_pre}
                    />
                  )}
                  sortable={true}
                  field="qtdtra_pre"
                />
                <Column
                  header="Quantidade disponível"
                  body={(data) => (
                    <ResponsiveColumn
                      className={`pl-2 ${data.qtdDisponivel < 0 ? 'text-red font-bold' : ''}`}
                      column="Quantidade disponível"
                      value={data.qtdDisponivel}
                    />
                  )}
                  sortable={true}
                  field="qtdDisponivel"
                />

                <Column
                  header="Quantidade solicitada"
                  body={(data) => (
                    <ResponsiveColumn
                      className="pl-2"
                      column="Quantidade solicitada"
                      value={data.qtdSolicitada}
                    />
                  )}
                  sortable={true}
                  field="qtdSolicitada"
                />
              </DataList>
            </Container>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

export default DisponibilidadePecasModal
