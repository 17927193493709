import React from 'react'
import DadosPrincipaisMar from './formPages/DadosPrincipaisMar'
import TabMenuContainer from '../../../../components/layout/TabMenuContainer/TabMenuContainer'
import TabMenuPage from '../../../../components/layout/TabMenuContainer/TabMenuPage'

const MarcaForm = ({ form, edicaoHabilitada, camposObrigatorios }) => {
  return (
    <TabMenuContainer>
      <TabMenuPage header="INFORMAÇÕES PRINCIPAIS">
        <DadosPrincipaisMar
          form={form}
          edicaoHabilitada={edicaoHabilitada}
          camposObrigatorios={camposObrigatorios}
        />
      </TabMenuPage>
    </TabMenuContainer>
  )
}

export default MarcaForm
