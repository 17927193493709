import { server } from '../../helpers/server'
import ServiceBase from '../base/ServiceBase'

export default class ImagemService {
  static async getImages (nomkey_img, codkey_img, database) {
    if (database) {
      return await ServiceBase.getBase(`/landing-veiculo/${nomkey_img}/${codkey_img}` + (database ? `/${database}` : ''))
    }
    return await ServiceBase.getBase(`/image/${nomkey_img}/${codkey_img}`)
  }

  static async uploadImages (files, codkey_img, nomkey_img, nomtab_img) {
    try {
      const formData = new FormData()

      files.forEach((file) => {
        formData.append('image', file)
      })

      const response = await server().post(`image/${nomtab_img}/${nomkey_img}/${codkey_img}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      return response.data
    } catch (error) {
      throw Error(error.response?.data?.message)
    }
  }

  static async deleteImages (imagens) {
    await ServiceBase.postBase('image/deleteImages', { imagens })
  }

  static async deleteAllImages (nomkey_img, codkey_img) {
    await ServiceBase.postBase('image/delete-all', { nomkey_img, codkey_img })
  }
}
