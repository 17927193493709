import { postPessoaFisicaDTO } from '../../../dtos/cadastro/pessoa/PessoaFisicaDTO'
import ServiceBase from '../../base/ServiceBase'

export default class PessoaFisicaService {
  async get (id) {
    return await ServiceBase.getBase(`pessoas/fisica/${id}`)
  }

  async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`pessoas/fisica?page=${page}&perPage=${perPage}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`pessoas/fisica?${query}&page=${page}&perPage=${perPage}`)
  }

  async filterAutoComplete (query) {
    return await ServiceBase.getBase(`pessoas/fisica?${query}`, false)
  }

  async save (data) {
    return await ServiceBase.postBase('pessoas/fisica', postPessoaFisicaDTO(data))
  }

  async delete (codigo_pef) {
    return await ServiceBase.deleteBase(`pessoas/fisica/${codigo_pef}`)
  }

  async getFormValidator () {
    return await ServiceBase.getBase('pessoas/fisica/validator')
  }

  static async getEscolaridades () {
    return await ServiceBase.getBase('pessoas/fisica/escolaridades', false)
  }

  static async getHobbies () {
    return await ServiceBase.getBase('pessoas/fisica/hobbies', false)
  }

  static async filterProfissoes (query) {
    return await ServiceBase.getBase(`pessoas/fisica/profissoes?${query}`)
  }
}
