import React, { useEffect, useState } from 'react'
import SelectInput from '../../../../components/inputs/SelectInput'
import TextInput from '../../../../components/inputs/TextInput'
import { showErrorMessage } from '../../../../components/utils/Message'
import CommonHelper from '../../../../helpers/CommonHelper'
import CorService from '../../../../services/cadastro/veiculo/CorService'

const DadosPrincipaisCor = ({ model, edicaoHabilitada, onChange }) => {
  const [marcas, setMarcas] = useState([])
  const [coresDenatran, setCoresDenatran] = useState([])

  useEffect(async () => {
    await getMarcas()
    await getCoresDenatran()
  }, [])

  const getMarcas = async () => {
    try {
      await CorService.getAllMarcas().then(marcas => setMarcas(marcas))
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro inesperado ao buscar as marcas')
    }
  }

  const getCoresDenatran = async () => {
    try {
      await CorService.getAllCoresDenatran().then(coresDenatran => setCoresDenatran(coresDenatran))
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar as cores!')
    }
  }

  const handleChange = (event) => {
    model[event.target.name] = event.target.value
    onChange(model)
  }

  return (
    <div className="formgrid grid">
      <div className="field col-6">
        <TextInput
          class="p-error"
          label="Descrição"
          placeholder="Descrição"
          name="descri_cor"
          value={model.descri_cor}
          onChange={handleChange}
          required={true}
          maxLength={80}
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-6">
        <SelectInput
          value={model.marca}
          options={marcas}
          onChange={handleChange}
          name="marca"
          label="Marca"
          filter={CommonHelper.isDesktop()}
          optionLabel="descri_mar"
          dataKey="codigo_mar"
          noFloatLabel
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
          showClear={model.marca?.codigo_mar}
        />
      </div>
      <div className="field col-6">
        <TextInput
          label="Cód. montadora"
          placeholder="Cód. montadora"
          name="cormar_cor"
          value={model.cormar_cor}
          onChange={handleChange}
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-6">
        <SelectInput
          value={model.corDenatran}
          options={coresDenatran}
          onChange={handleChange}
          name="corDenatran"
          label="Cor DENATRAN"
          filter={CommonHelper.isDesktop()}
          optionLabel="descri_cde"
          dataKey="codigo_cde"
          noFloatLabel
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
          showClear={model.corDenatran?.codigo_cde}
        />
      </div>
      <div className="col-12 md:col-12">
        <div className="p-text-right" style={{ color: 'rgb(70, 70, 70)' }}>
          Ao importar uma nota fiscal de terceiro de veículo, o sistema procura pela descrição da cor
          ou código do DENATRAN, se não achar, a cor é automaticamente cadastrada.
        </div>
      </div>
    </div>
  )
}

export default DadosPrincipaisCor
