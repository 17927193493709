import React from 'react'
import { Button } from 'primereact/button'
import List from '../../../../classes/List'
import Container from '../../../../components/layout/Container'
import Page from '../../../../components/layout/Page'
import DataList from '../../../../components/layout/DataList'
import TablePreferences from '../../../../components/utils/TablePreferences'
import { createColumns, getColunasHabilitadas } from '../../../../helpers/tableConfigs'
import PreferencesButton from '../../../../components/utils/PreferencesButton'
import CommonHelper from '../../../../helpers/CommonHelper'
import { getAcessorioDTO } from '../../../../dtos/cadastro/veiculo/AcessorioDTO'
import AcessorioService from '../../../../services/cadastro/veiculo/AcessorioService'
import { classNames } from 'primereact/utils'

export class AcessorioList extends List {
  constructor (props) {
    super(props, getAcessorioDTO, AcessorioService, 'acessorio', 'codigo_ace')

    this.state = {
      ...this.state,
      visiblePreferencesModal: false
    }

    this.setColumns(createColumns([
      { field: 'codigo_ace', header: 'Código', width: '6%' },
      { field: 'descri_ace', header: 'Descrição', width: '30%', customBody: this.getPlaca },
      { field: 'sigla_ace', header: 'Sigla', width: '20%' },
      { field: 'codext_ace', header: 'Código externo', width: '20%' }
    ]))
  }

  render () {
    const { rows, totalRecords, first } = this.state
    const columns = this.columns

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Acessórios</h4>
          <div className="page-header-buttons">
            <Button
              className="mr-2"
              label={CommonHelper.isDesktop() && 'Novo'}
              icon="pi pi-plus"
              onClick={this.onNew}
            />
          </div>
        </div>
        <div className="table-options-container">
          <div className="tabel-options">
            <div
              className={classNames({ active: this.state.opcaoSelecionada === 't' }, 'filter-option')}
            >
              <span className="option-label">Todos</span>
              <div className="option-quantity">
                {this.state.totalRecords}
              </div>
            </div>
          </div>
          <div className="table-preferences">
            <PreferencesButton
              onClick={() => this.setState({ visiblePreferencesModal: true })}
            />
          </div>
        </div>
        <Container>
          <DataList
            data={this.state.list}
            selected={this.state.selected}
            rows={rows}
            onSelect={this.onSelect}
            totalRecords={totalRecords}
            first={first}
            onPage={this.onPageChange}
            page={this.state.page}
            onRowSelect={(e) => this.onView(e.data)}
            showFilter={true}
            filterPlaceholder="Procurar Acessórios"
            filterName="descricaoFiltro"
            responsive={true}
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
          >
            {getColunasHabilitadas(columns, 'acessorio')}
          </DataList>
        </Container>
        <TablePreferences
          title="Preferências da tabela de acessório"
          tableName="acessorio"
          columns={columns}
          visible={this.state.visiblePreferencesModal}
          hide={() => this.setState({ visiblePreferencesModal: false })}
        />
      </Page>
    )
  }
}
