import React from 'react'
import { Dropdown } from 'primereact/dropdown'
import InputBase from './base/InputBase'

class SelectInput extends InputBase {
  renderInput () {
    return (
      <span className="selectinput">
        <Dropdown
          value={this.props.value || ''}
          optionLabel={this.props.optionLabel}
          optionValue={this.props.optionValue}
          id={this.props.id}
          options={this.props.options}
          itemTemplate={this.props.itemTemplate}
          emptyMessage="Nenhum registro encontrado"
          emptyFilterMessage="Nenhum registro encontrado"
          placeholder=" - Selecione - "
          style={{ appearence: 'auto' }}
          onHide={(e) => this.onHide(e)}
          onChange={(e) => props.onChange(e)}
          onBlur={this.onBlur}
          className={this.getInputClassName()}
          showClear={this.props.showClear}
          {...this.props}
        />
      </span>
    )
  }
}

export default SelectInput
