import * as queryString from 'query-string'
import { formatDateToAmerican } from '../../../helpers/formaters'
import CommonHelper from '../../../helpers/CommonHelper'
import { PessoaModel } from './PessoaModel'

export class PessoaJuridicaModel extends PessoaModel {
  constructor (params = {}) {
    super({
      codigo_pes: params.pessoa?.codigo_pes || params.codigo_pes || null,
      nomraz_pes: params.pessoa?.nomraz_pes || params.nomraz_pes || null,
      apelid_pes: params.pessoa?.apelid_pes || params.apelid_pes || null,
      end_pes: params.pessoa?.end_pes || params.end_pes || null,
      bairro_pes: params.pessoa?.bairro_pes || params.bairro_pes || null,
      cidade_pes: params.pessoa?.cidade_pes || params.cidade_pes || null,
      uf_pes: params.pessoa?.uf_pes || params.uf_pes || null,
      pais_pes: params.pessoa?.pais_pes || params.pais_pes || null,
      cep_pes: params.pessoa?.cep_pes || params.cep_pes || null,
      email_pes: params.pessoa?.email_pes || params.email_pes || null,
      emafin_pes: params.pessoa?.emafin_pes || params.emafin_pes || null,
      numend_pes: params.pessoa?.numend_pes || params.numend_pes || null,
      codemp_pes: params.pessoa?.codemp_pes || params.codemp_pes || null,
      cgccpf_pes: params.pessoa?.cgccpf_pes || params.cgccpf_pes || null,
      ierg_pes: params.pessoa?.ierg_pes || params.ierg_pes || null,
      ie_pes: params.pessoa?.ie_pes || params.ie_pes || null,
      telcel_pes: params.pessoa?.telcel_pes || params.telcel_pes || null,
      telcom_pes: params.pessoa?.telcom_pes || params.telcom_pes || null,
      telres_pes: params.pessoa?.telres_pes || params.telres_pes || null,
      insmun_pes: params.pessoa?.insmun_pes || params.insmun_pes || null,
      comend_pes: params.pessoa?.comend_pes || params.comend_pes || null,
      codmun_pes: params.pessoa?.codmun_pes || params.codmun_pes || null,
      codpai_pes: params.pessoa?.codpai_pes || params.codpai_pes || null,
      usuins_pes: params.pessoa?.usuins_pes || params.usuins_pes || null,
      refban_pes: params.pessoa?.refban_pes || params.refban_pes || null,
      datins_pes: params.pessoa?.datins_pes || params.datins_pes || new Date(),
      dahalt_pes: params.pessoa?.dahalt_pes || params.dahalt_pes || null,
      numcom_pes: params.pessoa?.numcom_pes || params.numcom_pes || null,
      cepcom_pes: params.pessoa?.cepcom_pes || params.cepcom_pes || null,
      endcom_pes: params.pessoa?.endcom_pes || params.endcom_pes || null,
      comcom_pes: params.pessoa?.comcom_pes || params.comcom_pes || null,
      muncom_pes: params.pessoa?.muncom_pes || params.muncom_pes || null,
      barcom_pes: params.pessoa?.barcom_pes || params.barcom_pes || null,
      numcob_pes: params.pessoa?.numcob_pes || params.numcob_pes || null,
      cepcob_pes: params.pessoa?.cepcob_pes || params.cepcob_pes || null,
      endcob_pes: params.pessoa?.endcob_pes || params.endcob_pes || null,
      comcob_pes: params.pessoa?.comcob_pes || params.comcob_pes || null,
      muncob_pes: params.pessoa?.muncob_pes || params.muncob_pes || null,
      barcob_pes: params.pessoa?.barcob_pes || params.barcob_pes || null,
      modalidadeVenda: params.pessoa?.modalidadeVenda || params.modalidadeVenda || {},
      municipioComercial: params.pessoa?.municipioComercial || params.municipioComercial || {},
      municipioCobranca: params.pessoa?.municipioCobranca || params.municipioCobranca || {},
      empresa: params.pessoa?.empresa || {},
      pais: params.pessoa?.pais || {},
      estado: params.pessoa?.estado || {},
      municipio: params.pessoa?.municipio || {},
      idrad_pes: params.pessoa?.idrad_pes || params.idrad_pes || null,
      telsac_pes: params.pessoa?.telsac_pes || params.telsac_pes || null,
      cliata_pes: params.pessoa?.cliata_pes || params.cliata_pes || false,
      parcei_pes: params.pessoa?.parcei_pes || params.parcei_pes || false,
      classificacao: params.pessoa?.classificacao || params.classificacao || {},
      grupo: params.pessoa?.grupo || params.grupo || {},
      categoria: params.pessoa?.categoria || params.categoria || {},
      tabelaDesconto: params.pessoa?.tabelaDesconto || params.tabelaDesconto || {},
      autorizacaoContatos: params.pessoa?.autorizacaoContatos || params.autorizacaoContatos || []
    })

    this.codigo_pej = params.codigo_pej || null
    this.codret_pej = params.codret_pej || null
    this.fornec_pej = params.fornec_pej || null
    this.datfun_pej = params.datfun_pej ? new Date(params.datfun_pej) : null
    this.ramati_pej = params.ramati_pej || null
    this.vercon_pej = params.vercon_pej || null
    this.empcon_pej = params.empcon_pej || null
    this.concon_pej = params.concon_pej || null
    this.telcon_pej = params.telcon_pej || null
    this.financ_pej = params.financ_pej || null
    this.transp_pej = params.transp_pej || null
    this.despac_pej = params.despac_pej || null
    this.forofi_pej = params.forofi_pej || 0
    this.nomfan_pej = params.nomfan_pej || null
    this.obs_pej = params.obs_pej || null
    this.codcnt_pej = params.codcnt_pej || null
    this.numsuf_pej = params.numsuf_pej || null
    this.cnae_pej = params.cnae_pej || null
    this.codpea_pej = params.codpea_pej || null
    this.nire_pej = params.nire_pej || null
    this.retiss_pej = params.retiss_pej || 0
    this.retcsr_pej = params.retcsr_pej || 0
    this.isconv_pej = params.isconv_pej || null
    this.fidfor_pej = params.fidfor_pej || 0
    this.nofpecnom_pej = params.nofpecnom_pej || 'S'
    this.nofsernom_pej = params.nofsernom_pej || 'S'
    this.codadq_pej = params.codadq_pej || null
    this.inccul_pej = params.inccul_pej || 0
    this.incfis_pej = params.incfis_pej || 0
    this.codrte_pej = params.codrte_pej || 0
    this.cliente = params.cliente || {}
    this.socios = params.socios || []
    this.conveniados = params.conveniados || []
    this.intven_pej = params.intven_pej || null
    this.usuarioInsercao = params.pessoa?.usuarioInsercao || {}
    this.usuarioAlteracao = params.pessoa?.usuarioAlteracao || {}
    this.contabilista = params.contabilista || { pessoa: {} }
    this.regimeTributacao = params.regimeTributacao || {}
    this.regimeTributacaoEspecial = params.regimeTributacaoEspecial || {}
    this.diapag_cli = params.diapag_cli || params.cliente?.payment_day || null
  }

  toMap () {
    return {
      codigo_pes: this.codigo_pes || null,
      nomraz_pes: this.nomraz_pes || null,
      apelid_pes: this.apelid_pes || null,
      end_pes: this.end_pes || null,
      bairro_pes: this.bairro_pes || null,
      cidade_pes: this.cidade_pes || null,
      uf_pes: this.uf_pes || null,
      pais_pes: this.pais_pes || null,
      cep_pes: CommonHelper.clearDigitsSpecialChars(this.cep_pes) || null,
      email_pes: this.email_pes ? this.email_pes.toLowerCase() : null,
      emafin_pes: this.emafin_pes ? this.emafin_pes.toLowerCase() : null,
      numend_pes: this.numend_pes || null,
      codemp_pes: this.codemp_pes || null,
      cgccpf_pes: CommonHelper.clearDigitsSpecialChars(this.cgccpf_pes) || null,
      ierg_pes: CommonHelper.clearDigitsSpecialChars(this.ierg_pes) || null,
      ie_pes: this.ie_pes || '',
      insmun_pes: this.insmun_pes || null,
      telcel_pes: CommonHelper.clearDigitsSpecialChars(this.telcel_pes) || null,
      telcom_pes: CommonHelper.clearDigitsSpecialChars(this.telcom_pes) || null,
      telres_pes: CommonHelper.clearDigitsSpecialChars(this.telres_pes) || null,
      comend_pes: this.comend_pes || null,
      codmun_pes: this.codmun_pes || null,
      codpai_pes: this.codpai_pes || null,
      usuins_pes: this.usuins_pes || null,
      refban_pes: this.refban_pes || null,
      datins_pes: formatDateToAmerican(this.datins_pes || new Date()),
      codigo_pej: this.codigo_pej || null,
      codret_pej: this.regimeTributacao.codigo_ret || null,
      fornec_pej: this.fornec_pej || null,
      datfun_pej: this.datfun_pej ? formatDateToAmerican(this.datfun_pej) : null,
      ramati_pej: this.ramati_pej || null,
      vercon_pej: this.vercon_pej || null,
      empcon_pej: this.empcon_pej || null,
      concon_pej: this.concon_pej || null,
      telcon_pej: this.telcon_pej || null,
      financ_pej: this.financ_pej || false,
      transp_pej: this.transp_pej || false,
      despac_pej: this.despac_pej || false,
      forofi_pej: this.forofi_pej || false,
      nomfan_pej: this.nomfan_pej || null,
      obs_pej: this.obs_pej || null,
      codcnt_pej: this.contabilista.codigo_cnt || null,
      numsuf_pej: this.numsuf_pej || null,
      cnae_pej: this.cnae_pej || null,
      codpea_pej: this.codpea_pej || null,
      nire_pej: this.nire_pej || null,
      retiss_pej: this.retiss_pej || 0,
      retcsr_pej: this.retcsr_pej || 0,
      isconv_pej: this.isconv_pej || false,
      fidfor_pej: this.fidfor_pej || false,
      intven_pej: this.intven_pej || false,
      nofpecnom_pej: this.nofpecnom_pej || 'S',
      nofsernom_pej: this.nofsernom_pej || 'S',
      codadq_pej: this.codadq_pej || null,
      inccul_pej: this.inccul_pej || 0,
      incfis_pej: this.incfis_pej || 0,
      codrte_pej: this.regimeTributacaoEspecial.codigo_rte || 0,
      socios: this.socios || null,
      conveniados: this.conveniados || null,
      idrad_pes: this.idrad_pes || null,
      telsac_pes: this.telsac_pes || null,
      cliata_pes: this.cliata_pes || false,
      parcei_pes: this.parcei_pes || false,
      numcom_pes: this.numcom_pes || null,
      cepcom_pes: this.cepcom_pes || null,
      endcom_pes: this.endcom_pes || null,
      comcom_pes: this.comcom_pes || null,
      muncom_pes: this.municipioComercial.id || null,
      barcom_pes: this.barcom_pes || null,
      numcob_pes: this.numcob_pes || null,
      cepcob_pes: this.cepcob_pes || null,
      endcob_pes: this.endcob_pes || null,
      comcob_pes: this.comcob_pes || null,
      muncob_pes: this.municipioCobranca.id || null,
      barcob_pes: this.barcob_pes || null,
      diapag_cli: this.diapag_cli || null,
      codgup_pes: this.grupo.codigo_gup || null,
      codcat_pes: this.categoria.codigo_cat || null,
      codcap_pes: this.classificacao.codigo_cap || null,
      codtad_pes: this.tabelaDesconto.codigo_tad || null,
      codmov_pes: this.modalidadeVenda.codigo_mov || null,
      autorizacaoContatos: this.autorizacaoContatos || []
    }
  }

  toFilter () {
    const validFields = Object
      .keys(this)
      .filter(it => !!this[it])
      .map(it => ({ [it]: this[it] }))

    let fields = {}

    validFields.forEach(it => {
      fields = { ...it, ...fields }
    })

    return queryString.stringify(fields)
  }

    static atividades = [
      { codigo_pea: 2, descri_pea: 'Distribuidora' },
      { codigo_pea: 1, descri_pea: 'Fabricante' },
      { codigo_pea: 4, descri_pea: 'Loja de peça' },
      { codigo_pea: 3, descri_pea: 'Outra' }
    ]
}
