import React from 'react'

function TabMenuPage ({ children, header, id }) {
  return (
    <div>
      {children}
    </div>
  )
}

export default TabMenuPage
