import ServiceBase from '../../base/ServiceBase'

export default class BancoService {
  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`bancos?${query}&page=${page}&perPage=${perPage}`)
  }

  static async filterAutocomplete (query) {
    return await ServiceBase.getBase('bancos?name=' + query, false)
  }
}
