import { Button } from 'primereact/button'
import { classNames } from 'primereact/utils'
import React, { useEffect, useState } from 'react'
import DateTimeInput from '../../../../../components/inputs/DateTimeInput'
import SelectInput from '../../../../../components/inputs/SelectInput'
import SearchInput from '../../../../../components/inputs/searchInput/SearchInput'
import AppButton from '../../../../../components/layout/AppButton'
import Container from '../../../../../components/layout/Container'
import DataListSimples from '../../../../../components/layout/DataListSimples/DataListSimples'
import FieldErrorMessage from '../../../../../components/utils/FieldErrorMessage'
import { showErrorMessage, showWarnMessage } from '../../../../../components/utils/Message'
import Modal from '../../../../../components/utils/Modal'
import RequiredLabel from '../../../../../components/utils/RequiredLabel'
import CommonHelper from '../../../../../helpers/CommonHelper'
import { formatDocument, formatPlaca, formatToCurrency } from '../../../../../helpers/formaters'
import { createColumns } from '../../../../../helpers/tableConfigs'
import { useValidateInput } from '../../../../../helpers/useValidateInput'
import { PessoaModel } from '../../../../../models/cadastro/pessoa/PessoaModel'
import { VendedorModel } from '../../../../../models/cadastro/pessoa/VendedorModel'
import PessoaService from '../../../../../services/cadastro/pessoa/PessoaService'
import VendedoresService from '../../../../../services/cadastro/pessoa/VendedoresService'
import ConfigService from '../../../../../services/configuracao/ConfigService'
import TipoMidiaService from '../../../../../services/vendas/TipoMidiaService'
import { PessoaFisica } from '../../../../../views/cadastro/pessoa/fisica/PessoaFisica'
import { PessoaJuridica } from '../../../../../views/cadastro/pessoa/juridica/PessoaJuridica'
import VeiculoConjuntoModal from '../VeiculoConjuntoModal'

function DadosVeiculosConjuntoNeg ({ form, edicaoHabilitada, pagandoConsignado, operacaoNeg }) {
  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(form)
  const [veiculoSelecionado, setVeiculoSelecionado] = useState({})
  const [visibleVeiculoConjuntoModal, setVisibleVeiculoConjuntoModal] = useState(false)
  const [sugestaoPessoas, setSugestaoPessoas] = useState([])
  const [sugestaoVendedor, setSugestaoVendedor] = useState([])
  const [tiposmidia, setTipoMidia] = useState([])
  const [visiblePrevisaoEntregaModal, setVisiblePrevisaoEntregaModal] = useState(false)
  const [columns, setColumns] = useState([])
  const [config1433, setConfig1433] = useState(false)

  const isVenda = () => {
    return operacaoNeg === 'Venda'
  }
  const isCompra = () => {
    return operacaoNeg === 'Compra'
  }

  const isConsignado = () => {
    return operacaoNeg === 'Consignação'
  }

  useEffect(() => {
    getTiposMidia()

    const columns = [
      {
        field: 'veiculo.codtiv_vei',
        header: 'Novo/Usado',
        width: isVenda() ? '10%' : '20%',
        customClassName: getTipoVeiculoClassName,
        customBody: getTipoVeiculo,
        sortable: false
      },
      {
        field: 'veiculo.placa_vei',
        header: 'Placa/Chassi',
        width: isVenda() ? '15%' : '20%',
        customBody: getPlacha,
        sortable: false
      },
      {
        field: 'veiculo.modelo.marca.descri_mar',
        header: 'Marca/Modelo',
        width: isVenda() ? '15%' : '20%',
        customBody: getMarcaModelo,
        sortable: false
      },
      {
        field: 'kmatu_nve',
        header: 'Km Atual',
        width: isVenda() ? '15%' : '20%',
        customBody: getKilometers,
        sortable: false
      }
    ]

    if (isVenda()) {
      columns.push({
        field: 'valbru_nve',
        header: 'Valor bruto',
        width: '15%',
        customBody: getValorBrutoVei,
        sortable: false
      },
      {
        field: 'valdes_nve',
        header: 'Desconto',
        width: '15%',
        customBody: getValorDescontoVei,
        sortable: false
      },
      {
        field: 'valor_nve',
        header: 'Valor total',
        width: '15%',
        customBody: getValorTotalVei,
        sortable: false
      })
    }

    if (isCompra() || isConsignado()) {
      columns.push(
        {
          field: 'valor_nve',
          header: 'Valor',
          width: '20%',
          customBody: getValorTotalVei,
          sortable: false
        })
    }
    form.setFieldValue('negociacaoVeiculo', form.values.veiculosConjunto[0])
    form.setFieldValue('negociacaoVeiculo.proprietario', form.values.veiculosConjunto.veiculo?.proprietario || form.values.veiculosConjunto[0].veiculo?.proprietario)
    form.setFieldValue('veiculosConjunto', form.values.veiculosConjunto || form.values.veiculosConjunto[0])

    setColumns(createColumns(columns))
  }, [])

  useEffect(async () => {
    setConfig1433(await ConfigService.getValor(1433))
  }, [])

  const getTiposMidia = async () => {
    try {
      const tiposmidia = await TipoMidiaService.filterTipoMidia()
      await form.setFieldValue('tiposmidia', tiposmidia)
      setTipoMidia(tiposmidia)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar as mídias!')
    }
  }

  function getTipoVeiculo (data) {
    return data.veiculo.codtiv_vei === 'n' ? 'Novo' : 'Usado'
  }

  function getTipoVeiculoClassName (data) {
    return `tipo-veiculo-${data.veiculo.codtiv_vei}`
  }

  function getPlacha (data) {
    return formatPlaca(data.veiculo.placa_vei) || data.veiculo.chassi_vei
  }

  function getMarcaModelo (data) {
    return data.veiculo.modelo.marca.descri_mar + '/' + data.veiculo.modelo.descri_mod
  }

  function getValorTotalVei (data) {
    return formatToCurrency(data.valor_nve)
  }

  function getValorBrutoVei (data) {
    return formatToCurrency(data.valbru_nve)
  }

  function getValorDescontoVei (data) {
    return formatToCurrency(data.valdes_nve)
  }

  function getKilometers (data) {
    return data.kmatu_nve.toLocaleString('pt-BR')
  }

  const sugerirPessoas = async () => {
    try {
      const pessoas = await PessoaService.filterAutocomplete(form.values.pessoa.nomraz_pes)

      if (pessoas.length <= 0) {
        showWarnMessage('Pessoa não encontrada!')
      }

      setSugestaoPessoas(pessoas)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir uma pessoa!')
    }
  }

  const sugerirVendedor = async () => {
    try {
      const vendedoresService = new VendedoresService()
      const vendedores = await vendedoresService.filterAutoComplete(`nomraz_pes=${form.values.vendedor.nomraz_pes}`)

      if (vendedores.length <= 0) {
        showWarnMessage('Vendedor não encontrado!')
      }

      setSugestaoVendedor(vendedores)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um vendedor!')
    }
  }

  const handleChangeVendedor = (e) => {
    form.setFieldValue('vendedor', e.target.value)
    form.setFieldValue('vendedor.nomraz_pes', e.target.value)
  }

  const handleChangePessoa = (e) => {
    form.setFieldValue('pessoa', e.target.value)
    form.setFieldValue('pessoa.nomraz_pes', e.target.value)
  }

  const handleChangeTipoMidia = async (e) => {
    form.setFieldValue('tipomidia', e.target.value)
    form.setFieldValue('tipomidia.descri_tim', e.target.value?.descri_tim)
    form.setFieldValue('tipomidia.codigo_tim', e.target.value?.codigo_tim)
  }

  function onSaveModalPessoa (pessoa) {
    onSelectPessoa({ value: pessoa.pessoa })
  }

  const onSelectPessoa = async (e) => {
    await form.setFieldValue('pessoa', e.value)
    form.setFieldTouched('pessoa.codigo_pes')
  }

  const onSelectVendedor = async (e) => {
    await form.setFieldValue('vendedor', e.value)
    form.setFieldTouched('vendedor.codigo_pes')
  }

  function changeAllPrevisaoDeEntrega (e) {
    const { veiculosConjunto } = form.values

    for (const veiculoConjunto of veiculosConjunto) {
      veiculoConjunto.dahpreent_nve = e.target.value
    }

    form.setFieldValue('veiculosConjunto', veiculosConjunto)
  }

  const limparVeiculo = () => {
    form.setFieldValue('veiculosConjunto', {})
    form.setFieldValue('negociacaoVeiculo', {})
    form.setFieldValue('negociacaoVeiculo.veiculo.proprietario', {})
    form.setFieldValue('negociacaoVeiculo.veiculo.codpes_vei', {})
    form.setFieldValue('vehiclesToSell.veiculo.proprietario', {})
  }

  return (
    <Container className="mb-5">
      <div className="formgrid grid fluid">
        <div className={' field col-12 md:col-4' }>
          <SearchInput
            //AutoComplete
            field="nomraz_pes"
            label="Cliente"
            value={form.values.pessoa && form.values.pessoa.nomraz_pes}
            placeholder="Nome do cliente"
            disabled={pagandoConsignado ? true : !edicaoHabilitada}
            onSelect={async (e) => await onSelectPessoa(e)}
            onBlur={() => form.setFieldTouched('pessoa.codigo_pes')}
            suggestions={sugestaoPessoas}
            completeMethod={sugerirPessoas}
            onChange={handleChangePessoa}
            className={classNames({ 'p-invalid': isFormFieldValid('pessoa.codigo_pes') }, 'inputfield w-full')}
            isFormFieldValid={isFormFieldValid('pessoa.codigo_pes')}
            //RegisterModal
            selected={form.values.pessoa}
            onSaveModal={onSaveModalPessoa}
            onDeleteModal={async () => await onSelectPessoa({})}
            ComponenteCadastro={[
              {
                label: 'Pessoa física',
                component: PessoaFisica,
                header: form.values.pessoa.codigo_pes ? 'Pessoa Física - ' + form.values.pessoa.codigo_pes : 'Cadastro de pessoa física'
              },
              {
                label: 'Pessoa jurídica',
                component: PessoaJuridica,
                header: form.values.pessoa.codigo_pes ? 'Pessoa jurídica - ' + form.values.pessoa.codigo_pes : 'Cadastro de pessoa jurídica'
              }
            ]}
            // FiltroModal
            filtrotitle="Pesquisa de pessoa"
            service={PessoaService}
            model={PessoaModel}
            primarykeyname="codigo_pes"
            columns={[
              { campo: 'codigo_pes', nome: 'Código' },
              { campo: 'nomraz_pes', nome: 'Nome razão' },
              { campo: 'nomfan_pej', nome: 'Nome fantasia', preload: 'pessoaJuridica' },
              { campo: 'cgccpf_pes', nome: 'CPF/CNPJ', format: formatDocument }
            ]}
          />
          <FieldErrorMessage message={getFormErrorMessage('pessoa.codigo_pes')} />
        </div>
        <div className={'md:col-4 field col-12'}>
          <SearchInput
            //AutoComplete
            field="nomraz_pes"
            label="Vendedor"
            value={form.values.vendedor && form.values.vendedor.nomraz_pes}
            placeholder="Nome do Vendedor"
            minLength='1'
            disabled={!edicaoHabilitada}
            onSelect={async (e) => await onSelectVendedor(e)}
            className={classNames({ 'p-invalid': isFormFieldValid('vendedor.codigo_pes') }, 'inputfield w-full')}
            onBlur={() => form.setFieldTouched('vendedor.codigo_pes')}
            suggestions={sugestaoVendedor}
            completeMethod={sugerirVendedor}
            onChange={handleChangeVendedor}
            isFormFieldValid={isFormFieldValid('vendedor.codigo_pes')}
            // FiltroModal
            filtrotitle="Pesquisa de vendedor"
            service={VendedoresService}
            model={VendedorModel}
            primarykeyname="codigo_ven"
            columns={[
              { campo: 'codigo_ven', nome: 'Código' },
              { campo: 'nomraz_pes', nome: 'Nome' }
            ]}
          />
          <FieldErrorMessage message={getFormErrorMessage('vendedor.codigo_pes')} />
        </div>
        <div className="field col-12 md:col-4">
          {config1433 && !isConsignado() && isVenda() ? <RequiredLabel label="Mídia" valid={isFormFieldValid('tipomidia.codigo_tim')} /> : <label>Mídia</label> }
          <SelectInput
            value={form.values?.tipomidia}
            options={tiposmidia}
            onChange={handleChangeTipoMidia}
            name="tipomidia"
            optionLabel="descri_tim"
            dataKey="codigo_tim"
            placeholder="- Selecione -"
            noFloatLabel
            disabled={!edicaoHabilitada}
            className={classNames({ 'p-invalid': isFormFieldValid('tipomidia.codigo_tim') }, 'inputfield w-full')}
            showClear={form.values.tipomidia?.codigo_tim}
            onHide={() => form.setFieldTouched('tipomidia.codigo_tim')}
          />
          <FieldErrorMessage message={getFormErrorMessage('tipomidia.codigo_tim')} />
        </div>
        <div className="form-divider"></div>
      </div>
      {isVenda() && (
        <div className={'field col-12 md:col-12 flex justify-content-' + (CommonHelper.isDesktop() ? 'end' : 'center')}>
          <AppButton
            label="Limpar"
            icon="pi pi-times"
            type="button"
            onClick={() => limparVeiculo()}
            className="p-button-danger mr-2 "
            disabled={!edicaoHabilitada}
          />
          <Button
            label="Alterar previsão de entrega"
            onClick={() => setVisiblePrevisaoEntregaModal(true)}
            disabled={!edicaoHabilitada}
          />
        </div>
      )}
      <DataListSimples
        value={form.values.veiculosConjunto}
        columns={columns}
        selected={veiculoSelecionado}
        onSelect={(e) => {
          setVeiculoSelecionado(e.value)
          setVisibleVeiculoConjuntoModal(true)
        }}
        totalizadores={[
          { key: 'valbru_nve', label: 'Valor total bruto', formatFunction: formatToCurrency },
          { key: 'valdes_nve', label: 'Valor total do desconto', formatFunction: formatToCurrency },
          { key: 'valor_nve', label: 'Valor total líquido', formatFunction: formatToCurrency }
        ]}
      />
      <VeiculoConjuntoModal
        visible={visibleVeiculoConjuntoModal}
        onHide={() => setVisibleVeiculoConjuntoModal(false)}
        veiculoNve={veiculoSelecionado}
        edicaoHabilitada={edicaoHabilitada}
        pagandoConsignado={pagandoConsignado}
        operacaoNeg={operacaoNeg}
        form={form}
      />
      <Modal
        header="Alterar previsão de entrega para todos os veículos"
        visible={visiblePrevisaoEntregaModal}
        onHide={() => setVisiblePrevisaoEntregaModal(false)}
      >
        <DateTimeInput
          label="Previsão de entrega"
          placeholder="Previsão de entrega"
          value={form.values.veiculosConjunto[0].dahpreent_nve}
          onChange={changeAllPrevisaoDeEntrega}
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
        />
      </Modal>
    </Container>
  )
}

export default DadosVeiculosConjuntoNeg
