import ServiceBase from '../../base/ServiceBase'

export default class PagarConsignadosService {
  async get (id) {
    return await ServiceBase.getBase(`negociacao/${id}`)
  }

  async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`pagarconsignados?page=${page}&perPage=${perPage}`)
  }

  static async getQuantidade () {
    return 0
    //return await ServiceBase.getBase('pagarconsignados/quantidade', false)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`pagarconsignados?${query}&page=${page}&perPage=${perPage}`)
  }
}
