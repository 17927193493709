import * as Yup from 'yup'

export const AvaliacaoValidator = Yup.object().shape({
  vendedor: Yup.object().shape({
    codigo_ven: Yup.number()
      .nullable()
      .required('Este campo é obrigatório!')
  }),
  placa_ava: Yup.mixed()
    .nullable()
    .required('Este campo é obrigatório!'),
  datval_ava: Yup.date()
    .nullable()
    .required('Este campo é obrigatório!')
})
