import ServiceBase from '../../base/ServiceBase'

export default class LandingVeiculoService {
  static async getLandingVeiculo (veiculoId, database) {
    return await ServiceBase.getBase(`landing-veiculo/${database}/${veiculoId}`)
  }

  static async getDadosEmpresa (empresaId, database) {
    return await ServiceBase.getBase(`landing-veiculo/empresa/${database}/${empresaId}`)
  }

  static async sendLandingVeiculoEmail (url, email, nomeEmpresa, veiculo) {
    return await ServiceBase.postBase('landing-veiculo/email', {
      url,
      email,
      nomeEmpresa,
      veiculo
    })
  }
}
