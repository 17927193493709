import React, { Component } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

/**
 * @typedef {object} Props
 * @prop {Array<{ key: string }>} items Array de objetos de coluna
 * @prop {Component} itemTemplate Componente de template do item
 * @prop {Function} onChangeItems Função onChange da lista de itens
 *
 * @extends {React.Component<Props>}
 */
export default class DragAndDropList extends Component {
  onDragEnd = (result) => {
    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list)
      const [removed] = result.splice(startIndex, 1)
      result.splice(endIndex, 0, removed)

      return result
    }

    if (!result.destination) {
      return
    }

    const items = reorder(
      this.props.items,
      result.source.index,
      result.destination.index
    )

    this.props.onChangeItems(items)
  }

  render () {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="p-2"
              style={{ background: snapshot.isDraggingOver ? '#f2f3f7' : '#ebecf0' }}
            >
              {this.props.items.map((option, index) => (
                <Draggable key={option.key} draggableId={option.key} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{ background: snapshot.isDragging ? '#e4e9f5' : '#ffffff', ...provided.draggableProps.style }}
                      className="mt-2 p-2 shadow-1"
                    >
                      {this.props.itemTemplate(option)}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>)}
        </Droppable>
      </DragDropContext>
    )
  }
}
