import { Crud } from '../../../../classes/NewCrud'
import { getPessoaFisicaDTO } from '../../../../dtos/cadastro/pessoa/PessoaFisicaDTO'
import PessoaFisicaForm from '../../../../forms/cadastro/pessoa/pessoaFisica/PessoaFisicaForm'
import { PessoaFisicaValidator } from '../../../../forms/cadastro/pessoa/pessoaFisica/PessoaFisicaValidator'
import PessoaFisicaService from '../../../../services/cadastro/pessoa/PessoaFisicaService'
import React from 'react'

export function PessoaFisica (props) {
  return (
    <Crud
      match={props.match}
      onSaveModal={props.onSaveModal}
      onDeleteModal={props.onDeleteModal}
      codigoSelecionado={props.codigoSelecionado}
      onHide={props.onHide}
      apenasVisualizacao={props.apenasVisualizacao}
      formContent={PessoaFisicaForm}
      valida={PessoaFisicaValidator}
      modelGetDTO={getPessoaFisicaDTO}
      service={PessoaFisicaService}
      resource="pessoas-fisicas"
      primarykeyname="codigo_pef"
      formTitle="Pessoa Física"
    />
  )
}
