import React from 'react'

import Container from '../../../../components/layout/Container'
import DataListSimples from '../../../../components/layout/DataListSimples/DataListSimples'
import Modal from '../../../../components/utils/Modal'
import { createColumns } from '../../../../helpers/tableConfigs'

const UsuarioPermissaoReqModal = ({ hide, visible, listaAprovadores }) => {
  const columns = createColumns([
    { field: 'codigo_pes', header: 'CÓDIGO', width: '20%' },
    { field: 'nomraz_pes', header: 'NOME', width: '80%' }
  ])
  return (
    <Modal
      header={listaAprovadores?.length > 0 ? 'Pessoas com permissão para aprovar' : 'Pessoa com permissão para aprovar'}
      width="70"
      visible={visible}
      modal={true}
      onHide={hide}
    >
      <Container>

        <DataListSimples
          value={listaAprovadores}
          columns={columns}
          onSelect={() => {}}
        >

        </DataListSimples>
      </Container>
    </Modal>
  )
}

export default UsuarioPermissaoReqModal
