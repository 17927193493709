import React from 'react'
import { Crud } from '../../../classes/NewCrud'
import { getOrcamentoBalcaoDTO } from '../../../dtos/vendas/orcamentoBalcao/OrcamentoBalcaoDTO'
import OrcamentoBalcaoForm from '../../../forms/vendas/orcamentoBalcao/OrcamentoBalcaoForm'
import { OrcamentoBalcaoValidator } from '../../../forms/vendas/orcamentoBalcao/OrcamentoBalcaoValidator'
import OrcamentoBalcaoService from '../../../services/vendas/orcamentoBalcao/OrcamentoBalcaoService'

export function OrcamentoBalcao (props) {
  return (
    <Crud
      match={props.match}
      onSaveModal={props.onSaveModal}
      onDeleteModal={props.onDeleteModal}
      codigoSelecionado={props.codigoSelecionado}
      onHide={props.onHide}
      apenasVisualizacao={props.apenasVisualizacao}
      formContent={OrcamentoBalcaoForm}
      modelGetDTO={getOrcamentoBalcaoDTO}
      service={OrcamentoBalcaoService}
      valida={OrcamentoBalcaoValidator}
      resource=""
      primarykeyname="codigo_opv"
      numeroKeyName="numero_opv"
      formTitle="Orçamento Balcão"
      isNew={props.isNew}
    />
  )
}
