import { TabMenu } from 'primereact/tabmenu'
import React, { useEffect, useState } from 'react'
import CommonHelper from '../../../helpers/CommonHelper'
import AppButton from '../AppButton'
import PanelContainer from '../PanelContainer'
import './_tabMenuContainer.scss'

function TabMenuContainer ({ children, tabChangeValidator, contentHeader, activeIndexParaNaoMostrarContentHeader, arrayLabelButtonNext, arrayLabelButtonBack, mostrarBotaoProximo = true }) {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [tabMenuPages, setTabMenuPages] = useState(children)

  useEffect(() => {
    const tabMenuPages = []

    React.Children.forEach(children, (child) => {
      if (child && child.type.name) {
        tabMenuPages.push(child.props.children)
      }
    })

    setTabMenuPages(tabMenuPages)
  }, [children])

  function getTabMenu () {
    const tabNames = []

    React.Children.forEach(children, (child) => {
      if (child && child.type.name) {
        tabNames.push(child.props.header)
      }
    })

    const model = tabNames.map(name => { return { label: name } })

    if (CommonHelper.isDesktop()) {
      return <TabMenu
        model={model}
        activeIndex={activeTabIndex}
        onTabChange={(e) => handleChangeActiveTabIndex(e.index)}
      />
    }

    return (
      <h1 className="tabMenu-header-cellphone">
        {tabNames[activeTabIndex]}
      </h1>
    )
  }

  function handleButtonTabChange (anteriorOuProximo) {
    if (anteriorOuProximo === 'Proximo') {
      handleChangeActiveTabIndex(activeTabIndex + 1)
    } else {
      handleChangeActiveTabIndex(activeTabIndex - 1)
    }
  }

  function handleChangeActiveTabIndex (index) {
    if (tabChangeValidator) {
      if (tabChangeValidator()) {
        setActiveTabIndex(index)
      }
    } else {
      setActiveTabIndex(index)
    }
  }

  return (
    <PanelContainer toggeable={false} headerTemplate={getTabMenu()} className="w-full">
      {(contentHeader && !(activeIndexParaNaoMostrarContentHeader && activeIndexParaNaoMostrarContentHeader.includes(activeTabIndex))) && (
        <>
          {contentHeader}
        </>
      )}
      {tabMenuPages.length > 0 ? tabMenuPages[activeTabIndex] : tabMenuPages}
      {mostrarBotaoProximo
        ? <div className="field col-12 md:col-12 grid justify-content-center no-margin">
          {activeTabIndex !== 0 && (
            <AppButton
              type="button"
              className="anterior-button neg-button mr-3 ml-3"
              label={CommonHelper.isDesktop() ? arrayLabelButtonBack ? arrayLabelButtonBack[activeTabIndex - 1] : 'Anterior' : ''}
              icon={CommonHelper.isDesktop() ? '' : 'pi pi-chevron-left'}
              onClick={() => handleButtonTabChange('Anterior')}
            />
          )}
          {(activeTabIndex !== tabMenuPages.length - 1) && (
            <AppButton
              type="button"
              className={'proximo-button neg-button mr-3 ml-3'}
              label={CommonHelper.isDesktop() ? arrayLabelButtonNext ? arrayLabelButtonNext[activeTabIndex] : 'Próximo' : ''}
              icon={CommonHelper.isDesktop() ? '' : 'pi pi-chevron-right white'}
              onClick={() => handleButtonTabChange('Proximo')}
            />
          )}
        </div> : '' }
    </PanelContainer>
  )
}

export default TabMenuContainer
