import React from 'react'
import DataListSimples from '../../../../components/layout/DataListSimples/DataListSimples'
import Modal from '../../../../components/utils/Modal'
import CommonHelper from '../../../../helpers/CommonHelper'
import { formatToCurrency } from '../../../../helpers/formaters'
import { createColumns } from '../../../../helpers/tableConfigs'

function OrcamentosAbertosOuReservadosModal ({ visible, onHide, orcamentosAbertosOuReservados, cliente }) {
  const columns = createColumns([
    { field: 'numero_opv', header: 'Número do orçamento', width: '20%' },
    { field: 'nomeEmpresa', header: 'Empresa', width: '20%' },
    { field: 'dahins_opv', header: 'Data', width: '15%', customBody: getDataInsercaoOrcamento },
    { field: 'descri_sop', header: 'Situação', width: '10%' },
    { field: 'nomeConsultor', header: 'Consultor', width: '20%' },
    { field: 'totliq_opv', header: 'Valor Total', width: '15%', customBody: getValorTotalOrcamento }
  ])

  function getDataInsercaoOrcamento (data) {
    return CommonHelper.dateTimeToBrazilian(data.dahins_opv)
  }

  function getValorTotalOrcamento (data) {
    return formatToCurrency(data.totliq_opv)
  }

  const s = orcamentosAbertosOuReservados.length === 1 ? '' : 's'

  const ao = orcamentosAbertosOuReservados.length === 1 ? 'a' : 'ão'

  return (
    <Modal
      visible={visible}
      onHide={onHide}
      header="Orçamentos abertos ou reservados do cliente"
      width={60}
    >
      <div className="mb-5">
        O{s} orçamento{s} abaixo est{ao} Reservado{s}/Aberto{s} para <strong>{cliente.nomraz_pes}</strong>,
        verifique se o orçamento que você está fazendo não é continuação {orcamentosAbertosOuReservados.length === 1 ? '' : 'de algum'} dele{s}.
      </div>
      <DataListSimples
        value={orcamentosAbertosOuReservados}
        columns={columns}
        onSelect={(e) => window.open(`${window.origin}/orcamentoBalcao/${e.value.codigo_opv}`)}
      />
    </Modal>
  )
}

export default OrcamentosAbertosOuReservadosModal
