export function getFilterConfig (filterName) {
  const allFilterConfigs = JSON.parse(localStorage.getItem('FilterConfigs'))

  if (!allFilterConfigs) return null

  convertDateStringsToDateTypeRecursively(allFilterConfigs[`${filterName}_FilterConfig`])

  return allFilterConfigs[`${filterName}_FilterConfig`]
}

function convertDateStringsToDateTypeRecursively (object) {
  const objectPropertiesNames = Object.getOwnPropertyNames(object)

  for (const propertyName of objectPropertiesNames) {
    const propertyTypeName = typeof object[propertyName]

    if (propertyTypeName === 'string') {
      const convertedValue = new Date(object[propertyName])

      if (convertedValue.getTime()) {
        object[propertyName] = convertedValue
      }
    }

    if (propertyTypeName === 'object' && object[propertyName]) {
      convertDateStringsToDateTypeRecursively(object[propertyName])
    }
  }
}

export function setFilterConfig (filterName, filterConfigValue) {
  const allFilterConfigs = JSON.parse(localStorage.getItem('FilterConfigs')) || {}

  allFilterConfigs[`${filterName}_FilterConfig`] = filterConfigValue

  localStorage.setItem('FilterConfigs', JSON.stringify(allFilterConfigs))
}
