import moment from 'moment'
import * as Yup from 'yup'
import { isValidCnpj, isValidCpf } from '../../../../../../helpers/checkers'
import { clearDigitsSpecialChars } from '../../../../../../helpers/parsers'

export const ChequeValidator = Yup.object().shape({
  veiculo: Yup.object().shape({
    codigo_vei: Yup.number()
      .nullable()
      .required('O veículo não foi selecionado!')
  }),

  dataEmissao: Yup.date()
    .nullable()
    .required('A data da operação não foi informada!'),
  valor: Yup.number()
    .nullable()
    .required('O valor não foi informado!')
    .min(0.001, 'O valor não foi informado!'),
  dataCompensacao: Yup.date()
    .nullable()
    .test('testeDataCompensacao', 'A data de compensação não pode ser anterior a data de emissão!', function (value) {
      const diferenca = moment(value).diff(this.parent.dataEmissao, 'days')

      if (diferenca >= 0) {
        return true
      }

      return false
    }),
  cpfCnpjCedente: Yup.string()
    .nullable()
    .test('testCpfCnpj', 'CPF/CNPJ inválido!', (value) => {
      if (!value) {
        return false
      }

      const cpfCnpj = clearDigitsSpecialChars(value)

      if (cpfCnpj.length === 11) {
        return isValidCpf(cpfCnpj)
      } else if (cpfCnpj.length === 14) {
        return isValidCnpj(cpfCnpj)
      }

      return false
    })
})
