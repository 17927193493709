export const getMarcaDTO = (data = {}) => {
  return {
    codigo_mar: data.codigo_mar || null,
    descri_mar: data.descri_mar || null,
    bandeiraEmpresa: data.bandeiraEmpresa || {},
    forved_mar: data.forved_mar || null
  }
}

export const postMarcaDTO = (data) => {
  return {
    codigo_mar: data.codigo_mar || null,
    descri_mar: data.descri_mar || null,
    codbae_mar: data.bandeiraEmpresa?.codigo_bae || {},
    forved_mar: data.forved_mar || null,
    bandeiraEmpresa: data.bandeiraEmpresa || {}
  }
}
