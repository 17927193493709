import * as Yup from 'yup'

export const PessoaJuridicaValidator = Yup.object().shape({
  nomraz_pes: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  cgccpf_pes: Yup.string().nullable().test('pessoaExterior', 'Este campo é obrigatório!', function (value) {
    if (this.parent.codmun_pes === 9999999) {
      return true
    }
    if (!value) {
      return false
    }

    return true
  }),
  ie_pes: Yup.string().nullable().test('pessoaExterior', 'Este campo é obrigatório!', function (value) {
    if (this.parent.codmun_pes === 9999999) {
      return true
    }
    if (!value) {
      return false
    }

    return true
  }),
  end_pes: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  bairro_pes: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  numend_pes: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  cep_pes: Yup.string().nullable().test('pessoaExterior', 'Este campo é obrigatório!', function (value) {
    if (this.parent.codmun_pes === 9999999) {
      return true
    }
    if (!value) {
      return false
    }

    return true
  }),
  email_pes: Yup.string().nullable().test('testeConfig1270', 'Este campo é obrigatório!', function (value) {
    if (this.parent.obrigaEmail && !value) {
      return false
    }

    return true
  })
})
