import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { Fieldset } from 'primereact/fieldset'
import React, { useEffect, useState } from 'react'
import DateInput from '../../../../../components/inputs/DateInput'
import SelectInput from '../../../../../components/inputs/SelectInput'
import TextInput from '../../../../../components/inputs/TextInput'
import PessoaAutocompleteTemplate from '../../../../../components/inputs/options/PessoaAutocompleteTemplate'
import SearchInput from '../../../../../components/inputs/searchInput/SearchInput'
import { showErrorMessage } from '../../../../../components/utils/Message'
import CommonHelper from '../../../../../helpers/CommonHelper'
import { formatDate, formatDateTime, formatDocument } from '../../../../../helpers/formaters'
import { getStore } from '../../../../../redux/store'
import CidadesService from '../../../../../services/cadastro/pessoa/CidadesService'
import PaisesService from '../../../../../services/cadastro/pessoa/PaisesService'
import PessoaFisicaService from '../../../../../services/cadastro/pessoa/PessoaFisicaService'
import PessoaService from '../../../../../services/cadastro/pessoa/PessoaService'
import DeclaracaoResidenciaModal from '../../DeclaracaoResidenciaModal'

const DadosAdicionaisPef = ({ isNew, form, edicaoHabilitada }) => {
  const [visibleDeclaracaoModal, setVisibleDeclaracaoModal] = useState(false)
  const [sugestoesNaturalidade, setSugestoesNaturalidade] = useState([])
  const [sugestoesConjuge, setSugestoesConjuge] = useState([])
  const [escolaridades, setEscolaridades] = useState([])
  const [hobbies, setHobbies] = useState([])
  const [paises, setPaises] = useState([])

  useEffect(() => {
    const onLoad = async () => {
      getHobbies()
      getEscolaridades()
      getPaises()
      if (!form.values.nacion_pef) {
        form.setFieldValue('nacion_pef', 'Brasileiro')
      }
      if (!form.values.codpai_pes) {
        const brasil = await PaisesService.getPaisByCodigo(getStore().codigoBrasil)

        form.setFieldValue('codpai_pes', brasil.codigo_pai)
        form.setFieldValue('pais_pes', brasil.descri_pai)
        form.setFieldValue('pais', brasil)
      }
    }

    onLoad()
  }, [])
  const getHobbies = async () => {
    try {
      const hobbies = await PessoaFisicaService.getHobbies()
      setHobbies(hobbies)
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro inesperado ao listar os hobbies!')
    }
  }

  const getEscolaridades = async () => {
    try {
      const escolaridades = await PessoaFisicaService.getEscolaridades()
      setEscolaridades(escolaridades)
    } catch (e) {
      showErrorMessage(e.message || 'Houve um erro inesperado ao listar as escolaridades!')
    }
  }

  const getPaises = async () => {
    try {
      const paises = await PaisesService.getAll()
      setPaises(paises)
    } catch (e) {
      showErrorMessage(e.message || 'Houve um erro inesperado ao listar os países!')
    }
  }
  const handleChangePais = (e) => {
    const pais = e.target.value

    form.setFieldValue('codpai_pes', pais?.codigo_pai)
    form.setFieldValue('pais_pes', pais?.descri_pai)
    form.setFieldValue('pais', pais)

    // getEstadosPorPais(pais?.codigo_pai)
  }

  const handleChangeConjuge = (e) => {
    form.setFieldValue('conjuge', e.target.value)
    form.setFieldValue('conjuge.nomraz_pes', e.target.value)
  }

  const handleChangeNaturalidade = (e) => {
    form.setFieldValue('naturalidade', e.target.value)
    form.setFieldValue('naturalidade.description', e.target.value)
  }

  const sugerirNaturalidade = async () => {
    try {
      const cidades = await CidadesService.filterAutocomplete(
        `description=${form.values.naturalidade?.description}`
      )

      setSugestoesNaturalidade(cidades)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir uma naturalidade!')
    }
  }

  const sugerirConjuge = async () => {
    try {
      const pessoaFisicaService = new PessoaFisicaService()
      const pessoasFisicas = await pessoaFisicaService.filterAutoComplete(
        `descricaoFiltro=${form.values.conjuge.nomraz_pes}`
      )

      setSugestoesConjuge(pessoasFisicas)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um conjuge!')
    }
  }

  const handleClickComoChegar = async () => {
    try {
      const url = await PessoaService.getUrlComoChegar(form.values.codigo_pef)
      window.open(url, '_blank')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado, por favor tente novamente mais tarde!')
    }
  }

  const handleChangeParceiro = () => {
    form.setFieldValue('parcei_pes', !form.values.parcei_pes)
  }

  const showDeclaracaoResidenciaModal = (isVisibleDeclaracaoModal) => {
    if (isVisibleDeclaracaoModal) {
      return (
        <DeclaracaoResidenciaModal
          hide={() => setVisibleDeclaracaoModal(false)}
          codigoPessoa={form.values.codigo_pef}
        />
      )
    }
  }

  return (
    <div className="formgrid grid">
      <div className="field col-6 md:col-3">
        <TextInput
          label="CNH"
          placeholder="CNH"
          name="cnh_pef"
          value={form.values.cnh_pef}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          maxLength={20}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-6 md:col-3">
        <TextInput
          label="I.E."
          placeholder="I.E."
          name="ie_pes"
          value={form.values.ie_pes}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          maxLength={20}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-3">
        <TextInput
          label="Órgão Emissor (RG)"
          placeholder="Órgão Emissor (RG)"
          name="orgemi_pef"
          maxLength={20}
          value={form.values.orgemi_pef}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-3">
        <DateInput
          label="Data de emissão (RG)"
          name="datemi_pef"
          placeholder="Emissão"
          className="inputfield w-full"
          value={form.values.datemi_pef}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
        />
      </div>
      <div className="field col-12 md:col-3">
        <TextInput label="Nacionalidade"
          placeholder="Nacionalidade"
          name="nacion_pef"
          maxLength={100}
          value={form.values.nacion_pef}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-3">
        <SelectInput
          value={form.values.pais}
          options={paises}
          onChange={handleChangePais}
          name="pais"
          label="País"
          filter={CommonHelper.isDesktop()}
          optionLabel="descri_pai"
          dataKey="codigo_pai"
          noFloatLabel
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-3">
        <label className="label">Naturalidade</label>
        <SearchInput
          field="description"
          name="naturalidade"
          value={form.values.naturalidade?.description && form.values.naturalidade}
          suggestions={sugestoesNaturalidade}
          completeMethod={sugerirNaturalidade}
          onChange={handleChangeNaturalidade}
          onSelect={(e) => form.setFieldValue('naturalidade', e.value)}
          placeholder="Município"
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-3">
        <SelectInput
          value={form.values.escolaridade}
          options={escolaridades}
          onChange={form.handleChange}
          label="Escolaridade"
          name="escolaridade"
          filter={CommonHelper.isDesktop()}
          optionLabel="descri_esc"
          dataKey="codigo_esc"
          noFloatLabel
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
          showClear={form.values.escolaridade?.codigo_esc}
        />
      </div>
      <div className="field col-12 md:col-3">
        <TextInput
          label="Inscrição municipal"
          placeholder="Inscrição municipal"
          maxLength={15}
          name="insmun_pes"
          value={form.values.insmun_pes}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-3">
        <SelectInput
          value={form.values.hobby}
          options={hobbies}
          onChange={form.handleChange}
          label="Hobby"
          name="hobby"
          filter={CommonHelper.isDesktop()}
          optionLabel="descri_hob"
          dataKey="codigo_hob"
          noFloatLabel
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
          showClear={form.values.hobby?.codigo_hob}
        />
      </div>
      <div className="field col-12 md:col-6">
        <TextInput
          label="Apelido"
          placeholder="Apelido"
          name="apelid_pes"
          maxLength={200}
          className="inputfield w-full"
          value={form.values.apelid_pes}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
        />
      </div>
      <div className="field col-12 md:col-6">
        <TextInput
          label="Pai"
          placeholder="Nome do pai"
          name="nompai_pef"
          maxLength={200}
          className="inputfield w-full"
          value={form.values.nompai_pef}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
        />
      </div>
      <div className="field col-12 md:col-6">
        <TextInput
          label="Mãe"
          placeholder="Nome da mãe"
          name="nommae_pef"
          maxLength={200}
          className="inputfield w-full"
          value={form.values.nommae_pef}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
        />
      </div>
      <div className="field col-12 md:col-6 relative">
        <SearchInput
          label="Cônjuge"
          field="nomraz_pes"
          value={form.values.conjuge?.nomraz_pes && form.values.conjuge}
          itemTemplate={PessoaAutocompleteTemplate}
          suggestions={sugestoesConjuge}
          completeMethod={sugerirConjuge}
          onChange={handleChangeConjuge}
          onSelect={(e) => form.setFieldValue('conjuge', e.value)}
          placeholder="Cônjuge"
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
        />
        <div className="label-over-cadastro">
          {formatDocument(form.values.conjuge?.cgccpf_pes)}
        </div>
      </div>
      <div className="field col-12 md:col-6">
        <TextInput
          label="E-mail financeiro"
          placeholder="E-mail financeiro"
          name="emafin_pes"
          maxLength={240}
          value={form.values.emafin_pes}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
        />
      </div>
      <div className={(CommonHelper.isDesktop() ? 'justify-content-end align-items-center' : 'flex-column') + ' col-12 flex mb-4'}>
        {!isNew && (
          <>
            <div className={CommonHelper.isDesktop() && 'mr-3'}>
              <Button
                label="Declaração de residência para emissão de..."
                type="button"
                onClick={() => setVisibleDeclaracaoModal(true)}
                className="p-button" />
            </div>
            <div className={(!CommonHelper.isDesktop() && 'mt-3 mb-3') + ' mr-3'}>
              <Button type="button" label="Como chegar" onClick={handleClickComoChegar} />
            </div>
          </>
        )}
        <div>
          <Checkbox
            inputId="parcei_pes"
            value={form.values.parcei_pes}
            onChange={handleChangeParceiro}
            checked={form.values.parcei_pes}
            disabled={!edicaoHabilitada}
          />
          <label htmlFor="parcei_pes" className="p-checkbox-label mb-0 pl-2">Parceiro</label>
        </div>
      </div>
      <Fieldset style={{ paddingTop: '1em', minWidth: '300px' }}
        className="col-12">
        <div className="col-12">
          <p className="negociacao-info">
            <b>Usuário:</b> ({form.values.usuarioInsercao?.codigo_usu}) - {form.values.usuarioAlteracao?.pessoa?.nomraz_pes}
          </p>
        </div>
        <div className="col-12">
          <p className="negociacao-info">
            <b>Empresa de cadastro:</b> ({form.values.empresa?.codigo_emp}) - {form.values.empresa?.pessoa?.nomraz_pes}
          </p>
        </div>
        <div className="col-12">
          <p className="negociacao-info">
            <b>Usuário da última alteração:</b> ({form.values.usuarioAlteracao?.codigo_usu}) - {form.values.usuarioAlteracao?.pessoa?.nomraz_pes}
          </p>
        </div>
        <div className="col-12">
          <p className="negociacao-info">
            <b>Data da última alteração:</b> {formatDateTime(form.values.dahalt_pes)}
          </p>
        </div>
        <div className="col-12">
          <p className="negociacao-info">
            <b>Data do cadastro:</b> {formatDate(form.values.datins_pes)}
          </p>
        </div>
      </Fieldset>
      {showDeclaracaoResidenciaModal(visibleDeclaracaoModal)}
    </div>
  )
}

export default DadosAdicionaisPef
