import { classNames } from 'primereact/utils'
import React, { useEffect, useState } from 'react'
import CheckboxInput from '../../../../../../components/inputs/CheckboxInput'
import DateInput from '../../../../../../components/inputs/DateInput'
import SelectInput from '../../../../../../components/inputs/SelectInput'
import TextAreaInput from '../../../../../../components/inputs/TextAreaInput'
import TextInput from '../../../../../../components/inputs/TextInput'
import PessoaAutocompleteTemplate from '../../../../../../components/inputs/options/PessoaAutocompleteTemplate'
import SearchInput from '../../../../../../components/inputs/searchInput/SearchInput'
import ResponsavelPagamento from '../../../../../../components/layout/ResponsavelPagamento/ResponsavelPagamento'
import FieldErrorMessage from '../../../../../../components/utils/FieldErrorMessage'
import { showErrorMessage } from '../../../../../../components/utils/Message'
import Modal from '../../../../../../components/utils/Modal'
import RequiredLabel from '../../../../../../components/utils/RequiredLabel'
import CommonHelper from '../../../../../../helpers/CommonHelper'
import {
  formatDocument,
  formatToCurrency
} from '../../../../../../helpers/formaters'
import { useValidateInput } from '../../../../../../helpers/useValidateInput'
import { getStore } from '../../../../../../redux/store'
import PessoaService from '../../../../../../services/cadastro/pessoa/PessoaService'
import FoPagNeg from '../_FoPagNeg'
import { ChequeValidator } from './ChequeValidator'

const FoPagCheque = (props) => {
  const {
    form,
    edicaoHabilitada,
    handleChangeCurrency,
    getTiposRestricaoVeiculo,
    setRestricao,
    setValorVeiculo,
    hide,
    dados,
    pessoa,
    excluir,
    isLojaPaga,
    isNew
  } = props

  const [sugestaoCedentes, setSugestaoCedentes] = useState([])
  const empresaLogada = getStore().empresaLogada
  const deveSelecionarVeiculo = props.negociacaoForm.values.veiculosConjunto.length > 0

  useEffect(() => {
    const onLoad = async () => {
      form.setFieldValue('veiculo', props.veiculo)

      if (!form.values.nomeCedente && !form.values.cpfCnpjCedente) {
        form.setFieldValue('nomeCedente', pessoa.nomraz_pes)
        form.setFieldValue('cpfCnpjCedente', pessoa.cgccpf_pes)
      } else {
        onSelectCedente({ value: { nomraz_pes: form.values.nomeCedente, cgccpf_pes: form.values.cpfCnpjCedente } })
      }

      if (isNew) {
        form.setFieldValue('isLojaPaga', isLojaPaga)
      }

      if (!form.values.nomeCedente) {
        if (isLojaPaga) {
          form.setFieldValue('nomeCedente', empresaLogada.pessoa?.nomraz_pes)
          form.setFieldValue('cpfCnpjCedente', empresaLogada.pessoa?.cgccpf_pes)
        } else {
          form.setFieldValue('nomeCedente', pessoa?.nomraz_pes)
          form.setFieldValue('cpfCnpjCedente', pessoa?.cgccpf_pes)
        }
      }

      const restricaoOptions = await getTiposRestricaoVeiculo()
      setRestricao(dados.codtrv_fpn, restricaoOptions)
    }
    onLoad()
  }, [])

  const sugerirCedente = async () => {
    try {
      const pessoas = await PessoaService.filterAutocomplete(form.values.nomeCedente)

      setSugestaoCedentes(pessoas)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um cedente!')
    }
  }

  const onSelectCedente = (e) => {
    form.setFieldValue('nomeCedente', e.value.nomraz_pes)
    form.setFieldValue('cpfCnpjCedente', e.value.cgccpf_pes)
  }

  const handleChangeDataCompensacao = async (e) => {
    await form.handleChange(e)
    await form.setFieldTouched('dataCompensacao')
    await form.validateField('dataCompensacao')
  }

  const { getFormErrorMessage, isFormFieldValid } = useValidateInput(form)

  return (
    <Modal
      header="Cheque"
      btnSalvar={edicaoHabilitada ? form.submitForm : undefined}
      btnExcluir={(!isNew && edicaoHabilitada) ? excluir : undefined}
      width="65"
      visible={true}
      modal={true}
      onHide={hide}
    >
      <div className={`formgrid grid ${CommonHelper.isDesktop() ? 'mt-3' : ''}`}>
        <div className="field col-12 flex justify-content-around mb-4">
          <ResponsavelPagamento isLojaPaga={isNew ? isLojaPaga : form.values.isLojaPaga} />
          <div>
            <CheckboxInput
              label="Sinal"
              checked={form.values.isSinal}
              disabled={!edicaoHabilitada}
              onChange={(e) => form.setFieldValue('isSinal', e.checked)}
            />
          </div>
        </div>
        <div className="field col-6 md:col-3">
          <TextInput
            name="numeroBanco"
            label="Nº do Banco"
            value={form.values.numeroBanco}
            disabled={!edicaoHabilitada}
            onChange={form.handleChange}
            keyfilter="int"
            className="inputfield w-full"
          />
        </div>
        <div className="field col-6 md:col-3">
          <TextInput
            name="numeroAgencia"
            label="Nº da Agência"
            value={form.values.numeroAgencia}
            disabled={!edicaoHabilitada}
            onChange={form.handleChange}
            keyfilter="int"
            className="inputfield w-full"
          />
        </div>
        <div className="field col-12 md:col-3">
          <TextInput
            name="numeroConta"
            label="Número da Conta"
            value={form.values.numeroConta}
            disabled={!edicaoHabilitada}
            onChange={form.handleChange}
            keyfilter="int"
            className="inputfield w-full"
          />
        </div>

        <div className="field col-12 md:col-3">
          <TextInput
            name="numeroCheque"
            label="Número do Cheque"
            value={form.values.numeroCheque}
            disabled={!edicaoHabilitada}
            onChange={form.handleChange}
            keyfilter="int"
            className="inputfield w-full"
          />
        </div>
        <div className="field col-12 md:col-6">
          <label className="label">Cedente</label>
          <SearchInput
            field="nomraz_pes"
            value={form.values.nomeCedente?.toUpperCase()}
            suggestions={sugestaoCedentes}
            completeMethod={sugerirCedente}
            itemTemplate={PessoaAutocompleteTemplate}
            onChange={(e) => {
              form.setFieldValue('nomeCedente', e.target.value)
            }}
            onSelect={onSelectCedente}
            placeholder="Nome do cedente"
            disabled={!edicaoHabilitada}
            className="inputfield w-full"
          />
        </div>
        <div className="field col-12 md:col-6">
          <RequiredLabel label="CPF/CNPJ do Cedente" valid={isFormFieldValid('cpfCnpjCedente')} />
          <TextInput
            name="cpfCnpjCedente"
            value={formatDocument(form.values.cpfCnpjCedente)}
            disabled={!edicaoHabilitada}
            onChange={form.handleChange}
            keyfilter="int"
            onBlur={() => form.setFieldTouched('cpfCnpjCedente')}
            className={classNames(
              { 'p-invalid': isFormFieldValid('cpfCnpjCedente') },
              'inputfield w-full'
            )}
          />
          <FieldErrorMessage message={getFormErrorMessage('cpfCnpjCedente')} />
        </div>
        <div className={'field col-12 md:col-' + (deveSelecionarVeiculo ? '4' : '6')}>
          <RequiredLabel label="Data de Emissão" valid={isFormFieldValid('dataEmissao')} />
          <DateInput
            name="dataEmissao"
            value={form.values.dataEmissao}
            disabled={!edicaoHabilitada}
            onChange={form.handleChange}
            onBlur={ () =>
              form.setFieldTouched('dataEmissao')
            }
            className={classNames({ 'p-invalid': isFormFieldValid('dataEmissao') }, 'inputfield w-full')}
          />
          <FieldErrorMessage message={getFormErrorMessage('dataEmissao')} />
        </div>
        {deveSelecionarVeiculo && (
          <div className="field col-12 md:col-4">
            <RequiredLabel label="Veículo" valid={isFormFieldValid('veiculo.codigo_vei')} />
            <SelectInput
              value={form.values.veiculo}
              options={props.negociacaoForm.values.veiculosConjunto.map(veiculoNve => veiculoNve.veiculo)}
              onChange={(e) => form.setFieldValue('veiculo', e.target.value)}
              name='veiculo'
              optionLabel="placha"
              dataKey="codigo_vei"
              disabled={!edicaoHabilitada}
              className={classNames({ 'p-invalid': isFormFieldValid('veiculo.codigo_vei') }, 'inputfield w-full')}
              noFloatLabel
            />
            <FieldErrorMessage message={getFormErrorMessage('veiculo.codigo_vei')} />
          </div>
        )}
        <div className={'field col-12 md:col-' + (deveSelecionarVeiculo ? '4' : '6')}>
          <RequiredLabel label="Valor" valid={isFormFieldValid('valor')} />
          <TextInput
            name="valor"
            value={formatToCurrency(form.values.valor)}
            onChange={handleChangeCurrency}
            onBlur={() => {
              setValorVeiculo()
              form.setFieldTouched('valor')
            }}
            disabled={!edicaoHabilitada}
            className={classNames(
              { 'p-invalid': isFormFieldValid('valor') },
              'inputfield w-full'
            )}
          />
          <FieldErrorMessage message={getFormErrorMessage('valor')} />
        </div>
        <div className="field col-12 md:col-6">
          <RequiredLabel label="Data de Compensação" valid={isFormFieldValid('dataCompensacao')} />
          <DateInput
            name="dataCompensacao"
            value={form.values.dataCompensacao}
            onChange={handleChangeDataCompensacao}
            disabled={!edicaoHabilitada}
            onBlur={() => form.setFieldTouched('dataCompensacao')}
            className={classNames({ 'p-invalid': isFormFieldValid('dataCompensacao') }, 'inputfield w-full')}
          />
          <FieldErrorMessage message={getFormErrorMessage('dataCompensacao')} />
        </div>
        <div className="field col-12 md:col-6">
          <SelectInput
            value={form.values.restricao}
            options={form.values.restricaoOptions}
            optionLabel="descri_trv"
            onChange={form.handleChange}
            placeholder="- Selecione -"
            name="restricao"
            label="Restrição"
            noFloatLabel
            disabled={!edicaoHabilitada}
            className="inputfield w-full"
            showClear={form.values.restricao?.codigo_trv}
          />
        </div>
        <div className="field col-12">
          <TextAreaInput
            name="observacoes"
            label="Observações"
            value={form.values.observacoes}
            disabled={!edicaoHabilitada}
            onChange={form.handleChange}
            className="inputfield w-full"
          />
        </div>
      </div>
    </Modal>
  )
}

export default (props) =>
  FoPagNeg({
    Component: FoPagCheque,
    validationSchema: ChequeValidator,
    ...props
  })
