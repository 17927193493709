import moment from 'moment'
import CommonHelper from './CommonHelper'
import { clearDigitsSpecialChars } from './parsers'

export function formatUpperCase (value) {
  if (!value) {
    return ''
  }

  return String(value).toUpperCase()
}

export function formatLowerCase (value) {
  if (!value) {
    return ''
  }

  return String(value).toLowerCase()
}

export function formatCpf (value) {
  let rawValue = clearDigitsSpecialChars(value)

  let newValue = rawValue.substr(0, 3)

  if (rawValue.length > 3) {
    newValue += '.' + rawValue.substr(3, 3)
  }

  if (rawValue.length > 6) {
    newValue += '.' + rawValue.substr(6, 3)
  }

  if (rawValue.length > 9) {
    newValue += '-' + rawValue.substr(9, 2)
  }

  return newValue
}

export function formatCnpj (value) {
  let rawValue = clearDigitsSpecialChars(value)

  let newValue = rawValue.substr(0, 2)

  if (rawValue.length > 2) {
    newValue += '.' + rawValue.substr(2, 3)
  }

  if (rawValue.length > 5) {
    newValue += '.' + rawValue.substr(5, 3)
  }

  if (rawValue.length > 8) {
    newValue += '/' + rawValue.substr(8, 4)
  }

  if (rawValue.length > 12) {
    newValue += '-' + rawValue.substr(12, 2)
  }

  return newValue
}

export function formatDocument (value) {
  if (!value) return ''

  value = clearDigitsSpecialChars(value)

  if (value.length < 12) {
    return formatCpf(value)
  } else {
    return formatCnpj(value)
  }
}

export function firstChar (value) {
  if (value)
    return value[0]

  return ''
}

export function formatPhone (value) {
  let rawValue = clearDigitsSpecialChars(value)

  let front = 4
  if (rawValue.length > 10) {
    front = 5
  }

  let newValue = ''
  if (rawValue.length > 0) {
    newValue += '(' + rawValue.substr(0, 2)
  }

  if (rawValue.length > 2) {
    newValue += ') ' + rawValue.substr(2, front)
  }

  if (rawValue.length >= 7) {
    newValue += '-' + rawValue.substr(front + 2, 4)
  }

  return newValue
}

export function formatCep (value) {
  let rawValue = clearDigitsSpecialChars(value)

  let newValue = rawValue.substr(0, 2)

  if (rawValue.length > 2) {
    newValue += '.' + rawValue.substr(2, 3)
  }

  if (rawValue.length > 5) {
    newValue += '-' + rawValue.substr(5, 3)
  }

  return newValue
}

export function formatToCurrency (value, ignoreCents = false) {
  if (value === null || value === undefined) {
    value = 0
  }

  let number = Number(value)

  if (ignoreCents) {
    number = parseInt(number)
    return number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 0 })
  }

  return number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
}

export function formatCurrencyToNumber (value) {
  let rawValue = clearDigitsSpecialChars(value)
  let number = Number(rawValue) * 0.01
  return Number(number.toFixed(2))
}

export function formatDecimalToNumber (value, fractionDigits = 2) {
  let rawValue = clearDigitsSpecialChars(value)
  let number = Number(rawValue) * (1 / (Math.pow(10, fractionDigits)))
  return Number(number.toFixed(fractionDigits))
}

export function formatToPercentage (value) {
  if (value === null || value === undefined) {
    value = 0
  }

  let number = Number(value) / 100

  return number.toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'percent' })
}

export function formatPercentageToNumber (value) {
  let rawValue = clearDigitsSpecialChars(value)
  let number = Number(rawValue) * 0.01

  return Number(number.toFixed(2))
}

export function formatDecimal (value, fractionDigits = 2) {
  return value.toLocaleString('pt-BR', { minimumFractionDigits: fractionDigits })
}

export function roundNumber (value, casasDecimais = 0) {
  const roundedValue = Math.round(value)
  return roundedValue.toLocaleString('pt-BR', { maximumFractionDigits: casasDecimais, minimumFractionDigits: casasDecimais })
}

/**
   * Objetivo: Arredondar um número conforme as casas decimais, e retornar um número
   * Parâmetros: <valor> Valor que será arredondado, <casasDecimais> Número de casas decimais
   * @param valor Valor numérico
   * @param casasDecimais Número de casas decimais que será arredondado
   */
export function Round (valor, casasDecimais) {
  const fatorMultiplicador = Math.pow(10, casasDecimais)
  return Math.round(Number(valor) * fatorMultiplicador) / fatorMultiplicador
}

export function formatToInteger (value) {
  return Number(CommonHelper.clearDigitsSpecialChars(value))
}

export function formatNumberToTwoFractionDigits (value) {
  if (value === null || value === undefined) {
    value = 0.00
  }

  return value.toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) || 0.00
}

export function formatArea (value, suffix) {
  if (!suffix) {
    suffix = ''
  }

  let rawValue = formatDecimal(value)
  return rawValue + suffix
}

export function formatDate (date) {
  return moment(date).format('DD/MM/YYYY')
}

export function formatTimestampToDate (timestamp) {
  let date = new Date(timestamp * 1000)
  return date.toLocaleDateString()
}

export function formatDateTime (date, seconds = false) {
  return moment(date).format(`DD/MM/YYYY - HH:mm${seconds ? ':ss' : ''}`)
}

export function formatDateTimeToAmerican (date, seconds = true) {
  return moment(date).format(`YYYY-MM-DD HH:mm${seconds ? ':ss' : ''}`)
}

export function formatDateToAmerican (date) {
  return moment(date).format('YYYY-MM-DD')
}

export function formatDateToInputDate (date) {
  formatedDate = new Date(`${date} 00:00`)

  if (CommonHelper.isValidDate(formatedDate)) {
    return formatedDate
  }

  let formatedDate = new Date(date)

  if (CommonHelper.isValidDate(formatedDate)) {
    return formatedDate
  }

  return null
}

export function formatPlaca (value) {
  if (value?.replace) {
    return value.replace(/([A-Z]{3})([0-9][0-9A-Z][0-9]{2})/, '$1-$2')
  } else {
    return
  }
}

export function formatPlacha (placa, chassi) {
  if (placa) {
    return placa.replace(/([A-Z]{3})([0-9][0-9A-Z][0-9]{2})/, '$1-$2')
  } else {
    return chassi
  }
}
