import ServiceBase from '../../base/ServiceBase'

export default class PaisesService {
  static async getAll () {
    return await ServiceBase.getBase('countries')
  }

  static async getCountryByState (stateId) {
    return await ServiceBase.getBase(`pais/byEstado/${stateId}`)
  }

  static async getPaisByCodigo (codigo) {
    return await ServiceBase.getBase(`pais/${codigo}`)
  }
}
