import React, { useEffect, useState } from 'react'
import { formatPlacha, formatToCurrency } from '../../../../../helpers/formaters'
import AppButton from '../../../../../components/layout/AppButton'
import FormasPagamentoModal from '../FormasPagamentoModal'
import FormaPagamentosService from '../../../../../services/vendas/negociacao/FormaPagamentosService'
import DataListForm from '../../../../../components/layout/DataListForm/DataListForm'
import ConfigService from '../../../../../services/configuracao/ConfigService'
import CommonHelper from '../../../../../helpers/CommonHelper'
import { showErrorMessage } from '../../../../../components/utils/Message'

const PagamentoNeg = ({ form, edicaoHabilitada, operacaoNeg, totalValorDocumentos }) => {
  function isVenda () {
    return operacaoNeg === 'Venda'
  }

  // Documentos da negociação devem ser liquidados na aba Forma de pagamento?
  const [config1419, setConfig1419] = useState(true)
  // Acessórios e serviços da negociação devem ser liquidados na aba Forma de pagamento?
  // const [config1423, setConfig1423] = useState(true)
  const [labelSaldoRestante, setLabelSaldoRestante] = useState(isVenda() ? 'Saldo para o cliente pagar:' : 'Saldo para a loja pagar:')
  const [labelValorTotalNeg, setLabelValorTotalNeg] = useState(isVenda() ? 'Total para o cliente pagar:' : 'Total para a loja pagar:')
  const [isLojaPaga, setIsLojaPaga] = useState(isVenda() ? true : false)
  const [totalValorPagamentos, setTotalValorPagamentos] = useState(0)
  const [selectedPagamento, setSelectedPagamento] = useState({})
  const [visibleFoPagNegModal, setVisibleFoPagNegModal] = useState(false)
  const [formasPagamento, setFormasPagamento] = useState([])
  const [totalSaldoRestante, setTotalSaldoRestante] = useState(0)
  const [valorTotalNeg, setValorTotalNeg] = useState(0)

  useEffect(async () => {
    await ConfigService.getValor(1419).then(config1419 => setConfig1419(config1419))

    await getFormaPagamentos()
  }, [])

  useEffect(() => {
    let valorTotalNeg = calculaValorTotalNeg()
    setValorTotalNeg(valorTotalNeg)
    atualizaTotalPagamentos(valorTotalNeg)
  }, [config1419])

  async function getFormaPagamentos () {
    try {
      const formasPagamento = await FormaPagamentosService.filterTiposPagamentos()
      setFormasPagamento(formasPagamento)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar os tipos de pagamento!')
    }
  }

  function calculaValorVeiculosRestantes (vendaOuCompra) {
    let valorVeiculosRestantes = 0

    if (vendaOuCompra === 'Compra') {
      form.values.veiculosRestantesCompra.forEach(veiculoNve => {
        valorVeiculosRestantes += veiculoNve.valor_nve
      })
    } else {
      form.values.veiculosRestantesVenda.forEach(veiculoNve => {
        valorVeiculosRestantes += veiculoNve.valor_nve
      })
    }

    return valorVeiculosRestantes
  }

  function calculaValorTotalNeg () {
    const { negociacaoVeiculo, veiculosRestantesCompra, veiculosRestantesVenda, veiculosConjunto, valorAcessoriosNeg, valorServicosNeg } = form.values

    let valorTotalNeg = negociacaoVeiculo.valor_nve || 0

    if (veiculosConjunto.length > 0) {
      valorTotalNeg = veiculosConjunto.map(veiculo => veiculo.valor_nve).reduce((previousValue, currentValue) => previousValue + currentValue)
    }

    valorTotalNeg += config1419 ? (isVenda() ? totalValorDocumentos : -totalValorDocumentos) : 0
    valorTotalNeg += (veiculosRestantesCompra ? calculaValorVeiculosRestantes('Compra') : 0) * (isVenda() ? (-1) : (1))
    valorTotalNeg += (veiculosRestantesVenda ? calculaValorVeiculosRestantes('Venda') : 0) * (!isVenda() ? (-1) : (1))
    valorTotalNeg += valorAcessoriosNeg?.totalCliente || 0
    valorTotalNeg += valorServicosNeg?.totalCliente || 0

    return Number(valorTotalNeg).toFixed(2)
  }

  function atualizaTotalPagamentos (valorTotalNegociacao = valorTotalNeg) {
    let valorTotalPagamentos = 0.00
    form.values.foPagNegs.forEach(foPagNeg => {
      const valorPagamento = foPagNeg.valor_fpn
      const isLojaPaga = foPagNeg.revpag_fpn

      if ((!isLojaPaga && isVenda()) || (isLojaPaga && !isVenda())) {
        valorTotalPagamentos += valorPagamento
      } else if ((isLojaPaga && isVenda()) || (!isLojaPaga && !isVenda())) {
        valorTotalPagamentos -= valorPagamento
      }
    })

    const saldoRestante = valorTotalNegociacao - valorTotalPagamentos
    setTotalValorPagamentos(valorTotalPagamentos)
    setTotalSaldoRestante(saldoRestante)

    if (valorTotalNegociacao < 0)
      setLabelValorTotalNeg(isVenda() ? 'Total para o loja pagar:' : 'Total para o cliente pagar:')

    if (valorTotalNegociacao > 0)
      setLabelValorTotalNeg(isVenda() ? 'Total para o cliente pagar:' : 'Total para a loja pagar:')

    if (saldoRestante < 0)
      setLabelSaldoRestante(isVenda() ? 'Saldo para a loja pagar:' : 'Saldo para o cliente pagar:')

    if (saldoRestante > 0)
      setLabelSaldoRestante(isVenda() ? 'Saldo para o cliente pagar:' : 'Saldo para a loja pagar:')

    if (saldoRestante === 0)
      setLabelSaldoRestante('Saldo zero')
  }

  function handleSelectRow (selected) {
    setSelectedPagamento(selected)
  }

  function getFormasPagamentoNegociacao () {
    return form.values.foPagNegs.map((foPagNeg) => {
      foPagNeg.descricaoFormaPagamento = foPagNeg.tipoFormaPagamento.descri_tfp

      if (foPagNeg.tipoFormaPagamento.codigo_tfp === 15) {
        foPagNeg.descricaoFormaPagamento = `Veículo na troca (${formatPlacha(foPagNeg.veiculoTroca.placa_vei, foPagNeg.veiculoTroca.chassi_vei)})`
      }

      return foPagNeg
    })
  }

  return (
    <>
      <div className="formgrid grid fluid justify-content-center">
        <div className="neg-pagamento-valores">
          {form.values.veiculosConjunto.length > 0 && (
            <div className="flex align-items-center">
              <label>Veículos: {formatToCurrency(form.values.veiculosConjunto
                .map(veiculo => veiculo.valor_nve)
                .reduce((previousValue, currentValue) => previousValue + currentValue) || 0)}</label>
              <span className="neg-pagamento-soma ml-1">+</span>
            </div>
          )}
          {(!form.values.veiculosRestantesCompra && !(form.values.veiculosConjunto.length > 0)) && (
            <div className="flex align-items-center">
              <label>Veículo: {formatToCurrency(form.values.negociacaoVeiculo?.valor_nve || 0)}</label>
              <span className="neg-pagamento-soma ml-1">+</span>
            </div>
          )}
          {form.values.veiculosRestantesCompra && (
            <>
              <div className="flex align-items-center">
                <label>
                  {isVenda() ? 'Veículos vendidos:' : 'veículos comprados'}{' '}
                  {formatToCurrency(form.values.negociacaoVeiculo?.valor_nve + (calculaValorVeiculosRestantes(isVenda() ? 'Venda' : 'Compra')))}
                </label>
                <span className="neg-pagamento-soma ml-1">+</span>
              </div>
              <div className="flex align-items-center">
                <label>
                  {isVenda() ? 'Veículos comprados:' : 'Veículos vendidos:'}{' '}
                  {formatToCurrency(calculaValorVeiculosRestantes(isVenda() ? 'Compra' : 'Venda'))}
                </label>
                <span className="neg-pagamento-subtrai ml-1">-</span>
              </div>
            </>
          )}
          <div className="flex align-items-center">
            <label>Documentos: {formatToCurrency(Math.abs(totalValorDocumentos))}</label>
            <span className={'ml-1 neg-pagamento-' + (isVenda() ? 'soma' : 'subtrai')}>{isVenda() ? '+' : '-'}</span>
          </div>
          {(form.values.valorAcessoriosNeg?.totalCliente > 0 || form.values.valorServicosNeg?.totalCliente > 0) && (
            <div className="flex align-items-center">
              <label>Acessórios e Serviços: {formatToCurrency(form.values.valorAcessoriosNeg.totalCliente + form.values.valorServicosNeg.totalCliente)}</label>
              <span className="neg-pagamento-soma ml-1">+</span>
            </div>
          )}
          <div>
            <label className="valor-total">{labelValorTotalNeg} {formatToCurrency(Math.abs(valorTotalNeg))}</label>
          </div>
        </div>
      </div>
      <div className="field col-12 md:col-12 grid justify-content-center no-margin">
        <div className={'field col-12 md:col-8 grid justify-content-' + (CommonHelper.isDesktop() ? 'between' : 'center')}>
          <AppButton
            type="button"
            className="neg-pagamento-button"
            label="Pagamento da loja"
            icon="pi pi-plus"
            onClick={() => {
              setIsLojaPaga(true)
              setVisibleFoPagNegModal(true)
            }}
            disabled={!edicaoHabilitada}
          />
          <AppButton
            type="button"
            className="neg-pagamento-button"
            label="Pagamento do cliente"
            icon="pi pi-plus"
            onClick={() => {
              setIsLojaPaga(false)
              setVisibleFoPagNegModal(true)
            }}
            disabled={!edicaoHabilitada}
          />
        </div>
      </div>
      <div className="field col-12 md:col-12 grid justify-content-center no-margin no-padding">
        <div className={'field col-12 md:col-' + (CommonHelper.isDesktop() ? '8' : '12 no-padding')}>
          <DataListForm
            value={getFormasPagamentoNegociacao()}
            emptyMessage="Nenhum pagamento informado."
            onRowSelect={(e) => handleSelectRow(e.data)}
            columns={[
              {
                header: 'Pagamento',
                field: 'descricaoFormaPagamento',
                className: 'text-left pl-4'
              },
              {
                header: '',
                field: 'revpag_fpn',
                className: 'text-right',
                ifHas: {
                  field: 'revpag_fpn',
                  value: true,
                  body: 'Pago pela loja',
                  className: 'neg-pagamento isLoja',
                  else: false,
                  elseBody: 'Pago pelo cliente',
                  elseClassName: 'neg-pagamento isCliente'
                }
              },
              {
                header: 'Valor',
                field: 'valor_fpn',
                className: 'text-right pr-4',
                format: formatToCurrency
              }
            ]}
            footer={
              <>
                <div className="flex justify-content-between neg-pagamento-footer">
                  <span className="pl-2">Total:</span>
                  <span className={(CommonHelper.isDesktop() ? 'pr' : 'pl') + '-2'}>{formatToCurrency(Math.abs(totalValorPagamentos))}</span>
                </div>
                <div className="flex justify-content-between pt-2 neg-pagamento-footer">
                  <span className="pl-2">{labelSaldoRestante}</span>
                  <span className={(CommonHelper.isDesktop() ? 'pr' : 'pl') + '-2'}>{formatToCurrency(Math.abs(totalSaldoRestante))}</span>
                </div>
              </>
            }
          />
        </div>
      </div>
      <FormasPagamentoModal
        visible={visibleFoPagNegModal}
        onHide={() => setVisibleFoPagNegModal(false)}
        negociacaoForm={form}
        formasPagamento={formasPagamento}
        edicaoHabilitada={edicaoHabilitada}
        atualizaTotalPagamentos={atualizaTotalPagamentos}
        selectedPagamento={selectedPagamento}
        setSelectedPagamento={setSelectedPagamento}
        isLojaPaga={isLojaPaga}
        isVenda={isVenda}
        saldoRestante={totalSaldoRestante}
      />
    </>
  )
}

export default PagamentoNeg
