import React from 'react'
import DadosPrincipaisPef from '../pessoaFisica/formPages/DadosPrincipaisPef'
import ComentariosPes from '../ComentariosPes'
import DadosProfissionaisPef from '../pessoaFisica/formPages/DadosProfissionaisPef'
import DadosEnderecoPes from '../DadosEnderecoPes'
import DadosAdicionaisPef from '../pessoaFisica/formPages/DadosAdicionaisPef'
import DadosUsuarioUsu from './formPages/DadosUsuarioUsu'
import TabMenuContainer from '../../../../components/layout/TabMenuContainer/TabMenuContainer'
import TabMenuPage from '../../../../components/layout/TabMenuContainer/TabMenuPage'

const UsuarioForm = ({ form, edicaoHabilitada, camposObrigatorios, isNew }) => {
  return (
    <TabMenuContainer>
      <TabMenuPage header="INFORMAÇÕES PRINCIPAIS">
        <DadosPrincipaisPef
          form={form}
          edicaoHabilitada={edicaoHabilitada}
          camposObrigatorios={camposObrigatorios}
        />
      </TabMenuPage>
      <TabMenuPage header="USUÁRIO">
        <DadosUsuarioUsu
          form={form}
          edicaoHabilitada={edicaoHabilitada}
          camposObrigatorios={camposObrigatorios}
          isNew={isNew}
        />
      </TabMenuPage>
      {!isNew && (
        <TabMenuPage header="INFORMAÇÕES ADICIONAIS">
          <DadosAdicionaisPef
            form={form}
            edicaoHabilitada={edicaoHabilitada}
          />
        </TabMenuPage>
      )}
      <TabMenuPage header="DADOS PROFISSIONAIS">
        <DadosProfissionaisPef
          form={form}
          edicaoHabilitada={edicaoHabilitada}
        />
      </TabMenuPage>
      <TabMenuPage header="ENDEREÇOS">
        <DadosEnderecoPes
          form={form}
          edicaoHabilitada={edicaoHabilitada}
        />
      </TabMenuPage>
      <TabMenuPage header="COMENTÁRIOS">
        <ComentariosPes
          form={form}
          tabela="usuario"
          codigo={form.values.codigo_usu}
          edicaoHabilitada={edicaoHabilitada}
        />
      </TabMenuPage>
    </TabMenuContainer>
  )
}

export default UsuarioForm
