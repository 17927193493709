import React, { Component } from 'react'
import Modal from './Modal'
import AppButton from '../layout/AppButton'

class Confirm extends Component {
  render () {
    return (
      <Modal
        header={this.props.title}
        visible={this.props.visible}
        width="40"
        modal={true}
        onHide={this.props.onCancel}
      >
        {this.props.description}
        <div className="p-dialog-buttons">
          {this.props.onCancel && (<AppButton label="Não" icon="pi pi-times" className="p-button-danger mr-2" onClick={this.props.onCancel} />)}
          <AppButton label="Sim" icon="pi pi-check" onClick={this.props.onConfirm} />
        </div>
      </Modal>
    )
  }
}

export default Confirm
