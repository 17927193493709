import React from 'react'
import List from '../../../../classes/List'
import DataList from '../../../../components/layout/DataList'
import Modal from '../../../../components/utils/Modal'
import { getProdutoDTO } from '../../../../dtos/cadastro/produto/ProdutoDTO'
import { formatToCurrency } from '../../../../helpers/formaters'
import { createColumns, getColunasHabilitadas } from '../../../../helpers/tableConfigs'
import ProdutoService from '../../../../services/cadastro/produto/ProdutoService'
import ConfigService from '../../../../services/configuracao/ConfigService'

class DadosProdutoDasOutrasEmpresas extends List {
  constructor (props) {
    super(
      props,
      getProdutoDTO,
      ProdutoService,
      '',
      '',
      20,
      ''
    )

    this.state = {
      ...this.state,
      nomePrecoPersonalizado1: 'Personalizado 1',
      nomePrecoPersonalizado2: 'Personalizado 2',
      nomePrecoPersonalizado3: 'Personalizado 3'
    }

    this.createDataListColumns()
  }

  createDataListColumns () {
    this.setColumns(createColumns([
      { field: 'nomeEmpresa', header: 'Empresa', width: '500px' },
      { field: 'estatu_pre', header: 'Estoque', width: '150px' },
      { field: 'qtdapl_pre', header: 'Aplicadas', width: '150px' },
      { field: 'qtdres_pre', header: 'Reservadas', width: '150px' },
      { field: 'qtdtra_pre', header: 'Em trânsito', width: '150px' },
      { field: 'qtddisponivel', header: 'Disponível', width: '150px' },
      { field: 'preco_pre', header: 'Preço varejo', customBody: (data) => formatToCurrency(data.preco_pre), width: '150px' },
      { field: 'prepro_ppr', header: 'Preço promocional', customBody: (data) => formatToCurrency(data.prepro_ppr), width: '150px' },
      { field: 'preata_pre', header: 'Atacado', customBody: (data) => formatToCurrency(data.preata_pre), width: '150px' },
      { field: 'preeco_pre', header: 'E-commerce', customBody: (data) => formatToCurrency(data.preeco_pre), width: '150px' },
      { field: 'preper1_pre', header: this.state.nomePrecoPersonalizado1, customBody: (data) => formatToCurrency(data.preper1_pre), width: '200px' },
      { field: 'preper2_pre', header: this.state.nomePrecoPersonalizado2, customBody: (data) => formatToCurrency(data.preper2_pre), width: '200px' },
      { field: 'preper3_pre', header: this.state.nomePrecoPersonalizado3, customBody: (data) => formatToCurrency(data.preper3_pre), width: '200px' }
    ]))
  }

  async componentDidMount () {
    const nomePrecoPersonalizado1 = await ConfigService.getValor(3091) || 'Personalizado 1'
    const nomePrecoPersonalizado2 = await ConfigService.getValor(3092) || 'Personalizado 2'
    const nomePrecoPersonalizado3 = await ConfigService.getValor(3093) || 'Personalizado 3'

    this.setState({ nomePrecoPersonalizado1, nomePrecoPersonalizado2, nomePrecoPersonalizado3 }, this.createDataListColumns)

    this.addEventListeners()
    this.resetDescricaoFiltro()
  }

  async componentDidUpdate (prevProps) {
    if (this.props.produto?.codigo_pro && prevProps.produto?.codigo_pro !== this.props.produto.codigo_pro) {
      const filter = { buscarDeTodasAsEmpresas: true, codigo_pro: this.props.produto.codigo_pro, agruparPorEmpresa: true }
      this.setState({ filter }, this.onFilter)
    }
  }

  render () {
    const props = this.props
    const state = this.state

    return (
      <Modal
        visible={props.visible}
        onHide={props.onHide}
        width={70}
        header={'Informações das outras empresas do produto número: ' + props.produto?.codigo_pro}
      >
        <DataList
          data={state.list}
          rows={state.rows}
          totalRecords={state.totalRecords}
          first={state.first}
          onPage={this.onPageChange}
          responsive={true}
          page={state.page}
          scrollable={true}
          scrollDirection="horizontal"
        >
          {getColunasHabilitadas(this.columns)}
        </DataList>
      </Modal>
    )
  }
}

export default DadosProdutoDasOutrasEmpresas
