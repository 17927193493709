import * as queryString from 'query-string'
import { formatDateToAmerican } from '../../../helpers/formaters'
import CommonHelper from '../../../helpers/CommonHelper'
import { PessoaModel } from './PessoaModel'
import moment from 'moment'

export class PessoaFisicaModel extends PessoaModel {
  constructor (params = {}) {
    super({
      codigo_pes: params.pessoa?.codigo_pes || null,
      nomraz_pes: params.pessoa?.nomraz_pes || params.nomraz_pes || null,
      apelid_pes: params.pessoa?.apelid_pes || params.apelid_pes || null,
      end_pes: params.pessoa?.end_pes || params.end_pes || null,
      bairro_pes: params.pessoa?.bairro_pes || params.bairro_pes || null,
      cidade_pes: params.pessoa?.cidade_pes || params.cidade_pes || null,
      uf_pes: params.pessoa?.uf_pes || params.uf_pes || null,
      pais_pes: params.pessoa?.pais_pes || params.pais_pes || null,
      cep_pes: params.pessoa?.cep_pes || params.cep_pes || null,
      email_pes: params.pessoa?.email_pes || params.email_pes || null,
      emafin_pes: params.pessoa?.emafin_pes || params.emafin_pes || null,
      numend_pes: params.pessoa?.numend_pes || params.numend_pes || null,
      codemp_pes: params.pessoa?.codemp_pes || params.codemp_pes || null,
      cgccpf_pes: params.pessoa?.cgccpf_pes || params.cgccpf_pes || null,
      ierg_pes: params.pessoa?.ierg_pes || params.ierg_pes || null,
      ie_pes: params.pessoa?.ie_pes || params.ie_pes || null,
      rg_pes: params.pessoa?.rg_pes || params.rg_pes || null,
      telcel_pes: params.pessoa?.telcel_pes || params.telcel_pes || null,
      telcom_pes: params.pessoa?.telcom_pes || params.telcom_pes || null,
      telres_pes: params.pessoa?.telres_pes || params.telres_pes || null,
      insmun_pes: params.pessoa?.insmun_pes || params.insmun_pes || null,
      comend_pes: params.pessoa?.comend_pes || params.comend_pes || null,
      codmun_pes: params.pessoa?.codmun_pes || params.codmun_pes || null,
      codpai_pes: params.pessoa?.codpai_pes || params.codpai_pes || null,
      usuins_pes: params.pessoa?.usuins_pes || params.usuins_pes || null,
      refban_pes: params.pessoa?.refban_pes || params.refban_pes || null,
      datins_pes: params.pessoa?.datins_pes || params.datins_pes || new Date(),
      dahalt_pes: params.pessoa?.dahalt_pes || params.dahalt_pes || null,
      numcom_pes: params.pessoa?.numcom_pes || params.numcom_pes || null,
      cepcom_pes: params.pessoa?.cepcom_pes || params.cepcom_pes || null,
      endcom_pes: params.pessoa?.endcom_pes || params.endcom_pes || null,
      comcom_pes: params.pessoa?.comcom_pes || params.comcom_pes || null,
      muncom_pes: params.pessoa?.muncom_pes || params.muncom_pes || null,
      barcom_pes: params.pessoa?.barcom_pes || params.barcom_pes || null,
      numcob_pes: params.pessoa?.numcob_pes || params.numcob_pes || null,
      cepcob_pes: params.pessoa?.cepcob_pes || params.cepcob_pes || null,
      endcob_pes: params.pessoa?.endcob_pes || params.endcob_pes || null,
      comcob_pes: params.pessoa?.comcob_pes || params.comcob_pes || null,
      muncob_pes: params.pessoa?.muncob_pes || params.muncob_pes || null,
      barcob_pes: params.pessoa?.barcob_pes || params.barcob_pes || null,
      cliata_pes: params.pessoa?.cliata_pes || params.cliata_pes || false,
      parcei_pes: params.pessoa?.parcei_pes || params.parcei_pes || false,
      municipioComercial: params.pessoa?.municipioComercial || params.municipioComercial || {},
      municipioCobranca: params.pessoa?.municipioCobranca || params.municipioCobranca || {},
      pais: params.pessoa?.pais || params.pais || {},
      estado: params.pessoa?.estado || params.estado || {},
      municipio: params.pessoa?.municipio || params.municipio || {},
      empresa: params.pessoa?.empresa || {},
      usuarioInsercao: params.pessoa?.usuarioInsercao || {},
      usuarioAlteracao: params.pessoa?.usuarioAlteracao || {},
      modalidadeVenda: params.pessoa?.modalidadeVenda || params.modalidadeVenda || {},
      limcre_pes: params.pessoa?.limcre_pes || params.limcre_pes || null,
      idrad_pes: params.pessoa?.idrad_pes || params.idrad_pes || null,
      classificacao: params.pessoa?.classificacao || params.classificacao || {},
      grupo: params.pessoa?.grupo || params.grupo || {},
      categoria: params.pessoa?.categoria || params.categoria || {},
      tabelaDesconto: params.pessoa?.tabelaDesconto || params.tabelaDesconto || {},
      autorizacaoContatos: params.pessoa?.autorizacaoContatos || params.autorizacaoContatos || []
    })

    this.codigo_pef = params.codigo_pef || null
    this.codcon_pef = params.codcon_pef || null
    this.datnas_pef = params.datnas_pef ? new Date(moment(params.datnas_pef).local()) : null
    this.estciv_pef = params.estciv_pef || null
    this.nacion_pef = params.codigo_pef ? params.nacion_pef : 'Brasileiro'
    this.natura_pef = params.natura_pef || null
    this.sex_pef = params.sex_pef || null
    this.obs_pef = params.obs_pef || null
    this.nompai_pef = params.nompai_pef || null
    this.nommae_pef = params.nommae_pef || null
    this.pejtra_pef = params.pejtra_pef || null
    this.nomtra_pef = params.nomtra_pef || null
    this.endtra_pef = params.endtra_pef || null
    this.numtra_pef = params.numtra_pef || null
    this.baitra_pef = params.baitra_pef || null
    this.ceptra_pef = params.ceptra_pef || null
    this.muntra_pef = params.muntra_pef || null
    this.teltra_pef = params.teltra_pef || null
    this.codpro_pef = params.codpro_pef || null
    this.temser_pef = params.temser_pef || null
    this.refpes_pef = params.refpes_pef || null
    this.temres_pef = params.temres_pef || null
    this.orgemi_pef = params.orgemi_pef || null
    this.datemi_pef = params.datemi_pef ? new Date(moment(params.datemi_pef).local()) : null
    this.cnh_pef = params.cnh_pef || null
    this.nuctps_pef = params.nuctps_pef || null
    this.sectps_pef = params.sectps_pef || null
    this.numpis_pef = params.numpis_pef || null
    this.renmes_pef = params.renmes_pef || null
    this.codesc_pef = params.codesc_pef || null
    this.codhob_pef = params.codhob_pef || null
    this.naturalidade = params.naturalidade || {}
    this.profissao = params.profissao || {}
    this.escolaridade = params.escolaridade || {}
    this.hobby = params.hobby || {}
    this.conjuge = params.conjuge || {}
    this.empresaTrabalho = params.empresaTrabalho?.codigo_pej
      ? {
        codigo_pej: params.empresaTrabalho.codigo_pej,
        nomraz_pes: params.empresaTrabalho?.pessoa?.nomraz_pes
      } : (params.empresa || {})
    this.usuarioInsercao = params.pessoa?.usuarioInsercao || {}
    this.usuarioAlteracao = params.pessoa?.usuarioAlteracao || {}
    this.cliente = params.cliente || {}
    this.municipioTrabalho = params.municipioTrabalho || {}
    this.diapag_cli = params.diapag_cli || params.cliente?.payment_day || null
  }

  toMap () {
    return {
      codigo_pes: this.codigo_pes || null,
      nomraz_pes: this.nomraz_pes || null,
      apelid_pes: this.apelid_pes || null,
      end_pes: this.end_pes || null,
      bairro_pes: this.bairro_pes || null,
      cidade_pes: this.cidade_pes || null,
      uf_pes: this.uf_pes || null,
      pais_pes: this.pais_pes || null,
      cep_pes: CommonHelper.clearDigitsSpecialChars(this.cep_pes) || null,
      email_pes: this.email_pes ? this.email_pes.toLowerCase() : null,
      emafin_pes: this.emafin_pes ? this.emafin_pes.toLowerCase() : null,
      numend_pes: this.numend_pes || null,
      codemp_pes: this.codemp_pes || null,
      cgccpf_pes: CommonHelper.clearDigitsSpecialChars(this.cgccpf_pes) || null,
      ierg_pes: CommonHelper.clearDigitsSpecialChars(this.ierg_pes) || null,
      ie_pes: this.ie_pes || '',
      rg_pes: this.rg_pes || null,
      insmun_pes: this.insmun_pes || null,
      telcel_pes: CommonHelper.clearDigitsSpecialChars(this.telcel_pes) || null,
      telcom_pes: CommonHelper.clearDigitsSpecialChars(this.telcom_pes) || null,
      telres_pes: CommonHelper.clearDigitsSpecialChars(this.telres_pes) || null,
      idrad_pes: CommonHelper.clearDigitsSpecialChars(this.idrad_pes) || null,
      codmov_pes: this.modalidadeVenda.codigo_mov || null,
      comend_pes: this.comend_pes || null,
      codmun_pes: this.codmun_pes || null,
      codpai_pes: this.codpai_pes || null,
      usuins_pes: this.usuins_pes || null,
      refban_pes: this.refban_pes || null,
      datins_pes: formatDateToAmerican(this.datins_pes || new Date()),
      numcom_pes: this.numcom_pes || null,
      cepcom_pes: this.cepcom_pes || null,
      endcom_pes: this.endcom_pes || null,
      comcom_pes: this.comcom_pes || null,
      muncom_pes: this.municipioComercial.id || null,
      barcom_pes: this.barcom_pes || null,
      numcob_pes: this.numcob_pes || null,
      cepcob_pes: this.cepcob_pes || null,
      endcob_pes: this.endcob_pes || null,
      comcob_pes: this.comcob_pes || null,
      muncob_pes: this.municipioCobranca.id || null,
      barcob_pes: this.barcob_pes || null,
      cliata_pes: this.cliata_pes || false,
      parcei_pes: this.parcei_pes || false,
      codgup_pes: this.grupo.codigo_gup || null,
      codcat_pes: this.categoria.codigo_cat || null,
      codcap_pes: this.classificacao.codigo_cap || null,
      codtad_pes: this.tabelaDesconto.codigo_tad || null,
      codigo_pef: this.codigo_pef || null,
      codcon_pef: this.conjuge.codigo_pef || null,
      datnas_pef: CommonHelper.dateToAmerican(this.datnas_pef) || null,
      estciv_pef: this.estciv_pef || null,
      nacion_pef: this.nacion_pef || null,
      natura_pef: this.naturalidade.id || null,
      sex_pef: this.sex_pef || null,
      obs_pef: this.obs_pef || null,
      nompai_pef: this.nompai_pef || null,
      nommae_pef: this.nommae_pef || null,
      pejtra_pef: this.empresaTrabalho.codigo_pej || null,
      nomtra_pef: this.nomtra_pef || null,
      endtra_pef: this.endtra_pef || null,
      numtra_pef: this.numtra_pef || null,
      baitra_pef: this.baitra_pef || null,
      ceptra_pef: CommonHelper.clearDigitsSpecialChars(this.ceptra_pef) || null,
      muntra_pef: this.municipioTrabalho.id || null,
      teltra_pef: CommonHelper.clearDigitsSpecialChars(this.teltra_pef) || null,
      codpro_pef: this.profissao.codigo_pro || null,
      temser_pef: this.temser_pef || null,
      refpes_pef: this.refpes_pef || null,
      temres_pef: this.temres_pef || null,
      orgemi_pef: this.orgemi_pef || null,
      datemi_pef: CommonHelper.dateToAmerican(this.datemi_pef) || null,
      cnh_pef: this.cnh_pef || null,
      nuctps_pef: this.nuctps_pef || null,
      sectps_pef: this.sectps_pef || null,
      numpis_pef: this.numpis_pef || null,
      renmes_pef: this.renmes_pef || null,
      codesc_pef: this.escolaridade.codigo_esc || null,
      codhob_pef: this.hobby.codigo_hob || null,
      diapag_cli: this.diapag_cli || null,
      autorizacaoContatos: this.autorizacaoContatos || []
    }
  }

  toFilter () {
    const validFields = Object
      .keys(this)
      .filter(it => !!this[it])
      .map(it => ({ [it]: this[it] }))

    let fields = {}

    validFields.forEach(it => {
      fields = { ...it, ...fields }
    })

    return queryString.stringify(fields)
  }

  static estadosCivis = [
    { label: 'Casado(a)', estciv_pef: 2 },
    { label: 'Desquitado(a)', estciv_pef: 3 },
    { label: 'Divorciado(a)', estciv_pef: 4 },
    { label: 'Ignorado(a)', estciv_pef: 7 },
    { label: 'Separado(a) judicialmente', estciv_pef: 6 },
    { label: 'Solteiro(a)', estciv_pef: 1 },
    { label: 'União estável', estciv_pef: 8 },
    { label: 'Viúvo(a)', estciv_pef: 5 }
  ];

  static generos = [
    { label: 'Masculino', sex_pef: 1 },
    { label: 'Feminino', sex_pef: 2 },
    { label: 'Ignorado', sex_pef: 3 }
  ];
}
