import writeXlsxFile from 'write-excel-file'

class ReportDataToXlsx {
  constructor (fileName, dataTableColumns, totalizadores) {
    this.workSheetData = []

    this.fileName = fileName
    this.totalizadores = totalizadores
    this.excelTableColumns = this.getExcelTableColumns(dataTableColumns)
    this.columnWidths = this.getColumnWidths()

    this.headerColor = '#ffffff'
    this.headerBackgroundColor = '#009EB3'
    this.headerBorderColor = '#646464'

    this.groupHeaderColor = '#ffffff'
    this.groupHeaderBackgroundColor = '#808080'
    this.groupheaderBorderColor = '#646464'

    this.valuesColor = '#0055AA'
    this.valuesBackgroundColor = '#ffffff'
    this.valuesBorderColor = '#E6E6E6'

    this.totalizadorColor = '#000000'
    this.totalizadorBackgroundColor = '#ffffff'
    this.totalizadorBorderColor = '#808080'
  }

  getExcelTableColumns (dataTableColumns) {
    return dataTableColumns.map(dataTableColumn => ({ value: dataTableColumn.props.header, propertyName: dataTableColumn.props.field }))
  }

  getColumnWidths () {
    return this.excelTableColumns.map(() => ({ width: 30 }))
  }

  addGroupToExcel (header) {
    this.insertGroupSeparationToSheet(header)
  }

  insertGroupSeparationToSheet (groupTitle) {
    const columnQuantity = this.excelTableColumns.length
    const groupSeparationRow = []

    for (let columnIndex = 0; columnIndex < columnQuantity; columnIndex++) {
      const isFirstColumn = columnIndex === 0

      groupSeparationRow.push({
        value: isFirstColumn ? groupTitle : '',
        backgroundColor: this.groupHeaderBackgroundColor,
        color: this.groupHeaderColor,
        topBorderColor: this.groupheaderBorderColor,
        bottomBorderColor: this.groupheaderBorderColor
      })
    }

    this.workSheetData.push(groupSeparationRow)
  }

  addDataToExcel (data) {
    this.insertColumnHeadersToSheet()
    this.addDatatableValuesToSheet(data)
    this.insertTotalizadoresToSheet(data)
  }

  insertColumnHeadersToSheet () {
    this.workSheetData.push(this.excelTableColumns.map(tableColumn => {
      return {
        ...tableColumn,
        backgroundColor: this.headerBackgroundColor,
        align: 'center',
        color: this.headerColor,
        borderColor: this.headerBorderColor
      }
    }))
  }

  addDatatableValuesToSheet (datatableValues) {
    for (const row of datatableValues) {
      this.insertDatatableRowToSheet(row)
    }
  }

  insertDatatableRowToSheet (row) {
    const excelRow = this.convertDatatableRowToExcelRow(row)
    this.workSheetData.push(excelRow)
  }

  convertDatatableRowToExcelRow (row) {
    const excelRow = []

    for (const tableColumn of this.excelTableColumns) {
      excelRow.push({
        value: row[tableColumn.propertyName],
        wrap: true,
        align: 'center',
        color: this.valuesColor,
        backgroundColor: this.valuesBackgroundColor,
        rightBorderColor: this.valuesBorderColor,
        leftBorderColor: this.valuesBorderColor,
        bottomBorderColor: this.valuesBackgroundColor,
        topBorderColor: this.valuesBackgroundColor
      })
    }

    return excelRow
  }

  insertTotalizadoresToSheet (datatableValues) {
    if (!this.totalizadores) return

    const excelRow = []

    for (const tableColumn of this.excelTableColumns) {
      const totalizador = this.buscaTotalizadorDaColuna(tableColumn)

      let valorTotalizador

      if (totalizador) {
        valorTotalizador = this.getColumnTotalizadorValue(datatableValues, totalizador.field)
      }

      excelRow.push({
        value: valorTotalizador,
        wrap: true,
        align: 'center',
        color: this.totalizadorColor,
        backgroundColor: this.totalizadorBackgroundColor,
        rightBorderColor: this.valuesBorderColor,
        leftBorderColor: this.valuesBorderColor,
        bottomBorderColor: this.totalizadorBackgroundColor,
        topBorderColor: this.totalizadorBorderColor
      })
    }

    this.workSheetData.push(excelRow)
  }

  buscaTotalizadorDaColuna (tableColumn) {
    return this.totalizadores.find(totalizador => totalizador.field === tableColumn.propertyName)
  }

  getColumnTotalizadorValue (datatableValues, field) {
    const values = datatableValues.map(row => row[field])

    return values.reduce((previousValue, currentValue) => previousValue + currentValue)
  }

  downloadSheetToFileXlsx () {
    writeXlsxFile(this.workSheetData, {
      columns: this.columnWidths,
      fileName: this.fileName,
      sheet: this.fileName,
      fontFamily: 'Swiss',
      fontSize: 10
    })
  }
}

export default ReportDataToXlsx
