import React, { useState } from 'react'
import { baseForm } from '../../../../../components/utils/BaseForm'
import { getFormaPagamentoDTO, postFormaPagamentoDTO } from '../../../../../dtos/vendas/negociacao/FormaPagamentoDTO'
import CommonHelper from '../../../../../helpers/CommonHelper'
import { formatCurrencyToNumber } from '../../../../../helpers/formaters'
import ContaService from '../../../../../services/cadastro/financeiro/ContaService'
import ContaBancariaService from '../../../../../services/cadastro/financeiro/ContaBancariaService'
import FinanceiraService from '../../../../../services/cadastro/pessoa/FinanceiraService'
import FormaPagamentosService from '../../../../../services/vendas/negociacao/FormaPagamentosService'
import { showErrorMessage, showWarnMessage } from '../../../../../components/utils/Message'

const FoPagNeg = ({
  Component,
  validationSchema,
  veiculo,
  negociacaoForm,
  dados,
  adicionar,
  editar,
  hide,
  edicaoHabilitada,
  ...props
}) => {
  const [bancos, setBancos] = useState([])
  const [contas, setContas] = useState([])
  const [financeiraOptions, setFinanceiraOptions] = useState([])
  const [formaCobrancaOptions, setFormaCobrancaOptions] = useState([])
  const [restricaoOptions, setRestricaoOptions] = useState([])

  const retornoOptions = [
    { nome: '0', valor: 1 },
    { nome: '1', valor: 2 },
    { nome: '2', valor: 3 },
    { nome: '3', valor: 4 },
    { nome: '4', valor: 5 },
    { nome: '5', valor: 6 },
    { nome: '6', valor: 7 },
    { nome: '7', valor: 8 },
    { nome: '8', valor: 9 },
    { nome: '9', valor: 10 },
    { nome: '10', valor: 11 },
    { nome: '11', valor: 12 },
    { nome: '12', valor: 13 }
  ]
  const calculateValorParcela = () => {
    let valorParcela = 0

    if (Number(form.values.quantidadeParcelas)) {
      valorParcela = form.values.valor / form.values.quantidadeParcelas
    }

    form.setFieldValue('valorParcela', valorParcela)
  }

  const handleBanco = async (e) => {
    const numeroBanco = e.target.value
    await form.setFieldValue('numeroBanco', numeroBanco)

    const bancoEncontrado = bancos.find((banco) => banco.codigo_ban === numeroBanco)
    if (bancoEncontrado) {
      form.setFieldValue('descricaoBanco', bancoEncontrado.descri_ban)
    } else {
      form.setFieldValue('descricaoBanco', '')
    }
  }

  const handleConta = async (e) => {
    const numeroConta = e.target.value
    await form.setFieldValue('numeroConta', numeroConta)

    const contaEncontrada = contas.find((conta) => conta.codigo_con === numeroConta)
    if (contaEncontrada) {
      form.setFieldValue('descricaoConta', contaEncontrada.descri_con)
    } else {
      form.setFieldValue('descricaoConta', '')
    }
  }

  const handleContaBancaria = async (e) => {
    const numeroContaBancaria = e.target.value
    await form.setFieldValue('numeroContaBancaria', numeroContaBancaria)
  }

  const handleChangePositiveInt = event => {
    if (/^[0-9]*$/.test(event.target.value)) {
      form.setFieldValue(event.target.name, event.target.value)
    }
  }

  const handleChangeCurrency = async (event) => {
    await form.setFieldValue(event.target.name, formatCurrencyToNumber(event.target.value))
  }

  const handleChangeCheckBox = event => {
    form.setFieldValue(event.target.name, event.checked)
  }

  const handleConfirmarPagamento = async () => {
    const dadosPagamento = postFormaPagamentoDTO(form.values)
    let veiculoTrocaOk = true

    if (dados === '15') {
      await FormaPagamentosService.podeAdcVeiculoTroca(form.values.veitro_fpn)
        .catch((error) => {
          veiculoTrocaOk = false
          showErrorMessage(error?.message || 'Escolha outro veículo na troca')
        })
    }

    if (veiculoTrocaOk) {
      dados.tipoFormaPagamento ? editar(dadosPagamento) : adicionar(dadosPagamento)
    }
  }

  const handleRetornoValores = (retorno) => {
    const valorRetorno = setAndGetValorRetorno(retorno)
    setAndGetValorRetornoLiquido(valorRetorno)
  }

  const handleTipoFinanciamento = async (e) => {
    await form.setFieldValue('restricao', e.target.value)
    setTipoFinanciamento(e.target.value)
  }

  const getBancos = async () => {
    try {
      const bancos = await FinanceiraService.filterBancos()
      CommonHelper.ordenarPorChave(bancos, 'descri_ban')
      setBancos(bancos)
      form.setFieldValue('bancos', bancos)
      return bancos
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar os bancos!')
    }
  }

  const getContas = async () => {
    try {
      const contas = await ContaService.filterContas()
      CommonHelper.ordenarPorChave(contas, 'descri_con')
      setContas(contas)
      form.setFieldValue('contas', contas)
      return contas
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar as contas!')
    }
  }

  const getContasBancarias = async () => {
    try {
      const contasBancarias = await ContaBancariaService.filterContasBancarias()
      form.setFieldValue('contasBancarias', contasBancarias)
      return contasBancarias
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar as contas bancárias!')
    }
  }

  const getFinanceiras = async () => {
    try {
      const financeiraOptions = await FinanceiraService.getAll()
      CommonHelper.ordenarPorChave(financeiraOptions, 'pessoa.nomraz_pes')
      form.setFieldValue('financeiraOptions', financeiraOptions)
      return financeiraOptions
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar as opções de financeiras!')
    }
  }

  const getFormaCobranca = async () => {
    try {
      const formaCobrancaOptions = await FormaPagamentosService.filterFormaCobranca()
      form.setFieldValue('formaCobrancaOptions', formaCobrancaOptions)
      return formaCobrancaOptions
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar as formas de cobrança!')
    }
  }

  const getTiposRestricaoVeiculo = async () => {
    try {
      const restricaoOptions = await FormaPagamentosService.filterTiposRestricaoVeiculo()
      form.setFieldValue('restricaoOptions', restricaoOptions)
      return restricaoOptions
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar os tipos de restrições!')
    }
  }

  const getPercentualILA = async () => {
    try {
      const ilaPercentagem = await FormaPagamentosService.getPercentualILA()
      form.setFieldValue('ilaPercentagem', ilaPercentagem)
      return ilaPercentagem
    } catch (error) {
      showWarnMessage(error.message || 'Não foi possível buscar o percentual do ILA!')
    }
  }

  const getVeiculo = (codvei) => {
    const veiculo = veiculo.find(veiculo => veiculo.codvei_nve === codvei)
    return veiculo
  }

  const setAndGetValorRetorno = (retorno) => {
    const valorRetorno = form.values.valor * (Number(retorno?.nome || form.values.retorno?.nome || 0) * 0.012)
    if (valorRetorno || valorRetorno === 0) {
      form.setFieldValue('valorRetorno', valorRetorno)
    }

    return valorRetorno
  }

  const setAndGetValorRetornoLiquido = (valorRetorno) => {
    const ilaValor = (valorRetorno * (form.values.ilaPercentagem / 100))
    let valorRetornoLiquido = Number(valorRetorno - ilaValor).toFixed(2)

    const irrfPercentagem = form.values.financeira?.retirrf_fin || 0
    const irrfValor = (valorRetornoLiquido * (irrfPercentagem / 100))

    form.setFieldValue('irrfPercentagem', irrfPercentagem)
    form.setFieldValue('irrfValor', irrfValor)
    form.setFieldValue('ilaValor', ilaValor)

    valorRetornoLiquido = Number(valorRetornoLiquido - irrfValor).toFixed(2)

    if (valorRetornoLiquido || valorRetornoLiquido === 0) {
      form.setFieldValue('valorRetornoLiquido', valorRetornoLiquido)
    }
  }

  const setBanco = (numban, options) => {
    const banco = options.find(banco => banco.codigo_ban === numban)
    form.setFieldValue('banco', banco && banco)
  }

  const setFinanceira = (codfin, options) => {
    const financeira = options.find(financeira => financeira.codigo_fin === codfin)
    form.setFieldValue('financeira', financeira || { codigo_fin: null })
  }

  const setRestricao = async (codtrv, options) => {
    const restricao = options.find(restricao => restricao?.codigo_trv === codtrv)
    await form.setFieldValue('restricao', restricao || { codigo_trv: null })
  }

  const setFormaCobranca = (codfoc, options) => {
    const formaCobranca = options.find(formaCobranca => formaCobranca.codigo_foc === codfoc)
    form.setFieldValue('formaCobranca', formaCobranca || { codigo_foc: null })
  }

  const setRetorno = (retorn, options) => {
    const retorno = options.find(retorno => retorno.valor === retorn)
    form.setFieldValue('retorno', retorno && retorno)
  }

  const setTipoFinanciamento = (restricao) => {
    if (restricao?.codigo_trv === 1 || restricao?.codigo_trv === 2) {
      form.setFieldValue('tipoFinanciamento', restricao?.codigo_trv)
    }
  }

  const setValorVeiculo = () => {
    form.setFieldValue('valorVeiculo', form.values.valor)
  }

  const setPercentILA = () => {
    form.setFieldValue('ilaPercentagem', form.values.ilaPercentagem)
  }

  const LojaPaga = () => {
    return form.values.isLojaPaga === true
  }

  const form = baseForm({
    initialValues: getFormaPagamentoDTO({ ...dados, retornoOptions }),
    onSubmit: handleConfirmarPagamento,
    validationSchema
  })

  return (
    <Component
      form={form}
      isNew={typeof dados === 'string'}
      isLojaPaga={LojaPaga()}
      edicaoHabilitada={edicaoHabilitada}
      calculateValorParcela = {calculateValorParcela}
      financeiraOptions={financeiraOptions}
      setFinanceiraOptions={setFinanceiraOptions}
      formaCobrancaOptions={formaCobrancaOptions}
      setFormaCobrancaOptions={setFormaCobrancaOptions}
      restricaoOptions={restricaoOptions}
      setRestricaoOptions={setRestricaoOptions}
      handleBanco={handleBanco}
      handleChangePositiveInt={handleChangePositiveInt}
      handleChangeCurrency={handleChangeCurrency}
      handleChangeCheckBox={handleChangeCheckBox}
      handleConfirmarPagamento={handleConfirmarPagamento}
      handleConta={handleConta}
      handleContaBancaria={handleContaBancaria}
      handleRetornoValores={handleRetornoValores}
      handleTipoFinanciamento={handleTipoFinanciamento}
      getBancos={getBancos}
      getContas={getContas}
      getContasBancarias={getContasBancarias}
      getFinanceiras={getFinanceiras}
      getFormaCobranca={getFormaCobranca}
      getTiposRestricaoVeiculo={getTiposRestricaoVeiculo}
      getPercentualILA={getPercentualILA}
      getVeiculo={getVeiculo}
      setAndGetValorRetorno={setAndGetValorRetorno}
      setAndGetValorRetornoLiquido={setAndGetValorRetornoLiquido}
      setBanco={setBanco}
      setFinanceira={setFinanceira}
      setRestricao={setRestricao}
      setFormaCobranca={setFormaCobranca}
      setRetorno={setRetorno}
      setTipoFinanciamento={setTipoFinanciamento}
      setValorVeiculo={setValorVeiculo}
      setPercentILA={setPercentILA}
      veiculo={veiculo}
      negociacaoForm={negociacaoForm}
      hide={hide}
      dados={dados}
      {...props}
    />
  )
}

export default FoPagNeg
