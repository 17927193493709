import React, { useEffect, useState } from 'react'
import CheckboxInput from '../../../../../components/inputs/CheckboxInput'
import DateInput from '../../../../../components/inputs/DateInput'
import SelectInput from '../../../../../components/inputs/SelectInput'
import TextInput from '../../../../../components/inputs/TextInput'
import { showErrorMessage, showSuccessMessage } from '../../../../../components/utils/Message'
import CommonHelper from '../../../../../helpers/CommonHelper'
import { getStore } from '../../../../../redux/store'
import TipoPrecoService from '../../../../../services/cadastro/financeiro/TipoPrecoService'
import CEPService from '../../../../../services/cadastro/pessoa/CEPService'
import CidadesService from '../../../../../services/cadastro/pessoa/CidadesService'
import EstadosService from '../../../../../services/cadastro/pessoa/EstadosService'
import PaisesService from '../../../../../services/cadastro/pessoa/PaisesService'
import PermissaoService from '../../../../../services/cadastro/pessoa/PermissaoService'
import AvaliacaoService from '../../../../../services/cadastro/veiculo/AvaliacaoService'
import ConfigService from '../../../../../services/configuracao/ConfigService'

const estadosCivis = [
  { label: 'Casado(a)', estciv_pef: 2 },
  { label: 'Desquitado(a)', estciv_pef: 3 },
  { label: 'Divorciado(a)', estciv_pef: 4 },
  { label: 'Ignorado(a)', estciv_pef: 7 },
  { label: 'Separado(a) judicialmente', estciv_pef: 6 },
  { label: 'Solteiro(a)', estciv_pef: 1 },
  { label: 'União estável', estciv_pef: 8 },
  { label: 'Viúvo(a)', estciv_pef: 5 }
]

const generos = [
  { label: 'Masculino', sex_pef: 1 },
  { label: 'Feminino', sex_pef: 2 },
  { label: 'Ignorado', sex_pef: 3 }
]

const DadosPrincipaisPef = ({ form, edicaoHabilitada, camposObrigatorios }) => {
  const [sexoSelecionado, setSexoSelecionado] = useState(
    generos.find((gen) => gen.sex_pef === form.values.sex_pef)
  )
  const [estadoCivilSelecionado, setEstadoCivilSelecionado] = useState(
    estadosCivis.find((ec) => ec.estciv_pef === form.values.estciv_pef)
  )
  const [estados, setEstados] = useState([])
  const [cidades, setCidades] = useState([])
  const [tiposPreco, setTiposPreco] = useState([])
  const [permissao176, setPermissao176] = useState(false)
  const [permissao123, setPermissao123] = useState(false)
  const [paises, setPaises] = useState({})

  useEffect(() => {
    onLoad()
  }, [])

  useEffect(() => {
    if (form.values.pais.codigo_pai === getStore().codigoBrasil) {
      onLoad()
    }
  }, [form.values.pais])

  const onLoad = async () => {
    const { pessoa } = getStore().empresaLogada
    const estado = await getEstadoPorCidade(pessoa?.codmun_pes)
    const pais = await getPaisPorEstado(estado?.codigo_uf)
    const paises = await getTodosPaises()
    setPaises(paises)

    if (form.values?.codpai_pes || pais?.codigo_pai) {
      await getEstadosPorPais(form.values.codpai_pes || pais?.codigo_pai)
    }

    if (!form.values?.codpai_pes) {
      form.setFieldValue('codpai_pes', pais?.codigo_pai)
      form.setFieldValue('pais_pes', pais?.descri_pai)
      form.setFieldValue('pais', pais)
    }

    if (form.values?.uf_pes || estado?.codigo_uf) {
      await getCidadesPorEstado(form.values.uf_pes || estado.sigla_uf)
    }

    if (form.values?.estado && Object.keys(form.values?.estado).length === 0 && form.values?.codmun_pes) {
      const estadoDaPessoa = await getEstadoPorCidade(form.values.codmun_pes)
      form.setFieldValue('estado', estadoDaPessoa)
    }

    if (!form.values?.uf_pes) {
      form.setFieldValue('uf_pes', estado?.sigla_uf)
      form.setFieldValue('estado', estado)
    }

    if (
      form.values?.codmun_pes &&
      Object.keys(form.values?.municipio).length === 0
    ) {
      const cidade = await getCidade(form.values.codmun_pes)
      form.setFieldValue('cidade_pes', cidade?.description)
      form.setFieldValue('codmun_pes', cidade?.id)
      form.setFieldValue('municipio', cidade)
    }

    if (!form.values?.codmun_pes && form.values?.codpai_pes === getStore().codigoBrasil) {
      const cidade = await getCidade(pessoa.codmun_pes)
      form.setFieldValue('cidade_pes', cidade?.description)
      form.setFieldValue('codmun_pes', cidade?.id)
      form.setFieldValue('municipio', cidade)
    }
    if (form.values.codmun_pes === 9999999) {
      let cidades = await CidadesService.getAll()
      let exterior = cidades.filter((city) => city.id === 9999999)
      setCidades(exterior)
    }

    const config1270 = await ConfigService.getValor(1270)
    const config90 = await ConfigService.getValor(90)
    const permissao176 = await PermissaoService.getByCodigo(176)
    setPermissao176(permissao176)
    const permissao123 = await PermissaoService.getByCodigo(123)
    setPermissao123(permissao123)
    form.setFieldValue('obrigaEmail', config1270)
    form.setFieldValue('obrigarDataNascimento', config90)
    await getTiposPreco()
  }

  async function getTiposPreco () {
    const tiposPreco = await TipoPrecoService.getTiposPrecoPersonalizadoOrcamentoBalcao()
    setTiposPreco(tiposPreco)
    return tiposPreco
  }

  const setApelidoComNome = () => {
    form.setFieldValue('apelid_pes', form.values.nomraz_pes)
  }

  const getEstadosPorPais = async (countryId) => {
    try {
      const estados = await EstadosService.getAllByCountry(countryId)
      setEstados(estados)
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro inesperado ao buscar os estados do pais!')
    }
  }

  const getPaisPorEstado = async (stateId) => {
    try {
      return await PaisesService.getCountryByState(stateId)
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro inesperado ao buscar o pais pelo estado!')
    }
  }
  const getTodosPaises = async () => {
    try {
      return await PaisesService.getAll()
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro inesperado ao buscar os paises!')
    }
  }

  const getCidadesPorEstado = async (stateInitials) => {
    try {
      let cidades = await CidadesService.getAllByStateInitials(stateInitials)
      setCidades(cidades)
    } catch (e) {
      console.error(e)
      showErrorMessage(e.message || 'Ocorreu um erro inesperado ao buscar as cidades pelo estado!')
    }
  }

  const getEstadoPorCidade = async (cityId) => {
    try {
      return await EstadosService.getStateByCity(cityId)
    } catch (e) {
      console.error(e)
      showErrorMessage(e.message || 'Ocorreu um erro inesperado ao buscar o estado pela cidade!')
    }
  }

  const getCidade = async (cidadeId) => {
    try {
      return await CidadesService.getCity(cidadeId)
    } catch (e) {
      console.error(e)
      showErrorMessage(e.message || 'Ocorreu um erro inesperado ao buscar a cidade!')
    }
  }

  const handleChangeSexo = (e) => {
    form.setFieldValue('sex_pef', e.target.value?.sex_pef)
    setSexoSelecionado(e.target.value)
  }

  const handleChangeEstadoCivil = (e) => {
    form.setFieldValue('estciv_pef', e.target.value?.estciv_pef)
    setEstadoCivilSelecionado(e.target.value)
  }

  const handleChangeEstado = (e) => {
    const estado = e.target.value
    form.setFieldValue('uf_pes', estado?.sigla_uf)
    form.setFieldValue('cidade_pes', null)
    form.setFieldValue('codmun_pes', null)
    form.setFieldValue('estado', estado)
    form.setFieldValue('municipio', {})

    getCidadesPorEstado(estado?.sigla_uf)
  }

  const handleChangePaises = async (e) => {
    const pais = e.target.value
    form.setFieldValue('pais', pais)
    form.setFieldValue('codpai_pes', pais?.codigo_pai)
    form.setFieldValue('pais_pes', pais?.descri_pai)

    if (pais?.codigo_pai !== getStore().codigoBrasil) {
      let cidades = await CidadesService.getAll()
      let exterior = cidades.filter((city) => city.id === 9999999)
      setCidades(exterior)
      form.setFieldValue('cidade_pes', exterior[0]?.description)
      form.setFieldValue('codmun_pes', exterior[0]?.id)
      form.setFieldValue('municipio', exterior[0])
      form.setFieldValue('cep_pes', null)
      form.setFieldValue('cgccpf_pes', null)
      form.setFieldValue('uf_pes', null)
      form.setFieldValue('estado', null)
      return
    } else {
      form.setFieldValue('cidade_pes', null)
      form.setFieldValue('codmun_pes', null)
      form.setFieldValue('municipio', null)
    }
  }

  const handleChangeCidade = (e) => {
    const cidade = e.target.value
    form.setFieldValue('cidade_pes', cidade?.description)
    form.setFieldValue('codmun_pes', cidade?.id)
    form.setFieldValue('municipio', cidade)
  }

  const handleChangeTipoPreco = (e) => {
    const tipoPreco = e.target.value
    form.setFieldValue('tipoPreco', tipoPreco)
  }

  const handleChangeCep = async (value) => {
    try {
      const cep = value
      form.setFieldValue('cep_pes', cep)

      if (CommonHelper.clearDigitsSpecialChars(cep).length === 8) {
        const endereco = await CEPService.getEndereco(cep)
        form.setFieldValue('estado', { sigla_uf: endereco.uf, descri_uf: 'treste' })

        await getCidadesPorEstado(endereco.uf)
        form.setFieldValue('municipio', { id: Number(endereco.ibge) })
        form.setFieldValue('codmun_pes', form.values.municipio?.id)
        form.setFieldValue('cidade_pes', endereco.localidade)
        form.setFieldValue('bairro_pes', String(endereco.bairro).toUpperCase())
        form.setFieldValue('end_pes', String(endereco.logradouro).toUpperCase())
        form.setFieldValue('uf_pes', endereco.uf)
      }
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar endereço via CEP!')
    }
  }

  const handleChangeRG = (e) => {
    form.setFieldValue('rg_pes', e.target.value)
    form.setFieldValue('ierg_pes', e.target.value)
  }

  const buscarPessoaAvaliacao = async () => {
    try {
      const avaliacao = await AvaliacaoService.getAvaliacaoCompletaCpfCnpj(
        CommonHelper.clearDigitsSpecialChars(form.values.cgccpf_pes)
      )

      if (avaliacao) {
        showSuccessMessage(
          `Os dados foram obtidos da avaliação ${avaliacao.codigo_ava}`
        )

        form.setFieldValue('nomraz_pes', avaliacao.nomraz_ava)
        form.setFieldValue('telcel_pes', avaliacao.telcel_ava)
        form.setFieldValue('email_pes', avaliacao.emacli_ava)
      }
    } catch (error) { }
  }

  return (
    <div className="formgrid grid">
      <div className="field col-12 md:col-12">
        <TextInput
          label="Nome"
          placeholder="Nome"
          name="nomraz_pes"
          value={form.values.nomraz_pes}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          maxLength={200}
          onBlur={(e) => setApelidoComNome(e)}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-7 md:col-2">
        {form.values.pais?.codigo_pai && form.values.pais?.codigo_pai !== getStore().codigoBrasil
          ? <TextInput
            label="ID Internacional"
            camposObrigatorios={'.'}
            form={form}
            placeholder="ID"
            name="idint_pes"
            value={form.values.idint_pes}
            onChange={(value) => {
              form.setFieldValue('idint_pes', value.target.value)
            }}
            disabled={!edicaoHabilitada}
          />
          : <TextInput
            label="CPF"
            camposObrigatorios={camposObrigatorios}
            form={form}
            placeholder="CPF"
            name="cgccpf_pes"
            value={form.values.cgccpf_pes}
            onChange={(value) => {
              form.setFieldValue('cgccpf_pes', value)
              if (value.length === 11) {
                buscarPessoaAvaliacao()
              }
            }}
            disabled={!edicaoHabilitada}
            mask="999.999.999-99"
          />
        }
      </div>
      <div className="field col-5 md:col-2">
        <TextInput
          camposObrigatorios={camposObrigatorios}
          form={form}
          label="RG"
          placeholder="RG"
          name="rg_pes"
          value={form.values.rg_pes}
          onChange={(e) => handleChangeRG(e)}
          disabled={!edicaoHabilitada}
          maxLength={20}
        />
      </div>
      <div className="field col-12 md:col-3">
        <DateInput
          name="datnas_pef"
          label="Data de nascimento"
          form={form}
          placeholder="Data de nascimento"
          value={form.values.datnas_pef}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          camposObrigatorios={form.values?.obrigarDataNascimento ? camposObrigatorios : '.'}
        />
      </div>
      <div className="field col-12 md:col-2">
        <label className="label" htmlFor="sex_pef">
          Sexo
        </label>
        <SelectInput
          value={sexoSelecionado}
          options={generos}
          onChange={handleChangeSexo}
          id="sex_pef"
          name="sex_pef"
          dataKey="sex_pef"
          optionLabel="label"
          noFloatLabel
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
          showClear={sexoSelecionado?.sex_pef}
        />
      </div>
      <div className="field col-12 md:col-3">
        <label className="label" htmlFor="estciv_pef">
          Estado civil
        </label>
        <SelectInput
          id="estciv_pef"
          name="estciv_pef"
          optionLabel="label"
          dataKey="estciv_pef"
          value={estadoCivilSelecionado}
          onChange={handleChangeEstadoCivil}
          options={estadosCivis}
          disabled={!edicaoHabilitada}
          placeholder="- Selecione -"
          className="inputfield w-full"
          showClear={estadoCivilSelecionado?.estciv_pef}
        />
      </div>
      <div className="field col-12 md:col-2">
        <TextInput
          label="CEP"
          camposObrigatorios={form.values.pais?.codigo_pai !== getStore().codigoBrasil ? '.' : camposObrigatorios}
          form={form}
          placeholder="CEP"
          name="cep_pes"
          value={form.values.cep_pes}
          onChange={handleChangeCep}
          mask="99999-999"
          disabled={!edicaoHabilitada || form.values.pais?.codigo_pai !== getStore().codigoBrasil}
        />
      </div>
      <div className="field col-12 md:col-10">
        <TextInput
          camposObrigatorios={camposObrigatorios}
          form={form}
          label="Endereço"
          placeholder="Endereço"
          name="end_pes"
          value={form.values.end_pes}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          maxLength={100}
        />
      </div>
      <div className="field col-5 md:col-2">
        <TextInput
          camposObrigatorios={camposObrigatorios}
          form={form}
          label="Número"
          placeholder="Número"
          name="numend_pes"
          maxLength={10}
          value={form.values.numend_pes}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
        />
      </div>
      <div className="field col-7 md:col-4">
        <TextInput
          camposObrigatorios={camposObrigatorios}
          form={form}
          label="Complemento"
          placeholder="Complemento"
          name="comend_pes"
          maxLength={60}
          value={form.values.comend_pes}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
        />
      </div>
      <div className="field col-12 md:col-6">
        <TextInput
          camposObrigatorios={camposObrigatorios}
          form={form}
          label="Bairro"
          placeholder="Bairro"
          name="bairro_pes"
          maxLength={70}
          value={form.values.bairro_pes}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
        />
      </div>
      <div className="field col-12 md:col-2">
        <SelectInput
          value={form.values.pais}
          options={paises}
          onChange={handleChangePaises}
          name="pais"
          label="País"
          filter={CommonHelper.isDesktop()}
          optionLabel="descri_pai"
          dataKey="codigo_pai"
          noFloatLabel
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-2">
        <SelectInput
          value={form.values.estado}
          options={estados}
          onChange={handleChangeEstado}
          name="estado"
          label="Estado"
          filter={CommonHelper.isDesktop()}
          optionLabel="descri_uf"
          dataKey="sigla_uf"
          noFloatLabel
          disabled={!edicaoHabilitada || form.values.pais?.codigo_pai !== getStore().codigoBrasil}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-2">
        <SelectInput
          value={form.values.municipio}
          options={cidades}
          onChange={handleChangeCidade}
          label="Município"
          name="municipio"
          filter={CommonHelper.isDesktop()}
          optionLabel="description"
          dataKey="id"
          noFloatLabel
          disabled={!edicaoHabilitada || form.values.pais?.codigo_pai !== getStore().codigoBrasil}
          className="inputfield w-full"
        />

      </div>
      <div className="field col-12 md:col-6">
        <TextInput
          camposObrigatorios={form.values?.obrigaEmail ? camposObrigatorios : '.'}
          form={form}
          label="E-mail"
          placeholder="E-mail"
          name={'email_pes'}
          value={form.values.email_pes}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          maxLength={240}
        />

      </div>
      <div className="field col-12 md:col-3">
        <TextInput
          camposObrigatorios={camposObrigatorios}
          form={form}
          label="Telefone Celular"
          placeholder="Telefone celular"
          name="telcel_pes"
          value={form.values.telcel_pes}
          onChange={(value) => form.setFieldValue('telcel_pes', value)}
          mask="(99) 99999-9999"
          disabled={!edicaoHabilitada}
        />
      </div>
      <div className="field col-12 md:col-3">
        <TextInput
          camposObrigatorios={camposObrigatorios}
          form={form}
          label="Telefone Comercial"
          placeholder="Telefone comercial"
          name="telcom_pes"
          value={form.values.telcom_pes}
          onChange={(value) => form.setFieldValue('telcom_pes', value)}
          mask="(99) 99999-9999"
          disabled={!edicaoHabilitada}
        />
      </div>
      <div className="field col-12 md:col-6">
        <TextInput
          camposObrigatorios={camposObrigatorios}
          form={form}
          label="Telefone Residencial"
          placeholder="Telefone residencial"
          name="telres_pes"
          value={form.values.telres_pes}
          onChange={(value) => form.setFieldValue('telres_pes', value)}
          mask="(99) 99999-9999"
          disabled={!edicaoHabilitada}
        />
      </div>
      <div className="field col-12 md:col-3">
        <SelectInput
          label="Tabela de preço"
          value={form.values?.tipoPreco}
          options={tiposPreco}
          onChange={handleChangeTipoPreco}
          name="tipoPreco"
          filter={CommonHelper.isDesktop()}
          optionLabel="descri_tpr"
          dataKey="codigo_tpr"
          noFloatLabel
          disabled={!edicaoHabilitada || !permissao176}
          className="inputfield w-full"
          showClear={form.values.tipoPreco?.codigo_tpr}
          onHide={() => form.setFieldTouched('tipoPreco.codigo_tpr')}
        />

      </div>
      <div className="field col-12 md:col-3 field checkbox-container">
        <CheckboxInput label={'Cliente atacadista'}
          name='cliata_pes'
          value={form.values?.cliata_pes}
          checked={form.values?.cliata_pes}
          disabled={!edicaoHabilitada || !permissao123}
          onChange={form.handleChange}
        />

      </div>
    </div>
  )
}

export default DadosPrincipaisPef
