import { Checkbox } from 'primereact/checkbox'
import { Column } from 'primereact/column'
import React from 'react'
import List from '../../../../classes/List'
import AppButton from '../../../../components/layout/AppButton'
import Container from '../../../../components/layout/Container'
import DataList from '../../../../components/layout/DataList'
import { showErrorMessage } from '../../../../components/utils/Message'
import Modal from '../../../../components/utils/Modal'
import ResponsiveColumn from '../../../../components/utils/ResponsiveColumn'
import { getAvaliacaoDTO } from '../../../../dtos/vendas/negociacao/avaliacao/AvaliacaoDTO'
import { formatToCurrency } from '../../../../helpers/formaters'
import ConectaCarrosService from '../../../../services/Integracao/conectaCarros/ConectaCarrosService'
import AvaliacaoService from '../../../../services/cadastro/veiculo/AvaliacaoService'

class SelecionarModelosFipeModal extends List {
  constructor (props) {
    super(props, getAvaliacaoDTO, AvaliacaoService, 'avaliacao', 'codigo_ava')
    this.state = {
      ...this.state,
      modeloFipeSelecionado: null,
      listaModelosFipe: [],
      avaliacao: props.avaliacao,
      veiculo: props.veiculo,
      objetoReferencia: null,
      prefixos: ['tivcoc', 'codcoc', 'descoc', 'desfip', 'anomod', 'fipcoc', 'valfip', 'datfip']
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { visible, avaliacao, veiculo } = this.props

    if (prevProps.visible !== visible) {
      this.setState({ visible })

      if (visible && (avaliacao || veiculo)) {
        this.criarObjetoReferencia(avaliacao || veiculo)
      }
    }

    if (this.state.objetoReferencia && prevState.objetoReferencia !== this.state.objetoReferencia) {
      this.getListaModelosFipe()
    }
  }

  componentDidMount () {
    this.resetDescricaoFiltro()
  }

  criarObjetoReferencia (objeto) {
    if (!objeto) return {}

    const objetoReferencia = {}

    const sufixo = objeto.hasOwnProperty('codcoc_vei') ? 'vei' : 'ava'
    this.state.prefixos.forEach(prefixo => {
      const chave = prefixo + '_' + sufixo
      if (objeto.hasOwnProperty(chave)) {
        objetoReferencia[prefixo] = objeto[chave]
      }
    })
    this.setState({ objetoReferencia: objetoReferencia })
  }

  preencherObjetoRetorno (objetoOriginal, objetoReferencia) {
    if (!objetoOriginal || !objetoReferencia) return objetoOriginal

    const sufixo = objetoOriginal.hasOwnProperty('codcoc_vei') ? 'vei' : 'ava'

    this.state.prefixos.forEach(prefixo => {
      const chaveOriginal = prefixo + '_' + sufixo
      if (objetoReferencia.hasOwnProperty(prefixo)) {
        objetoOriginal[chaveOriginal] = objetoReferencia[prefixo]
      }
    })
    return objetoOriginal
  }

    getListaModelosFipe = async () => {
      try {
        const listaFipe = await ConectaCarrosService.getDadosFipeByConectaCarros(this.state.objetoReferencia.codcoc, this.state.objetoReferencia.tivcoc)
        this.setState({ listaModelosFipe: listaFipe?.data })

        const modeloSelecionado = listaFipe?.data.find(modelo => modelo.codigo_fipe == this.state.objetoReferencia.fipcoc)
        if (modeloSelecionado) {
          this.setState({ modeloFipeSelecionado: modeloSelecionado })
          // Rolagem até o elemento selecionado
          var modal = document.getElementById('ListaModelosFipeModal')
          var checkbox = modal.querySelector('input[type="checkbox"][checked]')
          checkbox.scrollIntoView({ behavior: 'smooth' })
        }
      } catch (error) {
        showErrorMessage(error.message)
      }
    }

    onSelectModeloFipe = selected => {
      this.setState({ modeloFipeSelecionado: selected })
    }

    onConfirmarFipe = () => {
      if (!this.state.modeloFipeSelecionado) {
        showErrorMessage('Selecione um modelo da FIPE!')
        return false
      }
      let obj = this.state.objetoReferencia
      obj.fipcoc = this.state.modeloFipeSelecionado.codigo_fipe
      obj.datfip = this.state.modeloFipeSelecionado.data_ultima_alteracao
      obj.valfip = this.state.modeloFipeSelecionado.valor_fipe
      obj.desfip = this.state.modeloFipeSelecionado.descricao_fipe
      this.setState({ objetoReferencia: obj })
      let retorno = this.preencherObjetoRetorno(this.props.avaliacao || this.props.veiculo, obj)
      this.props.onModeloConfirmado(retorno)
      this.props.onHide()
    }

    hideModal = () => {
      this.setState({ visible: false })
      this.props.onHide()
    }

    render () {
      const { visible } = this.state
      return (
        <>
          <Modal
            header="Selecionar modelo Fipe"
            id="ListaModelosFipeModal"
            width={40}
            visible={visible}
            onHide={this.hideModal}
            footer={
              <div>
                <AppButton
                  label="Cancelar"
                  icon="pi pi-times"
                  type="button"
                  className="p-button-danger mb-1 mt-1 mr-3"
                  onClick={() => this.hideModal()}
                />
                <AppButton
                  label="Confirmar"
                  icon="pi pi-check"
                  className="p-button mb-1 mt-1 mr-5"
                  onClick={() => this.onConfirmarFipe()}
                />
              </div>
            }
          >
            <div className="formgrid grid fluid">

              <Container>
                <DataList
                  data={this.state.listaModelosFipe}
                  selected={this.state.modeloFipeSelecionado}
                  rows={this.state.listaModelosFipe?.length}
                  onSelect={this.onSelect}
                  onRowClick={(e) => this.setState({ modeloFipeSelecionado: e.data })}
                  hidePaginator={true}
                  totalRecords={this.state.listaModelosFipe?.length}
                  first={1}
                  page={1}
                  showFilter={false}
                  responsive={true}
                  selectionMode="single"
                  frozen
                >
                  <Column
                    header="Selecionar"
                    body={data => (
                      <ResponsiveColumn
                        column="Selecionar"
                      >
                        <Checkbox
                          checked={data.codigo_fipe == this.state.modeloFipeSelecionado?.codigo_fipe}
                          value={data.codigo_fipe == this.state.modeloFipeSelecionado?.codigo_fipe ? true : false}
                          onChange={(e) => this.setState({ modeloFipeSelecionado: data })}
                          disabled={false}
                          readOnly={false}
                        />
                      </ResponsiveColumn>
                    )}
                    headerClassName="w-2"
                    sortable={true}
                  />
                  <Column
                    header="Modelo"
                    body={(data) => (
                      <ResponsiveColumn
                        className="pl-2"
                        column="Modelo"
                        value={data.descricao_fipe}
                      />
                    )}
                    sortable={true}
                    field="descricao_fipe"
                  />
                  <Column
                    header="Preço"
                    body={(data) => (
                      <ResponsiveColumn
                        className="pl-2"
                        column="Preço"
                        value={formatToCurrency(data.valor_fipe)}
                      />
                    )}
                    headerClassName="w-3"
                    sortable={true}
                    field="valor_fipe"
                  />
                </DataList>
              </Container>
            </div>
          </Modal>
        </>
      )
    }
}

export default SelecionarModelosFipeModal
