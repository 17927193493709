import { classNames } from 'primereact/utils'
import React, { useEffect } from 'react'
import CheckboxInput from '../../../../../../components/inputs/CheckboxInput'
import DateInput from '../../../../../../components/inputs/DateInput'
import SelectInput from '../../../../../../components/inputs/SelectInput'
import TextAreaInput from '../../../../../../components/inputs/TextAreaInput'
import TextInput from '../../../../../../components/inputs/TextInput'
import ResponsavelPagamento from '../../../../../../components/layout/ResponsavelPagamento/ResponsavelPagamento'
import FieldErrorMessage from '../../../../../../components/utils/FieldErrorMessage'
import Modal from '../../../../../../components/utils/Modal'
import RequiredLabel from '../../../../../../components/utils/RequiredLabel'
import CommonHelper from '../../../../../../helpers/CommonHelper'
import {
  formatCurrencyToNumber,
  formatToCurrency
} from '../../../../../../helpers/formaters'
import { useValidateInput } from '../../../../../../helpers/useValidateInput'
import FoPagNeg from '../_FoPagNeg'
import { CartaoDebitoValidator } from './CartaoDebitoValidator'

const FoPagCartaoDebito = (props) => {
  const {
    form,
    edicaoHabilitada,
    handleChangeCheckBox,
    setValorVeiculo,
    hide,
    isLojaPaga,
    excluir,
    isNew
  } = props

  const deveSelecionarVeiculo = props.negociacaoForm.values.veiculosConjunto.length > 0

  useEffect(() => {
    const onLoad = async () => {
      form.setFieldValue('veiculo', props.veiculo)

      if (isNew) {
        form.setFieldValue('isLojaPaga', isLojaPaga)
      }
    }
    onLoad()
  }, [])

  const { getFormErrorMessage, isFormFieldValid } = useValidateInput(form)

  return (
    <Modal
      header="Cartão de débito"
      btnSalvar={edicaoHabilitada ? form.submitForm : undefined}
      btnExcluir={(!isNew && edicaoHabilitada) ? excluir : undefined}
      width="55"
      visible={true}
      modal={true}
      onHide={hide}
    >
      <div className={`formgrid grid ${CommonHelper.isDesktop() ? 'mt-3' : ''}`}>
        <div className="field col-12 flex justify-content-around mb-4">
          <ResponsavelPagamento isLojaPaga={isNew ? isLojaPaga : form.values.isLojaPaga} />
          <div>
            <CheckboxInput
              name="isSinal"
              label="Sinal"
              checked={form.values.isSinal}
              disabled={!edicaoHabilitada}
              onChange={handleChangeCheckBox}
            />
          </div>
        </div>
        {deveSelecionarVeiculo && (
          <div className="field col-12 md:col-4">
            <RequiredLabel label="Veículo" valid={isFormFieldValid('veiculo.codigo_vei')} />
            <SelectInput
              value={form.values.veiculo}
              options={props.negociacaoForm.values.veiculosConjunto.map(veiculoNve => veiculoNve.veiculo)}
              onChange={(e) => form.setFieldValue('veiculo', e.target.value)}
              name='veiculo'
              optionLabel="placha"
              dataKey="codigo_vei"
              disabled={!edicaoHabilitada}
              className={classNames({ 'p-invalid': isFormFieldValid('veiculo.codigo_vei') }, 'inputfield w-full')}
              noFloatLabel
            />
            <FieldErrorMessage message={getFormErrorMessage('veiculo.codigo_vei')} />
          </div>
        )}
        <div className={'field col-12 md:col-' + (deveSelecionarVeiculo ? '4' : '6')}>
          <RequiredLabel label="Data de Recebimento" valid={isFormFieldValid('dataEmissao')} />
          <DateInput
            name="dataEmissao"
            disabled={!edicaoHabilitada}
            value={form.values.dataEmissao}
            onChange={form.handleChange}
            onBlur={ () =>
              form.setFieldTouched('dataEmissao')
            }
            className={classNames({ 'p-invalid': isFormFieldValid('dataEmissao') }, 'inputfield w-full')}
          />
          <FieldErrorMessage message={getFormErrorMessage('dataEmissao')} />
        </div>
        <div className={'field col-12 md:col-' + (deveSelecionarVeiculo ? '4' : '6')}>
          <RequiredLabel label="Valor" valid={isFormFieldValid('valor')} />
          <TextInput
            value={formatToCurrency(form.values.valor)}
            disabled={!edicaoHabilitada}
            onChange={(e) =>
              form.setFieldValue(
                'valor',
                formatCurrencyToNumber(e.target.value)
              )
            }
            onBlur={() => {
              setValorVeiculo()
              form.setFieldTouched('valor')
            }}
            className={classNames(
              { 'p-invalid': isFormFieldValid('valor') },
              'inputfield w-full'
            )}
          />
          <FieldErrorMessage message={getFormErrorMessage('valor')} />
        </div>
        <div className="field col-12">
          <TextAreaInput
            name="observacoes"
            label="Observações"
            value={form.values.observacoes}
            disabled={!edicaoHabilitada}
            onChange={form.handleChange}
            className="inputfield w-full"
          />
        </div>
      </div>
    </Modal>
  )
}

export default (props) =>
  FoPagNeg({
    Component: FoPagCartaoDebito,
    validationSchema: CartaoDebitoValidator,
    ...props
  })
