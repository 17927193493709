import { postOrcamentoBalcaoDTO } from '../../../dtos/vendas/orcamentoBalcao/OrcamentoBalcaoDTO'
import { postGerarTituloOpvDTO } from '../../../dtos/vendas/orcamentoBalcao/TituloOpvDTO'
import ServiceBase from '../../base/ServiceBase'

export default class OrcamentoBalcaoService {
  async save (orcamentoBalcao) {
    return await ServiceBase.postBase('orcamentoBalcao/', postOrcamentoBalcaoDTO(orcamentoBalcao))
  }

  async get (codigo) {
    return await ServiceBase.getBase(`orcamentoBalcao/${codigo}`)
  }

  static async getPodOrcOutroVendedor (codigo) {
    return await ServiceBase.getBase(`orcamentoBalcao/getOrcOutroVendedor/${codigo}`)
  }

  async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`orcamentoBalcao?${query}&page=${page}&perPage=${perPage}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`orcamentoBalcao?${query}&page=${page}&perPage=${perPage}`)
  }

  static async getAllModalidadeFrete () {
    const data = await ServiceBase.getBase('modalidadeFrete')
    return data
  }

  async getFormValidator () {
    return await ServiceBase.getBase('orcamentoBalcao/getFormValidator')
  }

  static async getQtdRecusada (codigoTra) {
    return await ServiceBase.getBase(`orcamentoBalcao/getRecusada/${codigoTra}`)
  }

  static async getQtdPendentes (codigoTra) {
    return await ServiceBase.getBase(`orcamentoBalcao/getPendente/${codigoTra}`)
  }

  static async configurarTituloOpv (codigoOrcamentoBalcao, condicaoPagamento) {
    const condPagDTO = postGerarTituloOpvDTO(condicaoPagamento)
    return await ServiceBase.postBase(`orcamentoBalcao/configurarTituloOpv/${codigoOrcamentoBalcao}`, { condicaoPagamento: condPagDTO })
  }

  static async getOrcamentosAbertosOuReservadosByCliente (codCliente, codOrcamentoAtual) {
    return await ServiceBase.getBase(`orcamentoBalcao/getOrcamentosAbertosOuReservadosByCliente/${codCliente}/${codOrcamentoAtual}`)
  }

  static async cancelarOrcamentoBalcao (codigoOrcamentoBalcao, motivoVendaPerdida) {
    return await ServiceBase.postBase('orcamentoBalcao/cancelarOrcamento', { codigoOrcamentoBalcao, motivoVendaPerdida })
  }

  static async validarDisponibilidadePecas (codigoOrcamentoBalcao) {
    return await ServiceBase.getBase(`orcamentoBalcao/validarDisponibilidade/${codigoOrcamentoBalcao}`)
  }

  static async enviarParaOCaixa (codigoOrcamentoBalcao) {
    return await ServiceBase.getBase(`orcamentoBalcao/enviarParaOCaixa/${codigoOrcamentoBalcao}`)
  }

  static async reabrirOrcamentoBalcao (codigoOrcamentoBalcao) {
    return await ServiceBase.postBase('orcamentoBalcao/reabrirOrcamento', { codigoOrcamentoBalcao })
  }

  static async reservarOrcamentoBalcao (codigoOrcamentoBalcao) {
    return await ServiceBase.postBase('orcamentoBalcao/reservarOrcamento', { codigoOrcamentoBalcao })
  }

  static async gerarURLMensagemWhatsApp (codigoOrcamentoBalcao, mensagem) {
    return await ServiceBase.postBase('orcamentoBalcao/gerarURLMensagemWhatsApp', { codigoOrcamentoBalcao, mensagem })
  }

  static async enviarEmail (codigoOrcamentoBalcao, dadosRetornoEnvioEmail) {
    return await ServiceBase.postBase(`orcamentoBalcao/enviarEmail/${codigoOrcamentoBalcao}`, { dadosRetornoEnvioEmail: dadosRetornoEnvioEmail })
  }
}
