import React, { useEffect, useState } from 'react'
import CheckboxInput from '../../../../../components/inputs/CheckboxInput'
import DateTimeInput from '../../../../../components/inputs/DateTimeInput'
import NumberInput from '../../../../../components/inputs/NumberInput'
import SelectInput from '../../../../../components/inputs/SelectInput'
import TextAreaInput from '../../../../../components/inputs/TextAreaInput'
import TextInput from '../../../../../components/inputs/TextInput'
import CommonHelper from '../../../../../helpers/CommonHelper'
import ClassificacaoFiscalService from '../../../../../services/cadastro/produto/ClassificacaoFiscalService'
import FamiliaVeiculoService from '../../../../../services/cadastro/veiculo/FamiliaVeiculoService'

const DadosAdicionaisMod = ({ form, edicaoHabilitada, camposObrigatorios }) => {
  const [familiasVeiculo, setFamiliasVeiculo] = useState([])
  const [classificacoesFiscais, setClassificacoesFiscais] = useState([])

  useEffect(async () => {
    await getFamiliasVeiculo()
    await getClassificacoesFiscais()
  }, [])

  const getFamiliasVeiculo = async () => {
    try {
      await FamiliaVeiculoService.getAll().then(familiasVeiculo => setFamiliasVeiculo(familiasVeiculo))
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar a familia do veículo!')
    }
  }

  const getClassificacoesFiscais = async () => {
    try {
      await ClassificacaoFiscalService.getAll().then(classificacoesFiscais => setClassificacoesFiscais(classificacoesFiscais))
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar as classificações fiscais!')
    }
  }

  return (
    <div className="formgrid grid fluid">
      <div className="field col-12 sm:col-4 md:col-4 flex align-items-center">
        <CheckboxInput
          inputId="isman_mod"
          name="isman_mod"
          value={form.values.isman_mod}
          onChange={form.handleChange}
          checked={form.values.isman_mod}
          disabled={!edicaoHabilitada}
          label="Modelo cadastrado manualmente?"
        />
      </div>
      <div className="field col-12 sm:col-8 md:col-8 flex align-items-center">
        <CheckboxInput
          inputId="ativo_mod"
          name="ativo_mod"
          value={form.values.ativo_mod}
          onChange={form.handleChange}
          checked={form.values.ativo_mod}
          disabled={!edicaoHabilitada}
          label="Modelo ativo?"
        />
      </div>
      <div className="field col-12 sm:col-6 md:col-6">
        <SelectInput
          options={classificacoesFiscais}
          field="classificacaofiscal.descri_clf"
          name="classificacaofiscal"
          dataKey="codigo_clf"
          optionLabel="descri_clf"
          label="Classificação fiscal"
          value={form.values.classificacaofiscal}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          panelClassName="overflowing-values"
          filter={CommonHelper.isDesktop()}
          noFloatLabel
          showClear={form.values.classificacaofiscal?.codigo_clf}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field checkbox-container col-12 sm:col-3 md:col-3 flex align-items-center">
        <CheckboxInput
          inputId="tagnov_mod"
          name="tagnov_mod"
          value={form.values.tagnov_mod}
          onChange={form.handleChange}
          checked={form.values.tagnov_mod}
          disabled={!edicaoHabilitada}
          label="Enviar dados do veículo na NF-e"
        />
      </div>
      <div className="field checkbox-container col-12 sm:col-3 md:col-3 flex align-items-center">
        <CheckboxInput
          inputId="igntamcha_mod"
          name="igntamcha_mod"
          value={form.values.igntamcha_mod}
          onChange={form.handleChange}
          checked={form.values.igntamcha_mod}
          disabled={!edicaoHabilitada}
          label="Ignora tamanho do chassi"
        />
      </div>
      <div className="field col-12 sm:col-3 md:col-3">
        <TextInput
          placeholder="Cód. DENATRAN"
          label="Cód. DENATRAN"
          value={form.values.codden_mod}
          name="codden_mod"
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 sm:col-3 md:col-3">
        <TextInput
          placeholder="Referência fábrica"
          label="Referência fábrica"
          value={form.values.reffab_mod}
          name="reffab_mod"
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 sm:col-3 md:col-3">
        <SelectInput
          options={familiasVeiculo}
          field="familiaVeiculo.descri_fav"
          name="familiaVeiculo"
          dataKey="codigo_fav"
          optionLabel="descri_fav"
          label="Família"
          value={form.values.familiaVeiculo}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          filter={CommonHelper.isDesktop()}
          noFloatLabel
          showClear={form.values.familiaVeiculo?.codigo_fav}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 sm:col-3 md:col-3">
        <NumberInput
          placeholder="Potência máxima (CV)"
          value={form.values.potmax_mod}
          name="potmax_mod"
          label="Potência máxima (CV)"
          onChange={(e) => form.setFieldValue('potmax_mod', e.value)}
          disabled={!edicaoHabilitada}
          mode="decimal"
          minFractionDigits={2}
          maxFractionDigits={2}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 sm:col-2 md:col-2">
        <NumberInput
          placeholder="Qtd. eixos"
          value={form.values.qtdeix_mod}
          name="qtdeix_mod"
          label="Qtd. eixos"
          onChange={(e) => form.setFieldValue('qtdeix_mod', e.value)}
          disabled={!edicaoHabilitada}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 sm:col-2 md:col-2">
        <NumberInput
          placeholder="Dist. dos eixos (mm)"
          value={form.values.diseix_mod}
          name="diseix_mod"
          label="Dist. dos eixos (mm)"
          onChange={(e) => form.setFieldValue('diseix_mod', e.value)}
          disabled={!edicaoHabilitada}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 sm:col-2 md:col-2">
        <NumberInput
          placeholder="Peso líquido (Kg)"
          value={form.values.pesliq_mod}
          name="pesliq_mod"
          label="Peso líquido (Kg)"
          onChange={(e) => form.setFieldValue('pesliq_mod', e.value)}
          disabled={!edicaoHabilitada}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 sm:col-2 md:col-2">
        <NumberInput
          placeholder="Peso bruto (Kg)"
          value={form.values.pesbru_mod}
          name="pesbru_mod"
          label="Peso bruto (Kg)"
          onChange={(e) => form.setFieldValue('pesbru_mod', e.value)}
          disabled={!edicaoHabilitada}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 sm:col-2 md:col-2">
        <NumberInput
          placeholder="C.M.T. (Kg)"
          value={form.values.cmt_mod}
          name="cmt_mod"
          label="C.M.T. (Kg)"
          onChange={(e) => form.setFieldValue('cmt_mod', e.value)}
          disabled={!edicaoHabilitada}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 sm:col-2 md:col-2">
        <NumberInput
          placeholder="Lotação máxima"
          value={form.values.lotmax_mod}
          name="lotmax_mod"
          label="Lotação máxima"
          onChange={(e) => form.setFieldValue('lotmax_mod', e.value)}
          disabled={!edicaoHabilitada}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 sm:col-12 md:col-12">
        <TextAreaInput
          label="Observações da NF-e"
          placeholder="Observações da NF-e"
          name="obsnof_mod"
          className='inputfield w-full'
          value={form.values.obsnof_mod}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          autoResize
        />
      </div>
      <div className="field col-12 sm:col-6 md:col-6">
        <TextInput
          label="Inserido por"
          placeholder="Inserido por"
          name="nomraz_pes"
          value={form.values.usuarioInsercao?.nomraz_pes}
          disabled="disabled"
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 sm:col-6 md:col-6">
        <DateTimeInput
          label="Inserido em"
          placeholder="Inserido em"
          name="dahins_mod"
          className='inputfield w-full'
          value={form.values.dahins_mod}
          onChange={form.handleChange}
          disabled="disabled"
        />
      </div>
      <div className="field col-12 sm:col-6 md:col-6">
        <TextInput
          label="Alterado por"
          placeholder="Alterado por"
          name="nomraz_pes"
          value={form.values.usuarioAlteracao?.nomraz_pes}
          disabled="disabled"
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 sm:col-6 md:col-6">
        <DateTimeInput
          label="Alterado em"
          placeholder="Alterado em"
          className='inputfield w-full'
          name="dahalt_mod"
          value={form.values.dahalt_mod}
          onChange={form.handleChange}
          disabled="disabled"
        />
      </div>
    </div>
  )
}

export default DadosAdicionaisMod
