import React, { useState } from 'react'
import AppButton from '../../../../components/layout/AppButton'
import DataListSimples from '../../../../components/layout/DataListSimples/DataListSimples'
import CommonHelper from '../../../../helpers/CommonHelper'
import { formatToCurrency } from '../../../../helpers/formaters'
import { createColumns } from '../../../../helpers/tableConfigs'
import PagamentoManutencaoModal from '../PagamentoManutencaoModal'

function PagamentosMan ({ form, edicaoHabilitada }) {
  const [pagamentoManutencaoSelecionado, setPagamentoManutencaoSelecionado] = useState(null)
  const [visiblePagamentoManutencaoModal, setVisiblePagamentoManutencaoModal] = useState(false)

  const columns = createColumns([
    {
      field: 'ordem_tma',
      header: 'Parcela',
      width: '22%',
      headerClassName: 'pagamentosMan',
      sortable: false
    },
    {
      field: 'datven_tma',
      header: 'Vencimento',
      width: '22%',
      headerClassName: 'pagamentosMan',
      customBody: formatDataVencimento,
      sortable: false
    },
    {
      field: 'valor_tma',
      header: 'Valor',
      width: '22%',
      headerClassName: 'pagamentosMan',
      customBody: formatValor,
      sortable: false
    },
    {
      field: 'descri_foc',
      header: 'Forma de cobrança',
      width: '34%',
      headerClassName: 'pagamentosMan',
      sortable: false
    }
  ])

  function formatDataVencimento (data) {
    return CommonHelper.dateToBrazilian(data.datven_tma)
  }

  function formatValor (data) {
    return formatToCurrency(data.valor_tma)
  }

  return (
    <div className="formgrid grid fluid">
      <div className="field col-12 flex justify-content-end">
        <AppButton
          label="Adicionar"
          icon="pi pi-plus"
          onClick={() => setVisiblePagamentoManutencaoModal(true)}
          disabled={!edicaoHabilitada}
        />
      </div>
      <div className="field col-12">
        <DataListSimples
          value={form.values.titulosManutencao}
          columns={columns}
          columnsClassName="pagamentosMan"
          totalizadores={[{ key: 'valor_tma', label: 'Valor total dos pagamentos', formatFunction: formatToCurrency }]}
          selected={pagamentoManutencaoSelecionado}
          onSelect={(e) => {
            setPagamentoManutencaoSelecionado(e.value)
            setVisiblePagamentoManutencaoModal(true)
          }}
        />
      </div>
      <PagamentoManutencaoModal
        visible={visiblePagamentoManutencaoModal}
        onHide={() => {
          setPagamentoManutencaoSelecionado(null)
          setVisiblePagamentoManutencaoModal(false)
        }}
        manutencaoForm={form}
        edicaoHabilitada={edicaoHabilitada}
        pagamentoManutencaoSelecionado={pagamentoManutencaoSelecionado}
      />
    </div>
  )
}

export default PagamentosMan
