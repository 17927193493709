import React, { Component } from 'react'
import { Panel } from 'primereact/panel'
import { Ripple } from 'primereact/ripple'

class PanelContainer extends Component {
  headerTemplate = (options, header) => {
    const toggleIcon = options.collapsed ? 'pi pi-angle-double-down' : 'pi pi-angle-double-up'
    const className = `${options.className} p-jc-start`
    const titleClassName = `${options.titleClassName} p-pl-1`

    return (
      <div className={className}>
        <span className={titleClassName}>
          {header}
        </span>
        <button type="button" className={options.togglerClassName} onClick={options.onTogglerClick}>
          <span className={toggleIcon}></span>
          <Ripple />
        </button>
      </div>
    )
  }

  render () {
    return (
      <Panel
        {...this.props}
        collapsed={this.props.collapsed || false}
        toggleable={this.props.toggleable === false ? false : true}
        headerTemplate={this.props.headerTemplate || ((options) => this.headerTemplate(options, this.props.header))}
      >
        {this.props.children}
      </Panel>
    )
  }
}

export default PanelContainer
