import { Button } from 'primereact/button'
import { ProgressSpinner } from 'primereact/progressspinner'
import React, { Component } from 'react'
import { login, logout, setCompany } from '../../helpers/auth'
import { setEmpresaLogada, startLoading } from '../../redux/actions'
import { dispatch, getStore } from '../../redux/store'
import EmpresasService from '../../services/cadastro/pessoa/EmpresasService'
import UsuarioService from '../../services/cadastro/pessoa/UsuarioService'
import { showErrorMessage } from '../utils/Message'
import TrocarEmpresaModal from '../utils/TrocarEmpresaModal'

export class AppProfile extends Component {
  constructor () {
    super()
    this.state = {
      empresa: null,
      visibleTrocarEmpresaModal: false,
      visibleTrocarEmpresaModal: false,
      selectedCompany: {},
      companies: []
    }
  }

  async componentDidMount () {
    try {
      const empresasAcessoUsuario = await UsuarioService.getEmpresasAcessoUsuarioLogado()
      const companies = empresasAcessoUsuario.filter(company => company.codigo_pes !== getStore().empresaLogada.codigo_emp)
      this.setState({ empresa: getStore().empresaLogada?.pessoa, companies })
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar as empresas que o usuario logado possui acesso!')
    }
  }

  async handleTrocarEmpresa () {
    const { selectedCompany } = this.state
    try {
      if (selectedCompany.codigo_pes) {
        const token = await EmpresasService.trocarEmpresa(selectedCompany.codigo_pes)
        logout()
        login({ token, credentials: undefined })
        dispatch(startLoading())
        this.setState({ visibleTrocarEmpresaModal: false })
        setCompany({ codigo_pes: selectedCompany.codigo_pes, apelid_pes: selectedCompany.apelid_pes })
        dispatch(setEmpresaLogada({}))
      }
    } catch (error) {
      showErrorMessage(error.message || 'Erro ao tentar trocar de empresa!')
    }
  }

  render () {
    const { empresa } = this.state

    return empresa ? (
      <div className="layout-profile">
        <div className="layout-profile-link">
          <div className="p-grid">
            <div className="col-12 company">{empresa.apelid_pes}</div>
            <div className="p-col-12">
              {empresa.end_pes ? (`${empresa.end_pes}${empresa.numend_pes ? (', ' + empresa.numend_pes) : ''}`) : ''}
            </div>
            <div className="p-col-12">
              {empresa.cidade_pes ? (`${empresa.cidade_pes}${empresa.estado?.descri_uf ? (`, ${empresa.estado?.descri_uf}-${empresa.uf_pes}`) : ''}`) : ''}
            </div>
          </div>
          <div>
            <Button
              className="p-button-secondary mt-3"
              label="Trocar empresa"
              icon="pi pi-sort-alt"
              onClick={() => this.setState({ visibleTrocarEmpresaModal: true })}
            />
          </div>
        </div>
        <TrocarEmpresaModal
          visible={this.state.visibleTrocarEmpresaModal}
          handleSelectCompany={(company) => this.setState({ selectedCompany: company })}
          onHide={() => this.setState({ visibleTrocarEmpresaModal: false })}
          selectedCompany={this.state.selectedCompany}
          companies={this.state.companies}
          onConfirm={() => this.handleTrocarEmpresa()}
        />
      </div>
    ) : (
      <div className="flex justify-content-center">
        <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
      </div>
    )
  }
}
