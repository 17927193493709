import React from 'react'
import Container from '../../components/layout/Container'
import Page from '../../components/layout/Page'
import { Crud } from '../../classes/Crud'
import { IntegracaoModel } from '../../models/integracao/IntegracaoModel'
import IntegracoesService from '../../services/Integracao/IntegracoesService'
import IntegracaoForm from '../../forms/integracao/integracao/IntegracaoForm'

export class Integracao extends Crud {
  constructor (props) {
    super(props, IntegracaoModel, IntegracoesService, 'integracoes', 'codigo_cor')
  }

  render () {
    const { model, dataLoaded, isNew } = this.state
    return (
      <Page>
        <Container>
          {(dataLoaded || isNew) && (
            <IntegracaoForm
              isNew={isNew}
              model={model}
              onSave={this.onSave}
              onChange={this.onChange}
              onClose={this.onClose}
            />
          )}
        </Container>
      </Page>
    )
  }
}
