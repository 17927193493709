import { AutoComplete } from 'primereact/autocomplete'
import { classNames } from 'primereact/utils'
import React from 'react'
import AppButton from '../../layout/AppButton'
import FiltroModal from '../../utils/FiltroModal'
import RegisterModal from '../../utils/RegisterModal'
import InputBase from '../base/InputBase'

class SearchClearInput extends InputBase {
  constructor(props) {
    super(props)

    this.state = {
      visibleFiltro: false
    }
  }

  render() {
    const { props } = this

    const isCampoObrigatorio = Object.getOwnPropertyNames(props).includes('isFormFieldValid')

    const classNameLabel = isCampoObrigatorio ? classNames({ 'p-error': props.isFormFieldValid }, 'required') : ''
    return (
      <React.Fragment>
            <label className={classNameLabel}>{props.label}</label>
            <div className="p-inputgroup flex-1">
              <AutoComplete
                {...props}
                field={props.field}
                value={props.value}
                minLength={props.minLength || 3}
                suggestions={props.suggestions}
                completeMethod={props.completeMethod}
                onChange={props.onChange}
                onSelect={async (e) => props.onSelect(e)}
                itemTemplate={props.itemTemplate}
                placeholder={props.placeholder || 'Digite para pesquisar.'}
                disabled={props.disabledAutoComplete !== undefined ? props.disabledAutoComplete : props.disabled}
                className={props.className}
                onBlur={props.onBlur}
              />
              {/* botão limpar */}
              <AppButton
                type="button"
                className="p-inputgroup-buttons p-button-danger"
                icon="pi pi-times"
                onClick={props.onClear}
                disabled={props.disabled}
                onBlur={props.searchOnBlur}
              />
              <AppButton
                type="button"
                // className="p-inputgroup-buttons-last"
                icon="pi pi-search"
                onClick={props.onSearch}
                disabled={props.disabled}
                // onBlur={props.searchOnBlur}
              />
            </div>
            {props.children}
    
      </React.Fragment>
    )
  }
}

export default SearchClearInput
