import { classNames } from 'primereact/utils'
import React from 'react'

const RequiredLabel = ({ valid, label }) => {
  return (
    <label className={classNames({ 'p-error': valid }, 'label required')}>{label}</label>
  )
}

export default RequiredLabel
