import Axios from "axios"
import { dispatch, getStore } from '../redux/store'
import { decrementActiveLoadingRequests, incrementActiveLoadingRequests, startLoading, stopLoading } from '../redux/actions'

let baseUrlApiConectaCarros = ''
let bearerTokenApiConectaCarros

if (process.env.REACT_APP_STAGE === 'prod') {
    baseUrlApiConectaCarros = process.env.REACT_APP_BASE_URL_API_CONNECTA_CARROS_PROD
    bearerTokenApiConectaCarros = process.env.REACT_APP_TOKEN_API_CONNECTA_CARROS_PROD
} else {
    baseUrlApiConectaCarros = process.env.REACT_APP_BASE_URL_API_CONNECTA_CARROS_DEV
    bearerTokenApiConectaCarros = process.env.REACT_APP_TOKEN_API_CONNECTA_CARROS_DEV
}
export const API_URL = baseUrlApiConectaCarros + '/api/integracao/'

export function server(loading = true, type = 'json') {
    let connection = Axios.create({
        baseURL: API_URL,
        responseType: type,
        headers: {
            Authorization: 'Bearer ' + bearerTokenApiConectaCarros,
            LoadingState: loading
        }
    })

    connection.interceptors.request.use(successHttpRequest, errorHttpRequest)

    connection.interceptors.response.use(successHttpResponse, errorHttpResponse)

    return connection
}

function successHttpRequest(config) {
    processHttpRequest(config)
    return config
}

function errorHttpRequest(error) {
    processHttpRequest(error)
    return Promise.reject(error)
}

function processHttpRequest(config) {
    if (config.headers['LoadingState']) {
        dispatch(incrementActiveLoadingRequests())

        if (getStore().activeLoadingRequests > 0) {
            startLoadingIfRequestTakesTooLong()
        }
    }
}

function startLoadingIfRequestTakesTooLong() {
    setTimeout(() => {
        if (getStore().activeLoadingRequests > 0) {
            dispatch(startLoading())
        }
    }, 200)
}

function successHttpResponse(response) {
    processHttpResponse(response)
    return response
}

function errorHttpResponse(error) {
    processHttpResponse(error)
    return Promise.reject(error)
}

function processHttpResponse(response) {
    if (response.config.headers['LoadingState']) {
        dispatch(decrementActiveLoadingRequests())

        if (getStore().activeLoadingRequests === 0) {
            dispatch(stopLoading())
        }
    }
}