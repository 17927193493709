import BaseModel from '../../BaseModel'
import moment from 'moment'

export class NegociacaoModel extends BaseModel {
  constructor (params = {}) {
    super()
    this.codigo_neg = params.codigo_neg || null
    this.company_id = params.company_id || null
    this.dat_neg = params.dat_neg || moment().format('YYYY-MM-DD HH:mm:ss') || null
    this.status_neg = params.status_neg || ''
    this.descri_sne = params.descri_sne || ''
    this.obscon_neg = params.obscon_neg || ''
    this.documents = params.documents || []
    this.foPagNegs = params.foPagNegs || []
    this.usuins_neg = params.usuins_neg || ''
    this.pessoa = params.pessoa || {}
    this.vendedor = params.vendedor || {}
    this.tipomidia = params.tipomidia || {}
    this.codtra_neg = params.codtra_neg || ''
    this.obscon_neg = params.obscon_neg || ''
    this.modalidade = params.modalidade || { codigo_mov: 1 }
    this.vehiclesToBuy = params.vehiclesToBuy || []
    this.vehiclesToSell = params?.vehiclesToSell?.map(vehicle => ({
      ...vehicle,
      dahpreent_nve: vehicle.dahpreent_nve ? moment(vehicle.dahpreent_nve).format('YYYY/MM/DD HH:mm') : null
    })) || []
    this.usuarioInseriu = params.usuarioInseriu || {}
    this.usuarioAprovou = params.usuarioAprovou || {}
    this.dahapr_neg = params.dahapr_neg || null
    this.datfin_neg = params.datfin_neg || null
    this.representantePJ = params.representantePJ || {}
    this.obs_neg = params.obs_neg || ''
  }

  toMap () {
    return {
      codigo_neg: this.codigo_neg,
      dat_neg: moment(this.dat_neg).format('YYYY-MM-DD HH:mm:ss') || moment().format('YYYY-MM-DD HH:mm:ss') || null,
      status_neg: this.status_neg || 1,
      codven_neg: this.vendedor.codigo_ven || '',
      codpes_neg: this.pessoa.codigo_pes || this.pessoa.codigo || '',
      obscon_neg: this.obscon_neg || '',
      codmov_neg: this.modalidade.codigo_mov || 1,
      codtim_neg: this.tipomidia.codigo_tim || null,
      vehiclesToSell: this.vehiclesToSell || [],
      vehiclesToBuy: this.vehiclesToBuy || [],
      documents: this.documents || [],
      foPagNegs: this.foPagNegs || [],
      codrep_neg: this.representantePJ.codigo_pef || this.representantePJ.codigo_pes || null,
      obs_neg: this.obs_neg || ''
    }
  }
}
