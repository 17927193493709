import { useCallback, useEffect, useState } from 'react'
import PlacaInput from '../../../../components/inputs/PlacaInput'
import SelectButtons from '../../../../components/inputs/SelectButton'
import SelectInput from '../../../../components/inputs/SelectInput'
import { showErrorMessage, showWarnMessage } from '../../../../components/utils/Message'
import Modal from '../../../../components/utils/Modal'
import ConectaCarrosService from '../../../../services/Integracao/conectaCarros/ConectaCarrosService'

function SelecionarModelosReferenciaModal ({ visible, onHide, avaliacao, veiculo, onSaveVeiculo = () => {} }) {
  const listaTipoVeiculo = [{ codigo_tiv: 1, descri_tiv: 'Carro' }, { codigo_tiv: 2, descri_tiv: 'Moto' }]
  const [listaAnoModelo, setListaAnoModelo] = useState([])
  const [listaMarca, setListaMarca] = useState([])
  const [listaModelo, setListaModelo] = useState([])
  const [listaVersao, setListaVersao] = useState([])

  const [tipoVeiculo, setTipoVeiculo] = useState(null)
  const [anoModelo, setAnoModelo] = useState(null)
  const [marca, setMarca] = useState('')
  const [modelo, setModelo] = useState('')
  const [versao, setVersao] = useState('')
  const [arrayDescricoes, setArrayDescricoes] = useState([])
  const [objetoReferencia, setObjetoReferencia] = useState({})
  const [dadosPlaca, setDadosPlaca] = useState(null)
  const [prefixos] = useState(['tivcoc', 'codcoc', 'descoc', 'desfip', 'anomod', 'fipcoc', 'valfip', 'datfip', 'placa'])

  useEffect(async () => {
    if (visible) {
      onInit()
    }
  }, [visible])

  async function onInit () {
    anosDesde1920()
    if (avaliacao) {
      criarObjetoReferencia(avaliacao)
    } else if (veiculo) {
      criarObjetoReferencia(veiculo)
    }
  }

  function criarObjetoReferencia (objeto) {
    if (!objeto) return {}

    const objetoReferencia = {}
    const sufixo = objeto.hasOwnProperty('codcoc_vei') ? 'vei' : 'ava'
    prefixos.forEach(prefixo => {
      const chave = prefixo + '_' + sufixo
      if (objeto.hasOwnProperty(chave)) {
        objetoReferencia[prefixo] = objeto[chave]
      }
    })
    setObjetoReferencia(objetoReferencia)
    const placa = 'placa' + '_' + sufixo
    if (!objeto[placa]) {
      showWarnMessage(`Informe a placa n${sufixo == 'vei' ? 'o cadastro de' : 'a avaliação do'} veículo!`)
    }
  }

  useEffect(async () => {
    // Prioridade ao abrir o modal:
    // 1 - código/descrição do conecta carros
    // 2 - placa
    // ou então abre tudo em branco para o usuário preencher
    if (objetoReferencia?.codcoc && objetoReferencia?.descoc) {
      preencherCombosComDescricao()
    }
    if (!objetoReferencia?.codcoc && objetoReferencia?.placa) {
      await getDadosPlaca()
    }
    if (!objetoReferencia?.codcoc && !objetoReferencia?.placa) {
      limpaListas()
    }
  }, [objetoReferencia.placa, objetoReferencia.codcoc])

  async function preencherCombosComDescricao () {
    setDadosPlaca(null)
    const descricoes = objetoReferencia.descoc.split('/')
    setArrayDescricoes(descricoes)
    const tiv = listaTipoVeiculo.find(tipo => tipo.codigo_tiv == objetoReferencia.tivcoc)
    setTipoVeiculo(tiv)
    setAnoModelo(objetoReferencia?.anomod)
  }

  function limpaListas () {
    setArrayDescricoes([])
    setTipoVeiculo(null)
    setAnoModelo(null)
    setMarca('')
    setModelo('')
    setVersao('')
    setListaModelo([])
    setListaVersao([])
  }

  function preencherObjetoRetorno (objetoOriginal, objetoReferencia) {
    if (!objetoOriginal || !objetoReferencia) return objetoOriginal

    const sufixo = objetoOriginal.hasOwnProperty('codcoc_vei') ? 'vei' : 'ava'
    prefixos.forEach(prefixo => {
      const chaveOriginal = prefixo + '_' + sufixo
      if (objetoReferencia.hasOwnProperty(prefixo)) {
        objetoOriginal[chaveOriginal] = objetoReferencia[prefixo]
      }
    })
    return objetoOriginal
  }

  useEffect(() => {
    const fetchData = async () => {
      setMarca('')
      setModelo('')
      setVersao('')
      setListaMarca([])
      setListaModelo([])
      setListaVersao([])

      if (tipoVeiculo && anoModelo) {
        await getListaMarca()
      }
    }
    fetchData()
  }, [tipoVeiculo, anoModelo])

  useEffect(() => {
    const descricaoMarca = arrayDescricoes[0] || avaliacao?.modelo?.marca?.descri_mar || veiculo?.modelo?.marca?.descri_mar
    if (descricaoMarca) {
      const marca = listaMarca.find(m => m.descricao_marca == descricaoMarca.trim())
      setMarca(marca)
    }
  }, [listaMarca])

  useEffect(() => {
    if (dadosPlaca && listaMarca.length > 0) {
      if (dadosPlaca?.codigo_marca) {
        const mar = listaMarca.find(m => m.codigo_marca == dadosPlaca.codigo_marca)
        setMarca(mar)
      }
    }
  }, [dadosPlaca, listaMarca])

  useEffect(() => {
    const fetchData = async () => {
      setModelo('')
      setVersao('')
      setListaModelo([])
      setListaVersao([])

      if (tipoVeiculo && anoModelo && marca) {
        await getListaModelo()
      }
    }
    fetchData()
  }, [marca])

  useEffect(() => {
    if (arrayDescricoes[1] && listaModelo?.length > 0) {
      const modelo = listaModelo.find(m => m.descricao_modelo == arrayDescricoes[1].trim())
      setModelo(modelo)
      if (tipoVeiculo?.codigo_tiv == 2) {
        setArrayDescricoes([])
      }
    }
  }, [listaModelo])

  useEffect(() => {
    if (dadosPlaca && listaModelo.length > 0) {
      if (dadosPlaca?.codigo_modelo) {
        const mod = listaModelo.find(m => m.codigo_modelo == dadosPlaca.codigo_modelo)
        setModelo(mod)
      }
    }
  }, [dadosPlaca, listaModelo])

  useEffect(() => {
    const fetchData = async () => {
      setVersao('')
      setListaVersao([])

      if (tipoVeiculo?.codigo_tiv === 1 && anoModelo && marca && modelo) {
        await getListaVersao()
      }
      if (tipoVeiculo?.codigo_tiv === 2 && modelo?.codigo_anomodelo_fipe) {
        await atualizarPrecoFipe(modelo.codigo_anomodelo_fipe)
      }
    }
    fetchData()
  }, [modelo])

  useEffect(() => {
    const fetchData = async () => {
      if (versao && versao?.codigo_anomodelo_fipe && tipoVeiculo?.codigo_tiv == 1) {
        await atualizarPrecoFipe(versao.codigo_anomodelo_fipe)
      }
    }
    fetchData()
  }, [versao])

  useEffect(() => {
    if (arrayDescricoes[2] && listaVersao?.length > 0) {
      const versao = listaVersao.find(m => m.descricao_versao == arrayDescricoes[2].trim())
      setVersao(versao)
      if (tipoVeiculo?.codigo_tiv == 1) {
        setArrayDescricoes([])
      }
    }
  }, [listaVersao])

  const atualizarPrecoFipe = useCallback(async (codigoFipeConectaCarros) => {
    if (!codigoFipeConectaCarros) return

    try {
      const valorFipeAtual = await ConectaCarrosService.getValorFipeAtual(codigoFipeConectaCarros)
      if (valorFipeAtual?.data) {
        setObjetoReferencia(prevState => {
          if (
            prevState.datfip !== valorFipeAtual.data.data_ultima_alteracao ||
            prevState.valfip !== valorFipeAtual.data.valor_fipe
          ) {
            return {
              ...prevState,
              datfip: valorFipeAtual.data.data_ultima_alteracao,
              valfip: valorFipeAtual.data.valor_fipe
            }
          }
          return prevState
        })
      }
    } catch (error) {
      showErrorMessage(error.message)
    }
  }, [])

  // Função para criar um array de objetos com chaves representando anos de 1920 até o ano atual
  function anosDesde1920 () {
    const anoAtual = new Date().getFullYear()
    const anos = []
    for (let ano = anoAtual; ano >= 1920; ano--) {
      anos.push({ value: ano, label: ano })
    }
    setListaAnoModelo(anos)
  }

  async function getDadosPlaca () {
    try {
      showWarnMessage('Buscando dados do veículo...')
      const dadosPlaca = await ConectaCarrosService.getDadosPlaca(objetoReferencia?.placa)

      if (dadosPlaca.data) {
        if (dadosPlaca.data.codigo_tipoveiculo) {
          const tiv = listaTipoVeiculo.find(t => t.codigo_tiv == dadosPlaca.data.codigo_tipoveiculo)
          await setTipoVeiculo(tiv)
        }
        if (dadosPlaca.data.ano_modelo) {
          await setAnoModelo(dadosPlaca.data.ano_modelo)
        }
        setDadosPlaca(dadosPlaca.data)
      }
    } catch (error) {
      showErrorMessage(error.message)
    }
  }

  async function getListaMarca () {
    try {
      const marcas = await ConectaCarrosService.getMarcas(tipoVeiculo?.codigo_tiv, anoModelo)
      setListaMarca(marcas?.data)
    } catch (error) {
      showErrorMessage(error.message)
    }
  }

  async function getListaModelo () {
    try {
      const modelos = await ConectaCarrosService.getModelos(marca?.codigo_marca, anoModelo)
      setListaModelo(modelos?.data)
    } catch (error) {
      showErrorMessage(error.message)
    }
  }

  async function getListaVersao () {
    try {
      const versoes = await ConectaCarrosService.getVersoes(marca?.codigo_marca, modelo?.codigo_modelo, anoModelo)
      setListaVersao(versoes?.data)
    } catch (error) {
      showErrorMessage(error.message)
    }
  }

  const onConfirm = () => {
    try {
      if (!tipoVeiculo) {
        showErrorMessage('Selecione um tipo de veículo!')
        return false
      }
      if (!anoModelo) {
        showErrorMessage('Selecione um ano de modelo!')
        return false
      }
      if (!marca) {
        showErrorMessage('Selecione uma marca!')
        return false
      }
      if (!modelo) {
        showErrorMessage('Selecione um modelo!')
        return false
      }
      if (tipoVeiculo.codigo_tiv == 1 && !versao) {
        showErrorMessage('Selecione uma versão!')
        return false
      }

      objetoReferencia.tivcoc = tipoVeiculo.codigo_tiv
      if (tipoVeiculo.codigo_tiv == 1) {
        objetoReferencia.codcoc = versao.codigo_anoversao
        objetoReferencia.descoc = marca.descricao_marca + '/' + modelo.descricao_modelo + '/' + versao?.descricao_versao
        objetoReferencia.desfip = versao.descricao_modelo_fipe
        objetoReferencia.anomod = versao.ano_versao
        objetoReferencia.fipcoc = versao.codigo_anomodelo_fipe
      } else {
        objetoReferencia.codcoc = modelo.codigo_anomodelo
        objetoReferencia.descoc = marca.descricao_marca + '/' + modelo.descricao_modelo
        objetoReferencia.desfip = modelo.descricao_modelo_fipe
        objetoReferencia.anomod = modelo.ano_modelo
        objetoReferencia.fipcoc = modelo.codigo_anomodelo_fipe
      }
      preencherObjetoRetorno(avaliacao || veiculo, objetoReferencia)
      onSaveVeiculo(preencherObjetoRetorno(avaliacao || veiculo, objetoReferencia))
      onHide()
    } catch (error) {
      showErrorMessage(error.message)
    }
  }

  const onCancel = () => {
    onHide()
  }

  return (
    <Modal
      visible={visible}
      onHide={onHide}
      header="Selecionar modelo de referência ConectaCarros"
      width={40}
      btnSalvar={() => onConfirm()}
      btnSalvarLabel={'Confirmar'}
      btnExcluir={() => onCancel()}
      btnExcluirLabel={'Cancelar'}
      className="mb-6"
    >
      <div className="formgrid fluid mb-6 ">
        <div className="field col-12 mb-0">
          <SelectButtons className='SelectButtons'
            options={listaTipoVeiculo}
            disabled={false}
            value={tipoVeiculo}
            onChange={(e) => {
              if (e.value !== null) {
                setTipoVeiculo(e.value)
              }
            }}
            optionLabel='descri_tiv'
            dataKey="codigo_tiv"
          />
        </div>
        <div className="field col-12 md:col-4 mb-0">
          <PlacaInput
            name="placa"
            className={'inputfield w-full'}
            value={avaliacao?.placa_ava || veiculo?.placa_vei}
            //onChange={async (value) => await handleChangePlaca(value)}
            onChange={() => { }}
            disabled={true}
            placeholder="Placa"

          />
        </div>

        {/* <div className="field col-12 mb-0">
          <SelectInput
            label="Tipo de veículo"
            dataKey="codigo_tiv"
            optionLabel="descri_tiv"
            value={tipoVeiculo}
            options={listaTipoVeiculo}
            onChange={(e) => setTipoVeiculo(e.target.value)}
            showOnFocus={true}
          />
        </div> */}

        <div className="field col-12 mb-0">
          <SelectInput
            label='Ano do modelo'
            value={anoModelo}
            options={listaAnoModelo}
            onChange={(e) => setAnoModelo(e.target.value)}
            name="anoModelo"
            optionLabel="label"
            dataKey="value"
            placeholder="- Selecione o ano do modelo -"
            noFloatLabel
            className="inputfield w-full"
            showClear={true}
            filter={true}
            resetFilterOnHide={true}
            showFilterClear={true}
          />
        </div>
        {tipoVeiculo && anoModelo && (
          <div className="field col-12 mb-0">
            <SelectInput
              label='Marca'
              value={marca}
              options={listaMarca}
              onChange={(e) => setMarca(e.target.value)}
              name="marca"
              optionLabel="descricao_marca"
              dataKey="codigo_marca"
              placeholder="- Selecione a marca -"
              noFloatLabel
              className="inputfield w-full"
              showClear={true}
              filter={true}
              resetFilterOnHide={true}
              showFilterClear={true}
            />
          </div>
        )}
        {tipoVeiculo && anoModelo && marca && (
          <div className="field col-12 mb-0">
            <SelectInput
              label='Modelo'
              value={modelo}
              options={listaModelo}
              onChange={(e) => setModelo(e.target.value)}
              name="modelo"
              optionLabel="descricao_modelo"
              dataKey="codigo_modelo"
              placeholder="- Selecione o modelo -"
              noFloatLabel
              className="inputfield w-full"
              showClear={true}
              filter={true}
              resetFilterOnHide={true}
              showFilterClear={true}
            />
          </div>
        )}
        {tipoVeiculo?.codigo_tiv == 1 && anoModelo && marca && modelo && (
          <div className="field col-12 mb-0">
            <SelectInput
              label='Versão'
              value={versao}
              options={listaVersao}
              onChange={(e) => setVersao(e.target.value)}
              name="marca"
              optionLabel="descricao_versao"
              dataKey="codigo_anoversao"
              placeholder="- Selecione a versão -"
              noFloatLabel
              className="inputfield w-full"
              showClear={true}
              filter={true}
              resetFilterOnHide={true}
              showFilterClear={true}
            />
          </div>
        )}
      </div>
    </Modal>
  )
}

export default SelecionarModelosReferenciaModal
