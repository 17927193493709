import React from 'react'
import { Crud } from '../../../../classes/NewCrud'
import { getMarcaDTO } from '../../../../dtos/cadastro/veiculo/MarcaDTO'
import MarcaForm from '../../../../forms/cadastro/veiculo/marca/MarcaForm'
import MarcaService from '../../../../services/cadastro/veiculo/MarcaService'

export default function Marca (props) {
  return (
    <Crud
      {...props}
      formContent={MarcaForm}
      modelGetDTO={getMarcaDTO}
      service={MarcaService}
      resource="marcas"
      primarykeyname="codigo_mar"
      formTitle="Marca"
    />
  )
}
