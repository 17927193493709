import React from 'react'
import { Crud } from '../../../../classes/_Crud'
import Container from '../../../../components/layout/Container'
import Page from '../../../../components/layout/Page'
import { getAcessorioDTO } from '../../../../dtos/cadastro/veiculo/AcessorioDTO'
import AcessorioForm from '../../../../forms/cadastro/veiculo/acessorio/AcessorioForm'
import AcessorioService from '../../../../services/cadastro/veiculo/AcessorioService'

export class Acessorio extends Crud {
  constructor (props) {
    super(props, getAcessorioDTO, AcessorioService, 'acessorios')
  }

  render () {
    const { model, dataLoaded, isNew } = this.state

    return (
      <Page>
        <Container>
          {(dataLoaded || isNew) &&
            <AcessorioForm
              model={model}
              isNew={isNew}
              resource={this.resource}
            />
          }
        </Container>
      </Page>
    )
  }
}
