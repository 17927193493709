import React from 'react'
import { RadioButton } from 'primereact/radiobutton'

function AppRadioButton (props) {
  return (
    <div className="field-radiobutton">
      <RadioButton
        name={props.name}
        value={props.value}
        onChange={(e) => props.onChange(e)}
        checked={props.checked}
        disabled={props.disabled}
        className={props.className}
      />
      <label htmlFor={props.name}>{props.label}</label>
    </div>
  )
}

export default AppRadioButton
