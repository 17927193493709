import { Button } from 'primereact/button'
import React, { Component } from 'react'
import Modal from '../Modal'
import { stringify } from 'query-string'
import { showErrorMessage } from '../Message'
import { getFilterConfig, setFilterConfig } from '../../../helpers/FilterConfigs'

class ReportFiltroModalBase extends Component {
  constructor (props, reportService) {
    super(props)

    this.reportService = new reportService()

    this.state = {
      ...this.state,
      lastQueryFiltersUsed: '',
      legendaConfigs: [],
      formInitialValues: null
    }

    this.filters = {}
  }

  componentDidUpdate (_prevProps, prevState) {
    if (this.isLastQueryFiltersUsedDifferentThanBefore(prevState)) {
      this.generateLegenda()
    }

    const filterConfig = getFilterConfig(this.props.reportName)

    if (this.doesFormNeedToBeInitialized()) {
      if (filterConfig) {
        this.props.setForm(filterConfig.form, this.getQueryByFilters.bind(this))
      } else {
        this.filterWithFormDefaultValues()
      }
    }
  }

  isLastQueryFiltersUsedDifferentThanBefore (prevState) {
    return prevState.lastQueryFiltersUsed !== this.state.lastQueryFiltersUsed
  }

  doesFormNeedToBeInitialized () {
    return Object.keys(this.props.form).length === 0 && this.state.formInitialValues
  }

  filterWithFormDefaultValues () {
    this.props.setForm(this.state.formInitialValues, this.handleFiltrar.bind(this))
  }

  generateLegenda () {
    const ultimosFiltrosUsados = this.getUltimosFiltrosUsados()

    const reportFiltroLegenda = []

    for (const legendaConfig of this.state.legendaConfigs) {
      const filtro = ultimosFiltrosUsados.find(filtro => filtro.includes(legendaConfig.filtroName))

      if (filtro) {
        const legenda = this.getLegendaByFiltro(filtro, legendaConfig)

        reportFiltroLegenda.push(legenda)
      }
    }

    this.props.setReportFiltroLegenda(reportFiltroLegenda)
  }

  getUltimosFiltrosUsados () {
    return this.state.lastQueryFiltersUsed.split('&')
  }

  getLegendaByFiltro (filtro, legendaConfig) {
    const { texto, formatFunction, onlyText } = legendaConfig

    let [textoDoFiltro, valorDoFiltro] = filtro.split('=')

    if (onlyText) {
      return `• ${texto}.`
    }

    textoDoFiltro = `• ${texto}: `
    valorDoFiltro = formatFunction ? formatFunction(valorDoFiltro) : valorDoFiltro

    return textoDoFiltro + valorDoFiltro
  }

  async handleFiltrar () {
    try {
      const props = this.props

      const queryFilters = this.getQueryByFilters()

      await this.reportService.filter(queryFilters).then(reportData => props.setReportData(reportData))

      props.onHide()
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar filtrar o relatório')
    }
  }

  setFilters () {
    this.filters = this.state.form
  }

  getQueryByFilters () {
    this.setFilters()

    const queryFilters = stringify(this.filters, { skipNull: true, skipEmptyString: true, encode: false })

    this.setState({ lastQueryFiltersUsed: queryFilters })

    const filterConfig = {
      form: this.props.form,
      queryFilters: queryFilters
    }

    setFilterConfig(this.props.reportName, filterConfig)

    return queryFilters
  }

  handleChange (changeEvent) {
    const { form, setForm } = this.props

    const input = changeEvent.target

    form[input.name] = input.type === 'checkbox' ? input.checked : input.value

    setForm(form)
  }

  setFormValue (valueName, value) {
    const { form, setForm } = this.props

    form[valueName] = value

    setForm(form)
  }

  handleEnterKeyDown (keyDownEvent) {
    if (keyDownEvent.key === 'Enter') {
      this.handleFiltrar()
    }
  }

  setFormInitialValues (formInitialValues) {
    this.setState({ formInitialValues })
  }

  setLegendaConfigs (legendaConfigs) {
    this.setState({ legendaConfigs })
  }

  filtroInputs () {
    return <></>
  }

  render () {
    const props = this.props

    return (
      <Modal
        header="Filtros"
        visible={props.visible}
        onHide={props.onHide}
        width={props.width || '65'}
        footer={
          <>
            <Button
              label="Voltar para o padrão"
              className="mt-2 mr-2"
              onClick={async () => this.filterWithFormDefaultValues()}
            />
            <Button
              label="Filtrar"
              className="mt-2"
              onClick={async () => await this.handleFiltrar()}
            />
          </>
        }
      >
        <div className="formgrid grid">
          {this.filtroInputs()}
        </div>
      </Modal>
    )
  }
}

export default ReportFiltroModalBase
