import { clearDigitsSpecialChars } from './parsers'
export const EMAIL_REGEX = '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,6}'

export function isValidEmail (email) {
  const emailRegex = RegExp(EMAIL_REGEX)

  return emailRegex.test(email)
}

export function isValidPhone (phone) {
  const regex13 = RegExp('^([0-9]{10,11})$')

  return regex13.test(phone)
}

export function isValidCpf (strCPF) {
  strCPF = clearDigitsSpecialChars(strCPF)
  var Soma
  var Resto
  Soma = 0

  let invalids = ['00000000000', '11111111111', '22222222222', '33333333333',
    '44444444444', '55555555555', '66666666666', '77777777777',
    '88888888888', '99999999999']
  if (invalids.indexOf(strCPF) > -1) return false

  for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i), 0) * (11 - i)
  Resto = (Soma * 10) % 11

  if ((Resto === 10) || (Resto === 11)) Resto = 0
  if (Resto !== parseInt(strCPF.substring(9, 10), 0)) return false

  Soma = 0
  for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i), 0) * (12 - i)
  Resto = (Soma * 10) % 11

  if ((Resto === 10) || (Resto === 11)) Resto = 0
  if (Resto !== parseInt(strCPF.substring(10, 11), 0)) return false
  return true
}

export function isValidCnpj (strCnpj) {
  if (strCnpj.length !== 14) return false

  const deniedList = [
    '00000000000000',
    '11111111111111',
    '22222222222222',
    '33333333333333',
    '44444444444444',
    '55555555555555',
    '66666666666666',
    '77777777777777',
    '88888888888888',
    '99999999999999'
  ]

  if (deniedList.indexOf(strCnpj) > -1) return false

  // Valida dígitos verificadores
  let tamanho = strCnpj.length - 2
  let numeros = strCnpj.substring(0, tamanho)
  let digitos = strCnpj.substring(tamanho)
  let soma = 0
  let pos = tamanho - 7

  for (let i = tamanho; i >= 1; i--) {
    soma += Number(numeros.charAt(tamanho - i)) * pos--
    if (pos < 2) pos = 9
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)

  if (resultado !== parseInt(digitos.charAt(0))) return false

  tamanho = tamanho + 1
  numeros = strCnpj.substring(0, tamanho)
  soma = 0
  pos = tamanho - 7

  for (let i = tamanho; i >= 1; i--) {
    soma += Number(numeros.charAt(tamanho - i)) * pos--
    if (pos < 2) pos = 9
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)

  if (resultado !== parseInt(digitos.charAt(1))) return false

  return true
};

export function isValidCep (cep) {
  cep = clearDigitsSpecialChars(cep)
  const regex = RegExp('^([0-9]{8})$')
  return regex.test(cep)
}

export function isNotEmpty (value) {
  if (!value) {
    return false
  }

  if (value.trim().length === 0) {
    return false
  }

  return true
}

export function getOperatingSystem () {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera

  if (/windows phone/i.test(userAgent)) {
    return null
  }

  if (/android/i.test(userAgent)) {
    return 'Android'
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS'
  }

  return null
}
