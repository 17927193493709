import ServiceBase from '../../base/ServiceBase'

export default class PermissaoService {
  async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`/permissao?page=${page}&perPage=${perPage}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`/permissao?${query}&page=${page}&perPage=${perPage}`)
  }

  static async getByCodigo (codigoPer) {
    return await ServiceBase.getBase(`/permissao/${codigoPer}`)
  }
}
