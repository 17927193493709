import { classNames } from 'primereact/utils'
import React, { useEffect, useState } from 'react'
import DateInput from '../../../../../../components/inputs/DateInput'
import SelectInput from '../../../../../../components/inputs/SelectInput'
import TextAreaInput from '../../../../../../components/inputs/TextAreaInput'
import TextInput from '../../../../../../components/inputs/TextInput'
import ResponsavelPagamento from '../../../../../../components/layout/ResponsavelPagamento/ResponsavelPagamento'
import FieldErrorMessage from '../../../../../../components/utils/FieldErrorMessage'
import Modal from '../../../../../../components/utils/Modal'
import RequiredLabel from '../../../../../../components/utils/RequiredLabel'
import { formatToCurrency } from '../../../../../../helpers/formaters'
import { useValidateInput } from '../../../../../../helpers/useValidateInput'
import FoPagNeg from '../_FoPagNeg'
import { FinanciamentoDiretoValidator } from './FinanciamentoDiretoValidator'

const FoPagFinanciamentoDireto = (props) => {
  const {
    form,
    calculateValorParcela,
    handleChangeCurrency,
    getFormaCobranca,
    getTiposRestricaoVeiculo,
    setRestricao,
    setFormaCobranca,
    setValorVeiculo,
    hide,
    dados,
    isLojaPaga,
    excluir,
    isNew
  } = props
  const [edicaoHabilitada, setEdicaoHabilitada] = useState(props.edicaoHabilitada)
  const deveSelecionarVeiculo = props.negociacaoForm.values.veiculosConjunto.length > 0

  useEffect(() => {
    const onLoad = async () => {
      form.setFieldValue('veiculo', props.veiculo)

      const formaCobrancaOptions = await getFormaCobranca()
      setFormaCobranca(dados.codfoc_fpn, formaCobrancaOptions)

      const restricaoOptions = await getTiposRestricaoVeiculo()
      setRestricao(dados.codtrv_fpn || 0, restricaoOptions)

      if (isNew) {
        form.setFieldValue('isLojaPaga', isLojaPaga)
      }
      if (isLojaPaga) {
        setEdicaoHabilitada(false)
      }
    }

    onLoad()
  }, [])

  useEffect(() => {
    calculateValorParcela()
  }, [form.values.valor, form.values.quantidadeParcelas])

  const handleSelect = async (e) => {
    await form.handleChange(e)
    await form.setFieldTouched(e.target.name)
  }

  const { getFormErrorMessage, isFormFieldValid } = useValidateInput(form)

  return (
    <Modal
      header="Financiamento direto com a loja"
      btnSalvar={edicaoHabilitada ? form.submitForm : undefined}
      btnExcluir={(!isNew && edicaoHabilitada) ? excluir : undefined}
      width="65"
      visible={true}
      modal={true}
      onHide={hide}
    >
      <div className="formgrid grid">
        <div className="field col-12 flex justify-content-around mb-4">
          <ResponsavelPagamento isLojaPaga={isNew ? isLojaPaga : form.values.isLojaPaga} />
        </div>
        {deveSelecionarVeiculo && (
          <div className="field col-12 md:col-3">
            <RequiredLabel label="Veículo" valid={isFormFieldValid('veiculo.codigo_vei')} />
            <SelectInput
              value={form.values.veiculo}
              options={props.negociacaoForm.values.veiculosConjunto.map(veiculoNve => veiculoNve.veiculo)}
              onChange={(e) => form.setFieldValue('veiculo', e.target.value)}
              name='veiculo'
              optionLabel="placha"
              dataKey="codigo_vei"
              disabled={!edicaoHabilitada}
              className={classNames({ 'p-invalid': isFormFieldValid('veiculo.codigo_vei') }, 'inputfield w-full')}
              noFloatLabel
            />
            <FieldErrorMessage message={getFormErrorMessage('veiculo.codigo_vei')} />
          </div>
        )}
        <div className={'field col-12 md:col-' + (deveSelecionarVeiculo ? '3' : '4')}>
          <RequiredLabel label="Valor" valid={isFormFieldValid('valor')} />
          <TextInput
            name="valor"
            value={formatToCurrency(form.values.valor)}
            disabled={!edicaoHabilitada}
            onChange={(e) => handleChangeCurrency(e)}
            onBlur={() => {
              setValorVeiculo()
              form.setFieldTouched('valor')
            }}
            className={classNames({ 'p-invalid': isFormFieldValid('valor') }, 'inputfield w-full')}
          />
          <FieldErrorMessage message={getFormErrorMessage('valor')} />
        </div>
        <div className={'field col-12 md:col-' + (deveSelecionarVeiculo ? '3' : '4')}>
          <RequiredLabel label="Quantidade de parcelas" valid={isFormFieldValid('quantidadeParcelas')} />
          <TextInput
            name="quantidadeParcelas"
            value={form.values.quantidadeParcelas}
            keyfilter="pint"
            disabled={!edicaoHabilitada}
            onChange={(e) => form.handleChange(e)}
            className={classNames(
              { 'p-invalid': isFormFieldValid('quantidadeParcelas') },
              'inputfield w-full'
            )}
            onBlur={() => form.setFieldTouched('quantidadeParcelas')}
          />
          <FieldErrorMessage message={getFormErrorMessage('quantidadeParcelas')} />
        </div>
        <div className={'field col-12 md:col-' + (deveSelecionarVeiculo ? '3' : '4')}>
          <RequiredLabel label="Valor da parcela" valid={isFormFieldValid('valorParcela')} />
          <TextInput
            name="valorParcela"
            value={formatToCurrency(form.values.valorParcela)}
            onChange={handleChangeCurrency}
            disabled={!edicaoHabilitada}
            className={classNames(
              { 'p-invalid': isFormFieldValid('valorParcela') },
              'inputfield w-full'
            )}
            onBlur={() => form.setFieldTouched('valorParcela')}
          />
          <FieldErrorMessage message={getFormErrorMessage('valorParcela')} />
        </div>

        <div className="field col-12 md:col-6">
          <RequiredLabel label="Data de Emissão" valid={isFormFieldValid('dataEmissao')} />
          <DateInput
            name="dataEmissao"
            value={form.values.dataEmissao}
            disabled={!edicaoHabilitada}
            onChange={form.handleChange}
            onBlur={ () =>
              form.setFieldTouched('dataEmissao')
            }
            className={classNames({ 'p-invalid': isFormFieldValid('dataEmissao') }, 'inputfield w-full')}
          />
          <FieldErrorMessage message={getFormErrorMessage('dataEmissao')} />
        </div>
        <div className="field col-12 md:col-6">
          <RequiredLabel label="Primeiro vencimento" valid={isFormFieldValid('primeiroVencimento')} />
          <DateInput
            name="primeiroVencimento"
            disabled={!edicaoHabilitada}
            value={form.values.primeiroVencimento}
            onChange={async (e) => {
              await form.handleChange(e)
              await form.validateField('primeiroVencimento')
            }}
            className={classNames({ 'p-invalid': isFormFieldValid('primeiroVencimento') }, 'inputfield w-full')}
            onBlur={() => form.setFieldTouched('primeiroVencimento')}
          />
          <FieldErrorMessage message={getFormErrorMessage('primeiroVencimento')} />
        </div>
        <div className="field col-12 md:col-6">
          <RequiredLabel label="Forma de cobrança" valid={isFormFieldValid('formaCobranca.codigo_foc')} />
          <SelectInput
            value={form.values.formaCobranca}
            options={form.values.formaCobrancaOptions}
            optionLabel="descri_foc"
            onChange={handleSelect}
            placeholder="- Selecione -"
            className={classNames(
              { 'p-invalid': isFormFieldValid('formaCobranca.codigo_foc') },
              'inputfield w-full'
            )}
            name="formaCobranca"
            noFloatLabel
            disabled={!edicaoHabilitada}
            onHide={() => form.setFieldTouched('formaCobranca.codigo_foc')}
          />
          <FieldErrorMessage message={getFormErrorMessage('formaCobranca.codigo_foc')} />
        </div>
        <div className="field col-12 md:col-6">
          <SelectInput
            label="Restrição"
            value={form.values.restricao}
            options={form.values.restricaoOptions}
            optionLabel="descri_trv"
            onChange={handleSelect}
            placeholder="- Selecione -"
            name="restricao"
            noFloatLabel
            disabled={!edicaoHabilitada}
            className="inputfield w-full"
          />
        </div>
        <div className="field col-12 md:col-12">
          <TextAreaInput
            name="observacoes"
            label="Observações"
            value={form.values.observacoes}
            disabled={!edicaoHabilitada}
            onChange={form.handleChange}
            className="inputfield w-full"
          />
        </div>
      </div>
    </Modal>
  )
}

export default (props) =>
  FoPagNeg({
    Component: FoPagFinanciamentoDireto,
    validationSchema: FinanciamentoDiretoValidator,
    ...props
  })
