import ServiceBase from '../base/ServiceBase'

export default class ConfigModulosService {
  static async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`/user-bd?page=${page}&perPage=${perPage}`)
  }

  static async filter (query, page, perPage) {
    return await ServiceBase.getBase(`/user-bd${query}?page=${page}&perPage=${perPage}`)
  }

  static async get (page, perPage) {
    return await ServiceBase.getBase(`/user-bd?page=${page}&perPage=${perPage}`)
  }

  static async updateModulos (codban, codmod) {
    return await ServiceBase.postBase('/user-bd/modulos', { codban, codmod })
  }
}
