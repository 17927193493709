import { setUserAsNotAutenticated } from '../redux/actions'
import { dispatch } from '../redux/store'

export function authToken () {
  return localStorage.getItem('accessToken')
}

export function credentials () {
  return localStorage.getItem('credentials')
}

export function login (data) {
  localStorage.setItem('accessToken', data.token)
  localStorage.setItem('credentials', data.credentials)
}

export function setCompany (company) {
  localStorage.setItem('company', JSON.stringify(company))
}

export function getCompany () {
  const company = localStorage.getItem('company')
  return JSON.parse(company)
}

export function logout () {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('credentials')
  localStorage.removeItem('company')
  sessionStorage.removeItem('sideBar')
  sessionStorage.removeItem('restricao12')
  sessionStorage.removeItem('codigoNeg')
  dispatch(setUserAsNotAutenticated())
  window.location.href = '/'
}

export function isLoggedIn () {
  const token = localStorage.getItem('accessToken')
  return !!token && token !== 'null'
}
