import React, { Component } from 'react'
import CommonHelper from '../../../helpers/CommonHelper'
import ReportLegenda from './ReportLegenda'

class ReportHtmlToPrint extends Component {
  componentDidUpdate () {
    const props = this.props
    const columns = document.getElementsByClassName('reportColumn')

    const areColumnsRendered = !!columns.length

    if (areColumnsRendered && !props.isReportColumnsWidthsAlreadySet) {
      const reportColumnsWidths = this.getReportColumnsWidths(columns)
      props.setReportColumnsWidths(reportColumnsWidths)
    }
  }

  getReportColumnsWidths (columns) {
    const props = this.props
    const reportColumnsWidths = []

    for (let columnIndex = 0; columnIndex < props.reportColumnsQuantity; columnIndex++) {
      const columnWidth = columns[columnIndex] ? columns[columnIndex].clientWidth : 0

      reportColumnsWidths.push(columnWidth)
    }

    return reportColumnsWidths
  }

  render () {
    const props = this.props
    console.log(props.reportFiltroLegenda)
    return (
      <div className="component-to-print-lucro-de-vendas-report">
        <div className="field mt-4">
          <h3 className="pl-2 no-margin flex align-items-center report-title">{props.reportTitle.toUpperCase()}</h3>
        </div>
        <div className={'legendas-container justify-content-end ' + (CommonHelper.isDesktop() ? 'flex' : 'flex-column')}>
          <ReportLegenda legendas={props.legendaReport} legendaTitle="Legenda do report."/>
          <ReportLegenda legendas={props.reportFiltroLegenda} legendaTitle="Legenda dos filtros aplicados."/>
        </div>
        {props.reportContent}
        {props.ReportFooterComponent && (
          <props.ReportFooterComponent reportData={props.reportData}/>
        )}
      </div>
    )
  }
}

export default ReportHtmlToPrint
