import * as Yup from 'yup'

export const DocumentoValidator = Yup.object().shape({
  valcus_ndo: Yup.number()
    .test('testeValcus_ndo', 'Deve ser informado o valor de custo!', function (value) {
      if (this.parent.obrigaCusto && value === 0) {
        return false
      }
      return true
    }),
  valor_ndo: Yup.number()
    .test('testevalor_ndo', 'Deve ser informado o valor cobrado!', function (value) {
      if (this.parent.respag_ndo === 1 && value === 0) {
        return false
      }
      return true
    }),
  veiculoNve: Yup.object().shape({
    codvei_nve: Yup.number()
      .nullable()
      .required('O veículo deve ser selecionado!')
  }),
  tipoDocumento: Yup.object().shape({
    codigo_tdo: Yup.number()
      .nullable()
      .required('O tipo de documento deve ser selecionado!')
  }),
  destinoPagar: Yup.object().shape({
    codigo_pes: Yup.number()
      .nullable()
      .required('O destinatário a pagar do documento deve ser selecionado!')
  }),
  destinoReceber: Yup.object()
    .nullable().test('Teste', 'O destinatário a receber do documento deve ser selecionado!', function () {
      if (this.parent.respag_ndo !== 1) {
        return true
      } else {
        if (this.parent.destinoReceber?.codigo_pes !== undefined) {
          return true
        } else {
          return false
        }
      }
    }),
  datpag_ndo: Yup.date()
    .nullable()
    .test('testeDataVencimento', 'A data de vencimento não foi informada!', function (dataVencimento) {
      if (this.parent.valor_ndo && !dataVencimento) {
        return false
      }

      return true
    })
})
