import ServiceBase from '../../base/ServiceBase'

export default class EmpresasService {
  async getAll (data) {
    return await ServiceBase.postBase('companies', data)
  }

  static async getAllEmpresas () {
    return await ServiceBase.getBase('companies/filter')
  }

  static async getEmpresaLogada () {
    return await ServiceBase.getBase('companies/logada')
  }

  static async trocarEmpresa (codigo_emp) {
    return (await ServiceBase.getBase(`companies/trocarEmpresa/${codigo_emp}`)).token
  }

  static async verificaFilial (codigo_emp) {
    return await ServiceBase.getBase(`empresa/verificaFilial/${codigo_emp}`)
  }
}
