import ServiceBase from '../../base/ServiceBase'

export default class ProdutoEmpresaService {
  static async get (codigo_pre) {
    return await ServiceBase.getBase(`/produtoempresa/${codigo_pre}`)
  }

  static async getByVeiculoId (codigo_vei, loading = true) {
    return await ServiceBase.getBase(`/produtoempresa/veiculo/${codigo_vei}`, loading)
  }

  /**
   * @param {number} codigo_vei Código do veículo
   * @param {{ preco_pre?: number; premin_pre?: number; preeco_pre?: number }} data
   */
  static async alterarPrecoVeiculo (codigo_vei, data) {
    await ServiceBase.putBase(`/produtoempresa/alterarpreco/veiculo/${codigo_vei}`, data)
  }
}
