import { Button } from 'primereact/button'
import React, { Component } from 'react'

class AppButton extends Component {
  constructor (props) {
    super(props)
  }

  render () {
    return (
      <>
        <Button {...this.props}>
          {this.props.children}
        </Button>
      </>
    )
  }
}

export default AppButton
