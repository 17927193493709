import { classNames } from 'primereact/utils'
import React, { useEffect, useState } from 'react'
import Modal from './Modal'
import OptionsModal from './OptionsModal'

export default function RegisterModal ({ isCampoObrigatorio, isFormFieldValid, selected, ComponenteCadastro, primarykeyname, onSaveModal, onDeleteModal, label, modalHeader }) {
  const [isInputObrigatorio, setIsInputObrigatorio] = useState(isCampoObrigatorio)
  const [visibleOptionsModal, setVisibleOptionsModal] = useState(false)
  const [visibleCadastroModal, setVisibleCadastroModal] = useState(false)
  const [visibleVisualizacaoModal, setVisibleVisualizacaoModal] = useState(false)

  useEffect(() => {
    if (isInputObrigatorio === undefined) {
      if (isFormFieldValid) {
        setIsInputObrigatorio(true)
      }
    }
  }, [])

  function getClassName () {
    return classNames({ 'p-error': isFormFieldValid }, 'cadastrar-register-modal')
  }

  function getComponentByCgccpf () {
    let tipoComponent

    if (selected.cgccpf_pes?.length > 11) {
      tipoComponent = 'JURÍDICA'
    } else {
      tipoComponent = 'FÍSICA'
    }

    const Component = (ComponenteCadastro.find(componenteCadastro => (componenteCadastro.label.toUpperCase()).includes(tipoComponent))).component
    const modalHeader = (ComponenteCadastro.find(componenteCadastro => (componenteCadastro.label.toUpperCase()).includes(tipoComponent))).header

    return (
      <Modal
        visible={visibleVisualizacaoModal}
        onHide={() => setVisibleVisualizacaoModal(false)}
        width="75"
        header={modalHeader}
      >
        <Component
          onSaveModal={onSaveModal}
          onDeleteModal={onDeleteModal}
          codigoSelecionado={selected ? selected[primarykeyname] : null}
          onHide={() => setVisibleVisualizacaoModal(false)}
        />
      </Modal>
    )
  }

  function getRegisterLabel () {
    const setVisibleModal = ComponenteCadastro.length === 1
      ? setVisibleCadastroModal
      : registroIsAlreadySelected()
        ? setVisibleVisualizacaoModal
        : setVisibleOptionsModal

    return (
      <>
        <a className={getClassName()} onClick={() => setVisibleModal(true)}>{label}</a>{isInputObrigatorio && <label className="required"></label>}
      </>
    )
  }

  function registroIsAlreadySelected () {
    return selected && Object.keys(selected).length > 1
  }

  return (
    <>
      {getRegisterLabel()}
      {ComponenteCadastro.length === 1 ? (
        <Modal
          visible={visibleCadastroModal}
          onHide={() => setVisibleCadastroModal(false)}
          width="75"
          header={modalHeader}
        >
          <ComponenteCadastro
            onSaveModal={onSaveModal}
            onDeleteModal={onDeleteModal}
            codigoSelecionado={selected ? selected[primarykeyname] : null}
            onHide={() => setVisibleCadastroModal(false)}
          />
        </Modal>
      ) : (
        <>
          {registroIsAlreadySelected() ? (
            <>
              {getComponentByCgccpf()}
            </>
          ) : (
            <>
              <OptionsModal
                visible={visibleOptionsModal}
                onHide={() => setVisibleOptionsModal(false)}
                options={ComponenteCadastro}
                onSaveModal={onSaveModal}
                onDeleteModal={onDeleteModal}
                codigoSelecionado={selected ? selected[primarykeyname] : null}
              />
            </>
          )}
        </>
      )}
    </>
  )
}
