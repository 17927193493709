import { SelectButton } from 'primereact/selectbutton'
import React, { Component } from 'react'

class SelectButtons extends Component {
  render () {
    return (
      <div className='field-radiobutton'>
        <SelectButton
          className='select-buttons'
          optionLabel={this.props.name}
          value={this.props.value || ''}
          onChange={this.props.onChange}
          options={this.props.options}
          disabled={this.props.disabled}
          {...this.props} />
      </div>
    )
  }
}

export default SelectButtons
