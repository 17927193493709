import React, { Component } from 'react'
import { InputText } from 'primereact/inputtext'
import InputMask from 'react-input-mask'

class PlacaInput extends Component {
  render () {
    const { camposObrigatorios, ...inputTextProps } = this.props
    return (
      <span>
        <label htmlFor={this.props.id}>
          {this.props.label || ''}
          {
            (camposObrigatorios || [])?.includes(this.props.name) &&
            <span style={{ color: '#F05C54' }}>*</span>
          }
        </label>
        <InputMask
          {...inputTextProps}
          mask="aaa-9*99"
          value={this.props.value || ''}
          onChange={(e) => this.props.onChange(e.target.value.toUpperCase())}
        >
          {inputProps => <InputText {...inputProps} disabled={this.props.disabled}/>}
        </InputMask>
      </span>
    )
  }
}

export default PlacaInput
