import * as Yup from 'yup'

export const ProcuradorValidator = Yup.object().shape({
  procurador: Yup.object().shape({
    codigo_pes: Yup.number()
      .nullable()
      .required('O procurador deve ser selecionado!')
  })
})

export const ProcuradorVeiculoValidator = Yup.object().shape({
  procurador: Yup.object().shape({
    codigo_pes: Yup.number()
      .nullable()
      .required('O procurador deve ser selecionado!')
  }),
  procuradorVeiculo: Yup.object().shape({
    codvei_nve: Yup.number()
      .nullable()
      .required('O veículo deve ser selecionado!')
  })
})
