import ServiceBase from '../../base/ServiceBase'

export default class DocumentosService {
  static async filterTiposDocumentos (query) {
    return await ServiceBase.getBase(`tipodocumentos?${query}`)
  }

  static async getTipoDocumento (codigo_tdo) {
    return await ServiceBase.getBase(`tipodocumentos/${codigo_tdo}`)
  }

  static async getCustosDaUltimaNegociacaoPorVeiculo (codigo_vei, loading = true) {
    return await ServiceBase.getBase(`documento/custos/veiculo/${codigo_vei}`, loading)
  }

  async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`tipodocumentos?page=${page}&perPage=${perPage}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`/tipodocumentos?${query}&page=${page}&perPage=${perPage}`)
  }
}
