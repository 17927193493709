
export const getConveniadoDTO = (data = {}) => {
  return {
    codigo_pes: data.codigo_pes || null,
    nomraz_pes: data.nomraz_pes || null,
    ativo_cvp: data?.ativo_cvp ?? true
  }
}

export const postConveniadoDTO = (data) => {
  return {
    codigo_pes: data.codigo_pes || null,
    nomraz_pes: data.nomraz_pes || null,
    ativo_cvp: data?.ativo_cvp || null
  }
}
