import moment from 'moment'
import { formatDateToAmerican } from '../../../../helpers/formaters'

export const getAvaliacaoDTO = (data = {}) => {

  return {
    codigo_ava: data.codigo_ava || null,
    codemp_ava: data.codemp_ava || null,
    placa_ava: data.placa_ava || null,
    km_ava: data.km_ava || null,
    valor_ava: data.valor_ava || 0.00,
    valfip_ava: data.valfip_ava || 0.00,
    totman_ava: data.totman_ava || 0.00,
    preven_ava: data.preven_ava || 0.00,
    codfoa_ava: data.codfoa_ava || null,
    codmod_ava: data.codmod_ava || null,
    codven_ava: data.codven_ava || null,
    anomod_ava: data.anomod_ava || null,
    observ_ava: data.observ_ava || '',
    dathor_ava: data.dathor_ava
      ? moment(new Date(data.dathor_ava)).format('DD/MM/YYYY HH:mm')
      : moment(new Date()).format('DD/MM/YYYY HH:mm'),
    datval_ava: data.datval_ava ? new Date(data.datval_ava) : null,
    formaAquisicao: data.formaAquisicao || {},
    modelo: data.modelo || {},
    vendedor: { ...data.vendedor, ...data.vendedor?.pessoa } || {},
    empresa: data.empresa || {},
    usuario: data.usuario || {},
    codfip_ava: data.codfip_ava || '',
    fipcoc_ava: data.fipcoc_ava || null,
    codcoc_ava: data.codcoc_ava || null,
    descoc_ava: data.descoc_ava || '',
    tivcoc_ava: data.tivcoc_ava || null,
    desfip_ava: data.desfip_ava || '',
    datfip_ava: data.datfip_ava ? formatDateToAmerican(data.datfip_ava) : null
  }
}

export const postAvaliacaoDTO = (data) => {
  return {
    codigo_ava: data.codigo_ava || null,
    codfoa_ava: data.formaAquisicao?.codigo_foa || null,
    placa_ava: data.placa_ava || '',
    anomod_ava: data.anomod_ava || null,
    km_ava: data.km_ava || null,
    codmod_ava: data.modelo?.codigo_mod || null,
    codven_ava: data.vendedor?.codigo_ven || null,
    valor_ava: data.valor_ava || 0.00,
    valfip_ava: data.valfip_ava || 0.00,
    totman_ava: data.totman_ava || 0.00,
    preven_ava: data.preven_ava || 0.00,
    observ_ava: data.observ_ava || '',
    datval_ava: data.datval_ava ? moment(data.datval_ava).format('YYYY-MM-DD') : null,
    codfip_ava: data.codfip_ava || '',    
    fipcoc_ava: data.fipcoc_ava || null,
    codcoc_ava: data.codcoc_ava || null,
    descoc_ava: data.descoc_ava || '',
    tivcoc_ava: data.tivcoc_ava || null,
    desfip_ava: data.desfip_ava || '',
    datfip_ava: data.datfip_ava ? formatDateToAmerican(data.datfip_ava) : null
  }
}
