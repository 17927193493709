import React from 'react'
import TextInput from '../../../components/inputs/TextInput'
import Modal from '../../../components/utils/Modal'
import AppButton from '../../../components/layout/AppButton'
import { useValidateInput } from '../../../helpers/useValidateInput'
import { MotivoCanValidator } from './MotivoCanValidator'
import FieldErrorMessage from '../../../components/utils/FieldErrorMessage'
import RequiredLabel from '../../../components/utils/RequiredLabel'

const MotivoCanModal = ({ cancelarNegociacao, form, hide, visible, motivoCancelamento, setMotivoCancelamento }) => {
  const onSave = async () => {
    form.setFieldValue('motcan_neg', motivoCancelamento)
    cancelarNegociacao()
    hide()
  }

  const { getFormErrorMessage, isFormFieldValid } = useValidateInput(form)

  return (
    <Modal
      header="Cancelamento da negociação"
      visible={visible}
      onHide={() => hide()}
      width="35"
      modal={true}
    >
      <div className="grid p-fluid">
        <div className="col-12">
          <RequiredLabel label="Motivo do cancelamento" valid={isFormFieldValid('motivoCancelamento')} />
          <TextInput
            name="motivoCancelamento"
            value={motivoCancelamento}
            onChange={(e) => setMotivoCancelamento(e.target.value)}
            onBlur={() => { form.setFieldTouched('motivoCancelamento') }}
          />
          <FieldErrorMessage message={getFormErrorMessage('motivoCancelamento')} />
        </div>
      </div>
      <div className="p-dialog-buttons">
        <AppButton
          type="button"
          className="btn-acoes"
          icon="pi pi-times-circle"
          label="Cancelar negociação"
          onClick={() => onSave()}
        />
      </div>
    </Modal>
  )
}

export default (props) =>
  MotivoCanModal({
    Component: MotivoCanModal,
    validationSchema: MotivoCanValidator,
    ...props
  })
