import React, { Component } from 'react'

class ResponsiveColumn extends Component {
  render () {
    return (
      <>
        <span className="p-column-title">{this.props.column}</span>
        <span className={this.props.className}>{this.props.value}</span>
        {this.props.children}
      </>
    )
  }
}

export default ResponsiveColumn
