import { postMarcaDTO } from '../../../dtos/cadastro/veiculo/MarcaDTO'
import ServiceBase from '../../base/ServiceBase'

export default class MarcaService {
  async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`/marcas?page=${page}&perPage=${perPage}`)
  }

  static async getMarcas () {
    return await ServiceBase.getBase('/marcas')
  }

  static async filterMarcas (query) {
    return await ServiceBase.getBase(`/marcas?descri_mar=${query}`)
  }

  static async filterSugestao (descricao) {
    return await ServiceBase.getBase(`/marcas?${descricao}&page=${1}&perPage=${1000}`)
  }

  async get (id) {
    return await ServiceBase.getBase(`/marcas/${id}`)
  }

  async save (data) {
    return await ServiceBase.postBase('/marcas', postMarcaDTO(data))
  }

  async getFormValidator () {
    return await ServiceBase.getBase('/marcas/validator')
  }

  async delete (codigo_mar) {
    return await ServiceBase.deleteBase(`/marcas/${codigo_mar}`)
  }

  static async get (id) {
    return await ServiceBase.getBase(`/marcas/${id}`)
  }

  async filter (query) {
    return await ServiceBase.getBase(`/marcas?${query}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`/marcas?${query}&page=${page}&perPage=${perPage}`)
  }
}
