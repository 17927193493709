import * as Yup from 'yup'

export const ReservaVeiculoValidator = Yup.object().shape({
  pessoa: Yup.object().shape({
    codigo_pes: Yup.number()
      .nullable()
      .required('Informe um cliente válido!')
  }),
  vendedor: Yup.object().shape({
    codigo_pes: Yup.number()
      .nullable()
      .required('Informe um vendedor válido!')
  }),
  motivoReserva: Yup.string()
    .nullable()
    .required('Informe o motivo da reserva!')
})
