import BaseModel from '../../BaseModel'

export class CorModel extends BaseModel {
  constructor (params = {}) {
    super()
    this.codigo_cor = params.codigo_cor || null
    this.descri_cor = params.descri_cor || ''
    this.cormar_cor = params.cormar_cor || ''
    this.marca = params.marca || {}
    this.corDenatran = params.corDenatran || {}
  }

  toMap () {
    return {
      codigo_cor: this.codigo_cor,
      descri_cor: this.descri_cor || '',
      cormar_cor: this.cormar_cor || '',
      codmar_cor: this.marca.codigo_mar || '',
      codcde_cor: this.corDenatran.codigo_cde || ''
    }
  }
}
