import React from 'react'

const ContaAutocompleteTemplate = (option) => {
  return (
    <span>
      <b>{option.codigo_con}</b> - {option.descri_con}
    </span>
  )
}

export default ContaAutocompleteTemplate
