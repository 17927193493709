import React, { useEffect, useRef } from 'react'
import AppButton from '../../../../components/layout/AppButton'
import TabMenuContainer from '../../../../components/layout/TabMenuContainer/TabMenuContainer'
import TabMenuPage from '../../../../components/layout/TabMenuContainer/TabMenuPage'
import { showErrorMessage } from '../../../../components/utils/Message'
import ComentariosPes from '../ComentariosPes'
import DadosEnderecoPes from '../DadosEnderecoPes'
import LogoEmpresaPej from '../LogoEmpresaPej'
import './_pessoaJuridica.scss'
import DadosAdicionaisPej from './formPages/DadosAdicionaisPej'
import DadosConvenioPej from './formPages/DadosConvenioPej'
import DadosFiscaisPej from './formPages/DadosFiscaisPej'
import DadosPrincipaisPej from './formPages/DadosPrincipaisPej'

const PessoaJuridicaForm = ({ form, edicaoHabilitada, camposObrigatorios, isNew, setFooter, onSave }) => {
  const fileUploadRef = useRef(null)

  useEffect(async () => {
    setPessoaJuridicaFooter()
  }, [form.values])

  function setPessoaJuridicaFooter () {
    setFooter(
      <div className="flex justify-content-end pb-6 pt-3">
        <AppButton
          type="submit"
          label="Salvar"
          className="p-button"
          onClick={async () => await handleSubmit()}
        />
      </div>
    )
  }

  const handleSubmit = async () => {
    if (fileUploadRef?.current?.files) {
      if (String(fileUploadRef.current.files[0].type).substring(0, 5) !== 'image') {
        showErrorMessage('Por favor insira uma imagem!')
        return
      }

      await fileUploadRef.current?.upload()
    }

    await onSave(form.values)
  }

  return (
    <TabMenuContainer>
      <TabMenuPage header="INFORMAÇÕES PRINCIPAIS">
        <DadosPrincipaisPej
          form={form}
          edicaoHabilitada={edicaoHabilitada}
          camposObrigatorios={camposObrigatorios}
        />
      </TabMenuPage>
      <TabMenuPage header="INFORMAÇÕES ADICIONAIS">
        <DadosAdicionaisPej
          form={form}
          edicaoHabilitada={edicaoHabilitada}
          isNew={isNew}
        />
      </TabMenuPage>
      <TabMenuPage header="CONVÊNIO">
        <DadosConvenioPej
          form={form}
          edicaoHabilitada={edicaoHabilitada}
          isNew={isNew}
          camposObrigatorios={camposObrigatorios}
        />
      </TabMenuPage>
      <TabMenuPage header="FISCAL">
        <DadosFiscaisPej
          form={form}
          edicaoHabilitada={edicaoHabilitada}
        />
      </TabMenuPage>
      <TabMenuPage header="ENDEREÇOS">
        <DadosEnderecoPes
          form={form}
          edicaoHabilitada={edicaoHabilitada}
        />
      </TabMenuPage>
      <TabMenuPage header="LOGO DA EMPRESA">
        <LogoEmpresaPej
          form={form}
          edicaoHabilitada={edicaoHabilitada}
          fileUploadRef={fileUploadRef}
        />
      </TabMenuPage>
      <TabMenuPage header="COMENTÁRIOS">
        <ComentariosPes
          form={form}
          tabela="pessoa"
          codigo={form.values.codigo_pej}
          edicaoHabilitada={edicaoHabilitada}
        />
      </TabMenuPage>
    </TabMenuContainer>
  )
}

export default PessoaJuridicaForm
