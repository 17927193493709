import { Column } from 'primereact/column'
import React from 'react'
import ResponsiveColumn from '../components/utils/ResponsiveColumn'

export const getTableConfig = (table) => {
  const tablesConfig = JSON.parse(localStorage.getItem('tablesConfig'))

  return tablesConfig ? tablesConfig[table + 'tableConfig'] : null
}

export const setTableConfig = (table, value) => {
  const tablesConfig = JSON.parse(localStorage.getItem('tablesConfig')) || {}

  tablesConfig[table + 'tableConfig'] = value
  localStorage.setItem('tablesConfig', JSON.stringify(tablesConfig))
}

export const getColunasHabilitadas = (columns, tableName) => {
  try {
    const activeColumnsKeys = getActiveColumnsKeys(columns, tableName)
    return activeColumnsKeys.map(activeColumnKey => columns.find(column => column.key === activeColumnKey).component)
  } catch {
    localStorage.removeItem('tablesConfig')
  }
}

const getActiveColumnsKeys = (columns, tableName) => {
  const preferenciasAtuais = getTableConfig(tableName)

  if (preferenciasAtuais) {
    return getActiveColumnsKeysByTablePreferences(preferenciasAtuais)
  }

  return getDefaultActiveColumnsKeys(columns)
}

function getActiveColumnsKeysByTablePreferences (preferenciasAtuais) {
  const activeColumns = preferenciasAtuais.filter(preferencia => preferencia.enabled)

  return activeColumns.map(activeColumn => activeColumn.key)
}

const getDefaultActiveColumnsKeys = (columns) => {
  const activeColumnsKeys = []

  columns.forEach(column => {
    const { enabled = true } = column

    if (enabled)
      activeColumnsKeys.push(column.key)
  })

  return activeColumnsKeys
}

export const createColumns = (columnsConfigs, reportTable = false) => {
  const columns = []

  for (const columnConfig of columnsConfigs) {
    const {
      field,
      customBody,
      header,
      width,
      className,
      customClassName,
      headerClassName,
      checkBoxColumn,
      frozen = false,
      enabled = true,
      sortable = true,
      alignText = 'text-center',
      borderLeft,
      borderRight,
      borderBottom
    } = columnConfig

    const columnIndex = columnsConfigs.indexOf(columnConfig)

    const column = {
      key: field,
      label: header,
      enabled: enabled,
      component:
        <Column
          key={columnIndex}
          field={field}
          header={header}
          sortable={sortable}
          frozen={frozen}
          className={alignText}
          body={(data) => (
            <ResponsiveColumn
              className={customClassName ? customClassName(data) : className}
              column={header}
              value={customBody ? customBody(data) : data[field]}
              frozen={frozen}
            />
          )}
          style={{ width: width || 'auto', borderLeft: borderLeft || 'none', borderRight: borderRight || 'none', borderBottom: borderBottom || 'none' }}
          sortField={reportTable ? field : `null${columnIndex}`}
          headerClassName={`${field} ${headerClassName ? headerClassName : ''} ${reportTable ? 'reportColumn' : ''}`}
          selectionMode={checkBoxColumn ? 'multiple' : ''}
        />
    }

    columns.push(column)
  }

  return columns
}
