import React from 'react'
import CheckboxInput from '../../components/inputs/CheckboxInput'
import DateInput from '../../components/inputs/DateInput'
import SelectInput from '../../components/inputs/SelectInput'
import EmpresasAcessoTreeSelect from '../../components/inputs/empresasAcessoTreeSelect/EmpresasAcessoTreeSelect'
import SearchInput from '../../components/inputs/searchInput/SearchInput'
import { showErrorMessage, showWarnMessage } from '../../components/utils/Message'
import ReportFiltroModalBase from '../../components/utils/report/ReportFiltroModalBase'
import { getModeloDTO } from '../../dtos/cadastro/veiculo/ModeloDTO'
import CommonHelper from '../../helpers/CommonHelper'
import { formatPlaca } from '../../helpers/formaters'
import { VendedorModel } from '../../models/cadastro/pessoa/VendedorModel'
import { NegociacaoModel } from '../../models/vendas/negociacao/NegociacaoModel'
import { getStore } from '../../redux/store'
import VendedoresService from '../../services/cadastro/pessoa/VendedoresService'
import GrupoProdutoService from '../../services/cadastro/produto/GrupoProdutoService'
import FamiliaVeiculoService from '../../services/cadastro/veiculo/FamiliaVeiculoService'
import ModeloService from '../../services/cadastro/veiculo/ModeloService'
import TipoVeiculoDenatranService from '../../services/cadastro/veiculo/TipoVeiculoDenatranService'
import VeiculoService from '../../services/cadastro/veiculo/VeiculoService'
import LucroDeVendasReportService from '../../services/reports/LucroDeVendasReportService'
import NegociacaoService from '../../services/vendas/negociacao/NegociacaoService'
import { getVeiculoDTO } from '../../dtos/cadastro/veiculo/VeiculoDTO'

class LucroDeVendasReportFiltroModal extends ReportFiltroModalBase {
  constructor (props) {
    super(props, LucroDeVendasReportService)

    this.state = {
      ...this.state,
      tiposNeg: ['Todos', 'Próprios', 'Consignados'],
      tiposVenda: ['Vendas diretas e normais', 'Somente diretas', 'Somente normais'],
      optionVendaNormalRepasse: ['Vendas normais e repasses', 'Somente repasses', 'Somente normais'],
      tiposVeiculo: ['Veículos novos e usados', 'Veículos novos', 'Veículos usados'],
      tiposVeiculosDenatran: [],
      formasAquisicao: [],
      familiasVeiculo: [],
      gruposProduto: [],
      sugestaoNegociacoes: [],
      sugestaoVendedores: [],
      sugestaoVeiculos: [],
      sugestaoModelos: [],
      empresas: []
    }
  }

  async componentDidMount () {
    super.setFormInitialValues({
      dataNegociacaoInicial: CommonHelper.lastMonthToDate(),
      dataNegociacaoFinal: new Date(),
      tipoNeg: 'Todos',
      tipoVenda: 'Vendas diretas e normais',
      tipoVeiculo: 'Veículos novos e usados',
      vendaNormalRepasse: 'Vendas normais e repasses',
      empresas: [getStore().empresaLogada.codigo_emp]
    })

    super.setLegendaConfigs([
      { filtroName: 'empresas', texto: 'Codigo das empresas', formatFunction: this.getDescricaoEmpresasSelecionadas.bind(this) },
      { filtroName: 'negociacao', texto: 'Negociação número' },
      { filtroName: 'vendedor', texto: 'Vendedor', formatFunction: () => this.props.form.vendedor?.nomraz_pes || this.props.form.vendedor },
      { filtroName: 'veiculo', texto: 'Placa do veiculo', formatFunction: this.getDescricaoVeiculo.bind(this) },
      { filtroName: 'modelo', texto: 'Modelo', formatFunction: () => this.props.form.modelo?.descri_mod || this.props.form.modelo },
      { filtroName: 'dataNegociacaoInicial', texto: 'Negociações com data de venda posterior a', formatFunction: CommonHelper.dateToBrazilian },
      { filtroName: 'dataNegociacaoFinal', texto: 'Negociações com data de venda anterior a', formatFunction: CommonHelper.dateToBrazilian },
      { filtroName: 'dataFaturadaInicial', texto: 'Negociações com data de faturamento posterior a', formatFunction: CommonHelper.dateToBrazilian },
      { filtroName: 'dataFaturadaFinal', texto: 'Negociações com data de faturamento anterior a', formatFunction: CommonHelper.dateToBrazilian },
      { filtroName: 'dataAprovadaInicial', texto: 'Negociações com data aprovação posterior a', formatFunction: CommonHelper.dateToBrazilian },
      { filtroName: 'dataAprovadaFinal', texto: 'Negociações com data aprovação anterior a', formatFunction: CommonHelper.dateToBrazilian },
      { filtroName: 'tipoNeg', texto: 'Tipo das negociações' },
      { filtroName: 'tipoVeiculoDenatran', texto: 'Tipo veículo DENATRAN', formatFunction: () => this.props.form.tipoVeiculoDenatran?.descri_tvd },
      { filtroName: 'familiaVeiculo', texto: 'Família dos veículos', formatFunction: () => this.props.form.familiaVeiculo?.descri_fav },
      { filtroName: 'grupoProduto', texto: 'Grupo produto', formatFunction: () => this.props.form.grupoProduto?.descri_grp },
      { filtroName: 'formaAquisicao', texto: 'Forma de aquisição', formatFunction: () => this.props.form.formaAquisicao?.descri_foa },
      { filtroName: 'somenteManutencoesCusto', texto: 'Somente manutenções de custo', onlyText: true },
      { filtroName: 'somenteNegociacoesAprovadas', texto: 'Somente negociações aprovadas', onlyText: true },
      { filtroName: 'tipoVenda', texto: 'Tipo das vendas' },
      { filtroName: 'vendaNormalRepasse', texto: 'Incluir vendas normais/repasse' },
      { filtroName: 'tipoVeiculo', texto: 'Tipo dos veículos' }
    ])

    await this.getTiposVeiculosDenatran()
    await this.getFamiliasVeiculo()
    await this.getGruposProduto()
    await this.getFormasAquisicao()
  }

  getDescricaoEmpresasSelecionadas () {
    const { form } = this.props

    let descricao = ''

    form.empresas.forEach((empresa, idx) => {
      descricao += empresa

      if (idx !== form.empresas.length - 1) {
        descricao += ', '
      }
    })

    return descricao
  }

  getDescricaoVeiculo () {
    return formatPlaca(this.props.form.veiculo?.placa_vei) || this.props.form.veiculo?.chassi_vei || this.props.form.veiculo
  }

  async getTiposVeiculosDenatran () {
    const tiposVeiculosDenatran = await TipoVeiculoDenatranService.getAll()
    this.setState({ tiposVeiculosDenatran })
  }

  async getFamiliasVeiculo () {
    const familiasVeiculo = await FamiliaVeiculoService.getAll()
    this.setState({ familiasVeiculo })
  }

  async getGruposProduto () {
    const gruposProduto = await GrupoProdutoService.getAll()
    this.setState({ gruposProduto })
  }

  async getFormasAquisicao () {
    const formasAquisicao = await NegociacaoService.getFormaAquisicao()
    this.setState({ formasAquisicao })
  }

  setFilters () {
    const { form } = this.props

    this.filters = {
      empresas: form.empresas,
      negociacao: form.negociacao?.codigo_neg,
      vendedor: form.vendedor?.codigo_ven || null,
      veiculo: form.veiculo?.codigo_vei || null,
      modelo: form.modelo?.codigo_mod || null,
      dataNegociacaoInicial: CommonHelper.dateToAmerican(form.dataNegociacaoInicial),
      dataNegociacaoFinal: CommonHelper.dateToAmerican(form.dataNegociacaoFinal),
      dataFaturadaInicial: CommonHelper.dateToAmerican(form.dataFaturadaInicial),
      dataFaturadaFinal: CommonHelper.dateToAmerican(form.dataFaturadaFinal),
      dataAprovadaInicial: CommonHelper.dateToAmerican(form.dataAprovadaInicial),
      dataAprovadaFinal: CommonHelper.dateToAmerican(form.dataAprovadaFinal),
      tipoNeg: form.tipoNeg,
      tipoVeiculoDenatran: form.tipoVeiculoDenatran?.codigo_tvd,
      familiaVeiculo: form.familiaVeiculo?.codigo_fav,
      grupoProduto: form.grupoProduto?.codigo_grp,
      formaAquisicao: form.formaAquisicao?.codigo_foa,
      somenteManutencoesCusto: form.somenteManutencoesCusto,
      somenteNegociacoesAprovadas: form.somenteNegociacoesAprovadas,
      tipoVenda: form.tipoVenda,
      vendaNormalRepasse: form.vendaNormalRepasse,
      tipoVeiculo: form.tipoVeiculo
    }
  }

  async sugerirNegociacao () {
    try {
      const { form } = this.props

      const negociacoes = await NegociacaoService.filterAutoComplete(`codigo_neg=${form.negociacao.codigo_neg || form.negociacao}`)

      if (negociacoes.length <= 0) {
        showWarnMessage('Negociação não encontrada!')
      }

      this.setState({ sugestaoNegociacoes: negociacoes })
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir uma negociação!')
    }
  }

  async sugerirVendedor () {
    try {
      const { form } = this.props

      const vendedoresService = new VendedoresService()
      const vendedores = await vendedoresService.filterAutoComplete(`nomraz_pes=${form.vendedor.nomraz_pes || form.vendedor}`)

      if (vendedores.length <= 0) {
        showWarnMessage('Vendedor não encontrado!')
      }

      this.setState({ sugestaoVendedores: vendedores })
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um vendedor!')
    }
  }

  async sugerirVeiculos () {
    try {
      const { form } = this.props

      const veiculoService = new VeiculoService()
      const veiculos = await veiculoService.filterAutoComplete(`placa_vei=${form.veiculo.placa_vei || form.veiculo}`)

      if (veiculos.length <= 0) {
        showWarnMessage('Veículo não encontrado!')
      }

      this.setState({ sugestaoVeiculos: veiculos })
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um veículo!')
    }
  }

  async sugerirModelos () {
    try {
      const { form } = this.props

      const modelos = await VeiculoService.filterModels(form.modelo.descri_mod || form.modelo)

      this.setState({ sugestaoModelos: modelos })
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um modelo!')
    }
  }

  veiculoTemplate (veiculo) {
    return <span><b>{formatPlaca(veiculo?.placa_vei)}</b> - {veiculo?.produto?.descri_pro}</span>
  }

  filtroInputs () {
    const { form } = this.props

    return (
      <>
        <div className="field col-12">
          <EmpresasAcessoTreeSelect
            label="Empresas"
            selectedOptions={form.selectedOptionsEmpresas}
            changeSelectedOptions={(selectedOptions) => this.setFormValue('selectedOptionsEmpresas', selectedOptions)}
            changeSelectedEmpresas={(empresas) => this.setFormValue('empresas', empresas.map(empresa => empresa.codigo_pes))}
          />
        </div>
        <div className="field col-12 md:col-6">
          <SearchInput
            field="codigo_neg"
            name="negociacao"
            label="Código da negociação"
            minLength={1}
            value={form.negociacao?.codigo_neg || form.negociacao}
            className="inputfield w-full"
            placeholder="Código da Negociação"
            onSelect={(e) => super.setFormValue('negociacao', e.value)}
            suggestions={this.state.sugestaoNegociacoes}
            completeMethod={(e) => this.sugerirNegociacao(e)}
            onChange={(e) => super.handleChange(e)}
            // FiltroModal
            filtrotitle="Pesquisa de negociação"
            service={NegociacaoService}
            model={NegociacaoModel}
            primarykeyname="codigo_neg"
            columns={[
              { campo: 'codigo_neg', nome: 'Código' },
              { campo: 'descri_emp', nome: 'Empresa' },
              { campo: 'placa_vei', nome: 'Placa', format: formatPlaca },
              { campo: 'datfin_neg', nome: 'Data de finalização', format: CommonHelper.dateTimeToBrazilian },
              { campo: 'descri_ope', nome: 'Operação' }
            ]}
          />
        </div>
        <div className="field col-12 md:col-6">
          <SearchInput
            //AutoComplete
            field="nomraz_pes"
            name="vendedor"
            label="Vendedor"
            value={form.vendedor?.nomraz_pes || form.vendedor}
            placeholder="Nome do vendedor"
            className="inputfield w-full"
            suggestions={this.state.sugestaoVendedores}
            onSelect={(e) => super.setFormValue('vendedor', e.value)}
            completeMethod={(e) => this.sugerirVendedor(e)}
            onChange={(e) => super.handleChange(e)}
            // FiltroModal
            filtrotitle="Pesquisa de vendedor"
            service={VendedoresService}
            model={VendedorModel}
            primarykeyname="codigo_ven"
            columns={[
              { campo: 'codigo_ven', nome: 'Código' },
              { campo: 'nomraz_pes', nome: 'Nome' }
            ]}
          />
        </div>
        <div className="field col-12 md:col-6">
          <SearchInput
            //AutoComplete
            field="placa_vei"
            label="Veículo"
            name="veiculo"
            value={form.veiculo?.placa_vei || form.veiculo?.chassi_vei || form.veiculo}
            placeholder="Informe a placa do veículo"
            className="inputfield w-full"
            suggestions={this.state.sugestaoVeiculos}
            onSelect={(e) => super.setFormValue('veiculo', e.value)}
            completeMethod={(e) => this.sugerirVeiculos(e)}
            onChange={(e) => super.handleChange(e)}
            itemTemplate={(veiculo) => this.veiculoTemplate(veiculo)}
            // FiltroModal
            filtrotitle="Pesquisa de veículo"
            service={VeiculoService}
            model={getVeiculoDTO}
            primarykeyname="codigo_vei"
            columns={[
              { campo: 'codigo_vei', nome: 'Código' },
              { campo: 'placa_vei', nome: 'Placa', format: formatPlaca },
              { campo: 'chassi_vei', nome: 'Chassi' },
              { campo: 'modelo.descri_mod', nome: 'Modelo' },
              { campo: 'modelo.marca.descri_mar', nome: 'Marca' }
            ]}
          />
        </div>
        <div className="field col-12 md:col-6">
          <SearchInput
            //AutoComplete
            field="descri_mod"
            label="Modelo"
            name="modelo"
            value={form.modelo?.descri_mod || form.modelo}
            placeholder="Digite para pesquisar um modelo"
            suggestions={this.state.sugestaoModelos}
            onSelect={(e) => super.setFormValue('modelo', e.value)}
            completeMethod={(e) => this.sugerirModelos(e)}
            onChange={(e) => super.handleChange(e)}
            className="inputfield w-full"
            // FiltroModal
            filtrotitle="Pesquisa de modelo"
            service={ModeloService}
            model={getModeloDTO}
            columns={[
              { campo: 'codigo_mod', nome: 'Código' },
              { campo: 'descri_mod', nome: 'Descrição' },
              { campo: 'marca.descri_mar', nome: 'Marca' }
            ]}
            //FiltroModal && RegisterModal
            primarykeyname="codigo_mod"
          />
        </div>
        <div className="field col-12">
          <label>Negociações</label>
          <div className="formgrid grid">
            <div className="field col-12 md:col-6 no-margin">
              <DateInput
                name="dataNegociacaoInicial"
                placeholder="Data Inicial"
                className="inputfield w-full"
                value={form.dataNegociacaoInicial}
                onChange={(e) => super.handleChange(e)}
              />
            </div>
            <div className="field col-12 md:col-6 no-margin">
              <DateInput
                name="dataNegociacaoFinal"
                placeholder="Data Final"
                className="inputfield w-full"
                value={form.dataNegociacaoFinal}
                onChange={(e) => super.handleChange(e) }
              />
            </div>
          </div>
        </div>
        <div className="field col-12">
          <label>Faturadas</label>
          <div className="formgrid grid">
            <div className="field col-12 md:col-6 no-margin">
              <DateInput
                name="dataFaturadaInicial"
                placeholder="Data Inicial"
                className="inputfield w-full"
                value={form.dataFaturadaInicial}
                onChange={(e) => super.handleChange(e)}
              />
            </div>
            <div className="field col-12 md:col-6 no-margin">
              <DateInput
                name="dataFaturadaFinal"
                placeholder="Data Final"
                className="inputfield w-full"
                value={form.dataFaturadaFinal}
                onChange={(e) => super.handleChange(e) }
              />
            </div>
          </div>
        </div>
        <div className="field col-12">
          <label>Aprovadas</label>
          <div className="formgrid grid">
            <div className="field col-12 md:col-6 no-margin">
              <DateInput
                name="dataAprovadaInicial"
                placeholder="Data Inicial"
                className="inputfield w-full"
                value={form.dataAprovadaInicial}
                onChange={(e) => super.handleChange(e)}
              />
            </div>
            <div className="field col-12 md:col-6 no-margin">
              <DateInput
                name="dataAprovadaFinal"
                placeholder="Data Final"
                className="inputfield w-full"
                value={form.dataAprovadaFinal}
                onChange={(e) => super.handleChange(e) }
              />
            </div>
          </div>
        </div>
        <div className="field col-12">
          <SelectInput
            value={form.tipoNeg}
            options={this.state.tiposNeg}
            onChange={(e) => super.handleChange(e)}
            name="tipoNeg"
            label="Tipo"
            noFloatLabel
            className="inputfield w-full"
          />
        </div>
        <div className="field col-12">
          <SelectInput
            value={form.tipoVeiculoDenatran}
            optionLabel="descri_tvd"
            dataKey="codigo_tvd"
            options={this.state.tiposVeiculosDenatran}
            onChange={(e) => super.handleChange(e)}
            name="tipoVeiculoDenatran"
            label="Tipo veiculo DENATRAN"
            noFloatLabel
            className="inputfield w-full"
            filter={CommonHelper.isDesktop()}
            showClear={form.tipoVeiculoDenatran}
          />
        </div>
        <div className="field col-12">
          <SelectInput
            value={form.familiaVeiculo}
            optionLabel="descri_fav"
            dataKey="codigo_fav"
            options={this.state.familiasVeiculo}
            onChange={(e) => super.handleChange(e)}
            name="familiaVeiculo"
            label="Familia veículo"
            noFloatLabel
            className="inputfield w-full"
            filter={CommonHelper.isDesktop()}
            showClear={form.familiaVeiculo}
          />
        </div>
        <div className="field col-12">
          <SelectInput
            value={form.grupoProduto}
            optionLabel="descri_grp"
            dataKey="codigo_grp"
            options={this.state.gruposProduto}
            onChange={(e) => super.handleChange(e)}
            name="grupoProduto"
            label="Grupo produto"
            noFloatLabel
            className="inputfield w-full"
            filter={CommonHelper.isDesktop()}
            showClear={form.grupoProduto}
          />
        </div>
        <div className="field col-12">
          <SelectInput
            value={form.formaAquisicao}
            optionLabel="descri_foa"
            dataKey="codigo_foa"
            options={this.state.formasAquisicao}
            onChange={(e) => super.handleChange(e)}
            name="formaAquisicao"
            label="Forma aquisição"
            noFloatLabel
            className="inputfield w-full"
            filter={CommonHelper.isDesktop()}
            showClear={form.formaAquisicao}
          />
        </div>
        <div className="field col-12 md:col-6">
          <CheckboxInput
            name="somenteManutencoesCusto"
            value={form.somenteManutencoesCusto}
            checked={form.somenteManutencoesCusto}
            onChange={(e) => super.handleChange(e)}
            label="Incluir somente manutenções de custo?"
          />
        </div>
        <div className="field col-12 md:col-6">
          <CheckboxInput
            name="somenteNegociacoesAprovadas"
            value={form.somenteNegociacoesAprovadas}
            checked={form.somenteNegociacoesAprovadas}
            onChange={(e) => super.handleChange(e)}
            label="Incluir somente negociações aprovadas?"
          />
        </div>
        <div className="field col-12">
          <SelectInput
            label="Dados a incluir"
            name="tipoVenda"
            value={form.tipoVenda}
            options={this.state.tiposVenda}
            className="inputfield w-full"
            onChange={(e) => super.handleChange(e)}
            noFloatLabel
          />
        </div>
        <div className="field col-12">
          <SelectInput
            name="vendaNormalRepasse"
            value={form.vendaNormalRepasse}
            options={this.state.optionVendaNormalRepasse}
            className="inputfield w-full"
            onChange={(e) => super.handleChange(e)}
            noFloatLabel
          />
        </div>
        <div className="field col-12">
          <SelectInput
            name="tipoVeiculo"
            value={form.tipoVeiculo}
            options={this.state.tiposVeiculo}
            className="inputfield w-full"
            onChange={(e) => super.handleChange(e)}
            noFloatLabel
          />
        </div>
      </>
    )
  }
}

export default LucroDeVendasReportFiltroModal
