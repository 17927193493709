import { classNames } from 'primereact/utils'
import React, { useEffect, useState } from 'react'
import AutoCompleteInput from '../../../../components/inputs/AutoCompleteInput'
import CheckboxInput from '../../../../components/inputs/CheckboxInput'
import DateInput from '../../../../components/inputs/DateInput'
import SelectInput from '../../../../components/inputs/SelectInput'
import TextInput from '../../../../components/inputs/TextInput'
import FieldErrorMessage from '../../../../components/utils/FieldErrorMessage'
import { showErrorMessage, showWarnMessage } from '../../../../components/utils/Message'
import RequiredLabel from '../../../../components/utils/RequiredLabel'
import CommonHelper from '../../../../helpers/CommonHelper'
import { formatCurrencyToNumber, formatDocument, formatToCurrency } from '../../../../helpers/formaters'
import { useValidateInput } from '../../../../helpers/useValidateInput'
import { PessoaModel } from '../../../../models/cadastro/pessoa/PessoaModel'
import ConveniadoService from '../../../../services/cadastro/pessoa/ConveniadoService'
import PessoaService from '../../../../services/cadastro/pessoa/PessoaService'
import SituacaoCreditoService from '../../../../services/cadastro/pessoa/SituacaoCreditoService'
import ModalidadeVendaService from '../../../../services/cadastro/veiculo/ModalidadeVendaService'
import ConfigService from '../../../../services/configuracao/ConfigService'
import TipoMidiaService from '../../../../services/vendas/TipoMidiaService'
import OrcamentoBalcaoService from '../../../../services/vendas/orcamentoBalcao/OrcamentoBalcaoService'
import { PessoaFisica } from '../../../../views/cadastro/pessoa/fisica/PessoaFisica'
import { PessoaJuridica } from '../../../../views/cadastro/pessoa/juridica/PessoaJuridica'
import OrcamentosAbertosOuReservadosModal from '../modals/OrcamentosAbertosOuReservadosModal'

function DadosPrincipaisOpv ({ form, edicaoHabilitada, camposObrigatorios, setTipoPrecoByCliente }) {
  const [isConveniadoEnabled, setIsConveniadoEnabled] = useState(false)
  const [isVendaSemConvenioEnabled, setIsVendaSemConvenioEnabled] = useState(false)
  const [visibleOrcamentosAbertosOuReservadosModal, setVisibleOrcamentosAbertosOuReservadosModal] = useState(false)
  const [orcamentosAbertosOuReservados, setOrcamentosAbertosOuReservados] = useState([])
  const [tiposmidia, setTiposMidia] = useState([])
  const [sugestaoClientes, setSugestaoClientes] = useState([])
  const [sugestaoConsultores, setSugestaoConsultores] = useState([])
  const [sugestaoConveniados, setSugestaoConveniados] = useState([])
  const [desmarcarOuMarcarPresencialSeHouverFrete, setDesmarcarOuMarcarPresencialSeHouverFrete] = useState(false)
  const [codigoPadraoDoClienteConsumidor, setCodigoPadraoDoClienteConsumidor] = useState(0)
  const [intermediadores, setIntermediadores] = useState([])
  const [modalidadesVenda, setModalidadesVenda] = useState([])
  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(form)

  useEffect(() => {
    requisicoesIniciais()
  }, [])

  async function requisicoesIniciais () {
    await Promise.all([
      getConfig2024(),
      getClienteConsumidor(),
      getTiposMidia(),
      getIntermediadores(),
      getModalidadesVenda(),
      getConfig1433()
    ])
  }

  async function getConfig2024 () {
    const desmarcarOuMarcarPresencialSeHouverFrete = await ConfigService.getValor(2024)
    setDesmarcarOuMarcarPresencialSeHouverFrete(desmarcarOuMarcarPresencialSeHouverFrete)
  }
  async function getConfig1433 () {
    const obrigarMidia = await ConfigService.getValor(1433)
    form.setFieldValue('tipomidia.obrigarTipoMidia', obrigarMidia)
  }

  async function getClienteConsumidor () {
    const codigoPadraoDoClienteConsumidor = await ConfigService.getValor(1292)
    setCodigoPadraoDoClienteConsumidor(codigoPadraoDoClienteConsumidor)
  }

  const getIntermediadores = async () => {
    try {
      const intermediadores = await PessoaService.getItermediador()
      setIntermediadores(intermediadores)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar os intermediadores!')
    }
  }

  async function getModalidadesVenda () {
    try {
      const modalidadesVenda = await ModalidadeVendaService.getAll()

      for (const modalidadeVenda of modalidadesVenda) {
        if (modalidadeVenda.codcsu_mov === 'n') {
          modalidadeVenda.descri_mov = modalidadeVenda.descri_mov + ' (REVENDA)'
        }
      }

      if (!form.values.modalidadeVenda) {
        form.setFieldValue('modalidadeVenda', modalidadesVenda[0])
      }

      setModalidadesVenda(modalidadesVenda)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar as modalidades de venda!')
    }
  }

  async function sugerirClientes () {
    try {
      const clientes = await PessoaService.filterAutocomplete(form.values.cliente.nomraz_pes)

      if (clientes.length <= 0) {
        showWarnMessage('Cliente não encontrado!')
      }
      setSugestaoClientes(clientes)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um cliente!')
    }
  }

  async function getTiposMidia () {
    try {
      const tiposmidia = await TipoMidiaService.filterTipoMidia()
      setTiposMidia(tiposmidia)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar as mídias!')
    }
  }

  async function sugerirConsultores () {
    try {
      const consultores = await PessoaService.filterAutocomplete(`${form.values.consultor.nomraz_pes}&apenasFuncionariosAtivos=true`)

      if (consultores.length <= 0) {
        showWarnMessage('Consultor não encontrado!')
      }
      setSugestaoConsultores(consultores)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um consultor!')
    }
  }

  async function sugerirConveniados () {
    try {
      const conveniados = await PessoaService.filterAutocompleteConveniado(form.values.cliente.codigo_pes, form.values.conveniado.nomraz_pes)

      if (conveniados.length <= 0) {
        showWarnMessage('Conveniado não encontrado!')
      }
      setSugestaoConveniados(conveniados)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um conveniado!')
    }
  }

  async function onSelectCliente (e) {
    try {
      const cliente = e.value
      const situacaoCreditoCliente = await SituacaoCreditoService.getSituacaoCredito(cliente.codigo_pes)

      if (situacaoCreditoCliente !== 99 && cliente.codigo_pes !== Number(codigoPadraoDoClienteConsumidor)) {
        let descricaoSituacao = ''

        switch (situacaoCreditoCliente) {
        case 1:
          const mensagemAvisoParaClientesBloqueadosManualmente = await ConfigService.getValor(3047)
          descricaoSituacao = mensagemAvisoParaClientesBloqueadosManualmente
          break
        case 3:
          const mensagemAvisoParaClientesQuePagamAposVencimento = await ConfigService.getValor(3049)
          descricaoSituacao = mensagemAvisoParaClientesQuePagamAposVencimento
          break
        case 4:
          const mensagemAvisoParaClientesComTitulosVencidos = await ConfigService.getValor(3050)
          descricaoSituacao = mensagemAvisoParaClientesComTitulosVencidos
          break
        default:
          descricaoSituacao = 'Pessoa bloqueada por situação de crédito anormal.'
          break
        }

        showWarnMessage(`${descricaoSituacao}\nA continuidade desse orçamento dependerá da aprovação de crédito.`)
      }

      if (cliente.codigo_pes) {
        await setEnabledConveniadoByCliente(cliente)
        if (edicaoHabilitada) {
          await setTipoPrecoByCliente(cliente, false)
        }
      }

      const orcamentosAbertosOuReservadosDoCliente = await OrcamentoBalcaoService.getOrcamentosAbertosOuReservadosByCliente(cliente.codigo_pes, form.values.codigo_opv)
      if (orcamentosAbertosOuReservadosDoCliente?.length > 0) {
        setOrcamentosAbertosOuReservados(orcamentosAbertosOuReservadosDoCliente)
        setTimeout(() => {
          setVisibleOrcamentosAbertosOuReservadosModal(true)
        }, 500)
      }

      form.setFieldValue('conveniado', { nomraz_pes: '' })
      const modalidadeCliente = modalidadesVenda.filter((modalideVenda) => modalideVenda.codigo_mov === cliente.codmov_pes)

      if (modalidadeCliente.length > 0) {
        form.setFieldValue('modalidadeVenda', modalidadeCliente[0])
      }

      await form.setFieldValue('vndsemcon_opv', false)
      await form.setFieldValue('cliente', cliente)
      await form.setFieldTouched('cliente.codigo_pes')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao definir o cliente')
      return false
    }
  }

  useEffect(async () => {
    if (edicaoHabilitada) {
      await setEnabledConveniadoByCliente(form.values.cliente)
    }
  }, [edicaoHabilitada])

  function handleChangeVendaSemConvenio (event) {
    form.setFieldValue('vndsemcon_opv', event.target.checked)
    if (event.target.checked) {
      form.setFieldValue('conveniado', { nomraz_pes: '' })
    }
  }

  async function setEnabledConveniadoByCliente (cliente) {
    const deveValidarConveniado = await ConfigService.getValor(1306)
    if (deveValidarConveniado) {
      setIsConveniadoEnabled(false)
      setIsVendaSemConvenioEnabled(false)

      const isEmpresaConveniada = cliente.pessoaJuridica?.isconv_pej
      if (isEmpresaConveniada) {
        setIsConveniadoEnabled(true)
        setIsVendaSemConvenioEnabled(true)
      }

      const isPFConveniada = await validarClienteConveniado(cliente.codigo_pes)
      if (isPFConveniada) {
        setIsVendaSemConvenioEnabled(true)
      }
    } else {
      setIsConveniadoEnabled(true)
      setIsVendaSemConvenioEnabled(true)
    }
  }

  async function onSelectConsultor (e) {
    await form.setFieldValue('consultor', e.value)
    await form.setFieldTouched('consultor.codigo_pes')
  }

  async function onSelectConveniado (e) {
    await form.setFieldValue('conveniado', e.value)
    await form.setFieldTouched('conveniado.codigo_pes')
  }

  async function validarClienteConveniado (codCliente) {
    try {
      const conveniado = await ConveniadoService.verificaClienteConveniado(codCliente)
      return conveniado.conveniado > 0
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar validar o conveniado!')
      return false
    }
  }

  function onSaveModalCliente (cliente) {
    onSelectCliente({ value: cliente.pessoa })
  }

  function onSaveModalConsultor (consultor) {
    onSelectConsultor({ value: consultor.pessoa })
  }

  async function onSaveModalConveniado (conveniado) {
    //onSelectConveniado({ value: conveniado.pessoa })
  }

  function handleChangeCliente (event) {
    form.setFieldValue('cliente', { nomraz_pes: event.target.value })
    form.setFieldValue('conveniado', { nomraz_pes: '' })
  }

  function handleChangeConsultor (event) {
    form.setFieldValue('consultor', { nomraz_pes: event.target.value })
  }

  function handleChangeConveniado (event) {
    form.setFieldValue('conveniado', { nomraz_pes: event.target.value })
  }

  function handleChangeCurrency (event) {
    form.setFieldValue(event.target.name, formatCurrencyToNumber(event.target.value))
  }

  function handleChangeFrete (event) {
    const frete = formatCurrencyToNumber(event.target.value)
    if (desmarcarOuMarcarPresencialSeHouverFrete) {
      if (frete > 0) {
        form.setFieldValue('ispres_opv', false)
      } else {
        form.setFieldValue('ispres_opv', true)
      }
    }

    form.setFieldValue('valfre_opv', frete)
  }

  const handleChangeTipoMidia = async (e) => {
    await form.setFieldValue('tipomidia', e.target.value)
  }

  const handleChangeIntermediador = async (e) => {
    await form.setFieldValue('intermediador', e.target.value)
  }

  return (
    <div className="formgrid grid fluid">
      <div className="field col-12 md:col-4">
        <AutoCompleteInput
          //AutoComplete
          name="cliente.codigo_pes"
          field="nomraz_pes"
          label="Cliente"
          value={form.values.cliente && form.values.cliente.nomraz_pes}
          placeholder="Nome do cliente"
          disabled={!edicaoHabilitada}
          onSelect={async (e) => { await onSelectCliente(e) }}
          suggestions={sugestaoClientes}
          completeMethod={sugerirClientes}
          onChange={handleChangeCliente}
          camposObrigatorios={camposObrigatorios}
          form={form}
          minLength='1'
          //RegisterModal
          selected={form.values.cliente}
          onSaveModal={onSaveModalCliente}
          onDeleteModal={async () => await onSelectCliente({})}
          ComponenteCadastro={[
            {
              label: 'Pessoa física',
              component: PessoaFisica,
              header: form.values.cliente.codigo_pes ? 'Pessoa Física - ' + form.values.cliente.codigo_pes : 'Cadastro de pessoa física'
            },
            {
              label: 'Pessoa jurídica',
              component: PessoaJuridica,
              header: form.values.cliente.codigo_pes ? 'Pessoa jurídica - ' + form.values.cliente.codigo_pes : 'Cadastro de pessoa jurídica'
            }
          ]}
          //FiltroModal
          filtrotitle="Pesquisa de cliente"
          service={PessoaService}
          model={PessoaModel}
          primarykeyname="codigo_pes"
          columns={[
            { campo: 'codigo_pes', nome: 'Código' },
            { campo: 'nomraz_pes', nome: 'Nome razão' },
            { campo: 'nomfan_pej', nome: 'Nome fantasia', preload: 'pessoaJuridica' },
            { campo: 'cgccpf_pes', nome: 'CPF/CNPJ', format: formatDocument }
          ]}
        />
      </div>
      <div className="field col-12 md:col-4">
        <AutoCompleteInput
          //AutoComplete
          name="conveniado.codigo_pes"
          field="nomraz_pes"
          label="Conveniado"
          value={form.values.conveniado && form.values.conveniado.nomraz_pes}
          placeholder="Nome do conveniado"
          disabled={!isConveniadoEnabled || form.values.vndsemcon_opv || !edicaoHabilitada}
          onSelect={async (e) => await onSelectConveniado(e)}
          suggestions={sugestaoConveniados}
          completeMethod={sugerirConveniados}
          onChange={handleChangeConveniado}
          camposObrigatorios={camposObrigatorios}
          form={form}
          //RegisterModal
          selected={form.values.conveniado}
          onSaveModal={onSaveModalConveniado}
          onDeleteModal={async () => await onSelectConveniado({})}
          ComponenteCadastro={[
            {
              label: 'Pessoa física',
              component: PessoaFisica,
              header: form.values.conveniado.codigo_pes ? 'Pessoa Física - ' + form.values.conveniado.codigo_pes : 'Cadastro de pessoa física'
            },
            {
              label: 'Pessoa jurídica',
              component: PessoaJuridica,
              header: form.values.conveniado.codigo_pes ? 'Pessoa jurídica - ' + form.values.conveniado.codigo_pes : 'Cadastro de pessoa jurídica'
            }
          ]}
          //FiltroModal
          filtrotitle="Pesquisa de conveniado"
          filtersDefault={{ codpejConveniado: form.values.cliente?.codigo_pes }}
          service={PessoaService}
          model={PessoaModel}
          primarykeyname="codigo_pes"
          columns={[
            { campo: 'codigo_pes', nome: 'Código' },
            { campo: 'nomraz_pes', nome: 'Nome' },
            { campo: 'cgccpf_pes', nome: 'CPF/CNPJ', format: formatDocument }
          ]}
        />
      </div>
      <div className="field col-12 md:col-4 md:flex justify-content-start align-items-center md:mt-2 ">

        <CheckboxInput
          label="Venda sem convênio"
          name="vndsemcon_opv"
          value={form.values.vndsemcon_opv}
          checked={form.values.vndsemcon_opv}
          onChange={handleChangeVendaSemConvenio}
          disabled={!isVendaSemConvenioEnabled || !edicaoHabilitada}
        />

      </div>
      <div className="field col-12 md:col-4">
        <AutoCompleteInput
          //AutoComplete
          name="consultor.codigo_pes"
          field="nomraz_pes"
          label="Consultor"
          value={form.values.consultor && form.values.consultor.nomraz_pes}
          placeholder="Nome do consultor"
          disabled={!edicaoHabilitada}
          onSelect={async (e) => await onSelectConsultor(e)}
          suggestions={sugestaoConsultores}
          completeMethod={sugerirConsultores}
          minLength='1'
          onChange={handleChangeConsultor}
          camposObrigatorios={camposObrigatorios}
          form={form}
          //RegisterModal
          selected={form.values.consultor}
          onSaveModal={onSaveModalConsultor}
          onDeleteModal={async () => await onSelectConsultor({})}
          ComponenteCadastro={PessoaFisica}
          modalHeader={form.values.consultor.codigo_pes ? 'Pessoa Física - ' + form.values.consultor.codigo_pes : 'Cadastro de pessoa física'}
          //FiltroModal
          filtrotitle="Pesquisa de consultor"
          filtersDefault={{ apenasFuncionariosAtivos: true }}
          service={PessoaService}
          model={PessoaModel}
          primarykeyname="codigo_pes"
          columns={[
            { campo: 'codigo_pes', nome: 'Código' },
            { campo: 'nomraz_pes', nome: 'Nome' },
            { campo: 'cgccpf_pes', nome: 'CPF/CNPJ', format: formatDocument }
          ]}
        />
      </div>
      <div className="field col-12 md:col-4">
        {form.values.tipomidia?.obrigarTipoMidia ? <RequiredLabel label="Mídia" valid={isFormFieldValid('negociacaoVeiculo.dahpreent_nve')} /> : <label>Mídia</label>}

        <SelectInput
          value={form.values.tipomidia}
          camposObrigatorios={camposObrigatorios}

          options={tiposmidia}
          onChange={handleChangeTipoMidia}
          name="tipomidia"
          optionLabel="descri_tim"
          dataKey="codigo_tim"
          className={classNames({ 'p-invalid': isFormFieldValid('tipomidia') }, 'inputfield w-full')}
          placeholder="- Selecione a mídia -"
          noFloatLabel
          disabled={!edicaoHabilitada}
          showClear={form.values.tipomidia?.codigo_tim}
          onHide={() => form.setFieldTouched('tipomidia.codigo_tim')}
        />
        <FieldErrorMessage message={getFormErrorMessage('tipomidia.codigo_tim')} />

      </div>
      <div className="field col-12 md:col-4">
        <SelectInput
          label='Intermediador de vendas '
          value={form.values.intermediador}
          options={intermediadores}
          onChange={handleChangeIntermediador}
          name="intermediador"
          optionLabel="pessoa.nomraz_pes"
          dataKey="codigo_pej"
          placeholder="- Selecione o intermediador -"
          noFloatLabel
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
          showClear={form.values.intermediador?.codigo_pej}
          onHide={() => form.setFieldTouched('intermediador.codigo_pej')}
          filter={CommonHelper.isDesktop()}
        />
      </div>
      <div className="field col-12 md:col-2">
        <TextInput
          name="valfre_opv"
          label="Frete"
          placeholder="Valor do frete"
          value={formatToCurrency(form.values.valfre_opv)}
          onChange={handleChangeFrete}
          disabled={!edicaoHabilitada}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 md:col-2">
        <TextInput
          name="valseg_opv"
          label="Seguro"
          placeholder="Valor do seguro"
          value={formatToCurrency(form.values.valseg_opv)}
          onChange={handleChangeCurrency}
          disabled={!edicaoHabilitada}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 md:col-2">
        <TextInput
          name="valoud_opv"
          label="Despesas acessórias"
          placeholder="Valor das despesas"
          value={formatToCurrency(form.values.valoud_opv)}
          onChange={handleChangeCurrency}
          disabled={!edicaoHabilitada}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>

      <div className="field col-12 md:col-2">

        <DateInput
          label="Data de validade"
          placeholder="Data de validade"
          name="datval_opv"
          value={form.values.datval_opv}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          showButtonBar
          todayButtonClassName='bg-gray-200 m-3 calendario'
          clearButtonClassName='bg-gray-200 m-3 calendario'
        />
      </div>
      <div className="field col-12 md:col-3"></div>
      <div className="field col-12 md:col-3">
        <SelectInput
          value={form.values.modalidadeVenda}
          options={modalidadesVenda}
          defaultValue={1}
          onChange={form.handleChange}
          label="Modalidade de venda"
          name="modalidadeVenda"
          optionLabel="descri_mov"
          dataKey="codigo_mov"
          noFloatLabel
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-4 md:flex justify-content-start align-items-center md:mt-2 ">
        <CheckboxInput
          label="Operação presencial"
          name="ispres_opv"
          value={form.values.ispres_opv}
          checked={form.values.ispres_opv}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
        />
      </div>

      <OrcamentosAbertosOuReservadosModal
        visible={visibleOrcamentosAbertosOuReservadosModal}
        onHide={() => setVisibleOrcamentosAbertosOuReservadosModal(false)}
        cliente={form.values.cliente}
        orcamentosAbertosOuReservados={orcamentosAbertosOuReservados}
      />
    </div>
  )
}

export default DadosPrincipaisOpv
