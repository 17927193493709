import React, { useEffect, useState } from 'react'
import Confirm from '../../../../components/utils/Confirm'
import { showWarnMessage } from '../../../../components/utils/Message'
import Modal from '../../../../components/utils/Modal'
import MotivoVendaPerdidaModal from '../../../../components/utils/MotivoVendaPerdidaModal'
import CommonHelper from '../../../../helpers/CommonHelper'
import { formatDateTimeToAmerican, formatToPercentage } from '../../../../helpers/formaters'
import { getStore } from '../../../../redux/store'
import PermissaoService from '../../../../services/cadastro/pessoa/PermissaoService'
import RestricaoService from '../../../../services/cadastro/pessoa/RestricaoService'
import PecaBalcaoService from '../../../../services/vendas/orcamentoBalcao/PecaBalcaoService'
import DadosPecaBalcaoOpv from '../orcamentoBalcaoFormPages/DadosPecaBalcaoOpv'

function DadosPecasOpvModal ({
  visible, onHide, pecaBalcaoForm, orcamentoBalcaoForm, pecaBalcaoSelecionada,
  edicaoHabilitada, camposObrigatorios, calculaValoresOrcamento, calculaValorTotalPeca, atualizaDescontoPeca,
  getDadosPrecoProduto, gerarVendaPerdidaPecCancelada, permitirDescontoByTipoPreco
}) {
  const [visibleConfirm, setVisibleConfirm] = useState(false)
  const [visibleMotivoVendaPerdidaModal, setVisibleMotivoVendaPerdidaModal] = useState(false)
  const [visibleConfirmModal, setVisibleConfirmModal] = useState(false)
  const [produtoSelecionado, setProdutoSelecionado] = useState(null)

  useEffect(async () => {
    if (pecaBalcaoForm.values.produto.codigo_pro) {
      await pecaBalcaoForm.validateForm()
    }
  }, [pecaBalcaoForm.values.produto.codigo_pro])

  function excluirPecaBalcao () {
    const pecasBalcao = orcamentoBalcaoForm.values.pecasBalcao

    const pecasBalcaoComOMesmoProduto = pecasBalcao.filter(pecaBalcao => pecaBalcao.produto.codigo_pro === pecaBalcaoSelecionada.produto.codigo_pro)

    for (const pecaBalcaoComMesmoProduto of pecasBalcaoComOMesmoProduto) {
      const indexPecaBalcao = pecasBalcao.indexOf(pecaBalcaoComMesmoProduto)

      pecasBalcao.splice(indexPecaBalcao, 1)
    }

    orcamentoBalcaoForm.setFieldValue('pecasBalcao', pecasBalcao)

    calculaValoresOrcamento(pecasBalcao)
    onHide()
  }

  async function openModalConfirmacaoOuMotivoVendaPerdida () {
    if (gerarVendaPerdidaPecCancelada) {
      setVisibleMotivoVendaPerdidaModal(true)
    } else {
      setVisibleConfirm(true)
    }
  }

  async function cancelarPecaBalcao (motivoVendaPerdida) {
    const pecasBalcao = orcamentoBalcaoForm.values.pecasBalcao

    const pecaParaCancelar = pecasBalcao.find(pecaBalcao => pecaBalcao.codigo_peo === pecaBalcaoSelecionada.codigo_peo)

    const indexPecaBalcao = pecasBalcao.indexOf(pecaBalcaoSelecionada)
    pecaParaCancelar.iscan_peo = true
    pecaParaCancelar.usucan_peo = getStore().usuarioLogado.usuario.codigo_usu
    pecaParaCancelar.dahcan_peo = formatDateTimeToAmerican(new Date())
    pecaParaCancelar.codkit_peo = null

    if (motivoVendaPerdida) {
      pecaParaCancelar.codmcv_peo = motivoVendaPerdida.codigo_mcv
    }

    pecasBalcao.splice(indexPecaBalcao, 1, pecaParaCancelar)

    pecasBalcao.sort((a, b) => Number(a.iscan_peo) - Number(b.iscan_peo))

    orcamentoBalcaoForm.setFieldValue('pecasBalcao', pecasBalcao)

    calculaValoresOrcamento(pecasBalcao)
    onHide()
  }

  async function validaLimiteDescontoPorcentagem () {
    if (pecaBalcaoForm.values.perdes_peo > 100) {
      showWarnMessage('Valor não pode exceder 100% de desconto')
      return false
    } else {
      if (!pecaBalcaoForm.values.usapreqtd_peo) {
        const parMargem = CommonHelper.getMargem(pecaBalcaoForm.values.cusuni_peo, pecaBalcaoForm.values.valbru_peo)
        const codEmp = orcamentoBalcaoForm.values.codemp_opv
        const limitePorcentagemDesconto = await PecaBalcaoService.getLimiteDesconto(parMargem, codEmp)

        if (pecaBalcaoForm.values.perdes_peo > limitePorcentagemDesconto) {
          const restricaoParaVisualizarLimiteDesconto = await RestricaoService.getByCodigo(25)
          const adicionarPecasComLimiteDescontoExcedido = await PermissaoService.getByCodigo('183')

          const descricaoDescontoMaximo = `O desconto máximo por margem de lucro é de ${formatToPercentage(limitePorcentagemDesconto)}, você solicitou ${formatToPercentage(pecaBalcaoForm.values.perdes_peo)}`

          if (adicionarPecasComLimiteDescontoExcedido) {
            showWarnMessage(restricaoParaVisualizarLimiteDesconto ? 'Desconto máximo para a peça excedido!' : descricaoDescontoMaximo)
          } else {
            showErrorMessage(`Usuário sem permissão para exceder o limite de desconto. ${restricaoParaVisualizarLimiteDesconto ? '' : descricaoDescontoMaximo}`)

            pecaBalcaoForm.setFieldValue('perdes_peo', 0)
            pecaBalcaoForm.setFieldValue('valdes_peo', 0)
            return false
          }
        }
      }
      return true
    }
  }

  async function adicionarPecas () {
    let produtoJaEstaNoOrcamento = false
    for (const pecaBalcao of orcamentoBalcaoForm.values.pecasBalcao) {
      if (!pecaBalcao.iscan_peo && pecaBalcao.produto.codigo_pro === produtoSelecionado.codigo_pro && !pecaBalcaoSelecionada?.codpec_peo) {
        produtoJaEstaNoOrcamento = true
      }
    }

    if (produtoJaEstaNoOrcamento) {
      setVisibleConfirmModal(true)
    }

    const descontoValidado = await validaLimiteDescontoPorcentagem()
    if (descontoValidado && !produtoJaEstaNoOrcamento) {
      await pecaBalcaoForm.submitForm()
    }
  }
  function somarQuantidadeAPecaJaExistente () {
    let quantidadeJaFoiSomada = false

    const { pecasBalcao } = orcamentoBalcaoForm.values

    for (const pecaBalcao of pecasBalcao) {
      if (!pecaBalcao.iscan_peo && !quantidadeJaFoiSomada && pecaBalcao.produto.codigo_pro === produtoSelecionado.codigo_pro) {
        pecaBalcao.qtd_peo += pecaBalcaoForm.values.qtd_peo

        atualizaDescontoPeca(pecaBalcao)
        calculaValorTotalPeca(pecaBalcao)

        quantidadeJaFoiSomada = true
      }
    }

    orcamentoBalcaoForm.setFieldValue('pecasBalcao', pecasBalcao)

    pecaBalcaoForm.resetForm()
    onHide()
    setVisibleConfirmModal(false)
  }

  async function cancelarModalAdicionaPecaExistente () {
    setVisibleConfirmModal(false)
    const descontoValidado = await validaLimiteDescontoPorcentagem()
    if (descontoValidado) {
      await pecaBalcaoForm.submitForm()
    }
  }

  const adicionarPecasEnter = (event) => {
    if (event.key === 'Enter' && edicaoHabilitada && produtoSelecionado?.codigo_pro) {
      if ((edicaoHabilitada && !pecaBalcaoForm.values.iscan_peo && produtoSelecionado)) {
        adicionarPecas()
        setProdutoSelecionado({})
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', adicionarPecasEnter)
    return () => {
      window.removeEventListener('keydown', adicionarPecasEnter)
    }
  }, [edicaoHabilitada, produtoSelecionado])

  return (
    <>
      <Modal
        visible={visible}
        onHide={onHide}
        header={'Peça' + (pecaBalcaoForm.values.codpec_peo || pecaBalcaoForm.values?.produto?.codigo_pro ? ` - ${pecaBalcaoForm.values?.codpec_peo || pecaBalcaoForm.values?.produto?.codigo_pro}` : '')}
        btnSalvarLabel={pecaBalcaoSelecionada ? 'Atualizar peça' : 'Adicionar peça'}
        icon={pecaBalcaoSelecionada ? '' : 'pi pi-plus'}
        width={40}
        btnSalvar={(edicaoHabilitada && !pecaBalcaoForm.values.iscan_peo) ? adicionarPecas : undefined}
        btnExcluir={
          (pecaBalcaoSelecionada && edicaoHabilitada && !pecaBalcaoForm.values.iscan_peo)
            ? (pecaBalcaoForm.values.codigo_peo
              ? openModalConfirmacaoOuMotivoVendaPerdida
              : excluirPecaBalcao)
            : undefined}
        btnExcluirLabel={pecaBalcaoForm.values.codigo_peo ? 'Cancelar peça' : null}
      >
        <DadosPecaBalcaoOpv
          form={pecaBalcaoForm}
          onHide={onHide}
          orcamentoBalcaoForm={orcamentoBalcaoForm}
          edicaoHabilitada={edicaoHabilitada && !pecaBalcaoForm.values.iscan_peo}
          pecaBalcaoSelecionada={pecaBalcaoSelecionada}
          camposObrigatorios={camposObrigatorios}
          calculaValorTotalPeca={calculaValorTotalPeca}
          getDadosPrecoProduto={getDadosPrecoProduto}
          atualizaDescontoPeca={atualizaDescontoPeca}
          permitirDescontoByTipoPreco={permitirDescontoByTipoPreco}
          setProdutoSelecionado={setProdutoSelecionado}
        />

      </Modal>
      <MotivoVendaPerdidaModal
        visible={visibleMotivoVendaPerdidaModal}
        onHide={() => setVisibleMotivoVendaPerdidaModal(false)}
        onConfirm={cancelarPecaBalcao}
        camposObrigatorios={camposObrigatorios}
      />
      <Confirm
        title="Peça já adicionada no orçamento!"
        description="Deseja que a quantidade informada seja somada no peça já existente?"
        visible={visibleConfirmModal}
        onCancel={() => cancelarModalAdicionaPecaExistente()}
        onConfirm={() => somarQuantidadeAPecaJaExistente()}
      />
      <Confirm
        visible={visibleConfirm}
        onCancel={() => setVisibleConfirm(false)}
        onConfirm={cancelarPecaBalcao}
        title="Confirma cancelar a peça?"
        description="Você tem certeza que deseja cancelar essa peça do orçamento?"
      />
    </>
  )
}

export default DadosPecasOpvModal
