export const useValidateInput = (formik) => {
  const isFormFieldValid = (field) => {
    const [name, attribute, childAttribute] = field.split('.')

    const fieldDepth = field.split('.').length

    if (formik.touched[name] && formik.errors[name]) {
      switch (fieldDepth) {
      case 1:
        return Boolean(formik.errors[name])
      case 2:
        return Boolean(formik.errors[name][attribute])
      case 3:
        return Boolean(formik.errors[name][childAttribute])
      default:
        break
      }
    }

    return
  }

  const getFormErrorMessage = (field) => {
    const [name, attribute, childAttribute] = field.split('.')

    const fieldDepth = field.split('.').length

    if (isFormFieldValid(name)) {
      switch (fieldDepth) {
      case 1:
        return formik.errors[name]
      case 2:
        return formik.errors[name][attribute]
      case 3:
        return formik.errors[name][childAttribute]
      default:
        break
      }
    }

    return isFormFieldValid(name) && formik.errors[name]
  }

  return {
    isFormFieldValid,
    getFormErrorMessage
  }
}
