export const getDeParaModeloDTO = (data = {}) => {
  return {
    codigo_mdd: data.codigo_mdd || null,
    codmodtur_mdd: data.codmodtur_mdd || null,
    codmodaug_mdd: data.codmodaug_mdd || null
  }
}

export const postDeParaModeloDTO = (data) => {
  return {
    codigo_mdd: data.codigo_mdd || null,
    codmodtur_mdd: data.codmodtur_mdd || null,
    codmodaug_mdd: data.codmodaug_mdd || null
  }
}
