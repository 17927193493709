import * as Yup from 'yup'

export const OrcamentoBalcaoValidator = Yup.object().shape({

  cliente: Yup.object().shape({
    codigo_pes: Yup.number()
      .required('O cliente deve ser selecionado!')
  }),
  consultor: Yup.object().shape({
    codigo_pes: Yup.number()
      .required('O consultor deve ser selecionado!')
  }),
  tipomidia: Yup.object().shape({
    codigo_tim: Yup.number()
      .nullable()
      .test('testeObrigarMidia', 'Informe o tipo de mídia!', function (value) {
        if (this.parent.obrigarTipoMidia && !value) {
          return false
        }

        return true
      })
  }),
  pecasBalcao: Yup.array().test('peca-nao-cancelada', 'Pelo menos uma peça deve ser adicionada!', function (value) {
    if (!value || !value.length) {
      return false
    }
    return value.some(peca => peca.iscan_peo == false)
  }),

  condicaoPagamento: Yup.object().shape({
    codigo_cop: Yup.number()
      .required('Informe a condição de pagamento!')
  })
})
