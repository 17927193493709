import React, { useEffect, useState } from 'react'
import { AutoComplete } from 'primereact/autocomplete'
import { Checkbox } from 'primereact/checkbox'
import PessoaAutocompleteTemplate from '../../../../../components/inputs/options/PessoaAutocompleteTemplate'
import SelectInput from '../../../../../components/inputs/SelectInput'
import TextInput from '../../../../../components/inputs/TextInput'
import PessoaJuridicaService from '../../../../../services/cadastro/pessoa/PessoaJuridicaService'
import ContabilistaService from '../../../../../services/cadastro/pessoa/ContabilistaService'
import { showErrorMessage } from '../../../../../components/utils/Message'

const DadosFiscaisPej = ({ form, edicaoHabilitada }) => {
  const [sugestoesContabilista, setSugestoesContabilista] = useState([])
  const [regimesTributacao, setRegimesTributacao] = useState([])
  const [regimesTributacaoEspecial, setRegimesTributacaoEspecial] = useState([])

  useEffect(() => {
    getRegimesTributacao()
  }, [])

  const handleChangeCheckbox = (e) => {
    form.setFieldValue(e.target.name, !form.values[e.target.name])
  }

  const getRegimesTributacao = async () => {
    try {
      const regimesTributacao = await PessoaJuridicaService.getAllRegimesTributacao()
      const regimesTributacaoEspecial = await PessoaJuridicaService.getAllRegimesTributacaoEspecial()
      setRegimesTributacao(regimesTributacao)
      setRegimesTributacaoEspecial(regimesTributacaoEspecial)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar os regimes de tributação!')
    }
  }

  const sugerirContabilista = async () => {
    try {
      const contabilistas = await ContabilistaService.filterAutoComplete(
        `nomraz_pes=${form.values.contabilista.pessoa?.nomraz_pes}`
      )

      const results = contabilistas.map(contabilista => {
        return {
          ...contabilista,
          pessoa: {
            nomraz_pes: contabilista.nomraz_pes,
            cgccpf_pes: contabilista.cgccpf_pes
          }
        }
      })

      setSugestoesContabilista(results)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado !')
    }
  }

  const handleChangeContabilista = (e) => {
    form.setFieldValue('contabilista.pessoa.nomraz_pes', String(e.target.value))

    if (form.values.contabilista.pessoa.nomraz_pes === '') {
      form.setFieldValue('contabilista.codigo_cnt', null)
      form.setFieldValue('contabilista.pessoa', {})
    }
  }

  return (
    <div className="formgrid grid">
      <div className="field col-12 md:col-3">
        <TextInput label="Insc. SUFRAMA" placeholder="Insc. SUFRAMA" name="numsuf_pej"
          value={form.values.numsuf_pej} onChange={form.handleChange}
          disabled={!edicaoHabilitada} className="inputfield w-full" />
      </div>
      <div className="field col-12 md:col-3">
        <TextInput label="Insc. municipal" placeholder="Insc. municipal" name="insmun_pes"
          value={form.values.insmun_pes} onChange={form.handleChange}
          disabled={!edicaoHabilitada} className="inputfield w-full" />
      </div>
      <div className="field col-12 md:col-3">
        <TextInput label="CNAE" placeholder="CNAE" name="cnae_pej" maxLength={7}
          value={form.values.cnae_pej} onChange={form.handleChange}
          disabled={!edicaoHabilitada} className="inputfield w-full" />
      </div>
      <div className="field col-12 md:col-3">
        <TextInput label="NIRE" placeholder="NIRE" name="nire_pej"
          value={form.values.nire_pej} onChange={form.handleChange}
          disabled={!edicaoHabilitada} className="inputfield w-full" />
      </div>
      <div className="field col-12 md:col-6">
        <label className="label">Contabilista</label>
        <AutoComplete
          field="pessoa.nomraz_pes"
          value={form.values.contabilista.pessoa.nomraz_pes && form.values.contabilista}
          minLength={4}
          suggestions={sugestoesContabilista}
          completeMethod={sugerirContabilista}
          onChange={handleChangeContabilista}
          onSelect={(e) => form.setFieldValue('contabilista', e.value)}
          itemTemplate={PessoaAutocompleteTemplate}
          placeholder="Contabilista"
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-3">
        <SelectInput
          value={form.values.regimeTributacao}
          options={regimesTributacao}
          onChange={form.handleChange}
          label="Regime de tributação"
          name="regimeTributacao"
          optionLabel="descri_ret"
          dataKey="codigo_ret"
          noFloatLabel
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
          showClear={form.values.regimeTributacao?.codigo_ret}
        />
      </div>
      <div className="field col-12 md:col-3">
        <SelectInput
          value={form.values.regimeTributacaoEspecial}
          options={regimesTributacaoEspecial}
          onChange={form.handleChange}
          label="Regime de tributação especial"
          name="regimeTributacaoEspecial"
          optionLabel="descri_rte"
          dataKey="codigo_rte"
          noFloatLabel
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
          showClear={form.values.regimeTributacaoEspecial?.codigo_rte}
        />
      </div>
      <div className="field checkbox-container col-12 md:col-3">
        <Checkbox inputId="inccul_pej" name="inccul_pej" value={form.values.inccul_pej}
          onChange={handleChangeCheckbox} checked={form.values.inccul_pej} disabled={!edicaoHabilitada} />
        <label htmlFor="inccul_pej" className="p-checkbox-label mb-0 pl-2">Incentivador Cultural</label>
      </div>
      <div className="field checkbox-container col-12 md:col-3">
        <Checkbox inputId="incfis_pej" name="incfis_pej" value={form.values.incfis_pej}
          onChange={handleChangeCheckbox} checked={form.values.incfis_pej} disabled={!edicaoHabilitada} />
        <label htmlFor="incfis_pej" className="p-checkbox-label mb-0 pl-2">Incentivo Fiscal</label>
      </div>
      <div className="field checkbox-container col-12 md:col-3">
        <Checkbox inputId="retcsr_pej" name="retcsr_pej" value={form.values.retcsr_pej}
          onChange={handleChangeCheckbox} checked={form.values.retcsr_pej} disabled={!edicaoHabilitada} />
        <label htmlFor="retcsr_pej" className="p-checkbox-label mb-0 pl-2">Reter PIS/COFINS/CSLL</label>
      </div>
      <div className="field checkbox-container col-12 md:col-3">
        <Checkbox inputId="retiss_pej" name="retiss_pej" value={form.values.retiss_pej}
          onChange={handleChangeCheckbox} checked={form.values.retiss_pej} disabled={!edicaoHabilitada} />
        <label htmlFor="retiss_pej" className="p-checkbox-label mb-0 pl-2">Reter ISS</label>
      </div>
    </div>
  )
}

export default DadosFiscaisPej
