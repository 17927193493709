import { formatDateToAmerican, formatDateToInputDate } from '../../../helpers/formaters'

export const getTituloManutencaoDTO = (data = {}) => {
  return {
    codigo_tma: data.codigo_tma || null,
    valor_tma: data.valor_tma || 0,
    datven_tma: data.datven_tma ? formatDateToInputDate(data.datven_tma) : null,
    codfoc_tma: data.codfoc_tma || null,
    descri_foc: data.descri_foc || '',
    formaCobranca: data.formaCobranca || { codigo_foc: data.codigo_foc, descri_foc: data.descri_foc }
  }
}

export const postTituloManutencaoDTO = (data) => {
  return {
    codigo_tma: data.codigo_tma || null,
    ordem_tma: data.ordem_tma || null,
    valor_tma: data.valor_tma || 0,
    datven_tma: data.datven_tma ? formatDateToAmerican(data.datven_tma) : null,
    codfoc_tma: data.codfoc_tma || null,
    codigo_foc: data.codigo_foc || null,
    descri_foc: data.descri_foc || '',
    formaCobranca: data.formaCobranca || { codigo_foc: data.codigo_foc, descri_foc: data.descri_foc }
  }
}
