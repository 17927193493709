import './_negociosFechados.scss'

import { formatDate, formatToCurrency } from '../../../../helpers/formaters'

import AlteracaoFoPagNegModal from '../alteracaoFoPagNeg/AlteracaoFoPagNegModal'
import AppButton from '../../../../components/layout/AppButton'
import CheckboxInput from '../../../../components/inputs/CheckboxInput'
import CommonHelper from '../../../../helpers/CommonHelper'
import Container from '../../../../components/layout/Container'
import DataList from '../../../../components/layout/DataList'
import DateInput from '../../../../components/inputs/DateInput'
import List from '../../../../classes/List'
import { NegociacaoModel } from '../../../../models/vendas/negociacao/NegociacaoModel'
import NegociacaoService from '../../../../services/vendas/negociacao/NegociacaoService'
import Page from '../../../../components/layout/Page'
import PreferencesButton from '../../../../components/utils/PreferencesButton'
import React from 'react'
import TablePreferences from '../../../../components/utils/TablePreferences'
import { classNames } from 'primereact/utils'
import { createColumns, getColunasHabilitadas } from '../../../../helpers/tableConfigs'
import { showErrorMessage } from '../../../../components/utils/Message'
import EmpresasAcessoTreeSelect from '../../../../components/inputs/empresasAcessoTreeSelect/EmpresasAcessoTreeSelect'

export class NegociosFechados extends List {
  constructor (props) {
    super(
      props,
      NegociacaoModel,
      NegociacaoService,
      'negociacao',
      'codigo_neg',
      20,
      'fechadas'
    )
    this.state = {
      ...this.state,
      visiblePreferencesModal: false,
      visibleAlteracaoFoPagNegModal: false,
      dataNegInicial: '',
      dataNegFinal: '',
      operacaoNegCompra: 'Compra',
      operacaoNegVenda: 'Venda',
      operacaoNegTroca: 'Troca',
      operacaoNegConsignacao: 'Consignação',
      expandedRows: null,
      negociacao: {},
      selectedOptionsEmpresas: null,
      empresas: []
    }

    this.setColumns(createColumns([
      { field: 'codigo_neg', header: 'Número', width: '10%' },
      { field: 'descri_emp', header: 'Empresa', width: '15%' },
      { field: 'descri_vei', header: 'Veículo', width: '25%' },
      { field: 'data_neg', header: 'Data', width: '10%', customBody: this.getDataNegociacao },
      { field: 'operac_neg', header: 'Operação', width: '10%', customBody: this.getOperacaoNeg, customClassName: this.getOperacaoNegClassName.bind(this) },
      { field: 'nomraz_pes', header: 'Cliente', width: '20%' },
      { field: 'valorTotalNeg', header: 'Valor', width: '10%', customBody: this.getValorTotalNeg }
    ]))
  }

  getDataNegociacao (data) {
    return formatDate(data.data_neg)
  }

  getOperacaoNeg (data) {
    let operacaoNeg = 'Compra'
    switch (data.operac_neg) {
    case 2:
      operacaoNeg = 'Venda'
      break
    case 5:
      operacaoNeg = 'Consignação'
      break
    case 98:
      operacaoNeg = 'Troca'
      break
    }

    return operacaoNeg
  }

  getOperacaoNegClassName (data) {
    return `operacaoneg-${this.getOperacaoNeg(data)}`
  }

  getValorTotalNeg (data) {
    return formatToCurrency(data.valorTotalNeg, true)
  }

  onFilterClick = () => {
    const filter = this.state.filter
    filter.dataNegociacaoInicial = CommonHelper.dateToAmerican(this.state.dataNegInicial)
    filter.dataNegociacaoFinal = CommonHelper.dateToAmerican(this.state.dataNegFinal)
    filter.operacaoNegCompra = this.state.operacaoNegCompra ? 'Compra' : 'Sem Compra'
    filter.operacaoNegVenda = this.state.operacaoNegVenda ? 'Venda' : 'Sem Venda'
    filter.operacaoNegTroca = this.state.operacaoNegTroca ? 'Troca' : 'Sem Troca'
    filter.operacaoNegConsignacao = this.state.operacaoNegConsignacao ? 'Consignação' : 'Sem Consignação'
    filter.empresas = this.state.empresas
    this.setState({ filter })
    this.onFilter()
  }

  expandedFiltersTemplate = () => (
    <>
      <div className="field col-12 md:col-12">
        <EmpresasAcessoTreeSelect
          label="Empresas"
          selectedOptions={this.state.selectedOptionsEmpresas}
          changeSelectedOptions={(selectedOptions) => this.setState({ selectedOptionsEmpresas: selectedOptions })}
          changeSelectedEmpresas={(empresas) => this.setState({ empresas: empresas.map(empresa => empresa.codigo_pes) })}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label className="label">Data de finalização da negociação</label>
        <div className="formgrid grid">
          <div className={'col-12 md:col-6 ' + (CommonHelper.isDesktop() ? '' : 'mb-3 mt-2')}>
            <DateInput
              name="dataNegInicial"
              placeholder="Data Inicial"
              className="inputfield w-full"
              value={this.state.dataNegInicial}
              onChange={(e) => this.setState({ dataNegInicial: e.target.value })} />
          </div>
          <div className="col-12 md:col-6">
            <DateInput
              name="dataNegociacaoFinal"
              placeholder="Data Final"
              className="inputfield w-full"
              value={this.state.filter.dataNegFinal}
              onChange={(e) => this.setState({ dataNegFinal: e.target.value })} />
          </div>
        </div>
      </div>
      <div className="field col-12 md:col-6">
        <label className="label">Operação</label>
        <div className="formgrid grid">
          <div className="field col-12 md:col-3">
            <CheckboxInput
              inputId="chkFiltroVenda"
              value={this.state.operacaoNegVenda}
              label="Venda"
              checked={this.state.operacaoNegVenda}
              onChange={(e) => this.setState({ operacaoNegVenda: !e.target.value })}/>
          </div>
          <div className="field col-12 md:col-3">
            <CheckboxInput
              inputId="chkFiltroCompra"
              value={this.state.operacaoNegCompra}
              label="Compra"
              checked={this.state.operacaoNegCompra}
              onChange={(e) => this.setState({ operacaoNegCompra: !e.target.value })} />
          </div>
          <div className="field col-12 md:col-3">
            <CheckboxInput
              inputId="chkFiltroTroca"
              value={this.state.operacaoNegTroca}
              label="Troca"
              checked={this.state.operacaoNegTroca}
              onChange={(e) => this.setState({ operacaoNegTroca: !e.target.value })} />
          </div>
          <div className="field col-12 md:col-3">
            <CheckboxInput
              inputId="chkFiltroConsignacao"
              value={this.state.operacaoNegConsignacao}
              label="Consignação"
              checked={this.state.operacaoNegConsignacao}
              onChange={(e) => this.setState({ operacaoNegConsignacao: !e.target.value })} />
          </div>
        </div>
      </div>
      <div className="col-12 flex justify-content-end mb-4">
        <AppButton label="Buscar" icon="pi pi-search" onClick={this.onFilterClick} />
      </div>
    </>
  )

  render () {
    const { rows, totalRecords, first } = this.state
    const columns = this.columns

    const onSelectOption = (option) => {
      let { filter } = this.state
      switch (option) {
      case 'fechadas':
        filter.status_neg = '3'
        localStorage.setItem('status_neg', 3)
        break
      case 'consignadas':
        filter.status_neg = '4'
        localStorage.setItem('status_neg', 4)
        break
      case 'canceladas':
        filter.status_neg = '2'
        localStorage.setItem('status_neg', 2)
        break
      }
      this.setState({ filter, opcaoSelecionada: option }, this.onFilter)
    }
    let headerStatusNeg = Number(localStorage.getItem('status_neg'))
    // verifica se o que está sendo listado é o mesmo que está nas opções "Fechados, Consignados, Cancelados"
    if (headerStatusNeg !== this.state.list[0]?.status_neg) {
      headerStatusNeg = this.state.list[0]?.status_neg
    }

    const rowExpansionTemplate = (data) => {
      function negociacaoFeitaPeloWeb () {
        return data.codapp_neg === 2
      }

      return (
        <div className={'negocios-fechados row-expansion grid justify-content-' + (CommonHelper.isDesktop() ? 'end' : 'center')}>
          {negociacaoFeitaPeloWeb() && (
            <AppButton
              label="Alterar pagamento"
              onClick={async () => {
                try {
                  const negociacaoService = new NegociacaoService()
                  const negociacao = await negociacaoService.get(data.codigo_neg)
                  this.setState({ negociacao: negociacao, visibleAlteracaoFoPagNegModal: true })
                } catch (error) {
                  showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar a negociação!')
                }
              }}
              className={(CommonHelper.isDesktop() ? 'mr-3' : 'mb-3') + ' negocios-fechados button'}
            />
          )}
          <AppButton
            label="Visualizar negócio"
            onClick={() => this.onView(data)}
            className="negocios-fechados button"
          />
        </div>
      )
    }

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Negócios Fechados</h4>
        </div>
        <div className="table-options-container">
          <div className="table-options">
            <div className={classNames({ active: headerStatusNeg === 3 }, 'filter-option')}
              onClick={() => onSelectOption('fechadas')}>
              <span className="option-label">Fechados</span>
              <div className="option-quantity">{this.state.meta?.quantidadeFechadas}</div>
            </div>
            <div className={classNames({ active: headerStatusNeg === 4 }, 'filter-option')}
              onClick={() => onSelectOption('consignadas')}>
              <span className="option-label">Consignados</span>
              <div className="option-quantity">{this.state.meta?.quantidadeConsignadas}</div>
            </div>
            <div className={classNames({ active: headerStatusNeg === 2 }, 'filter-option')}
              onClick={() => onSelectOption('canceladas')}>
              <span className="option-label">Cancelados</span>
              <div className="option-quantity">{this.state.meta?.quantidadeCanceladas}</div>
            </div>
          </div>
          <div className="table-preferences">
            <PreferencesButton
              onClick={() => this.setState({ visiblePreferencesModal: true })}
            />
          </div>
        </div>
        <Container>
          <DataList
            data={this.state.list}
            selected={this.state.selected}
            rows={rows}
            onSelect={this.onSelect}
            onRowClick={(e) => this.handleRowExpansion(e.data)}
            totalRecords={totalRecords}
            first={first}
            onPage={this.onPageChange}
            page={this.state.page}
            showFilter={true}
            filterPlaceholder="Procurar negócio"
            filterName="descricaoFiltro"
            responsive={true}
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
            expandedFiltersTemplate={this.expandedFiltersTemplate}
            expandedRows={this.state.expandedRows}
            rowExpansionTemplate={rowExpansionTemplate}
          >
            {getColunasHabilitadas(columns, 'negociosFechados')}
          </DataList>
          <AlteracaoFoPagNegModal
            visible={this.state.visibleAlteracaoFoPagNegModal}
            hide={() => this.setState({ visibleAlteracaoFoPagNegModal: false })}
            negociacao={this.state.negociacao}
          />
        </Container>
        <TablePreferences
          title="Preferências da tabela de negócios"
          tableName="negociosFechados"
          columns={columns}
          visible={this.state.visiblePreferencesModal}
          hide={() => this.setState({ visiblePreferencesModal: false })}
        />
      </Page>
    )
  }
}

export default NegociosFechados
