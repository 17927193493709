import ServiceBase from '../../base/ServiceBase'

export default class FinanceiraService {
  static async getAll () {
    return await ServiceBase.getBase('financeiras')
  }

  static async filterBancos () {
    return await ServiceBase.getBase('bancos')
  }
}
