import ServiceBase from '../../base/ServiceBase'

export default class CidadesService {
  static async getAll () {
    return await ServiceBase.getBase('cities')
  }

  static async getAllByState (stateId) {
    return await ServiceBase.getBase(`cities/${stateId}`)
  }

  static async getAllByStateInitials (stateInitials) {
    return await ServiceBase.getBase(`cities/state/${stateInitials}`)
  }

  static async getCity (cityId) {
    return await ServiceBase.getBase(`city/${cityId}`)
  }

  static async filterAutocomplete (query) {
    return await ServiceBase.getBase(`cities?${query}`, false)
  }
}
