import React from 'react'
import { Crud } from '../../../../classes/_Crud'
import Container from '../../../../components/layout/Container'
import Page from '../../../../components/layout/Page'
import { getAvaliacaoDTO } from '../../../../dtos/vendas/negociacao/avaliacao/AvaliacaoDTO'
import AvaliacaoForm from '../../../../forms/vendas/negociacao/avaliacao/AvaliacaoForm'
import AvaliacaoService from '../../../../services/cadastro/veiculo/AvaliacaoService'

export class Avaliacao extends Crud {
  constructor (props) {
    super(props, getAvaliacaoDTO, AvaliacaoService, 'avaliacoes')
  }

  render () {
    const { model, dataLoaded, isNew } = this.state

    return (
      <Page>
        <Container>
          {(dataLoaded || isNew) &&
            <AvaliacaoForm
              model={model}
              isNew={isNew}
            />
          }
        </Container>
      </Page>
    )
  }
}
