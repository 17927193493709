import { ServiceBaseConectaCarros as ServiceBase } from '../../base/ServiceBaseConectaCarros'

export default class ConectaCarrosService {
  static async getMarcas (tipoVeiculo, anoModelo) {
    let url = 'getMarcas?'
    if (tipoVeiculo) url += `codigo_tipoveiculo=${tipoVeiculo}&`
    if (anoModelo) url += `ano_veiculo=${anoModelo}`
    return await ServiceBase.getBase(url)
  }

  static async getModelos (codigoMarca, anoModelo) {
    let url = 'getModelos?'
    if (codigoMarca) url += `codigo_marca=${codigoMarca}&`
    if (anoModelo) url += `ano_veiculo=${anoModelo}`
    return await ServiceBase.getBase(url)
  }

  static async getVersoes (codigoMarca, codigoModelo, anoModelo) {
    let url = 'getVersoes?'
    if (codigoMarca) url += `codigo_marca=${codigoMarca}&`
    if (codigoModelo) url += `codigo_modelo=${codigoModelo}&`
    if (anoModelo) url += `ano_veiculo=${anoModelo}`
    return await ServiceBase.getBase(url)
  }

  static async getModeloFipe (codigoFipe) {
    let url = 'getDadosFipe?'
    if (codigoFipe) url += `codigo_fipe=${codigoFipe}`
    return await ServiceBase.getBase(url)
  }

  static async getDadosFipeByConectaCarros (codigoConectaCarros, codigoTipoVeiculo) {
    let url = 'getDadosFipeByConectaCarros?'
    if (codigoConectaCarros) url += `codigo_conectacarros=${codigoConectaCarros}&`
    if (codigoTipoVeiculo) url += `codigo_tipoveiculo=${codigoTipoVeiculo}`
    return await ServiceBase.getBase(url)
  }

  static async getValorFipeAtual (codigoFipeConectaCarros) {
    return await ServiceBase.getBase(`getValorFipeAtual?codigo_fipe=${codigoFipeConectaCarros}`)
  }

  static async getDadosPlaca (placa) {
    return await ServiceBase.getBase(`buscarDadosPlaca?placa=${placa}`)
  }
}
