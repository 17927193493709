import React from 'react'
import { Crud } from '../../../classes/NewCrud'
import { getManutencaoDTO } from '../../../dtos/cadastro/manutencao/ManutencaoDTO'
import ManutencaoForm from '../../../forms/cadastro/manutencao/ManutencaoForm'
import ManutencaoService from '../../../services/cadastro/manutencao/ManutencaoService'

export function Manutencao (props) {
  return (
    <Crud
      match={props.match}
      onSaveModal={props.onSaveModal}
      onDeleteModal={props.onDeleteModal}
      codigoSelecionado={props.codigoSelecionado}
      onHide={props.onHide}
      apenasVisualizacao={props.apenasVisualizacao}
      formContent={ManutencaoForm}
      modelGetDTO={getManutencaoDTO}
      service={ManutencaoService}
      resource="manutencoes"
      primarykeyname="codigo_man"
      formTitle="Manutenção"
    />
  )
}
