import React, { Component } from 'react'

class Container extends Component {
  render () {
    return (
      <div className="p-grid">
        <div className={`p-col-${this.props.col || '12'} ${this.props.className || ''}`}>
          <h1 className="container-title">{this.props.title}</h1>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default Container
