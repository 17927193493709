import './_manutencao.scss'
import React, { useEffect, useState } from 'react'
import AccordionContainer from '../../../components/layout/AccordionContainer'
import AccordionPage from '../../../components/layout/AccordionPage'
import DadosPrincipaisMan from './formPages.js/DadosPrincipaisMan'
import ItensMan from './formPages.js/ItensMan'
import { formatToCurrency } from '../../../helpers/formaters'
import PagamentosMan from './formPages.js/PagamentosMan'
import ManutencaoService from '../../../services/cadastro/manutencao/ManutencaoService'
import { showErrorMessage, showSuccessMessage } from '../../../components/utils/Message'
import CommonHelper from '../../../helpers/CommonHelper'
import AppButton from '../../../components/layout/AppButton'

const ManutencaoForm = ({ form, camposObrigatorios, edicaoHabilitada, setEdicaoHabilitada, setActions, setHeader, setFooter, onSave }) => {
  const situacaoManAberta = 1
  const situacaoManFinalizada = 2
  const situacaoManCancelada = 3

  const [totalItensManutencao, setTotalItensManutencao] = useState(null)

  const actionsWhenManutencaoAberta = [
    { label: 'Finalizar', command: async () => await finalizarManutencao() },
    { label: 'Imprimir', command: async () => await imprimirManutencao() },
    { label: 'Cancelar', command: async () => await cancelarManutencao() }
  ]

  const actionsWhenManutencaoFinalizada = [
    { label: 'Cancelar', command: async () => await cancelarManutencao() },
    { label: 'Imprimir', command: async () => await imprimirManutencao() }
  ]

  const actionsWhenManutencaoCancelada = [
    { label: 'Reabrir', command: async () => await reabrirManutencao() }
  ]

  useEffect(() => {
    updateValorTotalItensManutencao()
  }, [])

  useEffect(() => {
    setManutencaoHeader()
    setManutencaoFooter()
    setActionsBySituacao()
    setEdicaoHabilitadaBySituacao()
  }, [form.values])

  function setManutencaoFooter () {
    setFooter(<div className="flex justify-content-end pb-6 pt-3">
      <AppButton
        type="submit"
        label="Salvar e finalizar"
        className="p-button"
        onClick={async () => await salvarEFinalizar()}
      />
      <AppButton
        type="submit"
        label="Salvar"
        className="p-button ml-3"
        onClick={async () => await onSave(form.values)}
      />
    </div>)
  }

  function setActionsBySituacao () {
    switch (form.values.codstm_man) {
    case situacaoManAberta:
      setActions(actionsWhenManutencaoAberta)
      break
    case situacaoManFinalizada:
      setActions(actionsWhenManutencaoFinalizada)
      break
    case situacaoManCancelada:
      setActions(actionsWhenManutencaoCancelada)
      break
    default:
      if (form.values.codigo_man) {
        setActions(actionsWhenManutencaoAberta)
      }
      break
    }
  }

  function setEdicaoHabilitadaBySituacao () {
    switch (form.values.codstm_man) {
    case situacaoManAberta:
      setEdicaoHabilitada(true)
      break
    case situacaoManFinalizada:
    case situacaoManCancelada:
      setEdicaoHabilitada(false)
      break
    }
  }

  function setManutencaoHeader () {
    let labelSituacao = ''

    switch (form.values.codstm_man) {
    case situacaoManAberta:
      const quantidadeDiasEmManutencao = CommonHelper.dateDiff(Date.now(), form.values.datsai_man)
      const quantidadeDiasRetornoAtrasado = CommonHelper.dateDiff(Date.now(), form.values.datpre_man)

      labelSituacao = 'Veículo em manutenção '

      if (quantidadeDiasEmManutencao > 0) {
        const pluralOuSingular = quantidadeDiasEmManutencao === 1 ? '' : 's'
        labelSituacao += `há ${quantidadeDiasEmManutencao} dia${pluralOuSingular}. `
      }

      if (quantidadeDiasRetornoAtrasado > 0) {
        const pluralOuSingular = quantidadeDiasRetornoAtrasado === 1 ? '' : 's'
        labelSituacao += `(retorno atrasado há ${quantidadeDiasRetornoAtrasado} dia${pluralOuSingular})`
      }

      break
    case situacaoManFinalizada:
      labelSituacao = 'Finalizada'
      break
    case situacaoManCancelada:
      labelSituacao = 'Cancelada'
      break
    }

    setHeader(<div>
      <h1>Manutenção {form.values.codigo_man ? `- ${form.values.codigo_man}` : ''}</h1>
      <div className="descrição-situacao-manutencao">{labelSituacao}</div>
    </div>)
  }

  async function cancelarManutencao () {
    try {
      await ManutencaoService.cancelarManutencao(form.values.codigo_man)

      await form.setFieldValue('codstm_man', situacaoManCancelada)

      showSuccessMessage('Manutenção cancelada com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar cancelar a manutenção!')
    }
  }

  async function reabrirManutencao () {
    try {
      await ManutencaoService.reabrirManutencao(form.values.codigo_man)

      await form.setFieldValue('codstm_man', situacaoManAberta)

      showSuccessMessage('Manutenção reaberta com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar reabrir a manutenção!')
    }
  }

  async function finalizarManutencao (codigoManutencao) {
    try {
      await ManutencaoService.finalizarManutencao(codigoManutencao || form.values.codigo_man)

      await form.setFieldValue('codstm_man', situacaoManFinalizada)

      showSuccessMessage('Manutenção finalizada com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar finalizar a manutenção!')
    }
  }

  async function imprimirManutencao () {
    try {
      const pdf = await ManutencaoService.getManutencaoReport(form.values.codigo_man)
      CommonHelper.openFile('application/pdf', pdf, `Ordem-Serviço-${form.values.codigo_man}.pdf`)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar imprimir a manutenção!')
    }
  }

  async function salvarEFinalizar () {
    const codigoManutencao = await onSave(form.values)

    if (codigoManutencao) {
      await finalizarManutencao(codigoManutencao)
    }
  }

  function updateValorTotalItensManutencao () {
    const itensManutencao = form.values.itensManutencao

    const valoresItensManutencao = itensManutencao.map(itemManutencao => itemManutencao.valtot_itm)

    const valorTotalItensManutencao = valoresItensManutencao.reduce((previousValue, currentValue) => previousValue + currentValue, 0)

    setTotalItensManutencao(valorTotalItensManutencao)
  }

  return (
    <AccordionContainer>
      <AccordionPage header="Dados Principais" active>
        <DadosPrincipaisMan
          form={form}
          edicaoHabilitada={edicaoHabilitada}
          camposObrigatorios={camposObrigatorios}
        />
      </AccordionPage>
      <AccordionPage header={'Itens' + (totalItensManutencao ? ` - ${formatToCurrency(totalItensManutencao)}` : '')} active>
        <ItensMan
          form={form}
          edicaoHabilitada={edicaoHabilitada}
          updateValorTotalItensManutencao={updateValorTotalItensManutencao}
        />
      </AccordionPage>
      <AccordionPage header="Pagamento">
        <PagamentosMan
          form={form}
          edicaoHabilitada={edicaoHabilitada}
        />
      </AccordionPage>
    </AccordionContainer>
  )
}

export default ManutencaoForm
