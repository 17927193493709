import { formatDateToAmerican, formatDateToInputDate } from '../../../helpers/formaters'


export const postGerarTituloOpvDTO = (data) => {

  if (data.parcelas && data.parcelas.length > 0) {
    data.parcelas.forEach((parcela, index) => {
      data.parcelas[index].vencimento = formatDateToAmerican(parcela.vencimento)
    })
  }

  return {
    codigo_cop: data.codigo_cop || null,
    descri_cop: data.descri_cop || "",
    qtdpar_cop: data.qtdpar_cop || 0,
    perdes_cop: data.perdes_cop || 0,
    peracr_cop: data.peracr_cop || 0,
    peredi_cop: data.peredi_cop || false,
    restrito_cop: data.restrito_cop || false,
    valorFinal: data.valorFinal || 0,
    valorEntrada: data.valorEntrada || 0,
    valorParcela: data.valorParcela || 0,
    dataPrimeiroVencimento: formatDateToAmerican(data.dataPrimeiroVencimento),
    dataEmissao: formatDateToAmerican(data.dataEmissao),
    resumoParcelas: data.resumoParcelas || "",
    parcelas: data.parcelas || []
  }
}
