import { postModeloDTO } from '../../../dtos/cadastro/veiculo/ModeloDTO'
import ServiceBase from '../../base/ServiceBase'

export default class ModeloService {
  async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`/modelos?page=${page}&perPage=${perPage}`)
  }

  async get (id) {
    return await ServiceBase.getBase(`/modelos/${id}`)
  }

  async save (data) {
    return await ServiceBase.postBase('/modelos', postModeloDTO(data))
  }

  async getFormValidator () {
    return await ServiceBase.getBase('/modelos/validator')
  }

  async delete (codigo_mod) {
    return await ServiceBase.deleteBase(`/modelos/${codigo_mod}`)
  }

  static async get (id) {
    return await ServiceBase.getBase(`/modelos/${id}`)
  }

  static async filter (query) {
    return await ServiceBase.getBase(`/modelos?${query}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`/modelos?${query}&page=${page}&perPage=${perPage}`)
  }
}
