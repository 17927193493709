import { postGrupoUsuarioDTO } from '../../../dtos/cadastro/grupoUsuario/GrupoUsuarioDTO'
import ServiceBase from '../../base/ServiceBase'

export default class GrupoService {
  async get (id) {
    return await ServiceBase.getBase(`/grupos/${id}`)
  }

  async save (data) {
    return await ServiceBase.postBase('/grupos', postGrupoUsuarioDTO(data))
  }

  async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`grupos?page=${page}&perPage=${perPage}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`grupos?${query}&page=${page}&perPage=${perPage}`)
  }

  async delete ({ codigo_gru }) {
    await ServiceBase.deleteBase(`/grupos/${codigo_gru}`)
  }

  static async getAll () {
    return await ServiceBase.getBase('grupos')
  }

  static async replicar ({ codigo_emp, codigo_gru }) {
    return await ServiceBase.postBase('grupos/replicar', { codigo_emp, codigo_gru })
  }
}
