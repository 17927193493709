import * as queryString from 'query-string'

export default class BaseModel {
  toFilter () {
    const validFields = Object
      .keys(this)
      .filter(it => !!this[it])
      .map(it => ({ [it]: this[it] }))

    let fields = {}

    validFields.forEach(it => {
      fields = { ...it, ...fields }
    })

    return queryString.stringify(fields)
  }
}
