import ServiceBase from '../base/ServiceBase'

export default class LucroDeVendasReportService {
  async getAll () {
    return await ServiceBase.getBase('lucrodevendasreport')
  }

  async filter (query) {
    return await ServiceBase.getBase(`lucrodevendasreport?${query}`)
  }
}
