import List from '../../../classes/List'
import { showErrorMessage } from '../../../components/utils/Message'
import { GerenciamentoEstoqueVeiculoModel } from '../../../models/vendas/negociacao/GerenciamentoEstoqueVeiculoModel'
import { decrementActiveLoadingRequests, incrementActiveLoadingRequests, startLoading, stopLoading } from '../../../redux/actions'
import { dispatch } from '../../../redux/store'
import VeiculoService from '../../../services/cadastro/veiculo/VeiculoService'
import GerenciamentoEstoqueVeiculoService from '../../../services/vendas/negociacao/GerenciamentoEstoqueVeiculoService'
import { persistenciaVendaVeiculo } from './PersistenciaVenderVeiculo'

class VenderVeiculo extends List {
  constructor (props) {
    super(props, GerenciamentoEstoqueVeiculoModel, GerenciamentoEstoqueVeiculoService, '', 'codpro_pre', 25)
  }

    handleVenderVeiculo = async (dadosVeiculo) => {
      try {
        const veiculosValidosParaVenda = []
        const veiculoService = new VeiculoService()
        localStorage.setItem('empresaOferta', dadosVeiculo.ofeemp_vei)

        dispatch(incrementActiveLoadingRequests())
        dispatch(startLoading())
        if (dadosVeiculo.itemConjuntoVeiculo && dadosVeiculo.itemConjuntoVeiculo.conjuntoVeiculo.ativo_cjv) {
          const itensConjunto = dadosVeiculo.itemConjuntoVeiculo.conjuntoVeiculo.itensConjunto

          for (const itemConjunto of itensConjunto) {
            const veiculoConjunto = await veiculoService.get(itemConjunto.codvei_icj)

            if (await persistenciaVendaVeiculo.persistenciaParaVenderVeiculo(veiculoConjunto)) {
              veiculosValidosParaVenda.push(veiculoConjunto)
            }
          }

          if (itensConjunto.length !== veiculosValidosParaVenda.length) {
            dispatch(decrementActiveLoadingRequests())
            dispatch(stopLoading())
            return
          }
        } else {
          if (await persistenciaVendaVeiculo.persistenciaParaVenderVeiculo(dadosVeiculo)) {
            const veiculo = await veiculoService.get(dadosVeiculo.codigo_vei)
            veiculosValidosParaVenda.push(veiculo)
          }
        }

        if (veiculosValidosParaVenda.length === 0) {
          dispatch(decrementActiveLoadingRequests())
          dispatch(stopLoading())
          return
        }

        let negociacao

        if (veiculosValidosParaVenda.length > 1) {
          negociacao = {
            conjuntoVeiculos: veiculosValidosParaVenda,
            operacao: 'Venda'
          }
        } else {
          negociacao = {
            veiculoVenda: veiculosValidosParaVenda[0],
            operacao: 'Venda'

          }
        }

        dispatch(decrementActiveLoadingRequests())
        dispatch(stopLoading())

        localStorage.setItem('negociacao', JSON.stringify(negociacao))
        return true
      } catch (error) {
        console.error(error)
        dispatch(stopLoading())
        dispatch(decrementActiveLoadingRequests())
        showErrorMessage(error.message || 'Ocorreu um erro inesperado!')
      }
    }
}

export const venderVeiculo = new VenderVeiculo()
