import React from 'react'
import Modal from '../../../../components/utils/Modal'

import { ReactComponent as CarroBloqueado } from '../../../../assets/icones-analise-estoque/carro-bloqueado-ativado.svg'
import { ReactComponent as CarroPatio } from '../../../../assets/icones-analise-estoque/carro-patio-ativado.svg'
import { ReactComponent as CarroReservado } from '../../../../assets/icones-analise-estoque/carro-reservado-ativado.svg'
import { ReactComponent as EmDemonstracao } from '../../../../assets/icones-analise-estoque/em-demonstracao-ativado.svg'
import { ReactComponent as KmAlta } from '../../../../assets/icones-analise-estoque/km-alta-ativado.svg'
import { ReactComponent as OrdemServico } from '../../../../assets/icones-analise-estoque/ordem-servico.svg'
import CommonHelper from '../../../../helpers/CommonHelper'

function ModalAnaliseEstoqueAlertas ({ visible, onHide, alertaPatio, alertaOrdemServico, AlertaReservado, AlertaBloqueado, alertaDemonstracao, alertaKMAlta, diasPatio, clienteReserva, vendedorReserva, vendedorBloqueio, descricaoBloqueio, localizacaoDemonstracao, diasDemonstracao }) {
  function renderizaAlerta (Component, label, cor) {
    return <div className='flex'>
      <p className='m-1 text-wrap flex'> <span className='mr-2'>< Component color={cor}/> </span> {label} </p>
    </div>
  }
  return (
    <Modal
      visible={visible}
      onHide={onHide}
      header="Alertas"
      width={40}
    >
      <div className="flex flex-column">
        {alertaPatio
          ? renderizaAlerta(CarroPatio, ` No pátio há ${diasPatio} ${diasPatio > 1 ? 'dias' : 'dia'}. (Alerta a partir de 30 dias)`, '#EC6A00') : renderizaAlerta(CarroPatio, ' No pátio. (Alerta a partir de 30 dias)', '#B4B4B4')
        }

        {alertaOrdemServico
          ? renderizaAlerta(OrdemServico, ' Ordens de serviço em aberto.', '#EC6A00') : renderizaAlerta(OrdemServico, ' Ordens de serviço em aberto.', '#B4B4B4') }
        {AlertaReservado
          ? renderizaAlerta(CarroReservado, ` Reservado por ${vendedorReserva} para ${CommonHelper.removerCaracteresEspeciais(clienteReserva)}`, '#EC6A00') : renderizaAlerta(CarroReservado, ' Reservado.', '#B4B4B4') }

        {AlertaBloqueado
          ? renderizaAlerta(CarroBloqueado, ` Bloqueado para venda por ${vendedorBloqueio}. (${descricaoBloqueio})`, '#BA0303')
          : renderizaAlerta(CarroBloqueado, ' Bloqueado. ', '#B4B4B4')
        }

        {alertaDemonstracao
          ? renderizaAlerta(EmDemonstracao, ` Em demonstração há ${diasDemonstracao} ${diasDemonstracao > 1 ? 'dias' : 'dia'} (${localizacaoDemonstracao})`, '#EC6A00')
          : renderizaAlerta(EmDemonstracao, ' Em demonstração.', '#B4B4B4')}

        {alertaKMAlta
          ? renderizaAlerta(KmAlta, ' KM alta - KMVeiculo / (AnoAtual - AnoFabricação) maior que 10.000', '#EC6A00')
          : renderizaAlerta(KmAlta, ' KM alta.', '#B4B4B4')}

      </div>
    </Modal>
  )
}

export default ModalAnaliseEstoqueAlertas
