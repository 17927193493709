import CommonHelper from '../../../helpers/CommonHelper'
import { formatDateToAmerican } from '../../../helpers/formaters'

export const getPessoaJuridicaDTO = (data = {}) => {
  data.conveniados = data.conveniados || []
  data.conveniados = data.conveniados.map(conveniado => ({
    codigo_pes: conveniado.pessoaConveniada.codigo_pes,
    nomraz_pes: conveniado.pessoaConveniada.nomraz_pes,
    ativo_cvp: conveniado.ativo_cvp
  }))
  
  return {
    codigo_pes: data.codigo_pes || null,
    nomraz_pes: data.nomraz_pes || null,
    apelid_pes: data.apelid_pes || null,
    end_pes: data.end_pes || null,
    bairro_pes: data.bairro_pes || null,
    cidade_pes: data.cidade_pes || null,
    uf_pes: data.uf_pes || null,
    pais_pes: data.pais_pes || null,
    cep_pes: data.cep_pes || null,
    email_pes: data.email_pes || null,
    emafin_pes: data.emafin_pes || null,
    numend_pes: data.numend_pes || null,
    codemp_pes: data.codemp_pes || null,
    cgccpf_pes: data.cgccpf_pes || null,
    ierg_pes: data.ierg_pes || null,
    ie_pes: data.ie_pes || null,
    telcel_pes: data.telcel_pes || null,
    telcom_pes: data.telcom_pes || null,
    telres_pes: data.telres_pes || null,
    insmun_pes: data.insmun_pes || null,
    comend_pes: data.comend_pes || null,
    codmun_pes: data.codmun_pes || null,
    codpai_pes: data.codpai_pes || null,
    usuins_pes: data.usuins_pes || null,
    refban_pes: data.refban_pes || '',
    datins_pes: data.datins_pes || new Date(),
    dahalt_pes: data.dahalt_pes || null,
    numcom_pes: data.numcom_pes || null,
    cepcom_pes: data.cepcom_pes || null,
    endcom_pes: data.endcom_pes || null,
    comcom_pes: data.comcom_pes || null,
    muncom_pes: data.muncom_pes || null,
    barcom_pes: data.barcom_pes || null,
    numcob_pes: data.numcob_pes || null,
    cepcob_pes: data.cepcob_pes || null,
    endcob_pes: data.endcob_pes || null,
    comcob_pes: data.comcob_pes || null,
    muncob_pes: data.muncob_pes || null,
    barcob_pes: data.barcob_pes || null,
    modalidadeVenda: data.modalidadeVenda || {},
    empresa: data.pessoa?.empresa || {},
    pais: data.pessoa?.pais || {},
    estado: data.pessoa?.estado || {},
    municipio: data.pessoa?.municipio || {},
    municipioComercial: data.municipioComercial || {},
    municipioCobranca: data.municipioCobranca || {},
    idrad_pes: data.idrad_pes || null,
    telsac_pes: data.telsac_pes || null,
    cliata_pes: data.cliata_pes || false,
    parcei_pes: data.parcei_pes || false,
    classificacao: data.classificacao || {},
    grupo: data.grupo || {},
    categoria: data.categoria || {},
    tabelaDesconto: data.tabelaDesconto || {},
    autorizacaoContatos: data.autorizacaoContatos || [],
    ...data.pessoa,
    codigo_pej: data.codigo_pej || null,
    codret_pej: data.codret_pej || null,
    fornec_pej: data.fornec_pej || null,
    ramati_pej: data.ramati_pej || null,
    vercon_pej: data.vercon_pej || null,
    empcon_pej: data.empcon_pej || null,
    concon_pej: data.concon_pej || null,
    telcon_pej: data.telcon_pej || null,
    financ_pej: data.financ_pej || null,
    transp_pej: data.transp_pej || null,
    despac_pej: data.despac_pej || null,
    forofi_pej: data.forofi_pej || 0,
    nomfan_pej: data.nomfan_pej || null,
    obs_pej: data.obs_pej || '',
    codcnt_pej: data.codcnt_pej || null,
    numsuf_pej: data.numsuf_pej || null,
    cnae_pej: data.cnae_pej || null,
    codpea_pej: data.codpea_pej || null,
    nire_pej: data.nire_pej || null,
    retiss_pej: data.retiss_pej || 0,
    retcsr_pej: data.retcsr_pej || 0,
    isconv_pej: data.isconv_pej || null,
    fidfor_pej: data.fidfor_pej || 0,
    codadq_pej: data.codadq_pej || null,
    inccul_pej: data.inccul_pej || 0,
    incfis_pej: data.incfis_pej || 0,
    codrte_pej: data.codrte_pej || 0,
    cliente: data.cliente || {},
    socios: data.socios || [],
    conveniados: data.conveniados || [],
    intven_pej: data.intven_pej || null,
    usuarioInsercao: data.pessoa?.usuarioInsercao || {},
    usuarioAlteracao: data.pessoa?.usuarioAlteracao || {},
    regimeTributacao: data.regimeTributacao || {},
    regimeTributacaoEspecial: data.regimeTributacaoEspecial || {},
    diapag_cli: data.diapag_cli || data.cliente?.payment_day || null,
    datfun_pej: data.datfun_pej ? new Date(data.datfun_pej) : null,
    nofpecnom_pej: data.nofpecnom_pej || 'S',
    nofsernom_pej: data.nofsernom_pej || 'S',
    contabilista: data.contabilista || { pessoa: {} },
    urlsit_pej: data.urlsit_pej,
    tipoPreco: data.pessoa?.tipoPreco || [],
    conveniado: data?.conveniado || [],
    ...data.pessoaJuridica
  }
}

export const postPessoaJuridicaDTO = (data) => {
  return {
    codtpr_pes: data.tipoPreco?.codigo_tpr || null,
    codigo_pes: data.codigo_pej || null,
    nomraz_pes: data.nomraz_pes || null,
    apelid_pes: data.apelid_pes || null,
    end_pes: data.end_pes || null,
    bairro_pes: data.bairro_pes || null,
    cidade_pes: data.cidade_pes || null,
    uf_pes: data.uf_pes || null,
    pais_pes: data.pais_pes || null,
    cep_pes: CommonHelper.clearDigitsSpecialChars(data.cep_pes) || null,
    email_pes: data.email_pes ? data.email_pes.toLowerCase() : '',
    emafin_pes: data.emafin_pes ? data.emafin_pes.toLowerCase() : null,
    numend_pes: data.numend_pes || null,
    codemp_pes: data.codemp_pes || null,
    cgccpf_pes: CommonHelper.clearDigitsSpecialChars(data.cgccpf_pes) || null,
    ierg_pes: CommonHelper.clearDigitsSpecialChars(data.ierg_pes) || null,
    ie_pes: data.ie_pes || '',
    insmun_pes: data.insmun_pes || null,
    telcel_pes: CommonHelper.clearDigitsSpecialChars(data.telcel_pes) || null,
    telcom_pes: CommonHelper.clearDigitsSpecialChars(data.telcom_pes) || null,
    telres_pes: CommonHelper.clearDigitsSpecialChars(data.telres_pes) || null,
    comend_pes: data.comend_pes || null,
    codmun_pes: data.codmun_pes || null,
    codpai_pes: data.codpai_pes || null,
    usuins_pes: data.usuins_pes || null,
    refban_pes: data.refban_pes || '',
    datins_pes: formatDateToAmerican(data.datins_pes || new Date()),
    codigo_pej: data.codigo_pej || null,
    codret_pej: data.regimeTributacao?.codigo_ret || null,
    fornec_pej: data.fornec_pej || null,
    datfun_pej: data.datfun_pej ? formatDateToAmerican(data.datfun_pej) : null,
    ramati_pej: data.ramati_pej || null,
    vercon_pej: data.vercon_pej || null,
    empcon_pej: data.empcon_pej || null,
    concon_pej: data.concon_pej || null,
    telcon_pej: data.telcon_pej || null,
    financ_pej: data.financ_pej || false,
    transp_pej: data.transp_pej || false,
    despac_pej: data.despac_pej || false,
    forofi_pej: data.forofi_pej || false,
    nomfan_pej: data.nomfan_pej || null,
    obs_pej: data.obs_pej || '',
    codcnt_pej: data.contabilista?.codigo_cnt || null,
    numsuf_pej: data.numsuf_pej || null,
    cnae_pej: data.cnae_pej || null,
    codpea_pej: data.codpea_pej || null,
    nire_pej: data.nire_pej || null,
    retiss_pej: data.retiss_pej || 0,
    retcsr_pej: data.retcsr_pej || 0,
    isconv_pej: data.isconv_pej || false,
    fidfor_pej: data.fidfor_pej || false,
    intven_pej: data.intven_pej || false,
    nofpecnom_pej: data.nofpecnom_pej || 'S',
    nofsernom_pej: data.nofsernom_pej || 'S',
    codadq_pej: data.codadq_pej || null,
    inccul_pej: data.inccul_pej || 0,
    incfis_pej: data.incfis_pej || 0,
    codrte_pej: data.regimeTributacaoEspecial?.codigo_rte || 0,
    socios: data.socios || null,
    conveniados: data.conveniados || null,
    idrad_pes: data.idrad_pes || null,
    telsac_pes: data.telsac_pes || null,
    cliata_pes: data.cliata_pes || false,
    parcei_pes: data.parcei_pes || false,
    numcom_pes: data.numcom_pes || null,
    cepcom_pes: data.cepcom_pes || null,
    endcom_pes: data.endcom_pes || null,
    comcom_pes: data.comcom_pes || null,
    muncom_pes: data.municipioComercial?.id || null,
    barcom_pes: data.barcom_pes || null,
    numcob_pes: data.numcob_pes || null,
    cepcob_pes: data.cepcob_pes || null,
    endcob_pes: data.endcob_pes || null,
    comcob_pes: data.comcob_pes || null,
    muncob_pes: data.municipioCobranca?.id || null,
    barcob_pes: data.barcob_pes || null,
    diapag_cli: data.diapag_cli || null,
    codgup_pes: data.grupo?.codigo_gup || null,
    codcat_pes: data.categoria?.codigo_cat || null,
    codcap_pes: data.classificacao?.codigo_cap || null,
    codtad_pes: data.tabelaDesconto?.codigo_tad || null,
    codmov_pes: data.modalidadeVenda?.codigo_mov || null,
    autorizacaoContatos: data.autorizacaoContatos || [],
    urlsit_pej: data.urlsit_pej
  }
}
