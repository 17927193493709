import React, { useState } from 'react'
import TextAreaInput from '../../../../components/inputs/TextAreaInput'
import Confirm from '../../../../components/utils/Confirm'
import { formatDateTime } from '../../../../helpers/formaters'

function InformacoesAdicionaisOpv ({ form, edicaoHabilitada }) {
  const [visibleConfirm, setVisibleConfirm] = useState(false)
  return (
    <div className="formgrid grid fluid">
      <div className="field col-12 md:col-6">
        <TextAreaInput
          label="Observações internas"
          name="obs_opv"
          className="inputfield w-full"
          cols={1000}
          rows={4}
          value={form.values.obs_opv}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
        />
      </div>
      <div className="field col-12 md:col-6">
        <TextAreaInput
          label="Observações da nota fiscal"
          name="obsnof_opv"
          className="inputfield w-full"
          rows={4}
          value={form.values.obsnof_opv}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
        />
      </div>
      <div className="form-divider mt-4"></div>
      <div className='w-full'>

        <div className='md:flex sm:text-sm'>

          <div className="field col-12 md:col-6 md:mr-2 border-1 border-200 " style={{ borderRadius: '20px' }}>
            <p>Inserido por {form.values.usuarioInsercao?.pessoa.nomraz_pes} em {form.values.dahins_opv ? formatDateTime(form.values.dahins_opv) : ''}</p>
          </div>

          <div className="field col-12 md:col-6 border-1 border-200 " style={{ borderRadius: '20px' }}>
            <p>Alterado por {form.values.usuarioUltimaAlteracao?.pessoa.nomraz_pes} em {form.values.dahalt_opv ? formatDateTime(form.values.dahalt_opv) : ''}</p>
          </div>

        </div>
        <div className='md:flex sm:text-sm'>

          <div className="field col-12 md:col-6 md:mr-2 border-1 border-200 " style={{ borderRadius: '20px' }}>
            <p>Cancelado por {form.values.usuarioCancelamento?.pessoa.nomraz_pes} em {form.values.dahcan_opv ? formatDateTime(form.values.dahcan_opv) : ''}</p>

          </div>

          <div className="field col-12 md:col-6 md:mr-2  border-1 border-200 " style={{ borderRadius: '20px' }}>
            <p>Entregue por {form.values.usuarioQueEntregouPecas?.pessoa.nomraz_pes} em {form.values.dahent_opv ? formatDateTime(form.values.dahent_opv) : ''}</p>

          </div>

        </div>
        <div className='md:flex sm:text-sm'>

          <div className="field col-12 md:col-6 border-1 md:mr-2 border-200 " style={{ borderRadius: '20px' }}>
            <p>Enviado para o caixa por {form.values.usuarioEnviouCaixa?.pessoa.nomraz_pes} em {form.values?.dahenv_opv ? formatDateTime(form.values?.dahenv_opv) : ''}</p>

          </div>
          <div className="field col-12 md:col-6 md:mr-2 border-1 border-200 " style={{ borderRadius: '20px' }}>
            <p>Separado por {form.values?.usuarioSeparacao?.usuarioSeparacaoPeca?.pessoa?.nomraz_pes} em { form.values?.usuarioSeparacao?.dahsep_peo ? formatDateTime(form.values?.usuarioSeparacao?.dahsep_peo) : ''}</p>

          </div>

        </div>

      </div>
      <Confirm
        visible={visibleConfirm}
        onCancel={() => setVisibleConfirm(false)}
        onConfirm={() => {
          form.setFieldValue('ispres_opv', true)
          form.setFieldValue('valfre_opv', 0.00)
          setVisibleConfirm(false)
        }}
        title="Confirma trocar o local da operação?"
        description="Suas informações de frete serão perdidas."
      />
    </div>
  )
}

export default InformacoesAdicionaisOpv
