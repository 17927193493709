import { classNames } from 'primereact/utils'
import React, { useEffect } from 'react'
import DateInput from '../../../../../../components/inputs/DateInput'
import SelectInput from '../../../../../../components/inputs/SelectInput'
import TextAreaInput from '../../../../../../components/inputs/TextAreaInput'
import TextInput from '../../../../../../components/inputs/TextInput'
import ResponsavelPagamento from '../../../../../../components/layout/ResponsavelPagamento/ResponsavelPagamento'
import FieldErrorMessage from '../../../../../../components/utils/FieldErrorMessage'
import Modal from '../../../../../../components/utils/Modal'
import RequiredLabel from '../../../../../../components/utils/RequiredLabel'
import { formatToCurrency } from '../../../../../../helpers/formaters'
import { useValidateInput } from '../../../../../../helpers/useValidateInput'
import FoPagNeg from '../_FoPagNeg'
import { ConsorcioValidator } from './ConsorcioValidator'

const FoPagConsorcio = (props) => {
  const {
    form,
    edicaoHabilitada,
    handleChangeCurrency,
    handleTipoFinanciamento,
    getFinanceiras,
    getTiposRestricaoVeiculo,
    setFinanceira,
    setRestricao,
    setValorVeiculo,
    dados,
    hide,
    isLojaPaga,
    excluir,
    isNew
  } = props

  const deveSelecionarVeiculo = props.negociacaoForm.values.veiculosConjunto.length > 0

  useEffect(() => {
    const onLoad = async () => {
      form.setFieldValue('veiculo', props.veiculo)

      const financeiraOptions = await getFinanceiras()
      setFinanceira(dados.codfin_fpn, financeiraOptions)

      const restricaoOptions = await getTiposRestricaoVeiculo()
      setRestricao(dados.codtrv_fpn, restricaoOptions)

      if (isNew) {
        form.setFieldValue('isLojaPaga', isLojaPaga)
      }
    }

    onLoad()
  }, [])

  const handleChangePrimeiroVencimento = async (e) => {
    await form.handleChange(e)
    await form.setFieldTouched('primeiroVencimento')
    await form.validateField('primeiroVencimento')
  }

  const handleSelect = async (e) => {
    await form.handleChange(e)
    await form.setFieldTouched(e.target.name)
  }

  const { getFormErrorMessage, isFormFieldValid } = useValidateInput(form)

  return (
    <Modal
      header="Consórcio"
      btnSalvar={edicaoHabilitada ? form.submitForm : undefined}
      btnExcluir={(!isNew && edicaoHabilitada) ? excluir : undefined}
      width="75"
      visible={true}
      modal={true}
      onHide={hide}
    >
      <div className="formgrid grid">
        <div className="field col-12 flex justify-content-around mb-4">
          <ResponsavelPagamento isLojaPaga={isNew ? isLojaPaga : form.values.isLojaPaga} />
        </div>
        <div className={'field col-12 md:col-' + (deveSelecionarVeiculo ? '6' : '9')}>
          <RequiredLabel label="Financeira" valid={isFormFieldValid('financeira.codigo_fin')} />
          <SelectInput
            value={form.values.financeira}
            options={form.values.financeiraOptions}
            onChange={handleSelect}
            placeholder="- Selecione -"
            name="financeira"
            optionLabel="pessoa.nomraz_pes"
            noFloatLabel
            disabled={!edicaoHabilitada}
            className={classNames({ 'p-invalid': isFormFieldValid('financeira.codigo_fin') }, 'inputfield w-full')}
            onHide={() => form.setFieldTouched('financeira.codigo_fin')}
          />
          <FieldErrorMessage message={getFormErrorMessage('financeira.codigo_fin')} />
        </div>
        {deveSelecionarVeiculo && (
          <div className="field col-12 md:col-3">
            <RequiredLabel label="Veículo" valid={isFormFieldValid('veiculo.codigo_vei')} />
            <SelectInput
              value={form.values.veiculo}
              options={props.negociacaoForm.values.veiculosConjunto.map(veiculoNve => veiculoNve.veiculo)}
              onChange={(e) => form.setFieldValue('veiculo', e.target.value)}
              name='veiculo'
              optionLabel="placha"
              dataKey="codigo_vei"
              disabled={!edicaoHabilitada}
              className={classNames({ 'p-invalid': isFormFieldValid('veiculo.codigo_vei') }, 'inputfield w-full')}
              noFloatLabel
            />
            <FieldErrorMessage message={getFormErrorMessage('veiculo.codigo_vei')} />

          </div>
        )}
        <div className="field col-12 md:col-3">
          <RequiredLabel label="Valor" valid={isFormFieldValid('valor')} />
          <TextInput
            name="valor"
            value={formatToCurrency(form.values.valor)}
            disabled={!edicaoHabilitada}
            onChange={handleChangeCurrency}
            className={classNames({ 'p-invalid': isFormFieldValid('valor') }, 'inputfield w-full')}
            onBlur={() => {
              setValorVeiculo()
              form.setFieldTouched('valor')
            }}
          />
          <FieldErrorMessage message={getFormErrorMessage('valor')} />
        </div>
        <div className="field col-12 md:col-6">
          <RequiredLabel label="Restrição" valid={isFormFieldValid('restricao.codigo_trv')} />
          <SelectInput
            value={form.values.restricao}
            options={form.values.restricaoOptions}
            optionLabel="descri_trv"
            onChange={async (e) => {
              await handleTipoFinanciamento(e)
              form.setFieldTouched('restricao')
            }}
            laceholder="- Selecione -"
            name="restricao"
            noFloatLabel
            disabled={!edicaoHabilitada}
            className={classNames({ 'p-invalid': isFormFieldValid('restricao.codigo_trv') }, 'inputfield w-full')}
            onHide={() => form.setFieldTouched('restricao.codigo_trv')}
          />
          <FieldErrorMessage message={getFormErrorMessage('restricao.codigo_trv')} />
        </div>

        <div className="field col-12 md:col-3">
          <RequiredLabel label="Grupo" valid={isFormFieldValid('grupo')} />
          <TextInput
            name="grupo"
            value={form.values.grupo}
            disabled={!edicaoHabilitada}
            onChange={form.handleChange}
            keyfilter="int"
            className={classNames(
              { 'p-invalid': isFormFieldValid('grupo') },
              'inputfield w-full'
            )}
            onBlur={() => form.setFieldTouched('grupo')}
          />
          <FieldErrorMessage message={getFormErrorMessage('grupo')} />
        </div>
        <div className="field col-12 md:col-3">
          <RequiredLabel label="Cota" valid={isFormFieldValid('cota')} />
          <TextInput
            name="cota"
            value={form.values.cota}
            disabled={!edicaoHabilitada}
            onChange={form.handleChange}
            keyfilter="int"
            className={classNames(
              { 'p-invalid': isFormFieldValid('cota') },
              'inputfield w-full'
            )}
            onBlur={() => form.setFieldTouched('cota')}
          />
          <FieldErrorMessage message={getFormErrorMessage('cota')} />
        </div>
        <div className="field col-12 md:col-3">
          <RequiredLabel label="Data de Emissão" valid={isFormFieldValid('dataEmissao')} />
          <DateInput
            name="dataEmissao"
            value={form.values.dataEmissao}
            disabled={!edicaoHabilitada}
            onChange={form.handleChange}
            className={classNames({ 'p-invalid': isFormFieldValid('dataEmissao') }, 'inputfield w-full')}
            onBlur={() => form.setFieldTouched('dataEmissao')}
          />
          <FieldErrorMessage message={getFormErrorMessage('dataEmissao')} />
        </div>
        <div className="field col-12 md:col-3">
          <RequiredLabel label="Primeiro vencimento" valid={isFormFieldValid('primeiroVencimento')} />
          <DateInput
            name="primeiroVencimento"
            disabled={!edicaoHabilitada}
            value={form.values.primeiroVencimento}
            onChange={handleChangePrimeiroVencimento}
            onBlur={() => form.setFieldTouched('primeiroVencimento')}
            className={classNames({ 'p-invalid': isFormFieldValid('primeiroVencimento') }, 'inputfield w-full')}
          />
          <FieldErrorMessage message={getFormErrorMessage('primeiroVencimento')} />
        </div>
        <div className="field col-12 md:col-3">
          <TextInput
            name="valorParcela"
            label="Valor da parcela"
            value={formatToCurrency(form.values.valorParcela)}
            onChange={handleChangeCurrency}
            disabled={!edicaoHabilitada}
            className="inputfield w-full"
          />
        </div>
        <div className="field col-12 md:col-3">
          <TextInput
            name="quantidadeParcelas"
            label="Quantidade de parcelas"
            value={form.values.quantidadeParcelas}
            onChange={form.handleChange}
            keyfilter="int"
            disabled={!edicaoHabilitada}
            className="inputfield w-full"
          />
        </div>
        <div className="field col-12 md:col-12">
          <TextAreaInput
            name="observacoes"
            label="Observações"
            value={form.values.observacoes}
            disabled={!edicaoHabilitada}
            onChange={form.handleChange}
            className="inputfield w-full"
          />
        </div>
      </div>
    </Modal>
  )
}

export default (props) =>
  FoPagNeg({
    Component: FoPagConsorcio,
    validationSchema: ConsorcioValidator,
    ...props
  })
