import { ScrollTop } from 'primereact/scrolltop'
import { classNames } from 'primereact/utils'
import React from 'react'
import List from '../../../../classes/List'
import AppButton from '../../../../components/layout/AppButton'
import Container from '../../../../components/layout/Container'
import DataList from '../../../../components/layout/DataList'
import Page from '../../../../components/layout/Page'
import Confirm from '../../../../components/utils/Confirm'
import { showErrorMessage, showSuccessMessage } from '../../../../components/utils/Message'
import PreferencesButton from '../../../../components/utils/PreferencesButton'
import TablePreferences from '../../../../components/utils/TablePreferences'
import CommonHelper from '../../../../helpers/CommonHelper'
import { formatDate, formatDateTime, formatPlacha } from '../../../../helpers/formaters'
import { createColumns, getColunasHabilitadas } from '../../../../helpers/tableConfigs'
import { GerenciamentoEstoqueVeiculoModel } from '../../../../models/vendas/negociacao/GerenciamentoEstoqueVeiculoModel'

import DateInput from '../../../../components/inputs/DateInput'
import ReportService from '../../../../services/reports/ReportService'
import EntregaVeiculoService from '../../../../services/vendas/negociacao/EntregaVeiculoService'

export class EntregaVeiculoList extends List {
  constructor (props) {
    super(props, GerenciamentoEstoqueVeiculoModel, EntregaVeiculoService, '', 'codigo_neg', 10)
    this.state = {
      ...this.state,
      expandedRows: null,
      dadosVeiculo: [],
      visibleConfirmacao: false,
      mostrarBotaoEntregue: false,
      id: null
    }

    var arrColunas = [
      { field: 'codigo_neg', header: 'Neg' },
      { field: 'nomecliente', header: 'Cliente' },
      { field: 'desmo1_vei', header: 'Veículo' },
      { field: 'situacao', header: 'Financeiro' },
      { field: 'entregaautorizada', header: 'Autorização', customBody: this.getAutorizado, customClassName: this.getDescricaoAutorizacaoClassName },
      { field: 'nomevendedor', header: 'Vendedor', enabled: false },
      { field: 'dataAprovacaoNeg', header: 'Aprovação', customBody: this.getDataAprovacao, enabled: false },
      { field: 'dahent_nve', header: 'Entrega', customBody: this.getDataEntrega },
      { field: 'dahpreent_nve', header: 'Previsão', customBody: this.getDataPrevisao }
    ]
    this.setColumns(createColumns(arrColunas))
  }

  async componentDidMount () {
    const { id } = this.props.match.params
    let { filter } = this.state

    if (id) {
      filter.descricaoFiltro = id
      this.setState({ filter, id })
    }
    await super.componentDidMount()
  }

  getDataAprovacao (data) {
    return data.dataAprovacaoNeg && formatDate(data.dataAprovacaoNeg)
  }

  getDataEntrega (data) {
    return data.dahent_nve && formatDateTime(data.dahent_nve)
  }

  getDataPrevisao (data) {
    return data.dahpreent_nve && formatDateTime(data.dahpreent_nve)
  }

  isVenda (data) {
    let operacaoNeg = 'Compra'
    switch (data.operac_neg) {
    case 2:
      operacaoNeg = 'Venda'
      break
    case 5:
      operacaoNeg = 'Consignação'
      break
    }
    return operacaoNeg === 'Venda' ? true : false
  }

  getAutorizado (data) {
    return data.entregaAutorizada === 1 ? 'A autorizar' : formatDateTime(data.dahaen_nve)
  }

  getDescricaoAutorizacaoClassName (data) {
    return `entrega-autorizada-${data.entregaAutorizada === 1 ? '1' : '2'}`
  }

  showConfirmacaoModal = (visibleConfirmacao) => {
    if (visibleConfirmacao) {
      return (
        <Confirm
          visible={this.state.visibleConfirmacao}
          onConfirm={() => {
            this.imprimirTermoEntregaSaida(this.state.dadosVeiculo)
            this.setState({ visibleConfirmacao: false })
          }}
          onCancel={() => this.setState({ visibleConfirmacao: false })}
          title="Imprimir termo de entrega"
          description="Gostaria de realizar a impressão do termo de entrega?"
        />
      )
    }
  }

  onSelectOption = (option) => {
    let { filter, mostrarBotaoEntregue } = this.state
    const { id } = this.props.match.params
    this.setState({ opcaoSelecionada: option })

    if (option === 't') {
      filter.entregue = false
      mostrarBotaoEntregue = false
      if (id) {
        filter.descricaoFiltro = id
      }
    }

    if (option === 'd') {
      filter.entregue = true
      mostrarBotaoEntregue = true
      if (id) {
        filter.descricaoFiltro = id
      }
    }

    this.setState({ filter, mostrarBotaoEntregue }, this.onFilter)
  }

  async downloadTermoEntregaSaida (data) {
    const placha = formatPlacha(data.placa_vei, data.chassi_vei)
    let pdf

    try {
      pdf = await ReportService.getTermoEntregaSaida(data.codigo_neg, data.codigo_nve)

      CommonHelper.openFile('application/pdf', pdf, `termo-entrega-saida-${placha}.pdf`)
    } catch (error) {
      console.error(error)
      showErrorMessage(error.message || `Ocorreu um erro inesperado ao tentar imprimir o termo de entrega para o veículo: ${placha}!`)
    }
  }

  async imprimirTermoEntregaSaida (data) {
    await this.downloadTermoEntregaSaida(data)
  }

  imprimirEntrega (data) {
    this.imprimirTermoEntregaSaida(data)
  }

  async entregarVeiculo (data) {
    try {
      await EntregaVeiculoService.entregarVeiculo(data)
      await this.getAll()
      showSuccessMessage('Veículo entregue!')
      this.setState({ visibleConfirmacao: true, dadosVeiculo: data })
      this.limparIdDaRota()
    } catch (error) {
      showErrorMessage(error.message || `Ocorreu um erro inesperado ao tentar entrega o veículo: ${data.placha}`)
    }
  }

  async cancelarEntrega (data) {
    try {
      await EntregaVeiculoService.cancelarEntrega(data)
      await this.getAll()
      showSuccessMessage('Entrega cancelada!')
    } catch (error) {
      showErrorMessage(error.message || `Ocorreu um erro inesperado ao tentar cancelar a entrega para o veículo: ${data.placha}`)
    }
  }

  async autorizarEntrega (data) {
    try {
      let { filter, id } = this.state
      await EntregaVeiculoService.autorizarEntrega(data)
      filter.descricaoFiltro = id
      this.setState({ filter })
      await this.getAll()
      showSuccessMessage('Entrega autorizada!')
    } catch (error) {
      showErrorMessage(error.message || `Ocorreu um erro inesperado ao tentar autorizar a entrega para o veículo: ${data.placha}`)
    }
  }

  async cancelarAutorizacaoEntrega (data) {
    try {
      let { filter, id } = this.state
      await EntregaVeiculoService.cancelarAutorizacaoEntrega(data)
      filter.descricaoFiltro = id
      this.setState({ filter })
      await this.getAll()
      showSuccessMessage('Autorização de entrega cancelada!')
    } catch (error) {
      showErrorMessage(error.message || `Ocorreu um erro inesperado ao tentar cancelar a autorização de entrega para o veículo: ${data.placha}`)
    }
  }

  expandedFiltersTemplate = () => (
    <>
      <div className="field col-12 md:col-4">
        <label className="label">Período de aprovação</label>
        <div className="formgrid grid">
          <div className="col-6">
            <DateInput placeholder="de" name="dataDe" value={this.state.filter.dataDe}
              onChange={this.handleChangeFilter} className="inputfield w-full" />
          </div>
          <div className="col-6">
            <DateInput placeholder="até" name="dataAte" value={this.state.filter.dataAte}
              onChange={this.handleChangeFilter} className="inputfield w-full" />
          </div>
        </div>
      </div>
      <div className="col-12 flex justify-content-end mb-4">
        <AppButton label="Buscar" icon="pi pi-search" onClick={this.onFilter} />
      </div>
    </>
  )

  limparIdDaRota = () => {
    this.props.history.push('/entrega-veiculo')
  }

  onChangeBusca = (e) => {
    let { filter, id } = this.state
    id = e.target.value
    filter.descricaoFiltro = e.target.value
    this.props.history.push(`/entrega-veiculo/${e.target.value}`)
    this.setState({ id, filter })
  }

  render () {
    const { state } = this
    const columns = this.columns

    const rowExpansionTemplate = (data) => {
      return (
        <div className="row-expansion grid" style={{ textAlign: '' }}>
          <div className="grid col-12 md:col-4">
          </div>
          <div className="grid col-12 md:col-8 mt-2">
            <div className="row-expansion-buttons col-12 flex mt-2 mb-0">
              {!state.mostrarBotaoEntregue &&
              <AppButton
                label="Autorizar"
                className="ml-1 row-expansion-button"
                disabled={data.entregaAutorizada === 2 ? true : false}
                onClick={() => this.autorizarEntrega(data)}
              />
              }
              {!state.mostrarBotaoEntregue &&
              <AppButton
                label="Cancelar autorização"
                className="ml-1 row-expansion-button "
                disabled={data.entregaAutorizada === 1 ? true : false}
                onClick={() => this.cancelarAutorizacaoEntrega(data)}
              />
              }
              {state.mostrarBotaoEntregue &&
              <AppButton
                label="Cancelar"
                className="ml-1 row-expansion-button "
                disabled={data.entregaAutorizada === 1 ? true : false}
                onClick={() => this.cancelarEntrega(data)}
              />
              }
              {!state.mostrarBotaoEntregue &&
              <AppButton
                label="Entregar"
                className="ml-1 row-expansion-button"
                disabled={data.entregaAutorizada === 1 ? true : false}
                onClick={() => this.entregarVeiculo(data)}
              />
              }
              <AppButton
                label="Visualizar negociação"
                className="ml-1 row-expansion-button"
                onClick={() => { this.props.history.push(`/negociacao/${data.codigo_neg}`) }}
              />
              {state.mostrarBotaoEntregue &&
              <AppButton
                label="Imprimir"
                className="ml-1 row-expansion-button"
                onClick={() => this.imprimirEntrega(data)}
                disabled={data.entregaAutorizada === 1 ? true : false}
              />
              }
            </div>
          </div>
        </div>
      )
    }

    return (
      <Page>
        {!CommonHelper.isDesktop() && (
          <ScrollTop threshold={1200} />
        )}
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Entrega de veículos</h4>
        </div>
        <div className="table-options-container">
          <div className="table-options">
            <div className={classNames({ active: state.opcaoSelecionada === 't' }, 'filter-option')}
              onClick={() => this.onSelectOption('t')}>
              <span className="option-label">Pendentes</span>
              <div className="option-quantity">{state?.meta?.quantidadePendentes}</div>
            </div>
            <div className={classNames({ active: state.opcaoSelecionada === 'd' }, 'filter-option')}
              onClick={() => this.onSelectOption('d')}>
              <span className="option-label">Entregues</span>
              <div className="option-quantity">{state?.meta?.quantidadeEntregue}</div>
            </div>
          </div>
          <div className="table-preferences">
            <PreferencesButton onClick={() => this.setState({ visiblePreferencesModal: true })} />
          </div>
        </div>
        <Container>
          <DataList
            data={state.list}
            selected={state.selected}
            onSelect={e => this.onSelect(e)}
            onRowClick={(e) => this.handleRowExpansion(e.data)}
            rows={state.rows}
            totalRecords={state.totalRecords}
            first={state.first}
            onPage={this.onPageChange}
            expandedRows={state.expandedRows}
            rowExpansionTemplate={rowExpansionTemplate}
            page={state.page}
            showFilter={true}
            filterPlaceholder="Procurar veículos"
            filterName="descricaoFiltro"
            onFilter={this.onFilter}
            filterValue={this.state.id}
            expandedFiltersTemplate={this.expandedFiltersTemplate}
            filterOnChange={this.onChangeBusca}
          >
            {getColunasHabilitadas(columns, 'entregaVeiculoTable')}
          </DataList>
        </Container>
        {this.showConfirmacaoModal(state.visibleConfirmacao)}
        <TablePreferences
          title="Preferências da tabela de entrega de veículos"
          tableName="entregaVeiculoTable"
          columns={columns}
          visible={state.visiblePreferencesModal}
          hide={() => this.setState({ visiblePreferencesModal: false })}
        />
      </Page>
    )
  }
}

export default EntregaVeiculoList
