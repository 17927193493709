import React from 'react'
import { Crud } from '../../../../classes/NewCrud'
import { getPessoaJuridicaDTO } from '../../../../dtos/cadastro/pessoa/PessoaJuridicaDTO'
import PessoaJuridicaForm from '../../../../forms/cadastro/pessoa/pessoaJuridica/PessoaJuridicaForm'
import PessoaJuridicaService from '../../../../services/cadastro/pessoa/PessoaJuridicaService'
import { PessoaJuridicaValidator } from '../../../../forms/cadastro/pessoa/pessoaJuridica/PessoaJuridicaValidator'

export function PessoaJuridica (props) {
  return (
    <Crud
      match={props.match}
      onSaveModal={props.onSaveModal}
      onDeleteModal={props.onDeleteModal}
      codigoSelecionado={props.codigoSelecionado}
      onHide={props.onHide}
      apenasVisualizacao={props.apenasVisualizacao}
      formContent={PessoaJuridicaForm}
      valida={PessoaJuridicaValidator}
      modelGetDTO={getPessoaJuridicaDTO}
      service={PessoaJuridicaService}
      resource="pessoas-juridicas"
      primarykeyname="codigo_pej"
      formTitle="Pessoa Jurídica"
    />
  )
}
