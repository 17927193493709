//Este componente é usado dentro dos forms, o outro DataList é para ser usado nas listas em sí
import './_dataListForm.scss'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { Component } from 'react'
import ResponsiveColumn from '../../utils/ResponsiveColumn'

class DataListForm extends Component {
  constructor (props) {
    super(props)
  }

  render () {
    const columns = this.props.columns

    return (
      <>
        <DataTable
          value={this.props.value}
          footer={this.props.footer}
          emptyMessage={this.props.emptyMessage}
          className="data-list-form datalist p-datatable-responsive"
          selectionMode="single"
          onRowSelect={this.props.onRowSelect}
        >
          {columns.map((column, index) => {
            return (
              <Column
                key={index}
                field={column.field}
                header={column.header}
                className={column.className}
                body={(data) => {
                  const propriedades = column.field.split('.')
                  const valor = propriedades.reduce((a, prop) => a[prop], data)
                  let newValor

                  Object.getOwnPropertyNames(data).forEach((keyName) => {
                    if (keyName === column?.ifHas?.field) {
                      if (data[keyName] === column?.ifHas?.value) {
                        newValor = column?.ifHas?.body
                      } else if (data[keyName] === column?.ifHas?.else) {
                        newValor = column?.ifHas?.elseBody
                      }
                    }
                  })

                  if (newValor) {
                    return <ResponsiveColumn
                      className={newValor === column?.ifHas?.body ? column?.ifHas?.className : column?.ifHas?.elseClassName}
                      value={newValor}
                      column={column?.header}/>
                  } else {
                    return <ResponsiveColumn
                      value={column.format ? column.format(valor) : valor}
                      column={column?.header} />
                  }
                }}
              />
            )
          })}
        </DataTable>
      </>
    )
  }
}

export default DataListForm
