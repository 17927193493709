import { FileUpload } from 'primereact/fileupload'
import React, { useState, useEffect } from 'react'
import { showErrorMessage } from '../../../components/utils/Message'
import ImagemService from '../../../services/imagem/ImagemService'

const LogoEmpresaPej = ({ form, edicaoHabilitada, fileUploadRef }) => {
  const [logo, setLogo] = useState({})
  const chooseOptions = { icon: 'pi pi-image', label: 'Mudar logo', className: 'custom-choose-btn p-button-rounded', id: 'chooseButton' }
  const uploadOptions = { className: 'hidden' }
  const cancelOptions = { className: 'hidden' }

  useEffect(() => {
    const element = document.getElementsByClassName('custom-choose-btn')
    element[0].addEventListener('click', async function () {
      fileUploadRef.current?.clear()
      const currentLogo = document.getElementById('current-logo')
      if (currentLogo) {
        currentLogo.hidden = true
      }
    })
    getLogo()
  }, [])

  const itemTemplate = (file, props) => {
    return (
      <div className="flex align-itemscenter flex-wrap">
        <div className="imagem-container mt-5">
          <img alt={file.name} role="presentation" src={file.objectURL} className="imagem" />
          <i className="pi pi-times excluir-imagem" onClick={props.onRemove} />
        </div>
      </div>
    )
  }

  const getLogo = async () => {
    try {
      const logo = await ImagemService.getImages('codigo_pej', form.values.codigo_pej)
      setLogo(logo[0])
    } catch (e) {}
  }

  const handleUpload = async (e) => {
    try {
      if (e.files[0]) {
        if (!(e.files[0].name === logo?.nomarq_img)) {
          fileUploadRef.current?.clear()
          await ImagemService.deleteAllImages('codigo_pej', form.values.codigo_pej)
          const image = await ImagemService.uploadImages(e.files, form.values.codigo_pej, 'codigo_pej', 'pessoaj')
          setLogo(image[0])
          const currentLogo = document.getElementById('current-logo')
          if (currentLogo) {
            currentLogo.hidden = false
          }
        }
      }
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao fazer upload da imagem')
    }
  }

  return (
    <div className="logo-empresa">
      {logo?.url && (
        <img className="current-logo" src={logo?.url} id="current-logo"/>
      )}
      <FileUpload
        disabled={!edicaoHabilitada}
        name="logo"
        ref={fileUploadRef}
        maxFileSize={5000000}
        emptyTemplate={<></>}
        previewWidth={200}
        customUpload
        chooseOptions={chooseOptions}
        uploadOptions={uploadOptions}
        cancelOptions={cancelOptions}
        itemTemplate={itemTemplate}
        uploadHandler={handleUpload}
        onValidationFail={(file) => {
          showErrorMessage(`${file.name}: Tamanho de arquivo inválido, o tamanho máximo de envio é de 5MB.`)
        }}
      />
    </div>
  )
}

export default LogoEmpresaPej
