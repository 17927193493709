import * as Yup from 'yup'

export const VeiculoTrocaValidator = Yup.object().shape({
  valor: Yup.number()
    .required('O valor não foi informado!')
    .min(0.01, 'O valor não foi informado!'),
  veiculo: Yup.object().shape({
    codigo_vei: Yup.number()
      .required('O veículo não foi selecionado!')
  }),
  veiculoTroca: Yup.object().shape({
    codigo_vei: Yup.number()
      .required('Informe um veículo para troca!')

  })
})
