import React from 'react'
import { Crud } from '../../../../classes/NewCrud'
import { getUsuarioDTO } from '../../../../dtos/cadastro/pessoa/UsuarioDTO'
import UsuarioForm from '../../../../forms/cadastro/pessoa/usuario/UsuarioForm'
import UsuarioService from '../../../../services/cadastro/pessoa/UsuarioService'

export function Usuario (props) {
  return (
    <Crud
      match={props.match}
      onSaveModal={props.onSaveModal}
      onDeleteModal={props.onDeleteModal}
      codigoSelecionado={props.codigoSelecionado}
      onHide={props.onHide}
      apenasVisualizacao={props.apenasVisualizacao}
      formContent={UsuarioForm}
      modelGetDTO={getUsuarioDTO}
      service={UsuarioService}
      resource="usuarios"
      primarykeyname="codigo_usu"
      formTitle="Usuário"
    />
  )
}
