import moment from 'moment'
import { formatPlacha } from '../../../helpers/formaters'

export const getDocumentoDTO = (data = {}) => {
  let dataAutal = new Date().toDateString()

  return {
    valor_ndo: data.valor_ndo || 0,
    valcus_ndo: data.valcus_ndo || 0,
    datemi_ndo: data.datemi_ndo ? new Date(`${data.datemi_ndo} 00:00`) : new Date(`${dataAutal} 00:00`),
    datpag_ndo: data.datpag_ndo ? new Date(`${data.datpag_ndo} 00:00`) : null,
    obslan_ndo: data.obslan_ndo || '',
    obsrec_ndo: data.obsrec_ndo || '',
    respag_ndo: data.respag_ndo || 1,
    addaut_ndo: data.addaut_ndo,
    veiculoNve: data.veiculoNve
      ? data.veiculoNve
      : (data.veiculo
        ? {
          codvei_nve: data.veiculo.codigo_vei,
          placha: formatPlacha(data.veiculo.placa_vei, data.veiculo.chassi_vei)
        }
        : null),
    tipoDocumento: {
      codigo_tdo: data?.tipoDocumento?.codigo_tdo,
      descri_tdo: data?.tipoDocumento?.descri_tdo,
      ativo_tdo: data?.tipoDocumento?.ativo_tdo,
      codigo_tdo: data?.tipoDocumento?.codigo_tdo,
      cusfix_tdo: data?.tipoDocumento?.cusfix_tdo,
      descri_tdo: data?.tipoDocumento?.descri_tdo,
      codctd_tdo: data?.tipoDocumento?.codctd_tdo,
      envsms_tdo: data?.tipoDocumento?.envsms_tdo,
      isreem_tdo: data?.tipoDocumento?.isreem_tdo,
      obrcus_tdo: data?.tipoDocumento?.obrcus_tdo,
      pespag_tdo: data?.tipoDocumento?.pespag_tdo,
      tdrpag_tdo: data?.tipoDocumento?.tdrpag_tdo,
      tdrpagres_tdo: data?.tipoDocumento?.tdrpagres_tdo,
      tdrrec_tdo: data?.tipoDocumento?.tdrrec_tdo,
      tdrrecres_tdo: data?.tipoDocumento?.tdrrecres_tdo,
      valcus_tdo: data?.tipoDocumento?.valcus_tdo
    },
    destinoPagar: data.destinoPagar || {},
    destinoReceber: data.destinoReceber || {},
    destino: data.destino || {},
    custoFixo: data?.custoFixo || false,
    obrigaCusto: data?.obrcus_tdo || data.tipoDocumento?.obrcus_tdo || false
  }
}

export const postDocumentoDTO = (data) => {
  return {
    codtdo_ndo: data.tipoDocumento.codigo_tdo,
    codvei_ndo: data.veiculoNve.codvei_nve,
    coddes_ndo: data.destino.codigo_pes,
    valor_ndo: data.valor_ndo,
    valcus_ndo: data.valcus_ndo,
    datemi_ndo: data.datemi_ndo ? moment(data.datemi_ndo).format('YYYY-MM-DD') : null,
    datpag_ndo: data.datpag_ndo ? moment(data.datpag_ndo).format('YYYY-MM-DD') : null,
    obslan_ndo: data.obslan_ndo || '',
    obsrec_ndo: data.obsrec_ndo || '',
    respag_ndo: data.respag_ndo || 1,
    veiculoNve: data.veiculoNve,
    addaut_ndo: data.addaut_ndo,
    tipoDocumento: {
      codigo_tdo: data?.tipoDocumento?.codigo_tdo,
      descri_tdo: data?.tipoDocumento?.descri_tdo,
      ativo_tdo: data?.tipoDocumento?.ativo_tdo,
      codigo_tdo: data?.tipoDocumento?.codigo_tdo,
      cusfix_tdo: data?.tipoDocumento?.cusfix_tdo,
      descri_tdo: data?.tipoDocumento?.descri_tdo,
      codctd_tdo: data?.tipoDocumento?.codctd_tdo,
      envsms_tdo: data?.tipoDocumento?.envsms_tdo,
      isreem_tdo: data?.tipoDocumento?.isreem_tdo,
      obrcus_tdo: data?.tipoDocumento?.obrcus_tdo,
      pespag_tdo: data?.tipoDocumento?.pespag_tdo,
      tdrpag_tdo: data?.tipoDocumento?.tdrpag_tdo,
      tdrpagres_tdo: data?.tipoDocumento?.tdrpagres_tdo,
      tdrrec_tdo: data?.tipoDocumento?.tdrrec_tdo,
      tdrrecres_tdo: data?.tipoDocumento?.tdrrecres_tdo,
      valcus_tdo: data?.tipoDocumento?.valcus_tdo
    },
    obrigaCusto: data.obrigaCusto,
    destino: data.destino,
    destinoPagar: data.destinoPagar || {},
    destinoReceber: data.destinoReceber || {},
    idx: data.idx
  }
}
