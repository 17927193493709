import { postAcessorioDTO } from '../../../dtos/cadastro/veiculo/AcessorioDTO'
import ServiceBase from '../../base/ServiceBase'

export default class AcessorioService {
  async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`/acessorio?page=${page}&perPage=${perPage}`)
  }

  async get (id) {
    return await ServiceBase.getBase(`/acessorio/${id}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`/acessorio?${query}&page=${page}&perPage=${perPage}`)
  }

  async save (data) {
    return await ServiceBase.postBase('/acessorio', postAcessorioDTO(data))
  }

  async delete (codigo_ace) {
    return await ServiceBase.deleteBase(`/acessorio/${codigo_ace}`)
  }
}
