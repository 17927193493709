import React, { Component } from 'react'
import Modal from './Modal'

class DescricaoAcessoriosModal extends Component {
  constructor (props) {
    super(props)
  }

  render () {
    const { props } = this

    return (
      <Modal
        header="Lista de acessórios"
        width="40"
        visible={props.visible}
        onHide={() => props.onHide()}
      >
        {props.descricaoAcessorios.map((descricaoAcessorio, idx) => (
          <div key={idx}>{descricaoAcessorio}</div>
        ))}
      </Modal>
    )
  }
}

export default DescricaoAcessoriosModal
