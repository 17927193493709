import * as queryString from 'query-string'
import CommonHelper from '../../../helpers/CommonHelper'
import { PessoaFisicaModel } from './PessoaFisicaModel'
import moment from 'moment'
import { formatDateToAmerican } from '../../../helpers/formaters'
import { getStore } from '../../../redux/store'

export class UsuarioModel extends PessoaFisicaModel {
  constructor (params = {}) {
    super({
      pessoa: {
        codigo_pes: params.pessoa?.codigo_pes || params.codigo_pes || null,
        nomraz_pes: params.pessoa?.nomraz_pes || params.nomraz_pes || null,
        apelid_pes: params.pessoa?.apelid_pes || params.apelid_pes || null,
        end_pes: params.pessoa?.end_pes || params.end_pes || null,
        bairro_pes: params.pessoa?.bairro_pes || params.bairro_pes || null,
        cidade_pes: params.pessoa?.cidade_pes || params.cidade_pes || null,
        uf_pes: params.pessoa?.uf_pes || params.uf_pes || null,
        pais_pes: params.pessoa?.pais_pes || params.pais_pes || null,
        cep_pes: params.pessoa?.cep_pes || params.cep_pes || null,
        email_pes: params.pessoa?.email_pes || params.email_pes || null,
        emafin_pes: params.pessoa?.emafin_pes || params.emafin_pes || null,
        numend_pes: params.pessoa?.numend_pes || params.numend_pes || null,
        codemp_pes: params.pessoa?.codemp_pes || params.codemp_pes || null,
        cgccpf_pes: params.pessoa?.cgccpf_pes || params.cgccpf_pes || null,
        ierg_pes: params.pessoa?.ierg_pes || params.ierg_pes || null,
        ie_pes: params.pessoa?.ie_pes || params.ie_pes || null,
        rg_pes: params.pessoa?.rg_pes || params.rg_pes || null,
        telcel_pes: params.pessoa?.telcel_pes || params.telcel_pes || null,
        telcom_pes: params.pessoa?.telcom_pes || params.telcom_pes || null,
        telres_pes: params.pessoa?.telres_pes || params.telres_pes || null,
        insmun_pes: params.pessoa?.insmun_pes || params.insmun_pes || null,
        comend_pes: params.pessoa?.comend_pes || params.comend_pes || null,
        codmun_pes: params.pessoa?.codmun_pes || params.codmun_pes || null,
        codpai_pes: params.pessoa?.codpai_pes || params.codpai_pes || getStore().codigoBrasil,
        usuins_pes: params.pessoaindex?.usuins_pes || params.usuins_pes || null,
        refban_pes: params.pessoa?.refban_pes || params.refban_pes || null,
        pais: params.pessoa?.pais || params.pais || { codigo_pai: getStore().codigoBrasil, descri_pai: 'Brasil' },
        estado: params.pessoa?.estado || params.estado || {},
        municipio: params.pessoa?.municipio || params.municipio || {},
        empresa: params.pessoa?.empresa || {},
        usuarioInsercao: params.pessoa?.usuarioInsercao || {},
        usuarioAlteracao: params.pessoa?.usuarioAlteracao || {},
        modalidadeVenda: params.pessoa?.modalidadeVenda || params.modalidadeVenda || {},
        limcre_pes: params.pessoa?.limcre_pes || params.limcre_pes || null,
        datins_pes: params.pessoa?.datins_pes,
        dahalt_pes: params.pessoa?.dahalt_pes,
        idrad_pes: params.pessoa?.idrad_pes || params.idrad_pes,
        parcei_pes: params.pessoa?.parcei_pes || params.parcei_pes,
        cliata_pes: params.pessoa?.cliata_pes || params.cliata_pes || false,
        numcom_pes: params.pessoa?.numcom_pes || params.numcom_pes || null,
        cepcom_pes: params.pessoa?.cepcom_pes || params.cepcom_pes || null,
        endcom_pes: params.pessoa?.endcom_pes || params.endcom_pes || null,
        comcom_pes: params.pessoa?.comcom_pes || params.comcom_pes || null,
        barcom_pes: params.pessoa?.barcom_pes || params.barcom_pes || null,
        numcob_pes: params.pessoa?.numcob_pes || params.numcob_pes || null,
        cepcob_pes: params.pessoa?.cepcob_pes || params.cepcob_pes || null,
        endcob_pes: params.pessoa?.endcob_pes || params.endcob_pes || null,
        comcob_pes: params.pessoa?.comcob_pes || params.comcob_pes || null,
        barcob_pes: params.pessoa?.barcob_pes || params.barcob_pes || null,
        municipioComercial: params.pessoa?.municipioComercial || params.municipioComercial || {},
        municipioCobranca: params.pessoa?.municipioCobranca || params.municipioCobranca || {},
        classificacao: params.pessoa?.classificacao || params.classificacao || {},
        grupo: params.pessoa?.grupo || params.grupo || {},
        categoria: params.pessoa?.categoria || params.categoria || {},
        tabelaDesconto: params.pessoa?.tabelaDesconto || params.tabelaDesconto || {},
        autorizacaoContatos: params.pessoa?.autorizacaoContatos || params.autorizacaoContatos || []
      },
      codigo_pef: params.pessoa?.pessoaFisica?.codigo_pef || params.codigo_pef || null,
      codcon_pef: params.pessoa?.pessoaFisica?.codcon_pef || params.codcon_pef || null,
      estciv_pef: params.pessoa?.pessoaFisica?.estciv_pef || params.estciv_pef || 7,
      nacion_pef: params.pessoa?.pessoaFisica?.nacion_pef || params.nacion_pef || null,
      natura_pef: params.pessoa?.pessoaFisica?.natura_pef || params.natura_pef || null,
      sex_pef: params.pessoa?.pessoaFisica?.sex_pef || params.sex_pef || null,
      obs_pef: params.pessoa?.pessoaFisica?.obs_pef || params.obs_pef || null,
      nompai_pef: params.pessoa?.pessoaFisica?.nompai_pef || params.nompai_pef || null,
      nommae_pef: params.pessoa?.pessoaFisica?.nommae_pef || params.nommae_pef || null,
      pejtra_pef: params.pessoa?.pessoaFisica?.pejtra_pef || params.pejtra_pef || null,
      nomtra_pef: params.pessoa?.pessoaFisica?.nomtra_pef || params.nomtra_pef || null,
      endtra_pef: params.pessoa?.pessoaFisica?.endtra_pef || params.endtra_pef || null,
      numtra_pef: params.pessoa?.pessoaFisica?.numtra_pef || params.numtra_pef || null,
      baitra_pef: params.pessoa?.pessoaFisica?.baitra_pef || params.baitra_pef || null,
      ceptra_pef: params.pessoa?.pessoaFisica?.ceptra_pef || params.ceptra_pef || null,
      muntra_pef: params.pessoa?.pessoaFisica?.muntra_pef || params.muntra_pef || null,
      teltra_pef: params.pessoa?.pessoaFisica?.teltra_pef || params.teltra_pef || null,
      codpro_pef: params.pessoa?.pessoaFisica?.codpro_pef || params.codpro_pef || null,
      temser_pef: params.pessoa?.pessoaFisica?.temser_pef || params.temser_pef || null,
      refpes_pef: params.pessoa?.pessoaFisica?.refpes_pef || params.refpes_pef || null,
      temres_pef: params.pessoa?.pessoaFisica?.temres_pef || params.temres_pef || null,
      orgemi_pef: params.pessoa?.pessoaFisica?.orgemi_pef || params.orgemi_pef || null,
      cnh_pef: params.pessoa?.pessoaFisica?.cnh_pef || params.cnh_pef || null,
      nuctps_pef: params.pessoa?.pessoaFisica?.nuctps_pef || params.nuctps_pef || null,
      sectps_pef: params.pessoa?.pessoaFisica?.sectps_pef || params.sectps_pef || null,
      numpis_pef: params.pessoa?.pessoaFisica?.numpis_pef || params.numpis_pef || null,
      renmes_pef: params.pessoa?.pessoaFisica?.renmes_pef || params.renmes_pef || null,
      codesc_pef: params.pessoa?.pessoaFisica?.codesc_pef || params.codesc_pef || null,
      codhob_pef: params.pessoa?.pessoaFisica?.codhob_pef || params.codhob_pe || null,
      datemi_pef: params.pessoa?.pessoaFisica?.datemi_pef || params.datemi_pef
        ? new Date(moment(params.pessoa?.pessoaFisica?.datemi_pef || params.datemi_pef).local())
        : null,
      datnas_pef: params.pessoa?.pessoaFisica?.datnas_pef || params.datnas_pef
        ? new Date(moment(params.pessoa?.pessoaFisica?.datnas_pef || params.datnas_pef).local())
        : null,
      naturalidade: params.pessoa?.pessoaFisica?.naturalidade || params.naturalidade || {},
      profissao: params.pessoa?.pessoaFisica?.profissao || params.profissao || {},
      escolaridade: params.pessoa?.pessoaFisica?.escolaridade || params.escolaridade || {},
      hobby: params.pessoa?.pessoaFisica?.hobby || params.hobby || {},
      conjuge: params.pessoa?.pessoaFisica?.conjuge || params.conjuge || {},
      empresaTrabalho: params.pessoa?.pessoaFisica?.empresaTrabalho?.codigo_pej
        ? {
          codigo_pej: params.pessoa?.pessoaFisica?.empresaTrabalho.codigo_pej,
          nomraz_pes: params.pessoa?.pessoaFisica?.empresaTrabalho?.pessoa?.nomraz_pes
        } : (params.pessoa?.pessoaFisica?.empresa || {}),
      usuarioInsercao: params.pessoa?.usuarioInsercao || {},
      usuarioAlteracao: params.pessoa?.usuarioAlteracao || {},
      cliente: params.pessoa?.pessoaFisica?.cliente || {},
      municipioTrabalho: params.pessoa?.pessoaFisica?.municipioTrabalho || params.municipioTrabalho || {},
      diapag_cli: params.diapag_cli || params.cliente?.payment_day || null
    })

    this.codigo_usu = params.codigo_usu || null
    this.email = params.email || null
    this.password = params.password || null
    this.altsen_usu = params.altsen_usu || null
    this.ativo_usu = params.ativo_usu !== false ? true : false
    this.loghep_usu = params.loghep_usu || null
    this.senhep_usu = params.senhep_usu || null
    this.nomimp_usu = params.nomimp_usu || ''
    this.celliv_usu = params.celliv_usu || null
    this.pasbcr_usu = params.pasbcr_usu || ''
    this.cliente = params.cliente || {}
    this.restricaoGrupo = params.restricaoGrupo || {}
    this.conta = params.conta || {}
  }

  toMap () {
    return {
      codigo_usu: this.codigo_usu || null,
      codgru_usu: this.restricaoGrupo.codigo_gru || null,
      email: this.email || null,
      password: this.password || null,
      codcon_usu: this.conta.codigo_con || null,
      altsen_usu: this.altsen_usu || null,
      ativo_usu: this.ativo_usu || false,
      loghep_usu: this.loghep_usu || '',
      senhep_usu: this.senhep_usu || '',
      nomimp_usu: this.nomimp_usu || '',
      celliv_usu: this.celliv_usu || null,
      pasbcr_usu: this.pasbcr_usu || '',
      codigo_pes: this.codigo_pes || null,
      nomraz_pes: this.nomraz_pes || null,
      apelid_pes: this.apelid_pes || null,
      end_pes: this.end_pes || null,
      bairro_pes: this.bairro_pes || null,
      cidade_pes: this.cidade_pes || null,
      uf_pes: this.uf_pes || null,
      pais_pes: this.pais_pes || null,
      cep_pes: CommonHelper.clearDigitsSpecialChars(this.cep_pes) || null,
      email_pes: this.email_pes ? this.email_pes.toLowerCase() : null,
      emafin_pes: this.emafin_pes ? this.emafin_pes.toLowerCase() : null,
      numend_pes: this.numend_pes || null,
      codemp_pes: this.codemp_pes || null,
      cgccpf_pes: CommonHelper.clearDigitsSpecialChars(this.cgccpf_pes) || null,
      ierg_pes: CommonHelper.clearDigitsSpecialChars(this.ierg_pes) || null,
      ie_pes: this.ie_pes || '',
      rg_pes: this.rg_pes || null,
      insmun_pes: this.insmun_pes || null,
      telcel_pes: CommonHelper.clearDigitsSpecialChars(this.telcel_pes) || null,
      telcom_pes: CommonHelper.clearDigitsSpecialChars(this.telcom_pes) || null,
      telres_pes: CommonHelper.clearDigitsSpecialChars(this.telres_pes) || null,
      idrad_pes: CommonHelper.clearDigitsSpecialChars(this.idrad_pes) || null,
      codmov_pes: this.modalidadeVenda.codigo_mov || null,
      comend_pes: this.comend_pes || null,
      codmun_pes: this.codmun_pes || null,
      codpai_pes: this.codpai_pes || null,
      usuins_pes: this.usuins_pes || null,
      refban_pes: this.refban_pes || null,
      datins_pes: formatDateToAmerican(this.datins_pes || new Date()),
      numcom_pes: this.numcom_pes || null,
      cepcom_pes: this.cepcom_pes || null,
      endcom_pes: this.endcom_pes || null,
      comcom_pes: this.comcom_pes || null,
      muncom_pes: this.municipioComercial.id || null,
      barcom_pes: this.barcom_pes || null,
      numcob_pes: this.numcob_pes || null,
      cepcob_pes: this.cepcob_pes || null,
      endcob_pes: this.endcob_pes || null,
      comcob_pes: this.comcob_pes || null,
      muncob_pes: this.municipioCobranca.id || null,
      barcob_pes: this.barcob_pes || null,
      cliata_pes: this.cliata_pes || false,
      parcei_pes: this.parcei_pes || false,
      codigo_pef: this.codigo_pef || null,
      codcon_pef: this.conjuge.codigo_pef || null,
      datnas_pef: CommonHelper.dateToAmerican(this.datnas_pef) || null,
      estciv_pef: this.estciv_pef || null,
      nacion_pef: this.nacion_pef || null,
      natura_pef: this.naturalidade.id || null,
      sex_pef: this.sex_pef || null,
      obs_pef: this.obs_pef || null,
      nompai_pef: this.nompai_pef || null,
      nommae_pef: this.nommae_pef || null,
      pejtra_pef: this.empresaTrabalho.codigo_pej || null,
      nomtra_pef: this.nomtra_pef || null,
      endtra_pef: this.endtra_pef || null,
      numtra_pef: this.numtra_pef || null,
      baitra_pef: this.baitra_pef || null,
      ceptra_pef: CommonHelper.clearDigitsSpecialChars(this.ceptra_pef) || null,
      muntra_pef: this.municipioTrabalho.id || null,
      teltra_pef: CommonHelper.clearDigitsSpecialChars(this.teltra_pef) || null,
      codpro_pef: this.profissao.codigo_pro || null,
      temser_pef: this.temser_pef || null,
      refpes_pef: this.refpes_pef || null,
      temres_pef: this.temres_pef || null,
      orgemi_pef: this.orgemi_pef || null,
      datemi_pef: CommonHelper.dateToAmerican(this.datemi_pef) || null,
      cnh_pef: this.cnh_pef || null,
      nuctps_pef: this.nuctps_pef || null,
      sectps_pef: this.sectps_pef || null,
      numpis_pef: this.numpis_pef || null,
      renmes_pef: this.renmes_pef || null,
      codesc_pef: this.escolaridade.codigo_esc || null,
      codhob_pef: this.hobby.codigo_hob || null,
      codgup_pes: this.grupo.codigo_gup || null,
      codcat_pes: this.categoria.codigo_cat || null,
      codcap_pes: this.classificacao.codigo_cap || null,
      codtad_pes: this.tabelaDesconto.codigo_tad || null,
      diapag_cli: this.diapag_cli || null,
      autorizacaoContatos: this.autorizacaoContatos || []
    }
  }

  toFilter () {
    const validFields = Object
      .keys(this)
      .filter(it => !!this[it])
      .map(it => ({ [it]: this[it] }))

    let fields = {}

    validFields.forEach(it => {
      fields = { ...it, ...fields }
    })

    return queryString.stringify(fields)
  }

    static estadosCivis = [
      { label: 'Solteiro(a)', estciv_pef: 1 },
      { label: 'Casado(a)', estciv_pef: 2 },
      { label: 'Desquitado(a)', estciv_pef: 3 },
      { label: 'Divorciado(a)', estciv_pef: 4 },
      { label: 'Viúvo(a)', estciv_pef: 5 },
      { label: 'Separado(a) judicialmente', estciv_pef: 6 },
      { label: 'Ignorado(a)', estciv_pef: 7 },
      { label: 'União estável', estciv_pef: 8 }
    ];

    static generos = [
      { label: 'Masculino', sex_pef: 1 },
      { label: 'Feminino', sex_pef: 2 },
      { label: 'Ignorado', sex_pef: 3 }
    ];
}
