import React from 'react'

const BancoAutocompleteTemplate = (option) => {
  return (
    <span>
      <b>{option.codigo_ban}</b> - {option.descri_ban}
    </span>
  )
}

export default BancoAutocompleteTemplate
