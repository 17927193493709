import React, { useState } from 'react'
import TextInput from '../../../components/inputs/TextInput'
import { showErrorMessage } from '../../../components/utils/Message'
import Modal from '../../../components/utils/Modal'
import CommonHelper from '../../../helpers/CommonHelper'
import ReportService from '../../../services/reports/ReportService'

const DeclaracaoResidenciaModal = ({ codigoPessoa, hide }) => {
  const [prefixo, setPrefixo] = useState('Solicito que seja emitido o CRV/CRLV para o endereço:')

  const imprimirDeclaracaoResidencia = async (prefixo) => {
    try {
      const pdf = await ReportService.getDeclaracaoResidencia(codigoPessoa, prefixo)
      CommonHelper.openFile('application/pdf', pdf, `declaracao-residencia-${codigoPessoa}.pdf`)
    } catch (error) {
      showErrorMessage(error.message || 'Pessoa não encontrada!')
    }
  }

  const handleFormSubmit = () => {
    imprimirDeclaracaoResidencia(prefixo)
    hide()
  }

  return (
    <Modal
      onHide={hide}
      header="Informe a solicitação"
      visible={true}
      modal={true}
      width="25"
      btnSalvar={handleFormSubmit}
    >
      <div className="formgrid grid">
        <div className="field col-12">
          <label className="label">Declaração de residência</label>
          <TextInput
            value={prefixo}
            onChange={(e) => setPrefixo(e.target.value)}
            className="inputfield w-full"
          />
        </div>
      </div>
    </Modal>
  )
}

export default DeclaracaoResidenciaModal
