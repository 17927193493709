import React from 'react'
import List from '../../../../../classes/List'
import { getAvaliacaoDTO } from '../../../../../dtos/vendas/negociacao/avaliacao/AvaliacaoDTO'
import CommonHelper from '../../../../../helpers/CommonHelper'
import { formatToCurrency } from '../../../../../helpers/formaters'
import AvaliacaoService from '../../../../../services/cadastro/veiculo/AvaliacaoService'
import DataListForm from '../../../../../components/layout/DataListForm/DataListForm'

class OutrasAvaliacoesList extends List {
  constructor (props) {
    super(props, getAvaliacaoDTO, AvaliacaoService)

    this.state = {
      ...this.state
      }
  }

  getDataAvaliacao (data) {
    return CommonHelper.dateToBrazilian(data.dathor_ava)
  }

  getValorAvaliacao (data) {
    return formatToCurrency(data.valor_ava)
  }

  componentDidMount () {
    this.filterByPlaca()
  }

  componentDidUpdate (prevProps) {
    const isPlacaDifferentThanBefore = prevProps.placa !== this.props.placa

    if (isPlacaDifferentThanBefore) {
      if (this.isPlacaCompleta()) {
        this.filterByPlaca()
      } else if (this.placaAnteriorEstavaCompleta(prevProps)) {
        this.filterByPlaca()
      }
    }
  }

  isPlacaCompleta () {
    return this.props.placa?.length === 7
  }

  placaAnteriorEstavaCompleta (prevProps) {
    return prevProps.placa?.length === 7
  }

  filterByPlaca () {
    const { filter } = this.state
    filter.descricaoFiltro = this.isPlacaCompleta() ? this.props.placa : 'Sem placa'
    this.setState({ filter }, this.onFilter)
  }

  render () {
    const state = this.state
    let valorTotalAvaliacao = 0
    for (const data of state.list) {
      data.dathor_ava = CommonHelper.dateToBrazilian(data.dathor_ava)
      valorTotalAvaliacao += data.valor_ava
    }

    return (
      <>
        <div className="field col-12 md:col-12 grid justify-content-center no-margin no-padding">
          <div className={'field col-12 md:col-' + (CommonHelper.isDesktop() ? '8' : '12 no-padding')}>
            <DataListForm
              value={state.list}
              emptyMessage="Nenhuma avaliação foi encontrada."
              columns={[
                {
                  header: 'Empresa',
                  field: 'apelido_emp',
                  className: 'text-left pl-4'
                },
                {
                  header: 'Data',
                  field: 'dathor_ava',
                  className: 'text-left pl-4'
                },
                {
                  header: 'Avaliador',
                  field: 'nome_ven',
                  className: 'text-left pl-4'
                },
                {
                  header: 'Valor',
                  field: 'valor_ava',
                  className: 'text-right pr-4',
                  format: formatToCurrency,
                  ifHas: { field: 'respag_ndo', value: 2, body: 'Cortesia', className: 'neg-documento-isCortesia' }
                }
              ]}
              footer={
                <>
                  <div className="flex justify-content-between">
                    <span className="pl-2">Total</span>
                    <span className="pr-2">{formatToCurrency(Math.abs(valorTotalAvaliacao))}</span>
                  </div>
                </>
              }
            />
          </div>
        </div>
      </>
    )
  }
}

export default OutrasAvaliacoesList
