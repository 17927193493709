import ReportBase from '../../components/utils/report/ReportBase'
import CommonHelper from '../../helpers/CommonHelper'
import { formatNumberToTwoFractionDigits, roundNumber } from '../../helpers/formaters'
import { createColumns } from '../../helpers/tableConfigs'
import LucroDeVendasReportService from '../../services/reports/LucroDeVendasReportService'
import LucroDeVendasReportFiltroModal from './LucroDeVendasReportFiltroModal'
import LucroDeVendasReportFooter from './LucroDeVendasReportFooter'

class LucroDeVendasReport extends ReportBase {
  constructor (props) {
    super(props, 'Relatório de lucro de vendas', LucroDeVendasReportService, LucroDeVendasReportFiltroModal, LucroDeVendasReportFooter)
  }

  async componentDidMount () {
    await super.componentDidMount()

    super.setGrouping({
      getHeader: this.getGroupHeader,
      propertyName: 'negociacoes'
    })

    super.setColumns(createColumns([
      { field: 'codnegvenda_ven', header: 'Neg.' },
      { field: 'desmo2_vei', header: 'Veículo', customBody: this.getDescricaoVeiculo },
      { field: 'datacompra_ven', header: 'Data compra', customBody: this.getDataCompra },
      { field: 'datavenda_ven', header: 'Data venda', customBody: this.getDataVenda },
      { field: 'valcompra_ven', header: 'Valor compra', customBody: this.getValorCompra },
      { field: 'valor_ratdes', header: 'Rateio despesas', customBody: this.getValorRateioDeDespesas, enabled: false },
      { field: 'valor_manutencao', header: 'Valor Manut. + Indicação', customBody: this.getCustoManutencaoMaisIndicacao, enabled: false },
      { field: 'custo_documento', header: 'Custo com doc.', customBody: this.getValorCustoComDocumentos, enabled: false },
      { field: 'diasestoque_ven', header: 'Pátio dias', enabled: false },
      { field: 'juros_estoque', header: 'Pátio juros', customBody: this.getPatioJuros, enabled: false },
      { field: 'valvenda_ven', header: 'Venda + complemento', customBody: this.getValorVendaComComplemento },
      { field: 'valor_documento', header: 'Receita com doc.', customBody: this.getValorReceitaComDocumentos, enabled: false },
      { field: 'tac_acordo_total', header: 'TAC + Acordo', customBody: this.getTacMaisAcordo, enabled: false },
      { field: 'valretorno_ven', header: 'Retorno sem impostos', customBody: this.getRetornoSemImpostos, enabled: false },
      { field: 'perret_ven', header: 'Ret.(%)', customBody: this.getRetornoEmPorcentagem, enabled: false },
      { field: 'lucro_venda_sem_retorno', header: 'L.S.R', customBody: this.getLucroVendaSemRetorno, enabled: false },
      { field: 'lucro_venda_incluso_juros_estoque', header: 'L.J.E', customBody: this.getLucroVendaInclusoJurosEstoque, enabled: false },
      { field: 'lucro_venda', header: 'Lucro venda', customBody: this.getLucroVenda },
      { field: 'perclucro_venda', header: 'Lucro(%)', customBody: this.getPorcentagemLucroVenda, enabled: false }
    ], true))

    super.setTotalizadores([
      { field: 'valcompra_ven', name: 'Valor total compras', formatFunction: formatNumberToTwoFractionDigits },
      { field: 'valor_ratdes', name: 'Valor total rateio despesas', formatFunction: formatNumberToTwoFractionDigits },
      { field: 'valor_manutencao', name: 'Valor total manutenção', formatFunction: formatNumberToTwoFractionDigits, customValue: this.getValorTotalManutencao },
      { field: 'custo_documento', name: 'Valor total custo + doc.', formatFunction: formatNumberToTwoFractionDigits },
      { field: 'diasestoque_ven', name: 'Média dos dias em estoque', formatFunction: roundNumber, customValue: this.getMediaDiasEmEstoque },
      { field: 'juros_estoque', name: 'Valor total juros estoque', formatFunction: formatNumberToTwoFractionDigits },
      { field: 'valvenda_ven', name: 'Valor total vendas', formatFunction: formatNumberToTwoFractionDigits, customValue: this.getValorTotalVendas },
      { field: 'valor_documento', name: 'Valor total receita + doc.', formatFunction: formatNumberToTwoFractionDigits },
      { field: 'tac_acordo_total', name: 'Valor total acordos', formatFunction: formatNumberToTwoFractionDigits },
      { field: 'valretorno_ven', name: 'Valor total retorno', formatFunction: formatNumberToTwoFractionDigits },
      { field: 'perret_ven', name: 'Valor total retorno(%)', formatFunction: formatNumberToTwoFractionDigits },
      { field: 'lucro_venda_sem_retorno', name: 'Valor total L.S.R', formatFunction: formatNumberToTwoFractionDigits },
      { field: 'lucro_venda_incluso_juros_estoque', name: 'Valor total L.J.E', formatFunction: formatNumberToTwoFractionDigits },
      { field: 'lucro_venda', name: 'Valor total lucro', formatFunction: formatNumberToTwoFractionDigits },
      { field: 'perclucro_venda', name: 'Valor total lucro(%)', formatFunction: formatNumberToTwoFractionDigits, customValue: this.getValorTotalPorcentagemLucro.bind(this) }
    ])

    super.setLegendaReport([
      'Custo = Manutenções + Documentos + Indicação',
      'Custo com Manut. + Indicação = Manutenções + Indicações + Cortesias sem O.S. finalizada',
      'Venda + complemento = Valor de venda + Retorno + TAC + Acordo',
      'RD = Rateio de despesas',
      'Lucro = (Venda + complemento + Lucro com doc.) - (Custo + Valor de Compra + RD)',
      'Impostos = IRRF + ILA',
      'L.S.R. = Lucro sem retorno (Retira valor de retorno da venda)',
      'L.J.E. = Lucro com juros de estoque (Soma juros de estoque aos custos)'
    ])
  }

  getGroupHeader (vendedor) {
    return `(${vendedor.codigoVendedor}) - ${vendedor.nomeVendedor.toUpperCase()} - Total de vendas: ${vendedor.negociacoes.length}`
  }

  getDescricaoVeiculo (data) {
    return (data.consig_nve ? 'C - ' : 'P - ') + data.desmo2_vei
  }

  getDataCompra (data) {
    return CommonHelper.dateToBrazilian(data.datacompra_ven)
  }

  getDataVenda (data) {
    return CommonHelper.dateToBrazilian(data.datavenda_ven)
  }

  getValorCompra (data) {
    return formatNumberToTwoFractionDigits(data.valcompra_ven)
  }

  getValorVendaComComplemento (data) {
    return formatNumberToTwoFractionDigits(data.valvenda_ven + data.valcom_fpn)
  }

  getLucroVenda (data) {
    return formatNumberToTwoFractionDigits(data.lucro_venda)
  }

  getPorcentagemLucroVenda (data) {
    return formatNumberToTwoFractionDigits(data.perclucro_venda)
  }

  getValorRateioDeDespesas (data) {
    return formatNumberToTwoFractionDigits(data.valor_ratdes)
  }

  getCustoManutencaoMaisIndicacao (data) {
    return formatNumberToTwoFractionDigits(data.valor_manutencao + data.valor_indicacao)
  }

  getValorCustoComDocumentos (data) {
    return formatNumberToTwoFractionDigits(data.custo_documento)
  }

  getPatioJuros (data) {
    return formatNumberToTwoFractionDigits(data.juros_estoque)
  }

  getValorReceitaComDocumentos (data) {
    return formatNumberToTwoFractionDigits(data.valor_documento)
  }

  getTacMaisAcordo (data) {
    return formatNumberToTwoFractionDigits(data.tac_acordo_total)
  }

  getRetornoSemImpostos (data) {
    return formatNumberToTwoFractionDigits(data.valretorno_ven)
  }

  getRetornoEmPorcentagem (data) {
    return formatNumberToTwoFractionDigits(data.perret_ven || 0)
  }

  getLucroVendaSemRetorno (data) {
    return formatNumberToTwoFractionDigits(data.lucro_venda_sem_retorno)
  }

  getLucroVendaInclusoJurosEstoque (data) {
    return formatNumberToTwoFractionDigits(data.lucro_venda_incluso_juros_estoque)
  }

  getValorTotalManutencao (data) {
    let somaDaColuna = data.reduce((previousValue, currentValue) => {
      const valorAnterior = (previousValue.valor_manutencao + previousValue.valor_indicacao) || Number(previousValue) || 0
      const valorAtual = currentValue.valor_manutencao + currentValue.valor_indicacao || 0

      return valorAnterior + valorAtual
    })

    if (data.length === 1) {
      somaDaColuna = data[0].valor_manutencao + data[0].valor_indicacao
    }

    return somaDaColuna
  }

  getMediaDiasEmEstoque (data) {
    const quantidadeDiasPatio = data.map(row => row['diasestoque_ven'])

    const quantidadeRegistros = data.length
    const totalDiasPatio = quantidadeDiasPatio.reduce((previousValue, currentValue) => previousValue + currentValue)

    return totalDiasPatio / quantidadeRegistros
  }

  getValorTotalVendas (data) {
    let somaDaColuna = data.reduce((previousValue, currentValue) => {
      const valorAnterior = (previousValue.valvenda_ven + previousValue.valcom_fpn) || previousValue || 0
      const valorAtual = currentValue.valvenda_ven + currentValue.valcom_fpn || 0

      return valorAnterior + valorAtual
    })

    if (data.length === 1) {
      somaDaColuna = data[0].valvenda_ven + data[0].valcom_fpn
    }

    return somaDaColuna
  }

  getValorTotalPorcentagemLucro (data) {
    const valorCusto = this.getTotalCampo(data, 'custo_total') + this.getTotalCampo(data, 'valcompra_ven') + this.getTotalCampo(data, 'valor_ratdes')
    const valorVenda = this.getTotalCampo(data, 'venda_total') + this.getTotalCampo(data, 'valcom_fpn') + this.getTotalCampo(data, 'valor_documento')

    return CommonHelper.getMargem(valorCusto, valorVenda)
  }

  getTotalCampo (data, nomeCampo) {
    const valores = data.map(row => row[nomeCampo])

    const total = valores.reduce((previousValue, currentValue) => previousValue + currentValue)

    return total || 0
  }
}

export default LucroDeVendasReport
