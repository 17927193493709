import { classNames } from 'primereact/utils'
import React, { useEffect, useState } from 'react'
import CheckboxInput from '../../../../../../components/inputs/CheckboxInput'
import DateInput from '../../../../../../components/inputs/DateInput'
import SelectInput from '../../../../../../components/inputs/SelectInput'
import TextAreaInput from '../../../../../../components/inputs/TextAreaInput'
import TextInput from '../../../../../../components/inputs/TextInput'
import PessoaAutocompleteTemplate from '../../../../../../components/inputs/options/PessoaAutocompleteTemplate'
import SearchInput from '../../../../../../components/inputs/searchInput/SearchInput'
import ResponsavelPagamento from '../../../../../../components/layout/ResponsavelPagamento/ResponsavelPagamento'
import FieldErrorMessage from '../../../../../../components/utils/FieldErrorMessage'
import { showErrorMessage } from '../../../../../../components/utils/Message'
import Modal from '../../../../../../components/utils/Modal'
import RequiredLabel from '../../../../../../components/utils/RequiredLabel'
import CommonHelper from '../../../../../../helpers/CommonHelper'
import {
  formatDocument,
  formatToCurrency
} from '../../../../../../helpers/formaters'
import { useValidateInput } from '../../../../../../helpers/useValidateInput'
import { getStore } from '../../../../../../redux/store'
import PessoaService from '../../../../../../services/cadastro/pessoa/PessoaService'
import FoPagNeg from '../_FoPagNeg'
import { PromissoriaValidator } from './PromissoriaValidator'

const FoPagPromissoria = (props) => {
  const {
    form,
    edicaoHabilitada,
    handleChangeCurrency,
    handleChangeCheckBox,
    getTiposRestricaoVeiculo,
    setRestricao,
    setValorVeiculo,
    hide,
    dados,
    pessoa,
    excluir,
    isLojaPaga,
    isNew
  } = props

  const deveSelecionarVeiculo = props.negociacaoForm.values.veiculosConjunto.length > 0

  const [sugestaoCedentes, setSugestaoCedentes] = useState([])
  const [empresa, setEmpresa] = useState(getStore().empresaLogada)

  useEffect(() => {
    const onLoad = async () => {
      setEmpresa(empresa)
      form.setFieldValue('veiculo', props.veiculo)

      await form.setFieldValue('nomeCedente', empresa.pessoa?.nomraz_pes)
      await form.setFieldValue('cpfCnpjCedente', empresa.pessoa?.cgccpf_pes)

      const restricaoOptions = await getTiposRestricaoVeiculo()
      setRestricao(dados.codtrv_fpn, restricaoOptions)

      if (isNew) {
        form.setFieldValue('isLojaPaga', isLojaPaga)
      }
    }

    onLoad()
  }, [])

  useEffect(() => {
    if (form.values.isLojaPaga) {
      form.setFieldValue('nomeCedente', pessoa.nomraz_pes)
      form.setFieldValue('cpfCnpjCedente', pessoa.cgccpf_pes)
    } else {
      form.setFieldValue('nomeCedente', empresa.pessoa?.nomraz_pes)
      form.setFieldValue('cpfCnpjCedente', empresa.pessoa?.cgccpf_pes)
    }
  }, [form.values.isLojaPaga])

  const sugerirCedente = async () => {
    try {
      const pessoas = await PessoaService.filterAutocomplete(form.values.nomeCedente)

      setSugestaoCedentes(pessoas)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um cedente!')
    }
  }

  const onSelectCedente = (e) => {
    form.setFieldValue('nomeCedente', e.value.nomraz_pes)
    form.setFieldValue('cpfCnpjCedente', e.value.cgccpf_pes)
  }

  const handleSelect = async (e) => {
    await form.handleChange(e)
    await form.setFieldTouched(e.target.name)
  }

  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(form)

  return (
    <Modal
      header="Promissória"
      btnSalvar={edicaoHabilitada ? form.submitForm : undefined}
      btnExcluir={(!isNew && edicaoHabilitada) ? excluir : undefined}
      width="55"
      visible={true}
      modal={true}
      onHide={hide}
    >
      <div className={`formgrid grid ${CommonHelper.isDesktop() ? 'mt-3' : ''}`}>
        <div className="field col-12 flex justify-content-around mb-4">
          <ResponsavelPagamento isLojaPaga={isNew ? isLojaPaga : form.values.isLojaPaga} />
          <div>
            <CheckboxInput
              name="isSinal"
              label="Sinal"
              checked={form.values.isSinal}
              disabled={!edicaoHabilitada}
              onChange={handleChangeCheckBox}
            />
          </div>
        </div>
        <div className={'field col-12 md:col-' + (deveSelecionarVeiculo ? '6' : '9')}>
          <RequiredLabel label="Recebedor" valid={isFormFieldValid('nomeCedente')} />
          <SearchInput
            name="nomeCedente"
            field="nomraz_pes"
            value={('' + form.values.nomeCedente).toUpperCase()}
            minLength={3}
            suggestions={sugestaoCedentes}
            completeMethod={sugerirCedente}
            itemTemplate={PessoaAutocompleteTemplate}
            onChange={handleSelect}
            onSelect={onSelectCedente}
            placeholder="Nome do cedente"
            disabled={!edicaoHabilitada}
            keyfilter={/[A-Z]|[a-z]|\s/}
            className={classNames({ 'p-invalid': isFormFieldValid('nomeCedente') }, 'inputfield w-full')}
            onBlur={() => form.setFieldTouched('nomeCedente')}
          />
          <FieldErrorMessage message={getFormErrorMessage('nomeCedente')} />
        </div>
        {deveSelecionarVeiculo && (
          <div className="field col-12 md:col-3">
            <RequiredLabel label="Veículo" valid={isFormFieldValid('veiculo.codigo_vei')} />
            <SelectInput
              value={form.values.veiculo}
              options={props.negociacaoForm.values.veiculosConjunto.map(veiculoNve => veiculoNve.veiculo)}
              onChange={(e) => form.setFieldValue('veiculo', e.target.value)}
              name='veiculo'
              optionLabel="placha"
              dataKey="codigo_vei"
              disabled={!edicaoHabilitada}
              className={classNames({ 'p-invalid': isFormFieldValid('veiculo.codigo_vei') }, 'inputfield w-full')}
              noFloatLabel
            />
            <FieldErrorMessage message={getFormErrorMessage('veiculo.codigo_vei')} />
          </div>
        )}
        <div className="field col-12 md:col-3">
          <RequiredLabel label="CPF/CNPJ da Pessoa" valid={isFormFieldValid('cpfCnpjCedente')} />
          <TextInput
            name="cpfCnpjCedente"
            value={formatDocument(form.values.cpfCnpjCedente)}
            onChange={form.handleChange}
            keyfilter="pint"
            disabled={!edicaoHabilitada}
            className={classNames({ 'p-invalid': isFormFieldValid('cpfCnpjCedente') }, 'inputfield w-full')}
            onBlur={() => form.setFieldTouched('cpfCnpjCedente')}
          />
          <FieldErrorMessage message={getFormErrorMessage('cpfCnpjCedente')} />
        </div>
        <div className="field col-12 md:col-6">
          <SelectInput
            value={form.values.restricao}
            options={form.values.restricaoOptions}
            optionLabel="descri_trv"
            onChange={form.handleChange}
            placeholder="- Selecione -"
            name="restricao"
            label="Restrição"
            noFloatLabel
            disabled={!edicaoHabilitada}
            className="inputfield w-full"
            showClear={form.values.restricao?.codigo_trv}
          />
        </div>
        <div className="field col-12 md:col-3">
          <RequiredLabel label="Valor" valid={isFormFieldValid('valor')} />
          <TextInput
            name="valor"
            value={formatToCurrency(form.values.valor)}
            disabled={!edicaoHabilitada}
            onChange={handleChangeCurrency}
            onBlur={() => {
              setValorVeiculo()
              form.setFieldTouched('valor')
            }}
            className={classNames({ 'p-invalid': isFormFieldValid('valor') }, 'inputfield w-full')}
          />
          <FieldErrorMessage message={getFormErrorMessage('valor')} />
        </div>
        <div className="field col-12 md:col-3">
          <RequiredLabel label="Data de pagamento" valid={isFormFieldValid('dataEmissao')} />

          <DateInput
            name="dataEmissao"
            value={form.values.dataEmissao}
            disabled={!edicaoHabilitada}
            onChange={form.handleChange}
            onBlur={ () =>
              form.setFieldTouched('dataEmissao')
            }
            className={classNames({ 'p-invalid': isFormFieldValid('dataEmissao') }, 'inputfield w-full')}
          />
          <FieldErrorMessage message={getFormErrorMessage('dataEmissao')} />
        </div>
        <div className="field col-12 md:col-12">
          <TextAreaInput
            name="observacoes"
            label="Observações"
            value={form.values.observacoes}
            disabled={!edicaoHabilitada}
            onChange={form.handleChange}
            className="inputfield w-full"
          />
        </div>
      </div>
    </Modal>
  )
}

export default (props) =>
  FoPagNeg({
    Component: FoPagPromissoria,
    validationSchema: PromissoriaValidator,
    ...props
  })
