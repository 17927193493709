import { AutoComplete } from 'primereact/autocomplete'
import React from 'react'
import InputBase from './base/InputBase'

export default class AutoCompleteInput extends InputBase {
  renderInput () {
    const { props } = this
    return (
      <AutoComplete
        {...props}
        field={props.field}
        value={props.value}
        minLength={props.minLength || 3}
        suggestions={props.suggestions}
        completeMethod={props.completeMethod}
        onSelect={async (e) => props.onSelect(e)}
        itemTemplate={props.itemTemplate}
        placeholder={props.placeholder || 'Digite para pesquisar.'}
        disabled={props.disabled}
        onChange={(e) => props.onChange(e)}
        onBlur={this.onBlur}
        className={this.getInputClassName()}
        visibleFiltroModal={props.visibleFiltroModal}
        full={props.full}
        semFiltroInicial={props.semFiltroInicial}
        abriModalAtalho={props.abriModalAtalho}
      />
    )
  }
}
