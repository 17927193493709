import ServiceBase from '../../base/ServiceBase'

export default class MenuWebService {
  static async getMenuWeb () {
    return await ServiceBase.getBase('/menuWeb')
  }

  static async getMenuLucroVenda () {
    return await ServiceBase.getBase('/menuWeb/acessoLucroVenda')
  }
}
