
export const getEstoqueDTO = (data = {}) => {
  return {
    produto: data.produto || [],
    dadosEstoque: data.dadosEstoque || [],
    qtdReservada: data.qtdReservada || [],
    pedidoPeca: data.pedidoPeca || []

  }
}
