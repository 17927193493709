import ServiceBase from '../../base/ServiceBase'

export default class VendedoresService {
  async filterAutoComplete (query) {
    return await ServiceBase.getBase(`vendedores?${query}`, false)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`vendedores?${query}&page=${page}&perPage=${perPage}`)
  }

  async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`vendedores?page=${page}&perPage=${perPage}`)
  }

  static async get (codigo_ven) {
    return await ServiceBase.getBase(`vendedores/${codigo_ven}`)
  }

  static async getVendedorReserva (codigo_vei) {
    if (codigo_vei) {
      return await ServiceBase.getBase(`vendedores/vendedorReserva/${codigo_vei}`)
    } else {
      return null
    }
  }
}
