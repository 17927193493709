import React from 'react'
import { Crud } from '../../../../classes/NewCrud'
import { getModeloDTO } from '../../../../dtos/cadastro/veiculo/ModeloDTO'
import ModeloForm from '../../../../forms/cadastro/veiculo/modelo/ModeloForm'
import ModeloService from '../../../../services/cadastro/veiculo/ModeloService'

export default function Modelo (props) {
  return (
    <Crud
      {...props}
      formContent={ModeloForm}
      modelGetDTO={getModeloDTO}
      service={ModeloService}
      resource="modelos"
      primarykeyname="codigo_mod"
      formTitle="Modelo"
    />
  )
}
