import BaseModel from '../../BaseModel'

export class CondicaoPagamentoModel extends BaseModel {
  constructor(params = {}) {
    super()
    this.codigo_cop = params.codigo_cop || null
    this.descri_cop = params.descri_cop || ''
    this.ativo_cop = params.ativo_cop || ''
    this.valorEntrada = param.valorEntrada || 0
    this.valorTotal = param.valorTotal || 0
    this.peredit_cop = param.peredit_cop || false

  }

  toMap() {
    return {
      codigo_cop: this.codigo_cop,
      descri_cop: this.descri_cop,
      ativo_cop: this.ativo_cop || true,
      valorEntrada: this.valorEntrada || 0,
      valorTotal: this.valorTotal,
      peredit_cop: this.peredit_cop
    }
  }
}
