
export const getProcuradorDTO = (data = {}) => {
  return {
    procurador: {
      codigo_pes: data?.codigo_pes,
      nomraz_pes: data?.nomraz_pes,
      cgccpf_pes: data?.cgccpf_pes
    },
    procuradorVeiculo: {
      veiculo: data?.veiculoNve?.veiculo || data.veiculo,
      placha: data?.veiculoNve?.veiculo.placa_vei || data.formNeg?.values?.procurador?.placa_vei,
      descri_mar: data?.veiculoNve?.veiculo.modelo.marca.descri_mar || data.formNeg?.values?.procurador?.descri_mar,
      descri_mod: data?.veiculoNve?.veiculo.modelo.descri_mod || data.formNeg?.values?.procurador?.descri_mod,
      codvei_nve: data?.veiculoNve?.codvei_nve || data.formNeg?.values?.procurador?.codvei_nve
    }
  }
}

export const postProcuradorDTO = (data) => {
  return {
    codigo_pes: data?.procurador?.codigo_pes,
    nomraz_pes: data?.procurador?.nomraz_pes,
    cgccpf_pes: data?.procurador?.cgccpf_pes,
    codvei_nve: data.procuradorVeiculo?.codvei_nve,
    placha: data?.procuradorVeiculo?.placa_vei,
    descri_mar: data?.procuradorVeiculo?.veiculo.modelo.marca?.descri_mar,
    descri_mod: data?.procuradorVeiculo?.veiculo.modelo.descri_mod,
    veiculo: data?.procuradorVeiculo?.veiculo
  }
}
