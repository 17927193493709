import { postManutencaoDTO } from '../../../dtos/cadastro/manutencao/ManutencaoDTO'
import ServiceBase from '../../base/ServiceBase'

export default class ManutencaoService {
  async save (manutencao) {
    return await ServiceBase.postBase('manutencao/', postManutencaoDTO(manutencao))
  }

  async get (codigo) {
    return await ServiceBase.getBase(`manutencao/${codigo}`)
  }

  async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`manutencao?page=${page}&perPage=${perPage}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`manutencao?${query}&page=${page}&perPage=${perPage}`)
  }

  async getFormValidator () {
    return await ServiceBase.getBase('manutencao/getFormValidator')
  }

  static async cancelarManutencao (codigoManutencao) {
    return await ServiceBase.postBase(`/manutencao/cancelar/${codigoManutencao}`)
  }

  static async reabrirManutencao (codigoManutencao) {
    return await ServiceBase.postBase(`/manutencao/reabrir/${codigoManutencao}`)
  }

  static async finalizarManutencao (codigoManutencao) {
    return await ServiceBase.postBase(`/manutencao/finalizar/${codigoManutencao}`)
  }

  static async getManutencaoReport (codigoManutencao) {
    return await ServiceBase.getBase(`/manutencao/report/${codigoManutencao}`, true, 'blob')
  }

  static async getValorTotalManutencaoDaUltimaNegociacao (codigo_vei, loading = true) {
    return await ServiceBase.getBase(`/manutencao/veiculo/${codigo_vei}`, loading)
  }

  static async getManutencaoEmAberto (codigo_vei, loading = true) {
    return await ServiceBase.getBase(`/manutencao/veiculo/getManutencaoEmAberto/${codigo_vei}`, loading)
  }
}
