import * as Yup from 'yup'

export const VeiculoValidator = Yup.object().shape({
  placa_vei: Yup.string()
    .nullable()
    .test('obrigaPlaca', 'Obrigado a preencher o campo placa!', function (value) {
      if (this.parent.obrigaPlaca && !value) {
        return false
      }
      return true
    }),
  unicil_vei: Yup.boolean()
    .test('obrigaCilEmLitros', 'Obrigado a usar cilindrada em litros!', function (value) {
      if (this.parent.obrigaCilEmLitros && value) {
        return false
      }
      return true
    }),
  anofab_vei: Yup.number()
    .nullable()
    .test('obrigaAnoFabMod', 'Obrigado a preencher o campo ano de fabricação!', function (value) {
      if (this.parent.obrigaAnoFabMod && !value) {
        return false
      }
      return true
    }),
  anomod_vei: Yup.number()
    .nullable()
    .test('obrigaAnoFabMod', 'Obrigado a preencher o campo ano de modelo!', function (value) {
      if (this.parent.obrigaAnoFabMod && !value) {
        return false
      }
      return true
    }),
  cilind_vei: Yup.number()
    .nullable().test('obrigaCilindrada', 'Obrigado a preencher o campo de cilindrada!', function (value) {
      if (this.parent.obrigaCilindrada && !value) {
        return false
      }
      return true
    }),
  usacom_tvd: Yup.number(),
  proprietario: Yup.object().shape({
    codigo_pes: Yup.number()
      .nullable()
      .required('Campo proprietário é obrigatório!')
  }),
  combustivel: Yup.object().shape({
    codigo_com: Yup.number()
      .nullable().test('obrigaCombustivel', 'Campo combustível é obrigatório!', function (value) {
        if (this?.from?.[1]?.value?.obrigaCombustivel && !value) {
          return false
        }
        return true
      })
  }),
  cor: Yup.object().shape({
    codigo_cor: Yup.number()
      .nullable()
      .test('obrigaCor', 'Campo cor é obrigatório!', function (value) {
        if (this?.from?.[1]?.value?.obrigaCor && !value) {
          return false
        }
        return true
      })
  }),
  produto: Yup.object().shape({
    codcla_pro: Yup.number()
      .nullable()
      .required('Campo código classificação é obrigatório!')
  }),
  categoria: Yup.object().shape({
    codigo_cat: Yup.number()
      .nullable()
      .required('Campo categoria é obrigatório!')
  }),
  modelo: Yup.object().shape({
    codigo_mod: Yup.number()
      .nullable()
      .required('Campo modelo é obrigatório!')
  }),
  chassi_vei: Yup.string()
    .nullable()
    .required('Obrigado a preencher o campo Chassi!'),
  tipoPintura: Yup.object().shape({
    codigo_tpp: Yup.string()
      .nullable()
      .test('obrigaTipoPintura', 'Campo tipo de pintura é obrigatório!', function (value) {
        if (((this?.from?.[1]?.value?.obrigaTipoPintura || this?.from?.[1]?.value?.codtiv_vei === 'n') && !value)) {
          return false
        }
        return true
      })
  })
})
