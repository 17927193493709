import { classNames } from 'primereact/utils'
import React, { useEffect } from 'react'
import DateInput from '../../../../../../components/inputs/DateInput'
import SelectInput from '../../../../../../components/inputs/SelectInput'
import TextAreaInput from '../../../../../../components/inputs/TextAreaInput'
import TextInput from '../../../../../../components/inputs/TextInput'
import ResponsavelPagamento from '../../../../../../components/layout/ResponsavelPagamento/ResponsavelPagamento'
import FieldErrorMessage from '../../../../../../components/utils/FieldErrorMessage'
import Modal from '../../../../../../components/utils/Modal'
import RequiredLabel from '../../../../../../components/utils/RequiredLabel'
import { formatToCurrency } from '../../../../../../helpers/formaters'
import { useValidateInput } from '../../../../../../helpers/useValidateInput'
import FoPagNeg from '../_FoPagNeg'
import { FloorPlanValidator } from './FloorPlanValidator'

const FoPagFloorPlan = (props) => {
  const {
    form,
    edicaoHabilitada,
    handleChangeCurrency,
    setValorVeiculo,
    setRestricao,
    setFinanceira,
    dados,
    getFinanceiras,
    getTiposRestricaoVeiculo,
    hide,
    excluir,
    isLojaPaga,
    isNew
  } = props

  const deveSelecionarVeiculo = props.negociacaoForm.values.veiculosConjunto.length > 0

  useEffect(() => {
    const onLoad = async () => {
      form.setFieldValue('veiculo', props.veiculo)

      const financeiraOptions = await getFinanceiras()
      setFinanceira(dados.codfin_fpn, financeiraOptions)

      const restricaoOptions = await getTiposRestricaoVeiculo()
      setRestricao(dados.codtrv_fpn, restricaoOptions)

      if (isNew) {
        form.setFieldValue('isLojaPaga', isLojaPaga)
      }
    }

    onLoad()
  }, [])

  const handleSelect = async (e) => {
    await form.handleChange(e)
    await form.setFieldTouched(e.target.name)
  }

  const handleChangePrimeiroVencimento = async (e) => {
    form.handleChange(e)
    await form.setFieldTouched('primeiroVencimento')
    await form.validateField('primeiroVencimento')
  }

  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(form)

  return (
    <Modal
      header="FloorPlan"
      btnSalvar={edicaoHabilitada ? form.submitForm : undefined}
      btnExcluir={(!isNew && edicaoHabilitada) ? excluir : undefined}
      width="65"
      visible={true}
      modal={true}
      onHide={hide}
    >
      <div className="formgrid grid">
        <div className="field col-12 flex justify-content-around mb-4">
          <ResponsavelPagamento isLojaPaga={isNew ? isLojaPaga : form.values.isLojaPaga} />
        </div>
        <div className={'field col-12 md:col-' + (deveSelecionarVeiculo ? '7' : '10')}>
          <RequiredLabel label="Financeira" valid={isFormFieldValid('financeira.codigo_fin')} />
          <SelectInput
            value={form.values.financeira}
            options={form.values.financeiraOptions}
            optionLabel="pessoa.nomraz_pes"
            onChange={handleSelect}
            placeholder="- Selecione -"
            name="financeira"
            noFloatLabel
            disabled={!edicaoHabilitada}
            className={classNames(
              { 'p-invalid': isFormFieldValid('financeira.codigo_fin') },
              'inputfield w-full'
            )}
            onHide={() => form.setFieldTouched('financeira.codigo_fin')}
          />
          <FieldErrorMessage message={getFormErrorMessage('financeira.codigo_fin')} />
        </div>
        {deveSelecionarVeiculo && (
          <div className="field col-12 md:col-3">
            <RequiredLabel label="Veículo" valid={isFormFieldValid('veiculo.codigo_vei')} />
            <SelectInput
              value={form.values.veiculo}
              options={props.negociacaoForm.values.veiculosConjunto.map(veiculoNve => veiculoNve.veiculo)}
              onChange={(e) => form.setFieldValue('veiculo', e.target.value)}
              name='veiculo'
              optionLabel="placha"
              dataKey="codigo_vei"
              disabled={!edicaoHabilitada}
              className={classNames({ 'p-invalid': isFormFieldValid('veiculo.codigo_vei') }, 'inputfield w-full')}
              noFloatLabel
            />
            <RequiredLabel label="Veículo" valid={isFormFieldValid('veiculo.codigo_vei')} />
          </div>
        )}
        <div className="field col-12 md:col-2">
          <RequiredLabel label="Valor" valid={isFormFieldValid('valor')} />
          <TextInput
            name="valor"
            value={formatToCurrency(form.values.valor)}
            disabled={!edicaoHabilitada}
            onChange={handleChangeCurrency}
            onBlur={() => {
              setValorVeiculo()
              form.setFieldTouched('valor')
            }}
            className={classNames({ 'p-invalid': isFormFieldValid('valor') }, 'inputfield w-full')}
          />
          <FieldErrorMessage message={getFormErrorMessage('valor')} />
        </div>
        <div className="field col-12 md:col-5">
          <SelectInput
            value={form.values.restricao}
            options={form.values.restricaoOptions}
            optionLabel="descri_trv"
            onChange={form.handleChange}
            placeholder="- Selecione -"
            name="restricao"
            label="Restrição"
            noFloatLabel
            disabled={!edicaoHabilitada}
            className="inputfield w-full"
            showClear={form.values.restricao?.codigo_trv}
          />
        </div>
        <div className="field col-12 md:col-2">
          <RequiredLabel label="Data de Emissão" valid={isFormFieldValid('dataEmissao')} />
          <DateInput
            name="dataEmissao"
            value={form.values.dataEmissao}
            disabled={!edicaoHabilitada}
            onChange={form.handleChange}
            onBlur={ () =>
              form.setFieldTouched('dataEmissao')
            }
            className={classNames({ 'p-invalid': isFormFieldValid('dataEmissao') }, 'inputfield w-full')}
          />
          <FieldErrorMessage message={getFormErrorMessage('dataEmissao')} />
        </div>
        <div className="field col-12 md:col-3">
          <RequiredLabel label="Primeiro vencimento" valid={isFormFieldValid('primeiroVencimento')} />
          <DateInput
            name="primeiroVencimento"
            disabled={!edicaoHabilitada}
            value={form.values.primeiroVencimento}
            onChange={handleChangePrimeiroVencimento}
            className={classNames({ 'p-invalid': isFormFieldValid('primeiroVencimento') }, 'inputfield w-full')}
          />
          <FieldErrorMessage message={getFormErrorMessage('primeiroVencimento')} />
        </div>
        <div className="field col-12 md:col-2">
          <TextInput
            name="floorPlan"
            label="Floor Plan (% a.m.)"
            value={form.values.floorPlan}
            disabled={!edicaoHabilitada}
            onChange={form.handleChange}
            keyfilter="pnum"
            className="inputfield w-full"
          />
        </div>
        <div className="field col-12">
          <TextAreaInput
            name="observacoes"
            label="Observações"
            value={form.values.observacoes}
            disabled={!edicaoHabilitada}
            onChange={form.handleChange}
            className="inputfield w-full"
          />
        </div>
      </div>
    </Modal>
  )
}

export default (props) =>
  FoPagNeg({
    Component: FoPagFloorPlan,
    validationSchema: FloorPlanValidator,
    ...props
  })
