import React from 'react'
import Modal from '../../../../components/utils/Modal'
import { PessoaFisica } from '../../../../views/cadastro/pessoa/fisica/PessoaFisica'

const PessoaFisicaModal = ({ hide, visible, onSaveModal, onDeleteModal, primaryKey, apenasVisualizacao }) => {
  return (
    <Modal
      width="75"
      visible={visible}
      onHide={() => hide()}
      header={primaryKey ? 'Pessoa Física - ' + primaryKey : 'Cadastro de pessoa física'}
    >
      <PessoaFisica
        onSaveModal={onSaveModal}
        onDeleteModal={onDeleteModal}
        codigoSelecionado={primaryKey}
        apenasVisualizacao={apenasVisualizacao}
        onHide={() => hide()}
      />
    </Modal>
  )
}

export default PessoaFisicaModal
