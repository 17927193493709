import { DataTable } from 'primereact/datatable'
import React from 'react'
import CommonHelper from '../../../helpers/CommonHelper'
import { getColunasHabilitadas } from '../../../helpers/tableConfigs'

function DataListSimples (props) {
  function getTotalizadores () {
    const totalizadoresHtml = []

    for (const column of props.columns) {
      const columnIndex = props.columns.indexOf(column)

      const columnWidth = column.component.props.style.width

      const width = CommonHelper.isDesktop() ? columnWidth : '35%'
      const maxWidth = CommonHelper.isDesktop() ? columnWidth : '100%'

      const totalizadorConfig = props.totalizadores.find(totalizadorConfig => totalizadorConfig.key === column.key)

      let totalizadorValue = 0

      for (const row of props.value) {
        if (totalizadorConfig?.condition) {
          if (totalizadorConfig.condition(row)) {
            totalizadorValue += row[column.key]
          }
        } else {
          totalizadorValue += row[column.key]
        }
      }

      if (totalizadorConfig && totalizadorValue) {
        console.log(props.widthTotalizador)
        totalizadoresHtml.push(
          <div key={columnIndex} style={{ width, maxWidth }} className={`flex ${props.widthTotalizador}`}>
            {!CommonHelper.isDesktop() && (
              <label className="totalizador-label">{totalizadorConfig.label}</label>
            )}
            <div className="text-center w-full">
              {totalizadorConfig.formatFunction ? totalizadorConfig.formatFunction(totalizadorValue) : totalizadorValue}
            </div>
          </div>
        )
      } else {
        totalizadoresHtml.push(<div key={columnIndex} style={{ width, maxWidth }} className="w-full"></div>)
      }
    }

    return totalizadoresHtml
  }

  return (
    <>
      <DataTable
        className="p-datatable-responsive"
        value={props.value}
        rowClassName={props.rowClassName}
        emptyMessage="Nenhum item encontrado"
        onSelectionChange={(e) => props.onSelect(e)}
        selectionMode="single"
      >
        {getColunasHabilitadas(props.columns)}
      </DataTable>
      {props.totalizadores && (
        <div className={'mt-2 flex' + (!CommonHelper.isDesktop() ? '-column' : '')}>
          {getTotalizadores()}
        </div>
      )}
    </>
  )
}

export default DataListSimples
