import List from '../../../classes/List'
import { createColumns, getColunasHabilitadas } from '../../../helpers/tableConfigs'
import React from 'react'
import Page from '../../../components/layout/Page'
import { Button } from 'primereact/button'
import PreferencesButton from '../../../components/utils/PreferencesButton'
import Container from '../../../components/layout/Container'
import DataList from '../../../components/layout/DataList'
import TablePreferences from '../../../components/utils/TablePreferences'
import ManutencaoService from '../../../services/cadastro/manutencao/ManutencaoService'
import { getManutencaoDTO } from '../../../dtos/cadastro/manutencao/ManutencaoDTO'
import CommonHelper from '../../../helpers/CommonHelper'
import { classNames } from 'primereact/utils'

export class ManutencaoList extends List {
  constructor (props) {
    super(
      props,
      getManutencaoDTO,
      ManutencaoService,
      'manutencao',
      'codigo_man'
    )

    this.state = {
      ...this.state,
      visiblePreferencesModal: false
    }

    this.setColumns(createColumns([
      { field: 'codigo_man', header: 'Código', width: '16%' },
      { field: 'nomeFornecedor', header: 'Fornecedor', width: '16%' },
      { field: 'plachaVeiculo', header: 'Veículo', width: '16%' },
      { field: 'valorTotalManutencao', header: 'Valor', width: '16%' },
      { field: 'datpre_man', header: 'Previsão retorno', width: '16%', customBody: this.getDataPrevisaoRetorno },
      { field: 'datret_man', header: 'Retorno', width: '16%', customBody: this.getDataRetorno }
    ]))
  }

  getDataPrevisaoRetorno (data) {
    return CommonHelper.dateToBrazilian(data.datpre_man)
  }

  getDataRetorno (data) {
    return CommonHelper.dateToBrazilian(data.datret_man)
  }

  render () {
    const state = this.state

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Manutenções</h4>
          <div className="page-header-buttons">
            <Button
              className="mr-2"
              label={CommonHelper.isDesktop() && 'Novo'}
              icon="pi pi-plus"
              onClick={this.onNew}
            />
          </div>
        </div>
        <div className="table-options-container">
          <div className="table-options">
            <div className={classNames({ active: state.opcaoSelecionada === 't' }, 'filter-option')}>
              <span className="option-label">Todas</span>
              <div className="option-quantity">
                {state.totalRecords}
              </div>
            </div>
          </div>
          <div className="table-preferences">
            <PreferencesButton
              onClick={() => this.setState({ visiblePreferencesModal: true })}
            />
          </div>
        </div>
        <Container>
          <DataList
            data={state.list}
            selected={state.selected}
            rows={state.rows}
            onSelect={this.onSelect}
            totalRecords={state.totalRecords}
            first={state.first}
            onPage={this.onPageChange}
            page={state.page}
            onRowSelect={(e) => this.onView(e.data)}
            filterPlaceholder="Procurar manutenções"
            filterName="descricaoFiltro"
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
            responsive
            showFilter
          >
            {getColunasHabilitadas(this.columns, 'manutencao')}
          </DataList>
        </Container>
        <TablePreferences
          title="Preferências da tabela de manutenção"
          tableName="manutencao"
          columns={this.columns}
          visible={state.visiblePreferencesModal}
          hide={() => this.setState({ visiblePreferencesModal: false })}
        />
      </Page>
    )
  }
}
