import BaseModel from '../../BaseModel'

export class ContaModel extends BaseModel {
  constructor (params = {}) {
    super()
    this.codigo_con = params.codigo_con || null
    this.codtic_con = params.codtic_con || ''
    this.codcob_con = params.codcob_con || ''
    this.codemp_con = params.codemp_con || null
    this.descri_con = params.descri_con || null
    this.databe_con = params.databe_con || null
    this.codtit_con = params.codtit_con || null
    this.persalneg_con = params.persalneg_con || null
    this.codban_cob = params.codban_cob || null
  }

  toMap () {
    return {
      codigo_con: this.codigo_con || null,
      codtic_con: this.codtic_con || '',
      codcob_con: this.codcob_con || '',
      codemp_con: this.codemp_con || null,
      descri_con: this.descri_con || null,
      databe_con: this.databe_con || null,
      codtit_con: this.codtit_con || null,
      persalneg_con: this.persalneg_con || null,
      codban_cob: this.codban_cob || null
    }
  }
}
