import React, { useEffect, useState } from 'react'
import AutoCompleteInput from '../../../../../components/inputs/AutoCompleteInput'
import CheckboxInput from '../../../../../components/inputs/CheckboxInput'
import { showWarnMessage } from '../../../../../components/utils/Message'
import Modal from '../../../../../components/utils/Modal'
import { getConveniadoDTO } from '../../../../../dtos/cadastro/pessoa/ConveniadoDTO'
import { formatDocument } from '../../../../../helpers/formaters'
import { PessoaModel } from '../../../../../models/cadastro/pessoa/PessoaModel'
import PessoaService from '../../../../../services/cadastro/pessoa/PessoaService'

function ModalPessoaConvenio ({ visible, onHide, selected, edicaoHabilitada, form, camposObrigatorios, setSelected, conveniados }) {
  const [sugestaoConveniado, setSugestaoConveniado] = useState([])
  const [ativo, setAtivo] = useState(true)
  useEffect(() => {
    if (selected) {
      setAtivo(selected?.ativo_cvp ?? ativo)
      form.setFieldValue('conveniado', getConveniadoDTO(selected))
    }
  }, [selected])

  function excluirConveniado () {
    const formConveniado = conveniados
    const conveniadoIgual = formConveniado?.filter(conveniado => conveniado.codigo_pes === selected.codigo_pes)
    for (const conveniado of conveniadoIgual) {
      const indexConveniado = formConveniado.indexOf(conveniado)
      formConveniado.splice(indexConveniado, 1)
    }
    form.setFieldValue('conveniado', formConveniado)

    onHide()
  }

  async function adicionarConveniado () {
    if (!form.values.conveniado.codigo_pes) {
      showWarnMessage('Selecione um conveniado!')
      return false
    }
    await form.submitForm()
    await form.resetForm()
  }

  async function sugerirConveniado () {
    try {
      const conveniado = await PessoaService.filterAutocomplete(form.values.cliente.nomraz_pes)

      if (conveniado.length <= 0) {
        showWarnMessage('Cliente não encontrado!')
      }
      setSugestaoConveniado(conveniado)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um cliente!')
    }
  }

  function handleChangeConveniado (e) {
    form.setFieldValue('conveniado', { codigo_pes: e.value.codigo_pes, nomraz_pes: e.value.nomraz_pes, ativo_cvp: ativo })
  }

  function onSaveModalConveniado (conveniado) {
    onSelectConveniado({ value: conveniado })
  }

  async function onSelectConveniado (e) {
    form.setFieldValue('conveniado', { codigo_pes: e.value.codigo_pes, nomraz_pes: e.value.nomraz_pes, ativo_cvp: ativo })
  }

  function onChangeAtivo (e) {
    setAtivo(e.target.checked)
    form.setFieldValue('conveniado.ativo_cvp', e.target.checked)
  }

  function fecharModal () {
    setSelected([])
    onHide()
    setAtivo(true)
  }

  return (
    <>
      <Modal
        visible={visible}
        onHide={fecharModal}
        header={'Pessoa'}
        btnSalvarLabel={selected ? 'Atualizar conveniado' : 'Adicionar conveniado'}
        icon={selected ? '' : 'pi pi-plus'}
        width={40}
        btnSalvar={(edicaoHabilitada) ? adicionarConveniado : undefined}
        btnExcluir={(edicaoHabilitada && selected) ? excluirConveniado : undefined}
      >
        <div className='md:flex'>
          <div className="field col-12 md:col-10">
            <AutoCompleteInput
              //AutoComplete
              name="conveniado.codigo_pes"
              field="nomraz_pes"
              label="Conveniado"
              value={form.values.conveniado && form.values.conveniado.nomraz_pes || selected}
              placeholder="Nome do conveniado"
              disabled={!edicaoHabilitada}
              onSelect={async (e) => { await onSelectConveniado(e) }}
              suggestions={sugestaoConveniado}
              completeMethod={sugerirConveniado}
              onChange={handleChangeConveniado}
              camposObrigatorios={camposObrigatorios}

              form={form}
              //RegisterModal
              selected={form.values.conveniado}
              onSaveModal={onSaveModalConveniado}
              onDeleteModal={async () => await onSelectConveniado({})}

              //FiltroModal
              filtrotitle="Pesquisa de conveniado"
              service={PessoaService}
              model={PessoaModel}
              primarykeyname="codigo_pes"
              columns={[
                { campo: 'codigo_pes', nome: 'Código' },
                { campo: 'nomraz_pes', nome: 'Nome' },
                { campo: 'cgccpf_pes', nome: 'CPF/CNPJ', format: formatDocument }
              ]}
            />

          </div>
          <div className="field col-12 md:col-2 field checkbox-container">
            <CheckboxInput
              label={'Ativo?'}
              checked={ativo}
              onChange={onChangeAtivo}
              disabled={!edicaoHabilitada}

            />

          </div>
        </div>

      </Modal>
    </>
  )
}

export default ModalPessoaConvenio
