import React, { useEffect, useState } from 'react'
import TextInput from '../../components/inputs/TextInput'
import Modal from '../../components/utils/Modal'

const TrocarEmpresaModal = ({ visible, handleSelectCompany, onHide, onConfirm, companies, selectedCompany }) => {
  const [activeCompany, setActiveCompany] = useState(selectedCompany)
  const [modalBody, setModalBody] = useState(<></>)
  const [filtroCompanies, setFiltroCompanies] = useState('')

  useEffect(() => {
    renderCompaniesList('')
  }, [companies])

  useEffect(() => {
    setActiveCompany(selectedCompany)
  }, [selectedCompany])

  useEffect(() => {
    if (activeCompany) {
      onConfirm()
      localStorage.removeItem('FilterConfigs')
    }
  }, [activeCompany])

  const renderCompaniesList = (filtro) => {
    const empresas = companies
    const modalBody = empresas.filter(company => company.apelid_pes.toUpperCase().includes(filtro.toUpperCase()))
      .map((company, idx) =>
        <div
          key={company.codigo_pes}
          className={'list-selection ' + (idx === 0 ? 'first' : '')}
          onClick={async () => {
            await handleSelectCompany(company)
          }}
        >
          <div><p>{company.apelid_pes}</p></div>
        </div>
      )

    setModalBody(modalBody)
  }

  return (
    <Modal
      header="Selecionar empresa"
      width={50}
      visible={visible}
      onHide={onHide}
    >
      <div className="field col-12">
        <TextInput
          value={filtroCompanies}
          placeholder="Filtrar por nome da empresa"
          className="inputfield w-full"
          onChange={async (e) => {
            setFiltroCompanies(e.target.value)
            renderCompaniesList(e.target.value)
          }}
        />
      </div>
      {modalBody}
    </Modal>
  )
}

export default TrocarEmpresaModal
