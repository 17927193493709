import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { isLoggedIn } from '../../helpers/auth'
import AppContent from '../layout/AppContent'

const PrivateRoute = ({ component: Component, codigoMenu, ignorePermissaoAcesso, ...rest }) => (
  <Route {...rest} render={props => {
    if (!isLoggedIn()) {
      return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    }

    return (
      <AppContent options={{ ignorePermissaoAcesso, codigoMenu }}>
        <Component {...props} />
      </AppContent>
    )
  }} />
)

export default PrivateRoute
