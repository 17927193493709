import React, { Component } from 'react'
import { Button } from 'primereact/button'
import CorService from '../../../services/cadastro/veiculo/CorService'
import { showErrorMessage, showSuccessMessage } from '../../../components/utils/Message'
import Container from '../../../components/layout/Container'
import Confirm from '../../../components/utils/Confirm'
import AccordionContainer from '../../../components/layout/AccordionContainer'
import AccordionPage from '../../../components/layout/AccordionPage'
import DadosPrincipaisCor from './formPages/DadosPrincipaisCor'

class CorForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      visibleConfirm: false,
      cores: [],
      colors: [],
      edicaoHabilitada: false
    }
  }

  componentDidMount () {
    if (this.props.isNew)
      this.setState({ edicaoHabilitada: true })
  }

  validation = () => {
    if (!this.props.model.descri_cor.length) {
      showErrorMessage('Informe a descrição')
    } else {
      this.props.onSave()
    }
  }

  deleteForm = async (e) => {
    const cor = new CorService()
    try {
      await cor.delete(this.props.model.codigo_cor)
      showSuccessMessage('Registro deletado com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar deletar a cor!')
    }
  }

  render () {
    const { model, onChange, isNew } = this.props
    const { edicaoHabilitada } = this.state

    return (
      <Container col="12" className="Cor">
        <div className="flex justify-content-between form-header">
          <h1>Cadastro de Cor {model.codigo_cor ? `- ${model.codigo_cor}` : ''}</h1>
          {!isNew && (
            <div className="flex justify-content-between">
              <Button
                label="Excluir"
                icon="pi pi-times"
                type="button"
                onClick={() => this.setState({ visibleConfirm: true })}
                className="p-button-danger mr-2"
              />
              <Button
                label="Editar"
                className="edit-button"
                icon="pi pi-pencil"
                type="button"
                onClick={() => this.setState({ edicaoHabilitada: !this.state.edicaoHabilitada })}
              />
            </div>
          )}
        </div>
        <div className="grid" style={{ minWidth: '314px' }}>
          <AccordionContainer>
            <AccordionPage header="Informações principais" active>
              <DadosPrincipaisCor
                model={model}
                edicaoHabilitada={edicaoHabilitada}
                onChange={onChange}
              />
            </AccordionPage>
          </AccordionContainer>
        </div>
        <div className="flex justify-content-end salvar" style={{ paddingTop: '1em', paddingBottom: '1em' }}>
          {edicaoHabilitada && (
            <Button
              label="Salvar"
              className="p-button-primary"
              onClick={this.validation}
            />
          )}
        </div>
        <Confirm
          visible={this.state.visibleConfirm}
          onConfirm={() => this.deleteForm()}
          onCancel={() => this.setState({ visibleConfirm: false })}
          title="Confirmação"
          description="Deseja realmente excluir este registro?"
        />
      </Container>
    )
  }
}

export default CorForm
