import { postVeiculoDTO } from '../../../dtos/cadastro/veiculo/VeiculoDTO'
import ServiceBase from '../../base/ServiceBase'

export default class VeiculoService {
  async get (id) {
    return await ServiceBase.getBase(`vehicle/${id}`)
  }

  static async getTipoCambio (descriCam) {
    return await ServiceBase.getBase(`vehicle/cambio/${descriCam}`)
  }

  async getInformacoesSimulacao (id) {
    return await ServiceBase.getBase(`vehicle/getInformacoesSimulacao/${id}`)
  }

  async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`vehicles?page=${page}&perPage=${perPage}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`vehicles?${query}&page=${page}&perPage=${perPage}`)
  }

  static async getVeiculo (id) {
    return await ServiceBase.getBase(`vehicle/${id}`)
  }

  async getFormValidator () {
    return await ServiceBase.getBase('/vehicles/validator')
  }

  async filterAutoComplete (query) {
    return await ServiceBase.getBase(`vehicles?${query}`)
  }

  async save (data) {
    return await ServiceBase.postBase('vehicle', postVeiculoDTO(data))
  }

  static async saveVeiculo (data) {
    return await ServiceBase.postBase('vehicle', postVeiculoDTO(data))
  }

  async delete (id) {
    return await ServiceBase.deleteBase(`vehicle/${id}`)
  }

  static async getByPlate (plate, id) {
    return await ServiceBase.getBase(`vehicle/placa/${plate}/${id ? id : 0}`)
  }

  static async filterCarros (query) {
    return await ServiceBase.getBase('vehicles?' + query)
  }

  static async getAllColors () {
    return await ServiceBase.getBase('vehicles/colors', false)
  }

  static async getAllAccessories () {
    return await ServiceBase.getBase('vehicles/accessories', false)
  }

  static async getAllFuels () {
    return await ServiceBase.getBase('vehicles/fuels', false)
  }

  static async getAllCambios () {
    return await ServiceBase.getBase('vehicles/cambios', false)
  }

  static async getAllCategorias () {
    return await ServiceBase.getBase('vehicles/categorias', false)
  }

  static async getAllTipoPinturas () {
    return await ServiceBase.getBase('vehicles/tipopinturas', false)
  }

  static async getAllModels () {
    return await ServiceBase.getBase('vehicles/models', false)
  }

  static async filterModels (query) {
    return await ServiceBase.getBase(`vehicles/models?description=${query}`, false)
  }

  static async reservar (codigo_pre, codigo_ven, codigo_pes, observacao) {
    return await ServiceBase.postBase('/vehicle/reservar', {
      codigo_pre,
      codigo_ven,
      codigo_pes,
      observacao
    })
  }

  static async cancelarReserva (codigo_pre) {
    return await ServiceBase.putBase(`/vehicle/cancelarreserva/${codigo_pre}`)
  }

  static async reservarAutoGestor (codigo_pre, codigo_ven, clienteAutoGestor, removerAnuncios) {
    return await ServiceBase.postBase('/vehicle/reservarAutoGestor', {
      codigo_pre,
      codigo_ven,
      clienteAutoGestor,
      removerAnuncios
    })
  }

  static async cancelarReservaAutoGestor (codigo_pre, codigoVen) {
    return await ServiceBase.putBase(`/vehicle/cancelarreservaAutoGestor/${codigo_pre}/${codigoVen}`)
  }

  static async getDadosReserva (codigo_pre) {
    return await ServiceBase.getBase(`/vehicle/reserva/${codigo_pre}`)
  }

  static async getDadosBloqueio (codigo_vei) {
    return await ServiceBase.getBase(`/vehicle/getDadosBloqueio/${codigo_vei}`)
  }

  static async bloquearVeiculo (codigo_vei, motivoBloqueio) {
    return await ServiceBase.postBase('/vehicle/bloquearVeiculo', { codigo_vei, motivoBloqueio })
  }

  static async desbloquearVeiculo (codigo_vei) {
    return await ServiceBase.postBase('/vehicle/desbloquearVeiculo', { codigo_vei })
  }

  static async getDiasEmEstoque (codigo_vei, loading = true) {
    return await ServiceBase.getBase(`/vehicle/diasestoque/${codigo_vei}`, loading)
  }

  static async getDescricaoCompleta (codigo_vei, loading = true) {
    return await ServiceBase.getBase(`/vehicle/descricaocompleta/${codigo_vei}`, loading)
  }
}
