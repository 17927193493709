import React, { Component } from 'react'

export default class PreferencesButton extends Component {
  render () {
    return (
      <i
        className="pi pi-cog preferences-button"
        onClick={this.props.onClick}
      />
    )
  }
}
