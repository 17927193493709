import produce from 'immer'
import createMenuSideBar from '../components/layout/CreateMenuSideBar'
import {
  ATUALIZAR_QUANTIDADE_CONSIGNACOES_PENDENTE_PAGAMENTO,
  ATUALIZAR_QUANTIDADE_NEGOCIACOES_PENDENTE_APROVACAO,
  CLEAR_STORE,
  DECREMENT_ACTIVE_LOADING_REQUESTS,
  GENERATE_MENU_SIDEBAR,
  INCREMENT_ACTIVE_LOADING_REQUESTS,
  REMOVE_MARCA,
  REMOVE_PESSOA_FISICA,
  REMOVE_PESSOA_JURIDICA,
  REMOVE_VEICULO,
  SET_EMPRESA_LOGADA,
  SET_INICIAL_LOADED,
  SET_MARCA,
  SET_MENUSACESSO,
  SET_PESSOA_FISICA,
  SET_PESSOA_JURIDICA,
  SET_USER_AS_AUTENTICATED,
  SET_USER_AS_NOT_AUTENTICATED,
  SET_USUARIO_LOGADO,
  SET_VEICULO,
  START_LOADING,
  STOP_LOADING,
  TOGGLE_LOADING
} from './types'

const initialState = {
  codigoBrasil: 1058,
  isInicialLoaded: false,
  isUserAutenticated: false,
  loading: false,
  menusAcesso: [],
  usuarioLogado: {},
  empresaLogada: {},
  veiculoVenda: {},
  pessoaFisica: {},
  pessoaJuridica: {},
  marca: {},
  veiculo: {},
  quantidadeConsignacoesPendentePagamento: 0,
  quantidadeNegociacoesPendenteAprovacao: 0,
  menuSideBar: [],
  activeLoadingRequests: 0
}

export default function reducer (state = initialState, action) {
  switch (action.type) {
  case CLEAR_STORE:
    return clearStore()
  case SET_INICIAL_LOADED:
    return setInicialLoaded()
  case SET_USER_AS_AUTENTICATED:
    return setUserAsAutenticated()
  case SET_USER_AS_NOT_AUTENTICATED:
    return setUserAsNotAutenticated()
  case TOGGLE_LOADING:
    return toggleLoading()
  case START_LOADING:
    return startLoading()
  case STOP_LOADING:
    return stopLoading()
  case SET_MENUSACESSO:
    return setMenusAcesso(action)
  case SET_USUARIO_LOGADO:
    return setUsuarioLogado(action)
  case SET_EMPRESA_LOGADA:
    return setEmpresaLogada(action)
  case SET_PESSOA_JURIDICA:
    return setPessoaJuridica(action)
  case REMOVE_PESSOA_JURIDICA:
    return removePessoaJuridica()
  case SET_PESSOA_FISICA:
    return setPessoaFisica(action)
  case REMOVE_PESSOA_FISICA:
    return removePessoaFisica()
  case SET_MARCA:
    return setMarca(action)
  case REMOVE_MARCA:
    return removeMarca()
  case SET_VEICULO:
    return setVeiculo(action)
  case REMOVE_VEICULO:
    return removeVeiculo()
  case INCREMENT_ACTIVE_LOADING_REQUESTS:
    return incrementActiveLoadingRequests()
  case DECREMENT_ACTIVE_LOADING_REQUESTS:
    return decrementActiveLoadingRequests()
  case ATUALIZAR_QUANTIDADE_CONSIGNACOES_PENDENTE_PAGAMENTO:
    return atualizaQuantidadeConsignacoesPendentePagamento(action)
  case ATUALIZAR_QUANTIDADE_NEGOCIACOES_PENDENTE_APROVACAO:
    return atualizaQuantidadeNegociacoesPendenteAprovacao(action)
  case GENERATE_MENU_SIDEBAR:
    return generateMenuSideBar()
  default:
    return state
  }

  function clearStore () {
    return produce(state, draft => {
      draft.isUserAutenticated = false
      draft.loading = false
      draft.menusAcesso = []
      draft.usuarioLogado = {}
      draft.empresaLogada = {}
      draft.veiculoVenda = {}
      draft.pessoaFisica = {}
      draft.pessoaJuridica = {}
      draft.marca = {}
      draft.veiculo = {}
      draft.activeLoadingRequests = 0
      draft.quantidadeConsignacoesPendentePagamento = 0
      draft.quantidadeNegociacoesPendenteAprovacao = 0
      draft.menuSideBar = []
    })
  }

  function setInicialLoaded () {
    return produce(state, draft => {
      draft.isInicialLoaded = true
    })
  }

  function setUserAsAutenticated () {
    return produce(state, draft => {
      draft.isUserAutenticated = true
    })
  }

  function setUserAsNotAutenticated () {
    return produce(state, draft => {
      draft.isUserAutenticated = false
    })
  }

  function toggleLoading () {
    return produce(state, draft => {
      draft.loading = !state.loading
    })
  }

  function startLoading () {
    return produce(state, draft => {
      draft.loading = true
    })
  }

  function stopLoading () {
    return produce(state, draft => {
      draft.loading = false
    })
  }

  function setMenusAcesso (action) {
    return produce(state, draft => {
      draft.menusAcesso = action.payload
    })
  }

  function setUsuarioLogado (action) {
    return produce(state, draft => {
      draft.usuarioLogado = action.payload
    })
  }

  function setEmpresaLogada (action) {
    return produce(state, draft => {
      draft.empresaLogada = action.payload
    })
  }

  function setPessoaJuridica (action) {
    return produce(state, draft => {
      draft.pessoaJuridica = action.payload
    })
  }

  function removePessoaJuridica () {
    return produce(state, draft => {
      draft.pessoaJuridica = {}
    })
  }

  function setPessoaFisica (action) {
    return produce(state, draft => {
      draft.pessoaFisica = action.payload
    })
  }

  function removePessoaFisica () {
    return produce(state, draft => {
      draft.pessoaFisica = {}
    })
  }

  function setMarca (action) {
    return produce(state, draft => {
      draft.marca = action.payload
    })
  }

  function removeMarca () {
    return produce(state, draft => {
      draft.marca = {}
    })
  }

  function setVeiculo (action) {
    return produce(state, draft => {
      draft.veiculo = action.payload
    })
  }

  function removeVeiculo () {
    return produce(state, draft => {
      draft.veiculo = {}
    })
  }

  function incrementActiveLoadingRequests () {
    return produce(state, draft => {
      draft.activeLoadingRequests = draft.activeLoadingRequests + 1
    })
  }

  function decrementActiveLoadingRequests () {
    return produce(state, draft => {
      draft.activeLoadingRequests = draft.activeLoadingRequests - 1
    })
  }

  function atualizaQuantidadeConsignacoesPendentePagamento (action) {
    return produce(state, draft => {
      draft.quantidadeConsignacoesPendentePagamento = action.payload
    })
  }

  function atualizaQuantidadeNegociacoesPendenteAprovacao (action) {
    return produce(state, draft => {
      draft.quantidadeNegociacoesPendenteAprovacao = action.payload
    })
  }

  function generateMenuSideBar () {
    return produce(state, draft => {
      draft.menuSideBar = createMenuSideBar(state)
    })
  }
}
