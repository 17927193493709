import { classNames } from 'primereact/utils'
import React, { useEffect, useState } from 'react'
import SearchInput from '../../../../components/inputs/searchInput/SearchInput'
import { baseForm } from '../../../../components/utils/BaseForm'
import Modal from '../../../../components/utils/Modal'
import { formatDocument, formatPlaca } from '../../../../helpers/formaters'
import { useValidateInput } from '../../../../helpers/useValidateInput'
import PessoaService from '../../../../services/cadastro/pessoa/PessoaService'

import FieldErrorMessage from '../../../../components/utils/FieldErrorMessage'
import { PessoaModel } from '../../../../models/cadastro/pessoa/PessoaModel'
import { PessoaJuridica } from '../../../../views/cadastro/pessoa/juridica/PessoaJuridica'

import VeiculoTemplate from '../../../../components/utils/VeiculoTemplate'
import Veiculo from '../../../../views/cadastro/veiculo/veiculo/Veiculo'

import PessoaAutocompleteTemplate from '../../../../components/inputs/options/PessoaAutocompleteTemplate.js'
import { getVeiculoDTO } from '../../../../dtos/cadastro/veiculo/VeiculoDTO.js'
import { getProcuradorDTO, postProcuradorDTO } from '../../../../dtos/vendas/negociacao/ProcuradorDTO.js'
import { PessoaFisica } from '../../../../views/cadastro/pessoa/fisica/PessoaFisica.js'
import { ProcuradorValidator, ProcuradorVeiculoValidator } from './ProcuradorValidator'

const ProcuradorModal = ({ edicaoHabilitada, veiculoNve, hide, selected, formNeg, visible }) => {
  const isNewDocument = Object.keys(selected).length === 0

  const handleSubmit = (data) => {
    adicionarProcurador(postProcuradorDTO(data))
    hide()
  }

  const form = baseForm({
    initialValues: getProcuradorDTO({ veiculoNve, formNeg }),
    validationSchema: formNeg.values.veiculosConjunto.length > 0 ? ProcuradorVeiculoValidator : ProcuradorValidator,
    onSubmit: handleSubmit
  })

  const [sugestaoDestinos, setSugestaoPessoa] = useState([])

  useEffect(async () => {
    if (isNewDocument) {
      await form.setValues(getProcuradorDTO({ veiculoNve }))
    } else {
      await form.setValues(getProcuradorDTO(selected, true))
      form.setFieldValue('procuradorVeiculo.codvei_nve', form.values.procuradorVeiculo?.codvei_nve)
    }
  }, [isNewDocument])
  useEffect(() => {
    if (visible && isNewDocument) {
      form.resetForm()
    }
  }, [visible])

  function onSaveModalDestino (destino) {
    onSelectPessoa({ value: destino.pessoa })
  }

  const handleChangePessoa = async (e) => {
    form.setFieldValue('procurador', e.target.value)
    form.setFieldValue('procurador.nomraz_pes', e.target.value)
  }
  const sugerirPessoa = async () => {
    try {
      const pessoas = await PessoaService.filterAutocomplete(form.values.procurador.nomraz_pes)
      setSugestaoPessoa(pessoas)
    } catch (error) {
    }
  }

  const onSelectVeiculo = async (e) => {
    form.setFieldValue('procuradorVeiculo.veiculo', e.value.veiculo)
    form.setFieldValue('procuradorVeiculo.veiculo.placa_vei', e.value.veiculo.placa_vei)
    form.setFieldValue('procuradorVeiculo.codvei_nve', e.value.codvei_nve)
  }
  const onSelectPessoa = async (e) => {
    form.setFieldValue('procurador', e.value)
    form.setFieldValue('codigo_pes', e.value.codigo_pes)
    form.setFieldValue('procurador.veiculoConjunto', formNeg.values.veiculosConjunto.length)
  }

  const handleChangeVeiculo = (e) => {
    form.setFieldValue('procuradorVeiculo.veiculo', e.target.value)
    form.setFieldValue('procuradorVeiculo.veiculo.placa_vei', e.target.value)
  }

  async function onSaveModalVeiculo (veiculo) {
    await onSelectVeiculo({ value: veiculo })
  }
  const adicionarProcurador = (procuradores) => {
    const { procurador } = formNeg.values
    if (isNewDocument) {
      procurador.push({ ...procuradores, idx: procurador.length })
    } else {
      const indexProcurador = procurador.indexOf(selected)
      procurador.splice(indexProcurador, 1, procuradores)
    }
    formNeg.setFieldValue('procurador', procurador)
    form.setFieldValue('procurador', procurador)
  }
  const deletarProcurador = () => {
    const { procurador } = formNeg.values
    const indexProcuradores = procurador.indexOf(selected)
    procurador.splice(indexProcuradores, 1)
    formNeg.setFieldValue('procurador', procurador)
    form.setFieldValue('procurador', procurador)
    hide()
  }

  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(form)

  class ServiceConjunto {
    filter () {
      let total = formNeg.values.veiculosConjunto?.length
      let objetoConjunto = { meta: { total: total }, data: formNeg.values.veiculosConjunto }
      return objetoConjunto
    }
  }
  let descricaoVeiculo = (form?.values.procuradorVeiculo.veiculo?.modelo?.marca?.descri_mar || form?.values.procuradorVeiculo.veiculo?.descri_mar) &&
   ((form?.values.procuradorVeiculo.veiculo?.modelo?.marca?.descri_mar || form?.values.procuradorVeiculo.veiculo?.descri_mar) + '/' + form?.values.procuradorVeiculo.veiculo?.modelo?.descri_mod)
  return (
    <Modal
      header="Procurador"
      width="40"
      visible={visible}
      modal={true}
      onHide={hide}
      btnSalvar={edicaoHabilitada ? form.submitForm : undefined}
      btnExcluir={(!isNewDocument && edicaoHabilitada) ? deletarProcurador : undefined}
    >
      <div className="formgrid grid">
        {formNeg.values.veiculosConjunto?.length > 0 &&
        <div className="field col-12 relative">
          <SearchInput
            //AutoComplete
            field="placa_vei"
            label="Veículo"
            value={descricaoVeiculo}
            placeholder="Informe a placa do veículo"
            onChange={handleChangeVeiculo}
            onSelect={async (e) => await onSelectVeiculo(e)}
            className={classNames({ 'p-invalid': isFormFieldValid('procuradorVeiculo.veiculo.codvei_nve') }, 'inputfield w-full')}
            onBlur={() => form.setFieldTouched('procuradorVeiculo.veiculo.codvei_nve')}
            disabled={!edicaoHabilitada}
            // suggestions={sugestaoVeiculos}
            // completeMethod={sugerirVeiculos}
            itemTemplate={VeiculoTemplate}
            //RegisterModal
            selected={form?.values.procuradorVeiculo.veiculo?.veiculo}
            ComponenteCadastro={Veiculo}
            onSaveModal={onSaveModalVeiculo}
            onDeleteModal={() => onSelectVeiculo({})}
            modalHeader={form?.values.procuradorVeiculo.veiculo?.codigo_vei ? `Veículo - ${form?.values.procuradorVeiculo.veiculo?.codigo_vei}` : 'Cadastro de veículo'}
            // FiltroModal
            filtrotitle="Pesquisa de veículo"
            service={ServiceConjunto}
            model={getVeiculoDTO}
            primarykeyname="codvei_nve"
            columns={[
              { campo: 'codvei_nve', nome: 'Código' },
              { campo: 'placha', nome: 'Placa/Chassi', format: formatPlaca },
              { campo: 'veiculo.modelo.descri_mod', nome: 'Modelo' },
              { campo: 'veiculo.modelo.marca.descri_mar', nome: 'Marca' }
            ]}
          />
          <div className="label-over-veiculo">
            {form.values.procuradorVeiculo.veiculo?.placha || formatPlaca(form.values.procuradorVeiculo.veiculo?.placa_vei) || formatPlaca(form.values.procurador.placa_vei)}
          </div>
          <FieldErrorMessage message={getFormErrorMessage('procuradorVeiculo.codvei_nve')} />
        </div>
        }
        <div className="field col-12 relative">
          <SearchInput
            //AutoComplete
            field="nomraz_pes"
            label="Procurador"
            value={form?.values.procurador?.nomraz_pes && form?.values.procurador}
            placeholder="Pesquisa de procurador"
            disabled={!edicaoHabilitada}
            onSelect={async (e) => { await onSelectPessoa(e); form?.setFieldTouched('procurador.codigo_pes') }}
            onBlur={() => form?.setFieldTouched('procurador.codigo_pes')}
            suggestions={sugestaoDestinos}
            completeMethod={sugerirPessoa}
            onChange={handleChangePessoa}
            itemTemplate={PessoaAutocompleteTemplate}
            className={classNames({ 'p-invalid': isFormFieldValid('procurador') }, 'inputfield w-full')}
            isFormFieldValid={isFormFieldValid('procurador.codigo_pes')}
            //RegisterModal
            selected={form?.values.procurador.codigo_pes ? form?.values.procurador : {} }
            onSaveModal={onSaveModalDestino}
            onDeleteModal={async () => await onSelectPessoa({})}
            ComponenteCadastro={[
              {
                label: 'Pessoa física',
                component: PessoaFisica,
                header: form.values?.procurador?.codigo_pes ? 'Pessoa Física - ' + form.values?.procurador.codigo_pes : 'Cadastro de pessoa física'
              },
              {
                label: 'Pessoa jurídica',
                component: PessoaJuridica,
                header: form.values?.procurador?.codigo_pes ? 'Pessoa jurídica - ' + form.values?.procurador.codigo_pes : 'Cadastro de pessoa jurídica'
              }
            ]}
            //FiltroModal
            filtrotitle="Pesquisa de pessoa"
            service={PessoaService}
            model={PessoaModel}
            primarykeyname="codigo_pes"
            columns={[
              { campo: 'codigo_pes', nome: 'Código' },
              { campo: 'nomraz_pes', nome: 'Nome' },
              { campo: 'cgccpf_pes', nome: 'CPF/CNPJ', format: formatDocument }
            ]}
          />
          <div className="label-over-cadastro">
            {formatDocument(form.values.procurador?.cgccpf_pes)}
          </div>
          <FieldErrorMessage message={getFormErrorMessage('procurador.codigo_pes')} />
        </div>
      </div>
    </Modal>
  )
}

export default ProcuradorModal
