import React from 'react'
import Container from '../../../../components/layout/Container'
import { Button } from 'primereact/button'
import Page from '../../../../components/layout/Page'
import DataList from '../../../../components/layout/DataList'
import VeiculoService from '../../../../services/cadastro/veiculo/VeiculoService'
import List from '../../../../classes/List'
import { createColumns, getColunasHabilitadas } from '../../../../helpers/tableConfigs'
import TablePreferences from '../../../../components/utils/TablePreferences'
import PreferencesButton from '../../../../components/utils/PreferencesButton'
import CommonHelper from '../../../../helpers/CommonHelper'
import { classNames } from 'primereact/utils'
import { formatPlaca } from '../../../../helpers/formaters'
import { getVeiculoDTO } from '../../../../dtos/cadastro/veiculo/VeiculoDTO'

export class VeiculoList extends List {
  constructor (props) {
    super(props, getVeiculoDTO, VeiculoService, 'veiculo', 'codigo_vei')

    this.state = {
      ...this.state,
      visiblePreferencesModal: false
    }

    this.setColumns(createColumns([
      { field: 'codigo_vei', header: 'Código', width: '10%' },
      { field: 'placa_vei', header: 'Placa', width: '12%', customBody: this.getPlaca },
      { field: 'chassi_vei', header: 'Chassi', width: '20%' },
      { field: 'descri_mod', header: 'Modelo', width: '18%', customBody: this.getModelo },
      { field: 'renava_vei', header: 'Renavam', width: '20%' },
      { field: 'anofab_vei', header: 'Ano fabricação', width: '10%' },
      { field: 'anomod_vei', header: 'Ano modelo', width: '10%' }
    ]))
  }

  getPlaca (data) {
    return formatPlaca(data.placa_vei)
  }

  getModelo (data) {
    return data.modelo?.descri_mod
  }

  render () {
    const { rows, totalRecords, first } = this.state
    const columns = this.columns

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Veículos</h4>
          <div className="page-header-buttons">
            <Button
              className="mr-2"
              label={CommonHelper.isDesktop() && 'Novo'}
              icon="pi pi-plus"
              onClick={this.onNew}
            />
          </div>
        </div>
        <div className="table-options-container">
          <div className="tabel-options">
            <div
              className={classNames({ active: this.state.opcaoSelecionada === 't' }, 'filter-option')}
            >
              <span className="option-label">Todos</span>
              <div className="option-quantity">
                {this.state.totalRecords}
              </div>
            </div>
          </div>
          <div className="table-preferences">
            <PreferencesButton
              onClick={() => this.setState({ visiblePreferencesModal: true })}
            />
          </div>
        </div>
        <Container>
          <DataList
            data={this.state.list}
            selected={this.state.selected}
            rows={rows}
            onSelect={this.onSelect}
            totalRecords={totalRecords}
            first={first}
            onPage={this.onPageChange}
            page={this.state.page}
            onRowSelect={(e) => this.onView(e.data)}
            showFilter={true}
            filterPlaceholder="Procurar Veículos"
            filterName="descricaoFiltro"
            responsive={true}
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
          >
            {getColunasHabilitadas(columns, 'veiculo')}
          </DataList>
        </Container>
        <TablePreferences
          title="Preferências da tabela de veículo"
          tableName="veiculo"
          columns={columns}
          visible={this.state.visiblePreferencesModal}
          hide={() => this.setState({ visiblePreferencesModal: false })}
        />
      </Page>
    )
  }
}
