import ServiceBase from '../../base/ServiceBase'

export default class ClassificacaoFiscalService {
  static async getAll () {
    return await ServiceBase.getBase('/classificacaofiscal')
  }

  static async getSubstituicaoTributariaPisCofins ({ ncm_clf, codigo_clf, codigo_emp }) {
    return await ServiceBase.postBase('/classificacaofiscal', { ncm_clf, codigo_clf, codigo_emp })
  }
}
