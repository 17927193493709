import classNames from 'classnames'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import CommonHelper from '../../helpers/CommonHelper'
import { generateMenuSideBar, setEmpresaLogada, setInicialLoaded, setMenusAcesso, setUserAsAutenticated, setUserAsNotAutenticated, setUsuarioLogado } from '../../redux/actions'
import { dispatch, getStore } from '../../redux/store'
import EmpresasService from '../../services/cadastro/pessoa/EmpresasService'
import UsuarioService from '../../services/cadastro/pessoa/UsuarioService'
import NegociacaoService from '../../services/vendas/negociacao/NegociacaoService'
import OrcamentoBalcaoService from '../../services/vendas/orcamentoBalcao/OrcamentoBalcaoService'
import ErrorPage from '../error/ErrorPage'
import Loading from '../utils/Loading'
import { AppMenu } from './AppMenu'
import { AppProfile } from './AppProfile'
import { AppTopbar } from './AppTopbar'

class AppContent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      layoutMode: 'static',
      layoutColorMode: 'light',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      isMenuLoaded: false,
      menuSideBarP: [],
      restricaoOutraNegVend: false,
      restricaoOutraOrcVend: false
    }

    this.onWrapperClick = this.onWrapperClick.bind(this)
    this.onToggleMenu = this.onToggleMenu.bind(this)
    this.onSidebarClick = this.onSidebarClick.bind(this)
    this.onMenuItemClick = this.onMenuItemClick.bind(this)
  }

  async componentDidMount () {
    await this.loadInicialState()
      .then(() => {
        dispatch(setInicialLoaded())
        this.setState({ isMenuLoaded: true })
      })
    await this.getRestricaoVerNegOutroVendedor()
    await this.getRestricaoVerOrcOutroVendedor()
    this.onRefresh()
  }

  loadInicialState = async () => {
    await this.loadMenusAcesso()

    await this.loadEmpresaLogada()

    await this.loadUsuarioLogado()

    await this.loadMenu()

    setInterval(async () => {
      await this.loadMenu()
    }, 30000)
  }

  async loadMenusAcesso () {
    await UsuarioService
      .getMenusAcesso()
      .then(res => {
        dispatch(setMenusAcesso(res))
      })
      .catch(error => {
        //logout()
        dispatch(setUserAsNotAutenticated())
      })
  }

  async loadEmpresaLogada () {
    await EmpresasService
      .getEmpresaLogada()
      .then(res => {
        dispatch(setEmpresaLogada(res))
      })
      .catch(() => {
        //logout()
        dispatch(setUserAsNotAutenticated())
      })
  }

  async loadUsuarioLogado () {
    await UsuarioService
      .getUsuarioLogado()
      .then(res => {
        if (!getStore().isUserAutenticated) {
          dispatch(setUserAsAutenticated())
          dispatch(setUsuarioLogado(res))
        }
      })
      .catch(() => {
        //logout()
        dispatch(setUserAsNotAutenticated())
      })
  }

  async loadMenu () {
    //dispatch(await atualizarQuantidadeConsignacoesPendentePagamento())
    //dispatch(await atualizarQuantidadeNegociacoesPendenteAprovacao())
    dispatch(generateMenuSideBar())
    let menu = await this.props.menuSideBar
    this.setState({ menuSideBarP: menu })
  }

  componentDidUpdate () {
    if (this.state.mobileMenuActive)
      this.addClass(document.body, 'body-overflow-hidden')
    else
      this.removeClass(document.body, 'body-overflow-hidden')
  }

  addClass (element, className) {
    if (element.classList)
      element.classList.add(className)
    else
      element.className += ' ' + className
  }

  removeClass (element, className) {
    if (element.classList)
      element.classList.remove(className)
    else
      element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ')
  }

  async getRestricaoVerNegOutroVendedor () {
    try {
      const { path } = this.props.children.props.match
      const { pathname } = this.props.children.props.location
      const { id } = this.props.children.props.match.params
      let restricaoOutroVendedor = false
      if (path === '/negociacao/:id' || pathname === `/negociacao/${id}`) {
        restricaoOutroVendedor = await NegociacaoService.podeVerNegociacaoOutroVendedor(id)
      }
      this.setState({ restricaoOutraNegVend: restricaoOutroVendedor })
    } catch (error) {
      this.props.children.props.history.push('/negociosfechados')
    }
  }

  async getRestricaoVerOrcOutroVendedor () {
    try {
      const { path } = this.props.children.props.match
      const { pathname } = this.props.children.props.location
      const { id } = this.props.children.props.match.params

      let restricaoOutroVendedor = false
      if (path === '/orcamentoBalcao/:id' || pathname === `/orcamentoBalcao/${id}`) {
        restricaoOutroVendedor = await OrcamentoBalcaoService.getPodOrcOutroVendedor(id)
      }
      this.setState({ restricaoOutraOrcVend: restricaoOutroVendedor })
    } catch (error) {
      this.props.children.props.history.push('/orcamentosBalcao')
    }
  }

  hasMenuAcesso = () => {
    return this.props.options.ignorePermissaoAcesso || CommonHelper.searchRecursively(
      this.props.options.codigoMenu,
      this.state.menuSideBarP,
      'codigo_mew',
      'items'
    )
  }

  onWrapperClick () {
    if (!this.menuClick) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false
      })
    }

    this.menuClick = false
  }

  onToggleMenu (event) {
    this.menuClick = true

    if (CommonHelper.isDesktop()) {
      this.setState({ staticMenuInactive: !this.state.staticMenuInactive })
    } else {
      this.setState({ mobileMenuActive: !this.state.mobileMenuActive })
    }

    event.preventDefault()
  }

  onRefresh () {
    window.addEventListener('beforeunload', sessionStorage.removeItem('sideBar'))
  }

  onSidebarClick () {
    this.menuClick = true
  }

  onMenuItemClick (event) {
    if (event.item.to !== null) {
      this.setState({ restricaoOutraNegVend: false })
      this.setState({ restricaoOutraOrcVend: false })
    }
    if (!event.item.items) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false
      })
    }
  }

  render () {
    const wrapperClass = classNames('layout-wrapper', {
      'layout-overlay': this.state.layoutMode === 'overlay',
      'layout-static': this.state.layoutMode === 'static',
      'layout-static-sidebar-inactive': this.state.staticMenuInactive && this.state.layoutMode === 'static',
      'layout-overlay-sidebar-active': this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
      'layout-mobile-sidebar-active': this.state.mobileMenuActive
    })

    const sidebarClassName = classNames('layout-sidebar', {
      'layout-sidebar-dark': this.state.layoutColorMode === 'dark',
      'layout-sidebar-light': this.state.layoutColorMode === 'light'
    })

    return ((getStore().isUserAutenticated && this.state.isMenuLoaded && this.props.menuSideBar) ? (
      <div className={wrapperClass} onClick={this.onWrapperClick} >
        <AppTopbar onToggleMenu={this.onToggleMenu} />
        <div className={sidebarClassName} onClick={this.onSidebarClick}>
          <div className="layout-logo mt-3 ml-4">
            <object data='/assets/layout/images/LogoComTexto.svg'></object>
          </div>
          <AppProfile/>
          <AppMenu menuSideBar={this.state.menuSideBarP} onMenuItemClick={this.onMenuItemClick} />
        </div>
        <div className="layout-main">
          { this.state.restricaoOutraNegVend || this.state.restricaoOutraOrcVend
            ? <ErrorPage message={`Usuário possui restrição: ${this.state.restricaoOutraNegVend ? '12-Visualizar negociações de outros vendedores!' : '44 -Visualizar orçamentos de outros vendedores!'}`} hideBackButton />
            : this.hasMenuAcesso()
              ? this.props.children
              : <ErrorPage message="Usuário não possui acesso a esta janela!" hideBackButton />}
        </div>
        <div className="layout-mask"></div>
        {this.props.loading && (
          <Loading/>
        )}
      </div >
    ) : (
      <Loading/>
    ))
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    menuSideBar: state.menuSideBar,
    menusAcesso: state.menusAcesso
  }
}

export default connect(mapStateToProps)(AppContent)
