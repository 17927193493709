import ServiceBase from '../../base/ServiceBase'

export default class ClassificacaoItensManutencaoService {
  static async getAll () {
    return await ServiceBase.getBase('classificacaoItensManutencao/getAll')
  }

  static async getByCodigo (codigo) {
    return await ServiceBase.getBase(`classificacaoItensManutencao/getByCodigo/${codigo}`)
  }
}
