import { InputTextarea } from 'primereact/inputtextarea'
import { classNames } from 'primereact/utils'
import React, { useEffect } from 'react'
import DateInput from '../../../../../../components/inputs/DateInput'
import SelectInput from '../../../../../../components/inputs/SelectInput'
import TextInput from '../../../../../../components/inputs/TextInput'
import Modal from '../../../../../../components/utils/Modal'
import {
  formatCurrencyToNumber,
  formatDecimal,
  formatToCurrency
} from '../../../../../../helpers/formaters'
import { useValidateInput } from '../../../../../../helpers/useValidateInput'
import { FinanciamentoValidator } from './FinanciamentoValidator'
import FoPagNeg from '../_FoPagNeg'
import ResponsavelPagamento from '../../../../../../components/layout/ResponsavelPagamento/ResponsavelPagamento'
import FieldErrorMessage from '../../../../../../components/utils/FieldErrorMessage'
import RequiredLabel from '../../../../../../components/utils/RequiredLabel'

const FoPagFinanciamento = (props) => {
  const {
    form,
    edicaoHabilitada,
    calculateValorParcela,
    handleChangeCurrency,
    handleRetornoValores,
    handleTipoFinanciamento,
    getFinanceiras,
    getTiposRestricaoVeiculo,
    getPercentualILA,
    setFinanceira,
    setRestricao,
    setValorVeiculo,
    veiculo,
    hide,
    dados,
    excluir,
    isLojaPaga,
    setRetorno,
    isNew
  } = props

  const deveSelecionarVeiculo = props.negociacaoForm.values.veiculosConjunto.length > 0

  useEffect(() => {
    const onLoad = async () => {
      form.setFieldValue('veiculo', veiculo)

      const financeiraOptions = await getFinanceiras()
      setFinanceira(dados.codfin_fpn, financeiraOptions)

      const restricaoOptions = await getTiposRestricaoVeiculo()
      await setRestricao(dados.codtrv_fpn || 1, restricaoOptions)

      setRetorno(form.values.retorno, form.values.retornoOptions)

      if (form.values.ilaPercentagem === 0) {
        const percentualILAAnterior = await getPercentualILA()
        form.setFieldValue('ilaPercentagem', percentualILAAnterior)
      }

      if (isNew) {
        form.setFieldValue('isLojaPaga', isLojaPaga)
      }
    }

    onLoad()
  }, [])

  useEffect(() => {
    handleRetornoValores()
  }, [form.values.valor, form.values.ilaPercentagem, form.values.retorno])

  useEffect(() => {
    calculateValorParcela()
  }, [form.values.valor, form.values.quantidadeParcelas])

  const handleChangePrimeiroVencimento = async (e) => {
    await form.handleChange(e)
    await form.setFieldTouched('primeiroVencimento')
    await form.validateField('primeiroVencimento')
  }

  const handleSelectFinanceira = async (e) => {
    await form.handleChange(e)
    await form.setFieldTouched('financeira.codigo_fin')
  }

  const handleSelectRetorno = async (e) => {
    await form.setFieldValue('retorno', e.target.value)
    handleRetornoValores(e.target.value)
  }

  const { getFormErrorMessage, isFormFieldValid } = useValidateInput(form)

  return (
    <Modal
      header="Financiamento"
      btnSalvar={edicaoHabilitada ? form.submitForm : undefined}
      btnExcluir={(!isNew && edicaoHabilitada) ? excluir : undefined}
      width="65"
      visible
      onHide={hide}
    >
      <div className="formgrid grid">
        <div className="field col-12 flex justify-content-around mb-4">
          <ResponsavelPagamento isLojaPaga={isNew ? isLojaPaga : form.values.isLojaPaga} />
        </div>
        <div className={'field col-12 md:col-' + (deveSelecionarVeiculo ? '8' : '12')}>
          <RequiredLabel label="Financeira" valid={isFormFieldValid('financeira.codigo_fin')} />
          <SelectInput
            value={form.values.financeira}
            options={form.values.financeiraOptions}
            optionLabel="pessoa.nomraz_pes"
            placeholder="- Selecione -"
            name="financeira"
            noFloatLabel
            onChange={async (e) => {
              await handleSelectFinanceira(e)
              handleRetornoValores()
            }}
            disabled={!edicaoHabilitada}
            className={classNames({ 'p-invalid': isFormFieldValid('financeira.codigo_fin') }, 'inputfield w-full')}
            onHide={() => form.setFieldTouched('financeira.codigo_fin')}
          />
          <FieldErrorMessage message={getFormErrorMessage('financeira.codigo_fin')} />
        </div>
        {deveSelecionarVeiculo && (
          <div className="field col-12 md:col-4">
            <SelectInput
              value={form.values.veiculo}
              options={props.negociacaoForm.values.veiculosConjunto.map(veiculoNve => veiculoNve.veiculo)}
              onChange={(e) => form.setFieldValue('veiculo', e.target.value)}
              label="Veículo"
              optionLabel="placha"
              dataKey="codigo_vei"
              disabled={!edicaoHabilitada}
              className="inputfield w-full"
              noFloatLabel
            />
          </div>
        )}
        <div className="field col-6 md:col-4">
          <RequiredLabel label="Valor do financiamento" valid={isFormFieldValid('valor')} />
          <TextInput
            name="valor"
            value={formatToCurrency(form.values.valor)}
            disabled={!edicaoHabilitada}
            onChange={async (e) => {
              await handleChangeCurrency(e)
            }}
            onBlur={() => {
              setValorVeiculo()
              form.setFieldTouched('valor')
            }}
            className={classNames({ 'p-invalid': isFormFieldValid('valor') }, 'inputfield w-full')}
          />
          <FieldErrorMessage message={getFormErrorMessage('valor')} />
        </div>
        <div className="field col-6 md:col-4">
          <TextInput
            name="quantidadeParcelas"
            label="Quantidade de parcelas"
            value={form.values.quantidadeParcelas}
            placeholder="Quantidade de parcelas"
            onChange={(e) => {
              form.handleChange(e)
              calculateValorParcela()
            }}
            keyfilter="int"
            disabled={!edicaoHabilitada}
            className="inputfield w-full"
          />
        </div>
        <div className="field col-12 md:col-4">
          <RequiredLabel label="Data prevista de Pagamento" valid={isFormFieldValid('primeiroVencimento')} />
          <DateInput
            name="primeiroVencimento"
            disabled={!edicaoHabilitada}
            placeholder="Data prevista de Pagamento"
            value={form.values.primeiroVencimento}
            onChange={handleChangePrimeiroVencimento}
            className={classNames({ 'p-invalid': isFormFieldValid('primeiroVencimento') }, 'inputfield w-full')}
            onBlur={() => form.setFieldTouched('primeiroVencimento')}
          />
          <FieldErrorMessage message={getFormErrorMessage('primeiroVencimento')} />
        </div>
        <div className="field col-12 md:col-4">
          <SelectInput
            value={form.values.retorno || form.values.retornoOptions[0]}
            options={form.values.retornoOptions}
            optionLabel="nome"
            disabled={!edicaoHabilitada}
            onChange={(e) => handleSelectRetorno(e)}
            placeholder="- Selecione -"
            name="retorno"
            label="Retorno"
            noFloatLabel
            className="inputfield w-full"
          />
        </div>
        <div className="field col-12 md:col-8">
          <SelectInput
            value={form.values.restricao}
            options={form.values.restricaoOptions}
            optionLabel="descri_trv"
            onChange={handleTipoFinanciamento}
            placeholder="- Selecione -"
            name="restricao"
            label="Restrição"
            noFloatLabel
            disabled={!edicaoHabilitada}
            className="inputfield w-full"
          />
        </div>
        {form.values.retorno?.valor !== form.values.retornoOptions[0]?.valor && (
          <>
            <div className="field col-6 md:col-4">
              <TextInput
                name="valorRetorno"
                label="Valor do retorno"
                value={formatToCurrency(form.values.valorRetorno)}
                disabled
                className="inputfield w-full"
              />
            </div>
            <div className="field col-6 md:col-4">
              <TextInput
                name="ilaPercentagem"
                label="ILA (%)"
                value={formatDecimal(form.values.ilaPercentagem)}
                onChange={async (e) => {
                  if (
                    parseFloat(formatCurrencyToNumber(e.target.value)) < 100
                  ) {
                    await handleChangeCurrency(e)
                  }
                }}
                disabled={!edicaoHabilitada}
                className="inputfield w-full"
              />
            </div>
            <div className="field col-12 md:col-4">
              <TextInput
                name="valorRetornoLiquido"
                label="Valor do retorno líquido"
                value={formatToCurrency(form.values.valorRetornoLiquido)}
                disabled
                className="inputfield w-full"
              />
            </div>
          </>
        )}
        <div className="field col-12">
          <label className="label">Observação</label>
          <InputTextarea
            name="observacoes"
            value={form.values.observacoes}
            disabled={!edicaoHabilitada}
            onChange={form.handleChange}
            rows={3}
            autoResize
            className="inputfield w-full"
          />
          <label className="observacao">Será impresso no contrato</label>
        </div>
      </div>
    </Modal>
  )
}

export default (props) =>
  FoPagNeg({
    Component: FoPagFinanciamento,
    validationSchema: FinanciamentoValidator,
    ...props
  })
