import BaseModel from '../../BaseModel'

export class ModeloDeParaModel extends BaseModel {
  constructor (params = {}) {
    super()
    this.codigo_mdd = params.codigo_mdd || null
    this.codmodtur_mdd = params.codmodtur_mdd || null
    this.codmodaug_mdd = params.codmodaug_mdd || null
  }

  toMap () {
    return {
      codigo_mdd: this.codigo_mdd,
      codmodtur_mdd: this.codmodtur_mdd || null,
      codmodaug_mdd: this.codmodaug_mdd || null
    }
  }
}
