import React from 'react'

const FieldErrorMessage = ({ message }) => {
  return (
    <>
      {message && (
        <small className="p-error pl-2">{message}</small>
      )}
    </>
  )
}

export default FieldErrorMessage
