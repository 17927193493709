import ServiceBase from '../../base/ServiceBase'

export default class MarcaAutoGestorService {
  static async getAllMarcasAG () {
    return await ServiceBase.getBase('marcas/autogestor/all')
  }

  static async filter () {
    return await ServiceBase.getBase(`marcas/autogestor/lista?descri_mar=${query}`)
  }
}
