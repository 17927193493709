import React, { useEffect, useState } from 'react'
import TextAreaInput from '../../../../components/inputs/TextAreaInput'
import { showErrorMessage, showSuccessMessage, showWarnMessage } from '../../../../components/utils/Message'
import Modal from '../../../../components/utils/Modal'
import { formatPlacha } from '../../../../helpers/formaters'
import PermissaoService from '../../../../services/cadastro/pessoa/PermissaoService'
import VeiculoService from '../../../../services/cadastro/veiculo/VeiculoService'

function BloquearVeiculoModal ({ visible, hide, motivo, descricaoVeiculo, veiculo, onFilter, list, setList }) {
  const [motivoBloqueio, setMotivoBloqueio] = useState(motivo)
  const [edicaoHabilitada, setEdicaoHabilitada] = useState(!motivo)
  const [permissaoParaDesbloquear, setPermissaoParaDesbloquear] = useState(false)

  useEffect(async () => {
    const permissaoParaDesbloquear = await PermissaoService.getByCodigo('29')

    setPermissaoParaDesbloquear(permissaoParaDesbloquear)

    setMotivoBloqueio(motivo)

    if (motivo) {
      setEdicaoHabilitada(false)
    } else {
      setEdicaoHabilitada(true)
    }
  }, [motivo])

  function onHide () {
    setMotivoBloqueio('')
    hide()
  }

  async function bloquearVeiculo () {
    if (!motivoBloqueio) {
      showWarnMessage('Por favor informe um motivo antes de bloquear!')
      return
    }

    try {
      await VeiculoService.bloquearVeiculo(veiculo.codigo_vei, motivoBloqueio)

      const lista = list.map(row => {
        if (row.codigo_vei === Number(veiculo.codigo_vei)) {
          return { ...row, codigo_vdi: 'D', descri_vdi: 'Bloqueado' }
        } else {
          return row
        }
      })

      setList(lista)

      await onFilter()
      onHide()
      showSuccessMessage('Veículo bloqueado com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar bloquear o veículo')
    }
  }

  async function desbloquearVeiculo () {
    try {
      const permissaoParaDesbloquear = await PermissaoService.getByCodigo('29')

      if (!permissaoParaDesbloquear) {
        showWarnMessage(`Usuário logado não possui permissão para desbloquear o veículo: ${formatPlacha(veiculo.placa_vei, veiculo.chassi_vei)}!`)
        return
      }

      await VeiculoService.desbloquearVeiculo(veiculo.codigo_vei)

      const lista = list.map(row => {
        if (row.codigo_vei === Number(veiculo.codigo_vei)) {
          return { ...row, codigo_vdi: 'D', descri_vdi: 'Disponível para venda' }
        } else {
          return row
        }
      })

      setList(lista)

      await onFilter()
      onHide()
      showSuccessMessage('Desbloqueio efetuado com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar desbloquear o veículo')
    }
  }

  return (
    <Modal
      header="Bloqueio de veículo"
      btnSalvarLabel="Bloquear"
      btnSalvar={edicaoHabilitada ? bloquearVeiculo : undefined}
      btnExcluirLabel="Desbloquear"
      btnExcluir={(!edicaoHabilitada && permissaoParaDesbloquear) ? desbloquearVeiculo : undefined}
      width="35"
      visible={visible}
      onHide={onHide}
    >
      <div className="formgrid grid">
        <div className="field col-12">
          <label className="label">Veículo</label>
          <TextAreaInput
            value={descricaoVeiculo}
            className="inputfield w-full"
            disabled={true}
          />
        </div>
        <div className="field col-12">
          <TextAreaInput
            label="Motivo"
            value={motivoBloqueio}
            onChange={(event) => setMotivoBloqueio(event.target.value)}
            className="inputfield w-full"
            disabled={!edicaoHabilitada}
          />
        </div>
      </div>
    </Modal>
  )
}

export default BloquearVeiculoModal
