import * as Yup from 'yup'

let nve = Yup.object().shape({
  codvei_nve: Yup.number()
    .nullable()
    .required('Informe um veículo válido!'),
  valbru_nve: Yup.number()
    .nullable()
    .test('testeValorBruto', 'Valor bruto não pode ser menor que o valor de oferta', function (value) {
      if (this.parent.venda_nve) {
        const precoDeOferta = this.parent.veiculo?.produto?.produtoEmpresas?.preco_pre
        const precoPromocional = this.parent.veiculo?.produto?.produtoEmpresas?.prepro_pre

        const diferenca = value - (precoPromocional || precoDeOferta)

        if (diferenca >= 0) {
          return true
        }
      } else {
        return true
      }

      return false
    }),
  proprietario: Yup.object().nullable().required('Informe um proprietario válido!'),
  valdes_nve: Yup.number()
    .nullable()
    .test('testeDesconto', 'Desconto não pode ser maior que o valor bruto', function (value) {
      if (this.parent.venda_nve) {
        const diferenca = this.parent.valbru_nve - value

        if (diferenca >= 0) {
          return true
        }
      } else {
        return true
      }

      return false
    }),
  valor_nve: Yup.number()
    .nullable()
    .min(0.001, 'O valor não foi informado!')
})

export const NegociacaoValidator = Yup.object().shape({
  pessoa: Yup.object().shape({
    codigo_pes: Yup.number()
      .nullable()
      .required('Informe um cliente válido!')
  }),
  vendedor: Yup.object().shape({
    codigo_pes: Yup.number()
      .nullable()
      .required('Informe um vendedor válido!')
  }),
  tipomidia: Yup.object().shape({
    codigo_tim: Yup.number()
      .nullable()
      .test('testeObrigarMidia', 'Informe o tipo de mídia!', function (value) {
        if (this.parent.obrigarTipoMidia && !value) {
          return false
        }

        return true
      })
  }),

  conjuntoVeiculo: Yup.array().of(nve),
  negociacaoVeiculo: nve
})
