import React, { useEffect, useRef, useState } from 'react'
import AES from '../../helpers/AES'
import CommonHelper from '../../helpers/CommonHelper'
import { formatToCurrency } from '../../helpers/formaters'
import { Carousel } from 'primereact/carousel'
import { Galleria } from 'primereact/galleria'
import { Image } from 'primereact/image'
import LandingVeiculoService from '../../services/cadastro/veiculo/LandingVeiculoService'
import ShareModal from './ShareModal'
import './_landingVeiculo.scss'
import ImagemService from '../../services/imagem/ImagemService'
import { showErrorMessage } from '../../components/utils/Message'

const LandingVeiculo = (props) => {
  const [loading, setLoading] = useState(true)
  const [veiculo, setVeiculo] = useState({})
  const [empresa, setEmpresa] = useState({})
  const [imagensVeiculo, setImagensVeiculo] = useState([])
  const [logoEmpresa, setLogoEmpresa] = useState({})
  const [activeIndex, setActiveIndex] = useState(0)
  const [visibleShareModal, setVisibleShareModal] = useState(false)
  const [siteEmpresa, setSiteEmpresa] = useState(null)

  const galleria = useRef(null)

  useEffect(async () => {
    try {
      const decryptedDatabase = AES.decryptWithAES(CommonHelper.formatURLtoString(props.match.params.database))
      const veiculo = await LandingVeiculoService.getLandingVeiculo(props.match.params.veiculoId, decryptedDatabase)
      const empresa = await LandingVeiculoService.getDadosEmpresa(props.match.params.empresaId, decryptedDatabase)
      const imagensVeiculo = await ImagemService.getImages('codigo_vei', props.match.params.veiculoId, decryptedDatabase)
      const logoEmpresa = await ImagemService.getImages('codigo_pej', props.match.params.empresaId, decryptedDatabase)

      if (empresa.urlsit_pej.includes('http')) {
        setSiteEmpresa(empresa.urlsit_pej)
      } else {
        setSiteEmpresa(`http://${empresa.urlsit_pej}`)
      }

      setImagensVeiculo(imagensVeiculo)
      setLogoEmpresa(logoEmpresa[0])
      setVeiculo(veiculo)
      setEmpresa(empresa)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar dos dados do veículo!')
    }
  }, [])

  useEffect(() => {
    if (veiculo.codigo_vei) {
      setLoading(false)
    }
  }, [veiculo])

  const imagemTemplate = (imagem) => {
    return (
      <div key={imagem.codigo_vim} className="small-img-container" onClick={() => setActiveIndex(imagensVeiculo.indexOf(imagem))}>
        <img className='img' src={imagem.url} alt={getDescriVei()} id={imagem?.codigo_img} />
      </div>
    )
  }

  const galeriaTemplate = (imagem) => {
    return (
      <Image className='imagemGaleria' src={imagem?.url} alt={getDescriVei()} id={imagem?.codigo_img} preview />
    )
  }

  const getDescriVei = () => {
    return veiculo.descri_mar.concat(' - ', veiculo.descri_mod)
  }

  return (
    <>
      {loading && (
        <div id="root">
          <div className="splash-screen">
            <div className="splash-container">
              <div className="splash-double-bounce1"></div>
              <div className="splash-double-bounce2"></div>
            </div>
          </div>
        </div>
      )}
      {!loading && (
        <div className="landing-veiculo-container">
          <div className="landing-veiculo-header">
            {logoEmpresa?.url && (
              <img className="logo-empresa" src={logoEmpresa.url} />
            )}
            <h1 className="header-text">{empresa.apelid_pes}</h1>
          </div>
          <div className="veiculo-header">
            <h4>{veiculo.descri_mar}/{veiculo.descri_mod}</h4>
            {veiculo.precoAnterior !== veiculo.preco_pre && (
              <h3>DE: {formatToCurrency(veiculo.precoAnterior)}</h3>
            )}
            <h3>{veiculo.precoAnterior !== veiculo.preco_pre ? 'POR: ' : ''}{formatToCurrency(veiculo.preco_pre)}</h3>
          </div>
          <div className="veiculo-info">
            <div className="veiculo-info-column">
              <div className="veiculo-info-row">
                <span>Marca/Modelo:</span> {veiculo.descri_mar}/{veiculo.descri_mod}
              </div>
              <div className="veiculo-info-row">
                <span>Ano:</span> {veiculo.anofab_vei}/{veiculo.anomod_vei}
              </div>
              <div className="veiculo-info-row">
                <span>Km:</span> {veiculo.km_vei.toLocaleString('pt-BR')}
              </div>
              {veiculo.descri_cam && (
                <div className="veiculo-info-row">
                  <span>Câmbio:</span> {veiculo.descri_cam}
                </div>
              )}
            </div>
            <div className="veiculo-info-column">
              {veiculo.descri_cor && (
                <div className="veiculo-info-row">
                  <span>Cor:</span> {veiculo.descri_cor}
                </div>
              )}
              {veiculo.descri_com && (
                <div className="veiculo-info-row">
                  <span>Combustível:</span> {veiculo.descri_com}
                </div>
              )}
              {veiculo.placa_vei && (
                <div className="veiculo-info-row">
                  <span>Final da placa:</span> {String(veiculo.placa_vei).charAt(6)}
                </div>
              )}
            </div>
          </div>
          {imagensVeiculo.length > 0 && (
            <div className="images-background">
              <div className="img-container">
                {imagensVeiculo.length === 1 && (
                  <img className="img mb-6" src={imagensVeiculo[0].url} alt="" />
                )}
                {imagensVeiculo.length > 1 && (
                  <>
                    <Galleria
                      ref={galleria}
                      value={imagensVeiculo}
                      numVisible={1}
                      circular
                      fullScreen
                      showItemNavigators
                      showThumbnails={false}
                      item={galeriaTemplate}
                      activeIndex={activeIndex}
                      onItemChange={(e) => { setActiveIndex(e.index) }}
                    />

                    <Galleria
                      value={imagensVeiculo}
                      numVisible={1}
                      circular
                      showItemNavigators
                      showThumbnails={false}
                      item={galeriaTemplate}
                      activeIndex={activeIndex}
                      onItemChange={(e) => { setActiveIndex(e.index) }}
                    />
                  </>

                )}
              </div>
              {imagensVeiculo.length > 1 && imagensVeiculo.length < 4 && CommonHelper.isDesktop() && (
                <div className="img-row">
                  {imagensVeiculo.map((imagem, idx) => {
                    return (
                      <div key={imagem.codigo_vim} className="small-img-container" id={imagem.codigo_vim}
                        onClick={() => { setActiveIndex(idx) }}>
                        <img className={'img ' + (idx === 0 ? '' : 'ml-4')} src={imagem.url} alt={getDescriVei()} />
                      </div>
                    )
                  })}
                </div>
              )}
              {(imagensVeiculo.length > 3 && CommonHelper.isDesktop()) && (
                <Carousel
                  value={imagensVeiculo}
                  numVisible={3}
                  numScroll={1}
                  className="img-row"
                  itemTemplate={imagemTemplate}
                />
              )}
            </div>
          )}
          {veiculo.descri_ace && (
            <div className="veiculo-items">
              <div className="veiculo-items-header">Ítens do veículo</div>
              <div className="veiculo-items-list">
                {veiculo.descri_ace?.split(', ').map((acessorio, idx) => (
                  <div key={idx} className="veiculo-items-row">{acessorio}</div>
                ))}
              </div>
            </div>
          )}
          <div className="share-button-container">
            <button onClick={() => setVisibleShareModal(true)}><i className="pi pi-share-alt"></i> Compartilhar</button>
          </div>
          <div className="landing-veiculo-footer flex justify-content-start">
            <img className="logo-sances" src="https://sancesturboweb.s3.sa-east-1.amazonaws.com/logoemp.jpg" />
            {siteEmpresa && (
              <div className="footer-text-container">
                <h3 className="footer-text" onClick={() => window.open(siteEmpresa)}>{siteEmpresa.split('//')[1]}</h3>
              </div>
            )}
          </div>
        </div>
      )}
      <ShareModal
        visible={visibleShareModal}
        hide={() => setVisibleShareModal(false)}
        database={props.match.params.database}
        empresa={empresa}
        veiculo={veiculo}
      />
    </>
  )
}

export default LandingVeiculo
