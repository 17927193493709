import { AiOutlineCar, AiOutlineHome } from 'react-icons/ai'
import { BsInboxes } from 'react-icons/bs'
import { GiGears } from 'react-icons/gi'
import { HiOutlineCog } from 'react-icons/hi'
import { VscCircleFilled } from 'react-icons/vsc'
import CommonHelper from '../../helpers/CommonHelper'
import MenuWebService from '../../services/cadastro/pessoa/MenuWebService'
let menuSideBar

export default async function createMenuSideBar (store) {
  function hasAccessMenu (codigoMenuWeb) {
    return CommonHelper.searchInList(codigoMenuWeb, store.menusAcesso, 'codigo_mew') ? true : false
  }

  function getReactIcon (iconName) {
    switch (iconName) {
    case 'AiOutlineCar':
      return AiOutlineCar
    case 'BsInboxes':
      return BsInboxes
    case 'GiGears':
      return GiGears
    case 'HiOutlineCog':
      return HiOutlineCog
    case 'VscCircleFilled':
      return VscCircleFilled
    default:
      return ''
    }
  }
  function getVariavel (variavel) {
    switch (variavel) {
    case 'store.quantidadeConsignacoesPendentePagamento':
      return store.quantidadeConsignacoesPendentePagamento
    case 'store.quantidadeNegociacoesPendenteAprovacao':
      return store.quantidadeNegociacoesPendenteAprovacao
    default:
      return ''
    }
  }

  async function recursivaMenuSubMenu (items, pai = null, isSubmenu) {
    const filtroItems = items.filter(item => item.nivsup_mew === pai)
    if (filtroItems.length === 0) return null
    const menu = await Promise.all(filtroItems.map(async item => {
      const submenu = await recursivaMenuSubMenu(items, item.codigo_mew, true)
      const menuItem = {
        codigo_mew: item.codigo_mew,
        nivsup_mew: item.nivsup_mew,
        label: item.label_mew,
        icon: item.icone_mew,
        reactIcon: getReactIcon(item.reico_mew),
        hasMenuAcesso: hasAccessMenu(item.codigo_mew),
        badge: getVariavel(item.badge_mew),
        to: item.sidbar_mew,
        items: submenu,
        subItem: item.issme_mew
      }
      return menuItem
    }))

    return menu
  }

  try {
    const menuWebLocalStorage = JSON.parse(sessionStorage.getItem('sideBar'))
    if (menuWebLocalStorage) {
      menuSideBar = await recursivaMenuSubMenu(menuWebLocalStorage)
      menuSideBar.unshift({
        label: 'Início',
        hasMenuAcesso: true,
        icon: 'pi pi-fw',
        reactIcon: AiOutlineHome,
        to: '/home'
      })
      return menuSideBar
    }
    const menuWeb = await MenuWebService.getMenuWeb()
    menuSideBar = await recursivaMenuSubMenu(menuWeb)
    menuSideBar.unshift({
      label: 'Início',
      hasMenuAcesso: true,
      icon: 'pi pi-fw',
      reactIcon: AiOutlineHome,
      to: '/home'
    })
    sessionStorage.setItem('sideBar', JSON.stringify(menuWeb))

    return menuSideBar
  } catch (error) {
    console.error('Erro ao criar o menu lateral:', error)
    return []
  }
}
