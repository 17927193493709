import { postAvaliacaoDTO } from '../../../dtos/vendas/negociacao/avaliacao/AvaliacaoDTO'
import ServiceBase from '../../base/ServiceBase'

export default class AvaliacaoService {
  async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`/avaliacoes?page=${page}&perPage=${perPage}`)
  }

  async get (id) {
    return await ServiceBase.getBase(`/avaliacoes/${id}`)
  }

  async save (data) {
    return await ServiceBase.postBase('/avaliacoes', postAvaliacaoDTO(data))
  }

  async delete (codigo_ava) {
    return await ServiceBase.deleteBase(`/avaliacoes/${codigo_ava}`)
  }

  static async get (id) {
    return await ServiceBase.getBase(`/avaliacoes/${id}`)
  }

  static async filter (query) {
    return await ServiceBase.getBase(`/avaliacoes?${query}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`/avaliacoes?${query}&page=${page}&perPage=${perPage}`)
  }

  static async getAvaliacaoCompletaPorPlaca (placa_ava) {
    return await ServiceBase.getBase(`/avaliacoes/placa/${placa_ava}`)
  }

  static async getAvaliacaoCompletaCpfCnpj (cgccpf_ava) {
    return await ServiceBase.getBase(`/avaliacoes/cpfcnpj/${cgccpf_ava}`)
  }
}
