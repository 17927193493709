import * as Yup from 'yup'
import { isValidCnpj, isValidCpf } from '../../../../../../helpers/checkers'
import { clearDigitsSpecialChars } from '../../../../../../helpers/parsers'

export const PromissoriaValidator = Yup.object().shape({
  veiculo: Yup.object().shape({
    codigo_vei: Yup.number()
      .nullable()
      .required('O veículo não foi selecionado!')
  }),

  dataEmissao: Yup.date()
    .nullable()
    .required('A data da operação não foi informada!'),
  valor: Yup.number()
    .nullable()
    .required('O valor não foi informado!')
    .min(0.001, 'O valor não foi informado!'),
  nomeCedente: Yup.string()
    .nullable()
    .required('Informe o nome do credor (devedor)!'),
  cpfCnpjCedente: Yup.string()
    .nullable()
    .required('Informe o CPF ou CNPJ do cedente!')
    .test('testCpfCnpj', 'CPF/CNPJ inválido!', (value) => {
      if (!value) {
        return false
      }

      const cpfCnpj = clearDigitsSpecialChars(value)

      if (cpfCnpj.length === 11) {
        return isValidCpf(cpfCnpj)
      } else if (cpfCnpj.length === 14) {
        return isValidCnpj(cpfCnpj)
      }

      return false
    })
})
