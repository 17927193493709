import BaseModel from '../../BaseModel'

export class PessoaModel extends BaseModel {
  constructor (params = {}) {
    super()
    this.codigo_pes = params.codigo_pes || null
    this.nomraz_pes = params.nomraz_pes || null
    this.apelid_pes = params.apelid_pes || null
    this.end_pes = params.end_pes || null
    this.bairro_pes = params.bairro_pes || null
    this.cidade_pes = params.cidade_pes || null
    this.uf_pes = params.uf_pes || null
    this.pais_pes = params.pais_pes || null
    this.cep_pes = params.cep_pes || null
    this.email_pes = params.email_pes || null
    this.emafin_pes = params.emafin_pes || null
    this.numend_pes = params.numend_pes || null
    this.codemp_pes = params.codemp_pes || null
    this.cgccpf_pes = params.cgccpf_pes || null
    this.ierg_pes = params.ierg_pes || null
    this.ie_pes = params.ie_pes || null
    this.rg_pes = params.rg_pes || null
    this.telcel_pes = params.telcel_pes || null
    this.telcom_pes = params.telcom_pes || null
    this.telres_pes = params.telres_pes || null
    this.insmun_pes = params.insmun_pes || null
    this.comend_pes = params.comend_pes || null
    this.codmun_pes = params.codmun_pes || null
    this.codpai_pes = params.codpai_pes || null
    this.cliata_pes = params.cliata_pes || false
    this.parcei_pes = params.parcei_pes || false
    this.usuins_pes = params.usuins_pes || null
    this.datins_pes = params.datins_pes || new Date()
    this.dahalt_pes = params.dahalt_pes || null
    this.refban_pes = params.refban_pes || null
    this.numcom_pes = params.numcom_pes || null
    this.cepcom_pes = params.cepcom_pes || null
    this.endcom_pes = params.endcom_pes || null
    this.comcom_pes = params.comcom_pes || null
    this.muncom_pes = params.muncom_pes || null
    this.barcom_pes = params.barcom_pes || null
    this.numcob_pes = params.numcob_pes || null
    this.cepcob_pes = params.cepcob_pes || null
    this.endcob_pes = params.endcob_pes || null
    this.comcob_pes = params.comcob_pes || null
    this.muncob_pes = params.muncob_pes || null
    this.barcob_pes = params.barcob_pes || null
    this.codmov_pes = params.codmov_pes || null
    this.municipioComercial = params.municipioComercial || {}
    this.municipioCobranca = params.municipioCobranca || {}
    this.pais = params.pais || {}
    this.estado = params.estado || {}
    this.municipio = params.municipio || {}
    this.empresa = params.empresa || {}
    this.usuarioInsercao = params.usuarioInsercao || {}
    this.usuarioAlteracao = params.usuarioAlteracao || {}
    this.modalidadeVenda = params.modalidadeVenda || {}
    this.limcre_pes = params.limcre_pes || null
    this.idrad_pes = params.idrad_pes || null
    this.telsac_pes = params.telsac_pes || null
    this.classificacao = params.classificacao || {}
    this.grupo = params.grupo || {}
    this.categoria = params.categoria || {}
    this.tabelaDesconto = params.tabelaDesconto || {}
    this.autorizacaoContatos = params.autorizacaoContatos || {}
  }
}
