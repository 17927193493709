import React, { Component } from 'react'
import DataList from '../../layout/DataList'

class ReportDatatable extends Component {
  constructor (props) {
    super(props)
  }

  render () {
    const props = this.props

    return (
      <>
        <DataList
          className="report-datatable"
          header={props.header}
          hidePaginator
          data={props.data}
          sortField={props.sortField}
          sortOrder={props.sortOrder}
          onSort={(e) => props.onSort(e)}
        >
          {props.columns}
        </DataList>
      </>
    )
  }
}

export default ReportDatatable
