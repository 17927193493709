import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import TextAreaInput from '../../components/inputs/TextAreaInput'
import MensagemService from '../../services/cadastro/mensagem/MensagemService'
import SelectInput from '../inputs/SelectInput'
import { showWarnMessage } from './Message'
import Modal from './Modal'

function EnviarWhatsAppModal ({ visible, onHide, onConfirm }) {
  const [mensagens, setMensagens] = useState([])
  const [descricaoMensagemSelecionada, setDescricaoMensagemSelecionada] = useState(null)
  const [mensagem, setMensagem] = useState('')

  useEffect(async () => {
    if (visible === true) {
      await getMensagens()
    }
  }, [visible])

  async function getMensagens () {
    const mensagens = await MensagemService.getAll()
    setMensagens(mensagens)
  }

  async function indicarMensagemSelecionada (mensagemSelecionada) {
    setDescricaoMensagemSelecionada(mensagemSelecionada)
    setMensagem(mensagemSelecionada.mensag_msg)
  }

  async function handleConfirm () {
    if ((!descricaoMensagemSelecionada) || (!mensagem)) {
      showWarnMessage('Por favor selecione uma mensagem!')
      return
    }

    await onConfirm(mensagem)

    onHide()
  }

  return (
    <Modal
      visible={visible}
      onHide={onHide}
      header="Enviar WhatsApp"
      width={40}
      footer={
        <Button
          label="Confirmar"
          onClick={async () => await handleConfirm()}
        />
      }
    >
      <div className="formgrid grid fluid">
        <div className="field col-12">
          <SelectInput
            label="Descrição"
            dataKey="codigo_msg"
            optionLabel="descri_msg"
            value={descricaoMensagemSelecionada}
            options={mensagens}
            onChange={(e) => indicarMensagemSelecionada(e.target.value)}
          />
        </div>
        <div className="field col-12">
          <TextAreaInput
            label="Mensagem"
            name="mensag_msg"
            className="inputfield w-full"
            cols={1000}
            rows={4}
            value={mensagem}
            onChange={(e) => setMensagem(e.target.value)}
          />
        </div>
      </div>
    </Modal>
  )
}

export default EnviarWhatsAppModal
