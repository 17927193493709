import ServiceBase from '../../../base/ServiceBase'

export default class PixGeradoService {
  static async gerarPix (dadosPix) {
    return await ServiceBase.postBase('financeiro/integracao/gerarPIX', dadosPix)
  }

  static async cancelarPix (idPix) {
    return await ServiceBase.deleteBase(`pix-gerado/${idPix}`)
  }

  static async listarPix (emp, nve) {
    return await ServiceBase.getBase(`financeiro/integracao/getDadosPixByNve/${emp}/${nve}`)
  }
}
