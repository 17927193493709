import moment from 'moment'
import * as Yup from 'yup'

export const FinanciamentoDiretoValidator = Yup.object().shape({
  veiculo: Yup.object().shape({
    codigo_vei: Yup.number()
      .required('O veículo não foi selecionado!')
  }),

  dataEmissao: Yup.date()
    .nullable()
    .required('A data da operação não foi informada!'),
  valor: Yup.number()
    .required('O valor não foi informado!')
    .min(0.001, 'O valor não foi informado!'),
  quantidadeParcelas: Yup.number()
    .required('A quantidade de parcelas não foi informada!'),
  valorParcela: Yup.number()
    .required('O valor das parcelas não foi informado!'),
  valorParcela: Yup.number()
    .test('testSomaParcelas', 'A soma das parcelas é diferente do valor do financiamento', function (value) {
      if ((this.parent.quantidadeParcelas) || (this.parent.valorParcela)) {
        const resultado = (this.parent.quantidadeParcelas * this.parent.valorParcela).toFixed(2) - this.parent.valor

        if (resultado !== 0) {
          return false
        }

        return true
      } else {
        return true
      }
    }),
  primeiroVencimento: Yup.date()
    .nullable()
    .required('O primeiro vencimento não foi informado!')
    .test('testeDataCompensacao', 'O primeiro vencimento não pode ser anterior a data de emissão!', function (value) {
      const diferenca = moment(value).diff(this.parent.dataEmissao, 'days')

      if (diferenca >= 0) {
        return true
      }

      return false
    }),
  formaCobranca: Yup.object().shape({
    codigo_foc: Yup.number()
      .nullable()
      .required('Informe a forma de cobrança!')
  }),
  restricao: Yup.object().shape({
    codigo_trv: Yup.number()
      .nullable()
      .required('Informe a restrição!')
  })
})
