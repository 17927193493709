import React, { useEffect, useState } from 'react'
import AppRadioButton from '../../../components/inputs/AppRadioButton'
import TextInput from '../../../components/inputs/TextInput'
import { baseForm } from '../../../components/utils/BaseForm'
import Modal from '../../../components/utils/Modal'
import { getItemManutencaoDTO, postItemManutencaoDTO } from '../../../dtos/cadastro/manutencao/ItemManutencaoDTO'
import { formatCurrencyToNumber, formatToCurrency } from '../../../helpers/formaters'
import ItensManutencaoService from '../../../services/cadastro/manutencao/ItensManutencaoService'
import { transformAll } from '@overgear/yup-ast'
import ClassificacaoItensManutencaoService from '../../../services/cadastro/manutencao/ClassificacaoItensManutencaoService'
import CommonHelper from '../../../helpers/CommonHelper'
import SelectInput from '../../../components/inputs/SelectInput'
import { showErrorMessage } from '../../../components/utils/Message'

function ItemManutencaoModal ({ visible, onHide, manutencaoForm, itemManutencaoSelecionado, updateValorTotalItensManutencao, edicaoHabilitada }) {
  const classificacaoPeca = 1
  const classificacaoImpostoTaxa = 3

  const [formValidator, setFormValidator] = useState({})
  const [camposObrigatorios, setCamposObrigatorios] = useState([])
  const [classificacoesItens, setClassificacoesItens] = useState(null)

  async function hideModal () {
    await setClassificacaoManutencao()
    updateValorTotalItensManutencao()
    onHide()
  }

  async function setClassificacaoManutencao () {
    const itensManutencao = manutencaoForm.values.itensManutencao
    if (itensManutencao.length > 0 && itensManutencao[0].codcim_itm === classificacaoImpostoTaxa) {
      await manutencaoForm.setFieldValue('codclm_man', 2)
    } else {
      await manutencaoForm.setFieldValue('codclm_man', 1)
    }
  }

  async function onSubmit () {
    try {
      validarClassificacao()
      insertOrUpdateItemManutencao()
      await hideModal()
    } catch (error) {
      showErrorMessage(error.message)
    }
  }

  function validarClassificacao () {
    const itensManutencao = manutencaoForm.values.itensManutencao

    for (const itemManutencao of itensManutencao) {
      if (itemManutencao.codcim_itm !== form.values.codcim_itm) {
        if (itemManutencaoSelecionado) {
          const indexItemSelecionado = itensManutencao.indexOf(itemManutencaoSelecionado)
          const indexItemManutencao = itensManutencao.indexOf(itemManutencao)

          if (indexItemManutencao === indexItemSelecionado) continue
        }

        if (itemManutencao.codcim_itm === classificacaoImpostoTaxa) {
          throw new Error('Não é possível adicionar itens com classificação de peça ou serviço em uma manutenção que já possui impostos/taxas')
        }

        if (form.values.codcim_itm === classificacaoImpostoTaxa) {
          throw new Error('Não é possível adicionar itens com classificação imposto/taxa em uma manutenção que já possui peças ou serviços')
        }
      }
    }
  }

  function insertOrUpdateItemManutencao () {
    if (itemManutencaoSelecionado) {
      updateItemManutencao()
      return
    }

    insertItemManutencao()
  }

  function updateItemManutencao () {
    const itensManutencao = manutencaoForm.values.itensManutencao

    const indexItemManutencao = itensManutencao.indexOf(itemManutencaoSelecionado)

    itensManutencao.splice(indexItemManutencao, 1, postItemManutencaoDTO(form.values))

    manutencaoForm.setFieldValue('itensManutencao', itensManutencao)
  }

  function insertItemManutencao () {
    const itensManutencao = manutencaoForm.values.itensManutencao

    itensManutencao.push(postItemManutencaoDTO(form.values))

    manutencaoForm.setFieldValue('itensManutencao', itensManutencao)
  }

  async function excluirItemManutencao () {
    const itensManutencao = manutencaoForm.values.itensManutencao

    const indexItemManutencao = itensManutencao.indexOf(itemManutencaoSelecionado)

    itensManutencao.splice(indexItemManutencao, 1)

    manutencaoForm.setFieldValue('itensManutencao', itensManutencao)

    await hideModal()
  }

  const form = baseForm({
    initialValues: getItemManutencaoDTO(),
    validationSchema: formValidator,
    onSubmit: onSubmit
  })

  useEffect(async () => {
    if (visible) {
      if (itemManutencaoSelecionado) {
        const dadosItemManutencaoSelecionado = getItemManutencaoDTO(itemManutencaoSelecionado)

        await form.setValues(dadosItemManutencaoSelecionado)

        await handleChangeClassificacao(dadosItemManutencaoSelecionado.classificacao)
      } else {
        form.resetForm()
      }
    }

    await getClassificacoesItens()
    await getFormValidator()
  }, [visible])

  async function getClassificacoesItens () {
    const classificacoesItens = await ClassificacaoItensManutencaoService.getAll()

    if (!itemManutencaoSelecionado) {
      if (manutencaoForm.values.codclm_man === 2) {
        await handleChangeClassificacao(classificacoesItens.find(classificacao => classificacao.codigo_cim === classificacaoImpostoTaxa))
      } else {
        await handleChangeClassificacao(classificacoesItens.find(classificacao => classificacao.codigo_cim === classificacaoPeca))
      }
    }

    setClassificacoesItens(classificacoesItens)
  }

  async function getFormValidator () {
    const formValidatorAST = await ItensManutencaoService.getFormValidator()

    const formValidator = transformAll(formValidatorAST)

    setFormValidator(formValidator)
    setCamposObrigatorios(formValidator._nodes)
  }

  useEffect(() => {
    form.setFieldValue('valtot_itm', form.values.val_itm * form.values.qtd_itm)
  }, [form.values.val_itm, form.values.qtd_itm])

  function handleChangeValorUnitario (event) {
    form.setFieldValue('val_itm', formatCurrencyToNumber(event.target.value))
  }

  function handleChangeQuantidade (event) {
    form.setFieldValue('qtd_itm', event.target.value)
  }

  async function handleChangeClassificacao (classificacao) {
    await form.setFieldValue('classificacao', classificacao)
    await form.setFieldValue('descri_cim', classificacao.descri_cim)
    await form.setFieldValue('codcim_itm', classificacao.codigo_cim)
  }

  function onBlurQuantidade () {
    if (form.values.qtd_itm < 1) {
      form.setFieldValue('qtd_itm', 1)
    }
  }

  function getClassificacaoHtml () {
    if (!classificacoesItens) return

    if (CommonHelper.isDesktop()) {
      return classificacoesItens.map((classificacao, idx) => {
        return (
          <AppRadioButton key={idx}
            label={classificacao.descri_cim}
            value={classificacao}
            name={classificacao.codigo_cim}
            onChange={async (event) => await handleChangeClassificacao(event.value)}
            checked={form.values.codcim_itm === classificacao.codigo_cim}
            disabled={!edicaoHabilitada}
          />
        )
      })
    }

    return (
      <SelectInput
        value={form.values.classificacao || classificacoesItens[0]}
        options={classificacoesItens}
        onChange={async (event) => await handleChangeClassificacao(event.target.value)}
        name="classificacao"
        label="Classificação do item"
        optionLabel="descri_cim"
        dataKey="codigo_cim"
        disabled={!edicaoHabilitada}
        className="inputfield w-full"
        noFloatLabel
      />
    )
  }

  return (
    <Modal
      header="Item da manutenção"
      width={45}
      visible={visible}
      onHide={async () => await hideModal()}
      btnSalvar={edicaoHabilitada ? form.submitForm : undefined}
      btnExcluir={(itemManutencaoSelecionado && edicaoHabilitada) ? excluirItemManutencao : undefined}
    >
      <div className="formgrid grid">
        <div className={'field col-12 justify-content-around flex' + (!CommonHelper.isDesktop() ? '-column' : '')}>
          {getClassificacaoHtml()}
        </div>
        <div className="field col-12">
          <TextInput
            label="Descrição"
            placeholder="Descrição do item"
            name="descri_itm"
            value={form.values.descri_itm}
            onChange={form.handleChange}
            disabled={!edicaoHabilitada}
            camposObrigatorios={camposObrigatorios}
            form={form}
          />
        </div>
        <div className="field col-12 md:col-4">
          <TextInput
            label="Valor unitário"
            name="val_itm"
            value={formatToCurrency(form.values.val_itm)}
            onChange={handleChangeValorUnitario}
            disabled={!edicaoHabilitada}
            camposObrigatorios={camposObrigatorios}
            form={form}
          />
        </div>
        <div className="field col-12 md:col-4">
          <TextInput
            name="qtd_itm"
            type="number"
            label="Quantidade"
            value={form.values.qtd_itm}
            onChange={handleChangeQuantidade}
            onBlur={onBlurQuantidade}
            disabled={!edicaoHabilitada}
            camposObrigatorios={camposObrigatorios}
            form={form}
          />
        </div>
        <div className="field col-12 md:col-4">
          <TextInput
            label="Valor Total"
            value={formatToCurrency(form.values.valtot_itm)}
            disabled
          />
        </div>
      </div>
    </Modal>
  )
}

export default ItemManutencaoModal
