export const getItemManutencaoDTO = (data = {}) => {
  return {
    codigo_itm: data.codigo_itm || null,
    descri_itm: data.descri_itm || '',
    qtd_itm: data.qtd_itm || 1,
    val_itm: data.val_itm || 0.00,
    valtot_itm: data.valtot_itm || 0.00,
    classificacao: data.classificacao || { codigo_cim: data.codigo_cim, descri_cim: data.descri_cim },
    descri_cim: data.descri_cim || ''
  }
}

export const postItemManutencaoDTO = (data) => {
  return {
    codigo_itm: data.codigo_itm || null,
    descri_itm: data.descri_itm || '',
    qtd_itm: data.qtd_itm || 1,
    val_itm: data.val_itm || 0.00,
    valtot_itm: data.valtot_itm || 0.00,
    codcim_itm: data.classificacao?.codigo_cim || null,
    classificacao: data.classificacao || {},
    descri_cim: data.descri_cim || '',
    impress_itm: 0
  }
}
