export const getModeloDTO = (data = {}) => {
  return {
    codigo_mod: data.codigo_mod || null,
    reffab_mod: data.reffab_mod || '',
    descri_mod: data.descri_mod || '',
    pesliq_mod: data.pesliq_mod || 0,
    pesbru_mod: data.pesbru_mod || 0,
    cmt_mod: data.cmt_mod || 0,
    diseix_mod: data.diseix_mod || 0,
    codden_mod: data.codden_mod || '',
    lotmax_mod: data.lotmax_mod || 0,
    potmax_mod: data.potmax_mod || 0,
    tagnov_mod: data.tagnov_mod || false,
    igntamcha_mod: data.igntamcha_mod || false,
    ativo_mod: data.ativo_mod || true,
    usuins_mod: data.usuins_mod || null,
    dahins_mod: new Date(data.dahins_mod) || null,
    usualt_mod: data.usualt_mod || null,
    dahalt_mod: data.dahalt_mod !== null ? new Date(data.dahalt_mod) : null,
    obsnof_mod: data.obsnof_mod || '',
    qtdeix_mod: data.qtdeix_mod || 0,
    isman_mod: data.isman_mod || true,
    marca: data.marca || {},
    classificacaofiscal: data.classificacaofiscal || {},
    tipoVeiculoDenatran: data.tipoVeiculoDenatran || {},
    especieDenatran: data.especieDenatran || {},
    familiaVeiculo: data.familiaVeiculo || {},
    usuarioInsercao: data.usuarioInsercao || {},
    usuarioAlteracao: data.usuarioAlteracao || {}
  }
}

export const postModeloDTO = (data) => {
  return {
    codigo_mod: data.codigo_mod || null,
    reffab_mod: data.reffab_mod || '',
    descri_mod: data.descri_mod || '',
    pesliq_mod: data.pesliq_mod || 0,
    pesbru_mod: data.pesbru_mod || 0,
    cmt_mod: data.cmt_mod || 0,
    diseix_mod: data.diseix_mod || 0,
    codden_mod: data.codden_mod || '',
    lotmax_mod: data.lotmax_mod || 0,
    potmax_mod: data.potmax_mod || 0,
    tagnov_mod: data.tagnov_mod || false,
    igntamcha_mod: data.igntamcha_mod || false,
    ativo_mod: data.ativo_mod || true,
    usuins_mod: data.usuarioInsercao?.codigo_pes || null,
    dahins_mod: data.dahins_mod || null,
    usualt_mod: data.usuarioAlteracao?.codigo_pes || null,
    dahalt_mod: data.dahalt_mod || null,
    obsnof_mod: data.obsnof_mod || '',
    qtdeix_mod: data.qtdeix_mod || 0,
    isman_mod: data.isman_mod || true,
    codmar_mod: data.marca?.codigo_mar || {},
    codesd_mod: data.especieDenatran?.codigo_esd || {},
    codtvd_mod: data.tipoVeiculoDenatran?.codigo_tvd || {},
    codfav_mod: data.familiaVeiculo?.codigo_fav || {},
    codclf_mod: data.classificacaofiscal?.codigo_clf || {},
    marca: data.marca || {},
    tipoVeiculoDenatran: data.tipoVeiculoDenatran || {},
    especieDenatran: data.especieDenatran || {},
    familiaVeiculo: data.familiaVeiculo || {},
    classificacaofiscal: data.classificacaofiscal || {},
    usuarioInsercao: data.usuarioInsercao || {},
    usuarioAlteracao: data.usuarioAlteracao || {}
  }
}
