import React, { useState } from 'react'
import TextAreaInput from '../../../../../components/inputs/TextAreaInput'
import TextInput from '../../../../../components/inputs/TextInput'
import AppButton from '../../../../../components/layout/AppButton'
import { showErrorMessage } from '../../../../../components/utils/Message'
import CommonHelper from '../../../../../helpers/CommonHelper'
import { formatDateTime } from '../../../../../helpers/formaters'
import { getStore } from '../../../../../redux/store'
import NegociacaoService from '../../../../../services/vendas/negociacao/NegociacaoService'

const ObservacoesNeg = ({ form, edicaoHabilitada }) => {
  const [observacao, setObservacao] = useState('')

  const adicionarObservacao = async () => {
    try {
      if (observacao === '') return

      const usuario = getStore().usuarioLogado.usuario
      const pularLinha = form.values.obs_neg !== '' ? '\r' : ''
        .replace(/(\r\n|\n|\r)/gm, ' ') // remove quebra de linhas
        .replace(/  +/g, ' ') // remove múltiplos espaços

      form.setFieldValue('obs_neg', form.values.obs_neg + `${pularLinha}${usuario.pessoa.apelid_pes}, em ${formatDateTime(new Date())} disse: ${observacao}`)

      if (form.values.codigo_neg !== null) {
        await NegociacaoService.setObservacao(form.values.obs_neg, form.values.codigo_neg)
      }

      setObservacao('')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao adicionar a observação!')
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      adicionarObservacao()
    }
  }

  const handleChangeObservacao = (e) => {
    form.setFieldValue('obscon_neg', CommonHelper.removerCaracteresEspeciais(e.target.value))
  }

  return (
    <>
      <div className="formgrid grid fluid justify-content-center">
        <label className="field col-12 md:col-8 mt-3 mb-4 text-left">Observações internas (Não visiveis no contrato)</label>
        <div className="field col-12 md:col-8">
          <TextAreaInput
            rows={1}
            cols={80}
            autoResize
            name="obs_neg"
            value={form.values.obs_neg}
            disabled={!edicaoHabilitada}
            className="neg-observacoes no-padding text-justify"
          />
        </div>
        <div className="p-inputgroup neg-observacoes-inputgroup field col-12  md:col-8">
          <TextInput
            value={observacao}
            onChange={(e) => setObservacao(e.target.value)}
            className="neg-observacoes-input mb-3"
            placeholder="Adicione uma observação interna"
            disabled={!edicaoHabilitada}
            onKeyDown={handleKeyDown}
          />
          <AppButton
            type="button"
            className="neg-observacoes-button"
            icon="pi pi-plus"
            onClick={() => adicionarObservacao()}
            disabled={!edicaoHabilitada}
            onKeyDown={handleKeyDown}
          />
        </div>
        <label className="field col-12  md:col-8 mt-5 text-left">Observações impressas no contrato</label>
        <div className="field col-12 md:col-8 mt-3">
          <TextAreaInput
            rows={6}
            cols={80}
            autoResize
            name="obscon_neg"
            value={form.values.obscon_neg}
            onChange={handleChangeObservacao}
            disabled={!edicaoHabilitada}
            maxLength="9999"
            className="w-full"
          />
        </div>
      </div>
    </>
  )
}

export default ObservacoesNeg
