import { Accordion } from 'primereact/accordion'
import { AccordionTab } from 'primereact/accordion'
import React, { Component } from 'react'

class AccordionContainer extends Component {
  constructor (props) {
    super(props)

    this.state = {
      activeIndex: [],
      loading: true,
      isReportAccordion: false
    }
  }

  componentDidMount () {
    let { activeIndex } = this.state

    React.Children.forEach(this.props.children, (child, index) => {
      if (child !== null && child.props.active || !!this.props.report)
        activeIndex.push(index)
    })

    this.setState({ activeIndex, isReportAccordion: !!this.props.report }, this.stopLoading)
  }

  stopLoading () {
    this.setState({ loading: false })
  }

  headerTemplate = (headerTitle, indexAccordionPage) => {
    const toggleIcon = !this.state.activeIndex.some((indexAtivo) => indexAtivo === indexAccordionPage) ? 'pi pi-angle-double-down' : 'pi pi-angle-double-up'

    return (
      <div className="header">
        <span>{headerTitle}</span>
        {!this.state.isReportAccordion && (
          <i className={toggleIcon}></i>
        )}
      </div>
    )
  }

  getAccordionClassName () {
    const baseClassName = 'normal col-12'
    const reportClassName = 'report col-12'
    const tabQuantityClassName = React.Children.count(this.props.children) === 1 ? 'unique' : 'many'

    const className = `${this.state.isReportAccordion ? reportClassName : baseClassName} ${tabQuantityClassName}`

    return className
  }

  render () {
    const tabs = React.Children.map(this.props.children, (child, indexAccordionPage) => {
      if (child !== null) {
        return (
          <AccordionTab key={indexAccordionPage} header={this.headerTemplate(child.props.header, indexAccordionPage)} disabled={this.state.isReportAccordion} >
            {child.props.children}
          </AccordionTab>
        )
      }
    })

    return this.state.loading
      ? (
        <>
        </>
      ) : (
        <Accordion
          multiple
          className={this.getAccordionClassName()}
          activeIndex={this.state.activeIndex}
          onTabChange={(e) => this.setState({ activeIndex: e.index })}
        >
          {tabs}
        </Accordion>
      )
  }
}

export default AccordionContainer
