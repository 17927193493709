import React, { useEffect, useState } from 'react'
import AutoCompleteInput from '../../../../components/inputs/AutoCompleteInput'
import DateInput from '../../../../components/inputs/DateInput'
import DescricaoAcessoriosModal from '../../../../components/utils/DescricaoAcessoriosModal'
import { showErrorMessage, showWarnMessage } from '../../../../components/utils/Message'
import VeiculoTemplate from '../../../../components/utils/VeiculoTemplate'
import CommonHelper from '../../../../helpers/CommonHelper'
import { formatDocument, formatPlaca } from '../../../../helpers/formaters'
import { PessoaModel } from '../../../../models/cadastro/pessoa/PessoaModel'
import PessoaService from '../../../../services/cadastro/pessoa/PessoaService'
import VeiculoService from '../../../../services/cadastro/veiculo/VeiculoService'
import NegociacaoService from '../../../../services/vendas/negociacao/NegociacaoService'
import { PessoaFisica } from '../../../../views/cadastro/pessoa/fisica/PessoaFisica'
import { PessoaJuridica } from '../../../../views/cadastro/pessoa/juridica/PessoaJuridica'
import Veiculo from '../../../../views/cadastro/veiculo/veiculo/Veiculo'
import { getVeiculoDTO } from '../../../../dtos/cadastro/veiculo/VeiculoDTO'

function DadosPrincipaisMan ({ form, camposObrigatorios, edicaoHabilitada }) {
  const veiculo = form.values.veiculo

  const [negociacaoCompraDoVeiculo, setNegociacaoCompraDoVeiculo] = useState(null)
  const [descricaoAcessorios, setDescricaoAcessorios] = useState([])
  const [visibleDescricaoAcessoriosModal, setVisibleDescricaoAcessoriosModal] = useState(false)
  const [sugestaoVeiculos, setSugestaoVeiculos] = useState([])
  const [sugestaoFornecedores, setSugestaoFornecedores] = useState([])

  useEffect(() => {
    if (veiculo.codigo_vei) {
      setUltimaCompraVeiculo(veiculo.codigo_vei)
    }
  }, [])

  async function sugerirVeiculos () {
    try {
      const veiculoService = new VeiculoService()
      const veiculos = await veiculoService.filterAutoComplete(`placa_vei=${form.values.veiculo?.placa_vei}`)

      if (veiculos.length <= 0) {
        showWarnMessage('Veículo não encontrado!')
      }

      setSugestaoVeiculos(veiculos)
    } catch (error) {
      console.error(error)
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um veículo!')
    }
  }

  async function sugerirFornecedores () {
    try {
      const fornecedores = await PessoaService.filterAutocomplete(form.values.fornecedor.nomraz_pes)

      if (fornecedores.length <= 0) {
        showWarnMessage('Fornecedor não encontrada!')
      }

      setSugestaoFornecedores(fornecedores)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um fornecedor!')
    }
  }

  async function handleChangeVeiculo (e) {
    setNegociacaoCompraDoVeiculo(null)
    await form.setFieldValue('codneg_man', null)
    await form.setFieldValue('codtdr_man', null)
    await form.setFieldValue('veiculo', { placa_vei: e.target.value })
  }

  function handleChangeFornecedor (e) {
    form.setFieldValue('fornecedor', { nomraz_pes: e.target.value })
  }

  async function handleChangeDate (e) {
    await form.setFieldValue(e.target.name, e.target.value)
    await form.setFieldTouched(e.target.name)
  }

  async function onSaveModalVeiculo (veiculo) {
    onSelectVeiculo({ value: veiculo })
  }

  function onSaveModalFornecedor (fornecedor) {
    onSelectFornecedor({ value: fornecedor.pessoa })
  }

  async function onSelectVeiculo (e) {
    const veiculo = await getVeiculoByCodigo(e.value.codigo_vei)
    await form.setFieldValue('veiculo', veiculo)
    await form.setFieldTouched('veiculo.codigo_vei')

    await setUltimaCompraVeiculo(veiculo.codigo_vei)
  }

  async function setUltimaCompraVeiculo (codigoVei) {
    const ultimaCompraValida = await NegociacaoService.getUltimaCompraVeiculo(codigoVei)
    await form.setFieldValue('codneg_man', ultimaCompraValida.codigo_neg)
    setNegociacaoCompraDoVeiculo(ultimaCompraValida)
  }

  async function onSelectFornecedor (e) {
    await form.setFieldValue('fornecedor', e.value)
    await form.setFieldTouched('fornecedor.codigo_pes')
  }

  async function getVeiculoByCodigo (codigo) {
    const veiculoService = new VeiculoService()
    return await veiculoService.get(codigo)
  }

  function openUltimaCompraNeg () {
    window.open(`${window.location.origin}/negociacao/${negociacaoCompraDoVeiculo.codigo_neg}`)
  }

  return (
    <div className="formgrid grid fluid">
      <div className="md:col-6 field col-12 formgrid grid">
        <div className="field col-12 md:col-11 no-margin">
          <AutoCompleteInput
            //AutoComplete
            name="veiculo.codigo_vei"
            field="placa_vei"
            label="Veículo"
            value={form.values.veiculo && (form.values.veiculo?.placa_vei || form.values.veiculo?.chassi_vei)}
            placeholder="Informe a placa do veículo"
            disabled={!edicaoHabilitada}
            onChange={async (e) => await handleChangeVeiculo(e)}
            onSelect={async (e) => await onSelectVeiculo(e)}
            suggestions={sugestaoVeiculos}
            completeMethod={sugerirVeiculos}
            itemTemplate={VeiculoTemplate}
            camposObrigatorios={camposObrigatorios}
            form={form}
            //RegisterModal
            selected={form.values.veiculo}
            onSaveModal={onSaveModalVeiculo}
            onDeleteModal={() => onSelectVeiculo({})}
            modalHeader={form.values.veiculo?.codigo_vei ? `Veículo - ${form.values.veiculo?.codigo_vei}` : 'Cadastro de veículo'}
            ComponenteCadastro={Veiculo}
            //FiltroModal
            filtrotitle="Pesquisa de veículo"
            service={VeiculoService}
            model={getVeiculoDTO}
            primarykeyname="codigo_vei"
            columns={[
              { campo: 'codigo_vei', nome: 'Código' },
              { campo: 'placa_vei', nome: 'Placa', format: formatPlaca },
              { campo: 'chassi_vei', nome: 'Chassi' },
              { campo: 'modelo.descri_mod', nome: 'Modelo' },
              { campo: 'modelo.marca.descri_mar', nome: 'Marca' }
            ]}
            filtersDefault={{ apenasVeiculosComNegsDeCompra: true }}
          />
        </div>
        <div className="flex-column">
          {negociacaoCompraDoVeiculo && (
            <span className="negociacao-compra-descri underline cursor-pointer" onClick={openUltimaCompraNeg}>
            Veículo comprado na negociação {negociacaoCompraDoVeiculo.codigo_neg}
            </span>
          )}
          {veiculo.codigo_vei && (
            <div className="pl-2 pt-2">
              <div className="descricao-vei-row">
                <span className="descricao-vei-label">Marca/Modelo:</span>
                <span className="descricao-vei-value">{veiculo.modelo.marca.descri_mar}/{veiculo.modelo.descri_mod}</span>
              </div>
              <div className="descricao-vei-row">
                <span className="descricao-vei-label">Ano:</span>
                <span className="descricao-vei-value">{veiculo.anofab_vei}/{veiculo.anomod_vei}</span>
              </div>
              <div className="descricao-vei-row">
                <span className="descricao-vei-label">Cor:</span>
                <span className="descricao-vei-value">{veiculo.cor.descri_cor}</span>
              </div>
              <div className="descricao-vei-row">
                <span className="descricao-vei-label">Placa/Chassi:</span>
                <span className="descricao-vei-value">
                  {veiculo.placa_vei ? formatPlaca(veiculo.placa_vei) : veiculo.chassi_vei.substring(veiculo.chassi_vei.length - 8)}
                </span>
                <span className={`tipo-veiculo-${veiculo.codtiv_vei} descricao-vei-tipo`}>
                  {veiculo.codtiv_vei === 'n' ? 'Novo' : 'Usado'}
                </span>
              </div>
              {veiculo.acessorios.length > 0 && (
                <div className="descricao-vei-row">
                  <span className="descricao-vei-label">Acessórios:</span>
                  <span
                    className="descricao-vei-value underline cursor-pointer"
                    onClick={() => {
                      setVisibleDescricaoAcessoriosModal(true)
                      setDescricaoAcessorios((veiculo.acessorios[0].acessorio.descri_ace).split(','))
                    }}
                  >
                    {veiculo.acessorios[0].acessorio.sigla_ace}
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="md:col-6 field col-12 formgrid grid">
        <div className="md:col-1 field col-0"></div>
        <div className="md:col-11 field col-12">
          <AutoCompleteInput
            //AutoComplete
            name="fornecedor.codigo_pes"
            field="nomraz_pes"
            label="Fornecedor"
            value={form.values.fornecedor && form.values.fornecedor.nomraz_pes}
            placeholder="Nome do fornecedor"
            disabled={!edicaoHabilitada}
            onSelect={async (e) => await onSelectFornecedor(e)}
            suggestions={sugestaoFornecedores}
            completeMethod={sugerirFornecedores}
            onChange={handleChangeFornecedor}
            camposObrigatorios={camposObrigatorios}
            form={form}
            //RegisterModal
            selected={form.values.fornecedor}
            onSaveModal={onSaveModalFornecedor}
            onDeleteModal={async () => await onSelectFornecedor({})}
            ComponenteCadastro={[
              {
                label: 'Pessoa física',
                component: PessoaFisica,
                header: form.values.fornecedor.codigo_pes ? 'Pessoa Física - ' + form.values.fornecedor.codigo_pes : 'Cadastro de pessoa física'
              },
              {
                label: 'Pessoa jurídica',
                component: PessoaJuridica,
                header: form.values.fornecedor.codigo_pes ? 'Pessoa jurídica - ' + form.values.fornecedor.codigo_pes : 'Cadastro de pessoa jurídica'
              }
            ]}
            //FiltroModal
            filtrotitle="Pesquisa de fornecedor"
            service={PessoaService}
            model={PessoaModel}
            primarykeyname="codigo_pes"
            columns={[
              { campo: 'codigo_pes', nome: 'Código' },
              { campo: 'nomraz_pes', nome: 'Nome' },
              { campo: 'cgccpf_pes', nome: 'CPF/CNPJ', format: formatDocument }
            ]}
          />
        </div>
        <div className={'col-12 no-padding flex' + (!CommonHelper.isDesktop() ? '-column' : '')}>
          <div className="md:col-1 col-0"></div>
          <div className="md:col-5 col-12 field">
            <DateInput
              name="datsai_man"
              label="Data de saída"
              placeholder="Data de saída"
              value={form.values.datsai_man}
              onChange={async (e) => handleChangeDate(e)}
              disabled={!edicaoHabilitada}
              camposObrigatorios={camposObrigatorios}
              form={form}
            />
          </div>
          {CommonHelper.isDesktop() && <div className="col-1 field"></div>}
          <div className="md:col-5 col-12 field">
            <DateInput
              name="datpre_man"
              label="Previsão de retorno"
              placeholder="Previsão de retorno"
              value={form.values.datpre_man}
              onChange={async (e) => handleChangeDate(e)}
              disabled={!edicaoHabilitada}
              camposObrigatorios={camposObrigatorios}
              form={form}
            />
          </div>
        </div>
        <div className="col-12 flex no-padding">
          <div className="col-0 md:col-7 field"></div>
          <div className="md:col-5 col-12 field">
            <DateInput
              name="datret_man"
              label="Retorno"
              placeholder="Retorno"
              value={form.values.datret_man}
              onChange={async (e) => handleChangeDate(e)}
              disabled={!edicaoHabilitada}
              camposObrigatorios={camposObrigatorios}
              form={form}
            />
          </div>
        </div>
      </div>
      <DescricaoAcessoriosModal
        visible={visibleDescricaoAcessoriosModal}
        descricaoAcessorios={descricaoAcessorios}
        onHide={() => {
          setVisibleDescricaoAcessoriosModal(false)
          setDescricaoAcessorios([])
        }}
      />
    </div>
  )
}

export default DadosPrincipaisMan
