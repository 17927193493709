import { Calendar } from 'primereact/calendar'
import React from 'react'
import CommonHelper from '../../helpers/CommonHelper'
import InputBase from './base/InputBase'

class DateInput extends InputBase {
  constructor (props) {
    super(props)
  }

  renderInput () {
    const { props } = this

    return (
      <Calendar
        {...props}
        value={props.value || ''}
        onChange={(e) => props.onChange(e)}
        mask="99/99/9999"
        slotChar="mm/dd/yyyy"
        showIcon={props.showIcon || true}
        dateFormat="dd/mm/yy"
        locale="br"
        hideOnDateTimeSelect
        monthNavigator
        yearNavigator
        yearRange="1900:2100"
        readOnlyInput={!CommonHelper.isDesktop()}
        onBlur={this.onBlur}
        className={this.getInputClassName()}
      />
    )
  }
}

export default DateInput
