import ServiceBase from '../../base/ServiceBase'

class TipoPrecoService {
  static async getAll () {
    return await ServiceBase.getBase('tipoPreco/getAll')
  }

  static async getTiposPrecoOrcamentoBalcao () {
    return await ServiceBase.getBase('tipoPreco/orcamentoBalcao')
  }

  static async getTiposPrecoPersonalizadoOrcamentoBalcao () {
    return await ServiceBase.getBase('tipoPreco/getTiposPrecoPersonalizadoOrcamentoBalcao')
  }
}

export default TipoPrecoService
