import { Button } from 'primereact/button'
import { classNames } from 'primereact/utils'
import React from 'react'
import List from '../../classes/List'
import Container from '../../components/layout/Container'
import DataList from '../../components/layout/DataList'
import Page from '../../components/layout/Page'
import PreferencesButton from '../../components/utils/PreferencesButton'
import TablePreferences from '../../components/utils/TablePreferences'
import CommonHelper from '../../helpers/CommonHelper'
import { createColumns, getColunasHabilitadas } from '../../helpers/tableConfigs'
import { IntegracaoModel } from '../../models/integracao/IntegracaoModel'
import IntegracoesService from '../../services/Integracao/IntegracoesService'

export class IntegracaoList extends List {
  constructor (props) {
    super(props, IntegracaoModel, IntegracoesService, 'integracao', 'codigo_int')

    this.state = {
      ...this.state,
      visiblePreferencesModal: false
    }

    this.setColumns(createColumns([
      { field: 'codigo_int', header: 'Código', width: '10%' },
      { field: 'codemp_int', header: 'Acesso à empresa', width: '15%', customBody: this.getAcessoAEmpresa },
      { field: 'codusu_int', header: 'Usuário', width: '20%', customBody: this.getUsuarioEmail },
      { field: 'token_int', header: 'Token', width: '40%' },
      { field: 'obs_int', header: 'Token', width: '15%' }
    ]))
  }

  getAcessoAEmpresa (data) {
    return data.empresa?.pessoa?.apelid_pes
  }

  getUsuarioEmail (data) {
    return data.usuario?.email
  }

  render () {
    const state = this.state

    const columns = this.columns
    const actions = (
      <div className="flex justify-content-between">
        <div>
          <Button className="mr-2" label={CommonHelper.isDesktop() && 'Novo'} icon="pi pi-plus" onClick={this.onNew} />
        </div>
      </div>
    )

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Integrações</h4>
          <div className="page-header-buttons">
            <div>
              <Button className="mr-2" label={CommonHelper.isDesktop() && 'Novo'} icon="pi pi-plus" onClick={this.onNew} />
            </div>
          </div>
        </div>
        <div className="table-options-container">
          <div className="table-options">
            <div className={classNames({ active: this.state.opcaoSelecionada === 't' }, 'filter-option')}>
              <span className="option-label">Todos</span>
              <div className="option-quantity">
                {state.totalRecords}
              </div>
            </div>
          </div>
          <div className="table-preferences">
            <PreferencesButton
              onClick={() => this.setState({ visiblePreferencesModal: true })}
            />
          </div>
        </div>
        <Container>
          <DataList
            actions={actions}
            data={state.list}
            selected={state.selected}
            onSelect={this.onSelect}
            rows={state.rows}
            onRowSelect={(e) => this.onView(e.data)}
            totalRecords={state.totalRecords}
            first={state.first}
            onPage={this.onPageChange}
            page={state.page}
            showFilter
            filterPlaceholder="Procurar integrações"
            filterName="descricaoFiltro"
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
            responsive
          >
            {getColunasHabilitadas(columns, 'integracao')}
          </DataList>
        </Container>
        <TablePreferences
          title="Preferências da tabela de integração"
          tableName="integracao"
          columns={columns}
          visible={state.visiblePreferencesModal}
          hide={() => this.setState({ visiblePreferencesModal: false })}
        />
      </Page>
    )
  }
}
