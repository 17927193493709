import React, { useEffect, useRef } from 'react'
import DadosPrincipaisVei from './formPages/DadosPrincipaisVei'
import DadosFiscaisVei from './formPages/DadosFiscaisVei'
import DadosAdicionaisVei from './formPages/DadosAdicionaisVei'
import ImagensVei from './formPages/ImagensVei'
import AppButton from '../../../../components/layout/AppButton'
import TabMenuContainer from '../../../../components/layout/TabMenuContainer/TabMenuContainer.js'
import TabMenuPage from '../../../../components/layout/TabMenuContainer/TabMenuPage.js'

const VeiculoForm = ({ form, edicaoHabilitada, camposObrigatorios, isNew, setFooter, onSave }) => {
  const fileUploadRef = useRef(null)

  async function handleSubmit () {
    await fileUploadRef.current?.upload()
    await onSave(form.values)
  }

  function setVeiculoFooter () {
    setFooter(
      <div className="flex justify-content-end pb-6 pt-3">
        <AppButton
          type="submit"
          label="Salvar"
          className="p-button"
          onClick={async () => await handleSubmit()}
        />
      </div>
    )
  }

  useEffect(async () => {
    setVeiculoFooter()
  }, [form.values])

  return (
    <TabMenuContainer>
      <TabMenuPage header="INFORMAÇÕES PRINCIPAIS" active>
        <DadosPrincipaisVei
          form={form}
          edicaoHabilitada={edicaoHabilitada}
          camposObrigatorios={camposObrigatorios}
        />
      </TabMenuPage>
      <TabMenuPage header="FISCAL">
        <DadosFiscaisVei
          form={form}
          edicaoHabilitada={edicaoHabilitada}
          isNew={isNew}
        />
      </TabMenuPage>
      <TabMenuPage header="INFORMAÇÕES ADICIONAIS">
        <DadosAdicionaisVei
          form={form}
          edicaoHabilitada={edicaoHabilitada}
        />
      </TabMenuPage>
      <TabMenuPage header="IMAGENS">
        <ImagensVei
          form={form}
          fileUploadRef={fileUploadRef}
          edicaoHabilitada={edicaoHabilitada}
        />
      </TabMenuPage>
    </TabMenuContainer>
  )
}

export default VeiculoForm
