import React from 'react'
import DadosPrincipaisMod from './formPages/DadosPrincipaisMod'
import DadosAdicionaisMod from './formPages/DadosAdicionaisMod'
import TabMenuContainer from '../../../../components/layout/TabMenuContainer/TabMenuContainer'
import TabMenuPage from '../../../../components/layout/TabMenuContainer/TabMenuPage'

const ModeloForm = ({ form, edicaoHabilitada, camposObrigatorios }) => {
  return (
    <TabMenuContainer>
      <TabMenuPage header="INFORMAÇÕES PRINCIPAIS">
        <DadosPrincipaisMod
          edicaoHabilitada={edicaoHabilitada}
          form={form}
          camposObrigatorios={camposObrigatorios}
        />
      </TabMenuPage>
      <TabMenuPage header="INFORMAÇÕES ADICIONAIS">
        <DadosAdicionaisMod
          edicaoHabilitada={edicaoHabilitada}
          form={form}
          camposObrigatorios={camposObrigatorios}
        />
      </TabMenuPage>
    </TabMenuContainer>
  )
}

export default ModeloForm
