import * as Yup from 'yup'

export const AcessorioValidator = Yup.object().shape({
  descri_ace: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  sigla_ace: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!')
})
