import React from 'react'
import CorForm from '../../../../forms/cadastro/cor/CorForm'
import { CorModel } from '../../../../models/cadastro/veiculo/CorModel'
import CorService from '../../../../services/cadastro/veiculo/CorService'
import Container from '../../../../components/layout/Container'
import Page from '../../../../components/layout/Page'
import { Crud } from '../../../../classes/Crud'

export class Cor extends Crud {
  constructor (props) {
    super(props, CorModel, CorService, 'cores', 'codigo_cor')
  }

  render () {
    const { model, dataLoaded, isNew } = this.state
    return (
      <Page>
        <Container>
          {(dataLoaded || isNew) && (
            <CorForm
              onSave={this.onSave}
              isNew={isNew}
              onClose={this.onClose}
              model={model}
              onChange={this.onChange}
            />
          )}
        </Container>
      </Page>
    )
  }
}
