import React from 'react'
import { classNames } from 'primereact/utils'
import './_responsavelPagamento.scss'

const ResponsavelPagamento = (props) => {
  const { isLojaPaga } = props

  return (
    <>
      <label className={classNames(isLojaPaga ? 'labelPag isLoja' : 'labelPag isCliente')}>{isLojaPaga ? 'Pago pela loja' : 'Pago pelo cliente'}</label>
    </>
  )
}

export default ResponsavelPagamento
