import { Component } from 'react'
import { showErrorMessage, showSuccessMessage } from '../components/utils/Message'

export class Crud extends Component {
  constructor (props, Model, Service, resource, primaryKey = 'id') {
    super(props)

    this.model = Model
    this.modelService = new Service()
    this.resource = resource
    this.primaryKey = primaryKey
    this.state = {
      model: new Model(),
      dataLoaded: false,
      isNew: false,
      resource: this.resource
    }
  }

  async componentDidMount () {
    let id = this.props.match.params.id
    if (id) {
      this.get(id)
    } else {
      this.setState({ isNew: true })
    }
  }

    get = async (id) => {
      try {
        let model = await this.modelService.get(id)

        this.setState({
          model: new this.model(model),
          dataLoaded: true
        })
      } catch (e) {
        showErrorMessage('Erro ao obter informações')
      }
    };

    onSave = async (redirect = true) => {
      try {
        await this.modelService.save(this.state.model, this.state.model[this.primaryKey])
        if (redirect) {
          showSuccessMessage('Registro salvo com sucesso')
          this.props.history.push(`/${this.resource}`)
        }
        return true
      } catch (error) {
        showErrorMessage(error.message || 'Erro ao salvar registro')
        return false
      }
    };

    onClose = async () => {
      this.props.history.push(`/${this.resource}`)
    };

    onChange = (model) => {
      this.setState({ model })
    };
}
