import { Button } from 'primereact/button'
import React, { useState } from 'react'
import Modal from './Modal'

function OptionsModal ({ visible, onHide, options, onSaveModal, onDeleteModal, codigoSelecionado }) {
  const [Component, setComponent] = useState(null)

  function showComponent () {
    return Component ? Component : <></>
  }

  function makeButtons () {
    if (options?.length) {
      const buttons = options.map((option, idx) => (
        <div className="col-6" key={idx}>
          <Button
            label={option.label}
            className="w-full"
            onClick={() => {
              setComponent(
                <Modal
                  visible={true}
                  onHide={() => {
                    onHide()
                    setComponent(null)
                  }}
                  width="75"
                  header={option.header}
                >
                  <option.component
                    onSaveModal={onSaveModal}
                    onDeleteModal={onDeleteModal}
                    codigoSelecionado={codigoSelecionado}
                    onHide={() => {
                      onHide()
                      setComponent(null)
                    }}
                  />
                </Modal>
              )
            }}
          />
        </div>
      ))

      return buttons
    }
  }

  return (
    <Modal
      header="Selecione o tipo de cadastro"
      visible={visible}
      onHide={onHide}
      width="25"
    >
      <div className="grid">
        {makeButtons()}
        {showComponent()}
      </div>
    </Modal>
  )
}

export default OptionsModal
