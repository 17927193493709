import React, { useState, useEffect } from 'react'
import TextInput from '../../../../../components/inputs/TextInput'
import SelectInput from '../../../../../components/inputs/SelectInput'
import BandeiraEmpresaService from '../../../../../services/cadastro/veiculo/BandeiraEmpresaService'
import CommonHelper from '../../../../../helpers/CommonHelper'
import { showErrorMessage } from '../../../../../components/utils/Message'

const DadosPrincipaisMar = ({ form, edicaoHabilitada, camposObrigatorios }) => {
  const [bandeirasEmpresa, setBandeiraEmpresa] = useState([])

  useEffect(async () => {
    await getBandeiraEmpresa()
  }, [])

  const getBandeiraEmpresa = async () => {
    try {
      const bandeirasEmpresa = await BandeiraEmpresaService.getAll()
      setBandeiraEmpresa(bandeirasEmpresa)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar as bandeiras!')
    }
  }

  return (
    <div className="formgrid grid fluid">
      <div className="field col-12 sm:col-9 md:col-9">
        <TextInput
          name="descri_mar"
          label="Descrição"
          placeholder="Descrição"
          value={form.values.descri_mar}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 sm:col-3 md:col-3">
        <SelectInput
          options={bandeirasEmpresa}
          value={form.values.bandeiraEmpresa}
          onChange={form.handleChange}
          name="bandeiraEmpresa"
          label="Bandeira"
          dataKey="codigo_bae"
          optionLabel="descri_bae"
          disabled={!edicaoHabilitada}
          noFloatLabel
          filter={CommonHelper.isDesktop()}
          showClear={form.values.bandeiraEmpresa?.codigo_bae}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
    </div>
  )
}

export default DadosPrincipaisMar
