export const getAcessorioDTO = (data = {}) => {
  return {
    codigo_ace: data.codigo_ace || null,
    descri_ace: data.descri_ace || '',
    sigla_ace: data.sigla_ace || '',
    codext_ace: data.codext_ace || null
  }
}

export const postAcessorioDTO = (data) => {
  return {
    codigo_ace: data.codigo_ace || null,
    descri_ace: data.descri_ace || '',
    sigla_ace: data.sigla_ace || '',
    codext_ace: data.codext_ace || null
  }
}
