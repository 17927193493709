import ServiceBase from '../../base/ServiceBase'

export default class EstadosService {
  static async getAll () {
    return await ServiceBase.getBase('states')
  }

  static async getAllByCountry (countryId) {
    return await ServiceBase.getBase(`states/${countryId}`)
  }

  static async getEstadoBySigla (sigla) {
    return await ServiceBase.getBase(`states/sigla/${sigla}`)
  }

  static async getStateByCity (cityId) {
    return await ServiceBase.getBase(`state/city/${cityId}`)
  }
}
