import AccordionPage from '../../layout/AccordionPage'
import React from 'react'
import AccordionContainer from '../../layout/AccordionContainer'
import CommonHelper from '../../../helpers/CommonHelper'
import ReportDatatable from './ReportDatatable'
import { Divider } from 'primereact/divider'

class ReportContentGenerator {
  constructor (reportData, columns, grouping, reportDataToXlsx, totalizadores, reportColumnsWidths, sortField, sortOrder, onSort) {
    this.reportData = reportData
    this.columns = columns
    this.grouping = grouping
    this.reportDataToXlsx = reportDataToXlsx

    this.totalizadores = totalizadores
    this.reportColumnsWidths = reportColumnsWidths

    this.sortField = sortField
    this.sortOrder = sortOrder
    this.onSort = onSort
  }

  generateReport () {
    const isGroupedReport = !!this.grouping

    if (this.reportData.length === 0) {
      return this.getEmptyMessage()
    }

    const reportContent = isGroupedReport ? this.generateGroupingRecursively() : this.generateDataDisplay(this.reportData)

    return reportContent
  }

  getEmptyMessage () {
    return <h3>Nenhum registro foi encontrado.</h3>
  }

  generateGroupingRecursively (data = this.reportData, grouping = this.grouping) {
    return (
      <AccordionContainer report>
        {data.map((value, idx) => {
          const groupingProperty = CommonHelper.getPropertyFromObjectByNamePath(grouping.propertyName, value)
          const header = grouping.getHeader(value)
          const isLastGroup = !grouping.group

          this.reportDataToXlsx.addGroupToExcel(header)

          const accordionPageContent = isLastGroup ? this.generateDataDisplay(groupingProperty) : this.generateGroupingRecursively(groupingProperty, grouping.group)

          return (
            <AccordionPage key={idx} header={header}>
              {accordionPageContent}
            </AccordionPage>
          )
        })}
      </AccordionContainer >
    )
  }

  generateDataDisplay (data) {
    this.reportDataToXlsx.addDataToExcel(data)

    return (
      <>
        <ReportDatatable
          data={data}
          columns={this.columns}
          sortField={this.sortField}
          sortOrder={this.sortOrder}
          onSort={(e) => this.onSort(e)}
        />
        {(this.totalizadores && this.reportColumnsWidths) && (
          <>
            {CommonHelper.isDesktop() && (
              <Divider className="no-margin pb-2"></Divider>
            )}
            <div className={'flex report-totalizadores-container ' + (!CommonHelper.isDesktop() && 'hidden')}>
              {this.generateTotalizadores(data)}
            </div>
          </>
        )}
      </>
    )
  }

  generateTotalizadores (data) {
    const totalizadoresHtml = this.columns.map((column, columnIndex) => {
      const columnWidth = this.reportColumnsWidths[columnIndex]

      const totalizador = this.buscaTotalizadorDaColuna(column)

      if (totalizador) {
        return this.getColumnTotalizadorHtml(totalizador, data, columnIndex, columnWidth)
      }

      return this.getColumnWithoutTotalizadorHtml(columnIndex, columnWidth)
    })

    return totalizadoresHtml
  }

  buscaTotalizadorDaColuna (column) {
    return this.totalizadores.find(totalizador => totalizador.field === column.props.field)
  }

  getColumnTotalizadorHtml (totalizador, data, columnIndex, columnWidth) {
    const { field, name, formatFunction, customValue } = totalizador

    const valorTotalizador = this.getColumnTotalizadorValue(data, field, formatFunction, customValue)

    const width = CommonHelper.isDesktop() ? columnWidth : '15%'
    const maxWidth = CommonHelper.isDesktop() ? columnWidth : '100%'

    return (
      <div key={columnIndex} style={{ width, maxWidth }} className="flex report-totalizador-column">
        {!CommonHelper.isDesktop() && (
          <label className="report-totalizador-label">{name}</label>
        )}
        <div className="text-center report-totalizador w-full">
          {valorTotalizador}
        </div>
      </div>
    )
  }

  getColumnTotalizadorValue (data, field, formatFunction, customValue) {
    let somaDaColuna

    if (customValue) {
      somaDaColuna = customValue(data)
    } else {
      const values = data.map(row => row[field])

      somaDaColuna = values.reduce((previousValue, currentValue) => previousValue + currentValue)
    }

    const valorTotalizador = formatFunction ? formatFunction(somaDaColuna) : somaDaColuna
    return valorTotalizador
  }

  getColumnWithoutTotalizadorHtml (columnIndex, columnWidth) {
    return <div key={columnIndex} style={{ width: columnWidth, maxWidth: columnWidth }}></div>
  }
}

export default ReportContentGenerator
