import React from 'react'
import DataListSimples from '../../../../components/layout/DataListSimples/DataListSimples'
import { createColumns } from '../../../../helpers/tableConfigs'

export default function EstoqueList ({ produtoEmpresa }) {
  const columns = createColumns([
    {
      field: 'codigo_pes',
      header: 'EMPRESA',
      customBody: formatNomeEmpresa,
      width: '20%',
      sortable: false
    },
    {
      field: 'enderecamento',
      header: 'ENDEREÇO',
      sortable: false
    },
    {
      field: 'qtdAplicada',
      header: 'APLICADAS',
      sortable: false
    },
    {
      field: 'qtdReservada',
      header: 'RESERVADAS',
      sortable: false
    },
    {
      field: 'qtdTransito',
      header: 'TRÂNSITO',
      sortable: false
    },
    {
      field: 'qtdARetirar',
      header: 'Á RETIRAR',
      sortable: false
    },
    {
      field: 'qtdEmPedido',
      header: 'EM PEDIDO',
      sortable: false
    },
    {
      field: 'qtdBackOrder',
      header: 'B.O',
      sortable: false
    },
    {
      field: 'qtdDisponivel',
      header: 'DISPONÍVEL',
      headerClassName: 'text-bold',
      sortable: false
    }
  ])

  function formatNomeEmpresa (data) {
    return '( ' + data.codigo_pes + ' )' + ' ' + data.nomraz_pes
  }

  return (
    <>
      <DataListSimples
        value={produtoEmpresa}
        columns={columns}
        totalizadores={[
          {
            key: 'qtdAplicadaa',
            label: 'Aplicada'
          },
          { key: 'qtdAplicada', label: 'Total aplicada' },
          { key: 'qtdReservada', label: 'Total reservadas' },
          { key: 'qtdTransito', label: 'Total trânsito' },
          { key: 'qtdARetirar', label: 'Total á retirar' },
          { key: 'qtdEmPedido', label: 'Total em pedido' },
          { key: 'qtdBackOrder', label: 'Total B.O' },
          { key: 'qtdDisponivel', label: 'Total disponível' }
        ]}
        onSelect={() => {

        }}
      />
    </>
  )
}
