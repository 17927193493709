import React, { useState } from 'react'
import AppButton from '../../../../components/layout/AppButton'
import DataListSimples from '../../../../components/layout/DataListSimples/DataListSimples'
import { formatToCurrency } from '../../../../helpers/formaters'
import { createColumns } from '../../../../helpers/tableConfigs'
import ItemManutencaoModal from '../ItemManutencaoModal'

function ItensMan ({ form, edicaoHabilitada, updateValorTotalItensManutencao }) {
  const [itemManutencaoSelecionado, setItemManutencaoSelecionado] = useState(null)
  const [visibleItemManutencaoModal, setVisibleItemManutencaoModal] = useState(false)

  const columns = createColumns([
    {
      field: 'descri_itm',
      header: 'Item da Manutenção',
      width: '32%',
      alignText: 'text-left',
      headerClassName: 'text-left',
      customBody: formatDescricaoItem,
      sortable: false
    },
    {
      field: 'qtd_itm',
      header: 'Quantidade',
      width: '17%',
      customBody: formatQuantidade,
      sortable: false
    },
    {
      field: 'val_itm',
      header: 'Valor Unitário',
      width: '17%',
      customBody: formatValorUnitario,
      sortable: false
    },
    {
      field: 'valtot_itm',
      header: 'Valor Total',
      width: '17%',
      customBody: formatValorTotal,
      sortable: false
    },
    {
      field: 'descri_cim',
      header: 'Classificação',
      width: '17%',
      customBody: formatDescricaoClassificacaoItem,
      customClassName: formatDescricaoClassificacaoItemClassName,
      sortable: false
    }
  ])

  function formatQuantidade (data) {
    return data.qtd_itm + ' UN'
  }

  function formatDescricaoItem (data) {
    return data.descri_itm.toUpperCase()
  }

  function formatValorUnitario (data) {
    return formatToCurrency(data.val_itm)
  }

  function formatValorTotal (data) {
    return formatToCurrency(data.valtot_itm)
  }

  function formatDescricaoClassificacaoItem (data) {
    return data.descri_cim.toUpperCase()
  }

  function formatDescricaoClassificacaoItemClassName (data) {
    let color = 'light-green'

    if (data.codcim_itm === 3) {
      color = 'yellow'
    }

    return `colored-label ${color} fixed-length`
  }

  return (
    <div className="formgrid grid fluid">
      <div className="field col-12 flex justify-content-end">
        <AppButton
          label="Adicionar"
          icon="pi pi-plus"
          onClick={() => setVisibleItemManutencaoModal(true)}
          disabled={!edicaoHabilitada}
        />
      </div>
      <div className="field col-12">
        <DataListSimples
          value={form.values.itensManutencao}
          columns={columns}
          totalizadores={[{ key: 'valtot_itm', label: 'Valor total dos itens', formatFunction: formatToCurrency }]}
          selected={itemManutencaoSelecionado}
          onSelect={(e) => {
            setItemManutencaoSelecionado(e.value)
            setVisibleItemManutencaoModal(true)
          }}
        />
      </div>
      <ItemManutencaoModal
        visible={visibleItemManutencaoModal}
        onHide={() => {
          setItemManutencaoSelecionado(null)
          setVisibleItemManutencaoModal(false)
        }}
        manutencaoForm={form}
        edicaoHabilitada={edicaoHabilitada}
        itemManutencaoSelecionado={itemManutencaoSelecionado}
        updateValorTotalItensManutencao={updateValorTotalItensManutencao}
      />
    </div>
  )
}

export default ItensMan
