import React, { Component } from 'react'

class LegendaRow extends Component {
  render () {
    return (
      <div className="legenda-row" >{this.props.value}</div>
    )
  }
}

export default LegendaRow
