import BaseModel from '../../BaseModel'
//codigo_ven, tipcom_ven, percom_ven, ativo_ven, valfix_ven, perven_ven, perluc_ven, tipluc_ven
export class VendedorModel extends BaseModel {
  constructor (params = {}) {
    super()
    this.codigo_ven = params.codigo_ven || null
    this.tipcom_ven = params.tipcom_ven || null
    this.percom_ven = params.percom_ven || null
    this.ativo_ven = params.ativo_ven || null
    this.valfix_ven = params.valfix_ven || null
    this.perven_ven = params.perven_ven || null
    this.perluc_ven = params.perluc_ven || null
    this.tipluc_ven = params.tipluc_ven || null
    this.nomraz_pes = params.nomraz_pes || null
    this.codigo_pes = params.codigo_pes || null
  }
}
