import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { getStore } from '../../redux/store'

class AppSubmenu extends Component {
    static defaultProps = {
      className: null,
      items: null,
      onMenuItemClick: null,
      root: false
    }

    static propTypes = {
      className: PropTypes.string,
      items: PropTypes.array,
      onMenuItemClick: PropTypes.func,
      root: PropTypes.bool
    }

    constructor (props) {
      super(props)
      this.state = { activeIndex: null }
    }

    async componentDidMount () {
      const { items } = this.props
      if (items instanceof Promise) {
        try {
          const resolvedItems = await items
          this.setState({ items: resolvedItems })
        } catch (error) {
          console.error('Erro ao carregar os itens do menu:', error)
        }
      } else if (Array.isArray(items)) {
        this.setState({ items })
      }
    }

    onMenuItemClick (event, item, index) {
      if (item.disabled) {
        event.preventDefault()
        return true
      }

      //execute command
      if (item.command) {
        item.command({ originalEvent: event, item: item })
      }

      if (index === this.state.activeIndex)
        this.setState({ activeIndex: null })
      else
        this.setState({ activeIndex: index })

      if (this.props.onMenuItemClick) {
        this.props.onMenuItemClick({
          originalEvent: event,
          item: item
        })
      }
    }

    renderLinkContent (item) {
      let submenuIcon = item.items && <i className="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>
      let badge = item.badge ? <span className="menuitem-badge">{item.badge}</span> : <></>
      let subItem = item.subItem ? ' pl-5' : ''
      return (
        <React.Fragment>
          <i className={item.icon + subItem}>
            {item.reactIcon && (
              <item.reactIcon />
            )}
          </i>
          <span>{item.label}</span>
          {submenuIcon}
          {badge}
        </React.Fragment>
      )
    }

    renderLink (item, i) {
      // atualiza o contador da tela de consignados
      if (item.codigo_mew === 8) {
        item.badge = getStore().quantidadeConsignacoesPendentePagamento
      }
      // atualiza o contador da tela de aprovação
      if (item.codigo_mew === 9) {
        item.badge = getStore().quantidadeNegociacoesPendenteAprovacao
      }
      let content = this.renderLinkContent(item)
      if (item.to) {
        return (
          <NavLink activeClassName="active-route" to={item.to} onClick={(e) => this.onMenuItemClick(e, item, i)} exact target={item.target}>
            {content}
          </NavLink>
        )
      } else {
        return (
          <a href={item.url} onClick={(e) => this.onMenuItemClick(e, item, i)} target={item.target}>
            {content}
          </a>
        )
      }
    }

    render () {
      const { items } = this.state
      let menuItems = items && items.map((item, i) => {
        if (item.hasMenuAcesso) {
          let active = this.state.activeIndex === i
          let styleClass = classNames(item.badgeStyleClass, { 'active-menuitem': active && !item.to })

          return (
            <li className={styleClass} key={i}>
              {item.items && this.props.root === true && <div className='arrow'></div>}
              {this.renderLink(item, i)}
              <AppSubmenu items={item.items} onMenuItemClick={this.props.onMenuItemClick} />
            </li>
          )
        }
        return null
      })

      return menuItems ? <ul className={this.props.className}>{menuItems}</ul> : null
    }
}

export class AppMenu extends Component {
    static defaultProps = {
      menuSideBar: null,
      onMenuItemClick: null
    }

    static propTypes = {
      menuSideBar: PropTypes.array,
      onMenuItemClick: PropTypes.func
    }

    render () {
      return (
        <div className="layout-menu-container">
          <AppSubmenu items={this.props.menuSideBar} className="layout-menu"
            onMenuItemClick={this.props.onMenuItemClick} root={true} />
        </div>
      )
    }
}
