import * as queryString from 'query-string'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { showErrorMessage } from '../components/utils/Message'

const useList = (Model, Service, resource, primaryKey = 'id', rows = 20, opcaoSelecionada = 't') => {
  const history = useHistory()
  const [page, setPage] = useState(1)
  const [state, setState] = useState({
    visible: false,
    visibleConfirm: false,
    confirmDescription: '',
    confirmTitle: '',
    onConfirmCallback: () => cancelConfirm(),
    filterCollapsed: true,
    selected: Model,
    filter: Model,
    list: [],
    rows: rows,
    page: 1,
    first: 1,
    totalRecords: 0,
    totalPages: 0,
    opcaoSelecionada: opcaoSelecionada,
    meta: {}
  })

  const columns = null

  useEffect(() => {
    getAll(page)
    addEventListeners()
    resetDescricaoFiltro()
  }, [page])

  const addEventListeners = () => {
    if (!columns) return

    const campos = columns.map(column => column.key)

    for (const campo of campos) {
      const elemento = document.querySelector(`.${campo}`)

      if (!elemento || !campo) continue

      elemento.addEventListener('click', () => {
        const { filter } = state
        filter.ordemCampo = filter.ordemCampo === `${campo}|asc` ? `${campo}|desc` : `${campo}|asc`
        setState({ ...state, filter })
        onFilter()
      })
    }
  }

  const resetDescricaoFiltro = () => {
    const { filter } = state
    filter.descricaoFiltro = undefined
    setState({ ...state, filter })
  }

  const getAll = async (page) => {
    try {
      let response = await Service.getAll(toFilter(state.filter), page, state.rows)
      setRetorno(response)
    } catch (error) {
      console.error(error)
      showErrorMessage(error.message || 'Houve um erro ao listar os registros!')
    }
  }

  const onFilter = async (page) => {
    try {
      let response = await Service.filter(toFilter(state.filter), page, state.rows)
      setRetorno(response)
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao filtrar os registros!')
    }
  }

  const setRetorno = (response) => {
    setState({
      ...state,
      list: response.data,
      totalRecords: response.meta.total,
      totalPages: response.meta.last_page,
      meta: response.meta,
      ...response
    })
  }

  const toFilter = (obj) => {
    const validFields = Object
      .keys(obj)
      .filter(it => !!obj[it])
      .map(it => ({ [it]: obj[it] }))

    let fields = {}

    validFields.forEach(it => {
      fields = { ...it, ...fields }
    })

    return queryString.stringify(fields)
  }

  const onPageChange = async data => {
    const first = data.first
    const page = data.page + 1
    setPage(page)
    setState({ ...state, first, page })
    onFilter(page)
  }

  const onView = async (selected) => {
    history.push(`/${resource}/${selected[primaryKey]}`)
  }

  const handleChangeFilter = event => {
    const filter = { ...state.filter }
    filter[event.target.name] = event.target.value
    setState({ ...state, filter })
  }

  const onSelect = selected => {
    setState({ ...state, selected })
  }

  const onNew = () => {
    history.push(`/${resource}`)
  }

  const handleRowExpansion = (e) => {
    let { expandedRows } = state

    if (expandedRows && expandedRows[0] === e) {
      expandedRows = null
    } else {
      expandedRows = [e]
    }

    setState({ ...state, expandedRows })
  }

  const showConfirm = () => {
    setState({ ...state, visibleConfirm: true })
  }

  const showCustomConfirm = (title, description, onConfirmCallback) => {
    setState({ ...state, confirmTitle: title, confirmDescription: description, onConfirmCallback })
    showConfirm()
  }

  const cancelConfirm = () => {
    setState({ ...state, visibleConfirm: false })
  }

  return {
    state,
    page,
    onPageChange,
    onFilter,
    handleChangeFilter,
    onSelect,
    onNew,
    handleRowExpansion,
    showCustomConfirm,
    cancelConfirm,
    onView,
    getAll
  }
}

export default useList
