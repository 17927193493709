import { applyMiddleware, compose, createStore } from 'redux'
import axiosMiddleware from 'redux-axios-middleware'
import reducer from './reducer'
import { server } from '../helpers/server'

// Para utilizar o Redux Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = createStore(
  reducer,
  process.env.REACT_APP_STAGE === 'loc'
    ? composeEnhancers(applyMiddleware(axiosMiddleware(server())))
    : applyMiddleware(axiosMiddleware(server()))
)

export const dispatch = (action) => {
  store.dispatch(action)
}

export const getStore = () => {
  return store.getState()
}
