import { CorModel } from '../../../models/cadastro/veiculo/CorModel'
import ServiceBase from '../../base/ServiceBase'

export default class CorService {
  async get (id) {
    return await ServiceBase.getBase(`cor/${id}`)
  }

  async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`cores?page=${page}&perPage=${perPage}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`cores?${query}&page=${page}&perPage=${perPage}`)
  }

  async filterAutoComplete (query) {
    return await ServiceBase.getBase(`cores?${query}`, false)
  }

  async save (data) {
    const cor = new CorModel(data)
    return await ServiceBase.postBase('cor', cor.toMap())
  }

  async delete (id) {
    return await ServiceBase.deleteBase(`cor/${id}`)
  }

  static async filterCarros (query) {
    return await ServiceBase.getBase(`cores?${query}`)
  }

  static async getAllMarcas () {
    return await ServiceBase.getBase('cores/marcas', false)
  }

  static async getAllCoresDenatran () {
    return await ServiceBase.getBase('coresdenatran')
  }
}
