import React, { useState } from 'react'
import { AutoComplete } from 'primereact/autocomplete'
import { Fieldset } from 'primereact/fieldset'
import TextInput from '../../../../../components/inputs/TextInput'
import PessoaFisicaService from '../../../../../services/cadastro/pessoa/PessoaFisicaService'
import PessoaJuridicaService from '../../../../../services/cadastro/pessoa/PessoaJuridicaService'
import CommonHelper from '../../../../../helpers/CommonHelper'
import CEPService from '../../../../../services/cadastro/pessoa/CEPService'
import CidadesService from '../../../../../services/cadastro/pessoa/CidadesService'
import NumberInput from '../../../../../components/inputs/NumberInput'
import { showErrorMessage } from '../../../../../components/utils/Message'

const DadosProfissionaisPef = ({ form, edicaoHabilitada }) => {
  const [sugestoesProfissao, setSugestoesProfissao] = useState([])
  const [sugestoesEmpresa, setSugestoesEmpresa] = useState([])
  const [sugestoesMunicipioTrabalho, setSugestoesMunicipioTrabalho] = useState([])

  const sugerirProfissao = async () => {
    try {
      const profissoes = await PessoaFisicaService.filterProfissoes(
        `descri_pro=${form.values.profissao.descri_pro}`
      )

      setSugestoesProfissao(profissoes)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir as profissões!')
    }
  }

  const sugerirEmpresa = async () => {
    try {
      const pessoasJuridicas = await PessoaJuridicaService.filterAutoComplete(
        `descricaoFiltro=${form.values.empresaTrabalho?.nomraz_pes}`
      )

      setSugestoesEmpresa(pessoasJuridicas)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir as empresas!')
    }
  }

  const sugerirMunicipioTrabalho = async () => {
    try {
      const cidades = await CidadesService.filterAutocomplete(
        `description=${form.values.municipioTrabalho.description}`
      )

      setSugestoesMunicipioTrabalho(cidades)
    } catch (error) {
      showErrorMessageMessage(error.message || 'Ocorreu um erro inesperado ao sugerir o municipio!')
    }
  }

  const handleChangeProfissao = (e) => {
    form.setFieldValue('profissao.descri_pro', String(e.target.value))

    if (form.values.profissao?.descri_pro === '') {
      form.setFieldValue('profissao', {})
    }
  }

  const handleChangeEmpresa = (e) => {
    form.setFieldValue('empresaTrabalho.nomraz_pes', String(e.target.value).toUpperCase())

    if (form.values.empresaTrabalho.nomraz_pes === '') {
      form.setFieldValue('empresaTrabalho', {})
    }
  }

  const handleChangeCepTrabalho = async (value) => {
    try {
      const cep = value

      form.setFieldValue('ceptra_pef', cep)

      if (CommonHelper.clearDigitsSpecialChars(cep).length === 8) {
        const endereco = await CEPService.getEndereco(cep)

        form.setFieldValue('baitra_pef', endereco?.bairro)
        form.setFieldValue('endtra_pef', endereco?.logradouro)
        form.setFieldValue('municipioTrabalho', { id: Number(endereco.ibge), description: endereco.localidade })
      }
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar o endereço pelo CEP!')
    }
  }

  const handleChangeMunicipioTrabalho = (e) => {
    form.setFieldValue('municipioTrabalho.description', String(e.target.value))

    if (form.values.municipioTrabalho.description === '') {
      form.setFieldValue('municipioTrabalho', {})
    }
  }

  return (
    <div className="formgrid grid">
      <Fieldset legend={<b>Empresa de trabalho</b>} style={{ marginBottom: '20px', minWidth: '300px' }} className="col-12">
        <div className="formgrid grid">
          <div className="field col-12 md:col-6">
            <label className="label">Empresa</label>
            <AutoComplete
              field="nomraz_pes"
              value={form.values.empresaTrabalho.nomraz_pes && form.values.empresaTrabalho}
              minLength={3}
              suggestions={sugestoesEmpresa}
              completeMethod={sugerirEmpresa}
              onChange={handleChangeEmpresa}
              onSelect={(e) => form.setFieldValue('empresaTrabalho', e.value)}
              placeholder="Nome da empresa"
              disabled={!edicaoHabilitada}
              className="inputfield w-full"
            />
          </div>
          <div className="field col-12 md:col-6">
            <TextInput
              label="Nome"
              placeholder="Nome"
              name="nomtra_pef"
              value={form.values.nomtra_pef}
              onChange={form.handleChange}
              disabled={!edicaoHabilitada}
              className="inputfield w-full"
            />
          </div>
          <div className="field col-12 md:col-3">
            <TextInput
              label="CEP"
              placeholder="CEP"
              name="ceptra_pef"
              value={form.values.ceptra_pef}
              onChange={handleChangeCepTrabalho}
              disabled={!edicaoHabilitada}
              mask="99999-999"
              className="inputfield w-full"
            />
          </div>
          <div className="field col-12 md:col-6">
            <TextInput
              label="Endereço"
              placeholder="Endereço"
              name="endtra_pef"
              value={form.values.endtra_pef}
              onChange={form.handleChange}
              disabled={!edicaoHabilitada}
              className="inputfield w-full"
            />
          </div>
          <div className="field col-12 md:col-3">
            <NumberInput
              label="Número"
              placeholder="Número"
              name="numtra_pef"
              value={form.values.numtra_pef}
              onChange={(e) => form.setFieldValue('numtra_pef', e.value)}
              disabled={!edicaoHabilitada}
              className="inputfield w-full"
            />
          </div>
          <div className="field col-12 md:col-4">
            <TextInput
              label="Bairro"
              placeholder="Bairro"
              name="baitra_pef"
              value={form.values.baitra_pef}
              onChange={form.handleChange}
              disabled={!edicaoHabilitada}
              className="inputfield w-full"
            />
          </div>
          <div className="field col-12 md:col-4">
            <label className="label">Município</label>
            <AutoComplete
              field="description"
              value={form.values.municipioTrabalho.description && form.values.municipioTrabalho}
              minLength={4}
              suggestions={sugestoesMunicipioTrabalho}
              completeMethod={sugerirMunicipioTrabalho}
              onChange={handleChangeMunicipioTrabalho}
              onSelect={(e) => form.setFieldValue('municipioTrabalho', e.value)}
              placeholder="Município"
              disabled={!edicaoHabilitada}
              className="inputfield w-full"
            />
          </div>
          <div className="field col-12 md:col-4">
            <TextInput
              label="Telefone"
              placeholder="Telefone"
              name="teltra_pef"
              value={form.values.teltra_pef}
              onChange={(value) => form.setFieldValue('teltra_pef', value)}
              mask="(99) 99999-9999"
              disabled={!edicaoHabilitada}
              className="inputfield w-full"
            />
          </div>
        </div>
      </Fieldset>
      <div className="field col-12 md:col-6">
        <label className="label">Profissão</label>
        <AutoComplete
          field="descri_pro"
          value={form.values.profissao?.descri_pro && form.values.profissao}
          minLength={3}
          suggestions={sugestoesProfissao}
          completeMethod={sugerirProfissao}
          onChange={handleChangeProfissao}
          onSelect={(e) => form.setFieldValue('profissao', e.value)}
          placeholder="Profissão"
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-6">
        <TextInput
          label="Tempo de serviço"
          placeholder="Tempo de serviço"
          name="temser_pef"
          value={form.values.temser_pef}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-6">
        <NumberInput
          label="Renda mensal"
          placeholder="Renda mensal"
          name="renmes_pef"
          value={form.values.renmes_pef}
          onChange={(e) => form.setFieldValue('renmes_pef', e.value)}
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
          mode="decimal"
        />
      </div>
      <div className="field col-12 md:col-6">
        <TextInput
          label="Tempo de residência"
          placeholder="Tempo de residência"
          name="temres_pef"
          value={form.values.temres_pef}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-4">
        <TextInput
          label="Número CTPS"
          placeholder="Número CTPS"
          name="nuctps_pef"
          value={form.values.nuctps_pef}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-4">
        <TextInput
          label="Série CTPS"
          placeholder="Série CTPS"
          name="sectps_pef"
          value={form.values.sectps_pef}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-4">
        <TextInput
          label="PIS"
          placeholder="PIS"
          name="numpis_pef"
          value={form.values.numpis_pef}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
        />
      </div>
    </div>
  )
}

export default DadosProfissionaisPef
