export const CLEAR_STORE = 'app/sances/CLEAR_STORE'
export const SET_INICIAL_LOADED = 'app/sances/SET_INICIAL_LOADED'
export const SET_USER_AS_AUTENTICATED = 'app/sances/SET_USER_AS_AUTENTICATED'
export const SET_USER_AS_NOT_AUTENTICATED = 'app/sances/SET_USER_AS_NOT_AUTENTICATED'
export const TOGGLE_LOADING = 'app/sances/TOGGLE_LOADING'
export const START_LOADING = 'app/sances/START_LOADING'
export const STOP_LOADING = 'app/sances/STOP_LOADING'
export const SET_MENUSACESSO = 'app/sances/SET_MENUSACESSO'
export const SET_USUARIO_LOGADO = 'app/sances/SET_USUARIO_LOGADO'
export const SET_EMPRESA_LOGADA = 'app/sances/SET_EMPRESA_LOGADA'
export const SET_VEICULO_VENDA = 'app/sances/SET_VEICULO_VENDA'
export const REMOVE_VEICULO_VENDA = 'app/sances/REMOVE_VEICULO_VENDA'
export const SET_PESSOA_JURIDICA = 'app/sances/SET_PESSOA_JURIDICA'
export const REMOVE_PESSOA_JURIDICA = 'app/sances/REMOVE_PESSOA_JURIDICA'
export const SET_PESSOA_FISICA = 'app/sances/SET_PESSOA_FISICA'
export const REMOVE_PESSOA_FISICA = 'app/sances/REMOVE_PESSOA_FISICA'
export const SET_MARCA = 'app/sances/SET_MARCA'
export const REMOVE_MARCA = 'app/sances/REMOVE_MARCA'
export const SET_VEICULO = 'app/sances/SET_VEICULO'
export const REMOVE_VEICULO = 'app/sances/REMOVE_VEICULO'
export const INCREMENT_ACTIVE_LOADING_REQUESTS = 'app/sances/INCREMENT_ACTIVE_LOADING_REQUESTS'
export const DECREMENT_ACTIVE_LOADING_REQUESTS = 'app/sances/DECREMENT_ACTIVE_LOADING_REQUESTS'
export const ATUALIZAR_QUANTIDADE_CONSIGNACOES_PENDENTE_PAGAMENTO = 'app/sances/ATUALIZAR_QUANTIDADE_CONSIGNACOES_PENDENTE_PAGAMENTO'
export const ATUALIZAR_QUANTIDADE_NEGOCIACOES_PENDENTE_APROVACAO = 'app/sances/ATUALIZAR_QUANTIDADE_NEGOCIACOES_PENDENTE_APROVACAO'
export const GENERATE_MENU_SIDEBAR = 'app/sances/GENERATE_MENU_SIDEBAR'
