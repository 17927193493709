import React, { Component } from 'react'
import SelectInput from '../../../components/inputs/SelectInput'
import { formatDateTime } from '../../../helpers/formaters'
import { InputTextarea } from 'primereact/inputtextarea'
import Modal from '../../../components/utils/Modal'

class ExcluirComentarioModal extends Component {
  constructor (props) {
    super(props)
    this.handleChangeComentario = this.handleChangeComentario.bind(this)

    this.state = {
      comentario: {}
    }
  }

  handleChangeComentario (event) {
    const comentario = event.target.value
    this.setState({ comentario })
  }

  handleFormSubmit () {
    this.props.excluirComentario(this.state.comentario)
    this.props.hide()
  }

  render () {
    const { comentario } = this.state

    const comentarioTemplate = (option) => (
      <div>
        #{option.ordcom_com} {option.usuarioInsercao?.pessoa?.apelid_pes} ({formatDateTime(option.dahins_com)})
      </div>
    )

    return (
      <Modal
        onHide={this.props.hide}
        header="Excluir comentário"
        visible={true}
        modal={true}
        width="25"
        btnExcluir={() => this.handleFormSubmit()}
      >
        <div className="formgrid grid">
          <div className="field col-12">
            <SelectInput
              label="Comentário"
              value={comentario.codigo_com && comentario}
              options={this.props.comentarios}
              onChange={this.handleChangeComentario}
              optionLabel="ordcom_com"
              itemTemplate={comentarioTemplate}
              noFloatLabel
              className="inputfield w-full"
            />
          </div>
          <div className="field col-12">
            <InputTextarea
              value={comentario.coment_com}
              disabled
              className="inputfield w-full"
            />
          </div>
        </div>
      </Modal>
    )
  }
}

export default ExcluirComentarioModal
