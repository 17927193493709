import React, { useState } from 'react'

import { Column } from 'primereact/column'
import AppButton from '../../../../components/layout/AppButton'
import Container from '../../../../components/layout/Container'
import DataList from '../../../../components/layout/DataList'
import Modal from '../../../../components/utils/Modal'
import ResponsiveColumn from '../../../../components/utils/ResponsiveColumn'
import PessoaService from '../../../../services/cadastro/pessoa/PessoaService'
import UsuarioPermissaoReqModal from './UsuarioPermissaoReqModal'
const RequisicaoLiberacaoPendentesModal = ({ hide, visible, listReqPendentes }) => {
  const [expandedRows, setExpandedRows] = useState(null)
  const [requisicaoSelecionada, setRequisicaoSelecionada] = useState([])
  const [visibleUsuarioPermissao, setVisibleUsuarioPermissao] = useState(false)
  const [listaAprovadores, setListaAprovadores] = useState([])

  const rowExpansionTemplate = () => {
    return (
      <div className="" style={{ textAlign: 'right' }}>
        <AppButton label="Ver aprovadores" className="m-3" onClick={async () => {
          await getAprovadores()
        }} />
      </div>
    )
  }

  async function getAprovadores () {
    try {
      const aprovadores = await PessoaService.getAprovadoresReq(requisicaoSelecionada?.codper_req, requisicaoSelecionada?.codemp_req)
      setListaAprovadores(aprovadores)
      setVisibleUsuarioPermissao(state => !state)
    } catch (error) {
    }
  }

  const handleRowExpansion = (e) => {
    if (expandedRows && expandedRows[0] === e) {
      setExpandedRows(null)
    } else {
      setExpandedRows([e])
    }
  }

  return (
    <>
      <Modal
        header="Situação das requisições"
        width="70"
        visible={visible}
        modal={true}
        onHide={hide}
      >
        <Container>
          <DataList
            data={listReqPendentes}
            onSelect={(e) => { setRequisicaoSelecionada(e) }}
            totalRecords={0}
            first={0}
            rows={0}
            page={1}
            hidePaginator={true}
            rowExpansionTemplate={rowExpansionTemplate}
            showFilter={false}
            expandedRows={expandedRows}
            onRowClick={(e) => handleRowExpansion(e.data)}

          >
            <Column
              header="ORIGEM"
              body={(data) => {
                return <ResponsiveColumn
                  className="pl-2"
                  column="ORIGEM"
                  value={data?.origem_req}
                />
              }}
              headerClassName="HeaderDataList width-40"
              sortable={true}
              field="origem_req"
            />
            <Column
              header="REQUISIÇÃO"
              body={(data) => {
                return <ResponsiveColumn
                  className="pl-2"
                  column="REQUISIÇÃO"
                  value={data?.motivo_req}
                />
              }}
              headerClassName="HeaderDataList width-40"
              sortable={true}
              field="motivo_req"
            />
            <Column
              header="CLIENTE"
              body={(data) => {
                return <ResponsiveColumn
                  className="pl-2"
                  column="CLIENTE"
                  value={data?.nomcli_req}
                />
              }}
              headerClassName="HeaderDataList width-40"
              sortable={true}
              field="nomcli_req"
            />
            <Column
              header="EMPRESA"
              body={(data) => {
                return <ResponsiveColumn
                  className="pl-2"
                  column="EMPRESA"
                  value={data?.empnom_req}
                />
              }}
              headerClassName="HeaderDataList width-40"
              sortable={true}
              field="empnom_req"
            />
            <Column
              header="USUÁRIO"
              body={(data) => {
                return <ResponsiveColumn
                  className="pl-2"
                  column="USUÁRIO"
                  value={data?.reqnom_req}
                />
              }}
              headerClassName="HeaderDataList width-40"
              sortable={true}
              field="reqnom_req"
            />
          </DataList>
        </Container>
      </Modal>
      <UsuarioPermissaoReqModal
        visible={visibleUsuarioPermissao}
        hide={() => setVisibleUsuarioPermissao(false)}
        listaAprovadores={listaAprovadores}
      />
    </>

  )
}

export default RequisicaoLiberacaoPendentesModal
