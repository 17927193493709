import React from 'react';
import { Button } from 'primereact/button';
import List from '../../classes/List';
import { Column } from 'primereact/column';
import CondicaoPagamentoService from '../../services/faturamento/CondicaoPagamentoService';
import FormaCobrancaService from '../../services/cadastro/financeiro/FormaCobrancaService';
import Modal from '../../components/utils/Modal';
import TextInput from '../../components/inputs/TextInput';
import DateInput from '../../components/inputs/DateInput';
import { formatCurrencyToNumber, formatDate, formatDateToAmerican, formatDateToInputDate, formatToCurrency } from '../../helpers/formaters';
import { CondicaoPagamentoModel } from '../../models/cadastro/financeiro/CondicaoPagamentoModel';
import { showErrorMessage, showWarnMessage } from '../../components/utils/Message';
import Container from '../../components/layout/Container';
import DataList from '../../components/layout/DataList';
import PermissaoService from '../../services/cadastro/pessoa/PermissaoService';
import ResponsiveColumn from '../../components/utils/ResponsiveColumn';
import SelectInput from '../../components/inputs/SelectInput';
import CommonHelper from '../../helpers/CommonHelper';

class CondicaoPagamentoModal extends List {
  constructor(props) {
    super(
      props,
      CondicaoPagamentoModel,
      CondicaoPagamentoService,
      "condicaoPagamento",
      "codigo_cop",
      20
    );
    this.state = {
      ...this.state,
      filterCollapsed: true,
      selected: this.model,
      filter: this.model,
      visible: props.visible,
      edicaoHabilitada: props.edicaoHabilitada,
      valorEntrada: 0,
      dataEmissao: new Date(),
      totalOrcamento: props.totalOrcamento,
      perPage: 20,
      page: 1,
      descricaoFiltro: "",
      expandedRows: null,
      formasCobranca: {},
      permissaoUsarCondPagRestrita: false,
      indexParcela: null,
      parcelaSelecionada: {},
      exibirModalEditarParcela: false
    };
  }

  componentDidUpdate(prevProps) {
    const { visible, edicaoHabilitada } = this.props;

    if (prevProps.edicaoHabilitada !== edicaoHabilitada) {
      this.setState({ edicaoHabilitada });
    }

    if (prevProps.visible !== visible) {
      this.setState({ visible });

      if (visible && edicaoHabilitada) {
        this.resetDescricaoFiltro()
        this.getFormaCobranca()
        this.getListaCondicoesPagVenda()
        this.getPermissao63()
      }
    }
  }
  componentDidMount() {
    this.addEventListeners();
    this.resetDescricaoFiltro();
  }

  hideModalListarParcelas = () => {
    const { onHide } = this.props;
    this.setState({ visible: false });
    onHide();
  };

  getPermissao63 = async () => {
    const permiteUsarCondPagRestrita = await PermissaoService.getByCodigo('63')
    this.setState({ permissaoUsarCondPagRestrita: permiteUsarCondPagRestrita })
  }

  handleChangeValorEntrada = (event) => {
    const valorNumerico = formatCurrencyToNumber(event.target.value);
    if (valorNumerico > 0) {
      this.setState({ valorEntrada: valorNumerico });
    } else {
      this.setState({ valorEntrada: 0 });
    }
  };

  handleChangeDataEmissao = async (event) => {
    this.setState({ dataEmissao: event.target.value }, () => {
      this.getListaCondicoesPagVenda();
    });
  };

  handlePageChange = async (data) => {
    const page = data.page + 1;
    const first = data.first;
    this.setState({ first, page }, () => {
      this.getListaCondicoesPagVenda();
    });
  };

  getListaCondicoesPagVenda = async () => {
    if (!isNaN(this.props.totalOrcamento) && this.props.totalOrcamento > 0) {
      try {
        const data = {
          valorTotal: this.props.totalOrcamento,
          valorEntrada: this.state.valorEntrada,
          dataEmissao: formatDateToAmerican(this.state.dataEmissao)
        };

        let listaCondPag;
        if (this.state.filter.descricaoFiltro != undefined) {
          listaCondPag = await CondicaoPagamentoService.parcelarVendas(
            data,
            this.state.filter.descricaoFiltro,
            this.state.page,
            this.state.perPage
          );
        } else {
          listaCondPag = await CondicaoPagamentoService.parcelarVendas(
            data,
            null,
            this.state.page,
            this.state.perPage
          );
        }
        this.setRetorno(listaCondPag);
      } catch (e) {
        console.error(e);
        showErrorMessage(
          e.message ||
          "Ocorreu um erro inesperado ao buscar as condições de pagamento!"
        );
      }
    }
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.getListaCondicoesPagVenda();
    }
  };

  getFormaCobranca = async () => {
    const formasCobranca = await FormaCobrancaService.getAll();
    this.setState({ formasCobranca });
  };

  usarCondicaoPagamento = (condicaoPagamento) => {
    if (this.verificaPermissaoCondPagRestrita(condicaoPagamento)) {
      if (this.verificaSomaDasParcelas(condicaoPagamento)) {
        this.props.configurarTitulos(condicaoPagamento)
        this.hideModalListarParcelas();
      }
    } else {
      showWarnMessage("Usuário sem permissão para usar condição de pagamento restrita");
    }
  }

  verificaPermissaoCondPagRestrita = (condicaoPagamento) => {
    if (condicaoPagamento.restrito_cop && !this.state.permissaoUsarCondPagRestrita) {
      return false
    } else {
      return true
    }
  }

  verificaSomaDasParcelas = (condicaoPagamento) => {
    const valorTotalParcelas = condicaoPagamento.parcelas.reduce(
      (acumulador, parcela) => acumulador + parcela.valorParcela,
      0
    );
    if (CommonHelper.Round(valorTotalParcelas, 2) == CommonHelper.Round(condicaoPagamento.valorFinal, 2)) {
      return true
    } else {
      showWarnMessage("Soma das parcelas não confere com o valor total!");
      return false
    }
  }

  onSelectParcela = selected => {
    this.setState({ parcelaSelecionada: selected })
  }

  onEditParcela(data) {
    if (this.state.selected.peredi_cop && this.state.selected.peracr_cop == 0) {
      this.setState({ parcelaSelecionada: data, exibirModalEditarParcela: true, indexParcela: data.index });
    } else {
      showWarnMessage("Condição de pagamento não permite edição ou possui acréscimo de juros");
    }
  }

  handleChangeValorParcela = (event) => {
    const valorNumerico = formatCurrencyToNumber(event.target.value);
    if (valorNumerico > 0) {
      this.setState({
        parcelaSelecionada: {
          ...this.state.parcelaSelecionada,
          valorParcela: valorNumerico
        }
      });
    } else {
      this.setState({
        parcelaSelecionada: {
          ...this.state.parcelaSelecionada,
          valorParcela: 0
        }
      });
    }
  };

  handleChangeDataParcela = (event) => {
    this.setState({
      parcelaSelecionada: {
        ...this.state.parcelaSelecionada,
        vencimento: event.target.value,
        isDataAlterada: true
      }
    });
  };

  handleFormaPagamentoChange = (event) => {
    this.setState({
      parcelaSelecionada: {
        ...this.state.parcelaSelecionada,
        formaPagamento: event.target.value.codigo_foc
      }
    });
  };

  selectFormaCobranca = (data) => {
    const formaPagamentoOption = this.state.formasCobranca?.find(
      (forma) => forma.codigo_foc === data.formaPagamento
    );
    return formaPagamentoOption
  }

  onConfirmarParcelaEditada = () => {
    const { selected, indexParcela, parcelaSelecionada } = this.state;
    const novaListaParcelas = selected.parcelas;
    novaListaParcelas[indexParcela] = parcelaSelecionada

    this.setState({
      selected: {
        ...selected,
        parcelas: novaListaParcelas,
      },
      exibirModalEditarParcela: false,
      parcelaSelecionada: {},
    });


  }

  hideModalEditarParcela = () => {
    this.setState({ exibirModalEditarParcela: false, parcelaSelecionada: {} });
  };

  render() {
    const { visible, edicaoHabilitada, valorEntrada, dataEmissao, exibirModalEditarParcela, parcelaSelecionada } = this.state
    const rowExpansionTemplate = (data) => {
      return (
        <div className={"row-expansion flex justify-content-center"}>
          <div className={"md:col-10 mt-2"}>
            <Container>
              <DataList
                data={data.parcelas}
                selected={parcelaSelecionada}
                onSelect={this.onSelectParcela}
                rows={data.parcelas.length}
                onRowClick={(e) => this.onEditParcela(e)}
                hidePaginator={true}
                totalRecords={data.parcelas.length}
                first={1}
                page={1}
                showFilter={false}
                responsive
              >
                <Column
                  header="Ordem"
                  body={(data) => (
                    <ResponsiveColumn
                      className="pl-2"
                      column="Ordem"
                      value={data.ordem}
                    />
                  )}
                  headerClassName="HeaderDataList"
                  sortable={true}
                  field="descri_cop"
                />
                <Column
                  header="Vencimento"
                  body={(data) => (
                    <ResponsiveColumn
                      className="pl-2"
                      column="Vencimento"
                      value={formatDate(data.vencimento)}
                    />
                  )}
                  headerClassName="HeaderDataList"
                  sortable={true}
                  field="vencimento"
                />
                <Column
                  header="Valor"
                  body={(data) => (
                    <ResponsiveColumn
                      className="pl-2"
                      column="Valor"
                      value={formatToCurrency(data.valorParcela)}
                    />
                  )}
                  sortable={true}
                  headerClassName="HeaderDataList "
                  field="valorParcela"
                />
                <Column
                  header="Forma de pagamento"
                  body={(data) => {
                    const foc = this.state.formasCobranca?.find(
                      (forma) => forma.codigo_foc === data.formaPagamento
                    )
                    return <ResponsiveColumn
                      className="pl-2"
                      column="Forma de pagamento"
                      value={foc?.descri_foc}
                    />
                  }}
                  headerClassName="HeaderDataList width-40"
                  sortable={true}
                  field="formaPagamento"
                />
              </DataList>
              <Button
                label="Usar esta condição"
                icon="pi pi-check"
                onClick={() => this.usarCondicaoPagamento(data)}
                disabled={!edicaoHabilitada}
                className="mb-4 mt-2"
              />
            </Container>
          </div>
        </div>
      );
    };

    return (
      <>
        <Modal
          header="Condição de pagamento"
          width={65}
          visible={visible}
          onHide={this.hideModalListarParcelas}
        >
          <div className="formgrid grid fluid">
            <div className="field col-12 md:col-2 d-flex justify-content-start ml-auto">
              <TextInput
                name="valorEntrada"
                label="Entrada"
                placeholder="Valor de entrada"
                value={formatToCurrency(valorEntrada)}
                onChange={this.handleChangeValorEntrada}
                onBlur={this.getListaCondicoesPagVenda}
                disabled={!edicaoHabilitada}
                onKeyDown={this.handleKeyDown}
              />
            </div>
            <div className="field col-12 md:col-2 d-flex justify-content-center mr-auto">
              <DateInput
                label="Data de emissão"
                name="dataEmissao"
                value={dataEmissao}
                onChange={this.handleChangeDataEmissao}
                disabled={!edicaoHabilitada}
              />
            </div>

            <Container>
              <DataList
                data={this.state.list}
                selected={this.state.selected}
                rows={this.state.perPage}
                onSelect={this.onSelect}
                onRowClick={(e) => this.handleRowExpansion(e.data)}
                hidePaginator={false}
                totalRecords={this.state.totalRecords}
                first={this.state.first}
                onPage={this.handlePageChange}
                page={this.state.page}
                showFilter={true}
                filterPlaceholder="Pesquisar condição de pagamento"
                filterName="descricaoFiltro"
                responsive={true}
                filterOnChange={this.handleChangeFilter}
                onFilter={this.getListaCondicoesPagVenda}
                expandedRows={this.state.expandedRows}
                rowExpansionTemplate={rowExpansionTemplate}
              >
                <Column
                  header="Descrição"
                  body={(data) => (
                    <ResponsiveColumn
                      className="pl-2"
                      column="Descrição"
                      value={data.descri_cop}
                    />
                  )}
                  headerClassName="width-35"
                  sortable={true}
                  field="descri_cop"
                />
                <Column
                  header="1º vencimento"
                  body={(data) => (
                    <ResponsiveColumn
                      className="pl-2"
                      column="1º vencimento"
                      value={formatDate(data.dataPrimeiroVencimento)}
                    />
                  )}
                  sortable={true}
                  field="dataPrimeiroVencimento"
                />
                <Column
                  header="Entrada"
                  body={(data) => (
                    <ResponsiveColumn
                      className="pl-2"
                      column="Entrada"
                      value={formatToCurrency(data.valorEntrada)}
                    />
                  )}
                  sortable={true}
                  field="valorEntrada"
                />
                <Column
                  header="Parcelas"
                  body={(data) => (
                    <ResponsiveColumn
                      className="pl-2"
                      column="Parcelas"
                      value={data.resumoParcelas}
                    />
                  )}
                  sortable={true}
                  field="resumoParcelas"
                />
                <Column
                  header="Valor final"
                  body={(data) => (
                    <ResponsiveColumn
                      className="pl-2"
                      column="Valor final"
                      value={formatToCurrency(data.valorFinal)}
                    />
                  )}
                  sortable={true}
                  field="valorFinal"
                />
              </DataList>
            </Container>
          </div>
        </Modal>

        {this.state.exibirModalEditarParcela && (
          <Modal
            header="Editar parcela"
            width={40}
            visible={exibirModalEditarParcela}
            onHide={this.hideModalEditarParcela}
            btnSalvarLabel="Salvar"
            btnSalvar={this.onConfirmarParcelaEditada}
          >
            <div className="formgrid grid fluid">
              <div className="field col-12 md:col-4 d-flex justify-content-start ml-auto">
                <TextInput
                  name="valorParcela"
                  label="Valor da parcela"
                  placeholder="Valor"
                  value={formatToCurrency(parcelaSelecionada.valorParcela)}
                  onChange={this.handleChangeValorParcela}
                  disabled={!edicaoHabilitada}
                />
              </div>
              <div className="field col-12 md:col-4 d-flex justify-content-center mr-auto">
                <DateInput
                  label="Data do vencimento"
                  name="dataVencimento"
                  value={formatDateToInputDate(parcelaSelecionada.vencimento)}
                  onChange={this.handleChangeDataParcela}
                  disabled={!edicaoHabilitada}
                />
              </div>
              <div className="field col-12 md:col-8 d-flex justify-content-center mx-auto">
                <SelectInput
                  label="Forma de pagamento"
                  value={this.selectFormaCobranca(this.state.parcelaSelecionada)}
                  options={this.state.formasCobranca}
                  onChange={(e) => this.handleFormaPagamentoChange(e)}
                  name="formaCobranca"
                  optionLabel="descri_foc"
                  dataKey="codigo_foc"
                  placeholder="- Selecione a forma de pagamento -"
                  noFloatLabel
                  disabled={!this.state.edicaoHabilitada}
                  className="inputfield w-full"
                />
              </div>
            </div>
          </Modal>
        )}


      </>
    );
  }
}

export default CondicaoPagamentoModal;
