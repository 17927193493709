import { formatDateToAmerican, formatDateToInputDate } from '../../../helpers/formaters'
import { postTituloManutencaoDTO } from './TituloManutencaoDTO'

export const getManutencaoDTO = (data = {}) => {
  let titulosManutencao = data.titulosManutencao

  if (titulosManutencao) {
    titulosManutencao = data.titulosManutencao.map(tituloManutencao => postTituloManutencaoDTO(tituloManutencao))
  }

  return {
    codigo_man: data.codigo_man || null,
    datsai_man: data.datsai_man ? formatDateToInputDate(data.datsai_man) : new Date(),
    datpre_man: data.datpre_man ? formatDateToInputDate(data.datpre_man) : null,
    datret_man: data.datret_man ? formatDateToInputDate(data.datret_man) : null,
    codclm_man: data.codclm_man || null,
    codtdr_man: data.codtdr_man || null,
    codneg_man: data.codneg_man || null,
    codstm_man: data.codstm_man || null,
    veiculo: data.veiculo || {},
    fornecedor: data.fornecedor || {},
    itensManutencao: data.itensManutencao || [],
    titulosManutencao: titulosManutencao || []
  }
}

export const postManutencaoDTO = (data) => {
  return {
    codigo_man: data.codigo_man || null,
    datsai_man: data.datsai_man ? formatDateToAmerican(data.datsai_man) : null,
    datpre_man: data.datpre_man ? formatDateToAmerican(data.datpre_man) : null,
    datret_man: data.datret_man ? formatDateToAmerican(data.datret_man) : null,
    codvei_man: data.veiculo.codigo_vei,
    codpes_man: data.fornecedor.codigo_pes,
    codclm_man: data.codclm_man || null,
    codtdr_man: data.codtdr_man || null,
    codneg_man: data.codneg_man || null,
    itensManutencao: data.itensManutencao || null,
    titulosManutencao: data.titulosManutencao || null
  }
}
