import ServiceBase from '../base/ServiceBase'
import { loc, prod, dev } from '../../config'

export default class LoginService {
  async login (data) {
    return await ServiceBase.postBase('login', data)
  }

  static async redefinirSenha (email) {
    const url = await this.getUrlAmbiente()
    return await ServiceBase.postBase('redefinirSenha', { email, url })
  }

  static async getAcessoDatabases (data) {
    return await ServiceBase.postBase('acessodatabases', data)
  }

  static async getUrlAmbiente () {
    let config

    switch (process.env.REACT_APP_STAGE) {
    case 'loc':
      config = loc
      break
    case 'prod':
      config = prod
      break
    default:
      config = dev
    }

    return config.clientInfo.APP_URL
  }
}
