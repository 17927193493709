import axios from 'axios'
import { authToken, credentials, logout } from './auth'
import { loc, dev, prod } from '../config'
import { dispatch, getStore } from '../redux/store'
import { decrementActiveLoadingRequests, incrementActiveLoadingRequests, startLoading, stopLoading } from '../redux/actions'

// const config = process.env.REACT_APP_STAGE === 'prod' ? prod : dev;

let config = loc

switch (process.env.REACT_APP_STAGE) {
case 'loc':
  config = loc
  break
case 'prod':
  config = prod
  break
default:
  config = dev
}

export const API_URL = config.apiGateway.URL

export function server (loading = true, type = 'json') {
  let connection = axios.create({
    baseURL: API_URL,
    responseType: type,
    headers: {
      Authorization: 'Bearer ' + authToken(),
      Credentials: credentials(),
      LoadingState: loading
    }
  })

  connection.interceptors.request.use(sucessHttpRequest, errorHttpRequest)

  connection.interceptors.response.use(sucessHttpResponse, errorHttpResponse)

  return connection
}

function sucessHttpRequest (config) {
  processHttpRequest(config)

  return config
}

function errorHttpRequest (error) {
  processHttpRequest(error)

  return Promise.reject(error)
}

function processHttpRequest (config) {
  if (config.headers['LoadingState']) {
    dispatch(incrementActiveLoadingRequests())

    if (getStore().activeLoadingRequests > 0) {
      startLoadingIfRequestTakesTooLong()
    }
  }
}

function startLoadingIfRequestTakesTooLong () {
  setTimeout(() => {
    if (getStore().activeLoadingRequests > 0) {
      dispatch(startLoading())
    }
  }, 200)
}

function sucessHttpResponse (response) {
  processHttpResponse(response)

  return response
}

function errorHttpResponse (error) {
  processHttpResponse(error)

  if (error.response?.status === 401) {
    logout()
  }

  return Promise.reject(error)
}

function processHttpResponse (response) {
  if (response.config.headers['LoadingState']) {
    dispatch(decrementActiveLoadingRequests())

    if (getStore().activeLoadingRequests === 0) {
      dispatch(stopLoading())
    }
  }
}
