import ServiceBase from '../../base/ServiceBase'

export default class ConveniadoService {
  static async verificaConveniadoValido (codigoConvenio, codigoConveniado) {
    return (await ServiceBase.getBase(`pessoas/conveniado/${codigoConvenio}/${codigoConveniado}`)).url
  }

  static async verificaClienteConveniado (codigoCliente) {
    return await ServiceBase.getBase(`pessoas/verificarClienteConveniado/${codigoCliente}`)
  }
}
