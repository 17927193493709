import React, { useEffect, useRef, useState } from 'react'
import AutoCompleteInput from '../../../../components/inputs/AutoCompleteInput'
import CheckboxInput from '../../../../components/inputs/CheckboxInput'
import TextInput from '../../../../components/inputs/TextInput'
import { showErrorMessage, showWarnMessage } from '../../../../components/utils/Message'
import PreferencesButton from '../../../../components/utils/PreferencesButton'
import TablePreferences from '../../../../components/utils/TablePreferences'
import { getGrupoProdutoDTO } from '../../../../dtos/cadastro/produto/GrupoProdutoDTO'
import { getProdutoDTO } from '../../../../dtos/cadastro/produto/ProdutoDTO'
import { getMarcaDTO } from '../../../../dtos/cadastro/veiculo/MarcaDTO'
import CommonHelper from '../../../../helpers/CommonHelper'
import { formatCurrencyToNumber, formatDecimal, formatDecimalToNumber, formatPercentageToNumber, formatToCurrency, formatToInteger, formatToPercentage } from '../../../../helpers/formaters'
import { getTableConfig } from '../../../../helpers/tableConfigs'
import PermissaoService from '../../../../services/cadastro/pessoa/PermissaoService'
import GrupoProdutoService from '../../../../services/cadastro/produto/GrupoProdutoService'
import ProdutoService from '../../../../services/cadastro/produto/ProdutoService'
import MarcaService from '../../../../services/cadastro/veiculo/MarcaService'
import ConfigService from '../../../../services/configuracao/ConfigService'
import PecaBalcaoService from '../../../../services/vendas/orcamentoBalcao/PecaBalcaoService'
import DadosProdutoDasOutrasEmpresas from './DadosProdutoDasOutrasEmpresasModal'
import PrecosEmpresasModal from './PrecosEmpresasModal'

function DadosPecaBalcaoOpv ({
  form, orcamentoBalcaoForm, edicaoHabilitada, camposObrigatorios, onHide, atualizaDescontoPeca, calculaValorTotalPeca, getDadosPrecoProduto,
  permitirDescontoByTipoPreco, setProdutoSelecionado, pecaBalcaoSelecionada
}) {
  const [nomePrecoPersonalizado1, setNomePrecoPersonalizado1] = useState('')
  const [nomePrecoPersonalizado2, setNomePrecoPersonalizado2] = useState('')
  const [nomePrecoPersonalizado3, setNomePrecoPersonalizado3] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [permiteQuantidadeDecimal, setPermitirQuantidadeDecimal] = useState(false)
  const [permissao45ItensSemEstoque, setPermissao45ItensSemEstoque] = useState(false)
  const [permissao197AlterarDescontoQtd, setPermissao197AlterarDescontoQtd] = useState(false)
  const [itensComEstoque, setItensComEstoque] = useState(false)
  const [apenasDisponiveis, setApenasDisponiveis] = useState(false)
  const [filtrosDefault, setFiltrosDefault] = useState({})
  const [visiblePreferencesModal, setVisiblePreferencesModal] = useState(false)

  const [sugestaoProdutos, setSugestaoProdutos] = useState([])
  const [permitirQuantidadeFracionada, setPermitirQuantidadeFracionada] = useState(false)
  const [permitirDesconto, setPermitirDesconto] = useState(true)
  const [produtoParaVisualizarDados, setProdutoParaVisualizarDados] = useState(null)
  const [visibleDadosOutrasEmpresasModal, setVisibleDadosOutrasEmpresasModal] = useState(false)
  const [visiblePrecosEmpresasModal, setVisiblePrecosEmpresasModal] = useState(false)
  const [sugestaoMarcas, setSugestaoMarcas] = useState([])
  const [sugestaoGrupo, setSugestaoGrupo] = useState([])
  const inputRef = useRef(null)

  useEffect(async () => {
    const nomePrecoPersonalizado1 = await ConfigService.getValor(3091) || 'Personalizado 1'
    const nomePrecoPersonalizado2 = await ConfigService.getValor(3092) || 'Personalizado 2'
    const nomePrecoPersonalizado3 = await ConfigService.getValor(3093) || 'Personalizado 3'
    const permissao45 = await PermissaoService.getByCodigo(45)
    const permissao197 = await PermissaoService.getByCodigo(197)
    setPermissao45ItensSemEstoque(permissao45)
    setPermissao197AlterarDescontoQtd(permissao197)
    setNomePrecoPersonalizado1(nomePrecoPersonalizado1)
    setNomePrecoPersonalizado2(nomePrecoPersonalizado2)
    setNomePrecoPersonalizado3(nomePrecoPersonalizado3)
    setFiltrosDefault({ ativo: true, apenasPecas: true, somenteComEstoque: !permissao45 || itensComEstoque, apenasDisponiveis: apenasDisponiveis })
  }, [])

  useEffect(async () => {
    await setPermitirDescontoByTipoPreco()

    if ((form.values.usapreqtd_peo === 1 || form.values.usapreqtd_peo === true) && !permissao197AlterarDescontoQtd) {
      setPermitirDesconto(false)
    }

    if (form.values.codtpr_peo === 6) {
      setPermitirDesconto(false)
    }

    setPermitirQuantidadeFracionada(form.values.produto?.unidade?.fracio_uni)
  }, [orcamentoBalcaoForm.values.tipoPreco.codigo_tpr, form.values.codtpr_peo, form.values.produto?.unidade?.fracio_uni, form.values.usapreqtd_peo, permissao197AlterarDescontoQtd])

  useEffect(() => {
    setFiltrosDefault({ ativo: true, apenasPecas: true, somenteComEstoque: !permissao45ItensSemEstoque || itensComEstoque, apenasDisponiveis: apenasDisponiveis })
  }, [apenasDisponiveis, itensComEstoque])

  async function setPermitirDescontoByTipoPreco () {
    const permite = await permitirDescontoByTipoPreco()
    if (permite) {
      setPermitirDesconto(true)
    } else {
      setPermitirDesconto(false)
      if (form.values.usapreqtd_peo === 0 || form.values.usapreqtd_peo === false) {
        handleChangePorcentagemDesconto({ target: { value: '0' } })
      }
    }
  }

  async function sugerirProdutos () {
    try {
      const produtos = await ProdutoService.filterSugestao(`apenasPecas=true&ativo=true&descricaoFiltro=${form.values.produto.descri_pro}${!permissao45ItensSemEstoque ? `&somenteComEstoque=${!permissao45ItensSemEstoque}` : ''}`)
      if (produtos?.data.length <= 0) {
        showWarnMessage('Produto não encontrado!')
      }
      setSugestaoProdutos(produtos.data)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um produto!')
    }
  }

  async function onSelectProduto (e) {
    try {
      let produto = e.value
      let unidadePro = await ProdutoService.getUnidade(produto.coduni_pro)
      produto.unidade = unidadePro
      setProdutoSelecionado(produto)

      if (produto.unidade?.fracio_uni) {
        form.setFieldValue('qtd_peo', 1.000)
        setPermitirQuantidadeDecimal(true)
      } else {
        form.setFieldValue('qtd_peo', 1)
        setPermitirQuantidadeDecimal(false)
      }

      const codEmp = orcamentoBalcaoForm.values.codemp_opv
      const codTipoPreco = orcamentoBalcaoForm.values.tipoPreco.codigo_tpr

      await PecaBalcaoService.persistencia(produto.codigo_pro, codTipoPreco)

      const dadosPrecoProduto = await getDadosPrecoProduto(produto, form.values.qtd_peo, codEmp, codTipoPreco)

      await form.setFieldValue('produto', produto)
      await form.setFieldTouched('produto.codigo_pro')
      await preencherDadosPecaPreco(produto, dadosPrecoProduto)
      if (inputRef.current) {
        inputRef.current.focus()
      }
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao verificar os dados do produto!')
      return
    }
  }

  function handleChangeProduto (event) {
    form.setFieldValue('produto', event.target.value)
    form.setFieldValue('produto.descri_pro', event.target.value)
  }

  function handleChangeCurrency (event) {
    form.setFieldValue(event.target.name, formatCurrencyToNumber(event.target.value))
  }

  async function definirUsaPrecoQuantidade (valorPorcentagemDesconto) {
    if (form.values.codpec_peo > 0) {
      // Se permite alterar desconto por quantidade
      if (permissao197AlterarDescontoQtd) {
        // Busca % de desconto por quantidade da API
        const codEmp = orcamentoBalcaoForm.values.codemp_opv
        const codTipoPreco = orcamentoBalcaoForm.values.tipoPreco.codigo_tpr
        const dadosPrecoProduto = await ProdutoService.getDadosPrecoByTipoPreco(form.values.codpec_peo, form.values.qtd_peo, codEmp, codTipoPreco)

        // Se o % de desconto por quantidade for igual ao informado
        if (dadosPrecoProduto.perdesQtd === valorPorcentagemDesconto) {
          form.setFieldValue('usapreqtd_peo', true)
        } else {
          form.setFieldValue('usapreqtd_peo', false)
        }
      }
    }
  }

  function handleChangePorcentagemDesconto (event) {
    let value = event.target.value

    const isValorMaiorQueUmChar = value.length !== 1
    if (isValorMaiorQueUmChar && !value.includes('%')) {
      value = value.slice(0, value.length - 1)
    }
    const valorPorcentagemDesconto = formatPercentageToNumber(value)
    atualizaDescontoPecaForm(valorPorcentagemDesconto)

    form.setFieldValue('perdes_peo', valorPorcentagemDesconto)

    definirUsaPrecoQuantidade(valorPorcentagemDesconto)
  }

  function handleChangeDesconto (event) {
    const valorTotalBrutoPecaBalcao = form.values.qtd_peo * form.values.valuni_peo
    const valorDesconto = formatCurrencyToNumber(event.target.value)

    if (valorTotalBrutoPecaBalcao !== 0) {
      const valorPorcentagemDesconto = valorDesconto / (valorTotalBrutoPecaBalcao / 100)
      form.setFieldValue('perdes_peo', valorPorcentagemDesconto)
      definirUsaPrecoQuantidade(valorPorcentagemDesconto)
    }

    form.setFieldValue(event.target.name, valorDesconto)
  }

  async function onBlurQuantidade () {
    if (permitirQuantidadeFracionada) {
      if (form.values.qtd_peo < 0.001) {
        form.setFieldValue('qtd_peo', 0.001)
      }
    } else {
      if (form.values.qtd_peo < 1) {
        form.setFieldValue('qtd_peo', 1)
      }
    }
    form.setFieldTouched('qtd_peo')
    try {
      if (form.values.produto.codigo_pro) {
        const codEmp = orcamentoBalcaoForm.values.codemp_opv
        const codTipoPreco = orcamentoBalcaoForm.values.tipoPreco.codigo_tpr
        const dadosPrecoProduto = await getDadosPrecoProduto(form.values.produto, form.values.qtd_peo, codEmp, codTipoPreco)

        await preencherDadosPecaPreco(form.values.produto, dadosPrecoProduto)
      }
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao verificar os dados do produto!')
      return
    }
  }

  async function preencherDadosPecaPreco (produto, dadosPrecoProduto) {
    form.setFieldValue('valuni_peo', dadosPrecoProduto.preco)
    form.setFieldValue('valbru_peo', dadosPrecoProduto.preco)
    form.setFieldValue('codtpr_peo', dadosPrecoProduto.codigoTipoPreco)
    form.setFieldValue('venpro_peo', dadosPrecoProduto.isVendaPromocional)
    form.setFieldValue('cusuni_peo', dadosPrecoProduto.cusmedliq_pre)

    // Define o desconto apenas se retornou desconto por quantidade ou se o produto atualmente está indicado como usando preço por quantidade
    if (dadosPrecoProduto.usaPreQtd || (form.values.usapreqtd_peo === 1 || form.values.usapreqtd_peo === true)) {
      form.setFieldValue('perdes_peo', dadosPrecoProduto.perdesQtd)
      await atualizaDescontoPecaForm(dadosPrecoProduto.perdesQtd)
    }

    form.setFieldValue('usapreqtd_peo', dadosPrecoProduto.usaPreQtd)
    form.setFieldValue('venliq_peo', produto.liquid_pro !== null ? produto.liquid_pro : false)
    form.setFieldValue('siguni_peo', produto.abrevi_uni)
    form.setFieldValue('descri_peo', produto.descri_pro)
    form.setFieldValue('refpro_peo', produto.refere_pro)

    form.setFieldValue('iscan_peo', 0)
    form.setFieldValue('valacr_peo', 0.00)
    form.setFieldValue('valpro_peo', 0.00)
    form.setFieldValue('totacrcop_peo', 0.00)
    form.setFieldValue('usucan_peo', null)
    form.setFieldValue('dahcan_peo', null)
    form.setFieldValue('codppr_peo', null)
  }

  async function atualizaDescontoPecaForm (percentualDesconto) {
    const valorTotalBrutoPecaBalcao = form.values.qtd_peo * form.values.valuni_peo

    if (valorTotalBrutoPecaBalcao !== 0) {
      const valorDesconto = (valorTotalBrutoPecaBalcao * percentualDesconto) / 100
      form.setFieldValue('valdes_peo', valorDesconto)

      const valtotPeo = form.values.qtd_peo * form.values.valuni_peo - valorDesconto
      form.setFieldValue('valtot_peo', valtotPeo)
    }
  }

  async function onBlurValorUnitario () {
    if (form.values.valbru_peo > form.values.valuni_peo) {
      const permissaoParaReduzirPrecoSemDesconto = await PermissaoService.getByCodigo('141')
      if (!permissaoParaReduzirPrecoSemDesconto) {
        showWarnMessage('Para reduzir o valor utilize a opção desconto!')
        form.setFieldValue('valuni_peo', form.values.valbru_peo)
        form.setFieldTouched('valuni_peo')
      }
    }
  }

  useEffect(async () => {
    calculaValorTotalPeca(form.values)
  }, [form.values.qtd_peo, form.values.valuni_peo, form.values.perdes_peo, form.values.valdes_peo])

  useEffect(async () => {
    // atualizaDesconto()
    atualizaDescontoPeca(form.values)
  }, [form.values.qtd_peo, form.values.valuni_peo])

  const columns = [
    {
      key: 'codigo_pro',
      label: 'Código',
      width: '100px',
      pesquisavel: false,
      enabled: true,
      frozen: true
    },
    { key: 'refere_pro', campo: 'refere_pro', nome: 'Referência', label: 'Referência', width: '150px', pesquisavel: false, enabled: true, style: { width: '150px', textAlign: 'center', overflowWrap: 'break-word' } },
    { key: 'reffab_fpr', campo: 'reffab_fpr', nome: 'Ref. Fábrica ', label: 'Ref. Fábrica', width: '150px', pesquisavel: false, enabled: true, style: { width: '150px', textAlign: 'center', overflowWrap: 'break-word' } },
    { key: 'descri_pro', campo: 'descri_pro', nome: 'Descrição', label: 'Descrição', width: '600px', pesquisavel: true, enabled: true },
    { key: 'descri_mar', campo: 'descri_mar', nome: 'Marca', label: 'Marca', width: '150px', pesquisavel: false, enabled: true },
    { key: 'descri_grp', campo: 'descri_grp', nome: 'Grupo', label: 'Grupo', width: '150px', pesquisavel: false, enabled: true },
    {
      key: 'estatu_pre',
      campo: 'estatu_pre',
      nome: 'Estoque',
      label: 'Estoque',
      width: '150px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'qtdapl_pre',
      campo: 'qtdapl_pre',
      nome: 'Aplicadas',
      label: 'Aplicadas',
      width: '150px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'qtdres_pre',
      campo: 'qtdres_pre',
      nome: 'Reservadas',
      label: 'Reservadas',
      width: '150px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'qtdtra_pre',
      campo: 'qtdtra_pre',
      nome: 'Em trânsito',
      label: 'Em trânsito',
      width: '150px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'qtddisponivel',
      campo: 'qtddisponivel',
      nome: 'Qtd. disponível',
      label: 'Qtd. disponível',
      width: '150px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'preco_pre',
      campo: 'preco_pre',
      nome: 'Preço varejo',
      label: 'Preço varejo',
      format: formatToCurrency,
      width: '150px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'prepro_ppr',
      campo: 'prepro_ppr',
      nome: 'Preço promocional',
      label: 'Preço promocional',
      format: formatToCurrency,
      width: '150px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'preata_pre',
      campo: 'preata_pre',
      nome: 'Atacado',
      label: 'Atacado',
      format: formatToCurrency,
      width: '150px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'preeco_pre',
      campo: 'preeco_pre',
      nome: 'E-commerce',
      label: 'E-commerce',
      format: formatToCurrency,
      width: '150px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'preper1_pre',
      campo: 'preper1_pre',
      nome: nomePrecoPersonalizado1,
      label: nomePrecoPersonalizado1,
      format: formatToCurrency,
      width: '200px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'preper2_pre',
      campo: 'preper2_pre',
      nome: nomePrecoPersonalizado2,
      label: nomePrecoPersonalizado2,
      format: formatToCurrency,
      width: '200px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'preper3_pre',
      campo: 'preper3_pre',
      nome: nomePrecoPersonalizado3,
      label: nomePrecoPersonalizado3,
      format: formatToCurrency,
      width: '200px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'enderecamento',
      campo: 'enderecamento',
      nome: 'Endereçamento',
      label: 'Endereçamento',
      width: '200px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'obs_pro',
      campo: 'obs_pro',
      nome: 'Observação',
      format: getObservacao,
      label: 'Observação',
      width: '600px',
      pesquisavel: true,
      enabled: true
    },
    {
      key: 'codbar_pro',
      campo: 'codbar_pro',
      nome: 'Código de barras',
      label: 'Código de barras',
      width: '200px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'codean_pro',
      campo: 'codean_pro',
      nome: 'Código EAN',
      label: 'Código EAN',
      width: '200px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'buttonPreco',
      campo: 'buttonPreco',
      nome: 'Preços',
      label: 'Preços',
      width: '250px',
      pesquisavel: false,
      enabled: true,
      buttonClassName: 'pi pi-info-circle info-selection',
      buttonOnClick: async (data) => {
        setProdutoParaVisualizarDados(data)
        setVisiblePrecosEmpresasModal(true)
      }
    },
    {
      key: 'button',
      campo: 'button',
      nome: 'Dados das outras empresas',
      label: 'Dados das outras empresas',
      width: '250px',
      pesquisavel: false,
      enabled: true,
      buttonClassName: 'pi pi-info-circle info-selection',
      buttonOnClick: async (data) => {
        setProdutoParaVisualizarDados(data)
        setVisibleDadosOutrasEmpresasModal(true)
      }
    }
  ]

  function getObservacao (data) {
    return data.slice(0, 200)
  }

  const SugestaoTemplateMarca = (data) => {
    return <span><b>{data.codigo_mar}</b> - {data.descri_mar}</span>
  }
  const SugestaoTemplateGrupo = (data) => {
    return <span><b>{data.codigo_grp}</b> - {data.descri_grp}</span>
  }
  function ProdutoExpandedFiltersTemplate (props) {
    const handleSomenteItensEstoque = () => {
      props.changeFilter({
        ...props.filter,
        somenteComEstoque: !itensComEstoque
      })
      setItensComEstoque(!itensComEstoque)
    }
    const handleApenasDisponiveis = () => {
      props.changeFilter({
        ...props.filter,
        apenasDisponiveis: !apenasDisponiveis
      })
      setApenasDisponiveis((prevState) => !prevState)
    }
    const sugerirMarcas = async () => {
      try {
        const marcas = await MarcaService.filterSugestao(`descricaoFiltro=${props.filter.marca?.descri_mar}`)
        setSugestaoMarcas(marcas.data)
      } catch (error) {
        showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir uma marca!')
      }
    }
    const sugerirGrupo = async () => {
      try {
        const grupo = await GrupoProdutoService.filterSugestao(`descricaoFiltro=${props.filter.grupo?.descri_grp}`)
        setSugestaoGrupo(grupo.data)
      } catch (error) {
        showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um grupo!')
      }
    }
    return (
      <>
        <div className="w-full optionTable" >
          <PreferencesButton onClick={() => setVisiblePreferencesModal(true)} />
        </div>
        <div className="field col-14 md:col-6">
          <AutoCompleteInput
          //AutoComplete
            label="Marca"
            name="marca.codigo_mar"
            field="descri_mar"
            value={props.filter.marca?.descri_mar}
            placeholder="Nome da marca"
            onSelect={(e) => props.changeFilter({
              ...props.filter,
              marca: e.value,
              codigo_mar: e.value?.codigo_mar
            })}
            suggestions={sugestaoMarcas}
            completeMethod={sugerirMarcas}
            onChange={(e) => props.changeFilter({
              ...props.filter,
              marca: {
                descri_mar: e.value
              }
            })}
            camposObrigatorios={camposObrigatorios}
            itemTemplate={SugestaoTemplateMarca}
            selected={form.values.marca}
            className='z-50'
            //FiltroModal
            filtrotitle="Pesquisa de marca"
            service={MarcaService}
            model={getMarcaDTO}
            panelStyle={{ width: `${CommonHelper.isDesktop() ? '300px' : ''}`, left: `${CommonHelper.isDesktop() ? '607px' : ''}` }}
            primarykeyname="codigo_pro"
            horizontalScroll={CommonHelper.isDesktop()}
            columns={[
              { campo: 'codigo_mar', nome: 'Código' },
              { campo: 'descri_mar', nome: 'Descrição' }
            ]}
          />
        </div>
        <div className="field col-14 md:col-6">
          <AutoCompleteInput
          //AutoComplete
            label="Grupo"
            name="grupo.codigo_grp"
            field="descri_mar"
            value={props.filter.grupo?.descri_grp}
            placeholder="Nome do grupo"
            onSelect={(e) => props.changeFilter({
              ...props.filter,
              grupo: e.value,
              codigo_grp: e.value?.codigo_grp
            })}
            suggestions={sugestaoGrupo}
            completeMethod={sugerirGrupo}
            onChange={(e) => props.changeFilter({
              ...props.filter,
              grupo: {
                descri_grp: e.value
              }
            })}
            camposObrigatorios={camposObrigatorios}
            itemTemplate={SugestaoTemplateGrupo}
            selected={form.values.marca}
            className='z-50'
            //FiltroModal
            filtrotitle="Pesquisa de grupo"
            service={GrupoProdutoService}
            model={getGrupoProdutoDTO}
            panelStyle={{ width: `${CommonHelper.isDesktop() ? '300px' : ''}`, left: `${CommonHelper.isDesktop() ? '607px' : ''}` }}
            primarykeyname="codigo_pro"
            horizontalScroll={CommonHelper.isDesktop()}
            columns={[
              { campo: 'codigo_grp', nome: 'Código' },
              { campo: 'descri_grp', nome: 'Descrição' }
            ]}
          />
        </div>
        <div className="field col-12 md:col-4">
          <TextInput
            label="Referência"
            placeholder="Referência do produto"
            value={props.filter.referencia}
            className="inputfield w-full"
            onChange={(e) => props.changeFilter({
              ...props.filter,
              referencia: e.target.value
            })}
          />
        </div>
        <div className="field col-12 md:col-4">
          <TextInput
            label="Referência de fábrica"
            placeholder="Referência de fábrica do produto"
            value={props.filter.referenciaFabrica}
            className="inputfield w-full"
            onChange={(e) => {
              props.changeFilter({
                ...props.filter,
                referenciaFabrica: e.target.value
              })
            }
            }
          />
        </div>
        <div className="field col-12 md:col-4">
          <TextInput
            label="Código de barras/EAN"
            placeholder="Código de barras/EAN"
            value={props.filter.codigoBarrasEAN}
            className="inputfield w-full"
            onChange={(e) => {
              props.changeFilter({
                ...props.filter,
                codigoBarrasEAN: e.target.value
              })
            }
            }
          />
        </div>
        <div className="md:flex col-12 md:col-span-4">
          <div className="field col-12 md:col-4">
            <CheckboxInput label={'Somente itens com estoque'}
              value={itensComEstoque}
              checked={itensComEstoque || !permissao45ItensSemEstoque}
              disabled={!permissao45ItensSemEstoque}
              onChange={handleSomenteItensEstoque} />
          </div>
          <div className="field col-12 md:col-4">
            <CheckboxInput label={'Somente disponíveis'}
              value={apenasDisponiveis}
              checked={apenasDisponiveis}
              onChange={handleApenasDisponiveis} />
          </div>
        </div>
      </>
    )
  }

  const getActiveColumnsKeys = (columns, tableName) => {
    const preferenciasAtuais = getTableConfig(tableName)
    if (preferenciasAtuais) {
      return getActiveColumnsKeysByTablePreferences(preferenciasAtuais)
    }

    return getDefaultActiveColumnsKeys(columns)
  }

  function getActiveColumnsKeysByTablePreferences (preferenciasAtuais) {
    const activeColumns = preferenciasAtuais.filter(preferencia => preferencia.enabled)
    return activeColumns.map(activeColumn => activeColumn.key)
  }

  const getDefaultActiveColumnsKeys = (columns) => {
    const activeColumnsKeys = []

    columns.forEach(column => {
      const { enabled = true } = column

      if (enabled)
        activeColumnsKeys.push(column.key)
    })

    return activeColumnsKeys
  }

  const getColunasHabilitadas = (columns, tableName) => {
    try {
      const activeColumnsKeys = getActiveColumnsKeys(columns, tableName)
      const colunas = []
      activeColumnsKeys.forEach(activeColumnKey => {
        const foundColumn = columns.find(column => column.key === activeColumnKey)
        if (foundColumn) {
          colunas.push(foundColumn)
        }
      })
      if (colunas.length === 0) {
        return columns
      } else {
        return colunas
      }
    } catch {
      localStorage.removeItem('tablesConfig')
    }
  }

  function getDescricaoPeca () {
    let descricaoPeca = form.values.produto.descri_pro
    let referencaPeca = form.values.produto.refere_pro

    if (referencaPeca) {
      if (typeof descricaoPeca !== 'object') {
        return `${descricaoPeca} - (${referencaPeca})`
      }
    } else {
      return typeof descricaoPeca !== 'object' && descricaoPeca
    }
  }

  const SugestaoTemplate = (data) => (
    <span><b>{data.refere_pro}</b> - {data.descri_pro}</span>
  )

  return (
    <div className="formgrid grid md:justify-content-center flex justify-center">
      {/* <div className='w-full md:flex col-12 md:col-12 '> */}
      <div className="field col-12 md:col-8 w-full">
        <AutoCompleteInput
          //AutoComplete
          label="Peças"
          name="produto.codigo_pro"
          field="descri_pro"
          value={form.values.produto?.descri_pro && getDescricaoPeca()}
          placeholder="Nome da peça"
          disabled={!edicaoHabilitada}
          onSelect={async (e) => await onSelectProduto(e)}
          suggestions={sugestaoProdutos}
          completeMethod={sugerirProdutos}
          onChange={handleChangeProduto}
          camposObrigatorios={camposObrigatorios}
          form={form}
          itemTemplate={SugestaoTemplate}
          //FiltroModal
          filtrotitle="Pesquisa de peças"
          filtersDefault={filtrosDefault}
          expandedFiltersTemplate={ProdutoExpandedFiltersTemplate}
          service={ProdutoService}
          model={getProdutoDTO}
          panelStyle={{ width: `${CommonHelper.isDesktop() ? '300px' : ''}`, left: `${CommonHelper.isDesktop() ? '607px' : ''}` }}
          primarykeyname="codigo_pro"
          horizontalScroll={CommonHelper.isDesktop()}
          columns={getColunasHabilitadas(columns, 'produtos')}
          visibleFiltroModal={pecaBalcaoSelecionada ? false : true}
          full
        />
      </div>
      <TablePreferences
        title="Preferências da tabela de peças"
        tableName="produtos"
        columns={columns}
        visible={visiblePreferencesModal}
        hide={() => setVisiblePreferencesModal(false)}
      />
      {/* </div> */}
      <div className='w-full md:flex'>

        <div className="field col-12 md:col-6 ">
          <TextInput
            name="qtd_peo"
            label="Quantidade"
            placeholder="Quantidade do produto"
            value={!permitirQuantidadeFracionada ? form.values.qtd_peo : formatDecimal(form.values.qtd_peo, 3)}
            onChange={(e) => form.setFieldValue('qtd_peo', !permitirQuantidadeFracionada ? formatToInteger(e.target.value) : formatDecimalToNumber(e.target.value, 3))}
            onBlur={onBlurQuantidade}
            disabled={!edicaoHabilitada}
            camposObrigatorios={camposObrigatorios}
            form={form}
            referencia={inputRef}
          />
        </div>
        <div className="field col-12 md:col-6 ">
          <TextInput
            name="valuni_peo"
            label="Valor unitário"
            placeholder="Valor das despesas"
            value={formatToCurrency(form.values.valuni_peo)}
            onChange={handleChangeCurrency}
            onBlur={onBlurValorUnitario}
            disabled={!edicaoHabilitada}
            camposObrigatorios={camposObrigatorios}
            form={form}
          />
        </div>
      </div>
      <div className='w-full md:flex'>

        <div className="field col-12 md:col-6">
          <TextInput
            name="perdes_peo"
            label="Desconto (%)"
            value={formatToPercentage(form.values.perdes_peo)}
            onChange={handleChangePorcentagemDesconto}
            disabled={!permitirDesconto || !edicaoHabilitada}
            camposObrigatorios={camposObrigatorios}
            form={form}
          />
        </div>
        <div className="field col-12 md:col-6">
          <TextInput
            name="valdes_peo"
            label="Desconto"
            value={formatToCurrency(form.values.valdes_peo)}
            onChange={handleChangeDesconto}
            disabled={!permitirDesconto || !edicaoHabilitada}
            camposObrigatorios={camposObrigatorios}
            form={form}
          />
        </div>
      </div>
      <div className='w-full md:flex'>
        <div className="field col-12 md:col-6">
          <TextInput
            name="valtot_peo"
            label="Valor total"
            placeholder="Valor das despesas"
            value={formatToCurrency(form.values.valtot_peo)}
            onChange={handleChangeCurrency}
            disabled
            camposObrigatorios={camposObrigatorios}
            form={form}
          />
        </div>
      </div>
      <DadosProdutoDasOutrasEmpresas
        visible={visibleDadosOutrasEmpresasModal}
        onHide={() => setVisibleDadosOutrasEmpresasModal(false)}
        produto={produtoParaVisualizarDados}
      />
      <PrecosEmpresasModal
        visible={visiblePrecosEmpresasModal}
        onHide={() => setVisiblePrecosEmpresasModal(false)}
        produto={produtoParaVisualizarDados}
      />
    </div>
  )
}

export default DadosPecaBalcaoOpv
