import ServiceBase from '../../base/ServiceBase'

export default class ModeloAutoGestorService {
  static async getAllModelosAG () {
    return await ServiceBase.getBase('modelos/autogestor/all')
  }

  static async filter () {
    return await ServiceBase.getBase(`modelos/autogestor/lista?descri_mod=${query}`)
  }

  static async getAllModelosAGByMarca (codMarcaAg) {
    return await ServiceBase.getBase(`modelos/autogestor/all/${codMarcaAg}`)
  }
}
