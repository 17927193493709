import ServiceBase from '../../base/ServiceBase'

export default class ProdutoService {
  async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`produtos?page=${page}&perPage=${perPage}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`produtos?${query}&page=${page}&perPage=${perPage}`)
  }

  static async getDadosPrecoByTipoPreco (codPro, qtdPro, codEmp, codTipoPreco) {
    return await ServiceBase.getBase(`produto/getDadosPrecoByTipoPreco?codPro=${codPro}&qtdPro=${qtdPro}&codEmp=${codEmp}&codTipoPreco=${codTipoPreco}`)
  }

  static async getPrecoPromocao (codPro, codEmp) {
    return await ServiceBase.getBase(`produto/getPromocao?codPro=${codPro}&codEmp=${codEmp}`)
  }

  static async getConsultaEstoque (codPro, codEmp, aRetirar, aplicada, reservada) {
    return await ServiceBase.getBase(`produto/consultaEstoque?codPro=${codPro}&codEmp=${codEmp}&aRetirar=${aRetirar}&aplicada=${aplicada}&reservada=${reservada}`)
  }

  static async filterByDescricao (descricao) {
    return await ServiceBase.getBase(`produtos?descri_pro=${descricao}`)
  }

  static async filterSugestao (descricao) {
    return await ServiceBase.getBase(`produtos?${descricao}&page=${1}&perPage=${1000}`)
  }

  static async getQuantidadeDisponivelProduto (codPro, codEmp) {
    return await ServiceBase.getBase(`produto/getQuantidadeDisponivel/${codPro}/${codEmp}`)
  }

  static async getEnderecoEstoqueProduto (codPro, codEmp) {
    return (await ServiceBase.getBase(`produto/getEnderecoEstoqueProduto/${codPro}/${codEmp}`)).localizacao
  }

  static async getUnidade (codigoUnidade) {
    return await ServiceBase.getBase(`unidade/${codigoUnidade}`)
  }

  static async getPrecoEmpresaQtd (query, page, perPage) {
    return await ServiceBase.getBase(`precoQtd?${query}&page=${page}&perPage=${perPage}`)
  }
}
