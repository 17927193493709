import React from 'react'
import { Crud } from '../../../classes/_Crud'
import Container from '../../../components/layout/Container'
import Page from '../../../components/layout/Page'
import { getNegociacaoDTO } from '../../../dtos/vendas/negociacao/NegociacaoDTO'
import NegociacaoForm from '../../../forms/vendas/negociacao/negociacao/NegociacaoForm/NegociacaoForm'
import NegociacaoService from '../../../services/vendas/negociacao/NegociacaoService'

class Negociacao extends Crud {
  constructor (props) {
    super(props, getNegociacaoDTO, NegociacaoService, 'negociacoes', 'codigo_neg')
  }

  render () {
    const { model, dataLoaded, isNew } = this.state
    return (
      <Page>
        <Container>
          {(dataLoaded || isNew) &&
            <NegociacaoForm
              isNew={isNew}
              model={model}
            />
          }
        </Container>
      </Page>
    )
  }
}

export default Negociacao
