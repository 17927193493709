import { AutoComplete } from 'primereact/autocomplete'
import { Fieldset } from 'primereact/fieldset'
import React, { useState } from 'react'
import NumberInput from '../../../components/inputs/NumberInput'
import TextInput from '../../../components/inputs/TextInput'
import { showErrorMessage } from '../../../components/utils/Message'
import CommonHelper from '../../../helpers/CommonHelper'
import CEPService from '../../../services/cadastro/pessoa/CEPService'
import CidadesService from '../../../services/cadastro/pessoa/CidadesService'

const DadosEnderecoPes = ({ form, edicaoHabilitada }) => {
  const [sugestoesMunicipioCobranca, setSugestoesMunicipioCobranca] = useState([])
  const [sugestoesMunicipioComercial, setSugestoesMunicipioComercial] = useState([])

  const sugerirMunicipioComercial = async () => {
    try {
      const cidades = await CidadesService.filterAutocomplete(
        `description=${form.values.municipioComercial.description}`
      )

      setSugestoesMunicipioComercial(cidades)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um municipio comercial!')
    }
  }

  const sugerirMunicipioCobranca = async () => {
    try {
      const cidades = await CidadesService.filterAutocomplete(
        `description=${form.values.municipioCobranca.description}`
      )

      setSugestoesMunicipioCobranca(cidades)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um municipio de cobrança!')
    }
  }

  const handleChangeMunicipioComercial = (e) => {
    form.setFieldValue('municipioComercial.description', String(e.target.value))

    if (form.values.municipioComercial?.description === '') {
      form.setFieldValue('municipioComercial', {})
      form.setFieldValue('muncom_pes', null)
    }
  }

  const handleChangeMunicipioCobranca = (e) => {
    form.setFieldValue('municipioCobranca.description', String(e.target.value))

    if (form.values.municipioCobranca?.description === '') {
      form.setFieldValue('municipioCobranca', {})
      form.setFieldValue('muncob_pes', null)
    }
  }

  const handleChangeCepComercial = async (value) => {
    try {
      const cep = value
      form.setFieldValue('cepcom_pes', cep)

      if (CommonHelper.clearDigitsSpecialChars(cep).length === 8) {
        const endereco = await CEPService.getEndereco(cep)
        form.setFieldValue('barcom_pes', endereco?.bairro)
        form.setFieldValue('endcom_pes', endereco?.logradouro)
        form.setFieldValue('municipioComercial', { id: Number(endereco.ibge), description: endereco.localidade })
      }
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar o endereço pelo CEP!')
    }
  }

  const handleChangeCepCobranca = async (value) => {
    try {
      const cep = value
      form.setFieldValue('cepcob_pes', cep)

      if (CommonHelper.clearDigitsSpecialChars(cep).length === 8) {
        const endereco = await CEPService.getEndereco(cep)

        form.setFieldValue('barcob_pes', endereco?.bairro)
        form.setFieldValue('endcob_pes', endereco?.logradouro)
        form.setFieldValue('municipioCobranca', { id: Number(endereco.ibge), description: endereco.localidade })
      }
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar o endereço pelo CEP!')
    }
  }

  return (
    <div className="grid">
      <Fieldset legend={<b>Comercial</b>} className="col-12" style={{ marginBottom: '20px', marginTop: '10px', minWidth: '300px' }}>
        <div className="formgrid grid">
          <div className="field col-12 md:col-2 disabled-input">
            <TextInput
              label="CEP"
              placeholder="CEP"
              name="cepcom_pes"
              value={form.values.cepcom_pes}
              onChange={handleChangeCepComercial}
              disabled={!edicaoHabilitada}
              mask="99999-999"
              className="inputfield w-full"
            />
          </div>
          <div className="field col-12 md:col-10 disabled-input">
            <TextInput
              label="Endereço"
              placeholder="Endereço"
              name="endcom_pes"
              value={form.values.endcom_pes}
              onChange={form.handleChange}
              disabled={!edicaoHabilitada}
              className="inputfield w-full"
            />
          </div>
          <div className="field col-12 md:col-2 disabled-input">
            <NumberInput
              label="Número"
              placeholder="Número"
              name="numcom_pes"
              value={form.values.numcom_pes}
              onChange={(e) => form.setFieldValue('numcom_pes', e.value)}
              disabled={!edicaoHabilitada}
              className="inputfield w-full"
              mode="decimal"
            />
          </div>
          <div className="field col-12 md:col-4 disabled-input">
            <TextInput
              label="Complemento"
              placeholder="Complemento"
              name="comcom_pes"
              value={form.values.comcom_pes}
              onChange={form.handleChange}
              disabled={!edicaoHabilitada}
              className="inputfield w-full"
            />
          </div>
          <div className="field col-12 md:col-3 disabled-input">
            <TextInput
              label="Bairro"
              placeholder="Bairro"
              name="barcom_pes"
              value={form.values.barcom_pes}
              onChange={form.handleChange}
              disabled={!edicaoHabilitada}
              className="inputfield w-full"
            />
          </div>
          <div className="field col-12 md:col-3 disabled-input">
            <label className="label">Município</label>
            <AutoComplete
              field="description"
              value={form.values.municipioComercial?.description && form.values.municipioComercial}
              minLength={4}
              suggestions={sugestoesMunicipioComercial}
              completeMethod={sugerirMunicipioComercial}
              onChange={handleChangeMunicipioComercial}
              onSelect={(e) => form.setFieldValue('municipioComercial', e.value)}
              placeholder="Município"
              disabled={!edicaoHabilitada}
              className="inputfield w-full"
            />
          </div>
        </div>
      </Fieldset>
      <Fieldset legend={<b>Cobrança</b>} className="col-12" style={{ marginBottom: '10px', minWidth: '300px' }}>
        <div className="formgrid grid">
          <div className="field col-12 md:col-2 disabled-input">
            <TextInput
              label="CEP"
              placeholder="CEP"
              name="cepcob_pes"
              value={form.values.cepcob_pes}
              onChange={handleChangeCepCobranca}
              disabled={!edicaoHabilitada}
              mask="99999-999"
              className="inputfield w-full"
            />
          </div>
          <div className="field col-12 md:col-10 disabled-input">
            <TextInput
              label="Endereço"
              placeholder="Endereço"
              name="endcob_pes"
              value={form.values.endcob_pes}
              onChange={form.handleChange}
              disabled={!edicaoHabilitada}
              className="inputfield w-full"
            />
          </div>
          <div className="field col-12 md:col-2 disabled-input">
            <NumberInput
              label="Número"
              placeholder="Número"
              name="numcob_pes"
              value={form.values.numcob_pes}
              onChange={(e) => form.setFieldValue('numcob_pes', e.value)}
              disabled={!edicaoHabilitada}
              className="inputfield w-full"
            />
          </div>
          <div className="field col-12 md:col-4 disabled-input">
            <TextInput
              label="Complemento"
              placeholder="Complemento"
              name="comcob_pes"
              value={form.values.comcob_pes}
              onChange={form.handleChange}
              disabled={!edicaoHabilitada}
              className="inputfield w-full"
            />
          </div>
          <div className="field col-12 md:col-3 disabled-input">
            <TextInput
              label="Bairro"
              placeholder="Bairro"
              name="barcob_pes"
              value={form.values.barcob_pes}
              onChange={form.handleChange}
              disabled={!edicaoHabilitada}
              className="inputfield w-full"
            />
          </div>
          <div className="field col-12 md:col-3 disabled-input">
            <label className="label">Município</label>
            <AutoComplete
              field="description"
              value={form.values.municipioCobranca?.description && form.values.municipioCobranca}
              minLength={4}
              suggestions={sugestoesMunicipioCobranca}
              completeMethod={sugerirMunicipioCobranca}
              onChange={handleChangeMunicipioCobranca}
              onSelect={(e) => form.setFieldValue('municipioCobranca', e.value)}
              placeholder="Município"
              disabled={!edicaoHabilitada}
              className="inputfield w-full"
            />
          </div>
        </div>
      </Fieldset>
    </div>
  )
}

export default DadosEnderecoPes
