import React, { useState } from 'react'
import AppButton from '../../../../../components/layout/AppButton'
import DataListForm from '../../../../../components/layout/DataListForm/DataListForm'
import CommonHelper from '../../../../../helpers/CommonHelper'
import ProcuradorModal from '../ProcuradorModal'

const ProcuradoresNeg = ({ form, edicaoHabilitada }) => {
  const [visibleProcuradorModal, setVisibleDocumentoModal] = useState(false)
  const [selectedProcurador, setSelectedProcurador] = useState({})

  const handleSelectRow = (selected) => {
    setSelectedProcurador(selected)
    setVisibleDocumentoModal(true)
  }

  const onHide = () => {
    setVisibleDocumentoModal(false)
    setSelectedProcurador({})
  }
  return (
    <>
      <div className="formgrid grid fluid justify-content-center">
        <div className={'field col-12 md:col-8 grid justify-content-' + (CommonHelper.isDesktop() ? 'end' : 'center')}>
          <AppButton
            type="button"
            className="inputfield"
            label=" Adicionar procurador "
            icon="pi pi-plus"
            onClick={() => setVisibleDocumentoModal(true)}
            disabled={!edicaoHabilitada}
          />
        </div>
      </div>
      <div className="field col-12 md:col-12 grid justify-content-center no-margin no-padding">
        <div className={'field col-12 md:col-' + (CommonHelper.isDesktop() ? '8' : '12 no-padding')}>
          <DataListForm
            value={form.values.procurador?.procuradores || form.values.procurador}
            emptyMessage="Nenhum procurador indicado."
            onRowSelect={(e) => handleSelectRow(e.data)}
            columns={[
              {
                header: 'Procurador',
                field: 'nomraz_pes',
                className: 'text-left pl-4'
              }
            ]}
          />
        </div>
      </div>
      <ProcuradorModal
        visible={visibleProcuradorModal}
        hide={() => onHide()}
        formNeg={form}
        veiculoNve={form.values.negociacaoVeiculo}
        selected={selectedProcurador}
        edicaoHabilitada={edicaoHabilitada}
      />
    </>
  )
}

export default ProcuradoresNeg
