import React, { useState } from 'react'

import { Column } from 'primereact/column'
import AppButton from '../../../../components/layout/AppButton'
import Container from '../../../../components/layout/Container'
import DataList from '../../../../components/layout/DataList'
import Modal from '../../../../components/utils/Modal'
import ResponsiveColumn from '../../../../components/utils/ResponsiveColumn'
import PessoaService from '../../../../services/cadastro/pessoa/PessoaService'
import UsuarioPermissaoReqModal from './UsuarioPermissaoReqModal'
const RequisicaoLiberacaoRecusadasModal = ({ hide, visible, listReqRecusada }) => {
  const [expandedRows, setExpandedRows] = useState(null)
  const [visibleUsuarioPermissao, setVisibleUsuarioPermissao] = useState(false)
  const [requisicaoSelecionada, setRequisicaoSelecionada] = useState([])
  const [listaAprovadores, setListaAprovadores] = useState([])

  function formatSituacao (data) {
    const situacao = { 1: 'Pendente', 2: 'Aprovada', 3: 'Recusada' }
    return situacao[data.codsuq_req]
  }

  const rowExpansionTemplate = () => {
    return (
      <div className="" style={{ textAlign: 'right' }}>
        <AppButton label="Ver aprovadores" className="m-3" onClick={async () => {
          await getAprovadores()
        }} />

      </div>
    )
  }

  async function getAprovadores () {
    try {
      const aprovadores = await PessoaService.getAprovadoresReq(requisicaoSelecionada?.codper_req, requisicaoSelecionada?.codemp_req)
      setListaAprovadores(aprovadores)
      setVisibleUsuarioPermissao(state => !state)
    } catch (error) {
    }
  }

  const handleRowExpansion = (e) => {
    if (expandedRows && expandedRows[0] === e) {
      setExpandedRows(null)
    } else {
      setExpandedRows([e])
    }
  }

  return (
    <>

      <Modal
        header="Situação das requisições"
        width="70"
        visible={visible}
        modal={true}
        onHide={hide}
      >
        <Container>

          <DataList
            data={listReqRecusada}
            totalRecords={0}
            first={0}
            rows={0}
            page={0}
            rowExpansionTemplate={rowExpansionTemplate}
            expandedRows={expandedRows}
            hidePaginator={true}
            showFilter={false}
            onRowClick={(e) => handleRowExpansion(e.data)}
            onSelect={(e) => { setRequisicaoSelecionada(e) }}

          >
            <Column
              header="Requisição"
              body={(data) => {
                return <ResponsiveColumn
                  className="pl-2"
                  column="Requisição"
                  value={data?.motivo_req}
                />
              }}
              headerClassName="HeaderDataList width-40"
              sortable={true}
              field="motivo_req"
            />
            <Column
              header="Situação"
              body={(data) => {
                return <ResponsiveColumn
                  className="pl-2"
                  column="Situação"
                  value={formatSituacao(data)}
                />
              }}
              headerClassName="HeaderDataList width-40"
              sortable={true}
              field="codsuq_req"
              customBody={formatSituacao}
            />
            <Column
              header="Observação"
              body={(data) => {
                return <ResponsiveColumn
                  className="pl-2"
                  column="Observação"
                  value={data?.observ_req}
                />
              }}
              headerClassName="HeaderDataList width-40"
              sortable={true}
              field="observ_req"
            />
          </DataList>
        </Container>
      </Modal>
      <UsuarioPermissaoReqModal
        visible={visibleUsuarioPermissao}
        hide={() => setVisibleUsuarioPermissao(false)}
        listaAprovadores={listaAprovadores}
      />
    </>
  )
}

export default RequisicaoLiberacaoRecusadasModal
