import React, { useEffect, useState } from 'react'
import TextInput from '../../../components/inputs/TextInput'
import { baseForm } from '../../../components/utils/BaseForm'
import Modal from '../../../components/utils/Modal'
import { formatCurrencyToNumber, formatToCurrency } from '../../../helpers/formaters'
import { transformAll } from '@overgear/yup-ast'
import { getTituloManutencaoDTO, postTituloManutencaoDTO } from '../../../dtos/cadastro/manutencao/TituloManutencaoDTO'
import FormaCobrancaService from '../../../services/cadastro/financeiro/FormaCobrancaService'
import TitulosManutencaoService from '../../../services/cadastro/manutencao/TitulosManutencaoService'
import DateInput from '../../../components/inputs/DateInput'
import SelectInput from '../../../components/inputs/SelectInput'

function PagamentoManutencaoModal ({ visible, onHide, manutencaoForm, pagamentoManutencaoSelecionado, edicaoHabilitada }) {
  const [formValidator, setFormValidator] = useState({})
  const [camposObrigatorios, setCamposObrigatorios] = useState([])
  const [formasCobranca, setFormasCobranca] = useState(null)

  async function hideModal () {
    onHide()
  }

  async function setTitulosManutencao (titulosManutencao) {
    await manutencaoForm.setFieldValue('titulosManutencao', setOrdemPagamentos(titulosManutencao))
  }

  function setOrdemPagamentos (titulosManutencao) {
    return titulosManutencao.map((tituloManutencao, indexTituloManutencao) => {
      tituloManutencao.ordem_tma = `${indexTituloManutencao + 1}/${titulosManutencao.length}`

      return tituloManutencao
    })
  }

  async function onSubmit () {
    await insertOrUpdatePagamentoManutencao()
    await hideModal()
  }

  async function insertOrUpdatePagamentoManutencao () {
    if (pagamentoManutencaoSelecionado) {
      await updatePagamentoManutencao()
      return
    }

    await insertPagamentoManutencao()
  }

  async function updatePagamentoManutencao () {
    const titulosManutencao = manutencaoForm.values.titulosManutencao

    const indexPagamentoManutencao = titulosManutencao.indexOf(pagamentoManutencaoSelecionado)

    titulosManutencao.splice(indexPagamentoManutencao, 1, postTituloManutencaoDTO(form.values))

    await setTitulosManutencao(titulosManutencao)
  }

  async function insertPagamentoManutencao () {
    const titulosManutencao = manutencaoForm.values.titulosManutencao

    titulosManutencao.push(postTituloManutencaoDTO(form.values))

    await setTitulosManutencao(titulosManutencao)
  }

  async function excluirPagamentoManutencao () {
    const titulosManutencao = manutencaoForm.values.titulosManutencao

    const indexPagamentoManutencao = titulosManutencao.indexOf(pagamentoManutencaoSelecionado)

    titulosManutencao.splice(indexPagamentoManutencao, 1)

    await setTitulosManutencao(titulosManutencao)

    await hideModal()
  }

  const form = baseForm({
    initialValues: getTituloManutencaoDTO(),
    validationSchema: formValidator,
    onSubmit: onSubmit
  })

  useEffect(async () => {
    if (visible) {
      if (pagamentoManutencaoSelecionado) {
        const dadosPagamentoManutencaoSelecionado = getTituloManutencaoDTO(pagamentoManutencaoSelecionado)

        await form.setValues(dadosPagamentoManutencaoSelecionado)
        await handleChangeFormaCobranca(dadosPagamentoManutencaoSelecionado.formaCobranca)
      } else {
        form.resetForm()
      }
    }

    await getFormasCobranca()
    await getFormValidator()
  }, [visible])

  async function getFormasCobranca () {
    const formasCobranca = await FormaCobrancaService.getAll()

    if (!pagamentoManutencaoSelecionado) {
      await handleChangeFormaCobranca(formasCobranca[0])
    }

    setFormasCobranca(formasCobranca)
  }

  async function getFormValidator () {
    const formValidatorAST = await TitulosManutencaoService.getFormValidator()

    const formValidator = transformAll(formValidatorAST)

    setFormValidator(formValidator)
    setCamposObrigatorios(formValidator._nodes)
  }

  function handleChangeValor (event) {
    form.setFieldValue('valor_tma', formatCurrencyToNumber(event.target.value))
  }

  async function handleChangeFormaCobranca (formaCobranca) {
    await form.setFieldValue('formaCobranca', formaCobranca)
    await form.setFieldValue('descri_foc', formaCobranca.descri_foc)
    await form.setFieldValue('codfoc_tma', formaCobranca.codigo_foc)
  }

  async function handleChangeDataVencimento (event) {
    await form.setFieldValue('datven_tma', event.target.value)
    await form.setFieldTouched('datven_tma')
  }

  return (
    <Modal
      header="Pagamento da manutenção"
      width={45}
      visible={visible}
      onHide={async () => await hideModal()}
      btnSalvar={edicaoHabilitada ? form.submitForm : undefined}
      btnExcluir={(pagamentoManutencaoSelecionado && edicaoHabilitada) ? excluirPagamentoManutencao : undefined}
    >
      <div className="formgrid grid">
        <div className="field col-12 md:col-6">
          <DateInput
            label="Vencimento"
            placeholder="Data de vencimento"
            name="datven_tma"
            value={form.values.datven_tma}
            onChange={handleChangeDataVencimento}
            disabled={!edicaoHabilitada}
            camposObrigatorios={camposObrigatorios}
            form={form}
          />
        </div>
        <div className="field col-12 md:col-6">
          <TextInput
            label="Valor"
            name="valor_tma"
            value={formatToCurrency(form.values.valor_tma)}
            onChange={handleChangeValor}
            disabled={!edicaoHabilitada}
            camposObrigatorios={camposObrigatorios}
            form={form}
          />
        </div>
        <div className="field col-12">
          <SelectInput
            value={form.values.formaCobranca || formasCobranca[0]}
            options={formasCobranca}
            onChange={(event) => handleChangeFormaCobranca(event.target.value)}
            name="formaCobranca"
            label="Forma de cobrança"
            optionLabel="descri_foc"
            dataKey="codigo_foc"
            disabled={!edicaoHabilitada}
            className="inputfield w-full"
            noFloatLabel
          />
        </div>
      </div>
    </Modal>
  )
}

export default PagamentoManutencaoModal
