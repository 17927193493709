import { IntegracaoModel } from '../../models/integracao/IntegracaoModel'
import ServiceBase from '../base/ServiceBase'

export default class IntegracoesService {
  async get (id) {
    return await ServiceBase.getBase(`tokenintegracao/${id}`)
  }

  async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`tokenintegracao?page=${page}&perPage=${perPage}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`tokenintegracao?${query}&page=${page}&perPage=${perPage}`)
  }

  async save (data) {
    const integracao = new IntegracaoModel(data)
    return await ServiceBase.postBase('tokenintegracao', integracao.toMap())
  }

  async delete ({ codigo_int }) {
    return await ServiceBase.deleteBase(`tokenintegracao/${codigo_int}`)
  }

  static async gerarToken (codigo_usu) {
    return (await ServiceBase.getBase(`tokenintegracao/gerar/${codigo_usu}`)).token
  }

  static async enviarEmailIntegracao (codusu_int) {
    await ServiceBase.postBase(`tokenintegracao/email/${codusu_int}`)
  }
}
