import { formatDateToAmerican, formatDateToInputDate } from '../../../helpers/formaters'


export const getCondicaoPagamentoDTO = (data = {}) => {

  if (data.parcelas && data.parcelas.length > 0) {
    data.parcelas.forEach((parcela, index) => {
      data.parcelas[index].vencimento = formatDateToInputDate(parcela.vencimento)
    })
  }

  return {
    codigo_cop: data.codigo_cop || null,
    descri_cop: data.descri_cop || "",
    qtdpar_cop: data.qtdpar_cop || 0,
    perdes_cop: data.perdes_cop || 0,
    peracr_cop: data.peracr_cop || 0,
    peredi_cop: data.peredi_cop || false,
    restrito_cop: data.restrito_cop || false,
    valorFinal: data.valorFinal || 0,
    valorEntrada: data.valorEntrada || 0,
    valorParcela: data.valorParcela || 0,
    dataPrimeiroVencimento: formatDateToInputDate(data.dataPrimeiroVencimento),
    dataEmissao: formatDateToInputDate(data.dataEmissao),
    resumoParcelas: data.resumoParcelas || ""
  }
}

export const postCondicaoPagamentoDTO = (data) => {

  return {
    valorTotal: data.valorTotal || 0,
    valorEntrada: data.valorEntrada || 0,
    dataPrimeiroVencimento: data.dataPrimeiroVencimento ? formatDateToAmerican(data.dataPrimeiroVencimento) : null,
    dataEmissao: formatDateToAmerican(data.dataEmissao),
  }
}
