import { Calendar } from 'primereact/calendar'
import React, { Component } from 'react'
import CommonHelper from '../../helpers/CommonHelper'

class DateTimeInput extends Component {
  render () {
    return (
      <span>
        <label htmlFor={this.props.name}>{this.props.label}</label>
        <Calendar
          {...this.props}
          value={this.props.value || ''}
          showIcon={this.props.showIcon || true}
          dateFormat="dd/mm/yy"
          locale="br"
          hideOnDateTimeSelect
          monthNavigator
          showTime
          showSeconds
          hourFormat="24"
          yearNavigator
          yearRange="1900:2100"
          readOnlyInput={!CommonHelper.isDesktop()}
        />
      </span>
    )
  }
}

export default DateTimeInput
