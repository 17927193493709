import React, { useState } from 'react'
import TabMenuPage from '../../components/layout/TabMenuContainer/TabMenuPage'
import TabMenuContainer from '../../components/layout/TabMenuContainer/TabMenuContainer'
import DeParaMarcaForm from '../../forms/integracao/DeParaMarcaForm'
import { Button } from 'primereact/button'
import CommonHelper from '../../helpers/CommonHelper'
import MarcaDeParaService from '../../services/Integracao/MarcaDeParaService'
import Confirm from '../../components/utils/Confirm'
import { showSuccessMessage } from '../../components/utils/Message'
import DeParaModeloForm from '../../forms/integracao/DeParaModeloForm'

export function DeParaMarcaModelo (props) {
  const [visibleConfirm, setVisibleConfirm] = useState(false)

  async function sincronizarMarcasModelosAG () {
    await MarcaDeParaService.sincronizarMarcaModeloAG()

    setVisibleConfirm(false)
    showSuccessMessage('Sincronização concluída com sucesso!')
  }

  return (
    <>
      <div className="flex justify-content-between align-items-center page-header">
        <h4></h4>
        <div className="page-header-buttons">
          <Button
            className="mr-2"
            label={CommonHelper.isDesktop() && 'Sincronizar com AutoGestor'}
            icon="pi pi-sync"
            onClick={async () => setVisibleConfirm(true)}
          />
        </div>
      </div>
      <TabMenuContainer>
        <TabMenuPage header="DE-PARA Marca">
          <DeParaMarcaForm
          />
        </TabMenuPage>
        <TabMenuPage header="DE-PARA Modelo">
          <DeParaModeloForm
          />
        </TabMenuPage>
      </TabMenuContainer>
      <Confirm
        title="Sincronizar marcas e modelos AutoGestor"
        visible={visibleConfirm}
        onCancel={() => setVisibleConfirm(false)}
        onConfirm={() => sincronizarMarcasModelosAG()}
        description="Confirma sincronizar as marcas e modelos do AutoGestor? Esse processo irá conectar com o Auto Gestor e cadastrar no sistema todas as marcas e modelos existentes lá. Também irá tentar fazer um de-para automático dessas marcas e modelos. Esse processo pode demorar alguns minutos."
        cancelLabel="Não"
      />
    </>
  )
}

export default DeParaMarcaModelo
