import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { Fieldset } from 'primereact/fieldset'
import React, { useEffect, useState } from 'react'
import SelectInput from '../../../../../components/inputs/SelectInput'
import TextInput from '../../../../../components/inputs/TextInput'
import CommonHelper from '../../../../../helpers/CommonHelper'
import { getStore } from '../../../../../redux/store'
import ContaService from '../../../../../services/cadastro/financeiro/ContaService'
import EmpresasService from '../../../../../services/cadastro/pessoa/EmpresasService'
import GrupoService from '../../../../../services/cadastro/pessoa/GrupoService'
import IntegracaoUsuarioForm from '../../../../integracao/integracaoUsuario/IntegracaoUsuarioForm'

const DadosUsuarioUsu = ({ form, edicaoHabilitada, isNew, camposObrigatorios }) => {
  const [empresas, setEmpresas] = useState([])
  const [contas, setContas] = useState([])
  const [grupos, setGrupos] = useState([])
  const [visibleIntegracaoUsuarioModal, setVisibleIntegracaoUsuarioModal] = useState(false)

  useEffect(async () => {
    await getGrupos().then(() => {
      if (form.values.codgru_usu) {
        const selectedGrupo = grupos.find(grupo =>
          grupo.codigo_gru === form.values.codgru_usu
        )

        form.setFieldValue('restricaoGrupo', selectedGrupo)
      }
    })

    await getContas().then(() => {
      if (form.values.codcon_usu) {
        const selectedConta = contas.find(conta =>
          conta.codigo_con === form.values.codcon_usu
        )

        form.setFieldValue('conta', selectedConta)
      }
    })

    await getEmpresas()
  }, [])

  const getGrupos = async () => {
    try {
      const grupos = await GrupoService.getAll()
      setGrupos(grupos)
    } catch (e) {
      showErrorMessage(e.message || 'Houve um erro inesperado ao buscar os grupos!')
    }
  }

  const getContas = async () => {
    try {
      const contas = await ContaService.getAll()
      setContas(contas)
    } catch (e) {
      showErrorMessage(e.message || 'Houve um erro inesperado ao buscar as contas!')
    }
  }

  const getEmpresas = async () => {
    try {
      const empresas = await EmpresasService.getAllEmpresas()
      setEmpresas(empresas)
    } catch (e) {
      showErrorMessage(e.message || 'Houve um erro inesperado ao listar as empresas!')
    }
  }

  const handleChangeUsuarioAtivo = (e) => {
    form.setFieldValue('ativo_usu', !e.target.value)
  }

  const handleChangeIsVendedor = (e) => {
    form.setFieldValue('isVendedor', !e.target.value)
  }

  const handleChangeEmpresa = (e) => {
    if (e.checked)
      form.setFieldValue('acessoEmpresas', [
        ...form.values.acessoEmpresas,
        { codemp_use: e.target.value }
      ])
    else
      form.setFieldValue('acessoEmpresas', form.values.acessoEmpresas.filter(
        acesso => acesso.codemp_use !== e.target.value
      ))
  }

  return (
    <div className="formgrid grid">
      <div className="field col-12 md:col-6">
        <SelectInput
          value={form.values.restricaoGrupo}
          options={grupos}
          onChange={form.handleChange}
          placeholder='Selecione o grupo'
          label="Grupo"
          name="restricaoGrupo"
          filter={CommonHelper.isDesktop()}
          optionLabel="nome_gru"
          dataKey="codigo_gru"
          noFloatLabel
          disabled={!edicaoHabilitada}
          showClear={form.values.restricaoGrupo?.codigo_gru}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 md:col-6">
        <SelectInput
          value={form.values.conta}
          options={contas}
          onChange={form.handleChange}
          placeholder='Selecione a conta'
          label="Conta"
          name="conta"
          filter={CommonHelper.isDesktop()}
          optionLabel="descri_con"
          dataKey="codigo_con"
          noFloatLabel
          disabled={!edicaoHabilitada}
          showClear={form.values.conta?.codigo_con}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 md:col-6">
        <TextInput
          label="Login"
          placeholder="Insira seu login"
          name="email"
          value={form.values.email}
          onChange={form.handleChange}
          disabled={!isNew}
          maxLength={200}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 md:col-3">
        <TextInput
          type="password"
          label="Senha"
          placeholder="Insira sua senha"
          name="password"
          value={isNew ? form.values.password : '********'}
          disabled={!isNew}
          onChange={form.handleChange}
          maxLength={200}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      {!isNew && (
        <div className="field col-12 md:col-3 flex justify-content-center align-items-center">
          <Button
            label="Acessar plataforma Web"
            className="mt-4"
            onClick={() => setVisibleIntegracaoUsuarioModal(true)}
          />
        </div>
      )}
      <div className="col-12 flex">
        <div className="checkbox-container pr-4">
          <Checkbox
            value={form.values.ativo_usu}
            onChange={handleChangeUsuarioAtivo}
            checked={form.values.ativo_usu}
            name="ativo_usu"
            disabled={!edicaoHabilitada}
          />
          <label htmlFor="ativo_usu" className="p-checkbox-label mb-0 pl-2">Usuário ativo</label>
        </div>
        <div className="checkbox-container">
          <Checkbox
            value={form.values.isVendedor}
            onChange={handleChangeIsVendedor}
            checked={form.values.isVendedor}
            name="isVendedor"
            disabled={!edicaoHabilitada}
          />
          <label htmlFor="isVendedor" className="p-checkbox-label mb-0 pl-2">Vendedor</label>
        </div>
      </div>
      <Fieldset legend={<b>Empresa</b>} className="col-12 mt-4">
        <div className="formgrid grid">
          {empresas.map(empresa => (
            <div className="field checkbox-container col-12 mb-2" key={empresa.codigo_emp}>
              <Checkbox
                value={empresa.codigo_emp}
                onChange={handleChangeEmpresa}
                checked={!!(form.values.acessoEmpresas.find(acesso => acesso.codemp_use === empresa.codigo_emp))}
                disabled={!edicaoHabilitada}
              />
              <label className="p-checkbox-label mb-0 pl-2">
                {empresa.pessoa.codigo_pes} - {empresa.pessoa.apelid_pes}
              </label>
            </div>
          ))}
        </div>
      </Fieldset>
      <IntegracaoUsuarioForm
        visible={visibleIntegracaoUsuarioModal}
        hide={() => setVisibleIntegracaoUsuarioModal(false)}
        codigoOrganizacao={getStore().usuarioLogado.databaseConnectionName}
        formUsu={form}
      />
    </div>
  )
}

export default DadosUsuarioUsu
