import BaseModel from '../../BaseModel'

export class MarcaDeParaModel extends BaseModel {
  constructor (params = {}) {
    super()
    this.codigo_mdp = params.codigo_mdp || null
    this.codmartur_mdp = params.codmartur_mdp || null
    this.codmaraug_mdp = params.codmaraug_mdp || null
  }

  toMap () {
    return {
      codigo_mdp: this.codigo_mdp,
      codmartur_mdp: this.codmartur_mdp || null,
      codmaraug_mdp: this.codmaraug_mdp || null
    }
  }
}
