import { Button } from 'primereact/button'
import { classNames } from 'primereact/utils'
import React from 'react'
import List from '../../../classes/List'
import Container from '../../../components/layout/Container'
import DataList from '../../../components/layout/DataList'
import Page from '../../../components/layout/Page'
import PreferencesButton from '../../../components/utils/PreferencesButton'
import TablePreferences from '../../../components/utils/TablePreferences'
import { getOrcamentoBalcaoDTO } from '../../../dtos/vendas/orcamentoBalcao/OrcamentoBalcaoDTO'
import CommonHelper from '../../../helpers/CommonHelper'
import { formatToCurrency } from '../../../helpers/formaters'
import { createColumns, getColunasHabilitadas } from '../../../helpers/tableConfigs'
import OrcamentoBalcaoService from '../../../services/vendas/orcamentoBalcao/OrcamentoBalcaoService'

export class OrcamentoBalcaoList extends List {
  constructor (props) {
    super(
      props,
      getOrcamentoBalcaoDTO,
      OrcamentoBalcaoService,
      'orcamentoBalcao',
      'codigo_opv',
      20,
      'a'
    )

    this.state = {
      ...this.state,
      visiblePreferencesModal: false
    }

    this.setColumns(createColumns([
      { field: 'numero_opv', header: 'Número', width: '16%' },
      { field: 'nomeCliente', header: 'Cliente', width: '16%' },
      { field: 'nomeConsultor', header: 'Consultor', width: '16%' },
      { field: 'dahins_opv', header: 'Criada em', width: '16%', customBody: this.getDataInsercao },
      { field: 'totliq_opv', header: 'Valor total', width: '16%', customBody: this.getValorTotalOrcamentoBalcao },
      { field: 'descri_sop', header: 'Situação', width: '16%' }
    ]))
  }

  getDataInsercao (data) {
    const date = new Date(data.dahins_opv)
    return CommonHelper.dateTimeToBrazilian(date)
  }

  getValorTotalOrcamentoBalcao (data) {
    return formatToCurrency(data.totliq_opv)
  }

  onSelectOption = (option) => {
    let { filter } = this.state
    this.setState({ opcaoSelecionada: option })

    if (option === 'a') {
      filter.codsop_opv = 'a'
    }
    if (option === 'e') {
      filter.codsop_opv = 'e'
    }
    if (option === 'r') {
      filter.codsop_opv = 'r'
    }
    if (option === 'f') {
      filter.codsop_opv = 'f'
    }
    if (option === 'c') {
      filter.codsop_opv = 'c'
    }
    if (option === 't') {
      filter.codsop_opv = 't'
    }

    this.setState({ filter }, this.onFilter)
  }

  componentDidMount () {
    this.onSelectOption(this.state.filter.codsop_opv || 'a')
    this.resetDescricaoFiltro()
    this.addEventListeners()
  }

  render () {
    const state = this.state
    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Orçamentos Balcão</h4>
          <div className="page-header-buttons">
            <Button
              className="mr-2"
              label={CommonHelper.isDesktop() && 'Novo'}
              icon="pi pi-plus"
              onClick={this.onNew}
            />
          </div>
        </div>
        <div className="table-options-container">
          <div className="table-options">

            <div className={classNames({ active: state.opcaoSelecionada === 'a' }, 'filter-option')}
              onClick={() => this.onSelectOption('a')}>
              <span className="option-label">Em aberto</span>
              <div className="option-quantity">{state.totalEmAberto}</div>
            </div>
            <div className={classNames({ active: state.opcaoSelecionada === 'r' }, 'filter-option')}
              onClick={() => this.onSelectOption('r')}>
              <span className="option-label">Reservados</span>
              <div className="option-quantity">{state.totalReservado}</div>
            </div>
            <div className={classNames({ active: state.opcaoSelecionada === 'e' }, 'filter-option')}
              onClick={() => this.onSelectOption('e')}>
              <span className="option-label">Enviados para o caixa</span>
              <div className="option-quantity">{state.totalEnviadoCaixa}</div>
            </div>
            <div className={classNames({ active: state.opcaoSelecionada === 'f' }, 'filter-option')}
              onClick={() => this.onSelectOption('f')}>
              <span className="option-label">Faturados</span>
              <div className="option-quantity">{state.totalFaturado}</div>
            </div>
            <div className={classNames({ active: state.opcaoSelecionada === 'c' }, 'filter-option')}
              onClick={() => this.onSelectOption('c')}>
              <span className="option-label">Cancelados</span>
              <div className="option-quantity">{state.totalCancelado}</div>
            </div>
            <div className={classNames({ active: state.opcaoSelecionada === 't' }, 'filter-option')}
              onClick={() => this.onSelectOption('t')}>
              <span className="option-label">Todos</span>
              <div className="option-quantity">
                {state.quantidadeTotal}
              </div>
            </div>

          </div>
          <div className="table-preferences">
            <PreferencesButton
              onClick={() => this.setState({ visiblePreferencesModal: true })}
            />
          </div>
        </div>
        <Container>
          <DataList
            data={state.list}
            selected={state.selected}
            rows={state.rows}
            onSelect={this.onSelect}
            totalRecords={state.totalRecords}
            first={state.first}
            onPage={this.onPageChange}
            page={state.page}
            onRowSelect={(e) => this.onView(e.data)}
            filterPlaceholder="Procurar orçamentos balcão"
            filterName="descricaoFiltro"
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
            responsive
            showFilter
          >
            {getColunasHabilitadas(this.columns, 'orcamentoBalcao')}
          </DataList>
        </Container>
        <TablePreferences
          title="Preferências da tabela de orçamento balcão"
          tableName="orcamentoBalcao"
          columns={this.columns}
          visible={state.visiblePreferencesModal}
          hide={() => this.setState({ visiblePreferencesModal: false })}
        />
      </Page>
    )
  }
}
