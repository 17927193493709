/* eslint-disable no-unused-vars */
import { Checkbox } from 'primereact/checkbox'
import { classNames } from 'primereact/utils'
import React, { useEffect, useState } from 'react'
import DateTimeInput from '../../../../../components/inputs/DateTimeInput'
import NumberInput from '../../../../../components/inputs/NumberInput'
import SelectInput from '../../../../../components/inputs/SelectInput'
import TextInput from '../../../../../components/inputs/TextInput'
import SearchInput from '../../../../../components/inputs/searchInput/SearchInput'
import AppButton from '../../../../../components/layout/AppButton'
import FieldErrorMessage from '../../../../../components/utils/FieldErrorMessage'
import { showErrorMessage, showWarnMessage } from '../../../../../components/utils/Message'
import Modal from '../../../../../components/utils/Modal'
import RequiredLabel from '../../../../../components/utils/RequiredLabel'
import VeiculoTemplate from '../../../../../components/utils/VeiculoTemplate'
import { getVeiculoDTO } from '../../../../../dtos/cadastro/veiculo/VeiculoDTO'
import CommonHelper from '../../../../../helpers/CommonHelper'
import { formatCurrencyToNumber, formatDocument, formatPlaca, formatPlacha, formatToCurrency } from '../../../../../helpers/formaters'
import { useValidateInput } from '../../../../../helpers/useValidateInput'
import { PessoaModel } from '../../../../../models/cadastro/pessoa/PessoaModel'
import { VendedorModel } from '../../../../../models/cadastro/pessoa/VendedorModel'
import { decrementActiveLoadingRequests, incrementActiveLoadingRequests, startLoading, stopLoading } from '../../../../../redux/actions'
import { dispatch } from '../../../../../redux/store'
import DocumentosService from '../../../../../services/cadastro/documento/DocumentosService'
import PermissaoService from '../../../../../services/cadastro/pessoa/PermissaoService'
import PessoaService from '../../../../../services/cadastro/pessoa/PessoaService'
import VendedoresService from '../../../../../services/cadastro/pessoa/VendedoresService'
import AvaliacaoService from '../../../../../services/cadastro/veiculo/AvaliacaoService'
import VeiculoService from '../../../../../services/cadastro/veiculo/VeiculoService'
import ConfigService from '../../../../../services/configuracao/ConfigService'
import TipoMidiaService from '../../../../../services/vendas/TipoMidiaService'
import NegociacaoService from '../../../../../services/vendas/negociacao/NegociacaoService'
import { PessoaFisica } from '../../../../../views/cadastro/pessoa/fisica/PessoaFisica'
import { PessoaJuridica } from '../../../../../views/cadastro/pessoa/juridica/PessoaJuridica'
import Veiculo from '../../../../../views/cadastro/veiculo/veiculo/Veiculo'
import { venderVeiculo } from '../../../../../views/vendas/persistencia/VenderVeiculo'
import VeiculoModal from '../../../../cadastro/veiculo/veiculo/VeiculoModal'

const DadosPrincipaisNeg = ({ form, edicaoHabilitada, operacaoNeg, pagandoConsignado, adicionarDocumentoAutomatico }) => {
  const veiculoNve = form.values.negociacaoVeiculo?.veiculo
  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(form)
  const [sugestaoPessoas, setSugestaoPessoas] = useState([])
  const [sugestaoVendedor, setSugestaoVendedor] = useState([])
  const [sugestaoVeiculos, setSugestaoVeiculos] = useState([])
  const [visibleVeiculoModal, setVisibleVeiculoModal] = useState(false)
  const [tiposmidia, setTipoMidia] = useState([])
  const [visibleAcessoriosModal, setVisibleAcessoriosModal] = useState(false)
  const [acessoriosList, setAcessoriosList] = useState('')
  const [isRepasse, setIsRepasse] = useState(form.values.negociacaoVeiculo?.repass_nve)
  const [isAtivoImobilizado, setIsAtivoImobilizado] = useState(form.values.negociacaoVeiculo?.atvimo_nve)
  const [realizadoTestDrive, setRealizadoTestDrive] = useState(form.values.negociacaoVeiculo?.hastesdri_nve)
  const [config1531, setConfig1531] = useState(false)
  const [config1433, setConfig1433] = useState(false)
  const [permissaoAlterarPreco, setPermissaoAlterarPreco] = useState(true)
  const [proprietario, setProprietario] = useState([])
  const isVenda = () => { return operacaoNeg === 'Venda' }
  const isConsignado = () => { return operacaoNeg === 'Consignação' }

  useEffect(async () => {
    await Promise.all([
      getTiposMidia(),
      getConfig1433(),
      getConfig1531(),
      getPerAlterarPreco()
    ])
  }, [])

  useEffect(() => {
    form.setFieldValue('tipomidia.obrigarTipoMidia', config1433 && !isConsignado() && isVenda())
  }, [operacaoNeg, config1433])

  async function getConfig1531 () {
    const obrigaDataPrevistaEntrega = await ConfigService.getValor(1531)
    setConfig1531(obrigaDataPrevistaEntrega)
  }

  async function getConfig1433 () {
    const obrigaTipoMidaVenda = await ConfigService.getValor(1433)
    setConfig1433(obrigaTipoMidaVenda)
  }

  async function getPerAlterarPreco () {
    const permissaoAlterarPrecoFuturo = await PermissaoService.getByCodigo('60')
    setPermissaoAlterarPreco(permissaoAlterarPrecoFuturo)
  }

  async function getTiposMidia () {
    try {
      const tiposmidia = await TipoMidiaService.filterTipoMidia()
      await setTipoMidia(tiposmidia)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar as mídias!')
    }
  }

  const getClienteVendedorReserva = async (codigo_vei) => {
    try {
      const vendedor = await VendedoresService.getVendedorReserva(codigo_vei)
      const cliente = await PessoaService.getClienteReserva(codigo_vei)

      if (vendedor) {
        if (vendedor[0]) {
          await form.setFieldValue('vendedor', vendedor[0])
        }
      }

      if (cliente) {
        if (cliente[0]) {
          await form.setFieldValue('pessoa', cliente[0])
        }
      }
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar as informações da reserva!')
    }
  }

  //Quando um veículo é selecionado ele atribui alguns valores.
  useEffect(() => {
    const produtoEmpresa = veiculoNve?.produto?.produtoEmpresas
    const { negociacaoVeiculo } = form.values
    if (isVenda() && negociacaoVeiculo.valbru_nve === 0 && produtoEmpresa.prepro_pre > 0) {
      form.setFieldValue('negociacaoVeiculo.valbru_nve', produtoEmpresa?.prepro_pre)
    } else if (negociacaoVeiculo?.valbru_nve === 0) {
      form.setFieldValue('negociacaoVeiculo.valbru_nve', produtoEmpresa?.preco_pre)
    }
    if (negociacaoVeiculo?.veiculo?.proprietario && proprietario.length === 0) {
      setProprietario(negociacaoVeiculo?.veiculo?.proprietario)
      form.setFieldValue('negociacaoVeiculo.proprietario', negociacaoVeiculo?.veiculo?.proprietario)
      form.setFieldValue('proprietario.apelid_pes', negociacaoVeiculo?.veiculo?.proprietario.apelid_pes)
      form.setFieldValue('proprietario', negociacaoVeiculo?.veiculo?.proprietario)
    }

    setIsRepasse(negociacaoVeiculo?.repass_nve)
    setIsAtivoImobilizado(negociacaoVeiculo?.atvimo_nve)
    setRealizadoTestDrive(negociacaoVeiculo?.hastesdri_nve)
    getClienteVendedorReserva(negociacaoVeiculo?.codvei_nve)
  }, [veiculoNve?.placa_vei, veiculoNve?.chassi_vei])

  //Calcula o preço líquido quando o valor bruto ou o desconto são alterados.
  useEffect(() => {
    if (isVenda()) {
      form.setFieldValue('negociacaoVeiculo.valor_nve', (form.values.negociacaoVeiculo?.valbru_nve - form.values.negociacaoVeiculo?.valdes_nve) || 0)
    }
  }, [form.values.negociacaoVeiculo?.valbru_nve, form.values.negociacaoVeiculo?.valdes_nve])

  const sugerirPessoas = async () => {
    try {
      const pessoas = await PessoaService.filterAutocomplete(form.values.pessoa.nomraz_pes)

      if (pessoas.length <= 0) {
        showWarnMessage('Pessoa não encontrada!')
      }
      setSugestaoPessoas(pessoas)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir uma pessoa!')
    }
  }

  const sugerirVendedor = async () => {
    try {
      const vendedoresService = new VendedoresService()
      const vendedores = await vendedoresService.filterAutoComplete(`nomraz_pes=${form.values.vendedor.nomraz_pes}`)

      if (vendedores.length <= 0) {
        showWarnMessage('Vendedor não encontrado!')
      }

      setSugestaoVendedor(vendedores)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um vendedor!')
    }
  }

  const sugerirVeiculos = async () => {
    try {
      const veiculoService = new VeiculoService()
      const veiculos = await veiculoService.filterAutoComplete(`placa_vei=${form.values.negociacaoVeiculo?.veiculo?.placa_vei}`)

      if (veiculos.length <= 0) {
        showWarnMessage('Veículo não encontrado!')
      }

      setSugestaoVeiculos(veiculos)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um veículo!')
    }
  }

  const handleChangeVeiculo = (e) => {
    form.setFieldValue('negociacaoVeiculo', e.target.value)
    form.setFieldValue('negociacaoVeiculo.veiculo.placa_vei', e.target.value)
  }

  const handleChangeVendedor = (e) => {
    form.setFieldValue('vendedor', e.target.value)
    form.setFieldValue('vendedor.nomraz_pes', e.target.value)
  }

  const handleChangeTipoMidia = async (e) => {
    await form.setFieldValue('tipomidia', e.target.value)
    await form.setFieldValue('tipomidia.descri_tim', e.target.value?.descri_tim)
    form.setFieldValue('tipomidia.codigo_tim', e.target.value?.codigo_tim)
  }

  const handleChangePessoa = (e) => {
    form.setFieldValue('pessoa', e.target.value)
    form.setFieldValue('pessoa.nomraz_pes', e.target.value)
  }

  const handleChangeProprietario = (e) => {
    setProprietario(e.target.value)
    form.setFieldValue('negociacaoVeiculo.veiculo.proprietario', e.target.value)
    form.setFieldValue('proprietario', e.target.value)
    form.setFieldValue('negociacaoVeiculo.proprietario', e.target.value)
    form.setFieldValue('proprietario.nomraz_pes', e.target.value)
  }

  const handleChangeRepasse = () => {
    form.setFieldValue('negociacaoVeiculo.repass_nve', !isRepasse)
    setIsRepasse(!isRepasse)
  }

  const handleChangeAtivoImobilizado = () => {
    form.setFieldValue('negociacaoVeiculo.atvimo_nve', !isAtivoImobilizado)
    setIsAtivoImobilizado(!isAtivoImobilizado)
  }

  const handleChangeRealizadoTestDrive = () => {
    form.setFieldValue('negociacaoVeiculo.hastesdri_nve', !realizadoTestDrive)
    setRealizadoTestDrive(!realizadoTestDrive)
  }

  const handleChangePrevisaoEntrega = (e) => {
    form.setFieldValue('negociacaoVeiculo.dahpreent_nve', e.target.value)
  }

  const handleChangeCurrency = (event) => {
    form.setFieldValue(event.target.name, formatCurrencyToNumber(event.target.value))
  }

  function onSaveModalPessoa (pessoa) {
    onSelectPessoa({ value: pessoa.pessoa })
  }
  function onSaveModalProprietario (pessoa) {
    onSelectProprietario({ value: pessoa.pessoa })
  }

  async function onSaveModalVeiculo (veiculo) {
    await onSelectVeiculo({ value: veiculo })
  }

  const onSelectPessoa = async (e) => {
    await form.setFieldValue('pessoa', e.value)
    form.setFieldTouched('pessoa.codigo_pes')
  }

  const onSelectProprietario = async (e) => {
    setProprietario(e.value)
    form.setFieldValue('negociacaoVeiculo.veiculo.proprietario', e.value)
    form.setFieldValue('negociacaoVeiculo.veiculo.codpes_vei', e.value.codigo_pes)
    if (isVenda()) {
      form.setFieldValue('vehiclesToSell.veiculo.proprietario', e.value)
    } else {
      form.setFieldValue('vehiclesToBuy.veiculo.proprietario', e.value)
    }
    await form.setFieldValue('negociacaoVeiculo.proprietario', e.value)
    await form.setFieldValue('proprietario', e.value)
    form.setFieldTouched('proprietario.codigo_pes')
  }

  const onSelectVendedor = async (e) => {
    await form.setFieldValue('vendedor', e.value)
    form.setFieldTouched('vendedor.codigo_pes')
  }

  const buscaCodAvaCoc = async (placa) => {
    const avaliacao = await AvaliacaoService.getAvaliacaoCompletaPorPlaca(
      placa
    )
    return avaliacao === null || avaliacao?.codavacoc_ava === null
  }

  const onSelectVeiculo = async (e) => {
    if (isVenda()) {
      await validarVendaVeiculo(e.value)
    } else {
      const naoPermitirCompraSemAvaliacao = await ConfigService.getValor(3389)
      const { placa_vei } = e.value
      if (naoPermitirCompraSemAvaliacao && placa_vei) {
        if (await buscaCodAvaCoc(placa_vei)) {
          showErrorMessage('Veículo não possui avaliação finalizada no ConectaCarros!')
          return false
        }
      }
      await validateCompraVeiculo(e.value)
    }
    form.setFieldTouched('negociacaoVeiculo.codvei_nve')
  }

  const validateCompraVeiculo = async (veiculoParaValidar) => {
    try {
      const veiculosValidosParaCompra = []
      const veiculoService = new VeiculoService()
      dispatch(incrementActiveLoadingRequests())
      dispatch(startLoading())

      const veiculoValidado = await NegociacaoService.validateCompraVeiculo(veiculoParaValidar.codigo_vei, form.values.vehiclesToBuy?.codigo_nve)

      if (veiculoValidado.message) {
        form.setFieldValue('negociacaoVeiculo', {})
        showWarnMessage(veiculoValidado.message)
        dispatch(decrementActiveLoadingRequests())
        dispatch(stopLoading())
        return
      }

      const veiculo = await veiculoService.get(veiculoValidado.codigo_vei)

      if (veiculo.itemConjuntoVeiculo && veiculo.itemConjuntoVeiculo.conjuntoVeiculo.ativo_cjv) {
        const itensConjunto = veiculo.itemConjuntoVeiculo.conjuntoVeiculo.itensConjunto

        for (const itemConjunto of itensConjunto) {
          const veiculoConjunto = await veiculoService.get(itemConjunto.codvei_icj)

          const veiculoValidado = await NegociacaoService.validateCompraVeiculo(veiculoConjunto.codigo_vei, form.values.vehiclesToBuy?.codigo_nve)

          if (veiculoValidado.message) {
            form.setFieldValue('negociacaoVeiculo', {})
            showWarnMessage(veiculoValidado.message)
            continue
          }

          if (veiculoConjunto.acessorios[0]) {
            veiculoConjunto.acessorios = veiculoConjunto.acessorios[0].acessorio
          }

          veiculoConjunto.produto.produtoEmpresas = veiculoConjunto.produto.produtoEmpresas[0]
          veiculoConjunto.produto.produtoEmpresas.preco_pre = 0
          veiculoConjunto.produto.produtoEmpresas.prepro_pre = 0

          const veiculoNegociacaoConjunto = {
            placha: formatPlacha(veiculoConjunto.placa_vei, veiculoConjunto.chassi_vei),
            veiculo: {
              ...veiculoConjunto,
              placha: formatPlacha(veiculoConjunto.placa_vei, veiculoConjunto.chassi_vei)
            },
            repass_nve: veiculoConjunto.repass_vei,
            kmatu_nve: veiculoConjunto.km_vei,
            codpro_nve: veiculoConjunto.codpes_vei,
            codfoa_nve: isConsignado() ? 3 : operacaoNeg === 'Compra' ? 2 : 1,
            consig_nve: isConsignado(),
            codvei_nve: veiculoConjunto.codigo_vei,
            valor_nve: 0,
            atvimo_nve: 0
          }

          veiculosValidosParaCompra.push(veiculoNegociacaoConjunto)
        }
        if (itensConjunto.length !== veiculosValidosParaCompra.length) {
          dispatch(decrementActiveLoadingRequests())
          dispatch(stopLoading())
          return
        }

        form.setFieldValue('veiculosConjunto', veiculosValidosParaCompra)
        form.setFieldValue('negociacaoVeiculo', veiculosValidosParaCompra[0])
        adicionaDocAutomatico(veiculosValidosParaCompra[0].veiculo)
      } else {
        if (veiculo.acessorios[0]) {
          veiculo.acessorios = veiculo.acessorios[0].acessorio
        }

        veiculo.produto.produtoEmpresas = veiculo.produto.produtoEmpresas[0]
        veiculo.produto.produtoEmpresas.preco_pre = 0
        veiculo.produto.produtoEmpresas.prepro_pre = 0

        setIsRepasse(veiculo.repass_vei)
        await form.setFieldValue('negociacaoVeiculo.veiculo', veiculo)
        await form.setFieldValue('negociacaoVeiculo.repass_nve', veiculo.repass_vei)
        await form.setFieldValue('negociacaoVeiculo.kmatu_nve', veiculo.km_vei)
        await form.setFieldValue('negociacaoVeiculo.codpro_nve', veiculo.codpes_vei)
        await form.setFieldValue('negociacaoVeiculo.codfoa_nve', isConsignado() ? 3 : operacaoNeg === 'Compra' ? 2 : 1)
        await form.setFieldValue('negociacaoVeiculo.consig_nve', isConsignado())
        await form.setFieldValue('negociacaoVeiculo.codvei_nve', veiculo.codigo_vei)
        adicionaDocAutomatico(veiculo)
        form.setFieldValue('negociacaoVeiculo.proprietario', veiculo.proprietario)
        form.setFieldValue('proprietario', veiculo.proprietario)
        form.setFieldTouched('proprietario.codigo_pes')
        setProprietario(veiculo.proprietario)
      }

      dispatch(decrementActiveLoadingRequests())
      dispatch(stopLoading())
    } catch (error) {
      showErrorMessage(error.message || `Ocorreu um erro inesperado ao validar a compra do veículo: ${formatPlacha(veiculoParaValidar.placa_vei, veiculoParaValidar.chassi_vei)}!`)
      dispatch(decrementActiveLoadingRequests())
      dispatch(stopLoading())
    }
  }
  const adicionaDocAutomatico = async (veiculo) => {
    let config3326 = await ConfigService.getValor(3326, false)
    let config3325 = await ConfigService.getValor(3325, false)
    let config3334 = await ConfigService.getValor(3334)
    let negociacao
    let documentoRoayltes = []
    if (config3325) {
      documentoRoayltes = await DocumentosService.getTipoDocumento(Number(config3326))
    }
    negociacao = {
      veiculoCompra: veiculo,
      operacao: 'Compra',
      documentoAutomatico: documentoRoayltes,
      valorDocumentoAutomatico: Number(config3334)
    }
    adicionarDocumentoAutomatico(negociacao, negociacao.codigo_vei)
  }
  const validarVendaVeiculo = async (data) => {
    try {
      if (await venderVeiculo.handleVenderVeiculo(data)) {
        const negociacao = JSON.parse(localStorage.getItem('negociacao'))

        if (negociacao.veiculoVenda) {
          const negociacaoVeiculo = {
            codvei_nve: negociacao.veiculoVenda.codigo_vei,
            valbru_nve: 0,
            valdes_nve: 0,
            valor_nve: 0,
            kmatu_nve: negociacao.veiculoVenda.km_vei,
            dahpreent_nve: 0,
            repass_nve: negociacao.veiculoVenda.repass_vei,
            atvimo_nve: 0,
            hastesdri_nve: 0,
            veiculo: negociacao.veiculoVenda
          }

          if (negociacao.operacao === 'Venda') {
            if (negociacaoVeiculo.veiculo?.acessorios[0]) {
              negociacaoVeiculo.veiculo.acessorios = negociacaoVeiculo.veiculo.acessorios[0].acessorio
            }

            negociacaoVeiculo.codpro_nve = negociacaoVeiculo.veiculo.codpes_vei
            negociacaoVeiculo.venda_nve = true
            negociacaoVeiculo.veiculo.produto.produtoEmpresas = negociacaoVeiculo.veiculo.produto.produtoEmpresas[0]
            trocarVeiculoDocumento(negociacao.veiculoVenda)
            trocarVeiculoFopagNeg(negociacao.veiculoVenda)
          }
          form.setFieldValue('negociacaoVeiculo', negociacaoVeiculo)
        } else {
          const veiculosConjunto = []
          for (const veiculo of negociacao.conjuntoVeiculos) {
            const negociacaoVeiculo = {
              codvei_nve: veiculo.codigo_vei,
              placha: formatPlacha(veiculo.placa_vei, veiculo.chassi_vei),
              valbru_nve: Number(veiculo.produto.produtoEmpresas[0].prepro_pre) || Number(veiculo.produto.produtoEmpresas[0].preco_pre),
              valdes_nve: 0,
              valor_nve: Number(veiculo.produto.produtoEmpresas[0].prepro_pre) || Number(veiculo.produto.produtoEmpresas[0].preco_pre),
              kmatu_nve: veiculo.km_vei,
              dahpreent_nve: 0,
              repass_nve: veiculo.repass_vei,
              atvimo_nve: 0,
              hastesdri_nve: 0,
              veiculo: {
                ...veiculo,
                placha: formatPlacha(veiculo.placa_vei, veiculo.chassi_vei)
              }
            }

            if (negociacaoVeiculo.veiculo?.acessorios[0]) {
              negociacaoVeiculo.veiculo.acessorios = negociacaoVeiculo.veiculo.acessorios[0].acessorio
            }

            negociacaoVeiculo.codpro_nve = negociacaoVeiculo.veiculo.codpes_vei
            negociacaoVeiculo.venda_nve = true
            negociacaoVeiculo.veiculo.produto.produtoEmpresas = negociacaoVeiculo.veiculo.produto.produtoEmpresas[0]

            veiculosConjunto.push(negociacaoVeiculo)
          }
          trocarVeiculoDocumento(veiculosConjunto[0])
          trocarProcuradorVeiculoConjunto(veiculosConjunto[0]?.veiculo.codigo_vei, veiculosConjunto[0])
          trocarVeiculoFopagNeg(veiculosConjunto[0]?.veiculo)
          form.setFieldValue('veiculosConjunto', veiculosConjunto)
          form.setFieldValue('negociacaoVeiculo', veiculosConjunto[0])
        }
        form.setFieldValue('negociacaoVeiculo.veiculo.proprietario', data.proprietario)
        form.setFieldValue('negociacaoVeiculo.veiculo.codpes_vei', data.proprietario.codigo_pes)
        form.setFieldValue('vehiclesToSell.veiculo.proprietario', data.proprietario)
        form.setFieldValue('negociacaoVeiculo.proprietario', data.proprietario)
        form.setFieldValue('proprietario.apelid_pes', data.proprietario.apelid_pes)
        form.setFieldValue('proprietario', data.proprietario)
        form.setFieldTouched('proprietario.codigo_pes')
        setProprietario(data.proprietario)
      }
    } catch (error) {
      showWarnMessage(error.message)
      dispatch(decrementActiveLoadingRequests())
      dispatch(stopLoading())
    }
  }
  const limparVeiculo = () => {
    form.setFieldValue('negociacaoVeiculo', {})
    form.setFieldValue('negociacaoVeiculo.veiculo.proprietario', {})
    form.setFieldValue('negociacaoVeiculo.veiculo.codpes_vei', {})
    form.setFieldValue('vehiclesToSell.veiculo.proprietario', {})
    form.setFieldValue('procuradorVeiculo', {})
    localStorage.removeItem('negociacao')
    setProprietario({})
  }

  const trocarVeiculoDocumento = (veiculo) => {
    const { documents } = form.values
    if (documents.length > 0) {
      documents.map((doc) => {
        doc.codvei_ndo = veiculo.codigo_vei
        doc.veiculoNve = veiculo
      })
    }
    form.setFieldValue('documents', documents)
  }
  const trocarVeiculoFopagNeg = (veiculo) => {
    const { foPagNegs } = form.values
    if (foPagNegs.length > 0) {
      foPagNegs.map((fopag) => {
        fopag.codvei_fpn = veiculo.codigo_vei
        fopag.veiculo = veiculo
      })
    }

    form.setFieldValue('foPagNegs', foPagNegs)
  }
  const trocarProcuradorVeiculoConjunto = (codigoVei, negVeiculo = []) => {
    const { procurador } = form.values
    const { procuradorVeiculo } = form.values
    if (procurador.length > 0) {
      procurador.map((proc) => {
        proc.codvei_nve = codigoVei
        proc.veiculo = negVeiculo.veiculo
      })
    }
    if (procuradorVeiculo.length > 0) {
      procuradorVeiculo.map((procVei) => {
        procVei.veiculo = negVeiculo
        procVei.codvei_nve = codigoNve
      })
    }
  }

  const dadosPrincipaisVenda = () => {
    return (
      <>
        <div className="field col-12 md:col-2 neg-checkbox-container">
          <div className={(CommonHelper.isDesktop() ? 'neg-checkbox-first ' : 'neg-checkbox') + ' field col-12 md:col-12 disabled-input checkbox-container'}>
            <Checkbox
              id="repasse"
              value={isRepasse}
              checked={isRepasse}
              onChange={handleChangeRepasse}
              disabled={!edicaoHabilitada} />
            <label htmlFor="repasse" className="p-checkbox-label mb-0 pl-2">Repasse</label>
          </div>
          <div className="field col-12 md:col-12 disabled-input checkbox-container neg-checkbox">
            <Checkbox
              id="ativoImobilizado"
              value={isAtivoImobilizado}
              checked={isAtivoImobilizado}
              onChange={handleChangeAtivoImobilizado}
              disabled={!edicaoHabilitada} />
            <label htmlFor="ativoImobilizado" className="p-checkbox-label mb-0 pl-2">Ativo Imobilizado</label>
          </div>
          <div className="field col-12 md:col-12 disabled-input checkbox-container neg-checkbox">
            <Checkbox
              id="test-drive"
              value={realizadoTestDrive}
              checked={realizadoTestDrive}
              onChange={handleChangeRealizadoTestDrive}
              disabled={!edicaoHabilitada} />
            <label htmlFor="test-drive" className="p-checkbox-label mb-0 pl-2">Realizado Test-Drive</label>
          </div>
        </div>
        <div className="field col-12 md:col-3 flex-column justify-content-center">
          <div className={(CommonHelper.isDesktop() ? 'col-12 sm:col-12 md:col-10 field' : ' field ') + ' '}>
            <SearchInput
              //AutoComplete
              field="nomraz_pes"
              label="Proprietario"
              value={proprietario && proprietario.nomraz_pes}
              placeholder="Nome do proprietario"
              disabled={pagandoConsignado ? true : !edicaoHabilitada}
              onSelect={async (e) => await onSelectProprietario(e)}
              onBlur={() => form.setFieldTouched('negociacaoVeiculo.proprietario')}
              suggestions={sugestaoPessoas}
              completeMethod={sugerirPessoas}
              onChange={handleChangeProprietario}
              className={classNames({ 'p-invalid': isFormFieldValid('negociacaoVeiculo.proprietario') }, 'inputfield w-full')}
              isFormFieldValid={isFormFieldValid('negociacaoVeiculo.proprietario')}
              //RegisterModal
              selected={form.values.negociacaoVeiculo.proprietario}
              onSaveModal={onSaveModalProprietario}
              onDeleteModal={async () => await onSelectProprietario({})}
              ComponenteCadastro={[
                {
                  label: 'Pessoa física',
                  component: PessoaFisica,
                  header: form.values.proprietario.codigo_pes ? 'Pessoa Física - ' + form.values.proprietario.codigo_pes : 'Cadastro de pessoa física'
                },
                {
                  label: 'Pessoa jurídica',
                  component: PessoaJuridica,
                  header: form.values.proprietario.codigo_pes ? 'Pessoa jurídica - ' + form.values.proprietario.codigo_pes : 'Cadastro de pessoa jurídica'
                }
              ]}
              // FiltroModal
              filtrotitle="Pesquisa de pessoa"
              service={PessoaService}
              model={PessoaModel}
              primarykeyname="codigo_pes"
              columns={[
                { campo: 'codigo_pes', nome: 'Código' },
                { campo: 'nomraz_pes', nome: 'Nome' },
                { campo: 'cgccpf_pes', nome: 'CPF/CNPJ', format: formatDocument }
              ]}
            />
            <FieldErrorMessage message={getFormErrorMessage('negociacaoVeiculo.proprietario')} />
          </div>
          <div className={(CommonHelper.isDesktop() ? '' : 'no-padding') + ' field col-12 sm:col-12 md:col-10'}>
            {form.values.negociacaoVeiculo.veiculo?.codtiv_vei === 'u' ? <RequiredLabel label="Km Atual" valid={isFormFieldValid('negociacaoVeiculo.dahpreent_nve')} /> : <label>Km Atual</label>}
            <NumberInput
              placeholder="Km Atual"
              value={form.values.negociacaoVeiculo?.kmatu_nve}
              onChange={(e) => form.setFieldValue('negociacaoVeiculo.kmatu_nve', e.value)}
              disabled={!edicaoHabilitada}
              className="inputfield w-full"
              useGrouping={false}
              min="0"
            />
            <FieldErrorMessage message={getFormErrorMessage('negociacaoVeiculo.dahpreent_nve')} />
          </div>
          <div className={(CommonHelper.isDesktop() ? '' : 'no-padding ') + 'field col-12 md:col-10'}>
            {config1531 ? <RequiredLabel label="Previsão de entrega" valid={isFormFieldValid('negociacaoVeiculo.dahpreent_nve')} /> : <label>Previsão de entrega</label>}
            <DateTimeInput
              placeholder="Previsão de entrega"
              value={form.values.negociacaoVeiculo?.dahpreent_nve}
              onChange={(e) => handleChangePrevisaoEntrega(e)}
              disabled={!edicaoHabilitada}
              className="inputfield w-full" />
            <FieldErrorMessage message={getFormErrorMessage('negociacaoVeiculo.dahpreent_nve')} />
          </div>
        </div>
        <div className="field col-12 md:col-3 no-padding">
          <div className="field col-12 md:col-12">
            <RequiredLabel label="Valor Bruto" valid={isFormFieldValid('negociacaoVeiculo.valbru_nve')} />
            <TextInput
              name="negociacaoVeiculo.valbru_nve"
              disabled={!edicaoHabilitada}
              onChange={handleChangeCurrency}
              value={formatToCurrency(form.values.negociacaoVeiculo?.valbru_nve || 0)}
              onBlur={() => { form.setFieldTouched('negociacaoVeiculo.valbru_nve') }}
              className={classNames({ 'p-invalid': isFormFieldValid('negociacaoVeiculo.valbru_nve') }, 'inputfield w-full')} />
            <FieldErrorMessage message={getFormErrorMessage('negociacaoVeiculo.valbru_nve')} />
          </div>
          <div className="field col-12 md:col-12">
            <TextInput
              label="Desconto"
              name="negociacaoVeiculo.valdes_nve"
              disabled={!edicaoHabilitada}
              onChange={(e) => {
                if (parseFloat(formatCurrencyToNumber(e.target.value)) > 0) {
                  handleChangeCurrency(e)
                } else {
                  e.target.value = 0
                  handleChangeCurrency(e)
                }
              }}
              value={formatToCurrency(form.values.negociacaoVeiculo?.valdes_nve || 0)}
              onBlur={() => { form.setFieldTouched('negociacaoVeiculo.valdes_nve') }}
              className={classNames({ 'p-invalid': isFormFieldValid('negociacaoVeiculo.valdes_nve') }, 'inputfield w-full')} />
            <FieldErrorMessage message={getFormErrorMessage('negociacaoVeiculo.valdes_nve')} />
          </div>
          <div className="field col-12 md:col-12">
            <label>Valor líquido</label>
            <TextInput
              disabled
              value={formatToCurrency(form.values.negociacaoVeiculo?.valor_nve || 0)}
              className="inputfield w-full" />
          </div>
        </div>
      </>
    )
  }

  const dadosPrincipaisCompra = () => {
    return (
      <>
        <div className="field col-12 md:col-3">
          <div className={(CommonHelper.isDesktop() ? 'field col-12 sm:col-12 md:col-10 no-padding' : ' field ') + ' '}>
            <SearchInput
              //AutoComplete
              field="nomraz_pes"
              label="Proprietario"
              value={proprietario && proprietario.nomraz_pes}
              placeholder="Nome do proprietario"
              disabled={pagandoConsignado ? true : !edicaoHabilitada}
              onSelect={async (e) => await onSelectProprietario(e)}
              onBlur={() => form.setFieldTouched('negociacaoVeiculo.proprietario')}
              suggestions={sugestaoPessoas}
              completeMethod={sugerirPessoas}
              onChange={handleChangeProprietario}
              className={classNames({ 'p-invalid': isFormFieldValid('negociacaoVeiculo.proprietario') }, 'inputfield w-full')}
              isFormFieldValid={isFormFieldValid('negociacaoVeiculo.proprietario')}
              //RegisterModal
              selected={form.values.negociacaoVeiculo.proprietario}
              onSaveModal={onSaveModalProprietario}
              onDeleteModal={async () => await onSelectProprietario({})}
              ComponenteCadastro={[
                {
                  label: 'Pessoa física',
                  component: PessoaFisica,
                  header: form.values.proprietario.codigo_pes ? 'Pessoa Física - ' + form.values.proprietario.codigo_pes : 'Cadastro de pessoa física'
                },
                {
                  label: 'Pessoa jurídica',
                  component: PessoaJuridica,
                  header: form.values.proprietario.codigo_pes ? 'Pessoa jurídica - ' + form.values.proprietario.codigo_pes : 'Cadastro de pessoa jurídica'
                }
              ]}
              // FiltroModal
              filtrotitle="Pesquisa de pessoa"
              service={PessoaService}
              model={PessoaModel}
              primarykeyname="codigo_pes"
              columns={[
                { campo: 'codigo_pes', nome: 'Código' },
                { campo: 'nomraz_pes', nome: 'Nome' },
                { campo: 'cgccpf_pes', nome: 'CPF/CNPJ', format: formatDocument }
              ]}
            />
            <FieldErrorMessage message={getFormErrorMessage('negociacaoVeiculo.proprietario')} />
          </div>
          <div className="field col-12 sm:col-12 md:col-10 no-padding">
            <NumberInput
              label="Km Atual"
              placeholder="Km Atual"
              value={form.values.negociacaoVeiculo?.kmatu_nve}
              onChange={(e) => form.setFieldValue('negociacaoVeiculo.kmatu_nve', e.value)}
              disabled={!edicaoHabilitada}
              className="inputfield w-full"
              useGrouping={false}
              min="0"
            />
            <div className="field col-12 md:col-12 disabled-input neg-checkbox mt-4">
              <Checkbox
                id="ativoImobilizado"
                value={isAtivoImobilizado}
                checked={isAtivoImobilizado}
                onChange={handleChangeAtivoImobilizado}
                disabled={!edicaoHabilitada} />
              <label htmlFor="ativoImobilizado" className="p-checkbox-label mb-0 pl-2">Ativo Imobilizado</label>
            </div>
            <div className="field col-12 md:col-12 disabled-input neg-checkbox">
              <Checkbox
                id="repasse"
                value={isRepasse}
                checked={isRepasse}
                onChange={handleChangeRepasse}
                disabled={!edicaoHabilitada} />
              <label htmlFor="repasse" className="p-checkbox-label mb-0 pl-2">Repasse</label>
            </div>
          </div>
        </div>
        <div className="field col-12 md:col-3">
          <div className="field col-12 md:col-12">
            <RequiredLabel label="Valor de Compra" valid={isFormFieldValid('negociacaoVeiculo.valor_nve')} />
            <TextInput
              name="negociacaoVeiculo.valor_nve"
              disabled={!edicaoHabilitada}
              onChange={handleChangeCurrency}
              value={formatToCurrency(form.values.negociacaoVeiculo?.valor_nve || 0)}
              onBlur={() => { form.setFieldTouched('negociacaoVeiculo.valor_nve') }}
              className={classNames({ 'p-invalid': isFormFieldValid('negociacaoVeiculo.valor_nve') }, 'inputfield w-full')} />
            <FieldErrorMessage message={getFormErrorMessage('negociacaoVeiculo.valor_nve')} />
          </div>
          <div className="field col-12 md:col-12">
            <TextInput
              label="Valor de Venda"
              name="negociacaoVeiculo.valfut_nve"
              disabled={(!edicaoHabilitada) || !(edicaoHabilitada && permissaoAlterarPreco)}
              onChange={handleChangeCurrency}
              value={formatToCurrency(form.values.negociacaoVeiculo?.valfut_nve || 0)}
              onBlur={() => { form.setFieldTouched('negociacaoVeiculo.valfut_nve') }}
              className={classNames({ 'p-invalid': isFormFieldValid('negociacaoVeiculo.valfut_nve') }, 'inputfield w-full')} />
            <FieldErrorMessage message={getFormErrorMessage('negociacaoVeiculo.valfut_nve')} />
          </div>
        </div>
      </>
    )
  }

  const dadosPrincipaisConsignacao = () => {
    return (
      <>
        <div className={(CommonHelper.isDesktop() ? '' : ' w-full ')}>
          <div className={'p-2'}>
            <SearchInput
              //AutoComplete
              field="nomraz_pes"
              label="Proprietario"
              value={proprietario && proprietario.nomraz_pes}
              placeholder="Nome do proprietario"
              disabled={pagandoConsignado ? true : !edicaoHabilitada}
              onSelect={async (e) => await onSelectProprietario(e)}
              onBlur={() => form.setFieldTouched('negociacaoVeiculo.proprietario')}
              suggestions={sugestaoPessoas}
              completeMethod={sugerirPessoas}
              onChange={handleChangeProprietario}
              className={classNames({ 'p-invalid': isFormFieldValid('negociacaoVeiculo.proprietario') }, 'inputfield w-full')}
              isFormFieldValid={isFormFieldValid('negociacaoVeiculo.proprietario')}
              //RegisterModal
              selected={form.values.negociacaoVeiculo.proprietario}
              onSaveModal={onSaveModalProprietario}
              onDeleteModal={async () => await onSelectProprietario({})}
              ComponenteCadastro={[
                {
                  label: 'Pessoa física',
                  component: PessoaFisica,
                  header: form.values.proprietario.codigo_pes ? 'Pessoa Física - ' + form.values.proprietario.codigo_pes : 'Cadastro de pessoa física'
                },
                {
                  label: 'Pessoa jurídica',
                  component: PessoaJuridica,
                  header: form.values.proprietario.codigo_pes ? 'Pessoa jurídica - ' + form.values.proprietario.codigo_pes : 'Cadastro de pessoa jurídica'
                }
              ]}
              // FiltroModal
              filtrotitle="Pesquisa de pessoa"
              service={PessoaService}
              model={PessoaModel}
              primarykeyname="codigo_pes"
              columns={[
                { campo: 'codigo_pes', nome: 'Código' },
                { campo: 'nomraz_pes', nome: 'Nome' },
                { campo: 'cgccpf_pes', nome: 'CPF/CNPJ', format: formatDocument }
              ]}
            />
            <FieldErrorMessage message={getFormErrorMessage('negociacaoVeiculo.proprietario')} />
          </div>
          <div className={(CommonHelper.isDesktop() ? 'flex flex-col justify-between gap-1' : ' w-full')}>
            <div className={(CommonHelper.isDesktop() ? 'p-2' : 'field col-12 md:col-2')}>
              <RequiredLabel label="Valor consignação" valid={isFormFieldValid('negociacaoVeiculo.valor_nve')} />
              <TextInput
                name="negociacaoVeiculo.valor_nve"
                disabled={!edicaoHabilitada}
                onChange={handleChangeCurrency}
                value={formatToCurrency(form.values.negociacaoVeiculo?.valor_nve || 0)}
                onBlur={() => { form.setFieldTouched('negociacaoVeiculo.valor_nve') }}
                className={classNames({ 'p-invalid': isFormFieldValid('negociacaoVeiculo.valor_nve') }, 'inputfield w-full')} />
              <FieldErrorMessage message={getFormErrorMessage('negociacaoVeiculo.valor_nve')} />
            </div>
            <div className={(CommonHelper.isDesktop() ? 'p-2' : 'field col-12 md:col-2')}>
              {pagandoConsignado && (
                <>
                  <TextInput
                    label="Vendido por"
                    disabled={true}
                    value={formatToCurrency(JSON.parse(localStorage.getItem('negociacao_' + form.values.codigo_neg)).vendidoPor)}
                    className="inputfield w-full" />
                </>
              )}
              {!pagandoConsignado && (
                <>
                  <TextInput
                    label="Valor de Venda"
                    name="negociacaoVeiculo.valfut_nve"
                    disabled={(!edicaoHabilitada) || !(edicaoHabilitada && permissaoAlterarPreco)}
                    onChange={handleChangeCurrency}
                    value={formatToCurrency(form.values.negociacaoVeiculo?.valfut_nve || 0)}
                    onBlur={() => { form.setFieldTouched('negociacaoVeiculo.valfut_nve') }}
                    className={classNames({ 'p-invalid': isFormFieldValid('negociacaoVeiculo.valfut_nve') }, 'inputfield w-full')} />
                  <FieldErrorMessage message={getFormErrorMessage('negociacaoVeiculo.valfut_nve')} />
                </>
              )}
            </div>
            <div className={(CommonHelper.isDesktop() ? 'p-2' : 'field col-12 md:col-2')}>
              <NumberInput
                label="Km Atual"
                placeholder="Km Atual"
                value={form.values.negociacaoVeiculo?.kmatu_nve}
                onChange={(e) => form.setFieldValue('negociacaoVeiculo.kmatu_nve', e.value)}
                disabled={!edicaoHabilitada}
                className="inputfield w-full"
                useGrouping={false}
                min="0"
              />
            </div>
          </div>

        </div>
      </>
    )
  }

  return (
    <>
      <div className="formgrid grid fluid">
        <div className={(CommonHelper.isDesktop() ? 'pr-6' : '') + ' field col-12 md:col-4'}>
          <SearchInput
            //AutoComplete
            field="nomraz_pes"
            label="Cliente"
            value={form.values.pessoa && form.values.pessoa.nomraz_pes}
            placeholder="Nome do cliente"
            disabled={pagandoConsignado ? true : !edicaoHabilitada}
            onSelect={async (e) => await onSelectPessoa(e)}
            onBlur={() => form.setFieldTouched('pessoa.codigo_pes')}
            suggestions={sugestaoPessoas}
            completeMethod={sugerirPessoas}
            minLength='1'
            onChange={handleChangePessoa}
            className={classNames({ 'p-invalid': isFormFieldValid('pessoa.codigo_pes') }, 'inputfield w-full')}
            isFormFieldValid={isFormFieldValid('pessoa.codigo_pes')}
            //RegisterModal
            selected={form.values.pessoa}
            onSaveModal={onSaveModalPessoa}
            onDeleteModal={async () => await onSelectPessoa({})}
            ComponenteCadastro={[
              {
                label: 'Pessoa física',
                component: PessoaFisica,
                header: form.values.pessoa.codigo_pes ? 'Pessoa Física - ' + form.values.pessoa.codigo_pes : 'Cadastro de pessoa física'
              },
              {
                label: 'Pessoa jurídica',
                component: PessoaJuridica,
                header: form.values.pessoa.codigo_pes ? 'Pessoa jurídica - ' + form.values.pessoa.codigo_pes : 'Cadastro de pessoa jurídica'
              }
            ]}
            // FiltroModal
            filtrotitle="Pesquisa de pessoa"
            service={PessoaService}
            model={PessoaModel}
            primarykeyname="codigo_pes"
            columns={[
              { campo: 'codigo_pes', nome: 'Código' },
              { campo: 'nomraz_pes', nome: 'Nome razão' },
              { campo: 'nomfan_pej', nome: 'Nome fantasia', preload: 'pessoaJuridica' },
              { campo: 'cgccpf_pes', nome: 'CPF/CNPJ', format: formatDocument }
            ]}
          />
          <FieldErrorMessage message={getFormErrorMessage('pessoa.codigo_pes')} />
        </div>
        <div className={'md:col-4 field col-12'}>
          <SearchInput
            //AutoComplete
            field="nomraz_pes"
            label="Vendedor"
            value={form.values.vendedor && form.values.vendedor.nomraz_pes}
            placeholder="Nome do Vendedor"
            minLength='1'
            disabled={!edicaoHabilitada}
            onSelect={async (e) => await onSelectVendedor(e)}
            className={classNames({ 'p-invalid': isFormFieldValid('vendedor.codigo_pes') }, 'inputfield w-full')}
            onBlur={() => form.setFieldTouched('vendedor.codigo_pes')}
            suggestions={sugestaoVendedor}
            completeMethod={sugerirVendedor}
            onChange={handleChangeVendedor}
            isFormFieldValid={isFormFieldValid('vendedor.codigo_pes')}
            // FiltroModal
            filtrotitle="Pesquisa de vendedor"
            service={VendedoresService}
            model={VendedorModel}
            primarykeyname="codigo_ven"
            columns={[
              { campo: 'codigo_ven', nome: 'Código' },
              { campo: 'nomraz_pes', nome: 'Nome' }
            ]}
          />
          <FieldErrorMessage message={getFormErrorMessage('vendedor.codigo_pes')} />
        </div>
        <div className={'md:col-4 field col-12'}>
          {config1433 && !isConsignado() && isVenda() ? <RequiredLabel label="Mídia" valid={isFormFieldValid('tipomidia.codigo_tim')} /> : <label>Mídia</label>}
          <SelectInput
            value={form.values.tipomidia}
            options={tiposmidia}
            onChange={handleChangeTipoMidia}
            name="tipomidia"
            optionLabel="descri_tim"
            dataKey="codigo_tim"
            onBlur={() => form.setFieldTouched('tipomidia.codigo_tim')}
            className={classNames({ 'p-invalid': isFormFieldValid('tipomidia') }, 'inputfield w-full')}
            placeholder="- Selecione a mídia -"
            noFloatLabel
            disabled={!edicaoHabilitada}
            showClear={form.values.tipomidia?.codigo_tim}
            onHide={() => form.setFieldTouched('tipomidia.codigo_tim')}
          />
          <FieldErrorMessage message={getFormErrorMessage('tipomidia.codigo_tim')} />
        </div>
        <div className="form-divider">

        </div>
        <div className={(isVenda() ? 'md:col-4' : 'md:col-6') + ' field col-12 text-left no-padding'}>
          {isVenda() && form.values.negociacaoVeiculo?.veiculo?.codigo_vei && (
            <div className='flex'><h1 className="titulo-veiculo">Veículo</h1>
              <AppButton
                label="Limpar"
                icon="pi pi-times"
                type="button"
                onClick={() => limparVeiculo()}
                className="p-button-danger ml-3 "
                disabled={!edicaoHabilitada}
              /></div>
          )}
          {(
            <div className={(CommonHelper.isDesktop() ? 'pr-6' : '') + ' field col-12 md:col-12 no-margin'}>
              {!form.values.negociacaoVeiculo?.veiculo?.codigo_vei &&
                <SearchInput
                  //AutoComplete
                  field="placa_vei"
                  label="Veículo"
                  value={(form.values.negociacaoVeiculo?.veiculo && form.values.negociacaoVeiculo?.veiculo?.placa_vei) || form.values.negociacaoVeiculo?.veiculo?.chassi_vei}
                  placeholder="Informe a placa do veículo"
                  disabled={pagandoConsignado ? true : !edicaoHabilitada}
                  onChange={handleChangeVeiculo}
                  onSelect={async (e) => await onSelectVeiculo(e)}
                  className={classNames({ 'p-invalid': isFormFieldValid('negociacaoVeiculo.codvei_nve') }, 'inputfield w-full')}
                  onBlur={() => form.setFieldTouched('negociacaoVeiculo.codvei_nve')}
                  suggestions={sugestaoVeiculos}
                  completeMethod={sugerirVeiculos}
                  itemTemplate={VeiculoTemplate}
                  isFormFieldValid={isFormFieldValid('negociacaoVeiculo.codvei_nve')}
                  //RegisterModal
                  selected={form.values.negociacaoVeiculo?.veiculo}
                  ComponenteCadastro={Veiculo}
                  onSaveModal={onSaveModalVeiculo}
                  onDeleteModal={() => onSelectVeiculo({})}
                  modalHeader={form.values.negociacaoVeiculo?.veiculo?.codigo_vei ? `Veículo - ${form.values.negociacaoVeiculo?.veiculo.codigo_vei}` : 'Cadastro de veículo'}
                  // FiltroModal
                  filtrotitle="Pesquisa de veículo"
                  service={VeiculoService}
                  filtersDefault={ isVenda() && { apenasOfertados: true }}
                  model={getVeiculoDTO}
                  primarykeyname="codigo_vei"
                  columns={[
                    { campo: 'codigo_vei', nome: 'Código' },
                    { campo: 'placa_vei', nome: 'Placa', format: formatPlaca },
                    { campo: 'chassi_vei', nome: 'Chassi' },
                    { campo: 'modelo.descri_mod', nome: 'Modelo' },
                    { campo: 'modelo.marca.descri_mar', nome: 'Marca' }
                  ]}
                />
              }

              <FieldErrorMessage message={getFormErrorMessage('negociacaoVeiculo.codvei_nve')} />
            </div>
          )}

          {veiculoNve?.modelo?.marca?.descri_mar && (
            <div className={(isVenda() ? 'first' : 'mt-3') + ' col-12 flex neg-row'}>
              <span className="neg-row-label">Marca/Modelo:</span>
              <span className="neg-row-value">
                {veiculoNve?.modelo?.marca?.descri_mar}/{veiculoNve?.modelo?.descri_mod}
              </span>
            </div>
          )}
          {veiculoNve?.anofab_vei && (
            <div className="col-12 flex neg-row">
              <span className="neg-row-label">Ano:</span>
              <span className="neg-row-value">
                {veiculoNve?.anofab_vei}/{veiculoNve?.anomod_vei}
              </span>
            </div>
          )}
          {veiculoNve?.cor?.descri_cor && (
            <div className="col-12 flex neg-row">
              <div className="neg-row-label">Cor:</div>
              <span className="neg-row-value">
                {veiculoNve?.cor?.descri_cor}
              </span>
            </div>
          )}
          {veiculoNve?.codigo_vei && (
            <div className="col-12 flex neg-row placa">
              <div className="neg-row-label">Placa/Chassi:</div>
              <span className="neg-row-value underline cursor-pointer" onClick={() => setVisibleVeiculoModal(true)}>
                {veiculoNve?.placa_vei ? formatPlaca(veiculoNve?.placa_vei) : (veiculoNve?.chassi_vei)?.substring(veiculoNve?.chassi_vei?.length - 8)}
              </span>
              {veiculoNve?.codtiv_vei && (
                <span className={`tipo-veiculo-${veiculoNve?.codtiv_vei} neg-tipo-veiculo`}>
                  {veiculoNve?.codtiv_vei === 'n' ? 'Novo' : 'Usado'}
                </span>
              )}
            </div>
          )}
          {veiculoNve?.acessorios?.sigla_ace && (
            <div className="col-12 flex neg-row">
              <div className="neg-row-label">Acessórios:</div>
              <div
                className="neg-row-value underline cursor-pointer"
                onClick={() => { setVisibleAcessoriosModal(true); setAcessoriosList(veiculoNve?.acessorios?.descri_ace) }}
              >
                {veiculoNve?.acessorios?.sigla_ace}
              </div>
            </div>
          )}
        </div>
        {isVenda() && (
          dadosPrincipaisVenda()
        )}
        {operacaoNeg === 'Compra' && (
          dadosPrincipaisCompra()
        )}
        {operacaoNeg === 'Consignação' && (
          dadosPrincipaisConsignacao()
        )}
      </div>
      <Modal
        header="Lista de acessórios"
        width="40"
        visible={visibleAcessoriosModal}
        onHide={() => { setVisibleAcessoriosModal(false); setAcessoriosList('') }}
      >
        {acessoriosList?.split(', ').map((acessorio, idx) => (
          <div key={idx}>{acessorio}</div>
        ))}
      </Modal>
      <VeiculoModal
        visible={visibleVeiculoModal}
        hide={() => setVisibleVeiculoModal(false)}
        onSaveModal={onSaveModalVeiculo}
        selected={form.values.negociacaoVeiculo?.veiculo}
        onDeleteModal={() => onSelectVeiculo({})}
        codigoSelecionado={form.values.pessoa.codigo_pes}
        primaryKey={veiculoNve?.codigo_vei}
        apenasVisualizacao={!edicaoHabilitada}
      />
    </>
  )
}

export default DadosPrincipaisNeg
