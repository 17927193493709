import ServiceBase from '../../base/ServiceBase'

export default class ComentariosService {
  /**
     * Retorna todos os comentários não excluídos
     * @param {string} tabela_com Tabela
     * @param {number} codpk_com Código
     */
  static async getComentarios (tabela_com, codpk_com) {
    return await ServiceBase.getBase(`/comentarios?tabela_com=${tabela_com}&codpk_com=${codpk_com}`)
  }

  /**
	 * Adiciona comentário
	 * @param {string} tabela_com Tabela
	 * @param {number} codpk_com Código
	 * @param {string} coment_com Comentário
	 */
  static async addComentario (tabela_com, codpk_com, coment_com) {
    return await ServiceBase.postBase('/comentarios', {
      tabela_com,
      codpk_com,
      coment_com
    })
  }

  /**
	 * Exclui comentário
	 * @param {number} codigo_com Código do comentário
	 */
  static async excluirComentario (codigo_com) {
    await ServiceBase.deleteBase(`/comentarios/${codigo_com}`)
  }
}
