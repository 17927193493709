import React from 'react'
import Modal from '../../../../components/utils/Modal'
import Veiculo from '../../../../views/cadastro/veiculo/veiculo/Veiculo'

const VeiculoModal = ({ hide, visible, onSaveModal, onDeleteModal, primaryKey, apenasVisualizacao }) => {
  return (
    <Modal
      width="75"
      visible={visible}
      onHide={() => hide()}
      header={primaryKey ? 'Veículo - ' + primaryKey : 'Cadastro de veículo'}
    >
      <Veiculo
        onSaveModal={onSaveModal}
        onDeleteModal={onDeleteModal}
        codigoSelecionado={primaryKey}
        apenasVisualizacao={apenasVisualizacao}
        onHide={() => hide()}
      />
    </Modal>
  )
}

export default VeiculoModal
