import NegociacaoService from '../../../services/vendas/negociacao/NegociacaoService'
import ManutencaoService from '../../../services/cadastro/manutencao/ManutencaoService'
import OrdemServicoService from '../../../services/vendas/ordemServico/OrdemServicoService'
import { getStore } from '../../../redux/store'
import { showWarnMessage } from '../../../components/utils/Message'
import { formatPlacha } from '../../../helpers/formaters'

class PersistenciaVendaVeiculo {
  async persistenciaParaVenderVeiculo (veiculo) {
    const empresaLogada = getStore().empresaLogada

    let descricaoDisponibilidade
    let permissaoParaVenda
    let precoDeOferta
    const placha = formatPlacha(veiculo.placa_vei, veiculo.chassi_vei)

    if (veiculo.produto) {
      descricaoDisponibilidade = veiculo.produto?.produtoEmpresas[0]?.veiculoDisponibilidade?.descri_vdi
      permissaoParaVenda = veiculo.produto?.produtoEmpresas[0]?.veiculoSituacao?.perven_ves
      precoDeOferta = veiculo.produto?.produtoEmpresas[0]?.preco_pre
    } else {
      descricaoDisponibilidade = veiculo.descri_vdi
      permissaoParaVenda = veiculo.perven_ves
      precoDeOferta = veiculo.preco_pre
    }

    if (descricaoDisponibilidade === 'Bloqueado') {
      showWarnMessage(`Veículo: ${placha} está bloqueado!`)
      return false
    }
    if (!permissaoParaVenda) {
      showWarnMessage(`A situação do veículo: ${placha} não permite que ele seja vendido!`)
      return false
    }

    if (precoDeOferta <= 0) {
      showWarnMessage(`Veículo: ${placha} não possui valor de oferta definido!`)
      return false
    }

    const negociacaoEmAberto = await NegociacaoService.getNegociacaoEmAberto(veiculo.codigo_vei, false)

    if (negociacaoEmAberto) {
      showWarnMessage(`Já existe uma negociação em aberto para o veículo: ${placha} Neg.: ${negociacaoEmAberto.codigo_neg}. ` +
          'Para prosseguir, primeiro cancele a negociação em aberto!')
      return false
    }

    const manutencaoEmAberto = await ManutencaoService.getManutencaoEmAberto(veiculo.codigo_vei, false)

    if (manutencaoEmAberto) {
      showWarnMessage(`Existem manutenções em aberto para o veículo: ${placha}`)
    }

    const ordemServicoEmAberto = await OrdemServicoService.getOrdemServicoEmAberto(veiculo.codigo_vei, false)

    if (ordemServicoEmAberto) {
      showWarnMessage(`Existem ordens de serviço em aberto para o veículo: ${placha}`)
    }
    if (empresaLogada.codigo_emp !== veiculo.ofeemp_vei) {
      showWarnMessage(`Veículo: ${placha} em estoque na empresa ${veiculo.ofeemp_vei}!`)
      return false
    }

    const veiculosDemonstracao = await NegociacaoService.validaVeiculosDemonstracao(false)

    let veiculoEstaEmDemonstracao = false

    for (const veiculoDemonstracao of veiculosDemonstracao) {
      if (veiculoDemonstracao.bloquearNeg === 'Sim' && veiculoDemonstracao.codpro_pre === veiculo.codigo_pro) {
        showWarnMessage(`Veículo: ${veiculo.codigo_vei} com demonstração à vencer/vencida!`)
        veiculoEstaEmDemonstracao = true
      }
    }

    if (veiculoEstaEmDemonstracao) return false

    return true
  }
}

export const persistenciaVendaVeiculo = new PersistenciaVendaVeiculo()
