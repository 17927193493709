import { server } from '../../helpers/server'

export default class ReportService {
  static async getContratoCompraVenda (codigoNegociacao, dataHoraEntregaVeiculo) {
    try {
      let urlNegDHEntrega
      if (!!(dataHoraEntregaVeiculo)) {
        urlNegDHEntrega = `${codigoNegociacao}?dataHoraEntregaVeiculo=${dataHoraEntregaVeiculo}`
      } else {
        urlNegDHEntrega = `${codigoNegociacao}`
      }
      const response = await server(true, 'blob').get(`report/contratoCompraVenda/${urlNegDHEntrega}`)
      return response.data
    } catch (error) {
      throw Error(error.response?.data?.mensagemUsuarioFinal)
    }
  }

  static async getContratoVendaGarantia (codigoNegociacao) {
    try {
      const response = await server(true, 'blob').get(`report/contratoVendaGarantia/${codigoNegociacao}`)
      return response.data
    } catch (error) {
      throw Error(error.response?.data?.mensagemUsuarioFinal)
    }
  }

  static async getRecibo (tipo, codigoNegociacao) {
    try {
      const response = await server(true, 'blob').get(`report/recibo/${tipo}/${codigoNegociacao}`)
      return response.data
    } catch (error) {
      throw Error(error.response?.data?.mensagemUsuarioFinal)
    }
  }

  static async getCheckList (codigoNegociacao) {
    try {
      const response = await server(true, 'blob').get(`report/checkList/${codigoNegociacao}`)
      return response.data
    } catch (error) {
      throw Error(error.response?.data?.mensagemUsuarioFinal)
    }
  }

  static async getTermoEntregaEntrada (codigoNegociacao, codigoVeiculo, pessoaEntregue) {
    try {
      let response
      if (pessoaEntregue) {
        response = await server(true, 'blob').get(`report/termoEntregaEntrada/${codigoNegociacao}/${codigoVeiculo}?entreguePara=${pessoaEntregue}`)
      } else {
        response = await server(true, 'blob').get(`report/termoEntregaEntrada/${codigoNegociacao}/${codigoVeiculo}`)
      }
      return response.data
    } catch (error) {
      throw Error(error.response?.data?.mensagemUsuarioFinal)
    }
  }

  static async getTermoEntregaSaida (codigoNegociacao, codigoNegociacaoVeiculo, pessoaEntregue) {
    try {
      let response
      if (pessoaEntregue) {
        response = await server(true, 'blob').get(`report/termoEntregaSaida/${codigoNegociacao}/${codigoNegociacaoVeiculo}?entreguePara=${pessoaEntregue}`)
      } else {
        response = await server(true, 'blob').get(`report/termoEntregaSaida/${codigoNegociacao}/${codigoNegociacaoVeiculo}`)
      }
      return response.data
    } catch (error) {
      try {
        let response
        if (pessoaEntregue) {
          response = await server(true, 'blob').get(`report/termoEntregaSaida/${codigoNegociacao}/${codigoNegociacaoVeiculo}?entreguePara=${pessoaEntregue}`)
        } else {
          response = await server(true, 'blob').get(`report/termoEntregaSaida/${codigoNegociacao}/${codigoNegociacaoVeiculo}`)
        }
        return response.data
      } catch (error) {
        throw Error(error.response?.data?.mensagemUsuarioFinal)
      }
    }
  }

  static async getInstrumentoProcuracao (tipo, codigoNegociacao, codigoNegociacaoVeiculo) {
    try {
      let urlRequest = `report/instrumentoProcuracao/${tipo}/${codigoNegociacao}`

      if (codigoNegociacaoVeiculo && tipo === 'venda') {
        urlRequest += `/${codigoNegociacaoVeiculo}`
      }

      const response = await server(true, 'blob').get(urlRequest)
      return response.data
    } catch (error) {
      throw Error(error.response?.data?.mensagemUsuarioFinal)
    }
  }

  static async getDeclaracaoResidencia (codigoPessoa, prefixo) {
    try {
      const response = await server(true, 'blob').post(`report/declaracaoResidencia/${codigoPessoa}`, { prefixo })
      return response.data
    } catch (error) {
      throw Error(error.response?.data?.mensagemUsuarioFinal)
    }
  }

  static async getContratoConsignacao (codigoNegociacao) {
    try {
      const response = await server(true, 'blob').get(`report/contratoConsignacao/${codigoNegociacao}`)
      return response.data
    } catch (error) {
      throw Error(error.response?.data?.mensagemUsuarioFinal)
    }
  }

  static async getResumo (codigoNegociacao) {
    try {
      const response = await server(true, 'blob').get(
        `report/resumo/${codigoNegociacao}`
      )
      return response.data
    } catch (error) {
      throw Error(error.response?.data?.mensagemUsuarioFinal)
    }
  }

  static async getHTML (codigoNegociacao, report, rota) {
    try {
      const response = await server(true).get(
        `report/${rota}/${codigoNegociacao}/${report}`,
        { responseType: 'text' }
      )
      return response.data
    } catch (error) {
      throw Error(error.response?.data?.mensagemUsuarioFinal)
    }
  }

  static async getEnvelope (codigoNegociacao) {
    try {
      const response = await server(true, 'blob').get(
        `report/envelope/${codigoNegociacao}`
      )
      return response.data
    } catch (error) {
      throw Error(error.response?.data?.mensagemUsuarioFinal)
    }
  }
}
