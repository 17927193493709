import { postUsuarioDTO } from '../../../dtos/cadastro/pessoa/UsuarioDTO'
import ServiceBase from '../../base/ServiceBase'

export default class UsuarioService {
  static async getUsuarioLogado () {
    return await ServiceBase.getBase('users/logado')
  }

  async get (id) {
    return await ServiceBase.getBase(`users/${id}`)
  }

  async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`users?page=${page}&perPage=${perPage}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`users?${query}&page=${page}&perPage=${perPage}`)
  }

  async getFormValidator () {
    return await ServiceBase.getBase('users/validator')
  }

  static async filterAutoComplete (email) {
    return await ServiceBase.getBase(`users?email=${email}`, false)
  }

  async save (data) {
    return await ServiceBase.postBase('users', postUsuarioDTO(data))
  }

  async delete (codigo_usu) {
    return await ServiceBase.deleteBase(`users/${codigo_usu}`)
  }

  static async alterarSenha ({ senhaAtual, novaSenha, confirmaSenha }) {
    return await ServiceBase.postBase('users/alterarSenha', {
      senhaAtual,
      novaSenha,
      confirmaSenha
    })
  }

  /**
     * Retorna os menus que o usuário possui acesso
     * @returns {Promise<Menu[]>} Lista de menus
     */
  static async getMenusAcesso () {
    return await ServiceBase.getBase('users/menusacesso')
  }

  /**
    * Retorna as empresas que o usuário possui acesso
    */
  static async getEmpresasAcessoUsuarioLogado () {
    return await ServiceBase.getBase('users/empresasacesso')
  }

  static async getUsuariosWeb () {
    return await ServiceBase.getBase('users/web')
  }

  static async getAllUsers () {
    return await ServiceBase.getBase('users/all')
  }
}
