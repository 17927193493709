import * as Yup from 'yup'
import moment from 'moment'

export const FinanciamentoAbertoValidator = Yup.object().shape({
  veiculo: Yup.object().shape({
    codigo_vei: Yup.number()
      .nullable()
      .required('O veículo não foi selecionado!')
  }),
  valor: Yup.number()
    .nullable()
    .required('O valor não foi informado!')
    .min(0.001, 'O valor não foi informado!'),

  financeira: Yup.object().shape({
    codigo_fin: Yup.number()
      .nullable()
      .min(1, 'A financeira não foi informada!')
      .required('A financeira não foi informada!')
  }),
  primeiroVencimento: Yup.date()
    .nullable()
    .required('A previsão de pagamento não foi informada!')
    .test('testDataCompensacao', 'Previsão de pagamento menor que a data de emissão!', function (value) {
      const diferenca = moment(value).diff(this.parent.dataEmissao, 'days')

      if (diferenca >= 0) {
        return true
      }

      return false
    }),

  valorParcela: Yup.number()
    .test('testSomaParcelas', 'A soma das parcelas é diferente do valor do financiamento', function (value) {
      if ((this.parent.quantidadeParcelas) || (this.parent.valorParcela)) {
        const resultado = (this.parent.quantidadeParcelas * this.parent.valorParcela).toFixed(2) - this.parent.valor

        if (resultado !== 0) {
          return false
        }

        return true
      } else {
        return true
      }
    })

})
