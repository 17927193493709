import ServiceBase from '../../base/ServiceBase'

export default class GrupoProdutoService {
  static async getAll () {
    return await ServiceBase.getBase('/grupoproduto')
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`/grupoproduto?${query}&page=${page}&perPage=${perPage}`)
  }

  static async filterSugestao (descricao) {
    return await ServiceBase.getBase(`/grupoproduto?${descricao}&page=${1}&perPage=${1000}`)
  }
}
