import ServiceBase from '../base/ServiceBase'

export default class TipoMidiaService {
  static async getAll () {
    return await ServiceBase.getBase('tiposmidia')
  }

  static async filterTipoMidia () {
    return await ServiceBase.getBase('tiposmidia/filter')
  }
}
