import { Component } from 'react'
import { showErrorMessage } from '../components/utils/Message'

export class Crud extends Component {
  constructor (props, modelDTO, Service, resource, primaryKey = 'id') {
    super(props)

    this.modelDTO = modelDTO
    this.modelService = new Service()
    this.resource = resource
    this.primaryKey = primaryKey
    this.state = {
      model: modelDTO(),
      dataLoaded: false,
      isNew: false,
      resource: this.resource
    }
  }

  async componentDidMount () {
    let id = this.props.match.params.id
    if (id) {
      this.get(id)
    } else {
      this.setState({ isNew: true })
    }
  }

  get = async (id) => {
    try {
      let model = await this.modelService.get(id)
      this.setState({
        model: this.modelDTO(model),
        dataLoaded: true
      })
    } catch (e) {
      showErrorMessage(e.message || 'Erro ao obter informações')
    }
  };

  onSave = async (data) => {
    try {
      await this.modelService.save(data)
      showSuccessMessage('Registro salvo com sucesso')

      return true
    } catch (e) {
      showErrorMessage(e.message || 'Erro ao salvar registro')
      return false
    }
  };

  onClose = async () => {
    this.props.history.push(`/${this.resource}`)
  };

  onChange = (model) => {
    this.setState({ model })
  };
}
